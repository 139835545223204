import React, { Component } from 'react';
import map from 'lodash/map';
import CarType from '../common/CarType'
import InfiniteScroll from 'react-infinite-scroller';
import MyRequestEmptyState from '../EmptyState/MyRequestEmptyState';
import PageLoader from '../common/PageLoader';
import { appBasePath } from '../../constants/staticData';

class CardView extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      currentWidth: '',
      parentContainer: null
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.windowWidth = this.windowWidth.bind(this);
  }
  windowWidth() {
    let docElemProp = window.document.documentElement.clientWidth,
      body = window.document.body;
    return window.document.compatMode === "CSS1Compat" && docElemProp || body && body.clientWidth || docElemProp;
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  updateDimensions() {
    const windowWidth = this.windowWidth();
    this.setState({ 'currentWidth': windowWidth });
  }
  render() {
    let jobLeftGridValue = "";
    let jobRightGridValue = "";
    if (this.state.currentWidth <= 1155 && this.state.currentWidth >= 992) {
      jobLeftGridValue = "col-md-7";
      jobRightGridValue = "col-md-5";
    } else if (this.state.currentWidth <= 1345 && this.state.currentWidth >= 1155) {
      jobLeftGridValue = "col-md-6";
      jobRightGridValue = "col-md-6";
    } else {
      jobLeftGridValue = "col-md-5";
      jobRightGridValue = "col-md-7";
    }
    return (

      <div>
        {this.props.data && this.props.data.length ?
          <InfiniteScroll
            pageStart={0}
            loadMore={this.props.btnCallBack}
            hasMore={this.props.checkLoadMore}
            loader={<PageLoader />}>
            {map(this.props.data, (cardDetails, key) => {
              return (
                <div key={key}>
                  {<CarType
                    source={this.props.source}
                    userPaymentStatus={this.props.userPaymentStatus}
                    authReducer={this.props.authReducer}
                    bookingRequestReducer={this.props.bookingRequestReducer}
                    actions={this.props.actions}
                    router={this.props.router}
                    key={key}
                    vendorQuotesCount={cardDetails && cardDetails.totalJobQuotes}
                    cardDetails={cardDetails}
                    jobLeftGridValue={jobLeftGridValue}
                    jobRightGridValue={jobRightGridValue}
                    messageRoute={() => {
                      if (cardDetails.requestid) {
                        this.props.router.push(appBasePath + "/request/" + cardDetails.requestid);
                      }
                    }}
                    quotesClick={(e) => {
                      e.stopPropagation();
                      this.clickRoute = "quotes";
                      if (cardDetails.requestid && this.clickRoute) {
                        this.props.router.push(`${appBasePath}/request/${cardDetails.requestid}?view=${this.clickRoute}`);
                      } else if (cardDetails.requestid) {
                        this.props.router.push(appBasePath + '/request/' + cardDetails.requestid);
                      }
                    }}
                    parentRef={this.state.parentContainer} />}
                </div>
              );
            })}
          </InfiniteScroll> : <MyRequestEmptyState router={this.props.router} title={this.props.emptyStateTitle} />}

      </div>
    );
  }
}

export default CardView;
