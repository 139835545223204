import React, { Component } from 'react';
import { Tabs, Tab, Popover, OverlayTrigger, PanelGroup } from 'react-bootstrap';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import size from 'lodash/size';
import each from 'lodash/each';

import CustomScroll from 'react-custom-scroll';
import ServiceTabs from './ServiceTabs';
import Button from '../common/Button';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverDesc: {
        name: "",
        desc: "",
        subCat: ""
      },
      activeCarWash: 0,
      activeCarRepair: 0,
      activeCarService: 0,
    };
    this.customPopoverRight = this.customPopoverRight.bind(this);
    this.renderCarWash = this.renderCarWash.bind(this);
    this.renderCarRepair = this.renderCarRepair.bind(this);
    this.renderCarService = this.renderCarService.bind(this);
    this.renderSupportedManufacturers = this.renderSupportedManufacturers.bind(this);
  }

  createRequest(serviceId, serviceParentId) {
    const { router, garageProfileDetails } = this.props;
    switch (serviceParentId) {
      case 1:
        router.push(`${appBasePath}/car-wash?vendor_id=${garageProfileDetails.id}?serviceId=${serviceId}`);
        break;
      case 2:
        router.push(`${appBasePath}/car-service?vendor_id=${garageProfileDetails.id}?serviceId=${serviceId}`);
        break;
      case 3:
        router.push(`${appBasePath}/car-repair?vendor_id=${garageProfileDetails.id}?serviceId=${serviceId}`);
        break;
      case 4:
        router.push(`${appBasePath}/emergency`);
        break;
      default:
        break;
    }
  }


  customPopoverRight(serviceId, serviceParentId) {
    return (
      <Popover className="customPopover" id="popover-positioned-right">
        <h4>{this.state.popoverDesc.name}</h4>
        <p>{this.state.popoverDesc.desc}</p>
        {this.state.popoverDesc.subCat && <h4>Subcategories</h4>}
        <ul className="popoverCatList">
          {map(this.state.popoverDesc.subCat, (subCatlist, key) => {
            return (
              <li key={key}>{subCatlist.name}</li>
            );
          })}
        </ul>
        <div className="popOver-footer text-right">
          <Button btnType="red" btnSize="lg" fontSize={14} btnCallBack={(e) => {
            localStorage.getItem('authData') ? this.createRequest(serviceId, serviceParentId) : this.props.router.push(appBasePath + '/guest-user/');
          }} label="Book Service" />
        </div>
      </Popover>
    );
  }

  renderCarWash() {
    let allServices = this.props.garageServices;
    let carWash = filter(allServices, function (service) {
      return service.serviceParentId == 1;
    });
    const carWashListView = map(carWash, (carItem, key) => {
      return (
        <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.customPopoverRight(carItem.serviceId, carItem.serviceParentId)}>
          <div className="carModelList" key={key} onClick={() => { this.setState({ 'activeCarWash': carItem.id, popoverDesc: { name: carItem.name, desc: carItem.description } }); }}>
            <div className={carItem.id == this.state.activeCarWash ? 'img-circle active' : 'img-circle'}>
              <img src={carItem.image ? carItem.image : `${imageBasePath}/images/placeholder.png`} alt="" />
            </div>
            <h6>{carItem.name}</h6>
          </div>
        </OverlayTrigger>
      );
    });

    return (
      <div className="vendor-service-container" id="n-services">
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <div className="vendor-service-list">
            {size(carWashListView)
              ?
              carWashListView :
              <div className="noResults-found">
                <img src={`${imageBasePath}/images/empty_service.png`} />
                <p>This Service is Currently unavailable</p>
              </div>
            }
          </div>
        </CustomScroll>
      </div>
    );
  }

  renderCarRepair() {
    let repairRequests = this.props.bookingRequestReducer.getAllServices && find(this.props.bookingRequestReducer.getAllServices, { id: 3 })
    let subServices = this.props.bookingRequestReducer.getAllServices && repairRequests ? repairRequests.sub_services : [];
    let carRepairListView = [];
    each(subServices, (carItem, key) => {
      let selectedServices = this.props.garageServices;
      let subCategory = filter(selectedServices, { serviceParentId: carItem.id });
      if (subCategory && subCategory.length) {
        carRepairListView.push(
          <OverlayTrigger key={key} trigger="click" rootClose placement="right" overlay={this.customPopoverRight(carItem.id, carItem.parentId)}>
            <div className="carModelList" onClick={() => { this.setState({ 'activeCarRepair': carItem.id, popoverDesc: { name: carItem.name, desc: carItem.description, subCat: subCategory } }); }}>
              <div className={carItem.id == this.state.activeCarRepair ? 'img-circle active' : 'img-circle'}>
                <img src={carItem.image ? carItem.image : `${imageBasePath}/images/placeholder.png`} alt="" />
              </div>
              <h6>{carItem.name}</h6>
            </div>
          </OverlayTrigger>
        );
      }
    });
    return (
      <div className="vendor-service-container">
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <div className="vendor-service-list">
            {size(carRepairListView)
              ?
              carRepairListView :
              <div className="noResults-found">
                <img src={`${imageBasePath}/images/empty_service.png`} />
                <p>This Service is Currently unavailable</p>
              </div>
            }
          </div>
        </CustomScroll>
      </div>
    );
  }

  renderCarService() {
    let carServices = this.props.bookingRequestReducer.getAllServices && find(this.props.bookingRequestReducer.getAllServices, { id: 2 });
    return (
      <div className="vendor-service-container">
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <div className="vendor-service-list">
            {carServices
              ?
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.customPopoverRight(carServices.id, carServices.id)}>
                <div className="carModelList" onClick={() => { this.setState({ 'activeCarService': carServices.id, popoverDesc: { name: carServices.name, desc: carServices.description, subCat: carServices.sub_services } }); }}>
                  <div className={carServices.id == this.state.activeCarService ? 'img-circle active' : 'img-circle'}>
                    <img src={`${imageBasePath}/images/carservice.png`} alt="" />
                  </div>
                  <h6>{carServices.name}</h6>
                </div>
              </OverlayTrigger> :
              <div className="noResults-found">
                <img src={`${imageBasePath}/images/empty_service.png`} />
                <p>This Service is Currently unavailable</p>
              </div>
            }
          </div>
        </CustomScroll>
      </div>
    );
  }
  emergencyService() {
    let allServices = this.props.garageServices;
    let carWash = filter(allServices, function (service) {
      return service.serviceParentId == 4;
    });
    const carWashListView = map(carWash, (carItem, key) => {
      return (
        <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.customPopoverRight(carItem.serviceId, carItem.serviceParentId)}>
          <div className="carModelList" key={key} onClick={() => { this.setState({ 'activeCarWash': carItem.id, popoverDesc: { name: carItem.name, desc: carItem.description } }); }}>
            <div className={carItem.id == this.state.activeCarWash ? 'img-circle active' : 'img-circle'}>
              <img src={carItem.image ? carItem.image : `${imageBasePath}/images/placeholder.png`} alt="" />
            </div>
            <h6>{carItem.name}</h6>
          </div>
        </OverlayTrigger>
      );
    });

    return (
      <div className="vendor-service-container" id="n-services">
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <div className="vendor-service-list">
            {size(carWashListView)
              ?
              carWashListView :
              <div className="noResults-found">
                <img src={`${imageBasePath}/images/empty_service.png`} />
                <p>This Service is Currently unavailable</p>
              </div>
            }
          </div>
        </CustomScroll>
      </div>
    );
  }

  renderSupportedManufacturers() {
    const carManufacturersListView = map(this.props.garageManufacturers, (carItem, key) => {
      return (
        <div className="carModelList" key={key}>
          <div className="img-circle">
            <img src={carItem.photos ? carItem.photos : `${imageBasePath}/images/placeholder.png`} alt="" />
          </div>
          <h6>{carItem.name}</h6>
        </div>
      );
    });
    return (
      <div className="vendor-service-container">
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <div className="vendor-service-list">
            {size(carManufacturersListView)
              ?
              carManufacturersListView :
              <div className="noResults-found">
                <img src={`${imageBasePath}/images/empty_service.png`} />
                <p>No Manufacturers available</p>
              </div>
            }
          </div>
        </CustomScroll>
      </div>
    );
  }
  selectTab(type) {
    if (type == this.state.serviceTab) {
      this.setState({ serviceTab: "" })
    } else {
      this.setState({ serviceTab: type })
    }
  }
  render() {
    return (
      <div className="vendor-services">
        <div className="vendor-description">
          <h4>Services</h4>
          <Tabs defaultActiveKey={1} id="vendorServices" className="vendorServices_tab_mobile">
            <Tab eventKey={1} title="Car Wash">
              {this.renderCarWash()}
            </Tab>
            <Tab eventKey={2} title="Car Repair">
              {this.renderCarRepair()}
            </Tab>
            <Tab eventKey={3} title="Car Service">
              {this.renderCarService()}
            </Tab>
            <Tab eventKey={4} title="Supported Manufacturers">
              {this.renderSupportedManufacturers()}
            </Tab>
            <Tab eventKey={5} title="Roadside Assistance">
              {this.emergencyService()}
            </Tab>
          </Tabs>
          <PanelGroup defaultActiveKey="2" accordion id="vendorServices_panel">
            <ServiceTabs title="CAR WASH" renderView={this.renderCarWash()} open={this.state.serviceTab == "wash" ? true : false} tabClick={() => this.selectTab("wash")} />
            <ServiceTabs title="CAR REPAIR" renderView={this.renderCarRepair()} open={this.state.serviceTab == "repair" ? true : false} tabClick={() => this.selectTab("repair")} />
            <ServiceTabs title="CAR SERVICE" renderView={this.renderCarService()} open={this.state.serviceTab == "service" ? true : false} tabClick={() => this.selectTab("service")} />
            <ServiceTabs title="SUPPORTED MANUFACTURES" renderView={this.renderSupportedManufacturers()} open={this.state.serviceTab == "manufactures" ? true : false} tabClick={() => this.selectTab("manufactures")} />
            <ServiceTabs title="ROADSIDE ASSISTANCE" renderView={this.emergencyService()} open={this.state.serviceTab == "emergency" ? true : false} tabClick={() => this.selectTab("emergency")} />

          </PanelGroup>
        </div>
      </div>
    );
  }
}
