import React, { Component } from "react";
import { store } from "../index";

export default class Home extends Component {
  constructor(props, context) {
    super(props, context);
  }
  componentDidUpdate() {
    const { location } = this.props;
    const authReducer = store.getState().authReducer;
    if (
      authReducer.redirectUrl &&
      location.pathname == authReducer.redirectUrl
    ) {
      store.dispatch({
        type: "REDIRECT_URI",
        payload: ""
      });
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
