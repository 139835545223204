import React, { Component } from 'react';
import {appBasePath, imageBasePath} from '../../constants/staticData';
import Slider from 'react-slick';

export default class CarcilityPlusIntro extends Component {


  constructor(props, context) {
    super(props, context);
    this.state ={
      currentSlide:1
    }


  }



  render() {
    const {router, authData} = this.props;
    const settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      speed: 1000,
      slidesToShow: 1,
      draggable: false,
      swipeToSlide: false,
      touchMove: false,
      swipe: false,
      fade: true,
      arrows: false,
      pauseOnHover: false,
      autoplay: true,
      afterChange: (prev, next) => {
        let currentSlide = prev+1;



        this.setState({ currentSlide: currentSlide });
      },
    };




    return (
      <div className="plus-banner"  onClick={() => {
        this.props.router.push(appBasePath + '/plus');
      }}>
        <Slider ref="slick" {...settings} >
          <div>
            <div className="container-fluid">
              <img className="banner-clock" src={`${imageBasePath}/images/plus/banner-clock.png`} alt=""/>
              <img className="banner-currency" src={`${imageBasePath}/images/plus/banner-currency.png`} alt=""/>
              <div className="header">
                <span>Introducing</span>
                <img className="carcility-plus-logo" src={`${imageBasePath}/images/plus/carcility-plus-logo.png`} alt=""/>
                <img className="carcility-plus-dashboard-logo" src={`${imageBasePath}/images/plus/carcility-plus-dashboard-logo.png`} alt=""/><br/>
                <div className="save-text">Save upto <text style={{color:"#F48021",fontFamily: "Charm-Bold", fontSize: 15}}>50%</text> off on Car Service </div>
                <div><button className="book-button">Book Now</button></div>
              </div>
              <p className="bottom">Time is Money.Save Both.</p>
            </div>
          </div>
          <div>
            <div className="container-fluid">
              <img className=" banner-clock banner-dash2a" src={`${imageBasePath}/images/plus/dash2a.svg`} alt=""/>
              <img className="banner-currency banner-dash2b" src={`${imageBasePath}/images/plus/dash2b.svg`} alt=""/>
              <div className="header">
                <span>Introducing</span>
                <img className="carcility-plus-logo" src={`${imageBasePath}/images/plus/carcility-plus-logo.png`} alt=""/>
                <img className="carcility-plus-dashboard-logo" src={`${imageBasePath}/images/plus/carcility-plus-dashboard-logo.png`} alt=""/>
                <div className="save-text">Save upto <text style={{color:"#F48021",fontFamily: "Charm-Bold", fontSize: 15}}>50%</text> off on Car Service </div>
                <div><button className="book-button">Book Now</button></div>
              </div>
              <p className="bottom">Free Pick up and Drop</p>
            </div>
          </div>
          <div>
            <div className="container-fluid">
              <img className="banner-clock banner-dash3a" src={`${imageBasePath}/images/plus/dash3a.svg`} alt=""/>
              <img className="banner-currency banner-dash3b" src={`${imageBasePath}/images/plus/dash3b.svg`} alt=""/>
              <div className="header">
                <span>Introducing</span>
                <img className="carcility-plus-logo" src={`${imageBasePath}/images/plus/carcility-plus-logo.png`} alt=""/>
                <img className="carcility-plus-dashboard-logo" src={`${imageBasePath}/images/plus/carcility-plus-dashboard-logo.png`} alt=""/>
                <div className="save-text">Save upto <text style={{color:"#F48021",fontFamily: "Charm-Bold", fontSize: 15}}>50%</text> off on Car Service </div>
                <div><button className="book-button">Book Now</button></div>
              </div>
              <p className="bottom">Warranty Included</p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
