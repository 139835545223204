import React, { Component } from "react";
import { map, range, filter, groupBy } from "lodash";
import LightBox from "../common/LightBox";
import { imageBasePath, appBasePath } from '../../constants/staticData';

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.label = { dateTime: null, location: null };
    this.state = {
      imageStartIndex: 0,
      showModal: false,
      images: []
    };
  }
  componentWillReceiveProps(nextProps) {
    const that = this;
    if (
      nextProps.pollCountObj &&
      nextProps.pollCountObj.totalCount &&
      nextProps.requestData &&
      nextProps.requestData.images &&
      nextProps.requestData.images.length
    ) {
      if (that.props.router.params.requestId) {
        if (
          nextProps.pollCountObj.totalCount -
          nextProps.requestData.images.length
        ) {
          that.props.actions.getBookingRequest(
            that.props.router.params.requestId,
            that.props.router
          );
        }
      }
    }
  }
  closeModal() {
    this.setState({ showModal: false });
  }
  render() {
    const { requestData, pollCountObj } = this.props;
    let startDate = new Date(requestData.userPrefferedDatetime);
    if (this.props.displayname == "Active") {
      this.label = { title: "Created Date", dateTime: "Preferred Date & Time", location: "Preferred Location" };
    }
    else if (this.props.displayname == "Waiting") {
      this.label = { title: "Created Date", dateTime: "Preferred Date & Time", location: "Preferred Location" };
    } else if (this.props.displayname == "Completed") {
      this.label = { title: "Created Date", dateTime: "Completed Date & Time", location: "Location" };
    } else {
      this.label = { title: "Created Date", dateTime: "Start Date & Time", location: "Location" };
    }
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let createdDate = new Date(requestData.createdAt);
    createdDate = createdDate.getDate() + ' ' + months[createdDate.getMonth()] + ' \'' + createdDate.getFullYear().toString().substring(2) + ' ' + createdDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    startDate = startDate.getDate()
    let selectedServices = "";
    if (requestData && requestData.serviceId == 1) {
      selectedServices =
        requestData &&
        requestData.userbookingrequest_services &&
        requestData.userbookingrequest_services.map((service, key) => {
          return (
            <li key={key}>
              <span className="wash" />
              <label>{service.name}</label>
              {/* <span className="repair-sub-category">{service.name}</span> */}
            </li>
          );
        });
    } else {
      let groupedServices =
        requestData &&
        requestData.userbookingrequest_services &&
        requestData.userbookingrequest_services.length &&
        groupBy(requestData.userbookingrequest_services, "parent.name");
      selectedServices = map(groupedServices, (parentService, parentKey) => {
        let serviceNames = "";
        map(parentService, (subService, key) => {
          let comma = (parentService.length != key + 1) ? ', ' : '';
          serviceNames = serviceNames + subService.name + comma;
        });
        return (
          <li key={parentKey}>
            <span className="wash" />
            <label>{parentKey}</label>
          </li>
        );
      });
    }
    let images = filter(requestData.images, { type: "image" });
    map(images, (image, key) => {
      images[key].thumbnail = image.small;
      images[key].original = image.large;
    });
    let policeReports = filter(requestData.images, { type: "policereport" });
    map(policeReports, (image, key) => {
      policeReports[key].thumbnail = image.small;
    });
    let registrationCard = filter(requestData.images, {
      type: "registrationcard"
    });
    map(registrationCard, (image, key) => {
      registrationCard[key].thumbnail = image.small;
    });
    let drivingLicense = filter(requestData.images, { type: "drivinglicense" });
    map(drivingLicense, (image, key) => {
      drivingLicense[key].thumbnail = image.small;
    });

    let requestImages =
      images &&
      images.length &&
      images.map((image, key) => {
        if (key <= 2) {
          return (
            <li
              key={key}
              className="upload-images"
              onClick={() => {
                this.setState({
                  images: images,
                  showModal: true,
                  imageStartIndex: key
                });
              }}
            >
              <img src={image.medium} alt="" />
            </li>
          );
        } else if (key == 3) {
          if (images.length > 4) {
            return (
              <li
                key={key}
                className="upload-images"
                onClick={() => {
                  this.setState({
                    images: images,
                    showModal: true,
                    imageStartIndex: key
                  });
                }}
              >
                <img src={image.medium} alt="" />
                <label className="more-popup">
                  {"+" + (images.length - 3) + " More"}
                </label>
              </li>
            );
          } else {
            return (
              <li
                key={key}
                className="upload-images"
                onClick={() => {
                  this.setState({
                    images: images,
                    showModal: true,
                    imageStartIndex: key
                  });
                }}
              >
                <img src={image.medium} alt="" />
              </li>
            );
          }
        } else {
          return "";
        }
      });

    let placeholderImages =
      pollCountObj && pollCountObj.totalCount && images && images.length
        ? map(range(pollCountObj.totalCount - images.length), function () {
          return (
            <div className="upload-images">
              <img src={`${imageBasePath}/images/placeholder.png`} alt="" />
            </div>
          );
        })
        : "";

    return (
      <div>
        <div className="data-time col-md-12">
          <div className="col-md-4 margin-correction">
            <div className="wrapper">
              <title className="title">{this.label.title} :</title>
              <span>
                {/* {requestData &&
                  requestData.vehicles &&
                  requestData.vehicles.name
                  ? requestData.vehicles.name
                  : "Car profile not available"} */}
                {createdDate}
                
              </span>
            </div>
          </div>
          <div className="col-md-4 margin-correction">
            <div className="wrapper">
              <title className="title">{this.label.dateTime} :</title>
              <span>{startDate}</span>
            </div>
          </div>
          <div className="col-md-4 margin-correction">
            <div className="wrapper">
              <title className="title">{this.label.location} :</title>
              <span>
                {requestData && requestData.location
                  ? requestData.location
                  : "No location found"}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-12 repair-detail n-job-details">
          <div className="col-md-6 repair-category emergency">
            <title>Emergency Categories:</title>
            <ul className="category-list emergency-categories">
              <li>
                <img src={`${imageBasePath}/images/auto-service-icons-4.png`} />
                <span>Battery Boost</span>
              </li>
            </ul>
          </div>

        </div>

        <LightBox
          images={this.state.images}
          imageStartIndex={this.state.imageStartIndex}
          showModal={this.state.showModal}
          isEditable={false}
          closeModal={this.closeModal.bind(this)}
        />
      </div>
    );
  }
}

export default JobDetails;
