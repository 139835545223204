import React, { Component } from 'react';
import { Accordion, Panel } from 'react-bootstrap';
import map  from 'lodash/map';
class SummaryCollpasePanel extends Component {
    constructor() {
        super()
        this.state = {
            open: false
        }
    }
    render() {
        return (
            <Panel collapsible expanded={this.state.open} onSelect={() => this.setState({ open: !this.state.open })} header={
                <span>
                    <img src={this.props.subCat.image}  className="sub-img"/>
                    <label>{this.props.subCat.name}</label>
                    <span>{"(" +this.props.selectedChkBoxCount+ ")"}</span>
                    <i className={this.state.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
                </span>
            }>
                <ul>
                    {map(this.props.subCat.sub_services, (subCat,key) => {
                        if (subCat.checked) {
                            return (
                                <li key={key}>{subCat.name}</li>
                            )
                        }

                    })}
                </ul>
            </Panel>
        );
    }
}

export default SummaryCollpasePanel;
