import React, { Component } from 'react';
import SelectDropdown from '../../common/SelectDropdown'
import Button from '../../common/Button';
import { imageBasePath } from '../../../constants/staticData';

class GfCarService extends Component {
    constructor(props) {
        super(props);
        this.state = {
          carServiceCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.serviceCategoryData ? this.props.bookingRequestReducer.serviceCategoryData : [],
          filteredCarServiceCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.serviceCategoryData ? this.props.bookingRequestReducer.serviceCategoryData : [],
          selectedServiceId:[],
          selectedService:null,
          servicesFetched: props.bookingRequestReducer.servicesFetched ? true : false,
        };
    }
    componentWillReceiveProps(nextProps){
      if (nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData && this.state.carServiceCategories && !this.state.carServiceCategories.length && this.state.filteredCarServiceCategories && !this.state.filteredCarServiceCategories.length && nextProps.bookingRequestReducer.servicesFetched) {
        this.setState({
          ...this.state,
          carServiceCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData,
          filteredCarServiceCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData,
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      }
    }

    select(carProfile) {
      this.setState({
        ...this.state,
        selectedServiceId: [carProfile.id],
        selectedService: carProfile,
      });
    }

    render() {
        return (
            <div className="sub-collapse-panel wash-collapse" >
                <span className="checkbox-style checkbox-wrapper ">
                  <span className="service-card-holder n-service-holder">
                    <figure >
                      <img src={`${imageBasePath}/images/carservice.png`} alt="" />
                    </figure>
                    <div className="sub-collapse-panel-head ">
                      <h4 className="card-name">Car Service</h4>
                      <i className="mdi mdi-information-outline"></i>
                      <div className="dropdown">
                      <SelectDropdown customPlaceholder="Enter Approx Mileage" data={this.state.filteredCarServiceCategories} select={this.select.bind(this)} />
                      </div>
                    </div>
                  </span>
                </span>
                {<div className="next-button">
                  <Button dataLabelledby="Select Service Next" disabled={this.state.selectedServiceId ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next"  btnCallBack={() => { this.props && this.props.formValidation(); this.props.getCarServiceData(this.state.selectedService) ;this.props.toggleNextButton('panel3',true); this.props ; this.props.panel2Valid();}} />
                </div>}
            </div>
        );
    }
}

export default GfCarService;
