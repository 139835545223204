import React, { Component } from 'react';
import { DropdownButton, MenuItem, Media } from 'react-bootstrap';
import map  from 'lodash/map';
import {appBasePath, imageBasePath} from '../../constants/staticData';

export default class ProvidersView extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentWillMount() {

  }
  componentWillReceiveProps(nextProps) {

  }
  render() {
    const { providers } = this.props;


    const notificationsView = map(providers, (provider, key) => {

      return (
        <MenuItem style={{ 'backgroundColor': '#FFFFFF', 'cursor': 'pointer' }} eventKey={key} key={key} onClick={(e) => {

          this.props.router.push(appBasePath + '/vendor-profile/' +provider.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase());


          e.preventDefault();

        }}>
          <Media>
            <Media.Left>
              <div className="notification-img">
                <img src={provider.profileImage? provider.profileImage : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="BMW car repair" />

              </div>
            </Media.Left>
            <Media.Body>
              <Media.Heading>

                {provider.companyprofilename}
              </Media.Heading>
              <span className="notify-time"> {provider.companyprofilename} has sent you an Offer</span>
            </Media.Body>
          </Media>
        </MenuItem>
      )
    });
    return (

      <div className={this.state.showNotificationBar ? "notification-menu desktop-view active" : "notification-menu desktop-view"}>

        <div className="text-dropdown notify" >
          <DropdownButton open={this.state.showNotificationBar} bsSize="large" id="dropdown-size-large" noCaret onToggle={() => { this.setState({ 'showNotificationBar': !this.state.showNotificationBar }); }} title={
            <span className="">
              {this.state.showNotificationBar && <i className="mdi mdi-chevron-up" />}
              {!this.state.showNotificationBar && <i className="mdi mdi-chevron-down" />}

            </span>} >

            <MenuItem eventKey="Notifications">
              Providers
            </MenuItem>
            <div className="desk-notify">
              {notificationsView}
            </div>

          </DropdownButton>
        </div>
      </div>
    );
  }
}
