import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class WelcomeText extends Component {


  constructor(props, context) {
    super(props, context);



  }


  render() {
      const {router, authData} = this.props;

      let redirectPath = appBasePath + this.props.promotionalOffersData[0].services[0].parentId == 1 ? 'car-wash': this.props.promotionalOffersData[0].services[0].parentId == 2 ? 'car-service': 'car-repair';
      let redirectPathSearch = "";
      if(authData && !authData.hasVehicleProfile){
        redirectPathSearch = "?redirect_to="+redirectPath;
        redirectPath = "/car-profiles/create";
      }

      return (
           <div className="welcome-banner">
               <div className="container-fluid">
                   <div className="col-md-8 col-sm-8 col-xs-12">
                        <h4>Hi <span>{authData ? authData.name : ''} !</span></h4>
                        <p>
                            It’s great to see that you have signed up with Carcility.
                        </p>
                     <h3>{this.props.promotionalOffersData[0].description}</h3>
                     <Button btnType="transparent" fontSize={16} label="BOOK NOW" btnCallBack={() =>



                       router.push({
                           pathname: redirectPath,
                           search: redirectPathSearch,
                           state: { selectedPromotionalOffersData: this.props.promotionalOffersData[0] }
                         }
                       )
                     } />

                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <img className="welcome-img" src={`${imageBasePath}/images/welcome.png`} alt=""/>
                    </div>
                </div>
           </div>
        );
    }
}
