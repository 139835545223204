import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import moment from "moment";
import Arrow from './Arrow';


export default class Offers extends Component {

  constructor(props, context) {
    super(props, context);


  }
  componentDidMount() { setTimeout(() => {window.dispatchEvent(new Event('resize')) }, 6000); }



  onOfferClick(parentId,index){

    if(this.props.isAuthenticated) {


        this.props.router.push({
            pathname: appBasePath +"offers/"+this.props.promotionalOffersData[index].id,
            state: {selectedPromotionalOffersData: this.props.promotionalOffersData[index]},
          }
        );





    }else{

      this.props.router.push({
          pathname: appBasePath +"all-offers/"+this.props.promotionalOffersData[index].id,
          state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
        }
      );

    }




  }
  onOfferBookClick(parentId,index,redirectUrl){

    console.log("this.props.authData.hasVehicleProfile",this.props.authData.hasVehicleProfile)
    if(this.props.isAuthenticated) {

      if(this.props.authData && this.props.authData.hasVehicleProfile){
        this.props.router.push({
            pathname: appBasePath + parentId == 1 ? 'car-wash': parentId == 2 ? 'car-service': 'car-repair',
            state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
          }
        );
      }else{

        localStorage.setItem('selectedPromotionalOffersData',JSON.stringify(this.props.promotionalOffersData[index]));
        this.props.router.push(`${appBasePath}/car-profiles/create?redirect_to=${redirectUrl}`)

      }


    }else{
      this.props.router.push({
          pathname: appBasePath +"all-offers/"+this.props.promotionalOffersData[index].id+"/book-now",
          state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
        }
      );
    }

  }


  render() {


    const { promotionalOffersData , router, title, viewAll, showPromoCode, isAuthenticated }   = this.props;

    const settings = {
      dots: showPromoCode? true: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      responsive: [
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 900,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1067,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 1400,
          settings: { slidesToShow: 3 }
        },
      ],
     // arrows:true
    };



    let promoCodesHtml =  promotionalOffersData.map((promoCode, index) => {


      let redirectUrl =  promoCode.services.length && promoCode.services[0].parentId == 1 ? 'car-wash': promoCode.services.length && promoCode.services[0].parentId == 2 ? 'car-service': 'car-repair';



      let days = moment(promoCode.validTo).diff(moment(new Date()),'days');

      let promoCodeText = promoCode.promoCode;
      if(promoCodeText.length > 10) promoCodeText = promoCodeText.substring(0,10);


      return (
        <div className="offer-card" key={index}  >
          <div className="offer-body">
            <img className="offer-cover" onClick={() => {  this.onOfferClick(promoCode.services[0].parentId,index)  }}  src={  promoCode.images && promoCode.images.length ?  promoCode.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="" />


            {promoCode.discountPercentage ?
              promoCode.discountPercentage == 100 ?
                <div className=" price free-price" onClick={() => {  this.onOfferClick(promoCode.services[0].parentId,index)  }} >

                  <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                  <div className="price-content">

                    <span className="amount">Free</span>
                  </div>
                </div>
                :
                <div className=" price" onClick={() => {  this.onOfferClick(promoCode.services[0].parentId,index) }} >

                  <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                  <div className="price-content">

                    <span className="amount">{promoCode.discountPercentage }%</span>
                    <span className="type">OFF</span>
                  </div>
                </div>
              :
              <div className=" price" onClick={() => {  this.onOfferClick(promoCode.services[0].parentId,index) }} >

                <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                <div className="price-content discount-amount-content">

                  <span className="discount-amount">{Number(promoCode.discountAmount).toFixed(2)}</span>
                  <span className="discount-currency">AED </span>
                  <span className="discount-type">OFF</span>
                </div>
              </div>

            }




            <div className="description col-md-6 col-sm-6 col-xs-6">

              <div onClick={() => {  this.onOfferClick(promoCode.services[0].parentId,index)  }} >

                <span className="garage">{promoCode.garages.length ? "Service Providers (" + (promoCode.garages.length) + ")" : ""}</span>
                <span className="title">{promoCode.offerName}</span>
                <span className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon.png` }  /> {days} Days Left</span>
              </div>

              {showPromoCode &&
                <div className="offer-promo-code">
                  Promo Code <span className="promo">{promoCodeText}</span>
                </div>
              }

              {promoCode.totalAmount ?
                <div className="offer-price">

                  {promoCode.discountPercentage ?
                    promoCode.discountPercentage == 100 ?
                      <span className="amount">Free</span>
                      :
                      <span className="amount">{Number(promoCode.discountedAmount).toFixed(2)} AED</span>
                    :
                    <span className="amount">{Number(promoCode.discountedAmount).toFixed(2)} AED</span>
                  }
                  <span className="original">{Number(promoCode.totalAmount).toFixed(2)} AED</span>
                </div>
                :
                <div className="offer-price">
                </div>
              }

              <span className="book"  onClick={(e)=> this.onOfferBookClick(promoCode.services[0].parentId,index,redirectUrl) && e.preventDefault()} >Book Now</span>
            </div>
          </div>
        </div>
      );
    });



    return (
      <div className="row" >
        <div className="offer-row" id="deals-section">
          <div className="offer-heading">
            <h5>{title}</h5>
            { viewAll && <span onClick={() => { router.push(`${appBasePath}/offers`) }}>View All</span> }
          </div>
          <div className="col-md-12  offer-layout">
            {promoCodesHtml ?
              <Slider ref="slick" {...settings} >
                {promoCodesHtml}
              </Slider>
            :""}
          </div>
        </div>
      </div>
    );
  }
}

/*


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", }}
      onClick={onClick}
    >
      <img className="amount-cover " src={`${imageBasePath}/images/next-arrow.png` } alt="" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", }}
      onClick={onClick}
    >
      <img className="amount-cover " src={`${imageBasePath}/images/prev-arrow.png` } alt="" />
    </div>
  );
}
*/
