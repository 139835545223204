import React, { Component } from 'react';
import { imageBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import { Helmet } from "react-helmet";

export default class Header extends Component {
  render() {
    const { headerClickAction, pageType, metaTagKey } = this.props;
    return (
      <div className="header-section navbar-fixed-top auth-header">
        {metaTagData ? <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData[metaTagKey] && metaTagData[metaTagKey].title ? metaTagData[metaTagKey].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData[metaTagKey] && metaTagData[metaTagKey].description ? metaTagData[metaTagKey].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet> : ''}
        <div className="logo-section desk-logo" onClick={(e) => { e.preventDefault(); window.location.pathname = '/'; }}>
          <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="logo-img" />
        </div>
        <div className="logo-section mobile-logo" onClick={(e) => { e.preventDefault(); window.location.pathname = '/'; }}>
          <img src={`${imageBasePath}/images/logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="logo-img" />
        </div>
        <div className="header-right authNav-right">
          <ul className="list-unstyled">
            {pageType == "signIn" && <li>
              <label>
                Don’t have an account?
                <a onClick={headerClickAction}>Sign Up</a>
              </label>
            </li>}
            {pageType == "signUp" && <li>
              <label>
                Already have an account?
                <a onClick={headerClickAction}>Sign In</a>
              </label>
            </li>}
            {pageType == "verifyOtp" && <li>
              <label>
                <a  onClick={() => {this.props.actions.logout(this.props.router)}} >Sign Out</a>
              </label>
            </li>}
          </ul>
        </div>
      </div>
    );
  }
}
