import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../actions/';
import { appBasePath } from '../constants/staticData';

export default function DashboardContainer(ComposedComponent) {
  class DashboardContainer extends Component {
    constructor(props) {
      super(props);
    }
    componentWillUpdate(nextProps) {
      if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.currentComponentKey) {
        nextProps.router.push(appBasePath + nextProps.bookingRequestReducer.currentComponentKey);
      }
      if (nextProps.commonReducer && nextProps.commonReducer.currentComponentKey) {
        nextProps.router.push(appBasePath + nextProps.commonReducer.currentComponentKey);
        
      }
    }
    
    render() {
     // console.log(this.props.bookingRequestReducer+"home")
      return <ComposedComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return { authReducer: state.authReducer, dashboardReducer: state.dashboardReducer, bookingRequestReducer: state.bookingRequestReducer, commonReducer: state.commonReducer };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ActionCreators, dispatch)
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
}

DashboardContainer.contextTypes = {
  router: React.PropTypes.object
};

