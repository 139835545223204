import React, { Component } from 'react';
import '../../styles/login.css';
import { imageBasePath } from '../../constants/staticData';

export default class CarouselSlider extends Component {

    render() {
        return (
            <div className="col-md-6 col-sm-6 col-xs-12 pad0">
                <div className="login-banner">
                    {/*<Carousel interval={0}>
                        <Carousel.Item>
                            <img src={this.props.sliderImage ? this.props.sliderImage : `${imageBasePath}/images/signIn-banner.png`} alt="" />
                            <Carousel.Caption>
                              {this.props.pageType ? 'Car care simplified. Book with confidence!' : 'Lets get started.'}
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>*/}
                    <img src={this.props.sliderImage ? this.props.sliderImage : `${imageBasePath}/images/signIn-banner.png`} alt={this.props.sliderImageAlt?this.props.sliderImageAlt:""} />
                    <div className="login-banner-content">
                    <h5>{this.props.pageType != 'signIn' ? 'Join Carcility and start booking your car service with confidence' : 'Welcome Back!'}</h5>
                    <p>{this.props.pageType != 'signIn' ? '' : 'Get the best car repair service from verified auto workshops.'}</p>
                    {this.props.pageType != 'signIn' && <ul className="list-unstyled">
                        <li>
                            <img src={`${imageBasePath}/images/tick_dark.png`} className="tick-img" />
                            <span className="points">Instant car repair estimate and quotes from only verified shops</span>
                        </li>
                        <li>
                            <img src={`${imageBasePath}/images/tick_dark.png`} className="tick-img" />
                            <span className="points">Seamless online booking</span>
                        </li>
                        <li>
                            <img src={`${imageBasePath}/images/tick_dark.png`} className="tick-img" />
                            <span className="points">Fast and smooth car care experience</span>
                        </li>
                      </ul>}
                    </div>
                </div>
            </div>
        );
    }
}
