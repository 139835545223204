import React, { Component } from 'react';
import find from 'lodash/find';
import round from 'lodash/round';

import { imageBasePath } from '../../constants/staticData';

export default class CoverPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile:false
    };
  }
    componentWillMount(){
      window.addEventListener('scroll', this.scrollPage.bind(this));

      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
    componentWillUnmount() {
      window.addEventListener('scroll', this.scrollPage.bind(this));
    }
  scrollPage(){
    // if(document.getElementsByClassName('cover-photo') && document.getElementsByClassName('cover-photo')[0]){
    // document.getElementsByClassName('cover-photo')[0].style.opacity =  (1 - window.pageYOffset / 700);
    // }
  }

  resize() {
    this.setState({isMobile: window.innerWidth <= 760});
  }
  render() {

    const { garageProfileDetails, actions } = this.props;
    let coverImg = '';
    if (garageProfileDetails && garageProfileDetails.images && garageProfileDetails.images.length) {
      coverImg = find(garageProfileDetails.images, function (image) {
        return image.isCover == true;
      });
    }
    let avgCount = garageProfileDetails.reviews && garageProfileDetails.reviews.overview && garageProfileDetails.reviews.overview.avgRating ? round(garageProfileDetails.reviews.overview.avgRating) : '';
    let style={
        background: this.state.isMobile? coverImg && coverImg.small ? `url("${coverImg.small}")`: `${imageBasePath}/images/placeholder.png` : coverImg && coverImg.medium ? `url("${coverImg.medium}")`: `${imageBasePath}/images/placeholder.png`,
    }
    return (
      <div className="vendor-cover">
        <div className="cover-photo">
        <img className="cover-photo__img" style={style} />
        </div>
        <div className="vendor-profile-pic upload-pic">

          <img src={garageProfileDetails.logo ? garageProfileDetails.logo : `${imageBasePath}/images/placeholder.png`} alt="logo" />
          {/* <a href="" className="profile-pic-upload">Upload Photo</a> */}
        </div>
        <div className="vendor-cover-header">
          <div className="rating rating-left">
            <span className={avgCount > 0 && avgCount < 1 && avgCount != 1 ? "mdi mdi-star-half" : avgCount >= 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
            <span className={avgCount > 1 && avgCount < 2 && avgCount != 2 ? "mdi mdi-star-half" : avgCount >= 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
            <span className={avgCount > 2 && avgCount < 3 && avgCount != 3 ? "mdi mdi-star-half" : avgCount >= 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
            <span className={avgCount > 3 && avgCount < 4 && avgCount != 4 ? "mdi mdi-star-half" : avgCount >= 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
            <span className={avgCount > 4 && avgCount < 5 && avgCount != 5 ? "mdi mdi-star-half" : avgCount >= 5 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
          </div>
          <h1>{garageProfileDetails.companyprofilename}</h1>
          <span>{garageProfileDetails.displaytext}</span>
          <div className="vendor-share-profile">
            {localStorage.getItem('authData') ? <i className={garageProfileDetails.isfavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"} onClick={(e) => { e.preventDefault(); actions.changeFavouriteSelection(garageProfileDetails.id, !garageProfileDetails.isfavourite, 'vendorProfile'); }} /> : ''}
            <i className="mdi mdi-share-variant" />
          </div>
        </div>
      </div>
    );
  }
}
