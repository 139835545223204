import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class PageLoader extends Component {
    render() {
        return (
          <div className="page-loader">
              <div className="loader-image">
                <img src={`${imageBasePath}/images/carcility-loader.gif`} alt="Carcility loader"/>
              </div>
          </div>
        );
    }
}
