import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import WashSteps from './WashSteps';
import each  from 'lodash/each';
import findIndex  from 'lodash/findIndex';
import cloneDeep  from 'lodash/cloneDeep';
import remove  from 'lodash/remove';
import map  from 'lodash/map';
import size  from 'lodash/size';
import { queryStringtoArray } from '../../helpers/index';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { imageBasePath } from '../../constants/staticData';

export default class CarWash extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      infoModalData: undefined,
      infoModal: false,
      notificationVisible: false,
      editClicked: false,
      messageVisible: false,
      getCarWashData: this.props.bookingRequestReducer && this.props.bookingRequestReducer.washCategoryData ? this.props.bookingRequestReducer.washCategoryData : [],
      searchGetCarWashData: this.props.bookingRequestReducer && this.props.bookingRequestReducer.washCategoryData ? this.props.bookingRequestReducer.washCategoryData : [],
      categoriesSelectedCount: 0,
      selectedCarWashDataArray: [],
      selectedCarProfile: undefined,
      promotionalOffersData: [],
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      garageProfileDetails: '',
      showError: undefined,
      servicesFetched: props.bookingRequestReducer.servicesFetched ? true: false,
      offerFromCreateCarProfile: this.props.location  && this.props.location.state && this.props.location.state.offerFromCreateCarProfile ? this.props.location.state.offerFromCreateCarProfile : false,
    };
  }

  componentWillMount() {
    this.props.actions.hideToastMessage();
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }
    if (queryStringtoArray('vendor_id')) {
      this.props.actions.getGarageProfileDetails(queryStringtoArray('vendor_id'));
    }
    if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
      console.log('💔 💔 💔 0 ', this.props.commonReducer.promotionalOffersData);
      this.props.actions.getPromotionalOffers();
    }
  }

  componentWillReceiveProps(nextProps) {
    let selectedCarProfile = undefined;
    if (queryStringtoArray('car_id')) {
      map(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles, (carProfile) => {
        if (queryStringtoArray('car_id') == carProfile.id) {
          selectedCarProfile = carProfile;
        }
      });
      this.setState({
        ...this.state,
        selectedCarProfile: selectedCarProfile,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCarProfile: nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles && nextProps.carProfileReducer.carProfiles[Object.keys(nextProps.carProfileReducer.carProfiles)[0]],
      });
    }

    let getVendorsCarWashData = [];
    if (!queryStringtoArray('vendor_id') && nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.getCarWashData && !this.state.getCarWashData.length && this.state.searchGetCarWashData && !this.state.searchGetCarWashData.length) {
      this.setState({
        ...this.state,
        getCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        searchGetCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   //<ToastMessage show={this.state.showToast}/>
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    // }
    else if (queryStringtoArray('vendor_id') && nextProps && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services && nextProps.bookingRequestReducer.servicesFetched) {
      if(nextProps.commonReducer.garageProfileDetails.garage_services.length == 0){
        this.setState({
          ...this.state,
          getCarWashData: [],
          searchGetCarWashData: [],
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      }else{
        nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData && nextProps.bookingRequestReducer.washCategoryData.map((allSubService, key) => {
          nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
            if (allSubService.id === vendorSubService.serviceId) {
              getVendorsCarWashData.push(allSubService);
            }
          });
        });
        //if (getVendorsCarWashData.length) {
          this.setState({
            ...this.state,
            getCarWashData: getVendorsCarWashData,
            searchGetCarWashData: getVendorsCarWashData,
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        //}
      }
      if (queryStringtoArray('serviceId')) {
        this.changeCheckboxState({ target: { checked: true } }, Number(queryStringtoArray('serviceId')));
      }
    }

    if(! this.state.offerFromCreateCarProfile){
      this.setState({
        promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
        selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      });
    }


    let promoCategories = [];
    this.state.selectedPromotionalOffersData && each(this.state.selectedPromotionalOffersData.services, (promoCategory) => {
     promoCategories.push(promoCategory.serviceId);
    //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);

    });
    console.log('promoCategories --->',promoCategories);
    promoCategories && promoCategories.length && this.setState({
      selectedCarWashDataArray: promoCategories,
      categoriesSelectedCount: promoCategories.length,

    });


  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails,
    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible});
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  renderCarType(carDetails, key) {
    if (carDetails) {

      let checked = false;


      if(this.state.selectedCarWashDataArray && this.state.selectedCarWashDataArray.includes(carDetails.id)){
        checked = true;
      }

      return (
        <div className="sub-collapse-panel wash-collapse" key={key}>
          <span className="checkbox-style checkbox-wrapper">
            <label htmlFor={carDetails.id}>
              <figure onClick={(e) => { this.showModal(e, carDetails); }}>
                <img src={carDetails.image ? carDetails.image : `${imageBasePath}/images/placeholder.png`} alt="" />
              </figure>
              <div className={carDetails.id == this.state.visibleCategory ? "sub-collapse-panel-head active" : "sub-collapse-panel-head "} >
                <h4>{carDetails.name}</h4>
                <div className="select-type">
                  <input type="checkbox" id={carDetails.id} checked={checked ? checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id); }} value="" />
                  <label htmlFor={carDetails.id} className="custom-checkbox"></label>
                </div>
              </div>
            </label>
          </span>
        </div>
      );
    }
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile,
    });
  }

  changeCheckboxState(e, categoryId) {

    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const filteredCarWashCategories = cloneDeep(this.state.getCarWashData);
    let findCategoryIndex = findIndex(filteredCarWashCategories, { id: categoryId });
    filteredCarWashCategories[findCategoryIndex]['checked'] = isChecked;
    if (isChecked) {
      if(!this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarWashDataArray.push(filteredCarWashCategories[findCategoryIndex].id);
      }
    } else {
      if(this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarWashDataArray, (n) => {
          return n != filteredCarWashCategories[findCategoryIndex].id;
        });
      }
    }
    if (!removedCategoriesArray) {
      removedCategoriesArray = this.state.selectedCarWashDataArray;
    }
    this.setState({
      ...this.state,
      selectedCarWashDataArray: removedCategoriesArray,
      categoriesSelectedCount: count,
      searchGetCarWashData: filteredCarWashCategories,
      getCarWashData: filteredCarWashCategories,
    });
  }

  //******* Search Categories **********************************************************************************************************************************/
  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.getCarWashData);
      let filteredCarWashCategories = [];
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          filteredCarWashCategories.push(carDetails);
        }
      });
      this.setState({
        ...this.state,
        searchGetCarWashData: filteredCarWashCategories,
      });
    } else {
      this.setState({
        ...this.state,
        searchGetCarWashData: cloneDeep(this.state.getCarWashData)
      });
    }
  }

  disableEdit(){
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  //********************************************************************************************************************************************************** */
  render() {
    const { commonReducer, actions, router,bookingRequestReducer } = this.props;


    let isCoverImage = "";
    this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        isCoverImage =  (
          <div className="type-img car-img" key={key}>
            <img src={value.medium} alt="" />
          </div>
        );
      }
    });

    if(! isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length){
      this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
        if (value.type == "vehicle") {
          isCoverImage =  (
            <div className="type-img car-img" key={key}>
              <img src={value.medium} alt="" />
            </div>
          );
        }
      });
    }



    let leftBlock = [];
    let catLength = 0;


    this.state.searchGetCarWashData  && this.state.searchGetCarWashData.length > 0 && each(this.state.searchGetCarWashData, (carDetails) => {



          catLength += 1;
        leftBlock.push(this.renderCarType(carDetails, catLength));
      });






    const selectedCarWashData = this.state.getCarWashData && this.state.getCarWashData.map((carDetails, key) => {


      let checked = false;
      if(this.state.selectedCarWashDataArray && this.state.selectedCarWashDataArray.includes(carDetails.id)){
        checked = true;
      }

      if (checked) {
        return (
          <li key={key}>
            <label>{carDetails.name}</label>
          </li>
        );
      }
    });

    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Car Wash</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                {this.state.getCarWashData && <div className="myCar-body col-md-8">
                  <WashSteps
                    commonReducer = {commonReducer}
                    actions = {actions}
                    servicesFetched = {this.state.servicesFetched}
                    showError = {this.props.bookingRequestReducer.toastType}
                    categoriesSelectedCount= {this.state.categoriesSelectedCount}
                    router = {this.props.router}
                    selectedCarProfile = {this.state.selectedCarProfile}
                    carProfiles = {this.props.carProfileReducer.carProfiles}
                    selectedCarWashDataArray = {this.state.selectedCarWashDataArray}
                    promotionalOffersData = {this.state.promotionalOffersData}
                    selectedPromotionalOffersData = {this.state.selectedPromotionalOffersData}
                    viewBlock = {leftBlock}
                    searchView = {this.filterCarType.bind(this)}
                    editClicked = {this.state.editClicked}
                    disableEdit = {this.disableEdit.bind(this)}
                    changeCarProfile = {this.changeCarProfile.bind(this)}
                    garageProfileDetails = {queryStringtoArray('vendor_id') && this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                  />
                </div>}
                <div className="myCar-body col-md-4">
                  <div className="car-summary">
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img className="cards" src={`${imageBasePath}/images/plus/wash-card.svg`} alt="" />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Wash</label>
                        </div>
                      </div>
                    </div>
                    <div className="category-wrapper">
                      <div className="category-heading">
                        <h5>Categories Selected</h5>
                        {this.state.categoriesSelectedCount > 0 ?
                          <label>
                            <a onClick={() =>
                            this.setState({
                              ...this.state,
                              editClicked: true
                            })}>Edit</a>
                          </label>
                          :
                          []}
                      </div>
                      <div className="category-list">
                        <ul>
                          {selectedCarWashData}
                        </ul>
                      </div>
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          {this.state.selectedCarProfile.images.length ?
                            isCoverImage
                            :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    {!queryStringtoArray('vendor_id') ? <div className="car-wrapper">
                      <div className="type-card">
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.logo ?
                             <div className="type-img car-img">
                              <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                             </div> :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>}
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false,}) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
