import { combineReducers } from 'redux';
import dashboardReducer from './dashboardReducer';
import authReducer from './authReducer';
import carProfileReducer from './carProfileReducer';
import bookingRequestReducer from './bookingRequestReducer';
import useraccountReducer from './useraccountReducer';
import commonReducer from './commonReducer';
import { routerReducer } from 'react-router-redux';

const appReducer = combineReducers({
  dashboardReducer,
  authReducer,
  routing: routerReducer,
  carProfileReducer,
  bookingRequestReducer,
  useraccountReducer,
  commonReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    const { routing } = state;
    state = { routing };
  }
  return appReducer(state, action);
};
export default rootReducer;
