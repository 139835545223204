import React, { Component }  from 'react';
import { imageBasePath } from '../../constants/staticData';


export default class HowWorks extends Component {
render(){
    return(
      
        <div className="common-wrapper" id="howworks-section">
        {/*<Element name="how-works" className="element"></Element> */}
        <div className="container">
            <div className="howworks-section-content">
              <div className="common-heading">
                <span>How It Works?</span>
              </div>
              <div className="howworks-section-text">An easy step by step way of getting your car repair done. Select the best car service
                center online, get quotes and book an appointment as per your time.
              </div>
            </div>
            <p className="ps-line-landing"></p>
            <div className="row common-why-section">
                <div className="col-md-4 col-sm-4 col-xs-4 text-center  wow slideInUp">
                    <div className="advantage-list">
                    <div className="how-Num">
                    <div className="how-one"></div>
                          {/*<img src={`${imageBasePath}/images/how-no-1.png`} alt="choose car service" />*/}
                        </div>
                        <div className="how-owl">
                          <img src={`${imageBasePath}/images/how-owl-img.svg`} alt="choose car service" />
                        </div>
                        <div className="al-image-holder al-image-holder-align">
                        <div className="how-service" width="1" height="1"></div>
                        {/*<img src={`${imageBasePath}/images/landing/how-itworks-service.svg`} alt="book car service in dubai" />*/}
                        </div>
                        <h5 >Choose your service</h5>
                        <p className="land-mob-hide">Select your car model, tell us what you need for your car.</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4 text-center  wow slideInUp">
                <div className="advantage-list">
                 <div className="how-Num">
                 <div className="how-two"></div>
                    {/*<img src={`${imageBasePath}/images/how-no-2.png`} alt="choose car service" />*/}
                 </div>
                 <div className="how-owl">
                    <img src={`${imageBasePath}/images/how-owl-img.svg`} alt="choose car service" />
                 </div>
                    <div className="al-image-holder al-image-holder-align">
                    <div className="how-time"></div>
                    {/*<img src={`${imageBasePath}/images/landing/how-itworks-time.svg`} alt="instant car service booking" />*/}
                    </div>
                    <h5 >Receive your quotes </h5>
                    <p className="land-mob-hide">Sit back and watch the quotes for your request start rolling in. Compare quotes and book time at your selected shop based on availability, price or rating.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4  text-center  wow slideInUp">
                    <div className="advantage-list">
                      <div className="how-Num">
                      <div className="how-three"></div>
                         {/*<img src={`${imageBasePath}/images/how-no-3.png`} alt="choose car service" />*/}
                       </div>
                        <div className="how-owl">
                          <img src={`${imageBasePath}/images/how-owl-img.svg`} alt="choose car service" />
                        </div>
                      <div className="al-image-holder last-image-holder-align last-image-holder-align-how">
                      <div className="how-car"></div>
                        {/*<img src={`${imageBasePath}/images/landing/how-itworks-car.svg`} alt="trusted car booking services" />*/}
                        </div>
                        <h5 > Get your job done </h5>
                        <p className="land-mob-hide">Search. Stop calling and visit more shops. No hassles !</p>
                    </div>
                    </div>
            </div>
            </div>
        </div>
    )
}
}