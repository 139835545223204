import React, { Component }  from 'react';


export default class ValuePara extends Component {
  constructor(props) {
    super(props);
    this.state= {
      readMore: false
    }
  }

   render(){
    const baseUrl = location.origin;
    return(
        <div className="busineesFooterSection businessFooterpad ">
          <div className="businessfooter-section">
            <div className="container">
              <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                <h1 className="businessfooter-section">Best value car service in Dubai</h1>
                <h5 className="businessfooter-section">Owning a car is easy but car maintenance is a tough task.
                  Especially, when you don’t know which service provider to go-to 
                  for a specific service. Carcility comes in as a one-stop
                  solution for choosing the right service for your car from a
                  plethora of options with quotes.
                  {(this.state.readMore ||  this.props.innerWidth > 729) && 
                    <span>
                      <span>
                        Finding the right car service
                        center in Dubai for your vehicle is no more a day’s task.
                        From car repair services to car detailing and car wash,
                        we can connect you to trusted car workshops and garages in just one click.
                        So, what are you waiting for?
                        <a href={`${baseUrl}/sign-up`} className="blue-text"> Create a FREE account at Carcility. </a>
                        Car service providers can also list their
                        <a href={`${baseUrl}/business/sign-up`} className="blue-text"> car care business on
                          Carcility. </a> Apart from helping customers to find
                        right car maintenance services, Carcility has a
                        <a href={`${baseUrl}/blog`} className="blue-text"> good automotive Blog section. </a> You can browse
                        through the posts and stay updated about car related
                        technologies, car maintenance tips and automotive industry news.</span>
                </span>}
                </h5>
                {(!this.state.readMore && this.props.innerWidth < 728) && <div className="read-more-less-text" onClick={()=>this.setState({readMore:true})}>Read More</div>}
              </div>
            </div>
          </div>
        </div>
    )
  }

}