import React, { Component } from 'react';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import map  from 'lodash/map';
import lowerCase  from 'lodash/lowerCase';
import { imageBasePath } from '../../constants/staticData';

class WorkLogModal extends Component {
  constructor() {
    super();
  }
  render() {
    const { serviceStatuses, showModal, hideModal } = this.props;
    return (
      <CustomModal showModal={showModal} title="Work Log" className="worklog-modal" closeIcon={true} hideModal={hideModal}>
        <Modal.Body>
          {map(serviceStatuses, (service, key) => {
            let status = service.worklog && service.worklog.length && service.worklog[service.worklog.length - 1];
            let lowerCaseStatus = status.jobstatus == 'In Progress' ? 'inprogress' : lowerCase(status.jobstatus);
            let updatedAt = new Date(status.createdAt);
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            updatedAt = updatedAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' on ' + months[updatedAt.getMonth()] + ' ' + updatedAt.getDate() + ' ' + updatedAt.getFullYear();
            // if (lowerCaseStatus != 'pending') {
            return (
              <div className="card-holder" key={key}>
                <span className={lowerCaseStatus ? lowerCaseStatus + " dot" : "dot"}></span>
                <div className="image-holder">
                  <img src={service.serviceImage ? service.serviceImage : service.parent && service.parent.image ? service.parent.image : service.parentId === 2 ? `${imageBasePath}/images/carservice.png` : `${imageBasePath}/images/placeholder.png`} alt="" className="image" />
                </div>
                <div className="text-holder">
                  <h5 className={lowerCaseStatus ? lowerCaseStatus + " worklogstatus" : "worklogstatus"}>{status.jobstatus}</h5>
                  <span className="type">{service.serviceName}</span>
                  <span className="time">{updatedAt}</span>
                </div>
              </div>
            );
            // }
          })}


        </Modal.Body>
      </CustomModal>
    );
  }
}

export default WorkLogModal;
