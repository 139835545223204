import React, { Component } from "react";
import PlusSidebar from './PlusSlidebar';
import map  from 'lodash/map';
import orderBy  from 'lodash/orderBy';
import range  from 'lodash/range';
import merge  from 'lodash/merge';
import moment from 'moment';
import filter  from 'lodash/filter';
import groupBy  from 'lodash/groupBy';
import LightBox from "../common/LightBox";
import { Media } from 'react-bootstrap';
import { imageBasePath,phoneNumber } from '../../constants/staticData';
import { Button } from "react-bootstrap";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import { ToastMessage } from "../common/ToastMessage";
import { appBasePath } from "../../constants/staticData"
import {checkArrayKeyExists} from '../../constants/apiEndPoints'
import PageLoader from "../common/PageLoader"

export default class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.label = { dateTime: null, location: null };
    this.state = {
      imageStartIndex: 0,
      showModal: false,
      images: [],
      taxes:[],
      discriptionAdminTrue:false,
      discriptionAdminFalse:false,
      loading: true,
      showPickupInfo: false,
      request: null,
      businessPackages: [],
      confirmedPackages: [],
      approveButtonDisabled: true,
      paymentMode: 1,
      paymentStatus: 0,
      paymentLoading: false,
      showPayment: { status: false, retry: false },
      offlinePayment: 0,
      engagedPackages: [],
      showLine : false,
      adminPackage: [],
      isCardPayment: true,
      isShopPayment: false,
      isPaymentButtonLoading: false,
      isPayAtShopEnabled: false,
      viewInfo:true,
      totalcost:0,
      showFullPageLoader: false,
      exactprice:0,
      jobProgress: [],
      statusArray:[],
      statusPopupPosition: 34,
      statusPopupArrow: -40,
      bcPopupPosition: 26,
      bcPopupArrow: -40,
      margin: 0,
      activeButton: undefined,
      smallScreen: false,
      clickEvent: false,
      hover: false,
      worklogVisible: false,
      currentKey: 1,
      clickedDotPosition: 0,
      statusCount:1,

    };
    this.serviceProgressPopUp = this.serviceProgressPopUp.bind(this);
    this.setInitialPosition = this.setInitialPosition.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const that = this;
    if (
      nextProps.pollCountObj &&
      nextProps.pollCountObj.totalCount &&
      nextProps.requestData &&
      nextProps.requestData.images &&
      nextProps.requestData.images.length
    ) {
      if (that.props.router.params.requestId) {
        if (
          nextProps.pollCountObj.totalCount -
          nextProps.requestData.images.length
        ) {
          that.props.actions.getBookingRequest(
            that.props.router.params.requestId,
            that.props.router
          );
        }
      }
    }
  }
  closeModal() {
    this.setState({ showModal: false });
  }

  operation(){
    this.setState({
      discriptionAdminTrue:!this.state.discriptionAdminTrue
    })
  }
  operate(){
    this.setState({
      discriptionAdminFalse:!this.state.discriptionAdminFalse
    })
  }

  hideandshow()
  {
    this.setState({
      viewInfo:!this.state.viewInfo
    })
  }
  componentDidMount() {
    const {requestData} = this.props;

    var i;
    if (requestData && !this.props.isPop) {
      const businessPackages = requestData.packages.filter((businessPackage) => { return businessPackage.confirmed === false && businessPackage.rejected === false  })
      console.log("hii =", businessPackages)

        let confirmedPackages = [], engagedPackages = [];
        for (i = 0; i < businessPackages.length; i++) {
            if (businessPackages[i].confirmed || businessPackages[i].rejected) {
                engagedPackages.push(businessPackages[i].services[0].userbookingrequestPackageId)
            }
            confirmedPackages.push({ package_id: businessPackages[i].services[0].userbookingrequestPackageId, status: businessPackages[i].confirmed ? "approved" : "rejected" })
        }
        const packagesApprovedRejected = businessPackages.filter((cPakage) => { return cPakage.confirmed === false && cPakage.rejected === false })
        this.setState({
          confirmedPackages,
          businessPackages,
          approveButtonDisabled:packagesApprovedRejected.length === 0,
      })
    }
    else if (this.props.fromNotification || this.props.isPop) {
      this.props.actions.getBookingRequest(this.props.requestId, undefined, false)
  }

    let divWidth = document.getElementById('custom-dots').offsetWidth;
    let totalWidth = (divWidth / (this.state.statusCount + 1)) - 14;
    this.setState({ margin: totalWidth });
    let inpCount = 0;

    if(requestData.requeststatus.displayname == "In-progress")
    {
      inpCount = inpCount + 1;
    }
    let popUpPosition = this.props.displayname == 'In-progress' ? inpCount : this.props.displayname == 'Completed' ? this.state.statusCount + 1 : 0;
    setTimeout(function () { this.setInitialPosition(popUpPosition, this.props.displayname == 'Completed'); }.bind(this), 100);
    console.log("Position1 = ",popUpPosition)
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));


}

checkPackageApproved(id) {
  const index = checkArrayKeyExists(this.state.confirmedPackages, "package_id", id)
  return this.state.confirmedPackages[index].status === "approved" ? 1 : 0;
}
setpackageForApproval(id, cost) {

  let oldArray = [...this.state.confirmedPackages];
  const { confirmedPackages } = this.state
  const index = checkArrayKeyExists(oldArray, "package_id", id)
  if (oldArray[index].status === "approved") {
      oldArray[index].status = "rejected"
  } else {
      oldArray[index].status = "approved"
  }

  this.setState({ confirmedPackages: oldArray }, () => {
      const serviceAdded = checkArrayKeyExists(confirmedPackages, "package_id", id)
      if (serviceAdded != -1) {
          if (confirmedPackages[serviceAdded].status === "approved") {
              this.setState({ exactprice: this.state.exactprice + cost })
          } else {
              this.setState({ exactprice: this.state.exactprice - cost })
          }
      }
  })
}
  approvePackage() {

    const { requestData } = this.props
    const { confirmedPackages, engagedPackages } = this.state
    let finalPackages = []
    finalPackages = confirmedPackages.filter((data) => {
        return engagedPackages.indexOf(data.package_id) === -1
    })
  this.props.actions.approveCarcilityPackages({ request_id: this.props.requestData.id, packages: finalPackages }, this.props.requestData.requestid, this.approvalCallback.bind(this))
  this.showLoader();
  setTimeout(() => {
    this.showLoader();
  }, 3000);
}
approvalCallback(status) {
  if (status === 1) {
      this.setState({ approveButtonDisabled: true, exactprice: 0 })
  }
}
// handleChange(e,packagecost) {
//   let isChecked = e.target.checked;
//   var total;
//   if(isChecked)
//     {
//     total=this.state.sumup+packagecost
//     }
//     else if(!isChecked)
//     {
//      total=this.state.sumup-packagecost
//   }
//   this.setState({
//     sumup:total
//   })
// }

showLoader() {
  this.setState({
    showFullPageLoader: !this.state.showFullPageLoader,
  });
}
refreshPage() {
  window.location.reload();
}
makePayment( requestId,plus) {
  this.setState({ isPaymentButtonLoading: true })
  let method = 2;
  if(this.state.isCardPayment)
    method = 1;
  this.props.actions.makePayment(requestId, plus, method);
}

updateRadioButton(type) {
  this.setState({ isCardPayment: type == "cardPayment"? true: false, isShopPayment : type == "shopPayment"? true: false })
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
}
setInitialPosition(position, isCompleted) {
  const currentElm = document.getElementById(`dot-position-${position}`);
  if (currentElm) {
    let currentPopupPosition = undefined;
    let currentPopupArrow = undefined;
    const totalTimelienWidth = document.getElementById('custom-dots').offsetWidth;
    let clickPosition = this.state.margin * position;
    if (this.state.smallScreen) {
      currentPopupPosition = totalTimelienWidth * .5;
    } else {
      currentPopupPosition = (totalTimelienWidth * .6) + 10;
    }
    if (position == -10 || isCompleted) {
      let winOffset = window.innerWidth < 1250 ? 194 : 214;
      currentPopupArrow = totalTimelienWidth - 31;
      currentPopupPosition = currentElm.offsetLeft - winOffset;
    } else if (position == 0) {
      currentPopupPosition = 34, currentPopupArrow = -4;
    } else if (clickPosition > currentPopupPosition) {

      currentPopupArrow = currentElm.offsetLeft - 14;
      currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
    } else {
      currentPopupPosition = this.state.margin * position, currentPopupArrow = currentElm.offsetLeft - 14;
      currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
    }
    this.setState({
      statusPopupPosition: currentPopupPosition, statusPopupArrow: currentPopupArrow, hover: false,
      bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow,
      currentKey: isCompleted ? -10 : position,
      clickedDotPosition: position
    });
  }
}
updateWindowDimensions() {
  if (window.innerWidth < 1200) {
    this.setState({ smallScreen: true });
  }
}






serviceProgressPopUp(serviceName, time, progress, requestStatus) {
  let currentKey = this.state.currentKey;

  if (currentKey == this.state.statusCount + 1) {
    currentKey = -10;
  }

  return (
    <span>
      {currentKey == 0 || currentKey == -10 ?
        <div className="status-popup n-new-view" id={currentKey == 0 ? "bbr" : currentKey == -10 ? "btr" : ""} style={{ left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition }} >
          <Media>
            <Media.Left>
              <i className="mdi mdi-calendar"></i>
            </Media.Left>
            <Media.Body>
              <Media.Heading>
                {currentKey == -10 ?
                  requestStatus == 'In-progress' ? 'Job is in Progress' : requestStatus == 'Completed' ? 'Job completed on' : 'Job scheduled to start on'
                  : requestStatus == 'In-progress' ? 'Job started on' : requestStatus == 'Completed' ? 'Job started on' : 'Job Scheduled to start on'}</Media.Heading>
              {(currentKey == -10 && requestStatus == 'In-progress') ? '' : <p className="time">{time} </p>}
            </Media.Body>
          </Media>
        </div> :
        <div className="status-popup" style={{ left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition }} >
          <span className="statusPopup-arrow" ></span>
          <div className="statusDescription" >
          {serviceName.map((service,i)=>{
           

          return <span className="servicelist">{service.name}{i==serviceName.length-1 ? " ":i==serviceName.length-2 ? " & ":" , "}</span>

            }
          )}

            <br/>
            <span className="status-process">{progress + "  "} </span>
            {progress != 'pending' && <span>{time}</span>}
            {/* {progress != 'pending' && <a className="view-worklog pull-right" onClick={() => this.setState({ worklogVisible: true })}> View Worklog</a>} */}
          </div>
        </div>
      }
    </span>
  );
}







stepClick(e, key, type) {
  let originalPopupPosition = this.state.statusPopupPosition;
  let originalPopupArrow = this.state.statusPopupArrow;
  let currentPopupPosition = undefined;
  let currentPopupArrow = undefined;

  const totalTimelienWidth = document.getElementById('custom-dots').offsetWidth;
  let clickPosition = this.state.margin * key;
  if (this.state.smallScreen) {
    currentPopupPosition = totalTimelienWidth * .5;
  } else {
    currentPopupPosition = (totalTimelienWidth * .6) + 10;
  }
  if (key == -10) {
    let winOffset = window.innerWidth < 1250 ? 194 : 214;
    currentPopupArrow = totalTimelienWidth - 31;
    currentPopupPosition = e.target.offsetLeft - winOffset;
  } else if (key == 0) {
    currentPopupPosition = 34, currentPopupArrow = -4;
  } else if (clickPosition > currentPopupPosition) {

    currentPopupArrow = e.target.offsetLeft - 14;
    currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
  } else {
    currentPopupPosition = this.state.margin * key, currentPopupArrow = e.target.offsetLeft - 14;
    currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
  }

  if (e.type == "click") {
    this.setState({
      statusPopupPosition: currentPopupPosition, statusPopupArrow: currentPopupArrow, hover: false,
      bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow,
      currentKey: key,
      clickedDotPosition: key
    });
    originalPopupPosition = currentPopupPosition,
      originalPopupArrow = currentPopupArrow;

  } else if (e.type == "mouseleave") {
    this.setState({ bcPopupPosition: originalPopupPosition, bcPopupArrow: originalPopupArrow, hover: false, currentKey: this.state.clickedDotPosition });
  } else if (e.type == "mouseover") {
    this.setState({ bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow, hover: true, currentKey: key });
  }
}








  render() {
    const {packages,requeststatusId,requeststatus,updatedAt,special_services} = this.props.requestData;



    const { requestData, pollCountObj } = this.props;
    const adminPackage = requestData.packages.filter((businessPackage)=> { return businessPackage.addedByAdmin === true })
    let requestDataPackages = orderBy(requestData.packages, ['id'],['asc']);

    const packagesApprovedRejected = adminPackage.filter((cPakage) => { return cPakage.confirmed === false && cPakage.rejected === false })
    var i;
    let inPRogressServices = [];
        for (i = 0; i < packages.length; i++) {
            const inPRogressServicesArray = packages[i].services.filter(service => {
                return requeststatusId === 6 ? service.status === 2 : service.status === 1
            })
            inPRogressServices = [...inPRogressServices, ...inPRogressServicesArray]
        }

        let completedServices = [];
        for (i = 0; i < packages.length; i++) {
            const completedServicesArray = packages[i].services.filter(service => {
                return service.status === 2
            })
            completedServices = [...completedServices, ...completedServicesArray]
        }

        let totalServices = [];

        for (i = 0; i < packages.length; i++) {
            if (packages[i].confirmed) {
                const completedServicesArray = packages[i].services.filter(service => {
                    return service
                })
                totalServices = [...totalServices, ...completedServicesArray]
            }
        }

    const style = {
      dotsWidth: {
        marginLeft: this.state.margin
      }, popupPos: {
        left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition
      }, popupArrow: {
        left: this.state.hover ? this.state.bcPopupArrow : this.state.statusPopupArrow
      }, arrowDown: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #e0e0e0',
        position: 'absolute',
        top: '98px',
      }, arrowLeftDown: {
        width: 0,
        height: 0,
        borderLeft: '1px solid transparent',
        borderRight: '14px solid transparent',
        borderTop: '15px solid #ddd',
        position: 'absolute',
        top: '98px',
        zIndex: '999',
        transform: 'rotate(0deg)',
      }, arrPos: this.state.hover ?
        { left: this.state.bcPopupArrow + 38 } : this.state.statusPopupArrow < 0 ? { right: 0, left: 34 } : { left: this.state.statusPopupArrow + 38 }
    };
    let statusArray = [];
    let firstServiceStatus = '';
    let lastServiceStatus = '';
    let statusCount = 1;
    let status = this.props.displayname;
    statusArray.push(status)
    let updatedandTime =moment(requestData.updatedAt).format("DD MMMM YY, hh:mm A");
    let servicesname = packages.filter((businessPackage) => { return businessPackage.confirmed === true || businessPackage.rejected===false })
    console.log("Name = ", servicesname)
    
    const stepCount = map(range(statusCount), (stepVal, parentKey) => {
      let currentService = requeststatus.displayname;
      let status = currentService;
      return (
        <span className={this.state.currentKey == parentKey + 1 ? "dots active " + status + ' ' + parentKey + 1 : "dots " + status + ' ' + parentKey + 1} style={style.dotsWidth} key={parentKey + 1}
          id={`dot-position-${parentKey + 1}`}
          onMouseOver={(e) => { this.stepClick(e, parentKey + 1, 'hover'); }}
          onClick={(e) => { this.stepClick(e, parentKey + 1, 'click'); }}
          onMouseLeave={(e) => { this.stepClick(e, parentKey + 1); }} />
      );
    });
    const popUp = this.serviceProgressPopUp(servicesname ,updatedandTime, requeststatus.displayname,  requeststatus.displayname);
    firstServiceStatus = (this.props.displayname== 'In-progress' || this.props.displayname == 'Completed') ? 'Completed' : '';
    lastServiceStatus = (this.props.displayname == 'Completed') ? 'Completed' : '';
    let startDate = new Date(this.props.displayname == "Expired" || this.props.displayname == "Cancelled" ?  requestData.updatedAt :requestData.plus==true ? requestData.special_services[0].preferredStarttime:requestData.userPrefferedDatetime);
    let endDate = requestData.plus==true ? requestData && requestData.special_services && requestData.special_services.length && requestData.special_services[0].preferredEndtime ? new Date(requestData.special_services[0].preferredEndtime) : ''
                : requestData && requestData.garagejobquotes && requestData.garagejobquotes.length && requestData.garagejobquotes[0].jobEndDatetime ? new Date(requestData.garagejobquotes[0].jobEndDatetime) : ''
    if ((this.props.displayname == "Active" || this.props.displayname == "In-progress" || this.props.displayname == "Completed") && requestData.plus == true) {
      this.label = { title: "Created Date", dateTime: "Pickup Date Time", location: "Pickup Location" };
    }
   else if (this.props.displayname == "Active" && requestData.plus != true) {
      this.label = { title: "Created Date", dateTime: "Preferred Date", location: "Preferred Location" };
    }

    else if (this.props.displayname == "Waiting") {
      this.label = { title: "Created Date", dateTime: "Preferred Date", location: "Preferred Location" };
    } else if (this.props.displayname == "Completed") {
      this.label = { title: "Created Date", dateTime: "Completed Date", location: "Location" };
    } else if (this.props.displayname == "Expired" || this.props.displayname == "Cancelled") {
      this.label = { title: `Created Date`, dateTime: `${this.props.displayname} Date`, location: "Location" };
    } else {
      this.label = { title: "Created Date", dateTime: "Start Date", location: "Location" };
    }
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let createdDate = new Date(requestData.createdAt);
    createdDate = createdDate.getDate() + ' ' + months[createdDate.getMonth()] + ' ' + createdDate.getFullYear().toString().substring(2) + ' ' + createdDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const startTime = (requestData && requestData.services && requestData.services.name !== "Servicing")?(' ' + startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })):' ';
    const endTime = (requestData && requestData.services && requestData.services.name !== "Servicing")?+ ' ' + endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : '';
    endDate = endDate ? endDate.getDate() + ' ' + months[endDate.getMonth()] + ' \'' + endDate.getFullYear().toString().substring(2)  :'';
    startDate = startDate.getDate() + ' ' + months[startDate.getMonth()] + ' ' + startDate.getFullYear().toString().substring(2)+' | ' +startTime+' - '+endTime;

    // + ' ' + startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    // + ' ' + endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : '';

    let selectedServices = "";
    if (requestData && requestData.serviceId == 1) {
      selectedServices =
        requestData &&
        requestData.userbookingrequest_services &&
        requestData.userbookingrequest_services.map((service, key) => {
          return (
            <li key={key}>
              {/* <span className="wash" /> */}
              <img className="repair" src={service.image} />
              <label>{service.name}</label>
              {/* <span className="repair-sub-category">{service.name}</span> */}
            </li>
          );
        });
    } else {
      let groupedServices =
        requestData &&
        requestData.userbookingrequest_services &&
        requestData.userbookingrequest_services.length &&
        groupBy(requestData.userbookingrequest_services, "parent.name");
      selectedServices = map(groupedServices, (parentService, parentKey) => {
        let serviceNames = "";
        let parentImage = '';
        map(parentService, (subService, key) => {
          let comma = (parentService.length != key + 1) ? ', ' : '';
          serviceNames = serviceNames + subService.name + comma;
          parentImage = subService && subService.parent && subService.parent.image;
        });
        return (
          <li key={parentKey}>
            <img className="repair" src={requestData.serviceId == 2 ? `${imageBasePath}/images/carservice.png` : parentImage} />
            <label>{parentKey}</label>
            <span className="repair-sub-category">{serviceNames}</span>
          </li>
        );
      });
    }
    let images = filter(requestData.images, { type: "image" });
    map(images, (image, key) => {
      images[key].thumbnail = image.small;
      images[key].original = image.large;
    });
    let policeReports = filter(requestData.images, { type: "policereport" });
    map(policeReports, (image, key) => {
      policeReports[key].thumbnail = image.small;
    });
    let registrationCard = filter(requestData.images, {
      type: "registrationcard"
    });
    map(registrationCard, (image, key) => {
      registrationCard[key].thumbnail = image.small;
    });
    let drivingLicense = filter(requestData.images, { type: "drivinglicense" });
    map(drivingLicense, (image, key) => {
      drivingLicense[key].thumbnail = image.small;
    });

    let requestImages =
      images &&
      images.length &&
      images.map((image, key) => {
        if (key <= 2) {
          return (
            <li
              key={key}
              className="upload-images"
              onClick={() => {
                this.setState({
                  images: images,
                  showModal: true,
                  imageStartIndex: key
                });
              }}
            >
              <img src={image.medium} alt="" />
            </li>
          );
        } else if (key == 3) {
          if (images.length > 4) {
            return (
              <li
                key={key}
                className="upload-images"
                onClick={() => {
                  this.setState({
                    images: images,
                    showModal: true,
                    imageStartIndex: key
                  });
                }}
              >
                <img src={image.medium} alt="" />
                <label className="more-popup">
                  {"+" + (images.length - 3) + " More"}
                </label>
              </li>
            );
          } else {
            return (
              <li
                key={key}
                className="upload-images"
                onClick={() => {
                  this.setState({
                    images: images,
                    showModal: true,
                    imageStartIndex: key
                  });
                }}
              >
                <img src={image.medium} alt="" />
              </li>
            );
          }
        } else {
          return "";
        }
      });

    let placeholderImages =
      pollCountObj && pollCountObj.totalCount && images && images.length
        ? map(range(pollCountObj.totalCount - images.length), function () {
          return (
            <div className="upload-images">
              <img src={`${imageBasePath}/images/placeholder.png`} alt="" />
            </div>
          );
        })
        : "";
    return (
      <div>
         {packagesApprovedRejected.length ?
        <PlusSidebar   approvePackage={()=>{this.approvePackage()}}  page="approvePackage" router={this.props.router}  />

          : requestData.requeststatus.displayname=="Completed" && requestData.paymentstatus.name!="Paid" ?
          // <div className="row-md-12">
          //     <div className="col-md-3"></div>
          //     <div className="col-md-3"></div>
          //     <div className="col-md-3">
          //     <a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>
          //     </div>
          //     <div className="col-md-3">
          //     <button className="makepayment" onClick={()=>{this.makePayment(requestData.id,true)}}>Make payment</button>
          //     </div>
          // </div>
          " "
          :
          <PlusSidebar  page="callus" router={this.props.router}  />
      //     <div className="row-md-12">
      //     <div className="col-md-3"></div>
      //     <div className="col-md-3"></div>
      //     <div className="col-md-3">
      //     </div>
      //     <div className="col-md-3">
      //     <a href={`tel:${phoneNumber}`} target="_blank"><button className="calls">Call Us</button></a>
      //     </div>
      // </div>
          }
         {this.state.showFullPageLoader  ? <PageLoader /> :
      <div>
      { requestData.plus!=true ?
        <div className="data-time col-md-12">
        <div className="col-md-4 margin-correction">
          <div className="wrapper">
            <title className="title">{this.label.title} :</title>
            <span>
              {createdDate}
            </span>
          </div>
        </div>
        <div className="col-md-4 margin-correction">
          <div className="wrapper">
            <title className="title">{this.label.dateTime} :</title>
            <span>{startDate}</span>
          </div>
          {(this.props.displayname == "Accepted" || this.props.displayname == "In-progress" || this.props.displayname == "Completed") && endDate != '' ? <div className="wrapper"><title className="title">End Date :</title>
            <span>{endDate}</span></div> : ''}
        </div>
        <div className="col-md-4 margin-correction">
          <div className="wrapper">
            <title className="title">{this.label.location} :</title>
            {requestData.plus == true ?
            <span>
              {requestData && requestData.special_services[0].address
                ?requestData.special_services[0].address
                : "No location found"}
            </span>
            :
            <span>
              {requestData && requestData.location
                ? requestData.location
                : "No location found"}
            </span>
            }
          </div>
        </div>
      </div>

      :
      <div>
      <div className="plus-desktop">
      <div className="col-md-12 total">
        <div className="quotation-total-first">

          {requestData.requeststatus.displayname=="Completed" ?
          <div className="total">
                    <label><b className="lable">Total</b></label>
                    <span><b className="lable">
                      {requestData && Number(requestData.finalAmount).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}</b>
                    </span>
                  </div>
                   :
                 <div className="total">
                 <label><b className="lable">Total</b></label>
                 <span className="hide">

                      {requestData && Number(requestData.totalamount+this.state.exactprice || 0).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                    <span className="hide">
                      {requestData && Number((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                 <span><b className="lable">
                   {requestData && Number((requestData.totalamount+this.state.exactprice || 0)+((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100)).toFixed(2)}
                   {requestData && ' ' +
                     requestData.currency}</b>
                 </span>
               </div>
                  }
                  </div>
      </div>
      {requestData.requeststatus.displayname == "In-progress" || requestData.requeststatus.displayname == "Completed" ?
      <div className="col-md-12 quotes">


      <div className="job-footer active">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                <div className="job-process">
                  <h1 className="job-footer-title">Job Progress</h1>
                  <div>
        {popUp}
        <div className={this.state.statusPopupArrow ? "arrow-down" : "arrow-down hide"} id={this.state.currentKey == 0 ? "n-arrow-left" : this.state.currentKey == -10 ? "n-arrow-right" : "n-arrow-middle"} style={merge({}, this.state.currentKey != 0 && this.state.currentKey != -10 ? style.arrowDown : style.arrowLeftDown, style.arrPos)}></div>
        <div className="custom-dots" id="custom-dots">
          <div className={requestData.requeststatus.displayname == "Completed" ? "main-line completed" : "main-line"} />
          <div>
            <span id={`dot-position-0`} className={this.state.currentKey == 0 ? "dots active first " + firstServiceStatus : "dots first " + firstServiceStatus}
              onMouseOver={(e) => { this.stepClick(e, 0, 'hover'); }}
              onClick={(e) => { this.stepClick(e, 0, 'click'); }}
              onMouseLeave={(e) => { this.stepClick(e, 0); }}
            />

          {stepCount}

            <span id={`dot-position-${statusCount + 1}`} className={this.state.currentKey == -10 ? "dots active last " + lastServiceStatus : "dots last " + lastServiceStatus}
              onMouseOver={(e) => { this.stepClick(e, -10, 'hover'); }}
              onClick={(e) => { this.stepClick(e, -10, 'click'); }}
              onMouseLeave={(e) => { this.stepClick(e, -10); }}
            />
          </div>
        </div>
        </div>
                  <span className="job-start-point">Job start</span>
                  <span className="job-end-point">Car ready</span>
            </div>
              </div>
            </div>
          </div>

          </div>: null }
      <div className="col-md-12 quotes">
        <p>QUOTE</p>
      </div>
      </div>

      
      <div className="plus-mobile">
      <div className="col-md-12 total">
        <div className="quotation-total-first">

          {requestData.requeststatus.displayname=="Completed" ?
          <div className="total">
                    <label><b className="lable">Total</b></label>
                    <span><b className="lable">
                      {requestData && Number(requestData.finalAmount).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}</b>
                    </span>
                  </div>
                   :
                 <div className="total">
                 <label><b className="lable">Total</b></label>
                 <span className="hide">

                      {requestData && Number(requestData.totalamount+this.state.exactprice || 0).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                    <span className="hide">
                      {requestData && Number((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                 <span><b className="lable">
                   {requestData && Number((requestData.totalamount+this.state.exactprice || 0)+((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100)).toFixed(2)}
                   {requestData && ' ' +
                     requestData.currency}</b>
                 </span>
               </div>
                  }
                  </div>
      </div>
      {requestData.requeststatus.displayname == "In-progress" || requestData.requeststatus.displayname == "Completed" ?
      <div className="col-md-12 quotes">

         <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="service-card">
            
          <p>{requestData.requeststatus.id === 3 ? "ONGOING" : requestData.requeststatus.name.toUpperCase() }</p>
          {requestData.requeststatusId !=4 ? totalServices.length-completedServices.length===0 ? <p  className="message">All The services have been completed and final check is in progress</p>: 
          inPRogressServices.map((service,i)=>{

            return <span className="servicelist">{service.name}{i==inPRogressServices.length-1 ? " ":i==inPRogressServices.length-2 ? " & ":" , "}</span> 


          }

          
          ):
          <span>Job Scheduled to start </span>
          
          }
          {requestData.requeststatusId === 6 && <p className="message">{moment(requestData.updatedAt).format("DD MMMM YY, hh:mm a")}</p>}
          {/* {requestData.requeststatusId === 3 ? " " : <p className="message">{updatedAt}</p> } */}
          </div>
          
          {requestData.requeststatusId===7 ? null : requestData.requeststatusId === 6 ?
          <p className="message">The Service provider has completed all tasks.</p> 
          :
          <p className="message">The Service provider has completed {completedServices.length} tasks, while {totalServices.length - completedServices.length} tasks are left.</p>
        
        
        }

          </div>
          <div className="col-md-4"></div>

          </div>: null }
      <div className="col-md-12 quotes">
        <p>QUOTE</p>
      </div>
      </div>


      </div>
      }
      {requestData.plus == true && (requestData.requeststatus.displayname == "Active" || requestData.requeststatus.displayname =="In-progress" || requestData.requeststatus.displayname == "Completed") ?
    <div>
    <div >
    <div className = " col-md-12 uploads" >
      <div className="quotation-totaly heading"><h5>1. Carcility Plus Services</h5>
      {requestData.requeststatus.displayname =="Active" || requestData.requeststatus.displayname =="In-progress"|| requestData.requeststatus.displayname =="Completed" ?
      <div>
        {requestDataPackages.map((item,i)=>
        item.services.map((service,j)=>
        <div>
          {item.confirmed == true  ?
        <div className="total" >
        <label htmlFor={item.id}><p className="servicesname" onClick={()=>this.operate()}>{service.name}</p> </label>
        <i className={item.description == null ? " ": this.state.discriptionAdminFalse ? "mdi mdi-chevron-up" :"mdi mdi-chevron-down" }onClick={()=>this.operate()}/>
        <span>
          {item && parseFloat(item.packageCost).toFixed(2)}
          {requestData && ' ' +
            requestData.currency}
        </span>
        {this.state.discriptionAdminFalse?
          <div className="description_plus">{item.description}</div> : null
        }
        {item.customizationName ?
        <div className="total">
          <label htmlFor={item.id}><p className="servicesname" >{item.customizationName}</p> </label>

        <span>
          {item && parseFloat(item.packageCustomizationCost).toFixed(2)}
          {requestData && ' ' +
            requestData.currency}
        </span>

        </div>:null
      }
      </div>: " "
        }
        </div>)
        )}
       </div>
       :

       <div>
       {requestData.packages.map((item,i)=>

       <div>
         {item.confirmed ==true ?
       <div className="total" >
       <label htmlFor={item.id}><p className="servicesname" onClick={()=>this.operate()}>{item.name}</p> </label>
       <i className={item.description == null ? " " : this.state.discriptionAdminFalse ? "mdi mdi-chevron-up" :"mdi mdi-chevron-down"} onClick={()=>this.operate()}/>
       <span>
         {item && parseFloat(item.packageCost).toFixed(2)}
         {requestData && ' ' +
           requestData.currency
           }
       </span>
       {this.state.discriptionAdminFalse?
         <div className="description_plus">{item.description}</div> : null
       }
     </div>: " "
       }
       </div>

       )}
      </div>



       }





      </div>
      {packagesApprovedRejected.length  && requestData.requeststatus.displayname!= "Completed" ?
      <div>
      <div className="quotation-totaly heading"><h5>2. Rocommended services</h5>
        <div className="select ">Please Select and approve required services</div>
        <div>
          {requestData.packages.map((item,id)=>
          item.services.map((service2,j) =>

          <div>
            {item.addedByAdmin == true && item.confirmed == false && item.rejected== false ?
          <div className="total" key={id}>
            <div className="check" onClick={()=>{this.checkPackageApproved(service2.userbookingrequestPackageId)}} >


          <input className="checked" type="checkbox" id={item.id} value={item.packageCost}


          onClick={() => {this.setpackageForApproval(service2.userbookingrequestPackageId,item.totalCost)}

        }
          />

          <label htmlFor={item.id} className="services" ></label>

          <label>
              <p className="servicename" id={item.id} onClick={()=>this.operation()} >{service2.name} </p>
          </label>
          <i id={item.id} className={item.description == null ? " ": this.state.discriptionAdminTrue ? "mdi mdi-chevron-up" :"mdi mdi-chevron-down"} onClick={()=>this.operation()}/>
          <label  className="amount">
            {item && Number(item.packageCost).toFixed(2)}
            {requestData && ' ' +
              requestData.currency}
          </label>
          {this.state.discriptionAdminTrue ?
          <div className="description_admin"  id={item.id}>{item.description}</div>:null
          }
         </div>
        </div>:""}
        </div>)
          )}
					</div>
        </div>
      </div> : null}
      <div className="quotation-totals">
              {requestData.plus == true && (requestData.requeststatus.displayname == "Active" || requestData.requeststatus.displayname =="In-progress" || requestData.requeststatus.displayname == "Completed") ?
                <div>
                  <div className="total">
                    <label>Sub Total</label>
                    {requestData.requeststatus.displayname=="Completed" ?

                    requestData.paymentstatus.name == 'Paid' ?
                    <span>
                      {requestData && Number(requestData.totalamount).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                    :
                    <span>
                    {requestData && Number(requestData.totalamount).toFixed(2)}
                    {requestData && ' ' +
                      requestData.currency}
                  </span>

                    :

                    <span>

                      {requestData && Number(requestData.totalamount+this.state.exactprice || 0).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                  </span>

                    }
                  </div>
                  {requestData.taxes.length == 0 ?
                  null
                  :
                  <div className="total">
                    <label>VAT ({requestData.taxes[0].value}%)</label>
                    {requestData.plus == true && (requestData.requeststatus.displayname == "Active" || requestData.requeststatus.displayname =="In-progress" )?
                    <span>
                     {requestData && Number((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>
                    :
                    <span>
                      {requestData && Number(requestData.taxes[0].amount).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}
                    </span>}
                  </div>
                  }

                  <div className="total">
                    <label><b className="lable">Total</b></label>
                    {requestData.requeststatus.displayname=="Completed"?
                    <span><b className="lable">
                      {requestData && Number(requestData.finalAmount).toFixed(2)}
                      {requestData && ' ' +
                        requestData.currency}</b>
                    </span>
                    :
                    <span><b className="lable">
                    {requestData && Number((requestData.totalamount+this.state.exactprice || 0)+((this.state.exactprice+requestData.totalamount)*(requestData.taxes[0].value)/100)).toFixed(2)}                      {requestData && ' ' +
                      requestData.currency}</b>
                  </span>


                    }
                  </div>
                </div>
                :
                " "
              }
            </div>
                                      {requestData.requeststatus.displayname!="Completed" ?
                                        <div className="quotation-totals">
                                              <p className="content">This quote based on the information submitted. Quotes may be subject to change upon further inspection of the vehicle.</p>
                                        </div>: null}
                                        <div className="upload-section-wrappers">

                                        {requestData.requeststatus.displayname == 'Completed' && requestData.paymentstatus.name == 'Paid' ?
                                          <div className="payment-containers">
                                            <h1>Payment Method</h1>
                                            <div className="col-md-12 repair-detail n-job-details">
                                              {requestData.paymentstatus.method == "Online (Telr)" ?
                                                <div className="col-md-4 col-sm-4 col-xs-4 no-padding">

                                                  <img src={`${imageBasePath}/images/card-payment.png`}
                                                       alt="Card payment"/>
                                                  <label htmlFor="aa" className="radio-custom-label title">Paid
                                                    Online</label>
                                                </div>
                                                :
                                                <div className="col-md-4 col-sm-4 col-xs-4  no-padding">

                                                  <img src={`${imageBasePath}/images/pay-at-shop.png`}
                                                       alt="Pay at shop"/>
                                                  <label htmlFor="bb" className="radio-custom-label title">Pay at
                                                    Shop</label>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                        : ''}
                                        </div>
                                        {requestData.requeststatus.displayname == "Completed" ?
                                        <div className="row-md-12 wrapper-line">
                                          </div>:
                                          null}
       {requestData.requeststatus.displayname == 'Completed' && requestData.paymentstatus.name != 'Paid' ? null :
        <div className="col-md-12 uploaded">

        <div className="col-md-6 margin-correction">
        <div className="view"><label>View Pickup Information</label>
        <i  className={this.state.viewInfo ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down" } onClick={()=>this.hideandshow()}/>
        </div>
        {this.state.viewInfo ?
        <div>
        <div className="wrapper">
            <title className="title">{this.label.location} </title>
            {requestData.plus == true ?
            <span>
              {special_services[0].type=="PICKUP" ? special_services[0].address : special_services[1].address}
            </span>
            :
            <span>
              {requestData && requestData.location
                ? requestData.location
                : "No location found"}
            </span>
            }
          </div>

          <div className="wrapper">
            <title className="title">{this.label.dateTime} </title>
            {/* <span>{special_services[0].type=="PICKUP" ? moment(special_services[0].scheduledTime || special_services[0].preferredStarttime).format("DD MMM YYYY") | moment(special_services[0].scheduledTime || special_services[0].preferredStarttime).format("hh:mm a") - special_services[0].scheduledTime ? moment(special_services[0].scheduledTime).add(1, "hour").format("hh:mm a") : moment(special_services[0].preferredEndtime).format("hh:mm a") :
            moment(special_services[1].scheduledTime || special_services[1].preferredStarttime).format("DD MMM YYYY") | moment(special_services[1].scheduledTime || special_services[1].preferredStarttime).format("hh:mm a") - special_services[1].scheduledTime ? moment(special_services[1].scheduledTime).add(1, "hour").format("hh:mm a") : moment(special_services[1].preferredEndtime).format("hh:mm a")}
            </span>  */}
            <span>
              {special_services[0].type=="PICKUP" ?
            <span>{moment(special_services[0].scheduledTime || special_services[0].preferredStarttime).format("DD MMM YYYY")} | {moment(special_services[0].scheduledTime || special_services[0].preferredStarttime).format("hh:mm a")} - {special_services[0].scheduledTime ? moment(special_services[0].scheduledTime).add(1, "hour").format("hh:mm a") : moment(special_services[0].preferredEndtime).format("hh:mm a")}</span>

               :
            <span>{moment(special_services[1].scheduledTime || special_services[1].preferredStarttime).format("DD MMM YYYY")} | {moment(special_services[1].scheduledTime || special_services[1].preferredStarttime).format("hh:mm a")} - {special_services[1].scheduledTime ? moment(special_services[1].scheduledTime).add(1, "hour").format("hh:mm a") : moment(special_services[1].preferredEndtime).format("hh:mm a")}</span>

               }
            </span>
          </div>
          </div>:null}
        </div>

      </div>}
          {packagesApprovedRejected.length ?
          <div className="row-md-12 plus-desktop">
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
              <a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>
              </div>
              <div className="col-md-3">
              <button className="approve"   onClick={()=>{this.approvePackage()}}>Approve</button>
            </div>


          </div>
          : requestData.requeststatus.displayname=="Completed" && requestData.paymentstatus.name!="Paid" ?
          // <div className="row-md-12">
          //     <div className="col-md-3"></div>
          //     <div className="col-md-3"></div>
          //     <div className="col-md-3">
          //     <a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>
          //     </div>
          //     <div className="col-md-3">
          //     <button className="makepayment" onClick={()=>{this.makePayment(requestData.id,true)}}>Make payment</button>
          //     </div>
          // </div>
          " "
          :
          
          <div className="row-md-12 plus-desktop">
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
          </div>
          <div className="col-md-3">
          <a href={`tel:${phoneNumber}`} target="_blank"><button className="calls">Call Us</button></a>
          </div>
      </div>
          }
        </div>
        </div>

      
        </div>
      :
      <div>
        <div className="col-md-12 repair-detail n-job-details">
        <div className="col-md-6 repair-category">
          <title>{"Car " + this.props.serviceTypes + " Categories"}:</title>
          <ul className="category-list">{selectedServices}</ul>
        </div>
        {requestData.serviceId != 4 &&
          <div className="col-md-6 more-details">
            <title>
              {this.props.serviceTypes == "Wash"
                ? "Special Instructions"
                : "More Details"}{" "}
              :
          </title>
            <p>
              {requestData && requestData.details
                ? requestData.details
                : "No details Found"}
            </p>
          </div>}
      </div>
      <div
        className={
          this.props.serviceTypes != "Wash" ? " col-md-12 uploads" : "hide"
        }
      >{requestData.serviceId == 4 && <div className="upload-section-wrapper "></div>}
        {requestData.serviceId != 4 && <div className="upload-section-wrapper ">
          <title>
            {this.props.serviceTypes != "Wash" ? "Uploads: " : ""}
          </title>
          <div className="n-upload-section">
            <div className="row">
              <div className="col-md-6 images">
                {this.props.serviceTypes != "Wash" &&
                  images &&
                  images.length ? (
                    <div className="figure no-top-padding">
                      {<h4>Images:</h4>}
                      {requestImages}
                      {placeholderImages}
                    </div>
                  ) : (
                    <div className="figure no-top-padding">
                      {<h4>Images:</h4>}
                      No Images Found
                  </div>
                  )}
              </div>
              {requestData && requestData.repairreason == 'accident' ? this.props.serviceTypes == "Repair" && (
                <div className="col-md-6 images">
                  <div className="figure no-top-padding">
                    <h4>Police Report:</h4>
                    {policeReports.length
                      ? policeReports.map((file, key) => {
                        return (
                          <div
                            className="upload-images"
                            onClick={() => {
                              if (
                                file.medium &&
                                file.medium.split(".") &&
                                file.medium.split(".").pop() == "pdf"
                              ) {
                                window.open(file.original, "_blank");
                              } else if (
                                file.medium &&
                                file.medium.split(".") &&
                                (file.medium.split(".").pop().toLowerCase() == "jpg" ||
                                  file.medium.split(".").pop().toLowerCase() == "jpeg" ||
                                  file.medium.split(".").pop().toLowerCase() == "png")
                              ) {
                                this.setState({
                                  images: policeReports,
                                  showModal: true,
                                  imageStartIndex: key
                                });
                              }
                            }}
                          >
                            <img
                              src={
                                file.medium &&
                                  file.medium.split(".") &&
                                  file.medium.split(".").pop() == "pdf"
                                  ? `${imageBasePath}/images/pdf.png`
                                  : file.medium
                              }
                              alt=""
                            />
                          </div>
                        );
                      })
                      : "No Files found"}
                  </div>
                </div>
              ) : ''}
            </div>
            {requestData && requestData.repairreason == 'accident' ? <div className="row">
              {this.props.serviceTypes == "Repair" && (
                <div className="col-md-6 images">
                  <div className="figure no-border">
                    <h4>Driving Licence:</h4>
                    {drivingLicense.length
                      ? drivingLicense.map((file, key) => {
                        return (
                          <div
                            className="upload-images"
                            onClick={() => {
                              if (
                                file.medium &&
                                file.medium.split(".") &&
                                file.medium.split(".").pop() == "pdf"
                              ) {
                                window.open(file.original, "_blank");
                              } else if (
                                file.medium &&
                                file.medium.split(".") &&
                                (file.medium.split(".").pop().toLowerCase() == "jpg" ||
                                  file.medium.split(".").pop().toLowerCase() == "jpeg" ||
                                  file.medium.split(".").pop().toLowerCase() == "png")
                              ) {
                                this.setState({
                                  images: drivingLicense,
                                  showModal: true,
                                  imageStartIndex: key
                                });
                              }
                            }}
                          >
                            <img
                              src={
                                file.medium &&
                                  file.medium.split(".") &&
                                  file.medium.split(".").pop() == "pdf"
                                  ? `${imageBasePath}/images/pdf.png`
                                  : file.medium
                              }
                              alt=""
                            />
                          </div>
                        );
                      })
                      : "No Files found"}
                  </div>
                </div>
              )}
              {this.props.serviceTypes == "Repair" && (
                <div className="col-md-6 images">
                  <div className="figure no-border">
                    <h4>Registration Card:</h4>
                    {registrationCard.length
                      ? registrationCard.map((file, key) => {
                        return (
                          <div
                            className="upload-images"
                            onClick={() => {
                              if (
                                file.medium &&
                                file.medium.split(".") &&
                                file.medium.split(".").pop().toLowerCase() == "pdf"
                              ) {
                                window.open(file.original, "_blank");
                              } else if (
                                file.medium &&
                                file.medium.split(".") &&
                                (file.medium.split(".").pop().toLowerCase() == "jpg" ||
                                  file.medium.split(".").pop().toLowerCase() == "jpeg" ||
                                  file.medium.split(".").pop().toLowerCase() == "png")
                              ) {
                                this.setState({
                                  images: registrationCard,
                                  showModal: true,
                                  imageStartIndex: key
                                });
                              }
                            }}
                          >
                            <img
                              src={
                                file.medium &&
                                  file.medium.split(".") &&
                                  file.medium.split(".").pop() == "pdf"
                                  ? `${imageBasePath}/images/pdf.png`
                                  : file.medium
                              }
                              alt=""
                            />
                          </div>
                        );
                      })
                      : "No Files found"}
                  </div>
                </div>
              )}
            </div> : ''}
          </div>
        </div>}
      </div>
      </div>}
      <LightBox
        images={this.state.images}
        imageStartIndex={this.state.imageStartIndex}
        showModal={this.state.showModal}
        isEditable={false}
        closeModal={this.closeModal.bind(this)}
      />
    </div>}
    </div>
    );
  }
}
