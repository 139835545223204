import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';
import { map,each,filter,cloneDeep,findIndex,remove,size } from 'lodash';
import TextInput from '../../common/TextInput';
import ServiceTypeEmptyState from '../../EmptyState/ServiceTypeEmptyState';
import Button from '../../common/Button';
import PageLoader from '../../common/PageLoader';


class GfCarRepair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      carRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      filteredCarRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      categoriesSelectedCount: 0,
      selectedCarRepairDataArray: [],
      servicesFetched: true
    };
    this.filterCarType = this.filterCarType.bind(this);
    this.changeCheckboxState = this.changeCheckboxState.bind(this);
  }

  componentWillMount(nextProps){
    if (nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.carRepairCategories && !this.state.carRepairCategories.length && this.state.filteredCarRepairCategories && !this.state.filteredCarRepairCategories.length) {
      this.setState({
        ...this.state,
        carRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        filteredCarRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails
    });
  }

  openCategory(id) {
    if(this.state.visibleCategory == id){
      this.setState({
        'visibleCategory': undefined
      });
    }else{
      this.setState({
        'visibleCategory': id
      });
    }
  }

  changeCheckboxState(e, categoryId, subCategoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const carRepairCategories = cloneDeep(this.state.carRepairCategories);
    const filteredCarRepairCategories = cloneDeep(this.state.filteredCarRepairCategories);
    if(this.state.filterKeyword) {
      filteredCarRepairCategories[categoryId]['sub_services'][subCategoryId]['checked'] = isChecked;
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: filteredCarRepairCategories
      });
    } else {
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: carRepairCategories
      });
    }
  }

  renderCarType(carDetails, key) {
    const checkedSubCategories = filter(carDetails.sub_services, (data) => { return (data.checked); });
    return (
      <div className="sub-collapse-panel" key={key}>
        <figure onClick={(e) => { this.showModal(e, carDetails); }}>
          <img src={carDetails.image} alt="" />
        </figure>
        <div className={carDetails.id == this.state.visibleCategory ? "sub-collapse-panel-head n-col-panel active" : " n-col-panel sub-collapse-panel-head "} onClick={(event) => { event.preventDefault(); this.openCategory(carDetails.id); }}>
          <h4>{carDetails.name}</h4>
          {checkedSubCategories && checkedSubCategories.length > 0 ?
            <span className="sub-category-count">
              {checkedSubCategories.length}{checkedSubCategories.length == 1 ? " Category Selected" : " Categories Selected"}
            </span>
            :
            <span className="sub-category-count">
              {carDetails && carDetails.sub_services && carDetails.sub_services.length} {carDetails && carDetails.sub_services && carDetails.sub_services.length == 1 ? "subcategory" : "subcategories"}
            </span>
          }
          <i className={carDetails.id == this.state.visibleCategory ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
        </div>
        <div className={carDetails.id == this.state.visibleCategory ? carDetails.sub_services.length > 10 ? "sub-collapse-panel-body sub-collapse-scroll" : "sub-collapse-panel-body" : "sub-collapse-panel-body hide"}>
          <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
            {map(carDetails.sub_services, (subCategory, index) => {
              return (<div className="options" key={index}>
                <span className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id={subCategory.id} checked={subCategory.checked ? subCategory.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id, subCategory.id) }} value="" />
                  <label htmlFor={subCategory.id} className="custom-checkbox"></label>
                  <label htmlFor={subCategory.id}>
                    {subCategory.checked} {subCategory.name}
                  </label>
                </span>
              </div>);
            })}
          </CustomScroll>
        </div>
      </div>
    );
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carRepairCategories);
      let filteredCarRepairCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        'filteredCarRepairCategories': filteredCarRepairCategories,
        filterKeyword: true
      });
    } else {
      this.setState({
        'filteredCarRepairCategories': cloneDeep(this.state.carRepairCategories),
        filterKeyword: false
      });
    }
  }

  render() {
    let leftBlock = [];
    let catLength = 0;
    each(this.state.filteredCarRepairCategories, (carDetails) => {
      catLength += 1;
      leftBlock.push(this.renderCarType(carDetails, catLength));
    });
    return (
      <div className="sub-collapse-panel" >
        <span className="">
          {this.state.servicesFetched ? <div className="panel-content">
          <div className="row">
            <div className="col-md-4 pad0">
              <div className="search-box gf-cr-search-box">
                <TextInput value = {this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => {this.filterCarType(e); this.setState({searchValue:e})}} />
                <i className="mdi mdi-magnify" />
              </div>
            </div>
          </div>
          {leftBlock.length > 0 ?
            <div className="row">
              <div className="col-md-12 padLeft0">{leftBlock}</div>
            </div>
            : <ServiceTypeEmptyState/>
          }
          {leftBlock.length > 0 && <div className="next-button">
            <Button dataLabelledby="Select Service Next" disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={13} label="Next" btnCallBack={() => {this.props && this.props.formValidation();this.props.getCarRepairData(this.state.carRepairCategories); this.props.toggleNextButton('panel3',true);  this.props.panel2Valid(); }} />
          </div>}
        </div>:
        <PageLoader/>}
        </span>
      </div>
    );
  }
}

export default GfCarRepair;
