import React, { Component } from "react";
import map  from 'lodash/map';
import { validateMobile } from "../../helpers/index";
import {
  validationMessage,
  imageBasePath,
  appBasePath
} from "../../constants/staticData";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "+91",
      regularFooter: false,
      appDownloadPhoneNumber: "",
      showPhoneNoError: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.regularFooter) {
      this.setState({ regularFooter: true });
    }
  }

  render() {
    const { router } = this.props;
    const footerItem = [
      {
        footerHeading: "Carcility",
        sublist: [
          {
            title: "About Us",
            hyperLink: "/about"
          },
          {
            title: "Blog",
            hyperLink: "/blog"
          },
          {
            title: "Contact Us",
            hyperLink: "/contact"
          }
        ]
      },
      {
        footerHeading: "Get In Touch",
        sublist: [
          {
            title: "+971 54 3040110",
            hyperLink: "#"
          },
          {
            title: "support@carcility.com",
            hyperLink: "#"
          }
        ]
      },
      {
        footerHeading: "Help",
        sublist: [
          {
            title: "FAQs",
            hyperLink: "/blog/faq"
          }
        ]
      }
    ];

    const socialIcon = [
      {
        title: "facebook",
        hyperLink: "https://www.facebook.com/carcility"
      },
      {
        title: "twitter",
        hyperLink: "https://twitter.com/carcility"
      },
      {
        title: "instagram",
        hyperLink: "https://www.instagram.com/carcility"
      }
    ];

    const quickLinks = [
      {
        title: "Contact",
        hyperLink: "/contact"
      },
      {
        title: "FAQ",
        hyperLink: "/blog/faq"
      }
    ];

    const footerLink = map(footerItem, (item, key) => {
      return (
        <div className="col-md-4 col-sm-12 col-xs-12 pad0" key={key}>
          <div className="footer-link">
            <h5>{item.footerHeading}</h5>
            <ul className="list-unstyled">
              {map(item.sublist, (subListVal, index) => {
                return (
                  <li key={index}>
                    <a href={subListVal.hyperLink}>{subListVal.title}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    });
    const socialLink = map(socialIcon, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
            <i className={"mdi mdi-" + item.title} />
          </a>
        </li>
      );
    });
    const shortFooter = map(quickLinks, (item, key) => {
      return (
        <li key={key}>
          <a
            onClick={() => {
              router.push(appBasePath + item.hyperLink);
            }}
          >
            {item.title}
          </a>
          <span>|</span>
        </li>
      );
    });

    return (
      <div id="footer-block">
        <div className="padwrapper">
          <div className="container-fluid">
            {this.state.regularFooter && (
              <div className="regular-footer">
                <div className="appLink-section">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 pad0">
                      {footerLink}
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 pad0">
                      <div className="app-right">
                        <div className="app-right-content">
                          <h5>Download App</h5>
                          <div className="input-group">
                            <span className="countryCode">+971</span>
                            <input
                              type="text"
                              className="form-control phone-no"
                              placeholder="Enter Phone Number"
                              onChange={e => {
                                this.setState({
                                  appDownloadPhoneNumber: e.target.value,
                                  showPhoneNoError: false
                                });
                              }}
                              value={this.state.appDownloadPhoneNumber}
                              onBlur={e => {
                                this.setState({
                                  showPhoneNoError: !validateMobile(
                                    e.target.value
                                  )
                                });
                              }}
                            />
                            {this.state.appDownloadPhoneNumber &&
                              this.state.showPhoneNoError &&
                              !validateMobile(
                                this.state.appDownloadPhoneNumber
                              ) && (
                                <span className="error-text">
                                  {validationMessage.phone.validationError}
                                </span>
                              )}
                            {/*<Phone
                                                        country="IN"
                                                        placeholder="Enter phone number"
                                                        value={this.state.value}
                                                    onChange={value => this.setState({ value })} />*/}
                            <span className="input-group-btn">
                              <button
                                className="btn btn-default"
                                type="button"
                                onClick={e => {

                                  e.preventDefault();
                                  if (
                                    !this.state.showPhoneNoError &&
                                    this.state.appDownloadPhoneNumber
                                  ) {
                                    this.props.actions.getAppDownloadLink('+971' + this.state.appDownloadPhoneNumber);
                                  }
                                }}
                              >
                                Text App Link
                              </button>
                            </span>
                          </div>
                        </div>
                        <ul className="list-unstyled">
                          <li>
                            <a href="https://play.google.com/store/apps/details?id=com.carcility">
                              <img
                                src={`${imageBasePath}/images/google-store.png`}
                                alt=""
                              />
                            </a>
                          </li>
                          <li>
                            <a href="https://itunes.apple.com/us/app/carcility/id1352273113">
                              <img
                                src={`${imageBasePath}/images/apple-store.png`}
                                alt=""
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="social-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-12 col-xs-12 pad0">
                      <div className="footer-link">
                        <ul className="social-icon">{socialLink}</ul>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12 pad0">
                      <div className="terms-right">
                        <label className="termsConditionsLink">
                          <a
                            onClick={() => {
                              this.props.router.push(`${appBasePath}/terms`);
                              // window.open(`${appBasePath}/terms`, "_blank");
                            }}
                          >
                            Terms and Conditions{" "}
                          </a>{" "}
                          and{" "}
                          <a
                            onClick={() => {
                              this.props.router.push(`${appBasePath}/privacy`);
                              // window.open(`${appBasePath}/privacy`, "_blank");
                            }}
                          >
                            {" "}
                            Privacy Policy{" "}
                          </a>
                        </label>
                        <span> | </span>
                        <label>
                          &copy;2020 <a>carcility.com</a>.All Rights Reserved
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!this.state.regularFooter && (
              <div className="short-footer">
                <div className="row">
                  <div className="col-md-5 col-sm-12 col-xs-12 pad0">
                    <div className="footer-link">
                      <ul className="quick-links">{shortFooter}</ul>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12 col-xs-12 pad0">
                    <div className="terms-right">
                      <label className="termsConditionsLink">
                        <a
                          onClick={() => {
                            this.props.router.push(`${appBasePath}/terms`);
                          }}
                        >
                          Terms and Conditions{" "}
                        </a>{" "}
                        and{" "}
                        <a
                          onClick={() => {
                            this.props.router.push(`${appBasePath}/privacy`);
                          }}
                        >
                          Privacy Policy{" "}
                        </a>
                      </label>
                      <span> | </span>
                      <label>
                        &copy;2020 <a href="">carcility.com</a>.All Rights
                        Reserved
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
