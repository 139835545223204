import React, { Component } from 'react';
import Slider from 'react-slick';
import map  from 'lodash/map';
import {appBasePath} from "../../../constants/staticData";
import moment from "moment/moment";

class Services extends Component {
  constructor() {
    super();
    this.state = {
      servicesModal: false,
      selectedService: undefined,
      selectedValue :'',
      submissionError : false,
      selectedSubService: []
    }
    this.selectCar = this.selectCar.bind(this);
    this.submitEmergencyData = this.submitEmergencyData.bind(this);
    this.selectedServiceCall = this.selectedServiceCall.bind(this);
  }
  selectCar(selectCar){
    this.setState({selectedValue : selectCar });
    this.setState({submissionError:false});
  }
  selectedServiceCall(selService){
    let data = [];
    let SelecetdId = selService.id;
    data.push(SelecetdId);
    return data;
  }
  submitEmergencyData(){
    let payload;
    let timeStamp = new Date();
    let momentTimeStamp = moment.utc(timeStamp).format();
    if(this.state.selectedValue == ''){
      this.setState({submissionError:true});
    } else {
      this.setState({submissionError:false});
      this.setState({ servicesModal: false });

      payload = {
        "batch_id": uniqid(),
        "serviceid": 4,
        "subserviceid": this.state.selectedSubService,
        "vehicleid": this.state.selectedValue.id,
        "details": '',
        "userpreffereddatetime": momentTimeStamp,
        "location": this.props.address.formatted_address,
        "latitude": this.props.mapPosition.lat.toString(),
        "longitude": this.props.mapPosition.lng.toString(),
        "isurgentrequest": true,
      };
      this.props.actions.createBookingRequest(payload);
      this.props.router.push(appBasePath + '/emergency/active')
    }

  }
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3.3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 4.3,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 5.3,
            slidesToScroll: 5,
          }
        },
      ]
    };
    //[{ name: "My Audi" }, { name: "My Nissan GT-R" }, { name: "My BMW" }]
    return (
      <div className="service-select gf-services-holder">
        <h5>Select a Service</h5>

        { this.props.data.length > 0 &&
        <div className="services-holder ">
          <Slider {...settings}>

            { map(this.props.data, (services, key) => {
              return (
                <div className="img-row" key={key} onClick={() =>
                  this.setState({ servicesModal: true, selectedService: services.name, selectedSubService:this.selectedServiceCall(services)  })
                }>
                  <div className="service-container">
                    <div className="img-holder">
                      <img src={services.image} alt="" className="service-img" />
                    </div>
                    <span>{services.name}</span>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        }

      </div>
    );
  }
}

export default Services;
