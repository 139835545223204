import React, { Component } from 'react';
import map  from 'lodash/map';
import CarType from './CarType';
import { appBasePath } from '../../constants/staticData';

export default class JobUpdate extends Component {
  constructor(...args) {
    super(...args);
    this.clickRoute = null,
      this.state = {

        open: false,
        currentWidth: '',
        parentContainer: null,
      };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.windowWidth = this.windowWidth.bind(this);
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.jobHolder)
      this.setState({
        parentContainer: this.jobHolder
      })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  updateDimensions() {
    const windowWidth = this.windowWidth();
    this.setState({ 'currentWidth': windowWidth });
  }
  windowWidth() {
    let docElemProp = window.document.documentElement.clientWidth,
      body = window.document.body;
    return window.document.compatMode === "CSS1Compat" && docElemProp || body && body.clientWidth || docElemProp;
  }
  collapseCard() {

  }
  render() {
    const { infoStatus, statusType, statusTitle, statusTime, statusProcess, router } = this.props;
    let jobLeftGridValue = "";
    let jobRightGridValue = "";
    let infoClass = 'jobInfo ';
    let statusClass = 'status-popup';
    if (this.state.currentWidth <= 1155 && this.state.currentWidth >= 992) {
      jobLeftGridValue = "col-md-7";
      jobRightGridValue = "col-md-5";
    } else if (this.state.currentWidth <= 1345 && this.state.currentWidth >= 1155) {
      jobLeftGridValue = "col-md-6";
      jobRightGridValue = "col-md-6";
    } else {
      jobLeftGridValue = "col-md-5";
      jobRightGridValue = "col-md-7";
    }

    let jobData = this.props.allOpenRequests.length  &&  this.props.allOpenRequests;
    return (
      <div className="jobUpdate-holder" ref={(ref) => this.jobHolder = ref}>
        <h4 className="job-update-title">Job Status</h4>
        <a className="jobUpdate-viewall" onClick={() => { router.push(`${appBasePath}/request`) }}>View All</a>
        {/* https://projects.invisionapp.com/d/main#/console/10950794/238371645/preview */}

        {map(jobData, (cardDetails, key) => {

          return (
            <div key={key}>
              {<CarType
                source={'dashboard-plus'}
                authReducer={this.props.authReducer}
                userPaymentStatus={this.props.userPaymentStatus}
                bookingRequestReducer={this.props.bookingRequestReducer}
                actions={this.props.actions}
                router={router} key={key} cardDetails={cardDetails}
                jobLeftGridValue={jobLeftGridValue}
                jobRightGridValue={jobRightGridValue}
                vendorQuotesCount={cardDetails && cardDetails.totalJobQuotes}
                messageClick={() => {this.clickRoute = "message"}}
                messageRoute={() => {
                  if (cardDetails.requestid && this.clickRoute) {
                    if (this.clickRoute == 'message' && cardDetails && cardDetails.garagejobquotes && cardDetails.garagejobquotes.length) {
                      const garageId = cardDetails.garagejobquotes[0].garageId;
                      const userbookingrequestId = cardDetails.garagejobquotes[0].userbookingrequestId;
                      router.push(`${appBasePath}/request/${cardDetails.requestid}/?jobid=${userbookingrequestId}&view=message&garage_id=${garageId}`);
                    } else {
                      router.push(`${appBasePath}/request/${cardDetails.requestid}/${this.clickRoute}`);
                    }
                  } else if (cardDetails.requestid) {
                    router.push(appBasePath + '/request/' + cardDetails.requestid);
                  }
                }}
                quotesClick={(e) => {
                  e.stopPropagation();
                  this.clickRoute = "quotes";
                  if (cardDetails.requestid && this.clickRoute) {
                    router.push(`${appBasePath}/request/${cardDetails.requestid}?view=${this.clickRoute}`);
                  } else if (cardDetails.requestid) {
                    router.push(appBasePath + '/request/' + cardDetails.requestid);
                  }
                }}
                parentRef={this.state.parentContainer} />}
            </div>
          )
        })}
      </div>
    );
  }
}
