import React, { Component } from 'react';
import IconNotification from '../common/IconNotification';
import Button from '../common/Button';
import { Media } from 'react-bootstrap';
import { round } from 'lodash';
import { imageBasePath } from '../../constants/staticData';
import TimingsView from "../common/TimingsView";
import { defaultImageOnError } from '../../helpers';

class QuotesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavouriteVisible: false,
    };
  }
  render() {
    const { index, vendorName, rating, distance, reviews, totalamount, finalAmount, discountAmount, currency, vendorImage, requestId, jobId, vendorId, router, isFavourite, userRequestId,timings } = this.props;
    return (
      <div className={this.props.activeClass == "active" ? "jobcard box active" : "jobcard box"} onMouseMove={() => { this.props.ClickedQuoteCard(vendorId); }} ref={index}>
        <div className="box-content">
       {/*   <Media onClick={() => { vendorId &&
            router.push(appBasePath + '/vendor-profile/' + `${vendorId}-${vendorName.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`)}}>*/}
            <Media>
            <Media.Left>
              <div className="vendor-brand-holder">
                <img src={vendorImage ? vendorImage : `${imageBasePath}/images/placeholder.png`} alt="Image"
                onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}}/>
              </div>
            </Media.Left>

            <Media.Body>
              <Media.Heading>{index} {vendorName} <span className={isFavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"} onClick={(e) => this.props.removeFavourite(e, !isFavourite, vendorId, userRequestId)} /></Media.Heading>
              <div className="rating rating-left">
                <span className={rating > 0 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className="rating-text">{isNaN(round(rating)) ? ' ' : round(rating)} <span className="total-reviews">({reviews} reviews)</span></span></div>

              <TimingsView {...this.props}  timings={timings} distance={distance}/>
            </Media.Body>
          </Media>
          <div className="box-footer">

            <div className="footer-container">
              <Button btnType="transparent" btnSize="sm" fontSize={13} label="View Quote" btnCallBack={() => this.props.btnClickFunc(requestId, jobId)} />

              {discountAmount?
              <div className="quotes-cost-container">
                <div className="original-cost">{Number(totalamount).toFixed(2)} {currency}
                </div>
                <div className="offer-cost" >{Number(finalAmount).toFixed(2)} {currency}
                </div>
              </div>
                :
                  <div className="quotes-cost-container">
                    <div className="offer-no-cost">{Number(finalAmount).toFixed(2)} {currency}
                    </div>
                  </div>
              }

              <div className="box-message jobCard-meaasage">
                <IconNotification iconType="comment-processing-outline" iconLabel="Message" notifyClass="notification" viewMessaging={() => { this.props.viewMessaging(jobId) }} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default QuotesCard;
