import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath } from '../../constants/staticData';

export default class EmptyUpdates extends Component {

  render() {
    const { router } = this.props;
    // this.props.router.push('repair')
    return (
      <div className="welcome-banner-row">
        <div className="offer-heading"><h5>Job Updates</h5></div>
        <div className="welcome-banner empty-banner empty">
          <div className="container-fluid">
            <div className="col-md-8 col-sm-8 col-xs-12">
              <h4>Oops! Look's like you don't have any requests yet.</h4>
              <p>
                Fortunately, it’s easy to create one
              </p>
              <Button btnType="red" btnSize="lg" fontSize={16} label="Book Service" btnCallBack={this.props.btnCallBack.bind(this)} />
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img src={`${imageBasePath}/images/empty-jobs.png`} className="empty-job" alt="" />
            </div>
          </div>
        </div>
      </div>

    );
  }
}
