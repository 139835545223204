import React, { Component } from 'react';
import Header from '../../common/Header';
import Sidebar from '../../common/Sidebar';
import Footer from '../../common/Footer';
import QuickWashSteps from './QuickWashSteps';

import MobileNotification from '../../common/MobileNotification';
import MobileMessage from '../../common/MobileMessage';
import each  from 'lodash/each';
import map  from 'lodash/map';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import difference  from 'lodash/difference';

import { queryStringtoArray } from '../../../helpers/index';
import CustomModal from '../../common/CustomModal';
import { Modal } from 'react-bootstrap';
import ToastMessage from '../../common/ToastMessage';
import MobileHeaderSearch from '../../common/MobileHeaderSearch'
import { imageBasePath, appBasePath } from '../../../constants/staticData';
import Button from "../../common/Button";
var uniqid = require('uniqid');

export default class QuickCarWash extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    var serviceFetch = localStorage.getItem('servicesFetched');
    var CarwashCategoryData = JSON.parse(localStorage.getItem('washCategoryData'));
    this.state = {
      searchMobile:false,
      hideStep1Panel:false,
      infoModalData: undefined,
      infoModal: false,
      notificationVisible: false,
      editClicked: false,
      messageVisible: false,
      getCarWashData: CarwashCategoryData  ? CarwashCategoryData : [],
      searchGetCarWashData: CarwashCategoryData ? CarwashCategoryData : [],
      categoriesSelectedCount: JSON.parse(localStorage.getItem("selectedCarWashDataArray") || 0).length,
      selectedCarWashDataArray: JSON.parse(localStorage.getItem("selectedCarWashDataArray") || "[]"),
      selectedCarProfile: undefined,
      promotionalOffersData: [],
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      garageProfileDetails: '',
      showError: undefined,
      servicesFetched: serviceFetch =="true" ? true: false,
      offerFromCreateCarProfile: this.props.location  && this.props.location.state && this.props.location.state.offerFromCreateCarProfile ? this.props.location.state.offerFromCreateCarProfile : false,
      promoCodesModal: false,
      selectedPromoCode: localStorage.getItem("selectedPromoCode") || "",
      isPromoCodeUpdated: false,
      promotionalOffersIsApproved: false,
      popUpPromoCode: '',
      promoPopupError:0,
      nonSelectedFirstItem:"",
      promoApplied:false,
    };

    this.removeItem = this.removeItem.bind(this);
    this.submitPromoCodeData = this.submitPromoCodeData.bind(this);
    this.updateRadioButton = this.updateRadioButton.bind(this);


  }
 



  componentWillMount() {

    

    if(! localStorage.getItem('bookingRequestId') ||  ! localStorage.getItem('serviceId') ){
      this.props.router.push(`${appBasePath}/dashboard-plus`)
    }else if( localStorage.getItem('serviceId') == "2"){
      this.props.router.push(`${appBasePath}/quick-booking/car-service`)
    }else{
      if( localStorage.getItem('serviceId') != "1")
        this.props.router.push(`${appBasePath}/quick-booking/car-repair`)
    }


    this.props.actions.hideToastMessage();
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }
    if (queryStringtoArray('vendor_id')) {
      this.props.actions.getGarageProfileDetails(queryStringtoArray('vendor_id'));
    }
    if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
      console.log('💔 💔 💔 0 ', this.props.commonReducer.promotionalOffersData);
      this.props.actions.getPromotionalOffers();
    }




  }

  componentWillReceiveProps(nextProps) {
    let selectedCarProfile = undefined;
    if (queryStringtoArray('car_id')) {
      map(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles, (carProfile) => {
        if (queryStringtoArray('car_id') == carProfile.id) {
          selectedCarProfile = carProfile;
        }
      });
      this.setState({
        ...this.state,
        selectedCarProfile: selectedCarProfile,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCarProfile: nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles && nextProps.carProfileReducer.carProfiles[Object.keys(nextProps.carProfileReducer.carProfiles)[0]],
      });
    }

    let getVendorsCarWashData = [];
    if (!queryStringtoArray('vendor_id') && nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.getCarWashData && !this.state.getCarWashData.length && this.state.searchGetCarWashData && !this.state.searchGetCarWashData.length) {
      this.setState({
        ...this.state,
        getCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        searchGetCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   //<ToastMessage show={this.state.showToast}/>
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    // }
    else if (queryStringtoArray('vendor_id') && nextProps && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services && nextProps.bookingRequestReducer.servicesFetched) {
      if(nextProps.commonReducer.garageProfileDetails.garage_services.length == 0){
        this.setState({
          ...this.state,
          getCarWashData: [],
          searchGetCarWashData: [],
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      }else{
        nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData && nextProps.bookingRequestReducer.washCategoryData.map((allSubService, key) => {
          nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
            if (allSubService.id === vendorSubService.serviceId) {
              getVendorsCarWashData.push(allSubService);
            }
          });
        });
        //if (getVendorsCarWashData.length) {
          this.setState({
            ...this.state,
            getCarWashData: getVendorsCarWashData,
            searchGetCarWashData: getVendorsCarWashData,
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        //}
      }
      if (queryStringtoArray('serviceId')) {
        this.changeCheckboxState({ target: { checked: true } }, Number(queryStringtoArray('serviceId')));
      }
    }

    if(! this.state.offerFromCreateCarProfile){
      this.setState({
        promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
        selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      });
    }


    let promoCategories = [];
    this.state.selectedPromotionalOffersData && each(this.state.selectedPromotionalOffersData.services, (promoCategory) => {
     promoCategories.push(promoCategory.serviceId);
    //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);

    });
    console.log('promoCategories --->',promoCategories);
    promoCategories && promoCategories.length && this.setState({
      selectedCarWashDataArray: promoCategories,
      categoriesSelectedCount: promoCategories.length,

    });

    if (nextProps && nextProps.showError) {
      this.setState({ isButtonLoading: false, });

    }



    if(nextProps.commonReducer && nextProps.commonReducer.promotionalOffersMessage && this.state.selectedPromoCode && this.state.isPromoCodeUpdated ){

      this.setState({
        promotionalOffersMessage:nextProps.commonReducer.promotionalOffersMessage,
        promotionalOffersIsApproved:nextProps.commonReducer.promotionalOffersIsApproved });
    }
    if(this.state.selectedPromoCode){
      this.setState({
        promotionalOffersIsApproved:true,
        
        
      })
    }


    if(this.state.selectedPromoCode && this.state.selectedPromoCode != "undefined" && this.state.selectedCarProfile && ! this.state.promoApplied){
      var promotionData = {
        place: 0,
        services: this.state.selectedCarWashDataArray,
        promo_code: this.state.selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.setState({ promoApplied:true, isPromoCodeUpdated: true,});

      // this.props.actions.applyPromotionalOffers(promotionData);
    }



  }


  submitPromoCodeData(isFromPopUp){


    let selectedPromoCode = this.state.selectedPromoCode;

    this.setState({
      isPromoCodeUpdated: true,
    });

    if(isFromPopUp){
      selectedPromoCode =  this.state.popUpPromoCode;
      if(selectedPromoCode)
        this.setState({ promoCodesModal: false, selectedPromoCode:selectedPromoCode});

    }

    if(selectedPromoCode){
      var promotionData = {
        place: 0,
        services: this.state.selectedCarWashDataArray,
        promo_code: selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.props.actions.applyPromotionalOffers(promotionData);

    }


  }



  updateRadioButton(event,promoId) {
    
    var promotionalOffersData =  this.state.promotionalOffersData;
    var promotionalOffersData1 = promotionalOffersData.filter((promoCode)=>{return promoCode.id === promoId});
    
    var name = promotionalOffersData1[event.target.value].promoCode;

    this.setState({ popUpPromoCode: name});


  }
  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails,
    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible});
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  renderCarType(carDetails, key) {
    if (carDetails) {

      let checked = false;


      if(this.state.selectedCarWashDataArray && this.state.selectedCarWashDataArray.includes(carDetails.id)){
        checked = true;
      }

      return (
        <div className="sub-collapse-panel wash-collapse quick-booking-collapse col-md-3  col-sm-3  col-xs-6" key={key}>
          <span className="checkbox-style checkbox-wrapper">
            {this.state.selectedCarWashDataArray.indexOf(carDetails.id) > -1 && <i className="mdi mdi-checkbox-marked-circle selected-icon"></i> }
            <label htmlFor={carDetails.id}>
              <div className={this.state.selectedCarWashDataArray.indexOf(carDetails.id) > -1 ? "sub-collapse-panel-head active" : this.state.nonSelectedFirstItem == carDetails.id ?  "sub-collapse-panel-head sub-collapse-panel-head-hover" : "sub-collapse-panel-head "} >
                <figure>
                  <img src={carDetails.image ? carDetails.image : `${imageBasePath}/images/placeholder.png`} alt="" />
                </figure>
                <h4>{carDetails.name}</h4>
                <div className="select-type">
                  <input type="checkbox" id={carDetails.id} checked={checked ? checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id); }} value="" />
                  <label htmlFor={carDetails.id} className="custom-checkbox"></label>
                </div>
              </div>
            </label>
          </span>
        </div>
      );
    }
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile,
    });
  }

  changeCheckboxState(e, categoryId) {

    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const filteredCarWashCategories = cloneDeep(this.state.getCarWashData);
    let findCategoryIndex = findIndex(filteredCarWashCategories, { id: categoryId });
    filteredCarWashCategories[findCategoryIndex]['checked'] = isChecked;
    if (isChecked) {
      if(!this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarWashDataArray.push(filteredCarWashCategories[findCategoryIndex].id);
      }
    } else {
      if(this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarWashDataArray, (n) => {
          return n != filteredCarWashCategories[findCategoryIndex].id;
        });
      }
    }
    if (!removedCategoriesArray) {
      removedCategoriesArray = this.state.selectedCarWashDataArray;
    }


    localStorage.setItem('selectedCarWashDataArray',JSON.stringify(removedCategoriesArray));

    this.setState({
      ...this.state,
      selectedCarWashDataArray: removedCategoriesArray,
      categoriesSelectedCount: removedCategoriesArray.length,
      searchGetCarWashData: filteredCarWashCategories,
      getCarWashData: filteredCarWashCategories,
    });
  }



  //******* Search Categories **********************************************************************************************************************************/
  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.getCarWashData);
      let filteredCarWashCategories = [];
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          filteredCarWashCategories.push(carDetails);
        }
      });
      this.setState({
        ...this.state,
        searchGetCarWashData: filteredCarWashCategories,
      });
    } else {
      this.setState({
        ...this.state,
        searchGetCarWashData: cloneDeep(this.state.getCarWashData)
      });
    }
  }

  disableEdit(){
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  activateLoader(){
    this.setState({isButtonLoading:true})
  }

  deactivateLoader(){

   this.setState({isButtonLoading:false})

  }


  triggerChildFunction(){
    this.refs.QuickWashSteps.requestQuotes();
  }



  openPromoModal(){

    this.setState({
      promoCodesModal: true
    });
  }

  triggerChildValidationFunction(){

    let validation = this.refs.QuickWashSteps.requestQuotesValidate();

    console.log("validation",validation);
    if(validation){
      this.setState({showSummary: true,})
    }
  }


  removeItem(categoryId){



    let count = 1;
    let removedCategoriesArray;
    const filteredCarWashCategories = cloneDeep(this.state.getCarWashData);
    let findCategoryIndex = findIndex(filteredCarWashCategories, { id: categoryId });
    filteredCarWashCategories[findCategoryIndex]['checked'] = false;
      if(this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarWashDataArray, (n) => {
          return n != filteredCarWashCategories[findCategoryIndex].id;
        });
      }
    if (!removedCategoriesArray) {
      removedCategoriesArray = this.state.selectedCarWashDataArray;
    }
    localStorage.setItem('selectedCarWashDataArray',JSON.stringify(removedCategoriesArray));

    this.setState({
      ...this.state,
      selectedCarWashDataArray: removedCategoriesArray,
      categoriesSelectedCount: count,
      searchGetCarWashData: filteredCarWashCategories,
      getCarWashData: filteredCarWashCategories,
    });



  }

  moreServices(){

    this.state.searchGetCarWashData  && this.state.searchGetCarWashData.length > 0 && each(this.state.searchGetCarWashData, (carDetails) => {

      let checked = false;


      if(this.state.selectedCarWashDataArray && this.state.selectedCarWashDataArray.includes(carDetails.id)){
        checked = true;
      }


      if(! checked){

        this.setState({
          nonSelectedFirstItem: carDetails.id
        })
        return false

      }

    });

    }
  //********************************************************************************************************************************************************** */
  render() {
    const { commonReducer, actions, router,bookingRequestReducer} = this.props;
    

    let isCoverImage = "";
    this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        isCoverImage =  (
          <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
          </div>
        );
      }
    });

    if(! isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length){
      this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
        if (value.type == "vehicle") {
          isCoverImage =  (
            <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
            </div>
          );
        }
      });
    }



    let leftBlock = [];
    let catLength = 0;


    this.state.searchGetCarWashData  && this.state.searchGetCarWashData.length > 0 && each(this.state.searchGetCarWashData, (carDetails) => {



          catLength += 1;
        leftBlock.push(this.renderCarType(carDetails, catLength));
      });



    const selectedCarWashData = this.state.getCarWashData && this.state.getCarWashData.map((carDetails, key) => {


      let checked = false;
      if(this.state.selectedCarWashDataArray && this.state.selectedCarWashDataArray.includes(carDetails.id)){
        checked = true;
      }

      if (checked) {
        return (
          <li key={key}  onClick={(e) => {

            this.removeItem(carDetails.id);

          }}  >
              <img src={carDetails.image ? carDetails.image : `${imageBasePath}/images/placeholder.png`} alt="" />
            <label>{carDetails.name}</label>
            <img src={`${imageBasePath}/images/main_close.png`} className="close-img"/>
          </li>
        );
      }
    });



    //Filter the offers based on the Services that was choosen
    let validPromoCode = [];
    if(this.state.selectedCarWashDataArray.length){

      validPromoCode = this.state.promotionalOffersData.filter((promoCode) => {
        const serviceIds = map(promoCode.services, 'serviceId');
        return difference(this.state.selectedCarWashDataArray, serviceIds).length === 0;
      });

    }



    let promoCodesHtml = validPromoCode.map((promoCode, index) => {


      var validPromo = false;

      promoCode.services.map((service) => {

        if(service.parentId == 1){
          validPromo = true;
        }

      });
      if (! validPromo){
        return;
      }

      var radioId = "radio-"+promoCode.id;
      return (<div className="form-check promo-item" key={promoCode.id}>
          <input id={radioId}  className="radio-custom"  onClick={ e => this.updateRadioButton(e,promoCode.id) }   value={0} name="radio-group" type="radio"/>
          <label htmlFor={radioId} className="radio-custom-label">{promoCode.promoCode}</label>
          <p>
            {promoCode.description}
          </p>

          <a
            onClick={() => {

              // let route = this.$router.resolve({path: '/offer-terms'});
              // let route = this.$router.resolve('/link/to/page'); // This also works.
              window.open('/offer-terms/'+promoCode.id, '_blank');





              /* router.push({
                 pathname: appBasePath + '/offer-terms',
                 state: { promotionalOffersTerms: promoCode.termsAndConditions }
               });*/


            }}
          >*Terms and Conditions Apply</a>
        </div>

      );

    });



    let authData = localStorage.getItem('authData');
    authData = authData ? JSON.parse(authData) : '';


    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header quick-booking-header">
            <div className="padwrapper">
              <span className="back-button"><i className="mdi mdi-arrow-left "></i></span>
              <h4>We can help you get the best car service</h4>


                {leftBlock.length > 0 && !this.state.hideStep1Panel ?

                  <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                    <li className=""><a onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }  >Home</a></li>
                    <li className="active"><span>Car Wash</span></li>
                  </ol>


                  :
                  <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                    <li className=""><a  onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }   >Home</a></li>
                    <li className=""><a  onClick={() =>
                      this.setState({ hideStep1Panel: false, showSummary: false})
                    }   >Car Wash</a></li>
                    <li className="active"><span>Book Service</span></li>
                  </ol>
                }
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                {this.state.getCarWashData && <div className="myCar-body col-md-8">
                  <QuickWashSteps
                    ref="QuickWashSteps"
                    commonReducer = {commonReducer}
                    actions = {actions}
                    servicesFetched = {this.state.servicesFetched}
                    showError = {this.props.bookingRequestReducer.toastType}
                    categoriesSelectedCount= {this.state.categoriesSelectedCount}
                    router = {this.props.router}
                    selectedCarProfile = {this.state.selectedCarProfile}
                    carProfiles = {this.props.carProfileReducer.carProfiles}
                    selectedCarWashDataArray = {this.state.selectedCarWashDataArray}
                    selectedPromoCode = {this.state.selectedPromoCode}
                    hideStep1Panel = {this.state.hideStep1Panel}
                    showSummary = {this.state.showSummary}
                    viewBlock = {leftBlock}
                    searchView = {this.filterCarType.bind(this)}
                    activateLoader = {this.activateLoader.bind(this)}
                    deactivateLoader = {this.deactivateLoader.bind(this)}
                    editClicked = {this.state.editClicked}
                    disableEdit = {this.disableEdit.bind(this)}
                    changeCarProfile = {this.changeCarProfile.bind(this)}
                    garageProfileDetails = {queryStringtoArray('vendor_id') && this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                  />



                </div>}
                <div className="myCar-body col-md-4">

                  <div className="car-summary quick-booking-user">
                    <div className="summary-heading">
                      Hi <span>{authData ? authData.name : ''} !</span>
                    </div>
                    <p>
                      It’s great to see that you are booking a service !
                    </p>



                  </div>

                  <div className={this.state.showSummary? "show-car-summary quick-booking-summary":"car-summary quick-booking-summary show-quick-booking-summary"} >
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {isCoverImage ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/default-guest-car-profile.jpg`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc summary-car-detail">
                            <h5>{this.state.selectedCarProfile.name}
                              <span className="edit-car">
                              <a  onClick={() =>  this.props.router.push(`${appBasePath}/car-profiles/${this.state.selectedCarProfile.id}/edit`) }  ><i className="mdi mdi-pencil edit-car-icon"></i> Edit</a>
                            </span>
                            </h5>
                            <label>{this.state.selectedCarProfile.model}</label>
                            <label>Make: {this.state.selectedCarProfile.make}, Year: {this.state.selectedCarProfile.year}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    <div className="category-wrapper">
                      <div className="category-heading">
                        {this.state.categoriesSelectedCount > 0 ?<h5>Services Selected</h5> : <h5>No Services Selected</h5> }
                        {this.state.categoriesSelectedCount > 0 ?
                          <spna>
                            <label className="more-service-web" onClick={() => {
                              window.scrollTo(0, 0)

                              this.setState({ hideStep1Panel: false, showSummary:false })

                              this.moreServices();

                            }}>
                              <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                            </label>
                            <label  className="more-service-mob"   onClick={() => {

                              window.scrollTo(0, 0)
                              this.setState({ hideStep1Panel: false, showSummary:false })
                              this.moreServices();

                            }}>
                              <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                            </label>

                          </spna>
                          :
                          []}
                      </div>
                      <div className="category-list wash-category-list ">
                        <ul>
                          {selectedCarWashData}
                        </ul>
                      </div>
                    </div>
                    <div className="category-wrapper promo-wrapper">
                      <div className="category-heading">

                        {this.state.promotionalOffersIsApproved?

                          <div className="promo-success">
                            <span className="text">Promo Code</span>
                            <span className="code">‘{this.state.selectedPromoCode}’</span>
                            <span className="button"  onClick={() => {

                              this.setState({
                                promotionalOffersMessage:"",
                                selectedPromoCode:"",
                                promotionalOffersIsApproved:false });


                            }}>
                              remove
                            </span>
                            {this.state.selectedPromoCode &&
                            <div className="promo-page-error">{this.state.promotionalOffersMessage}</div>
                            }
                          </div>

                        :
                          <div>
                            <h5>Have a Promo Code ?</h5>
                            <label onClick={this.openPromoModal.bind(this)}>
                              <i className="mdi mdi-plus more-service-icon"></i><a>Apply</a>
                            </label>
                           

                          </div>
                        }



                      </div>
                    </div>


                    <div className="car-wrapper  web-navigation-button">
                      <div className="">
                        <div className="type-desc full-width">
                          {leftBlock.length  > 0 && ! this.state.hideStep1Panel? <div className="next-button">
                              <Button disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {   this.setState({ hideStep1Panel: true }) }} />
                            </div>:

                            <div className="next-button clearfix">
                              <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                              <label className="label-text">Request will be sent to multiple service providers.</label>

                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="sidebar sidebar-next-button show" style={{ backgroundColor:'#fff' }}>



            {!this.state.hideStep1Panel ?
              <div className="next-button">
                <Button disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red"
                        btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {
                  this.setState({hideStep1Panel: true})
                }}/>
              </div>
              :
              this.state.hideStep1Panel && ! this.state.showSummary?
              <div className="next-button">
                <Button disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red"
                        btnSize="sm" fontSize={14} label="Show Summary"
                        btnCallBack={this.triggerChildValidationFunction.bind(this)}

                />
              </div>

                :
                <div className="next-button">
                  <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                  <label className="label-text">Request will be sent to multiple service providers.</label>

                </div>
            }




        </div>
        <CustomModal showModal={this.state.promoCodesModal} footer={validPromoCode.length > 0? true: false} cancelText="Cancel" saveText="Apply"
                     title="Add Promocode"
                     submitCallBack ={() => { this.submitPromoCodeData(true);  }}
                     closeIcon={true}  hideModal={() => { this.setState({ promoCodesModal: false }) }}>
          <Modal.Body>
            <div className="emgcy-modal">


              <div className="holder">



                {validPromoCode.length > 0?  <form> {promoCodesHtml}  </form> :  <div className="promo-empty"> <img src={'/images/offers_empty.png'} alt='' /> <p> Oops! Looks like you don't have any Promo Offers. </p>  </div> }



              </div>

            </div>
          </Modal.Body>
        </CustomModal>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection  quick-footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false,}) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
