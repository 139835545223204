import React, { Component }  from 'react';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { validateMobile } from '../../helpers/index';
import { imageBasePath,  validationMessage } from '../../constants/staticData';


export default class DownloadApp extends Component {
    constructor(props) {
      super(props);
      this.state = {
        appDownloadPhoneNumber:'',
        showPhoneNoError: false

      }
    }

    render(){
    return(
        <Element name="download" className="element">
          <div id="download-section">
            {/*<LazyLoad>*/}
              <div className="downlaod-content container">
                <div className="row">
                  <div className="col-md-offset-4 col-md-8 col-sm-12 col-xs-12 margin-align">
                    <div className="download-desc">
                    <div className="download-text">
                      <div className="mod-image-show">
                        <img src={`${imageBasePath}/images/download-app-logo.png`} alt="car service booking app dubai" />
                      </div>
                      <div className="wow slideInDown">
                          <h5>Give your car the best care. Anytime, anywhere.</h5>
                          <p>Download the Carcility Service App, so that you can access, compare and book services from our trusted network of service providers with confidence whenever, wherever.</p>
                        </div>
                      </div>
                      <div className="wow slideInUp">
                        <label>Download the app!</label>
                        <div className="download-form">
                          <form className="form-inline">
                            <div className="input-group">
                                <span className="countryCode">+971</span>
                                  <input
                                    placeholder="Enter Mobile No"
                                    type="number"
                                    className="form-control"
                                     onChange={e => {
                                        this.setState({ appDownloadPhoneNumber: e.target.value, showPhoneNoError: false });
                                     }}
                                        value={this.state.appDownloadPhoneNumber}
                                        onBlur={e => {
                                          this.setState({ showPhoneNoError: !validateMobile(e.target.value) });
                                        }}
                                      />
                                      {this.state.appDownloadPhoneNumber &&
                                      this.state.showPhoneNoError &&
                                      !validateMobile(this.state.appDownloadPhoneNumber) &&
                                      <span className="error-text">{validationMessage.phone.validationError}</span>}
                                      <span className="input-group-btn" data-labelledby="Text App Link111">
                                        <button
                                          data-labelledby="Text App Link"
                                          className="btn btn-default"
                                          type="button"
                                          onClick={e => {
                                            e.preventDefault();
                                            if (!this.state.showPhoneNoError && this.state.appDownloadPhoneNumber) {
                                              this.props.actions.getAppDownloadLink('+971' + this.state.appDownloadPhoneNumber);
                                            }
                                          }}
                                        >
                                          Get App Link
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </div>
                                <ul className="list-unstyled">
                                  <li>
                                   <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                                    <img src={`${imageBasePath}/images/landing/car-service-app-apple-store.svg`} alt="car service app - app store" />
                                   </a>
                                  </li>
                                  <li>
                                    <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                                      <img src={`${imageBasePath}/images/landing/car-service-app-googleplay-store.svg`} alt="car service app - play store" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mob-img col-md-4">
                          <img src={`${imageBasePath}/images/landing/car-service-booking-app-dubai.png`} alt="car service booking app dubai" className="download-app-img"/>
                        </div>
                      </div>
                   {/* </LazyLoad>*/}
                    </div>
                </Element>
        
    );
}
}