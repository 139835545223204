import React, { Component } from 'react';
import {  MenuItem} from 'react-bootstrap';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import QuickHeader from '../common/QuickHeader';
import Footer from '../Landing/Footer';
import { Link } from 'react-scroll';
import { imageBasePath, appBasePath, } from '../../constants/staticData';
import TagManager from 'react-gtm-module';
import has  from 'lodash/has';
import map  from 'lodash/map';
import range  from 'lodash/range';
import findIndex  from 'lodash/findIndex';
import TextInput from '../common/TextInput';
import Button from '../common/Button';
import { validationMessage } from '../../constants/staticData';
var uniqid = require('uniqid');
import { validateFormSubmit } from '../../helpers';
import CustomScroll from 'react-custom-scroll';
import Review from "../common/Review";
import ToastMessage from "../common/ToastMessage";

class QuickBookingCarWash extends Component {
  constructor(props) {
    super(props);

    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });

    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : {};
    this.state = {
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : "",


      yearListDD: false,
      open: false,
      isButtonLoading: false,
      "selectedYear": "Year",
      "allYears": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      "serviceListDD": false,
      "selectedService": "Select Service",
      "allService": [],
      activeRequestType: "",
      activeRequestTypeLabel: "",
      selectedCarMakeKey: '',
      isMakeandModelLoaded: false,
    };




    this.initialFormData = {
      "name": '',
      "email": '',
      "phone": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "serviceid": 1,
      "terms": true,
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      //"name": false,
      "email": false,
      "phone": false,
      "make": false,
      "model": false,
      "model_id": false,
      "year": false,
      "plate_no": false,
      "serviceid": false,
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.chooseCarDetails = this.chooseCarDetails.bind(this);

  }
  componentWillMount() {
    window.scrollTo(0,0);


    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    const userId = localStorage.getItem("userId");
    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      this.props.router.push(`${appBasePath}/dashboard-plus`);
    }

    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
      this.props.actions.getManufacturers();
    }

    //this.props.actions.getAllBookingRequests('all', 5, 0);

  }
  componentWillReceiveProps(nextProps) {


    if (nextProps.tabOpen == "panel4") {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ 'isButtonLoading': false });
    }

    if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.getAllServices) {
      let quickBookingServices = [];
      nextProps.bookingRequestReducer.getAllServices.forEach((service) => {
        if (service.name !== "Emergency Service") {
          quickBookingServices.push(service);
        }
      });
      this.setState({
        allService: quickBookingServices,
      });
    }

    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;

      this.setState({
        allManufacturer: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        filteredCarModelList: carModel,
      });
    }
  }

  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,

    });
  }

  // dropdownOpen(val) {
  //   if (val == 'makeDropdown') {
  //     this.formData['year'] = '';
  //     this.formData['model'] = '';
  //     this.formData['make'] = '';
  //     this.setState({ makeDropdown: true, modelDropdown: false, yearDropdown: false });
  //   } else if (val == 'modelDropdown') {
  //     this.formData['year'] = '';
  //     this.formData['model'] = '';
  //     this.setState({ makeDropdown: false, modelDropdown: true, yearDropdown: false });
  //   } else if (val == 'yearDropdown') {
  //     this.formData['year'] = '';
  //     this.setState({ makeDropdown: false, modelDropdown: false, yearDropdown: true });
  //   }
  // }

  onSubmit(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      console.log(formData);
      console.log(validatedForm.validForm);
      console.log(validatedForm);
      console.log(this.errors);

      this.setState({ submissionError: true });
      return;
    } else {

      this.setState({ submissionError: false, isButtonLoading: true });
      let phone_no = this.formData.phone;
      let payload = {
        "batch_id": uniqid(),
        "name": this.formData.name,
        "email": this.formData.email,
        "phone": '+971' + phone_no,
        "make": this.formData.make,
        "model": this.formData.model,
        "model_id": this.formData.model_id,
        "year": this.formData.year,
        "plate_no": this.formData.plate_no,
        "serviceid": this.formData.serviceid,
        "flow": "quickbooking",
        "provider": "normal",
        "subtype": "quick-booking"
      };
      if (this.props && this.props.activeRequestType == "repair") {
        payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
          payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
      }
      this.props.actions.quickBookingUserSignUp(payload);
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  render() {

    const tagManagerArgs = {
      dataLayer: {
      pageCategory: 'Quick and Easy Booking User Sign Up',
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
    const carList = this.state.filteredCarList ? this.state.filteredCarList : this.state.allManufacturer;
    let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
    const carModelLoaded = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].isCarModelLoaded;

    const carManufacterView = map(carList, (carItem, key) => {
      return (
        <MenuItem

          key={key}
          onClick={() => {
            this.formData["make"] = carItem.name;
            this.props.actions.getCarModels(carItem.id);
            this.setState({
              selectedManufacturer: carItem.name,
              carModel: carItem.carmodel,
              selectedCarMakeKey: carItem.id,
              filteredCarModelList: carItem.carmodel,
              allFilteredCarModelList: carItem.carmodel
            });
            this.errors["make"] = false;
            this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });

    const carModalList = this.state.isMakeandModelLoaded && this.state.filteredCarModelList;

    const carModelView = this.state.isMakeandModelLoaded && map(this.state.filteredCarModelList, (carItem, key) => {
      return (
        <MenuItem
          key={key}
          onClick={() => {
            this.formData['model_id'] = carItem.id;
            this.formData['model'] = carItem.name;
            this.setState({ selectedModel: carItem.name });
            this.setState({ 'modelListDD': !this.state.modelListDD });
            this.errors["model_id"] = false;
            this.errors["model"] = false;
          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });
    const { router, actions, commonReducer } = this.props;


    console.log(":this.errors['year']", this.errors['year']);
    return (
      <div className="guf">

        <QuickHeader
          hideOption={() => this.setState({ searchMobile: true })}
          pageType="signIn"
          headerClickAction={e => {
            e.preventDefault();
            router.push(`${appBasePath}/sign-in`);
          }}
          metaTagKey={'quickBookingWash'}
        />
        {this.props.bookingRequestReducer.toastMessage &&
        this.props.bookingRequestReducer.toastType &&
        <ToastMessage
          actions={this.props.actions}
          type={this.props.bookingRequestReducer.toastType}
          title={this.props.bookingRequestReducer.toastType + '...'}
          text={this.props.bookingRequestReducer.toastMessage}
        />}

        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />


            <div className="row quick-booking-dubai-container">

              <div className="col-md-8 col-sm-12 col-xs-12 description-container">

                <h1 className="header">Spot-free drive with best car wash services.</h1>



                <img className="image" src={`${imageBasePath}/images/quick-booking/quick-booking-car-wash.png`} alt="Book Car wash in dubai" />

                <p className="description">Get hand wash, steam wash, automatic car wash and many other types of car wash services with the utmost care by experienced service providers on Carcility. We know you love your car and how a well-groomed car adds to your vibes. Carcility offers a variety of car wash in several car service centres across Dubai.</p>

              </div>
              <div className="col-md-4 col-sm-4 col-xs-12 quick-booking-dubai-form  quick-booking-form">
                <div className="">

                  <div className={this.state.hideForm && !this.state.hideForm1? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">Select Manufacturer
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="manufacturer"
                          id="manufacturer"
                          className="form-control quick-search-input"
                          placeholder="Manufacturer"
                          value={this.state.searchManufacturerTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteManufacturer(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(carList, (carItem, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      this.formData["make"] = carItem.name;
                                      this.props.actions.getCarModels(carItem.id);
                                      this.setState({
                                        hideForm1: true,
                                        hideForm2: false,
                                        selectedManufacturer: carItem.name,
                                        carModel: carItem.carmodel,
                                        selectedCarMakeKey: carItem.id,
                                        filteredCarModelList: carItem.carmodel
                                      });
                                      this.errors["make"] = false;
                                      this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

                                    }}>
                                    <label>{carItem.name}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.hideForm  && this.state.hideForm1 && !this.state.hideForm2? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="model"
                          id="model"
                          className="form-control quick-search-input"
                          placeholder="Model"
                          value={this.state.searchModelTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteModel(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(carModalList, (carItem, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {

                                      this.formData['model_id'] = carItem.id;
                                      this.formData['model'] = carItem.name;
                                      this.setState({
                                        selectedModel: carItem.name,
                                        'modelListDD': !this.state.modelListDD,
                                        hideForm1: true,
                                        hideForm2: true,
                                        hideForm3: false,
                                      });
                                      this.errors["model_id"] = false;
                                      this.errors["model"] = false;


                                    }}>
                                    <label>{carItem.name}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.hideForm  && this.state.hideForm1 && this.state.hideForm2 && !this.state.hideForm3? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="year"
                          id="year"
                          className="form-control quick-search-input"
                          placeholder="Year"
                          value={this.state.searchYearTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteYear(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(this.state.allYears, (list, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      this.setState({ selectedYear: list,hideForm3:true,hideForm:false });
                                      this.formData["year"] = list.toString();
                                      this.setState({ 'yearListDD': !this.state.yearListDD });
                                      this.errors["year"] = false;
                                      this.errors["car_details"] = false;
                                      this.formData["car_details"] = "car_details";
                                    }}>
                                    <label>{list}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form className={this.state.hideForm? "hide form": "form"}>
                    <div className="form-title">Quick Booking</div>
                    <div className="row">
                      <div className="col-12">
                        <TextInput
                          label="Car Wash"
                          name="car_Wash"
                          type="text"
                          disabled={true}
                        />
                      </div>
                      <div className="col-12">

                        <div className="car-details-div">
                          <TextInput
                            label="Car Details.*"
                            name="car_details"
                            id="car_details"
                            type="text"
                            value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                            showValidationError={this.errors['car_details']}
                            emptyError={validationMessage.car_details.emptyError}
                            validationError={validationMessage.car_details.validationError}
                            onFocus={this.chooseCarDetails.bind(this)} />

                          <span className="close" onClick={(e) => {


                            this.setState({
                              selectedManufacturer:"Manufacturer",
                              selectedModel:"Model",
                              selectedYear:"Year"
                            });

                            this.formData["make"] = "";
                            this.formData['model_id'] = "";
                            this.formData['model'] = "";
                            this.formData["year"] = "";
                            this.formData["car_details"] = "";

                            this.errors["car_details"] = true;



                          }}><i className="mdi mdi-close"></i></span>

                        </div>

                      </div>

                      <div className="col-12">
                        <TextInput
                          label="Plate No.*"
                          name="plate_no"
                          id="plate_no"
                          type="text"
                          showValidationError={this.errors['plate_no']}
                          emptyError={validationMessage.platenumber.emptyError}
                          validationError={validationMessage.platenumber.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
             {/*         <div className="col-12">
                        <TextInput
                          label="Name"
                          name="name"
                          id="name"
                          type="text"
                      /*    showValidationError={this.errors['plate_no']}
                          emptyError={validationMessage.name.emptyError}
                          validationError={validationMessage.name.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>*/}

                      <div className="col-12">
                        <TextInput
                          type="email"
                          label="E-mail ID*"
                          name="email"
                          id="email"
                          showValidationError={this.errors['email']}
                          emptyError={validationMessage.email.emptyError}
                          validationError={validationMessage.email.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
                      <div className="col-12">
                        <TextInput
                          type="phone"
                          label="Phone Number*"
                          placeholder="Mobile Number"
                          name="phone"
                          id="phone"
                          showValidationError={this.errors['phone']}
                          emptyError={validationMessage.phone.emptyError}
                          validationError={validationMessage.phone.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
                      <div className="col-12 text-center quick-button">
                        <Button dataLabelledby="Quick Booking User SignUp"
                                btnType="red"
                                btnSize="sm"
                                fontSize={13}
                                label="Book Now"
                                onSumbit btnCallBack={this.onSubmit.bind(this)}
                                isButtonLoading={this.state.isButtonLoading} />
                      </div>
                      <div className="col-12 quick-terms">
                        <div className="checkbox-style checkbox-wrapper n-align">
                          <input
                            type="checkbox"
                            id="check-box"
                            onChange={e => {
                              this.setState({ terms: !e.target.checked });
                              this.formData.terms = e.target.checked;
                              this.errors.terms = !e.target.checked;
                            }}
                            checked={this.formData.terms}
                          />
                          <label htmlFor="check-box" className="custom-checkbox" />
                          <label htmlFor="" className="n-signup-checkbox">
                            By using Carcility, you agree to our
                            <a
                              onClick={() => {
                                // this.props.router.push(`${appBasePath}/terms`);
                                window.open(`${appBasePath}/terms`, "_blank");
                              }}
                              className="blue-text"
                            >
                              {" "}
                              Terms & Conditions
                            </a>, and{" "}
                            <a
                              onClick={() => {
                                // this.props.router.push(`${appBasePath}/privacy`);
                                window.open(`${appBasePath}/privacy`, "_blank");
                              }}
                              className="blue-text"
                            >
                              Privacy Policy
                            </a>.
                          </label>
                        </div>
                        <p
                          className={
                            this.errors.terms
                              ? "note-text tc-text error"
                              : "note-text tc-text hide"
                          }
                        >
                    <span className="error-text">
                      {"Please agree to the terms and condition"}
                    </span>
                        </p>


                      </div>

                    </div>
                  </form>
                </div>
              </div>
        </div>

        <div className="row quick-booking-dubai-review-container">


          <Review
            router={router}
          />

        </div>

        {/*footer*/}
        <Footer {...this.props} />
        {/* sidebar menu */}
        <div className={this.state.sidebarOpen ? "menu-sidebar active" : "menu-sidebar inactive"}>
          {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
          <div className="nonSignup-list">
            <ul className="list-unstyled">
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/sign-in'); }}>
                <span>
                  LOG IN
                    </span>
              </li>
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/register'); }}>
                <span>
                  SIGN UP
                    </span>
              </li>
              <li>
                <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                      </span>
                </Link>
              </li>
              <li onClick={(e) => { e.preventDefault(); window.location.pathname = '/business'; }}>
                <span>
                  FOR BUSINESS
                    </span>
              </li>
            </ul>
          </div>
        </div>
      </div>


    );
  }
}

export default QuickBookingCarWash;
