import React, { Component } from 'react';
import Sidebar from '../common/Sidebar';
import Footer from '../Landing/Footer';
import Button from '../common/Button';
import { Link, animateScroll as scroll, } from 'react-scroll';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import ReactRotatingText from 'react-rotating-text';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import take  from 'lodash/take';
import findIndex  from 'lodash/findIndex';
import has  from 'lodash/has';
import { removeParameterFromUrl } from '../../helpers/index';
import {  imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import PageLoader from '../common/PageLoader';
import ProvidersView from "../common/ProvidersView";
import OfferCard from "./OfferCard";
import moment from "moment";
import FloatingMenu from "../LandingNew/FloatingMenu";


export default class SingleOffer extends Component {
  constructor(props) {
    super(props);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};
    this.searchVendors = this.searchVendors.bind(this);

    (this.reverseScroll = false), (this.startAnimation = false), (this.divEle = 0), (this.state = {
      autoSuggestedList: props.commonReducer && props.commonReducer.autoSuggestedList
        ? props.commonReducer.autoSuggestedList
        : [],
      autoCompleteData: [],
      searchMobile: false,
      sidebarProfileDropdown: false,
      windowWidth: 0,
      searchText: '',
      blinkText: true,
      navbarVisible: false,
      activeBenifitsId: 0,
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : '',
      serviceSearchTerm: '',
      timelineHeight: 0,
      locateMeDD: false,
      serviceDD: false,
      value: '+91',
      scrollType: true,
      sidebarOpen: false,
      ie: false,
      appDownloadPhoneNumber: '',
      showPhoneNoError: false,
      showOfferNotification: false,
      mobNavbarVisible: false,
      showYoutubePromo: false,
      workshopsData: [],
      isWorkshopsLoading: true,
      offerLoading:false,
      promotionalOffersData: this.props.promotionalOffersData ? this.props.promotionalOffersData : [],
      offer: this.props.offer?  this.props.offer: "",
      offerId : this.props.params.id?this.props.params.id:"",

    });
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.scrollPage = this.scrollPage.bind(this);
    this.formData = {
      phone: ''
    };
    this.errors = {
      phone: false
    };
  }
  componentDidMount() {
    window.scrollTo(0,0);
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    this.props.actions.getWorkshops();


    if (this.state.promotionalOffersData.length < 1) {
      this.props.actions.getAllPromotionalOffers();
    }



  }
  componentWillReceiveProps(nextProps) {

    let offer = nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData && this.state.offerId ? filter(nextProps.commonReducer.promotionalOffersData, (offer) => { return offer.id == this.state.offerId }) : "";




    this.setState({
      promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
      offer: offer? offer[0] : ""
    });



    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList && !this.state.autoSuggestedList.length) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    this.setState({
      workshopsData: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? nextProps.commonReducer.vendorsListFromSearch : [],
      isWorkshopsLoading: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? false : true,
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.scrollPage);
    clearInterval(this.intervalId);
    this.props.actions.hideErrorMessage();
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        locationSearchTerm: searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ input: searchTerm }, function (res) {
        that.setState({
          autoCompleteData: res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        locationSearchTerm: searchTerm,
        autoCompleteData: []
      });
    }
  }
  timer() {
    let benifitId = this.state.activeBenifitsId;
    benifitId += 1;
    this.benefitsToggleClass('none', benifitId);
  }
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }
  benefitsToggleClass(val, id) {
    const totalLength = document.querySelectorAll('.benefits-list').length;
    if (id >= totalLength) {
      id = 0;
      this.setState({ activeBenifitsId: 0 });
    } else {
      this.setState({ activeBenifitsId: id });
    }

    clearInterval(this.intervalId);
    this.intervalId = setInterval(this.timer.bind(this), 3500);

    let children = document.querySelectorAll('.benefits-description');
    for (var i = 0; i < children.length; ++i) {
      if (children[i].className.indexOf('hide') == -1) {
        children[i].className += ' hide';
      }
    }
    let selectedChild = children[id];
    selectedChild && selectedChild.classList.remove('hide');
    let selectImage = document.querySelectorAll('.benefits-list');
    for (var i = 0; i < selectImage.length; ++i) {
      if (selectImage[i].className.indexOf('active') > 0) {
        selectImage[i].classList.remove('active');
      }
    }
    selectImage[id].className += ' active';
  }
  activeTimelineDots(index, height) {
    if (window.pageYOffset > document.getElementsByClassName('hiw-block')[0].offsetHeight + height) {
      if (!document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.add('active');
      }
    } else {
      if (document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.remove('active');
      }
    }
  }
  searchNav() {
    let normalHeader = document.getElementsByClassName('default-header')[0];
    let scrollHeader = document.getElementsByClassName('scroll-header')[0];
    let bannersearchHeight = document.getElementsByClassName('banner-search')[1].offsetHeight;
    if (this.state.windowWidth > 1100 && normalHeader) {
      if (window.pageYOffset > document.getElementsByClassName('banner-search')[1].offsetHeight + bannersearchHeight) {
        if (!normalHeader.classList.contains('hide')) {
          normalHeader.classList.add('hide');
          scrollHeader.classList.remove('hide');
        }
      } else {
        if (normalHeader.classList.contains('hide')) {
          scrollHeader.classList.add('hide');
          normalHeader.classList.remove('hide');
        }
      }
    } else if (normalHeader) {
      if (normalHeader.classList.contains('hide')) {
        scrollHeader.classList.add('hide');
        normalHeader.classList.remove('hide');
      }
    }
    if (this.state.sidebarOpen) {
      this.setState({ sidebarOpen: false });
    }
  }
  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { lat: Number(location.lat), lng: Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          let locationResult = {};
          if (results[0].formatted_address && results[0].formatted_address.indexOf('Unnamed Road') > -1) {
            locationResult = results[1];
          } else {
            locationResult = results[0];
          }
          this.setState({
            userLocation: locationResult,
            locationSearchTerm: searchTerm || location.description || locationResult.formatted_address,
            showLocationDropdown: false
          });
          that.props.actions.setCurrentLocation({
            lat: locationResult.geometry.location.lat(),
            lng: locationResult.geometry.location.lng(),
            formatted_address: locationResult.formatted_address,
            label: searchTerm || location.description || locationResult.formatted_address
          });

        } else {
          this.setState({ showLocationDropdown: false });
        }
      });
    }
  }
  scrollPage() {
    // call benefits function on section active
    if (
      this.state.scrollType &&
      window.pageYOffset > document.getElementById('benefits-section').offsetTop - window.innerHeight - 150
    ) {
      this.setState({ activeBenifitsId: -1, scrollType: false });
      this.startAnimation = true;
    }

    // check browser
    if (this.state.ie) {
      if (!this.reverseScroll) {
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 100) {
          if (document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
            document.getElementsByClassName('line-block')[0].classList.remove('hide');
          }
        }

        if (
          window.scrollY >
          document.getElementById('benefits-section').offsetTop -
          document.getElementById('benefits-section').offsetHeight ||
          window.scrollY < document.getElementById('how-works').offsetHeight + 150
        ) {
          document.getElementsByClassName('line-block')[0].classList.add('hide');
        }

        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 1080) {
          this.reverseScroll = true;
        }

        this.activeTimelineDots(0, 521);
        this.activeTimelineDots(1, 837);
        this.activeTimelineDots(2, 1147);
      }

      this.searchNav();
    } else {
      this.searchNav();

      if (!this.reverseScroll) {
        this.activeTimelineDots(0, 521);
        this.activeTimelineDots(1, 837);
        this.activeTimelineDots(2, 1147);

        // console.log(document.getElementById('how-works')>window.pageYOffset)
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 58) {
          if (document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
            document.getElementsByClassName('line-block')[0].classList.remove('hide');
          }
        }
        // if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 823) {
        //   if (!document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
        //     document.getElementsByClassName('line-block')[0].classList += ' hide';
        //   }
        // }
        if (
          window.scrollY >
          document.getElementById('benefits-section').offsetTop -
          document.getElementById('benefits-section').offsetHeight ||
          window.scrollY < document.getElementById('how-works').offsetHeight + 150
        ) {
          document.getElementsByClassName('line-block')[0].classList.add('hide');
        }
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 1080) {
          this.reverseScroll = true;
        }
      }
    }
  }
  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng };
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == 'keyword') {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, 'distance');
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == 'autosuggest') {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, 'distance');
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }
  render() {


    const tagManagerArgs = {
      dataLayer: {
        pageCategory: 'Homepage',
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


    const origin = location.origin;
    let baseUrl = location.origin;

    const settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };

    let searchView = filter(this.state.autoSuggestedList, val => {
      if (this.state.searchText != '' && val.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
        return val;
      }
    });
    searchView = take(searchView, 5);
    const searchAutoSuggestedList = map(searchView, (val, key) => {
      return (
        <MenuItem
          onClick={() => {
            // this.searchVendors(val, 'autosuggest');
            this.setState({ searchText: val.name });
          }}
          eventKey={key}
          key={key}
        >
          {val.name}
        </MenuItem>
      );
    });


    let locationData = [{ textPlaceholder: 'Current Location' }];
    map(this.state.autoCompleteData, (location, key) => {
      return locationData.push({ key: key, description: location.description, place_id: location.place_id });
    });
    map(this.props.commonReducer && this.props.commonReducer.userLocations, (location, key) => {
      return locationData.push({
        key: key,
        lat: location.lat,
        lng: location.lng,
        label: location.label,
        id: location.id,
        formatted_address: location.location
      });
    });
    const locationAutosuggestedList = map(locationData, (val, key) => {
      return (
        <MenuItem
          onClick={item => {
            if (item.target.text != 'Current Location') {
              this.setUserLocation(val, item.target.text);
            } else {
              if (navigator.geolocation) {
                const that = this;
                navigator.geolocation.getCurrentPosition(function (position) {
                  that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                }, function (error) {
                  switch (error.code) {
                    case error.PERMISSION_DENIED:
                      that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                      break;
                    case error.POSITION_UNAVAILABLE:
                      that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                      break;
                    case error.TIMEOUT:
                      that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                      break;
                    case error.UNKNOWN_ERROR:
                      that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                      break;
                  }
                }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 });
              } else {
                this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
              }
            }
          }}
          eventKey={key}
          key={key}
        >
          {val.description ? val.description : val.label ? val.label : val.textPlaceholder}
        </MenuItem>
      );
    });
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    const { router, commonReducer, actions } = this.props;


    let days = this.state.offer && moment(this.state.offer.validTo).diff(moment(new Date()),'days');



    let redirectPath = appBasePath + this.state.offer && this.state.offer.services[0].parentId == 1 ? 'car-wash': this.state.offer  && this.state.offer.services[0].parentId == 2 ? 'car-service': 'car-repair';
    let redirectPathSearch = "";
    if(authData && !authData.hasVehicleProfile){
      redirectPathSearch = "?redirect_to="+redirectPath;
      redirectPath = "/car-profiles/create";
    }

    return (

      <div>
        <FloatingMenu  />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['landing'] && metaTagData['landing'].title ? metaTagData['landing'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['landing'] && metaTagData['landing'].description ? metaTagData['landing'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        {/* Quick Call Modal */}
        {/*    {< FloatingMenu playPromo={this.playKnowCarcilityPromo.bind(this)} enableOpac={this.enableOpacity.bind(this)} />}*/}
        <div id="landing" className={`landing-wrapper ${this.state.enableOpacity}`} ref="landing">
          {this.props.bookingRequestReducer.toastMessage &&
          this.props.bookingRequestReducer.toastType &&
          <ToastMessage
            actions={this.props.actions}
            type={this.props.bookingRequestReducer.toastType}
            title={this.props.bookingRequestReducer.toastType + '...'}
            text={this.props.bookingRequestReducer.toastMessage}
          />}

          <nav className="navbar navbar-default scroll-header all-offer-header" id="header-section2">
            <div className="container-fluid">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                  onClick={() => {
                    this.setState({  mobNavbarVisible: !this.state.mobNavbarVisible });
                  }}
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <a className="navbar-brand" href={baseUrl}>
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                </a>
              </div>
              <div id="navbar" className={this.state.mobNavbarVisible ? 'navbar-collapse' : 'hide'}>
                <ul className="nav navbar-nav navbar-right">
                  <li className="search-mobile md-mob" onClick={() => this.setState({ searchMobile: true })}>
                    <i className="mdi mdi-magnify" aria-hidden="true" />
                  </li>
                  <li className="work-active">

                    <Link to="how-works" smooth={true} offset={-100} duration={600}>
                      how it works
                    </Link>
                    <label />
                  </li>
                  <li>
                    <a href="/business">
                      For Business
                    </a>
                    <label />
                  </li>
                  <li>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        this.props.router.push(appBasePath + '/sign-in');
                      }}
                    >
                      Log In{' '}
                    </a>
                    <label />
                  </li>
                  <li>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        this.props.router.push(appBasePath + '/register');
                      }}
                    >
                      {' '}Sign Up
                    </a>
                    <label />
                  </li>
                </ul>
              </div>
              <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
                <ul className="nav navbar-nav nav-left all-offer-nav">
                  <li>
                    <div className="banner-search">
                      <div className="search-wrapper">
                        <form className="form-inline">

                          <DropdownButton
                            bsSize="small"
                            id="dropdown-size-small"
                            noCaret
                            onToggle={e => this.setState({ locateMeDD: e })}
                            onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                            className="location-search"
                            title={
                              <span className="search-left">
                              <i className="mdi mdi-crosshairs-gps" />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Locate Me"
                                value={this.state.locationSearchTerm}
                                onChange={e => {
                                  e.preventDefault();
                                  this.autocompleteLocation(e);
                                  this.setState({ locationSearchTerm: e.target.value });
                                }}
                              />
                              <i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                            </span>
                            }
                          >
                            {locationAutosuggestedList}
                          </DropdownButton>

                          <DropdownButton
                            bsSize="small"
                            id="dropdown-size-small"
                            noCaret
                            onToggle={e => this.setState({ serviceDD: e })}
                            open={this.state.searchText == '' ? false : this.state.serviceDD}
                            onChange={e => {
                              this.setState({ searchText: e.target.value });
                            }}
                            onSelect={(id, e) => {
                              this.setState({ serviceSearchTerm: e.target.innerText });
                            }}
                            className="location-search search-service-block"
                            title={
                              <span
                                className="search-right"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({ blinkText: false });
                                }}
                              >
                              <input
                                type="text"
                                ref={focusFunction => this.inputFocusTop = focusFunction}
                                className="form-control"
                                placeholder=""
                                onFocus={(e) => e.target.placeholder = "Search for services, service providers.."}
                                onBlur={(e) => e.target.placeholder = "Search for services, service providers.."}
                                onChange={e => {
                                  e.preventDefault;
                                  const serviceSearchTerm = e.target.value;
                                  this.setState({ serviceSearchTerm: serviceSearchTerm });
                                }}
                                value={this.state.serviceSearchTerm}
                              />
                              <input
                                className={'hide'}
                                type="submit"
                                onClick={e => {
                                  e.preventDefault();
                                  this.searchVendors(this.state.searchText, 'keyword');
                                }}
                              />
                              <span
                                onClick={e => {
                                  e.preventDefault;
                                  this.inputFocusTop.focus();
                                }}
                                className={this.state.blinkText ? 'blink-text' : 'blink-text hide'}
                              >
                                <ReactRotatingText
                                  items={[
                                    'Find a quality service provider near you.'
                                  ]}
                                />
                              </span>
                              <i
                                className="mdi mdi-magnify"
                                onClick={() => {
                                  this.searchVendors(this.state.searchText, 'keyword');
                                }}
                              />
                            </span>
                            }
                          >
                            {searchAutoSuggestedList}
                          </DropdownButton>
                        </form>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nav navbar-nav navbar-right all-offer-nav-right">
                  <li className="search-mobile md-mob" onClick={() => this.setState({ searchMobile: true })}>
                    <i className="mdi mdi-magnify" aria-hidden="true" />
                  </li>
                  <li>
                    <Button
                      dataLabelledby="Get Started"
                      isSubmitBtn={true}
                      btnCallBack={() => {
                        this.props.router.push(appBasePath + '/guest-user');
                      }}
                      btnType="red"
                      btnSize="sm"
                      fontSize={13}
                      label="Get Started"
                    />
                  </li>
                  <li className="offer"

                      onClick={() =>  this.props.router.push(appBasePath + '/all-offers') }
                  >
                    <span>OFFERS</span>
                    <span className="border-bottom"></span>
                  </li>
                  <li
                    className="menu-option"
                    onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen, sidebarProfileDropdown: false })}
                  >
                    <i className="mdi mdi-menu" />
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="main-wrapper all-offer-main-wrapper ">
            {/*Sidebar*/}

            {/*message*/}
            {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
            <div className="page-sec-header all-offer-header">
              <div className="padwrapper">
                <img src={`${imageBasePath}/images/arrow-backward.png`} alt="back" className="back-arrow"  onClick={() => {
                  this.props.router.push(appBasePath + '/all-offers');
                }} />
                <h4>Offers</h4>
              </div>
            </div>

            <div className="inSection">
              {this.state.offer ? <div className=" offer-detail-wrapper guest-offer-detail-wrapper">
                <div className="padwrapper">
                  <div className="offer-body offer-detail row">
                    {/*Job Updates*/}
                    <div className="offer-box card  col-md-12 col-sm-12 col-xs-12" >
                      <div className="card-detail col-md-12 col-sm-12 col-xs-12" >
                        <div className={this.state.offer.discountPercentage &&  this.state.offer.discountPercentage == 100 ? "col-md-4 col-sm-6 col-xs-6  free-card cover-card " : "cover-card col-md-4 col-sm-6  col-xs-6  "}>
                          <figure>
                            <img className="offer-cover" src={ this.state.offer.images && this.state.offer.images.length ?  this.state.offer.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="BMW car repair" />


                            { this.state.offer.discountPercentage ?

                              this.state.offer.discountPercentage == 100 ?
                                <div className=" price free-price col-md-6 col-sm-6 col-xs-6">

                                  <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                                  <div className="price-content">

                                    <span className="amount">Free</span>
                                  </div>
                                </div>
                                :
                                <div className=" price col-md-4 col-sm-4 col-xs-4">

                                  <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                                  <div className="price-content">

                                    <span className="amount">{this.state.offer.discountPercentage }%</span>
                                    <span className="type">OFF</span>
                                  </div>
                                </div>
                              :
                              <div className=" price col-md-4 col-sm-4 col-xs-4">

                                <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                                <div className="price-content discount-amount-content">

                                  <span className="discount-amount">{Number(this.state.offer.discountAmount).toFixed(2)}</span>
                                  <span className="discount-currency">AED </span>
                                  <span className="discount-type">OFF</span>
                                </div>
                              </div>

                            }
                          </figure>

                        </div>
                        <div className=" col-md-3 col-sm-6 col-xs-6">

                          <div className="description">



                            <div className="">

                              <div className="header-right">
                                <ul className="list-unstyled">

                                  <img className="provider-expand" src={`${imageBasePath}/images/provider-expand.png` } alt="" />
                                  <span className="garage">{this.state.offer.garages.length ? "Service Providers" : "-"}


                                    </span>
                                  <span className="providers">

                                      {this.state.offer.garages.length ?  ( "(" + (this.state.offer.garages.length) + ")")  : "" }
                                    {/* this.state.offer.garages.length &&
                                    <ProvidersView {...this.props}  providers={this.state.offer.garages}/>
                                    */}
                                    </span>

                                </ul>
                              </div>


                              {/*
                                <span className="garage">{this.state.offer.garages.length ? this.state.offer.garages[0].companyprofilename : "-"}</span>
                                <div className="notification-menu desktop-view ">
                                    <ProvidersView providers={this.state.offer.garages} />

                                </div>*/}
                            </div>

                            <span className="title">{this.state.offer.offerName}</span>
                            <span className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon.png` }  /> {days} Days Left</span>
                            {this.state.offer.totalAmount ?
                              <div className="offer-price">
                                <span className="original">{Number(this.state.offer.totalAmount).toFixed(2)} AED</span>

                                {this.state.offer.discountPercentage ?
                                  this.state.offer.discountPercentage == 100 ?
                                    <span className="amount">Free</span>
                                    :
                                    <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                                  :
                                  <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                                }


                              </div>
                              :
                              <div className="offer-price">
                              </div>

                            }
                          </div>


                        </div>
                        <div className="description-card col-md-5 col-sm-12 col-xs-12">

                          <div className="description description-right">
                            <p>{this.state.offer.description}</p>

                          </div>
                          <div className="terms">

                            <p onClick={() => {
                              let url = "/offer-terms/"+this.state.offer.id;
                              window.open(url, '_blank');

                            }}>*Terms and Conditions Apply</p>

                          </div>


                        </div>
                      </div>
                      <div className="offer-bottom col-md-12 col-sm-12 col-xs-12" >

                        <div className="row">
                          <div className="col-md-9 col-sm-6 col-xs-6 offer-bottom-code" >
                            <span className="use-code">Use Code</span>
                            <span className="promo-code">{this.state.offer.promoCode}</span>
                          </div>
                          <div className="col-md-3 col-sm-6 col-xs-6 offer-bottom-code-button">
                            <Button btnType="transparent" fontSize={16} label="Book Now" btnCallBack={() =>

                              router.push({
                                  pathname: appBasePath +"/all-offers/"+this.state.offer.id+"/book-now",
                                  search: redirectPathSearch,
                                  state: { selectedPromotionalOffersData: this.state.offer }
                                }
                              )
                            } />
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </div> : <PageLoader />}
              {!this.state.isOfferLoading ? this.state.promotionalOffersData && this.state.promotionalOffersData.length > 0 &&


                <div className=" ">
                  <div className=" bottom-all-offers row">
                    <div className="offer-list">
                      <div className="offer-body padwrapper">

                          <OfferCard
                            showPromoCode={true}
                            title="All offers"
                            promotionalOffersData={this.state.promotionalOffersData}
                            router={router}
                          />

                      </div>
                    </div>
                  </div>


                </div>


                : <PageLoader/>
              }

            </div>
          </div>
          {/* footer */}

          <Footer {...this.props} />
          {/* sidebar menu */}
          <div className={this.state.sidebarOpen ? 'menu-sidebar active' : 'menu-sidebar inactive'}>
            {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
            <div className="nonSignup-list">
              <ul className="list-unstyled">
                <li
                  onClick={e => {
                    e.preventDefault();
                    this.props.router.push(appBasePath + '/sign-in');
                  }}
                >
                <span>
                  LOG IN
                </span>
                </li>
                <li
                  onClick={e => {
                    e.preventDefault();
                    this.props.router.push(appBasePath + '/sign-up');
                  }}
                >
                <span>
                  SIGN UP
                </span>
                </li>
                <li>
                  <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                  </span>
                  </Link>
                </li>
                <li
                  onClick={e => {
                    e.preventDefault();
                    window.location.pathname = '/business';
                  }}
                >
                <span>
                  FOR BUSINESS
                </span>
                </li>
              </ul>
            </div>
          </div>
          {this.state.searchMobile &&
          <div>

            <MobileHeaderSearch
              mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() => this.setState({ searchMobile: false })}
            />
          </div>}
        </div>
      </div>
    );
  }
}
