import React, { Component } from 'react';
import Iframe from 'react-iframe';


export default class OfferIframe extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
      this.state = {


    }
  }

  componentDidMount() {



  }


  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }


  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }


  componentWillReceiveProps(nextProps) {



  }

  resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }
  render() {



    const { router, commonReducer, actions, authData } = this.props;





    return (

      <div>
        <iframe src="https://www.carcility.com/blog/major-new-cars-releases-in-2020/" frameBorder="0" scrolling="no" onLoad={this.resizeIframe(this)}/>

      </div>

    );
  }
}

