import React, { Component } from 'react';
import map  from 'lodash/map';
import '../../styles/sidebar.css';
import {Modal, Panel} from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import { imageBasePath, appBasePath,phoneNumber } from '../../constants/staticData';
import Button from "../common/Button";

export default class PlusSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      currentPath:""
    }
  }
  componentWillMount() {
    const currentPath = window.location.pathname;
    this.state = {
      currentPath: currentPath
    };
  }
  componentWillReceiveProps() {
    const currentPath = window.location.pathname;
    this.state = {
      currentPath: currentPath
    };
  }
  goToUrl(url) {
    this.props.router.push(appBasePath + url);
  }
  render() {
    const { bgColor } = this.props;
    
    const sidebarItems = [
      {
        icon: `${imageBasePath}/images/sidebar/dashboard.svg`,
        icon_active: `${imageBasePath}/images/sidebar/dashboard_active.svg`,
        name: 'Dashboard',
        hyperLink: '/dashboard-plus'
      },
      {
        icon: `${imageBasePath}/images/sidebar/request.svg`,
        icon_active: `${imageBasePath}/images/sidebar/request_active.svg`,
        name: 'My Requests',
        hyperLink: '/request'
      },
      {
        icon: `${imageBasePath}/images/sidebar/car.svg`,
        icon_active: `${imageBasePath}/images/sidebar/car_active.svg`,
        name: 'My Cars',
        hyperLink: '/car-profiles'
      },
      {
        icon: `${imageBasePath}/images/sidebar/message.svg`,
        icon_active: `${imageBasePath}/images/sidebar/message_active.svg`,
        name: 'Messages',
        hyperLink: '/messages'
      },
      {
        icon: `${imageBasePath}/images/sidebar/fav.svg`,
        icon_active: `${imageBasePath}/images/sidebar/fav_active.svg`,
        name: 'Favourites',
        hyperLink: '/favourites'
      },
    ];

    const currentPath = this.props.router.location.pathname;
    var that = this.state.currentPath



    const sideBarCardList = map(sidebarItems, (item, key) => {

      return (
        // <li key={key} className={this.state.currentPath == item.hyperLink ? 'active' : ''}
        <li key={key} className={this.state.currentPath && this.state.currentPath.startsWith(item.hyperLink) ? 'active' : ''}
          onClick={this.goToUrl.bind(this, item.hyperLink)}>
          <a>
            {item.icon && <img src={item.icon} className="non_active" />}
            {item.icon && <img src={item.icon_active} className="active_icon" />}
            <span>{item.name}</span>
          </a>
        </li>
      );
    });

    let pageHtml = "";

    if(this.props.page == "createCarProfileModal"){
      pageHtml = (<div className="plus-sidebar-button">

        <div className="col-sm-6 col-xs-6">

          <p className="amount-text">Starting at</p>
          <p className="amount">AED 345</p>
        </div>
        <div className="col-sm-6 col-xs-6">
          <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service"/>
        </div>

      </div>)
    }else if(this.props.page == "engineOilModal"){
      pageHtml = (<div className="plus-sidebar-button">

        <div className="col-sm-6 col-xs-6">

          <p className="amount-text">{this.props.textChange? "Total":"Starting at"}</p>
          <p className="amount">{this.props.packageDefaultValue}</p>
        </div>
        <div className="col-sm-6 col-xs-6">
          <Button btnType="red" btnSize="lg" fontSize={14} disabled={this.props.checkAvailability} label="Book Now"/>
        </div>

      </div>)
    }else if(this.props.page == "updatedServices"){
      pageHtml = (<div className="plus-sidebar-button">

        <div className="col-sm-5 col-xs-5">
          <Button btnType="transparent" btnSize="lg" btnCallBack={()=>this.props.cancelEdit()} fontSize={14} label="Cancel"/>
        </div>
        <div className="col-sm-1 col-xs-1"></div>
        <div className="col-sm-5 col-xs-5">
          <Button btnType="red" btnSize="lg" btnCallBack={()=>this.props.udatedSpecialService()} fontSize={14} label="Save"/>
        </div>

      </div>)
    }
    
    else if(this.props.page == "callus"){
      pageHtml = (<div className="plus-sidebar-button">
        <div className="col-lg-12 col-xs-12">
        <a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>
        </div>
      </div>)
    }
    else if(this.props.page == "approvePackage"){
      pageHtml = (<div className="plus-sidebar-button">

<div className="col-sm-5 col-xs-5">
           <a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>
        </div>
        <div className="col-sm-1 col-xs-1"></div>
        <div className="col-sm-5 col-xs-5">
          <Button btnType="red" btnSize="lg" btnCallBack={()=>this.props.approvePackage()} fontSize={14} label="Approve"/>
        </div>
        {/* <div className="col-lg-1 col-xs-1"></div> */}
      </div>)
    }
    else if(this.props.page == "makePayment"){
      pageHtml = (<div className="plus-sidebar-button">

<div className="col-sm-5 col-xs-5">
<a href={`tel:${phoneNumber}`} target="_blank"><button className="call" >Call Us</button></a>        
</div>
        <div className="col-sm-1 col-xs-1"></div>
        <div className="col-sm-5 col-xs-5">
          <Button btnType="red" btnSize="lg" btnCallBack={()=>this.props.makePayment()} fontSize={14} label="Make Payment"/>
        </div>
      </div>)
    }
    
    
    
    
    else if(this.props.page == "requestDetail"){
      pageHtml = (<div className="plus-sidebar-button">

        <div className="col-sm-10 col-xs-10">
          <Button btnType="transparent" btnSize="sm" fontSize={15} label="Call Us" />
        </div>

      </div>)
    }

    return (
       
      this.props.page == "makePayment" ?

      <div className="sidebars plus-sidebars-container" style={{ backgroundColor: bgColor ? bgColor : '#fff' }}>
        
           <ul className="list-unstyled plus-sidebar">
                    {sideBarCardList}
                  </ul>
          <div>{pageHtml}</div>
          
       
      </div>
      :
      

      <div className="sidebar plus-sidebar-container" style={{ backgroundColor: bgColor ? bgColor : '#fff' }}>
        
           <ul className="list-unstyled plus-sidebar">
                    {sideBarCardList}
                  </ul>
          <div>{pageHtml}</div>
          
       
      </div>
    );
  }
}
