import React, { Component } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import MobileNotification from "../common/MobileNotification";
import MobileMessage from "../common/MobileMessage";
import ServiceSteps from "./ServiceSteps";
import each from "lodash/each";
import map from "lodash/map";
import size from "lodash/size";
import cloneDeep from "lodash/cloneDeep";

import CustomModal from "../common/CustomModal";
import { Modal } from "react-bootstrap";
import { queryStringtoArray } from "../../helpers/index";
import ToastMessage from "../common/ToastMessage";
import SelectDropdown from "../common/SelectDropdown";
import MobileHeaderSearch from "../common/MobileHeaderSearch";
import { imageBasePath } from "../../constants/staticData";

export default class CarService extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      mobileSearch: false,
      editClicked: false,
      infoModal: true,
      ChasisNumber: "",
      checkedItemPresent: false,
      notificationVisible: false,
      messageVisible: false,
      carServiceCategories:
        this.props.bookingRequestReducer &&
        this.props.bookingRequestReducer.serviceCategoryData
          ? this.props.bookingRequestReducer.serviceCategoryData
          : [],
      filteredCarServiceCategories:
        this.props.bookingRequestReducer &&
        this.props.bookingRequestReducer.serviceCategoryData
          ? this.props.bookingRequestReducer.serviceCategoryData
          : [],
      checkedCategories: [],
      selectedCarCategoryForModel: undefined,
      selectedCarProfile: undefined,
      promotionalOffersData:
        props.commonReducer && props.commonReducer.promotionalOffersData
          ? props.commonReducer.promotionalOffersData
          : [],
      selectedPromotionalOffersData:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selectedPromotionalOffersData
          ? this.props.location.state.selectedPromotionalOffersData
          : null,
      catDescriptionModalVisible: false,
      categoriesSelectedCount: 0,
      selectedCarServiceDataArray: [],
      showError: undefined,
      selectedServiceId:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selectedPromotionalOffersData
          ? this.props.location.state.selectedPromotionalOffersData.services[0]
              .serviceId
          : null,
      selectedService:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selectedPromotionalOffersData
          ? this.props.location.state.selectedPromotionalOffersData.services[0]
          : null,
      servicesFetched: props.bookingRequestReducer.servicesFetched
        ? true
        : false,
      showChasis: true,
      offerFromCreateCarProfile:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.offerFromCreateCarProfile
          ? this.props.location.state.offerFromCreateCarProfile
          : false,
      chassisImage: [],
    };
  }

  componentWillMount() {
    if (
      !(
        this.props.bookingRequestReducer &&
        this.props.bookingRequestReducer.getAllServices &&
        this.props.bookingRequestReducer.getAllServices.length
      )
    ) {
      this.props.actions.getAllServices();
    }
    if (
      !(
        this.props.carProfileReducer &&
        this.props.carProfileReducer.carProfiles &&
        size(this.props.carProfileReducer.carProfiles) > 0
      )
    ) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray("car_id")) {
        map(
          this.props.carProfileReducer &&
            this.props.carProfileReducer.carProfiles,
          (carProfile) => {
            if (queryStringtoArray("car_id") == carProfile.id) {
              selectedCarProfile = carProfile;
            }
          }
        );
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile:
            this.props.carProfileReducer &&
            this.props.carProfileReducer.carProfiles &&
            this.props.carProfileReducer.carProfiles[
              Object.keys(this.props.carProfileReducer.carProfiles)[0]
            ],
        });
      }
    }
    if (queryStringtoArray("vendor_id")) {
      this.props.actions.getGarageProfileDetails(
        Number(queryStringtoArray("vendor_id"))
      );
    }

    if (
      !(
        this.props.commonReducer.promotionalOffersData &&
        this.props.commonReducer.promotionalOffersData.length
      )
    ) {
      console.log(
        "💔 💔 💔 0 ",
        this.props.commonReducer.promotionalOffersData
      );
      this.props.actions.getPromotionalOffers();
    }
  }

  componentDidMount() {
    this.setState({
      filteredCarServiceCategories: cloneDeep(this.state.carServiceCategories),
    });
  }

  componentWillReceiveProps(nextProps) {
    let getVendorsCarServiceData = [];
    let selectedCarProfile = undefined;
    /* if (queryStringtoArray('car_id') && nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles) {
      map(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles, (carProfile) => {
        if (queryStringtoArray('car_id') == carProfile.id) {
          selectedCarProfile = carProfile;
        }
      });
      this.setState({
        ...this.state,
        selectedCarProfile: selectedCarProfile,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
      });
    } */
    if (
      !queryStringtoArray("vendor_id") &&
      nextProps &&
      nextProps.bookingRequestReducer &&
      nextProps.bookingRequestReducer.serviceCategoryData &&
      this.state.carServiceCategories &&
      !this.state.carServiceCategories.length &&
      this.state.filteredCarServiceCategories &&
      !this.state.filteredCarServiceCategories.length &&
      nextProps.bookingRequestReducer.servicesFetched
    ) {
      this.setState({
        ...this.state,
        carServiceCategories:
          nextProps.bookingRequestReducer &&
          nextProps.bookingRequestReducer.serviceCategoryData,
        filteredCarServiceCategories:
          nextProps.bookingRequestReducer &&
          nextProps.bookingRequestReducer.serviceCategoryData,
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    } else if (
      queryStringtoArray("vendor_id") &&
      nextProps &&
      nextProps.commonReducer &&
      nextProps.commonReducer.garageProfileDetails &&
      nextProps.commonReducer.garageProfileDetails.garage_services
    ) {
      if (
        nextProps.commonReducer.garageProfileDetails.garage_services.length == 0
      ) {
        this.setState({
          ...this.state,
          carServiceCategories: [],
          filteredCarServiceCategories: [],
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      } else {
        nextProps &&
          nextProps.bookingRequestReducer &&
          nextProps.bookingRequestReducer.serviceCategoryData &&
          nextProps.bookingRequestReducer.serviceCategoryData.map(
            (allSubService) => {
              let sub_services = [],
                services = {};
              allSubService.sub_services.map((subservices) => {
                nextProps.commonReducer &&
                  nextProps.commonReducer.garageProfileDetails &&
                  nextProps.commonReducer.garageProfileDetails.garage_services.map(
                    (vendorSubService) => {
                      if (subservices.id === vendorSubService.serviceId) {
                        sub_services.push(subservices);
                      }
                    }
                  );
              });
              if (sub_services && sub_services.length > 0) {
                services["description"] = allSubService.description;
                services["id"] = allSubService.id;
                services["image"] = allSubService.image;
                services["name"] = allSubService.name;
                services["parentId"] = allSubService.parentId;
                services["sub_services"] = sub_services;
              }
              //console.log("services",services);
              if (size(services)) {
                getVendorsCarServiceData.push(services);
              }
            }
          );
        if (getVendorsCarServiceData.length) {
          this.setState({
            ...this.state,
            carServiceCategories: getVendorsCarServiceData,
            filteredCarServiceCategories: getVendorsCarServiceData,
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        }
      }
    }

    if (!this.state.offerFromCreateCarProfile) {
      this.setState({
        promotionalOffersData:
          nextProps.commonReducer &&
          nextProps.commonReducer.promotionalOffersData
            ? nextProps.commonReducer.promotionalOffersData
            : [],
        selectedPromotionalOffersData:
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.selectedPromotionalOffersData
            ? this.props.location.state.selectedPromotionalOffersData
            : null,
      });
    }

    if (
      this.state.selectedPromotionalOffersData &&
      this.state.selectedPromotionalOffersData.services
    ) {
      this.setState({
        selectedServiceId:
          this.state.selectedPromotionalOffersData.services[0].serviceId,
        selectedService: this.state.selectedPromotionalOffersData.services[0],
      });
    }

    let promoCategories = [];

    this.state.selectedPromotionalOffersData &&
      each(
        this.state.selectedPromotionalOffersData.services,
        (promoCategory) => {
          promoCategories.push(promoCategory.serviceId);

          //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);
        }
      );

    promoCategories &&
      promoCategories.length &&
      this.setState({
        selectedCarServiceDataArray: promoCategories,
        categoriesSelectedCount: promoCategories.length,

        /*
            selectedServiceId: promoCategoriesData[0].serviceId,
            selectedService: promoCategoriesData[0].name,*/
      });
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carServiceCategories);
      let filteredCarServiceCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails["sub_services"] = filteredSubCategory;
            filteredCarServiceCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails["sub_services"] = filteredSubCategory;
            filteredCarServiceCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        filteredCarServiceCategories: filteredCarServiceCategories,
        filterKeyword: true,
      });
    } else {
      this.setState({
        filteredCarServiceCategories: cloneDeep(
          this.state.carServiceCategories
        ),
        filterKeyword: false,
      });
    }
  }

  openCategory(id) {
    if (this.state.visibleCategory == id) {
      this.setState({
        visibleCategory: undefined,
      });
    } else {
      this.setState({
        visibleCategory: id,
      });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ notificationVisible: isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ messageVisible: isVisible });
  }

  hideModalCallback() {
    this.setState({ showChasis: false });
  }

  submitCallback(number, chassisImage) {
    console.log("The chasis Number is ", number);
    this.setState({
      ChasisNumber: number,
      showChasis: false,
      chassisImage,
    });
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      selectedCarCategoryForModel: categoryDetails,
      catDescriptionModalVisible: true,
    });
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile,
    });
  }

  disableEdit() {
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedServiceId: carProfile.id,
      selectedService: carProfile,
    });
    // this.errors['selectedCarProfileId'] = false;
    // this.formData['selectedCarProfileId'] = true;
  }

  renderCarType(data) {
    console.log("this.state.selectedService", this.state.selectedService);
    let customPlaceholder =
      this.state.selectedService && this.state.selectedService.name
        ? this.state.selectedService.name
        : "Enter Approx Mileage";
    return (
      <div className="sub-collapse-panel ">
        <span className="service-card-holder n-service-holder">
          <figure>
            <img src={`${imageBasePath}/images/carservice.png`} alt="" />
          </figure>
          <div className="sub-collapse-panel-head ">
            <h4 className="card-name">Car Service</h4>
            <i className="mdi mdi-information-outline"></i>
            <div className="dropdown custom-scroll-dropdown">
              <SelectDropdown
                customPlaceholder={customPlaceholder}
                selectedCarServiceData={
                  this.state.selectedCarServiceDataArray[0]
                }
                selectedCarProfile={
                  this.state.selectedService && this.state.selectedService
                }
                data={data}
                select={this.select.bind(this)}
              />
            </div>
          </div>
        </span>
      </div>
    );
  }

  render() {
    const { commonReducer, actions, router, bookingRequestReducer } =
      this.props;

    let isCoverImage = "";
    this.state.selectedCarProfile &&
      this.state.selectedCarProfile.images.length &&
      this.state.selectedCarProfile.images.map((value, key) => {
        if (value.isCover && value.type == "vehicle") {
          isCoverImage = (
            <div className="type-img car-img" key={key}>
              <img src={value.medium} alt="" />
            </div>
          );
        }
      });

    if (
      !isCoverImage &&
      this.state.selectedCarProfile &&
      this.state.selectedCarProfile.images.length
    ) {
      this.state.selectedCarProfile &&
        this.state.selectedCarProfile.images.length &&
        this.state.selectedCarProfile.images.map((value, key) => {
          if (value.type == "vehicle") {
            isCoverImage = (
              <div className="type-img car-img" key={key}>
                <img src={value.medium} alt="" />
              </div>
            );
          }
        });
    }

    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header
          hideOption={() => this.setState({ searchMobile: true })}
          bookingRequestReducer={bookingRequestReducer}
          commonReducer={commonReducer}
          notificationCount={2}
          profileName="Derrick Frank"
          notificationCallBack={this.toggleNotification}
          messageCallBack={this.toggleMessage.bind(this)}
          router={router}
          actions={actions}
          authReducer={this.props.authReducer}
        />
        <MobileNotification
          isVisible={this.state.notificationVisible}
          backBtnCallBack={this.toggleNotification}
        />
        <MobileMessage
          isVisible={this.state.messageVisible}
          backBtnCallBack={this.toggleMessage.bind(this)}
        />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Car Service</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className="myCar-body col-md-8">
                  <ServiceSteps
                    actions={actions}
                    categoriesSelectedCount={this.state.categoriesSelectedCount}
                    btnCallBack={this.requestQuotes}
                    chasisNumber={this.state.ChasisNumber}
                    chasisImage={this.state.chassisImage}
                    router={this.props.router}
                    selectedCarProfile={this.state.selectedCarProfile}
                    carProfiles={this.props.carProfileReducer.carProfiles}
                    selectedServiceId={this.state.selectedServiceId}
                    selectedService={this.state.selectedService}
                    promotionalOffersData={this.state.promotionalOffersData}
                    selectedPromotionalOffersData={
                      this.state.selectedPromotionalOffersData
                    }
                    selectedCarServiceDataArray={
                      this.state.selectedCarServiceDataArray
                    }
                    viewBlock={this.renderCarType(
                      this.state.filteredCarServiceCategories
                    )}
                    showError={this.props.bookingRequestReducer.toastType}
                    servicesFetched={this.state.servicesFetched}
                    searchView={this.filterCarType.bind(this)}
                    disableEdit={this.disableEdit.bind(this)}
                    editClicked={this.state.editClicked}
                    changeCarProfile={this.changeCarProfile.bind(this)}
                    commonReducer={commonReducer}
                    bookingRequestReducer={bookingRequestReducer}
                    garageProfileDetails={
                      queryStringtoArray("vendor_id") &&
                      this.props &&
                      this.props.commonReducer &&
                      this.props.commonReducer.garageProfileDetails
                    }
                    carProfileReducer={this.props.carProfileReducer}
                  />
                </div>
                <div className="myCar-body col-md-4">
                  {/*Car wash Updates*/}
                  <div className="car-summary">
                    <div className="summary-heading">Summary</div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img
                            className="cards"
                            src={`${imageBasePath}/images/plus/service-card.svg`}
                            alt=""
                          />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Service</label>
                        </div>
                      </div>
                    </div>
                    {this.state.selectedService && (
                      <div className="category-wrapper car-wrapper">
                        <div className="category-heading">
                          <h5>Categories Selected</h5>
                          {this.state.selectedService ? (
                            <label>
                              <a
                                onClick={() =>
                                  this.setState({ editClicked: true })
                                }
                              >
                                Edit
                              </a>
                            </label>
                          ) : (
                            []
                          )}
                        </div>
                        <div className="category-list">
                          <ul>
                            <li>
                              <label>{this.state.selectedService.name}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ? (
                        <div className="type-card">
                          <div className="type-img car-img">
                            {isCoverImage ? (
                              isCoverImage
                            ) : (
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img
                                    src={`${imageBasePath}/images/placeholder.png`}
                                    alt="Carcility"
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div>
                      ) : (
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>
                      )}
                    </div>
                    {!queryStringtoArray("vendor_id") ? (
                      <div className="car-wrapper">
                        <div className="type-card">
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label className="label-text">
                              Request will be sent to multiple service
                              providers.
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props.commonReducer.garageProfileDetails &&
                          this.props.commonReducer.garageProfileDetails.logo ? (
                            <div className="type-img car-img">
                              <img
                                src={
                                  this.props.commonReducer.garageProfileDetails
                                    .logo
                                }
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img
                                  src={`${imageBasePath}/images/placeholder.png`}
                                  alt="Carcility"
                                />
                              </span>
                            </div>
                          )}
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>
                              {
                                this.props.commonReducer.garageProfileDetails
                                  .companyprofilename
                              }
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage &&
          this.props.bookingRequestReducer.toastType && (
            <ToastMessage
              actions={this.props.actions}
              type={this.props.bookingRequestReducer.toastType}
              title={this.props.bookingRequestReducer.toastType + "..."}
              text={this.props.bookingRequestReducer.toastMessage}
            />
          )}
        <div className="footerSection">
          <Footer {...this.props} />
        </div>
        <CustomModal
          showModal={this.state.catDescriptionModalVisible}
          title={
            this.state.selectedCarCategoryForModel &&
            this.state.selectedCarCategoryForModel.name
          }
          closeIcon={true}
          hideModal={() => {
            this.setState({ catDescriptionModalVisible: false });
          }}
        >
          <Modal.Body>
            <p className="info-text">
              {this.state.selectedCarCategoryForModel &&
                this.state.selectedCarCategoryForModel.description}
            </p>
          </Modal.Body>
        </CustomModal>
        {this.state.searchMobile && (
          <div>
            <MobileHeaderSearch
              mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() => this.setState({ searchMobile: false })}
            />
          </div>
        )}
        {/*<ChasisNumberPopup
          modalStatus={this.state.showChasis}
          hideModalCallback={this.hideModalCallback.bind(this)}
          submitCallback={this.submitCallback.bind(this)}
          actions= {actions}
          bookingRequestReducer={bookingRequestReducer}
        />*/}
      </div>
    );
  }
}
