import React, { Component } from 'react';
import { Link,animateScroll as scroll,  } from 'react-scroll';;
import { map } from 'lodash';
import { appBasePath, metaTagData, canonicalLink, imageBasePath } from '../../constants/staticData';
import { Helmet } from "react-helmet";


export default class Footer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let baseUrl = location.origin;

    const footerItem = [
      {
        footerHeading: 'Car Wash',
        sublist: [
          {
            title: 'Exterior/Interior wash',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Steam Wash',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Car Wrapping',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Paint Treatment',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Car Detailing',
            hyperLink: '/car-wash-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'Car Service',
        sublist: [
          {
            title: 'Normal Car Servicing',
            hyperLink: '/car-service-in-dubai'
          },
          {
            title: 'Annual Car Maintenance',
            hyperLink: '/car-service-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'Car Repair',
        sublist: [
          {
            title: 'AC/Heating & Cooling',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Battery Services',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Engine fixes',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Window Screens & Mirrors',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Tyres, Wheels & Tracking',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Interiors, Audio & Navigation Us',
            hyperLink: '/car-repair-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'For Business',
        sublist: [
          {
            title: 'Join Us',
            hyperLink: '/business'
          },
          {
            title: 'Benefits',
            hyperLink: '/business'
          },
          {
            title: 'Sign Up',
            hyperLink: '/business/sign-up'
          }
        ]
      }
    ];
    const footerLink = map(footerItem, (item, key) => {
      return (
        <div className="col-md-3 col-sm-3 col-xs-12 pad0" key={key}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTagData['landing'] && metaTagData['landing'].title ? metaTagData['landing'].title : 'Carcility'}</title>
            <meta name="description" content={metaTagData['landing'] && metaTagData['landing'].description ? metaTagData['landing'].description : ''} />
            <link rel="canonical" href={canonicalLink} />
          </Helmet>
          <div className="footer-link">
            <h5>{item.footerHeading}</h5>
            <ul className="list-unstyled">
              {map(item.sublist, (subListVal, index) => {
                return (
                  <li key={index}>
                    {subListVal.title == 'Mobile Apps' &&
                    <Link to="download" smooth={true} offset={-100} duration={600}>
                      Mobile Apps
                    </Link>}
                    {subListVal.title != 'Mobile Apps' &&
                    <a
                      href={baseUrl + subListVal.hyperLink}
                      onClick={() => {
                        if (subListVal.title === 'Benefits') {
                          window.localStorage.setItem(subListVal.title, true);
                        }
                      }}
                    >
                      {subListVal.title}
                    </a>}

                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    });
    const socialIcon = [
      {
        title: 'facebook',
        hyperLink: 'https://www.facebook.com/carcility'
      },
      {
        title: 'twitter',
        hyperLink: 'https://twitter.com/carcility'
      },
      {
        title: 'instagram',
        hyperLink: 'https://www.instagram.com/carcility'
      }
    ];
    const quickLinks = [
      {
        title: 'About Us',
        hyperLink: '/about'
      },
      {
        title: 'Help',
        hyperLink: '/blog/faq'
      },
      {
        title: 'Blog',
        hyperLink: '/blog'
      },
      {
        title: 'Contact Us',
        hyperLink: '/contact'
      }
    ];
    const socialLink = map(socialIcon, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
            <i className={'mdi mdi-' + item.title} />
          </a>
        </li>
      );
    });
    const quickLink = map(quickLinks, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
            {item.title}
          </a>
        </li>
      );
    });
    
    return (<div>
      <div className="footerSection footerpad dashboard-footer authpage-footer landingPage-footer">
          <div className="container">
            <div className="quick-link-section">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12 pad0">
                  <div className="footer-link">
                    <ul className="social-icon">
                      {quickLink}
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div className="appLink-section">
              <div className="row">
                <div className="col-md-9 col-sm-8 col-xs-6 pad0">
                  {footerLink}
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6 pad0 pull-right">

                  <ul className="payment-icon pull-right">
                    <li><img src={`${imageBasePath}/images/landing/mobil-logo-footer.png`} alt="master card" /></li>
                    <li><img src={`${imageBasePath}/images/landing/master.png`} alt="master card" /></li>
                    <li><img src={`${imageBasePath}/images/landing/visa.png`} alt="visa card" /></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="social-section">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12 pad0">
                  <div className="footer-link">
                    <ul className="social-icon">
                      {socialLink}
                    </ul>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 col-xs-12 pad0">
                  <div className="terms-right">
                    <label className="termsConditionsLink">
                      <a
                        onClick={() => {
                          this.props.router.push(appBasePath + '/terms');
                          // window.open(appBasePath + '/terms', "_blank");
                        }}
                      >
                        Terms and Conditions and{' '}
                      </a>
                      <a
                        onClick={() => {
                          this.props.router.push(appBasePath + '/privacy');
                          // window.open(appBasePath + '/privacy', "_blank");
                        }}
                      >
                        Privacy Policy{' '}
                      </a>
                    </label>
                    <span> | </span>
                    <label>&copy;2020 <a>carcility.com</a>.All Rights Reserved</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
}
}
