import React, { Component } from 'react';
import Header from "./Header";
import Welcome from "./Welcome";
import Awards from "./Awards";
import CustomerFeedback from "./CustomerFeedback";
import ValuePara from "./ValuePara";
import DownloadApp from "./DownloadApp";
import Service from "./Service";
import WhyCarcility from "./WhyCarcility";
import WhyPeopleLove from "./WhyPeopleLove";
import HowWorks from "./HowWorks";
import Footer from "./Footer";
import ManufactureList from "./ManufactureList";
import VendorMaps from "./VendorMaps";
import Faq from "./faq";
import FloatingMenu from "./FloatingMenu";
import SideBar from "./SideBar";
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import DownloadAppPopUp from './DownloadAppPopUp';
import LazyLoad from 'react-lazyload';
import ToastMessage from '../common/ToastMessage';
import {appBasePath, imageBasePath} from "../../constants/staticData";
import SpriteMaterial from "../common/SpriteMaterial";
import {DropdownButton, MenuItem} from "react-bootstrap";
import Button from "../common/Button";
import ModalVideo from "react-modal-video";
import Slider from "react-slick";
import {isWebpSupported} from "react-image-webp/dist/utils";
import TagManager from "react-gtm-module";
import filter from "lodash/filter";
import take from "lodash/take";
import map from "lodash/map";
import WOW from "wowjs";
import * as Scroll from "react-scroll";
import has from "lodash/has";
import findIndex from "lodash/findIndex";
import {removeParameterFromUrl} from "../../helpers";
import ReactRotatingText from "react-rotating-text";


export default class Landing extends Component {
  constructor(props) {
    super(props);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};
    this.searchVendors = this.searchVendors.bind(this);
    this.sideBarOpenFunc = this.sideBarOpenFunc.bind(this);

    (this.reverseScroll = false), (this.startAnimation = false), (this.divEle = 0), (this.state = {
      autoSuggestedList: props.commonReducer && props.commonReducer.autoSuggestedList
        ? props.commonReducer.autoSuggestedList
        : [],
      autoCompleteData: [],
      searchMobile: false,
      sidebarProfileDropdown: false,
      windowWidth: 0,
      searchText: '',
      searchTextHead: '',
      blinkText: true,
      blinkTextHead: true,
      navbarVisible: false,
      activeBenifitsId: 0,
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : '',
      serviceSearchTerm: '',
      serviceSearchTermHead: '',
      headFocus: false,
      normalFocus: false,
      timelineHeight: 0,
      locateMeDD: false,
      serviceDD: false,
      value: '+91',
      scrollType: true,
      sidebarOpen: false,
      ie: false,
      appDownloadPhoneNumber: '',
      showPhoneNoError: false,
      showOfferNotification: false,
      showYoutubePromo: false,
      workshopsData: [],
      isWorkshopsLoading: true,
      innerWidth: '',
      showModalPopUp: true,
      redirectToSection : localStorage.getItem("redirectToSection"),
    });
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.moveToInternalPosition = this.moveToInternalPosition.bind(this);
    this.scrollPage = this.scrollPage.bind(this);
    this.formData = {
      phone: ''
    };
    this.errors = {
      phone: false
    };

  }
  componentDidMount() {
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    this.props.actions.getWorkshops();
    if (authData && authData.phone && authData.phoneVerified && authData.type && authData.type == 'customer') {
      this.props.router.push(appBasePath + '/dashboard-plus');
    }
    // only for ie
    var ua = window.navigator.userAgent;

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      this.setState({ ie: true });
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    new WOW.WOW().init();
    window.addEventListener('scroll', this.scrollPage);
    this.intervalId = setInterval(this.timer.bind(this), 3500);

    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : undefined;
    if (userSelectedLocation) {
      this.props.actions.setCurrentLocation(userSelectedLocation);
    }

    console.log("this.props.commonReducer.autoSuggestedList",this.props.commonReducer.autoSuggestedList)
    if (!(this.props.commonReducer.autoSuggestedList && this.props.commonReducer.autoSuggestedList.length)) {
      this.props.actions.getAutosuggestedList();
    }

    this.showNotification();


    setTimeout(
      () => {
        let scroller = Scroll.scroller;
        if (window.localStorage.getItem('MobileApp') && JSON.parse(window.localStorage.getItem('MobileApp'))) {
          scroller.scrollTo('download', {
            // delay: 100,
            smooth: true,
            offset: -100
          });
          window.localStorage.removeItem('MobileApp');
        }
      },
      1200
    );
    if(this.state.redirectToSection ){
      //this.moveToInternalPosition(this.state.redirectToSection );

      window.scroll(0,this.findPos(document.getElementById(`${this.state.redirectToSection }`)));
      localStorage.removeItem('redirectToSection');

    }
  }

  showNotification(){
    console.log("pageYOffset  :",window.pageYOffset)
    setTimeout(
      function() {
        if(window.pageYOffset  <= 50){
          console.log("pageYOffset now :",window.pageYOffset)
          this.setState({showOfferNotification: true});
        }else{
          this.setState({showOfferNotification: false});
        }

      }
        .bind(this),
      5000
    );

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList && !this.state.autoSuggestedList.length) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    this.setState({
      workshopsData: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? nextProps.commonReducer.vendorsListFromSearch : [],
      isWorkshopsLoading: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? false : true,
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.scrollPage);
    //window.Tawk_API.showWidget();
    clearInterval(this.intervalId);
    this.props.actions.hideErrorMessage();
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }

  moveToInternalPosition(id){
    // document.getElementById(`${id}`).scrollIntoView();
    window.scroll(0,this.findPos(document.getElementById(`${id}`)));
    const { innerWidth} = this.state;
    if(innerWidth<480){
      this.setState({navbarVisible: !this.state.navbarVisible});
    }
  }

  findPos(obj) {
    var curtop = 0;
    const { innerWidth} = this.state;
    var additionalPos = innerWidth < 480 ? 150 :innerWidth < 800 ? 120 : 40;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
    return [(curtop - (additionalPos))];
    }
}

  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        locationSearchTerm: searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ input: searchTerm }, function (res) {
        that.setState({
          autoCompleteData: res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        locationSearchTerm: searchTerm,
        autoCompleteData: []
      });
    }
  }
  timer() {
    let benifitId = this.state.activeBenifitsId;
    benifitId += 1;
    this.benefitsToggleClass('none', benifitId);
  }
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, innerWidth: window.innerWidth});
  }
  benefitsToggleClass(val, id) {
    const totalLength = document.querySelectorAll('.benefits-list').length;
    if (id >= totalLength) {
      id = 0;
      this.setState({ activeBenifitsId: 0 });
    } else {
      this.setState({ activeBenifitsId: id });
    }

    clearInterval(this.intervalId);
    this.intervalId = setInterval(this.timer.bind(this), 3500);

    let children = document.querySelectorAll('.benefits-description');
    for (var i = 0; i < children.length; ++i) {
      if (children[i].className.indexOf('hide') == -1) {
        children[i].className += ' hide';
      }
    }
    let selectedChild = children[id];
    selectedChild && selectedChild.classList.remove('hide');
    let selectImage = document.querySelectorAll('.benefits-list');
    for (var i = 0; i < selectImage.length; ++i) {
      if (selectImage[i].className.indexOf('active') > 0) {
        selectImage[i].classList.remove('active');
      }
    }
    if(selectImage[id] && selectImage[id].className)
      selectImage[id].className += ' active';
  }
/*  activeTimelineDots(index, height) {
    if (window.pageYOffset > document.getElementsByClassName('hiw-block')[0].offsetHeight + height) {
      if (!document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.add('active');
      }
    } else {
      if (document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.remove('active');
      }
    }
  }*/
  searchNav() {
    let normalHeader = document.getElementsByClassName('default-header')[0];
    let scrollHeader = document.getElementsByClassName('scroll-header')[0];
    let bannersearchHeight = document.getElementsByClassName('banner-search')[1].offsetHeight;
    if (this.state.windowWidth > 1100 && normalHeader) {
      if (window.pageYOffset > document.getElementsByClassName('banner-search')[1].offsetHeight + bannersearchHeight) {


        if (!normalHeader.classList.contains('hide')) {

          normalHeader.classList.add('hide');
          scrollHeader.classList.remove('hide');
          this.setState({showOfferNotification: false});
        }
      } else {
        if (normalHeader.classList.contains('hide')) {
          scrollHeader.classList.add('hide');
          normalHeader.classList.remove('hide');



        }
      }
    } else if (normalHeader) {
      if (normalHeader.classList.contains('hide')) {
        scrollHeader.classList.add('hide');
        normalHeader.classList.remove('hide');


      }
    }
    if (this.state.sidebarOpen) {
      this.setState({ sidebarOpen: false });
    }
  }

  sideBarOpenFunc(){
    this.setState({ sidebarOpen: !this.state.sidebarOpen});
  }


  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { lat: Number(location.lat), lng: Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          let locationResult = {};
          if (results[0].formatted_address && results[0].formatted_address.indexOf('Unnamed Road') > -1) {
            locationResult = results[1];
          } else {
            locationResult = results[0];
          }
          this.setState({
            userLocation: locationResult,
            locationSearchTerm: searchTerm || location.description || locationResult.formatted_address,
            showLocationDropdown: false
          });
          that.props.actions.setCurrentLocation({
            lat: locationResult.geometry.location.lat(),
            lng: locationResult.geometry.location.lng(),
            formatted_address: locationResult.formatted_address,
            label: searchTerm || location.description || locationResult.formatted_address
          });

        } else {
          this.setState({ showLocationDropdown: false });
        }
      });
    }
  }
  scrollPage() {

    this.showNotification();
    this.searchNav();
  }
  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng };
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == 'keyword') {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, 'distance');
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == 'autosuggest') {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, 'distance');
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }

  searchMobileFunc() {
    this.setState({searchMobile: !this.state.searchMobile})
  }

  closeModal(){
    this.setState({showModalPopUp: false});
  }



  render(){
      const settings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        speed: 2000,
        slidesToShow: 1,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        swipe: false,
        fade: true,
        arrows: false,
        pauseOnHover: false,
        autoplay: true,
        afterChange: (prev, next) => {
          let currentSlide = prev+1;
          this.setState({ currentSlide: currentSlide });
        },
      };

      const tagManagerArgs = {
        dataLayer: {
          pageCategory: 'Homepage',
        },
        dataLayerName: 'PageDataLayer'
      }

      TagManager.dataLayer(tagManagerArgs);


      const origin = location.origin;
      let baseUrl = location.origin;

      let searchView = filter(this.state.autoSuggestedList, val => {
        if ((this.state.searchText != '' && this.state.normalFocus) && val.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
          return val;
        }
      });
      let searchViewHead = filter(this.state.autoSuggestedList, val => {
        if ((this.state.searchTextHead != '' && this.state.headFocus ) && val.name.toLowerCase().indexOf(this.state.searchTextHead.toLowerCase()) != -1) {
          return val;
        }
      });
      searchViewHead = take(searchViewHead, 5);
      const searchAutoSuggestedListHead = map(searchViewHead, (val, key) => {
        return (
          <MenuItem
            onClick={() => {
              // this.searchVendors(val, 'autosuggest');
              this.setState({ searchTextHead: val.name });
            }}
            eventKey={key}
            key={key}
          >
            {val.name}
          </MenuItem>
        );
      });



     // console.log("this.state.searchText 1",this.state.autoSuggestedList )
      // console.log("this.state.searchText 2",this.state.searchText )
      // console.log("this.state.searchText 3",searchView )
      searchView = take(searchView, 5);
      const searchAutoSuggestedList = map(searchView, (val, key) => {
        return (
          <MenuItem
            onClick={() => {
              // this.searchVendors(val, 'autosuggest');
              this.setState({ searchText: val.name });
            }}
            eventKey={key}
            key={key}
          >
            {val.name}
          </MenuItem>
        );
      });


      let locationData = [{ textPlaceholder: 'Current Location' }];
      map(this.state.autoCompleteData, (location, key) => {
        return locationData.push({ key: key, description: location.description, place_id: location.place_id });
      });
      map(this.props.commonReducer && this.props.commonReducer.userLocations, (location, key) => {
        return locationData.push({
          key: key,
          lat: location.lat,
          lng: location.lng,
          label: location.label,
          id: location.id,
          formatted_address: location.location
        });
      });
      const locationAutosuggestedList = map(locationData, (val, key) => {
        return (
          <MenuItem
            onClick={item => {
              if (item.target.text != 'Current Location') {
                this.setUserLocation(val, item.target.text);
              } else {
                if (navigator.geolocation) {
                  const that = this;
                  navigator.geolocation.getCurrentPosition(function (position) {
                    that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                  }, function (error) {
                    switch (error.code) {
                      case error.PERMISSION_DENIED:
                        that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                        break;
                      case error.POSITION_UNAVAILABLE:
                        that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                        break;
                      case error.TIMEOUT:
                        that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                        break;
                      case error.UNKNOWN_ERROR:
                        that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                        break;
                    }
                  }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 });
                } else {
                  this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
                }
              }
            }}
            eventKey={key}
            key={key}
          >
            {val.description ? val.description : val.label ? val.label : val.textPlaceholder}
          </MenuItem>
        );
      });
      const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    let Background = isWebpSupported() ?'../images/landing/welcome-back.webp' : '../images/landing/landing-welcome-back.svg';
    let BackgroundResponse = isWebpSupported() ?'../images/landing/welcome-back-mob.webp' : '../images/landing/landing-welcome-back-mob.svg';
      return(
          <div>
            <FloatingMenu />
            {this.props.bookingRequestReducer.toastMessage &&
              this.props.bookingRequestReducer.toastType &&
              <ToastMessage
                actions={this.props.actions}
                type={this.props.bookingRequestReducer.toastType}
                title={this.props.bookingRequestReducer.toastType + '...'}
                text={this.props.bookingRequestReducer.toastMessage}
              />}
            <div>
              <div className={this.state.showOfferNotification ? "notification-alert  show-notification" : "notification-alert alert alert-success"} role="alert">
                <div className="content">

                  {/*
            <div className="off-percent">We have just partnered with Mobil 1 to improve customer experience for automotive repair and services.</div>
*/}

                  {/*
            <span className="off-percent">45% OFF</span> on Steam car wash *Limited period offer. Use Code: <span className="off-percent">45OFF</span>*
*/}

                  <span className="off-percent">Exclusive Car Service, Repair and Wash deals for you.</span>


                  <span className="book-now" onClick={() => this.props.router.push(`${appBasePath}/all-offers`) } >BOOK NOW</span>


                </div>
                <div className="  close" onClick={() => this.setState({ showOfferNotification: false })}>
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>
              <nav className={this.state.showOfferNotification ? "navbar navbar-default default-header default-show-header-top" : "navbar navbar-default default-header default-header-top"} id="header-section1">


                  <div className="container-fluid">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar"
                        aria-expanded="false"
                        aria-controls="navbar"
                        onClick={() => {
                          this.setState({ navbarVisible: !this.state.navbarVisible });
                        }}
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </button>
                      <a className="navbar-brand desk-logo logo-border" href={baseUrl}>
                        <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                      </a>

                    </div>
                    {/*Search mobile for mobile */}
                    <span className="search-mobile sm-mob" onClick={() => this.searchMobileFunc()}>
             {/*<i className="mdi mdi-magnify" aria-hidden="true" />*/}
                      {this.state.innerWidth <= 767 && <SpriteMaterial posx={73} posy={0} width={21} height={23} widthBack={21} classNameVal='magnify' aria-hidden="true"/>}
            </span>

                    <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
                      <ul className="nav navbar-nav navbar-left-sub">
                        {/* <li className="search-mobile md-mob" onClick={() => this.setState({ searchMobile: true })}>
                  <i className="mdi mdi-magnify" aria-hidden="true" />
                </li> */}

                        <li className="offer-item">
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.props.router.push(appBasePath + '/all-offers');
                            }}
                          >
                            Offers{' '}
                          </a>
                        </li>
                        <li className="work-active">

                          <a onClick={() => this.moveToInternalPosition('howworks-section')}>
                            how it works
                          </a>
                          <label />
                        </li>
                        <li>
                          <a href="/business ">
                            For Business
                          </a>
                          <label />
                        </li>
                        <li>
                          <a href="/blog">
                            BLOG
                          </a>
                          <label />
                        </li>
                      </ul>
                      <ul className="nav navbar-nav navbar-right navbar-right-land">
                        {/*<li className="download-text">
                  <a onClick={() => this.moveToInternalPosition('download-section')}>
                  <img className="landing-download" src={`${imageBasePath}/images/download-app-logo.png`} alt="download the app" />
                   Download the app!
                  </a>
                  </li>*/}
                        <li>
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.props.router.push(appBasePath + '/sign-in');
                            }}
                          >
                            Log In{' '}
                          </a>
                          <label />
                        </li>
                        <li>
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.props.router.push(appBasePath + '/register');
                            }}
                          >
                            {' '}Sign Up
                          </a>
                          <label />
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              <nav className="navbar navbar-default scroll-header hide" id="header-section2">
                <div className="container-fluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded="false"
                      aria-controls="navbar"
                      onClick={() => {
                        this.setState({ navbarVisible: !this.state.navbarVisible });
                      }}
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand desk-logo logo-border" href={baseUrl}>
                      <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                    </a>
                  </div>
                  <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
                    <ul className="nav navbar-nav nav-left nav-left-land">
                    <li className="offer-item">
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.props.router.push(appBasePath + '/all-offers');
                            }}
                          >
                            Offers{' '}
                          </a>
                        </li>
                      <li>
                        <div className="banner-search banner-search-header banner-search-header-land">
                          <div className="search-wrapper">
                            <form className="form-inline">

                              <DropdownButton
                                bsSize="small"
                                id="dropdown-size-small"
                                noCaret
                                onToggle={e => this.setState({ locateMeDD: e })}
                                onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                                className="location-search"
                                title={
                                  <span className="search-left">
                              <i className="mdi mdi-crosshairs-gps mdi-greenIcon-gps" />
                              <input
                                type="text"
                                className="form-control input"
                                placeholder="Locate Me"
                                value={this.state.locationSearchTerm}
                                onChange={e => {
                                  e.preventDefault();
                                  this.autocompleteLocation(e);
                                  this.setState({ locationSearchTerm: e.target.value });
                                }}
                              />
                              <i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                            </span>
                                }
                              >
                                {locationAutosuggestedList}
                              </DropdownButton>

                              <DropdownButton
                                bsSize="small"
                                id="dropdown-size-small"
                                noCaret
                                onToggle={e => this.setState({ serviceDD: e })}
                                open={this.state.searchTextHead == '' ? false : this.state.serviceDD}
                                onChange={e => {
                                  this.setState({ searchTextHead: e.target.value });
                                }}
                                onSelect={(id, e) => {
                                  this.setState({ serviceSearchTermHead: e.target.innerText });
                                }}
                                className="location-search search-service-block"
                                title={
                                  <span
                                    className="search-right"
                                    onClick={e => {
                                      e.preventDefault();
                                      this.setState({ blinkTextHead: false });
                                    }}
                                  >
                              <input
                                type="text"
                                ref={focusFunctionHead => this.inputFocusTopHead = focusFunctionHead}
                                className="form-control form-search-land"
                                placeholder=""
                                onFocus={(e) => {e.target.placeholder = "Search for services, service providers..", this.setState({headFocus: true,normalFocus: false})}}
                                onBlur={(e) => {e.target.placeholder = "Search for services, service providers..", this.setState({headFocus: false})}}
                                onChange={e => {
                                  e.preventDefault;
                                  const serviceSearchTermHead = e.target.value;
                                  this.setState({ serviceSearchTermHead: serviceSearchTermHead });
                                }}
                                value={this.state.serviceSearchTermHead}
                              />
                              <input
                                className={'hide'}
                                type="submit"
                                onClick={e => {
                                  e.preventDefault();
                                  this.searchVendors(this.state.searchTextHead, 'keyword');
                                }}
                              />
                              <span
                                onClick={e => {
                                  e.preventDefault;
                                  this.inputFocusTopHead.focus();
                                }}
                                className={this.state.blinkTextHead ? 'blink-text' : 'blink-text hide'}
                              >
                                <ReactRotatingText
                                  items={[
                                    'Find a quality service provider near you.'
                                  ]}
                                  // color="#BFC4C9"
                                />
                              </span>
                              <i
                                className="mdi mdi-magnify"
                                onClick={() => {
                                  this.searchVendors(this.state.searchTextHead, 'keyword');
                                }}
                              />
                            </span>
                                }
                              >
                                {searchAutoSuggestedListHead}
                              </DropdownButton>
                            </form>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                      <li>
                        <Button
                          dataLabelledby="Get Started"
                          isSubmitBtn={true}
                          btnCallBack={() => {
                            this.props.router.push(appBasePath + '/guest-user');
                          }}
                          btnType="red"
                          btnSize="sm"
                          fontSize={13}
                          label="Book Now"
                        />
                      </li>
                      <li
                        className="menu-option"
                        onClick={() => this.sideBarOpenFunc()}
                      >
                        <i className="mdi mdi-menu mdi-scroll-menu" />
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div id="welcome-page" style={{background:(this.state.innerWidth > 480 ?`url(${Background})`:'none',`height: 78vh`)}}>
              <ModalVideo channel='youtube' isOpen={this.state.showYoutubePromo} videoId='h736TiMyfrs' maxwidth="600px" height="500px" onClose={() => this.setState({ showYoutubePromo: false })} />
              <div className="row main-header">
                <div className="col-md-8 col-sm-12 col-xs-12 pad0 welcome-section-up">
                  <div className="fb-rating-desk landing-mobile-fal">
                    <div className="static-rating"></div>
                    {/*<img  src={`${imageBasePath}/images/landing/static-rating.png`} className="fb-rating landing-mobile-fal"/>*/}
                  </div>
                  <div className="no-one-car-desk"><h2>The <span className="no-one-bold">No.1</span> Car Service in UAE </h2></div>
                  <div className="no-one-car-mob"><h2><div>The
                    <span className="no-one-bold"> No.1</span> </div><div>Car Service in UAE</div></h2>
                  </div>
                  <div className="service-section-subText welcome-service-section">
                  Hassle-free Quality Car Service.
                 </div>
                  <div id="new-banner-section"  >

                    <div className="banner-search banner-search-land">
                      <div className="search-wrapper search-wraaper-land">
                        <form className="form-inline">
                          <div className="mobileShadow">
                           <DropdownButton
                            bsSize="small"
                            id="dropdown-size-small"
                            noCaret
                            onToggle={e => this.setState({ locateMeDD: e })}
                            onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                            className="location-search"
                            title={
                              <span className="search-left">
                      {this.state.innerWidth > 1100 && <i className="mdi mdi-crosshairs-gps mdi-greenIcon-gps" />}
                      <input
                        type="text"
                        className="form-control location"
                        placeholder="Locate Me"
                        value={this.state.locationSearchTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autocompleteLocation(e);
                          this.setState({ locationSearchTerm: e.target.value });
                        }}
                      />
                      <i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                    </span>
                            }
                          >
                            {locationAutosuggestedList}
                          </DropdownButton>
                           <DropdownButton
                            bsSize="small"
                            id="dropdown-size-small"
                            noCaret
                            onToggle={e => this.setState({ serviceDD: e })}
                            open={this.state.searchText == '' ? false : this.state.serviceDD}
                            onChange={e => this.setState({ searchText: e.target.value })}
                            onSelect={(id, e) => {
                              this.setState({ serviceSearchTerm: e.target.innerText });
                            }}
                            className="location-search search-service-block welcome-search-service"
                            title={
                              <span className="search-right" onClick={() => this.setState({ blinkText: false })}>
                      <input
                        type="text"
                        ref={focusFunction => this.inputFocus = focusFunction}
                        className="form-control"
                        placeholder=""
                        onFocus={(e) => {e.target.placeholder = this.state.windowWidth > 460 ? "E.g. Battery Services, Brake Repairs …": "E.g. Battery Services..", this.setState({normalFocus: true,headFocus: false})}}
                        onBlur={(e) => {e.target.placeholder = this.state.windowWidth > 460 ? "E.g. Battery Services, Brake Repairs …" : "E.g. Battery Services..", this.setState({normalFocus: false,})}}
                        onChange={e => {
                          e.preventDefault;
                          const serviceSearchTerm = e.target.value;
                          this.setState({ serviceSearchTerm: serviceSearchTerm });
                        }}
                        value={this.state.serviceSearchTerm}
                      />
                      <span
                        onClick={e => {
                          e.preventDefault;
                          this.inputFocus.focus();
                        }}
                        className={this.state.blinkText ? 'blink-text' : 'blink-text hide'}
                      >
                        {this.state.windowWidth > 768 &&
                        <ReactRotatingText
                        items={[
                          'What service do you need today?',
                          'Battery Services, Brake Services ...etc'
                        ]}
                       // color="#BFC4C9"
                        />}
                        {(this.state.windowWidth <= 768 && this.state.windowWidth > 460)&&
                        <ReactRotatingText
                          items={['What service do you need today?', 'Battery Services, Brake Services ...etc']}
                         // color="#BFC4C9"
                        />}
                        {(this.state.windowWidth <= 460)&&
                        <ReactRotatingText
                          items={['What service do you need?', 'Battery Services...etc']}
                         // color="#BFC4C9"
                        />}
                      </span>
                                {/* <i className="mdi mdi-magnify" /> */}
                    </span>
                            }
                          >
                            {searchAutoSuggestedList}
                          </DropdownButton>
                          </div>
                          <button
                            data-labelledby="Get Started"
                            onClick={e => {
                              e.preventDefault();
                              // this.searchVendors(this.state.searchText, 'keyword');
                              this.props.router.push(appBasePath + '/guest-user');
                            }}
                            className="btn btn-default green-btn button"
                          >
                            Book Now
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="landing-mob-tru landing-rate ">
                    <div className="fb-rating-desk ">
                      <div className="static-rating"></div></div>
                    {/*<img  src={`${imageBasePath}/images/landing/static-rating.png`} className="fb-rating "/>*/}
                  </div>

                  <div className="welcome-know-carcility landing-mobile-fal" onClick={e => {
                    e.preventDefault();
                    this.setState({ showYoutubePromo: true })

                  }}>
                    <img src="/images/landing/play-video.svg" alt="app store" />
                    <span>Know Carcility</span>

                  </div>
                  <div className="download-app-text landing-mobile-fal">
                    Download the app!
                  </div>
                  <ul className="download-app-link landing-mobile-fal">
                    <li>
                      <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                        <img src={`${imageBasePath}/images/landing/car-service-app-apple-store.svg`} alt="car service app - app store" />
                      </a>
                    </li>
                    <li>
                      <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                        <img src={`${imageBasePath}/images/landing/car-service-app-googleplay-store.svg`} alt="car service app - play store" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="welcome-page-mobileback" style={{background:(this.state.innerWidth < 480 ?`url(${BackgroundResponse}) repeat-x`:'none')}} className="col-md-4  col-sm-12 col-xs-12 pad0 welcome-mobile-img welcome-mobile-image-desk">
                  <div className="slider-div" >
                    <Slider ref="slick" {...settings} >
                      <div>
                        <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${this.state.innerWidth < 480 ? (isWebpSupported() ? 'mob.webp' :'mob.jpg') : (isWebpSupported() ? 'desk.webp' :'desk.jpg')}`}/>
                      </div>
                      <div>
                        <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${this.state.innerWidth < 480 ? (isWebpSupported() ? 'mob1.webp' :'mob1.jpg') : (isWebpSupported() ? 'desk1.webp' :'desk1.jpg')}`}/>
                      </div>
                      <div>
                        <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${this.state.innerWidth < 480 ? (isWebpSupported() ? 'mob2.webp' :'mob2.jpg') : (isWebpSupported() ? 'desk2.webp' :'desk2.jpg')}`}/>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>

            </div>
            <Service {...this.props}/>
              <WhyCarcility />
              <HowWorks />
              <LazyLoad >
                <ManufactureList innerWidth={this.state.innerWidth}/>
              </LazyLoad>
              <LazyLoad >
                <div id="whypeoplelove-section">
                  <div className="whypeople-text-section">
                    <div  className="whypeople-header">
                      <span>Why people love using Carcility?</span>
                    </div>
                    <div className="whypeople-sub-header">
                    <span>We aim to bring the best service possible and take the burden off
                          our customers when it comes to auto repair and maintenance.</span>
                    </div>
                  </div>
                  <div className="common-why-people-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
                          <div className="why-loveimage">
                            <div className="why-icon">
                              <div className="easy easy-use"  width="5" height="1"></div>
                              {/*<img src={`${imageBasePath}/images/landing/EasytoUse.svg`} alt="easy to use" className="easy-use"/>*/}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Easy to Use</h1>
                            <p>Our site and mobile apps make it easy to book anytime,anywhere.</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
                          <div className="why-loveimage">
                            <div className="why-icon" >
                              <div className="peace peace-mind"></div>
                              {/*<img src={`${imageBasePath}/images/landing/PeaceofMindGuaranteed.svg`} alt="peace of mind" className="peace-mind" /> */}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Peace of Mind Guaranteed</h1>
                            <p>Quality service, no unexpected charges,and customer support.</p>
                          </div>
                        </div>
                        <div className="clearBothWhy"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
                          <div className="why-loveimage">
                            <div className="why-icon">
                              <div className="fair fair-quote"></div>
                              {/*<img src={`${imageBasePath}/images/landing/GetInstant-FairQuote.svg`} alt="instant fair quote" className="fair-quote" /> */}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Get Instant and Fair Quote</h1>
                            <p>Recieve and compare instant quotes from different service providers.</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
                          <div className="why-loveimage">
                            <div className="why-icon">
                              <div className="maintain maintain-track"></div>
                              {/* <img src={`${imageBasePath}/images/landing/MaintainanceTracker.svg`} alt="maintainance tracker" className="maintain-track" /> */}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Maintenance Tracker</h1>
                            <p>Service is tracked,and you'll receive reminders for future service.</p>
                          </div>
                        </div>
                        <div className="clearBothWhy"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
                          <div className="why-loveimage">
                            <div className="why-icon">
                              <div className="manage manage-car"></div>
                              {/* <img src={`${imageBasePath}/images/landing/ManageyourCarsOnline.svg`} alt="manage your cars online" className="manage-car" /> */}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Manage your Cars Online</h1>
                            <p>Store your car and insurance details,24/7 access to service records
                              and no more paper work!</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
                          <div className="why-loveimage">
                            <div className="why-icon">
                              <div className="secure secure-pay"></div>
                              {/* <img src={`${imageBasePath}/images/landing/SecurePayment.svg`} alt="secure payment" className="secure-pay" />   */}
                            </div>
                          </div>
                          <div className="why-people-maintext">
                            <h1>Secure Payment</h1>
                            <p>We hold payment until service is complete and you're satisfied.</p>
                          </div>
                        </div>
                        <div className="clearBothWhy"></div>
                      </div>

                    </div>
                  </div>


                </div>
              </LazyLoad>
              <LazyLoad >
                 <Awards />
              </LazyLoad>
              <LazyLoad >
                <VendorMaps workshopsData={this.state.workshopsData}/>
              </LazyLoad>

              <CustomerFeedback innerWidth={this.state.innerWidth}/>
              <LazyLoad >
                <Faq />
              </LazyLoad>
              <LazyLoad >
                <DownloadApp {...this.props}/>
              </LazyLoad>
              <LazyLoad >
                <ValuePara {...this.props} innerWidth={this.state.innerWidth}/>
              </LazyLoad>
                <Footer {...this.props} innerWidth={this.state.innerWidth}/>

                <SideBar {...this.props} sidebarOpen={this.state.sidebarOpen}/>

              {this.state.searchMobile &&
                <div>

                  <MobileHeaderSearch
                  {...this.props}
                    mobile={true}
                    actions={this.props.actions}
                    commonReducer={this.props.commonReducer}
                    mobSearchClick={() => this.setState({ searchMobile: false })}
                  />
                </div>}
                <DownloadAppPopUp   showModalPopUp={this.state.showModalPopUp} closeModal={()=> this.closeModal()}/>
          </div>
       )
   }
}
