import React, { Component } from 'react';
import map  from 'lodash/map';
import range  from 'lodash/range';
import merge  from 'lodash/merge';
import values  from 'lodash/values';
import findIndex  from 'lodash/findIndex';
import concat  from 'lodash/concat';
import sortBy  from 'lodash/sortBy';
import filter  from 'lodash/filter';


import WorkLogModal from './WorkLogModal';
import { Media } from 'react-bootstrap';
import { imageBasePath } from '../../constants/staticData';


class StatusBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobProgress: [],
      statusPopupPosition: 34,
      statusPopupArrow: -40,
      bcPopupPosition: 26,
      bcPopupArrow: -40,
      margin: 0,
      activeButton: undefined,
      smallScreen: false,
      clickEvent: false,
      hover: false,
      worklogVisible: false,
      currentKey: 0,
      clickedDotPosition: 0
    };
    this.serviceProgressPopUp = this.serviceProgressPopUp.bind(this);
    this.setInitialPosition = this.setInitialPosition.bind(this);
  }

  componentDidMount() {
    
    let divWidth = document.getElementById('custom-dots').offsetWidth;
    let totalWidth = (divWidth / (this.props.statusCount + 1)) - 14;
    this.setState({ margin: totalWidth });
    let inpCount = 0;
    map(this.props.groupedJobStatus, (group) => {
      let inprogressCount = filter(group, { serviceStatus: 'In Progress' }).length;     
      if (inprogressCount) {
        inpCount = inpCount + 1;
      }
    });
    
    let popUpPosition = this.props.requestStatus == 'inprogress' ? inpCount : this.props.requestStatus == 'Completed' ? this.props.statusCount + 1 : 0;
    setTimeout(function () { this.setInitialPosition(popUpPosition, this.props.requestStatus == 'Completed'); }.bind(this), 100);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  setInitialPosition(position, isCompleted) {
    const currentElm = document.getElementById(`dot-position-${position}`);
    if (currentElm) {
      let currentPopupPosition = undefined;
      let currentPopupArrow = undefined;
      const totalTimelienWidth = document.getElementById('custom-dots').offsetWidth;
      let clickPosition = this.state.margin * position;
      if (this.state.smallScreen) {
        currentPopupPosition = totalTimelienWidth * .5;
      } else {
        currentPopupPosition = (totalTimelienWidth * .6) + 10;
      }
      if (position == -10 || isCompleted) {
        let winOffset = window.innerWidth < 1250 ? 194 : 214;
        currentPopupArrow = totalTimelienWidth - 31;
        currentPopupPosition = currentElm.offsetLeft - winOffset;
      } else if (position == 0) {
        currentPopupPosition = 34, currentPopupArrow = -4;
      } else if (clickPosition > currentPopupPosition) {

        currentPopupArrow = currentElm.offsetLeft - 14;
        currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
      } else {
        currentPopupPosition = this.state.margin * position, currentPopupArrow = currentElm.offsetLeft - 14;
        currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
      }
      this.setState({
        statusPopupPosition: currentPopupPosition, statusPopupArrow: currentPopupArrow, hover: false,
        bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow,
        currentKey: isCompleted ? -10 : position,
        clickedDotPosition: position
      });
    }
  }
  updateWindowDimensions() {
    if (window.innerWidth < 1200) {
      this.setState({ smallScreen: true });
    }
  }
  serviceProgressPopUp(serviceName, time, progress, image, requestStatus) {

    
 
    let currentKey = this.state.currentKey;
    
    if (currentKey == this.props.statusCount + 1) {
      currentKey = -10;
    }
    
    return (
      <span>
        {currentKey == 0 || currentKey == -10 ?
          <div className="status-popup n-new-view" id={currentKey == 0 ? "bbr" : currentKey == -10 ? "btr" : ""} style={{ left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition }} >
            <Media>
              <Media.Left>
                <i className="mdi mdi-calendar"></i>
              </Media.Left>
              <Media.Body>
                <Media.Heading>
                  {currentKey == -10 ?
                    requestStatus == 'inprogress' ? 'Job is in Progress' : requestStatus == 'Completed' ? 'Job completed on' : 'Job scheduled to start on'
                    : requestStatus == 'inprogress' ? 'Job started on' : requestStatus == 'Completed' ? 'Job started on' : 'Job Scheduled to start on'}</Media.Heading>
                {(currentKey == -10 && requestStatus == 'inprogress') ? '' : <p className="time">{time} </p>}
              </Media.Body>
            </Media>
          </div> :
          <div className="status-popup" style={{ left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition }} >
            <span className="statusPopup-arrow" ></span>
            {<div className="iconHolder"><img src={image ? image : this.props.allServices.length && this.props.allServices[0] && this.props.allServices[0].parentId === 2 ? `${imageBasePath}/images/carservice.png` : `${imageBasePath}/images/placeholder.png`} className={'placeHolder-img'} /></div>}
            <div className="statusDescription" >
              <h4>{serviceName}</h4>
              <span className="status-process">{progress + "  "} </span>
              {progress != 'pending' && <span>{time}</span>}
              {progress != 'pending' && <a className="view-worklog pull-right" onClick={() => this.setState({ worklogVisible: true })}> View Worklog</a>}
            </div>
          </div>
        }
      </span>
    );
  }
  stepClick(e, key, type) {
    let originalPopupPosition = this.state.statusPopupPosition;
    let originalPopupArrow = this.state.statusPopupArrow;
    let currentPopupPosition = undefined;
    let currentPopupArrow = undefined;

    const totalTimelienWidth = document.getElementById('custom-dots').offsetWidth;
    let clickPosition = this.state.margin * key;
    if (this.state.smallScreen) {
      currentPopupPosition = totalTimelienWidth * .5;
    } else {
      currentPopupPosition = (totalTimelienWidth * .6) + 10;
    }
    if (key == -10) {
      let winOffset = window.innerWidth < 1250 ? 194 : 214;
      currentPopupArrow = totalTimelienWidth - 31;
      currentPopupPosition = e.target.offsetLeft - winOffset;
    } else if (key == 0) {
      currentPopupPosition = 34, currentPopupArrow = -4;
    } else if (clickPosition > currentPopupPosition) {

      currentPopupArrow = e.target.offsetLeft - 14;
      currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
    } else {
      currentPopupPosition = this.state.margin * key, currentPopupArrow = e.target.offsetLeft - 14;
      currentPopupArrow = window.innerWidth < 1250 ? currentPopupArrow - 5 : currentPopupArrow;
    }

    if (e.type == "click") {
      this.setState({
        statusPopupPosition: currentPopupPosition, statusPopupArrow: currentPopupArrow, hover: false,
        bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow,
        currentKey: key,
        clickedDotPosition: key
      });
      originalPopupPosition = currentPopupPosition,
        originalPopupArrow = currentPopupArrow;

    } else if (e.type == "mouseleave") {
      this.setState({ bcPopupPosition: originalPopupPosition, bcPopupArrow: originalPopupArrow, hover: false, currentKey: this.state.clickedDotPosition });
    } else if (e.type == "mouseover") {
      this.setState({ bcPopupPosition: currentPopupPosition, bcPopupArrow: currentPopupArrow, hover: true, currentKey: key });
    }
  }

  getStatus(currentService) {
    console.log("EEEE = ",currentService)
    let inprogressServicesCount, pendingServicesCount, completedServicesCount = 0;
    inprogressServicesCount = findIndex(currentService, (service) => {
      return service.worklog && service.worklog.length && service.worklog[service.worklog.length - 1] && service.worklog[service.worklog.length - 1].jobstatus == 'In Progress';
    });
    pendingServicesCount = findIndex(currentService, (service) => {
      return service.worklog && service.worklog.length && service.worklog[service.worklog.length - 1] && service.worklog[service.worklog.length - 1].jobstatus == 'Pending';
    });
    completedServicesCount = findIndex(currentService, (service) => {
      return service.worklog && service.worklog.length && service.worklog[service.worklog.length - 1] && service.worklog[service.worklog.length - 1].jobstatus == 'Completed';
    });
    let status = '';
    if (inprogressServicesCount != -1) {
      status = 'inprogress';
    }
    if (!status && pendingServicesCount != -1) {
      status = 'pending';
    }
    if (!status && completedServicesCount != -1) {
      status = 'completed';
    }
    return status;
  }

  render() {
    const style = {
      dotsWidth: {
        marginLeft: this.state.margin
      }, popupPos: {
        left: this.state.hover ? this.state.bcPopupPosition : this.state.statusPopupPosition
      }, popupArrow: {
        left: this.state.hover ? this.state.bcPopupArrow : this.state.statusPopupArrow
      }, arrowDown: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #e0e0e0',
        position: 'absolute',
        top: '98px',
      }, arrowLeftDown: {
        width: 0,
        height: 0,
        borderLeft: '1px solid transparent',
        borderRight: '14px solid transparent',
        borderTop: '15px solid #ddd',
        position: 'absolute',
        top: '98px',
        zIndex: '999',
        transform: 'rotate(0deg)',
      }, arrPos: this.state.hover ?
        { left: this.state.bcPopupArrow + 38 } : this.state.statusPopupArrow < 0 ? { right: 0, left: 34 } : { left: this.state.statusPopupArrow + 38 }
    };
    const { statusCount, groupedJobStatus, allServices, requestStatus, jobStartDate, jobEndDatetime } = this.props;
  
    let statusArray = [];
    let firstServiceStatus = '';
    let lastServiceStatus = '';
    let worklogs = [];
    let allWorklogs = [];
    const stepCount = map(range(statusCount), (stepVal, parentKey) => {
     
      
      let jobProgress = values(groupedJobStatus);
      
      let currentService = jobProgress[parentKey];

      
      let status = this.getStatus(currentService);
     
      statusArray.push(status);
      
      map(currentService, (subService, key) => {
        let jobStatus = subService.worklog && subService.worklog.length && subService.worklog[subService.worklog.length - 1] && subService.worklog[subService.worklog.length - 1].jobstatus;
        currentService[key].serviceStatus = jobStatus;
        let currentkey = this.state.currentKey != -10 ? this.state.currentKey == 0 ? this.state.currentKey : this.state.currentKey - 1 : groupedJobStatus.length - 1;
       
        if (currentkey == parentKey) {
          worklogs = concat(worklogs, subService.worklog);
        }
        allWorklogs = concat(allWorklogs, subService.worklog);
      });

      return (
        <span className={this.state.currentKey == parentKey + 1 ? "dots active " + status + ' ' + parentKey + 1 : "dots " + status + ' ' + parentKey + 1} style={style.dotsWidth} key={parentKey + 1}
          id={`dot-position-${parentKey + 1}`}
          onMouseOver={(e) => { this.stepClick(e, parentKey + 1, 'hover'); }}
          onClick={(e) => { this.stepClick(e, parentKey + 1, 'click'); }}
          onMouseLeave={(e) => { this.stepClick(e, parentKey + 1); }} />
      );
    });
   
    let testWorklogs = worklogs;
    if (requestStatus != 'Accepted') {
      worklogs = filter(worklogs, (log) => {
        return log.jobstatus == "Completed" || log.jobstatus == "In Progress";
      });
    }
    if (!worklogs.length) {
      worklogs = filter(testWorklogs, (log) => {
        return log.jobstatus == "Pending";
      });
    }
    // if (requestStatus == 'inprogress') {
    //   worklogs = filter(worklogs, (log) => {
    //     return log.jobstatus == "In Progress";
    //   });
    // }

    let jobStartTime = new Date(jobStartDate);
    if (requestStatus == 'inprogress' || requestStatus == 'Completed' || requestStatus == 'completed') {
      let inProgressWorklogs = filter(allWorklogs, (log) => {
        return log.jobstatus == "In Progress";
      });
      if (!inProgressWorklogs.length) {
        inProgressWorklogs = filter(allWorklogs, (log) => {
          return log.jobstatus == "Completed";
        });
      }
      inProgressWorklogs = inProgressWorklogs && inProgressWorklogs.length && sortBy(inProgressWorklogs, (obj) => { return obj && obj.createdAt; });
      jobStartTime = inProgressWorklogs.length && inProgressWorklogs[0] && new Date(inProgressWorklogs[0].createdAt);
    }

    worklogs = worklogs && worklogs.length && sortBy(worklogs, (obj) => { return obj && -obj.createdAt; });
    let currentWorklogIndex = worklogs && worklogs.length && worklogs[0] && findIndex(allServices, { id: worklogs[0].refId });
    let currentService = allServices[currentWorklogIndex != -1 ? currentWorklogIndex : 0];

    // currentService.parentId
    let updatedAt = (this.state.currentKey == 0 || this.state.currentKey == -10 || (this.state.currentKey == this.props.statusCount + 1)) ? ((this.state.currentKey == -10 || (this.state.currentKey == this.props.statusCount + 1)) && requestStatus == 'Completed') ? new Date(jobEndDatetime) : jobStartTime : worklogs.length && worklogs[0] && new Date(worklogs[0].createdAt);
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    updatedAt = updatedAt && updatedAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' on ' + months[updatedAt.getMonth()] + ' ' + updatedAt.getDate() + ' ' + updatedAt.getFullYear();
    const popUp = this.serviceProgressPopUp(currentService && currentService.parentId != 1 ? currentService.parent.name : currentService && currentService.serviceName ? currentService.serviceName : "", updatedAt, statusArray[this.state.currentKey != -10 && (this.state.currentKey != this.props.statusCount + 1) ? this.state.currentKey - 1 : statusArray.length - 1], currentService && currentService.serviceImage ? currentService.serviceImage : currentService && currentService.parent && currentService.parent.image ? currentService.parent.image : '', requestStatus);
    firstServiceStatus = (requestStatus == 'inprogress' || requestStatus == 'Completed') ? 'completed' : '';
    lastServiceStatus = (requestStatus == 'Completed') ? 'completed' : '';
    return (
      <div>
        {popUp}
        <div className={this.state.statusPopupArrow ? "arrow-down" : "arrow-down hide"} id={this.state.currentKey == 0 ? "n-arrow-left" : this.state.currentKey == -10 ? "n-arrow-right" : "n-arrow-middle"} style={merge({}, this.state.currentKey != 0 && this.state.currentKey != -10 ? style.arrowDown : style.arrowLeftDown, style.arrPos)}></div>
        <div className="custom-dots" id="custom-dots">
          <div className={requestStatus == 'Completed' ? "main-line completed" : "main-line"} />
          <div>
            <span id={`dot-position-0`} className={this.state.currentKey == 0 ? "dots active first " + firstServiceStatus : "dots first " + firstServiceStatus}
              onMouseOver={(e) => { this.stepClick(e, 0, 'hover'); }}
              onClick={(e) => { this.stepClick(e, 0, 'click'); }}
              onMouseLeave={(e) => { this.stepClick(e, 0); }}
            />
            {stepCount}
            <span id={`dot-position-${statusCount + 1}`} className={this.state.currentKey == -10 ? "dots active last " + lastServiceStatus : "dots last " + lastServiceStatus}
              onMouseOver={(e) => { this.stepClick(e, -10, 'hover'); }}
              onClick={(e) => { this.stepClick(e, -10, 'click'); }}
              onMouseLeave={(e) => { this.stepClick(e, -10); }}
            />
          </div>
        </div>
        <WorkLogModal {...this.props} showModal={this.state.worklogVisible} hideModal={() => { this.setState({ worklogVisible: false }) }} />

      </div>
    );
  }
}

export default StatusBar;
