import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import LocationSearchBox from '../common/LocationSearchBox';
import Gmaps from '../MyRequest/Gmaps';
import Button from '../common/Button';
import { imageBasePath } from '../../constants/staticData';

class Panel3 extends Component {
  constructor(props) {
    super(props);
    let momentdate = moment();
    let momentPicker = new Date(momentdate._d);
    this.state = {
      open: false,
      accidentRadioBtn: true,
      generalRadioBtn: false,
      jobDetails: '',
      selectedDate: moment().add(1, 'hours'),
      now: moment().add(1, 'hours'),
      hoursValue: moment().add(1, 'hours').hours(),
      minutesValue: moment().minutes(),
      dateValue: momentPicker.getDate(),
      monthValue: momentPicker.getMonth(),
      yearValue: momentPicker.getFullYear(),
      checked: false,
      selectedLocation: '',
      formValidation: false,
    };
    this.errors = {
      selectedLocation: false,
      timeValid: false
    };
    this.formData = {
      selectedLocation: '',
      timeValid: false
     // timeValid: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.requestDetails = this.requestDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tabOpen == "panel3") {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  }

  jobDetails(data) {
    if (data.target.value.length) {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
    else {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,

    });
    if (moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()) {
      if (this.state.selectedTimeValue < moment().add(1, 'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });

      } else {
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
    this.timePicker(date);

  }

  timePicker(value) {
    if (value) {
     // if (moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue) {
        if (value.startOf('minutes') < moment().add(1, 'hours').startOf('minutes')) {

          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              now: value,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        } else {
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              now: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        }
      /*} else {
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            now: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
      }*/
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  requestDetails() {

    //to validate time
    this.timePicker(this.state.now);


    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key] || (key == 'selectedLocation' && !(formData.selectedLocation.formatted_address || formData.selectedLocation.label))) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
    } else {
      let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
      let momentTimeStamp = moment.utc(timeStamp).format();
      let requestDetails = {
        "repairreason": this.state.accidentRadioBtn ? "accident" : "general",
        "isurgentrequest": this.state.checked,
        "location": this.state.selectedLocation.formatted_address ? this.state.selectedLocation.formatted_address : this.state.selectedLocation.label,
        "latitude": this.state.selectedLocation.lat.toString(),
        "longitude": this.state.selectedLocation.lng.toString(),
        "userpreffereddatetime": momentTimeStamp,
        "jobDetails": this.state.jobDetails,
      };
      if (requestDetails) {
        this.props && this.props.getRequestDetails(requestDetails);
        this.props.toggleNextButton('panel4', true);
        this.props && this.props.panel3Valid();
        this.setState({
          formValidation: true
        });
      }
    }
  }

  render() {
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];
    return (
      <div className="panel3">
        <Panel collapsible expanded={this.state.open} header={
          <div className="panel-section p-panel" onClick={() => { this.props.selectedTab("panel3", this.props.panel2Valid); }}>
            <div className="panel-head" >
              <span className={this.props.tabOpen == 'panel4' ? "n-step active" : "n-step"}>3</span>
              <h4>Additional Details</h4>
              <i className={this.state.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
            </div>
          </div>
        }>
          <div className="panel-content collapse-panel">

            {this.props.activeRequestType == "repair" ? <div className="form-section">
              <h4 className="panel-sub-title">Reason For Repair</h4>
              <div className="radio-btn">
                <div className="radio-style">
                  <label>
                    <input type="radio" name="radio" checked={this.state.accidentRadioBtn} onChange={() => this.setState({ accidentRadioBtn: true, generalRadioBtn: false })} />
                    <i className="mf-radio-button" /><span>Accident</span>
                  </label>
                </div>
                <div className="radio-style">
                  <label>
                    <input type="radio" name="radio"  checked={this.state.generalRadioBtn} onChange={() => this.setState({ accidentRadioBtn: false, generalRadioBtn: true })} />
                    <i className="mf-radio-button" /><span>General</span>
                  </label>
                </div>
              </div>
            </div> : <div />}
            <div className="form-section">
              <h4 className="panel-sub-title">Add Notes</h4>
              <div className="model-select">
                <input type="text"  id="notes"  value={this.state.jobDetails} className="jd-text" placeholder="Additional information you can provide" onChange={(e) => { this.jobDetails(e); }} />
              </div>
            </div>
            <div className="form-section">
              <h4 className="panel-sub-title">Prefered Time & Date</h4>
              {<div className="toggleBtn">
                <span> This is an urgent request ! </span>
                <ToggleSwitch
                  checked={this.state.checked}
                  size="small"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      selectedDate: this.state.checked ? this.state.selectedDate : moment(),
                      submissionError: false,
                      selectedTimeValue: this.state.checked ? this.state.selectedDate : moment().add(1, 'hours'),
                      now: this.state.checked ? this.state.now : moment().add(1, 'hours'),
                      checked: !this.state.checked,
                    });
                  }}
                />
              </div>}
              {!this.state.checked && <div className="row date-time">
                <div className="col-md-6 padLeft0 timeDate_grid">
                  <DatePicker
                    selected={this.state.selectedDate}
                    onChange={this.handleChange}
                    minDate={moment().add(1, 'hours')}
                  />
                </div>
                <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                  <TimePicker
                    showSecond={false}
                    className="custom-timepicker"
                    format={'h:mm a'}
                    onChange={this.timePicker.bind(this)}
                    value={this.state.now}
                    use12Hours
                  />
                  {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                </div>
              </div>}
            </div>
            <div className="form-section">
              <h4 className="panel-sub-title">Prefered location</h4>
              <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                <div className="location-dd header-search ws-form-section">
                  <LocationSearchBox fromGuestFlow={true} style={style} placeholder="Select Location" commonReducer={this.props.commonReducer} actions={this.props.actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined} />
                </div>
                {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
              </div>
              {this.state.selectedLocation && <div className="map-panel">
                <div className="gmaps">
                  <Gmaps
                    center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                    zoom={12}
                    setCenter={true}
                    markers={markerLocation}
                    containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                    mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                  />
                </div>
              </div>}
            </div>
            {/* {this.state.activeRequestType == "repair" ? <div className="form-section uploads">
                            <div className="row">
                                <h4 className="panel-sub-title">upload a image (Optional)</h4>
                                <div className="model-select upload">
                                     <Upload id="uploadImage"  />
                                </div>
                            </div>
                        </div>:<div/>} */}
            <div className="next-button">
              <Button dataLabelledby="Additional Details Next" btnType="red" btnSize="sm" fontSize={13} label="Next" btnCallBack={() => { this.requestDetails(); }} />
            </div>
          </div>
        </Panel>

      </div>
    );
  }
}

export default Panel3;
