import React, { Component } from 'react';
import { MenuItem } from 'react-bootstrap';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import ToastMessage from '../common/ToastMessage';
import QuickHeader from '../common/QuickHeader';
import Footer from '../Landing/Footer';

import { Link } from 'react-scroll';
import { imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';
import filter  from 'lodash/filter';
import map  from 'lodash/map';
import has  from 'lodash/has';
import range  from 'lodash/range';
import findIndex  from 'lodash/findIndex';
import TextInput from '../common/TextInput';
import Button from '../common/Button';
import { validationMessage } from '../../constants/staticData';
var uniqid = require('uniqid');
import { validateFormSubmit } from '../../helpers';
import CustomScroll from 'react-custom-scroll';
import Review from "../common/Review";
import moment from "moment";
import queryString from "query-string";

class AllOfferBooking extends Component {
  constructor(props) {
    super(props);

    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });

    const urlData = queryString.parse(this.props.location.search);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : {};
    this.state = {
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : "",

      hasMultipleSubCategories: false,
      yearListDD: false,
      showSubCategory: false,
      hideForm: false,
      hideForm1: true,
      hideForm2: true,
      hideForm3: true,
      open: false,
      isButtonLoading: false,
      "selectedYear": "Year",
      "subServiceName": "Select Service",
      "allYears": years,
      "allYearsList": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      "serviceListDD": false,
      "selectedService": this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData.services[0].parentId == 1 ? 'Car Wash and Detailing': this.props.location.state.selectedPromotionalOffersData.services[0].parentId == 2 ? 'Car Servicing': 'Car Repair':"Select Service":"Select Service",
      "offer": this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData:"":"",
      "allService": [],
      allFilteredCarModelList: [],
      activeRequestType: "",
      activeRequestTypeLabel: "",
      selectedCarMakeKey: '',
      isMakeandModelLoaded: false,
      selectedPromotionalOffersData: this.props.params.id?  this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData?this.props.location.state.selectedPromotionalOffersData:[]:[]
    };


    this.initialFormData = {
      "name": '',
      "email": '',
      "phone": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "car_details": '',
      "category": '',
      "serviceid":  this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData.services[0].parentId == "1" || this.props.location.state.selectedPromotionalOffersData.services[0].parentId == "2" ? this.props.location.state.selectedPromotionalOffersData.services[0].parentId:3 :"":"",
      "terms": true,
    };



    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      "name": false,
      "email": false,
      "phone": false,
      "make": false,
      "model": false,
      "model_id": false,
      "year": false,
      "plate_no": false,
      "car_details": false,
      "category": false,
      "serviceid":false,
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.chooseCarDetails = this.chooseCarDetails.bind(this);
    this.chooseSubCategory = this.chooseSubCategory.bind(this);
  }
  componentWillMount() {


    window.scrollTo(0,0);
    const urlData = queryString.parse(this.props.location.search);

    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    const userId = localStorage.getItem("userId");
    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      this.props.router.push(`${appBasePath}/dashboard-plus`);
    }

    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    // if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
    this.props.actions.getManufacturers();
    //}



 //   this.props.actions.getAllPromotionalOffers();

    if(this.props.params.id)
      this.props.actions.getSingleOffer(this.props.params.id);



  }
  componentWillReceiveProps(nextProps) {



    const urlData = queryString.parse(this.props.location.search);

    if (nextProps.tabOpen == "panel4") {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ 'isButtonLoading': false });
    }

    if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.getAllServices) {
      let quickBookingServices = [];
      nextProps.bookingRequestReducer.getAllServices.forEach((service) => {
        if (service.name !== "Emergency Service") {
          quickBookingServices.push(service);
        }
      });
      this.setState({
        allService: quickBookingServices,
      });

    }

    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;

      this.setState({
        allManufacturer: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        filteredCarModelList: carModel,
        allFilteredCarModelList: carModel,
      });
    }


    if(this.props.params.id){

      let promotionalOffersData = nextProps.commonReducer && nextProps.commonReducer.singleOffersData ? nextProps.commonReducer.singleOffersData : "";

      this.setState({
        selectedPromotionalOffersData: promotionalOffersData?promotionalOffersData:"",
        selectedPromotionalOffersServiceData: promotionalOffersData?promotionalOffersData.services:"",
        hasMultipleSubCategories: promotionalOffersData && promotionalOffersData.services.length > 1 ? true : false,
      //  subServiceName: promotionalOffersData && promotionalOffersData.services && promotionalOffersData.services.length == 1 ? promotionalOffersData.services[0].name : "Select Service",
        //"selectedService": promotionalOffersData ? promotionalOffersData.services[0].parentId == 1 ? 'Car Wash and Detailing': promotionalOffersData.services[0].parentId == 2 ? 'Car Servicing': 'Car Repair':"Select Service",
        "offer": promotionalOffersData?promotionalOffersData :"",


      });


      if(promotionalOffersData && promotionalOffersData.services.length < 2){

        this.formData["category"] =  promotionalOffersData.services[0].serviceId;
        this.errors["category"] = false;

        this.setState({
          subServiceId: promotionalOffersData.services[0].serviceId,
          subServiceName: promotionalOffersData.services[0].name
        });

      }




    }



  }


  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,

    });
  }
  chooseSubCategory() {

    if(this.state.hasMultipleSubCategories){
      this.setState({
        showSubCategory:true,
        hideForm:true,
      });
    }

  }

  // dropdownOpen(val) {
  //   if (val == 'makeDropdown') {
  //     this.formData['year'] = '';
  //     this.formData['model'] = '';
  //     this.formData['make'] = '';
  //     this.setState({ makeDropdown: true, modelDropdown: false, yearDropdown: false });
  //   } else if (val == 'modelDropdown') {
  //     this.formData['year'] = '';
  //     this.formData['model'] = '';
  //     this.setState({ makeDropdown: false, modelDropdown: true, yearDropdown: false });
  //   } else if (val == 'yearDropdown') {
  //     this.formData['year'] = '';
  //     this.setState({ makeDropdown: false, modelDropdown: false, yearDropdown: true });
  //   }
  // }

  onSubmit(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      console.log(formData);
      console.log(validatedForm.validForm);
      console.log(validatedForm);
      console.log(this.errors);

      this.setState({ submissionError: true });
      return;
    } else {

      this.setState({ submissionError: false, isButtonLoading: true });
      let phone_no = this.formData.phone;
      let payload = {
        "batch_id": uniqid(),
        "name": this.formData.name,
        "email": this.formData.email,
        "phone": '+971' + phone_no,
        "make": this.formData.make,
        "model": this.formData.model,
        "model_id": this.formData.model_id,
        "year": this.formData.year,
        "plate_no": this.formData.plate_no,
        "serviceid": this.formData.serviceid,
        "flow": "quickbooking",
        "provider": "normal",
        "subtype": "all offers",
      };
      if (this.props && this.props.activeRequestType == "repair") {
        payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
          payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
      }



      let promoCategories = [];

      this.state.selectedPromotionalOffersData && this.state.selectedPromotionalOffersData.services && this.state.selectedPromotionalOffersData.services.length && this.state.selectedPromotionalOffersData.services.forEach((promoCategory) => {

        promoCategories.push(promoCategory.serviceId);

      });


      if(this.formData.serviceid == 1){
        //Car Wash
        localStorage.setItem('selectedCarWashDataArray',JSON.stringify(promoCategories));

      }else if(this.formData.serviceid == 2){


         //localStorage.setItem('selectedServiceId', promoCategories.length && promoCategories[0].serviceId);
        localStorage.setItem('selectedServiceId', this.formData.category);
        localStorage.setItem('selectedServiceName', this.state.subServiceName);

        localStorage.setItem('selectedService',JSON.stringify(promoCategories));


      }else{
        localStorage.setItem('selectedCarRepairDataArray',JSON.stringify(promoCategories));

      }

      localStorage.setItem('selectedPromoCode',this.state.selectedPromotionalOffersData.promoCode);
      localStorage.setItem('selectedPromoCodeId',this.state.selectedPromotionalOffersData.id);




      this.props.actions.quickBookingUserSignUp(payload, router);
    }

  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  autoCompleteManufacturer(value){

    this.setState({ searchManufacturerTerm: value });

    let searchView = "";
    if(value){
      searchView = filter(this.state.allManufacturer, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allManufacturer;
    }

    this.setState({ 'filteredCarList': searchView });
  }
  autoCompleteServices(value){

    this.setState({ searchServiceTerm: value });

    let searchView = "";
    if(value){
      searchView = filter(this.state.selectedPromotionalOffersData.services, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.selectedPromotionalOffersData.services;
    }

    this.setState({ 'selectedPromotionalOffersServiceData': searchView });
  }
  autoCompleteModel(value){

    this.setState({ searchModelTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.filteredCarModelList, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allFilteredCarModelList;
    }


    this.setState({ 'filteredCarModelList': searchView });
  }

  autoCompleteYear(value){

    this.setState({ searchYearTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.allYears, val => {

        if (value.toString() != '' && val.toString().indexOf(value.toString()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allYearsList;
    }

    this.setState({ allYears: searchView });
  }

  render() {


    const tagManagerArgs = {
      dataLayer: {
        pageCategory: 'Quick and Easy Booking User Sign Up',
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);

    let baseUrl = location.origin;

    
    const carList = this.state.filteredCarList ? this.state.filteredCarList : this.state.allManufacturer;
    const servicesList = this.state.selectedPromotionalOffersServiceData?this.state.selectedPromotionalOffersServiceData:[];
    let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
    const carModelLoaded = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].isCarModelLoaded;

    const carManufacterView = map(carList, (carItem, key) => {
      return (
        <MenuItem

          key={key}
          onClick={() => {
            this.formData["make"] = carItem.name;
            this.props.actions.getCarModels(carItem.id);
            this.setState({
              selectedManufacturer: carItem.name,
              carModel: carItem.carmodel,
              selectedCarMakeKey: carItem.id,
              filteredCarModelList: carItem.carmodel,
              allFilteredCarModelList: carItem.carmodel
            });
            this.errors["make"] = false;
            this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });

    const carModalList = this.state.isMakeandModelLoaded && this.state.filteredCarModelList;

    const carModelView = this.state.isMakeandModelLoaded && map(this.state.filteredCarModelList, (carItem, key) => {
      return (
        <MenuItem
          key={key}
          onClick={() => {
            this.formData['model_id'] = carItem.id;
            this.formData['model'] = carItem.name;
            this.setState({ selectedModel: carItem.name });
            this.setState({ 'modelListDD': !this.state.modelListDD });
            this.errors["model_id"] = false;
            this.errors["model"] = false;
          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });
    const { router, actions, commonReducer } = this.props;

    let days = "";

    if(this.state.offer){
      days = this.state.offer && moment(this.state.offer.validTo).diff(moment(new Date()),'days');

    }
    const urlData = queryString.parse(this.props.location.search);


    return (
      <div className="guf">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['quickBooking'] && metaTagData['quickBooking'].title ? metaTagData['quickBooking'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['quickBooking'] && metaTagData['quickBooking'].description ? metaTagData['quickBooking'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}

        <QuickHeader
          hideOption={() => this.setState({ searchMobile: true })}
          pageType="signIn"
          headerClickAction={e => {
            e.preventDefault();
            router.push(`${appBasePath}/sign-in`);
          }}
          metaTagKey={'quickBooking'}
        />

        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="quick-booking-section quick-booking-offer-section row offer-section">
          <div className="quick-banner-img">

            {this.state.offer ?
              <div>

                {this.state.offer.offerId == 12 ?


                  <span>
                     <img className="normal-bg "
                          src={`${imageBasePath}/images/offer-quick-booking-placeholder-12.jpg`}
                          alt="quick booking"/>
                  <img className={this.state.offer ? "offer-img responsive-bg": "quick-img responsive-bg" } src={`${imageBasePath}/images/offer-quick-booking-placeholder-12.jpg`}
                        alt="quick booking"/>
                  </span>

                  :

                  <span>
                     <img className="normal-bg "
                          src={this.state.offer.images && this.state.offer.images.length && this.state.offer.images[0].large ? this.state.offer.images[0].large : `${imageBasePath}/images/offer-placeholder.jpeg` }
                          alt="BMW car repair"/>
                  <img className={this.state.offer ? "offer-img responsive-bg": "quick-img responsive-bg" } src={this.state.offer.images && this.state.offer.images.length &&  this.state.offer.images[0].small ? this.state.offer.images[0].small : `${imageBasePath}/images/offer-placeholder.jpeg` }
                        alt="BMW car repair"/>
                  </span>
                }

                <div className="col-md-8 col-sm-8 col-xs-12 description-container">

                  {this.state.offer&&



                  <div className=" offer-detail-wrapper offer-banner ">
                    <div className="padwrapper offer-body">
                      {this.state.offer.discountPercentage ?
                        this.state.offer.discountPercentage == 100 ?
                          <div className="  free-price">

                            <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                            <div className="price-content">

                              <span className="amount">Free</span>
                            </div>
                          </div>
                          :
                          <div className=" price">

                            <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                            <div className="price-content">

                              <span className="amount">{this.state.offer.discountPercentage }%</span>
                              <span className="type">OFF</span>
                            </div>
                          </div>
                        :
                        <div className=" price">

                          <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                          <div className="price-content discount-amount-content">

                            <span className="discount-amount">{this.state.offer.discountAmount}</span>
                            <span className="discount-currency">AED </span>
                            <span className="discount-type">OFF</span>
                          </div>
                        </div>

                      }

                      <div className="title">{this.state.offer.offerName}</div>

                      <div className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon-white.png` }  /> {days} Days Left</div>
                      <div className="offer-promo-code">
                        Promo Code <span className="promo">{this.state.offer.promoCode}</span>
                      </div>
                       <div className="offer-promo-code-description">
                        {this.state.offer.description}
                      </div>


                      {this.state.offer.totalAmount ?
                        <div className="offer-price">

                          {this.state.offer.discountPercentage ?
                            this.state.offer.discountPercentage == 100 ?
                              <span className="amount">Free</span>
                              :
                              <span className="amount">{this.state.offer.discountedAmount} AED</span>
                            :
                            <span className="amount">{this.state.offer.discountedAmount} AED</span>
                          }
                          <span className="original">{this.state.offer.totalAmount} AED</span>
                        </div>
                        :
                        <div className="offer-price">
                        </div>
                      }



                    </div>
                  </div>
                  }

                </div>
              </div>
              :
              <div>


              </div>
            }


            <div id="quick-booking-section">




              <div className="quick-booking-form col-md-12 col-sm-12 col-xs-12">

                <div className={this.state.showSubCategory? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">Select Services
                    <span className="close" onClick={(e) => {
                      this.setState({
                        showSubCategory:false,
                        hideForm:false
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="services"
                        id="services"
                        className="form-control quick-search-input"
                        placeholder="Services"
                        value={this.state.searchServiceTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteServices(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(servicesList, (service, key) => {
                               return (
                                <li
                                  key={key}
                                  onClick={() => {


                                    this.formData["category"] =  service.serviceId;
                                    this.errors["category"] = false;

                                    this.setState({
                                      showSubCategory:false,
                                      hideForm:false,
                                      subServiceId: service.serviceId,
                                      subServiceName: service.name,
                                    });


                                  }}>
                                  <label>{service.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm && !this.state.hideForm1? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">Select Manufacturer
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="manufacturer"
                        id="manufacturer"
                        className="form-control quick-search-input"
                        placeholder="Manufacturer"
                        value={this.state.searchManufacturerTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteManufacturer(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(carList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.formData["make"] = carItem.name;
                                    this.props.actions.getCarModels(carItem.id);
                                    this.setState({
                                      hideForm1: true,
                                      hideForm2: false,
                                      selectedManufacturer: carItem.name,
                                      carModel: carItem.carmodel,
                                      selectedCarMakeKey: carItem.id,
                                      filteredCarModelList: carItem.carmodel
                                    });
                                    this.errors["make"] = false;
                                    this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && !this.state.hideForm2? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="model"
                        id="model"
                        className="form-control quick-search-input"
                        placeholder="Model"
                        value={this.state.searchModelTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteModel(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(carModalList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {

                                    this.formData['model_id'] = carItem.id;
                                    this.formData['model'] = carItem.name;
                                    this.setState({
                                      selectedModel: carItem.name,
                                      'modelListDD': !this.state.modelListDD,
                                      hideForm1: true,
                                      hideForm2: true,
                                      hideForm3: false,
                                    });
                                    this.errors["model_id"] = false;
                                    this.errors["model"] = false;


                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && this.state.hideForm2 && !this.state.hideForm3? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="year"
                        id="year"
                        className="form-control quick-search-input"
                        placeholder="Year"
                        value={this.state.searchYearTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteYear(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(this.state.allYears, (list, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.setState({ selectedYear: list,hideForm3:true,hideForm:false });
                                    this.formData["year"] = list.toString();
                                    this.setState({ 'yearListDD': !this.state.yearListDD });
                                    this.errors["year"] = false;
                                    this.errors["car_details"] = false;
                                    this.formData["car_details"] = "car_details";
                                  }}>
                                  <label>{list}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <form className={this.state.hideForm? "hide form": "form"}>
                  <div className="form-title">{this.props.params.id?"Book now & avail the Offer" : "Quick Booking" }</div>
                  <div className="row">
                    <div className="col-12">
                      <br />

                      <TextInput
                        customClass={ this.state.hasMultipleSubCategories? "" : "disabled" }
                        label={this.state.selectedService }
                        disabled={ this.state.hasMultipleSubCategories? false : true }
                        name="category"
                        id="category_no"
                        type="text"
                        value={this.state.selectedPromotionalOffersData && this.state.subServiceName}
                        onFocus={this.chooseSubCategory.bind(this)}
                        showValidationError={this.errors['category']}
                        emptyError={validationMessage.category.emptyError}
                        validationError={validationMessage.car_details.validationError}
                      />


                    </div>
                    <div className="col-12">

                      <div className="car-details-div">
                        <TextInput
                          label="Car Details.*"
                          name="car_details"
                          id="car_details"
                          type="text"
                          value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                          showValidationError={this.errors['car_details']}
                          emptyError={validationMessage.car_details.emptyError}
                          validationError={validationMessage.car_details.validationError}
                          onFocus={this.chooseCarDetails.bind(this)} />

                        <span className="close" onClick={(e) => {


                          this.setState({
                            selectedManufacturer:"Manufacturer",
                            selectedModel:"Model",
                            selectedYear:"Year"
                          });

                          this.formData["make"] = "";
                          this.formData['model_id'] = "";
                          this.formData['model'] = "";
                          this.formData["year"] = "";
                          this.formData["car_details"] = "";

                          this.errors["car_details"] = true;



                        }}><i className="mdi mdi-close"></i></span>

                      </div>

                    </div>

                    <div className="col-12">
                      <TextInput
                        label="Plate No.*"
                        name="plate_no"
                        id="plate_no"
                        type="text"
                        showValidationError={this.errors['plate_no']}
                        emptyError={validationMessage.platenumber.emptyError}
                        validationError={validationMessage.platenumber.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        label="Name"
                        name="name"
                        id="name"
                        type="text"
                        showValidationError={this.errors['plate_no']}
                        emptyError={validationMessage.name.emptyError}
                        validationError={validationMessage.name.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        type="email"
                        label="E-mail ID*"
                        name="email"
                        id="email"
                        showValidationError={this.errors['email']}
                        emptyError={validationMessage.email.emptyError}
                        validationError={validationMessage.email.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        type="phone"
                        label="Phone Number*"
                        placeholder="Mobile Number"
                        name="phone"
                        id="phone"
                        showValidationError={this.errors['phone']}
                        emptyError={validationMessage.phone.emptyError}
                        validationError={validationMessage.phone.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12 text-center quick-button">
                      <Button dataLabelledby="Quick Booking User SignUp"
                              btnType="red"
                              btnSize="sm"
                              fontSize={13}
                              label="Book Now"
                              onSumbit btnCallBack={this.onSubmit.bind(this)}
                              isButtonLoading={this.state.isButtonLoading} />
                    </div>
                    <div className="col-12 quick-terms">
                      <div className="checkbox-style checkbox-wrapper n-align">
                        <input
                          type="checkbox"
                          id="check-box"
                          onChange={e => {
                            this.setState({ terms: !e.target.checked });
                            this.formData.terms = e.target.checked;
                            this.errors.terms = !e.target.checked;
                          }}
                          checked={this.formData.terms}
                        />
                        <label htmlFor="check-box" className="custom-checkbox" />
                        <label htmlFor="" className="n-signup-checkbox">
                          By using Carcility, you agree to our
                          <a
                            onClick={() => {
                              // this.props.router.push(`${appBasePath}/terms`);
                              window.open(`${appBasePath}/terms`, "_blank");
                            }}
                            className="blue-text"
                          >
                            {" "}
                            Terms & Conditions
                          </a>, and{" "}
                          <a
                            onClick={() => {
                              // this.props.router.push(`${appBasePath}/privacy`);
                              window.open(`${appBasePath}/privacy`, "_blank");
                            }}
                            className="blue-text"
                          >
                            Privacy Policy
                          </a>.
                        </label>
                      </div>
                      <p
                        className={
                          this.errors.terms
                            ? "note-text tc-text error"
                            : "note-text tc-text hide"
                        }
                      >
                    <span className="error-text">
                      {"Please agree to the terms and condition"}
                    </span>
                      </p>


                    </div>

                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>

        {this.state.offer &&
        <div className="row quick-booking-dubai-review-container  quick-booking-offer-review">


          <Review
            router={router}
          />

        </div>

        }
        <Footer {...this.props} />
        {/* sidebar menu */}
        <div className={this.state.sidebarOpen ? "menu-sidebar active" : "menu-sidebar inactive"}>
          {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
          <div className="nonSignup-list">
            <ul className="list-unstyled">
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/sign-in'); }}>
                <span>
                  LOG IN
                    </span>
              </li>
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/register'); }}>
                <span>
                  SIGN UP
                    </span>
              </li>
              <li>
                <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                      </span>
                </Link>
              </li>
              <li onClick={(e) => { e.preventDefault(); window.location.pathname = '/business'; }}>
                <span>
                  FOR BUSINESS
                    </span>
              </li>
            </ul>
          </div>
        </div>
      </div>


    );
  }
}

export default AllOfferBooking;
