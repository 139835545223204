import React, { Component } from 'react';
import CustomModal from './CustomModal';
import { Modal } from 'react-bootstrap';
import each from 'lodash/each';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';


import Upload from '../common/Upload';
import Button from '../common/Button';
import { imageBasePath } from '../../constants/staticData';
import LightBox from '../common/LightBox';
var uniqid = require('uniqid');

export default class ChasisNumberPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chassis_number: '',
      uploadImageErrText: false,
      uploadImgSize: 0,
      imageUploaded: [],
      imageTotalCount:0,
      createRequestImageArray:[],
      imageStartIndex: 0,
      newImageIdArray:[],
      imageCountErrText: false,
      showModal: false,
      chassisImages: [],
      newImagesArray: []
    };

    this.cancelUploadImage = this.cancelUploadImage.bind(this);
    this.chassisFileUpload = this.chassisFileUpload.bind(this);
  }

  componentWillMount(){
    this.props.actions.clearImageArray();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false });
    } else if(nextProps && nextProps.showError){
      this.setState({isButtonLoading:false});
    } else
      if (
        nextProps.bookingRequestReducer &&
        nextProps.bookingRequestReducer.createRequestImageArray &&
        nextProps.bookingRequestReducer.createRequestImageArray.length &&
        nextProps.bookingRequestReducer.createRequestImageArray.length >=
        this.state.createRequestImageArray.length) {
      let imageUploaded = cloneDeep(this.state.imageUploaded);
      nextProps.bookingRequestReducer.createRequestImageArray.map((createRequestImageArray)=>{
        let findUploadImageIndex = findIndex(this.state.imageUploaded, { fileName: createRequestImageArray.fileName });
        if(findUploadImageIndex > -1){
          imageUploaded[findUploadImageIndex]['uploaded'] = true;
          imageUploaded[findUploadImageIndex]['id'] = createRequestImageArray.id;
        }
      });
      this.setState({
        ...this.state,
        createRequestImageArray: nextProps.bookingRequestReducer.createRequestImageArray,
        imageUploaded: imageUploaded,
      });
    }

    // Load ChassisNumber Images on Receiving Props,
    // Since the ChassisImage and CarImages are all part of the same image array
    // this is where the chassisImage is plucked and not on the car reducers
    /*
      if next props has the images of chassis Number put them
      as the default value of chassisImages in the state
    */
    if (nextProps && nextProps.selectedCarProfile &&
    nextProps.selectedCarProfile.images) {
    console.log('Start of Selected Car ---> ', this.state.chassisImages);
    let imageArray = [...this.state.chassisImages];
    const CSNimages = [];
    map(nextProps.selectedCarProfile.images, (img) => {
      if (img.small.indexOf('_chassisNumber_') > -1) {
        CSNimages.push({
          isUploaded: true,
          isFailed: false,
          id: img.id,
          thumbnail: img.small,
          original: img.original,
          large: img.large,
          isCover: false
        });
      }
    });
    if (
    nextProps.carProfileReducer &&
    nextProps.carProfileReducer.deletedImageId
    ) {
    remove(imageArray, function(f) {
      return f.id == nextProps.carProfileReducer.deletedImageId;
    });
    }
    this.setState({
    chassisImages: CSNimages
    });
    }

    // check if new CSN numbers are uploaded here
    if (nextProps && nextProps.carProfileReducer &&
      nextProps.carProfileReducer.newCarProfileData
      && nextProps.carProfileReducer.newCarProfileData.CSNimages
      && nextProps.carProfileReducer.newCarProfileData.CSNimages.length
      && (nextProps.carProfileReducer.newCarProfileData.batch_id === nextProps.selectedCarProfile.batchId))
    {
    const CSNimages = [...nextProps.carProfileReducer.newCarProfileData.CSNimages];
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(CSNimages, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        chassisImages: CSNimages
      });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  uploadImageError(value) {
    this.props.actions.bookingUploadImage('',value,this.state.batchID, false);
  }

  uploadFileError(type) {
    this.props.actions.bookingUploadFile('', '', this.state.batchID, type, "createrequest", false);
  }

  // The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number
  chassisFileUpload(e) {
    console.log('What are the files here target ', e.target.files);
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      console.log('SETTING this to ERROR in Service');
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      console.log('SETTING this to no ERROR in Service');
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    console.log('What is the props is here =---> ',this.props);
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.selectedCarProfile.batchId,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  // console.log("What ssssss ", that.props.carProfileReducer);
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.selectedCarProfile.batchId,
                    imageId,
                    true,
                    'CSN'
                  );
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError2(value),
          that.props.selectedCarProfile && that.props.selectedCarProfile.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });
  }

  cancelUploadImage(fromAction,index,id) {
    let deleteSize = 0;
    let createRequestImageArray = cloneDeep(this.state.createRequestImageArray);
    if (this.state.uploadImgSize >= 20000000) {
      this.setState({ uploadImageErrText: true });
    } else {
      this.setState({ uploadImageErrText: false });
    }
    this.props.actions.deleteUploadedImages(id,"createrequest");
    const array = this.state.imageUploaded;
    deleteSize = this.state.uploadImgSize - this.state.imageUploaded[index].size;
    array.splice(index, 1);
    let removeImage = remove(createRequestImageArray, (n) => {
      return n.id != id;
    });
    this.setState({
      imageUploaded: array,
      uploadImgSize: deleteSize,
      createRequestImageArray:removeImage,
      imageTotalCount:this.state.imageTotalCount-1,
    });
  }

  chassisChange(e) {
    this.setState({
      chassis_number: e.target.value
    });
  }
  imageThumbnailsChassis(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          {img.isUploaded ? <span className="cancel-image" onClick={(e) => {
            e.preventDefault();
            this.props.actions.deleteVehicleImage(index, img.id, imageArray, this.props.selectedCarProfile.id);
          }}>
            <i className="mdi mdi-close" />
          </span> : ''}
          <img src={img.isFailed ? `${imageBasePath}/images/warning.png` : img.thumbnail} onClick={() => {
            this.setState({ showModal: true, imageStartIndex: index });
          }} />
          {!img.isUploaded ? <div><img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" /></div> : ''}
        </div>
      );
    });
  }

  render() {

    //const imageUploadedView = this.imageThumbnails(this.state.carImages ? this.state.carImages : [], );
    const CSNUploadView = this.imageThumbnailsChassis(
      this.state.chassisImages ? this.state.chassisImages : []
    );

    return (
      <div>

      <CustomModal
        showModal={this.props.modalStatus}
        title="add chassis number"
        className="bookServiceRepair-modal"
        closeIcon={false}
        hideModal={() => this.props.hideModalCallback()}>
        <Modal.Body>
            <ul>
              <li>Chassis Number is required to provide you with the exact quote</li>
              <li>
                <div className="form-section">
                  <div className="model-select">
                    <input
                      value={this.state.jobDetails}
                      type="text" className="jd-text"
                      placeholder="Chassis Number"
                      onChange={
                        (e) => { this.chassisChange(e); }
                        }
                    />
                  </div>
                  <div className="underlinetext">
                    The chassis number can be found on the Vehicle Registration Certificate (VRC)
                  </div>
                </div>
                <div className="or-text">
                  <span>OR</span>
                      </div>
              </li>
              <li className="noPaddingPanel">
                <div className="row">
                  <h4 className="panel-sub-title">Upload Image</h4>
                  <div className="model-select upload">
                    <Upload
                      id="chassisUpload"
                      fileUpload={
                        (e) => {
                          this.chassisFileUpload(e);
                        }}
                    />
                    {CSNUploadView}
                    {/*this.state.createRequestImageArray && this.state.createRequestImageArray.length > 0 &&  createRequestImage*/}
                  </div>
                  <span className={this.state.uploadImageErrText ? "image-upload-error" : "image-upload-error hide"}>
                    <p>Sorry, your image format is wrong or image size exceeds the limit of 20mb. Try again with another image</p>
                    <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageErrText: false })} />
                  </span>
                  <div className="underlinetext">
                    The file has to be below 25MB. Please use JPG or PNG
                  </div>
                </div>
              </li>
              <li>
                <div className="btn-shiftright">
                  <Button btnType="transparent" btnSize="sm" fontSize={15} label="Cancel" btnCallBack={() => { this.props.hideModalCallback(); }} />
                  <Button
                    btnType="red"
                    btnSize="sm"
                    fontSize={14}
                    label="Submit"
                    btnCallBack={
                      () => this.props.submitCallback(this.state.chassis_number, this.state.newImageIdArray)
                    }
                    isButtonLoading={this.state.isButtonLoading} />
                </div>
              </li>
            </ul>
        </Modal.Body>
      </CustomModal>
      {this.state.imageStartIndex > -1 ?
          <LightBox
            showUploadIcon={this.state.imageUploaded.length < 5}
            actions={this.props.actions}
            images={this.state.imageUploaded}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.chassisFileUpload}
            cancelUploadImage = {this.cancelUploadImage}
            fromCreateRequest = {"uploadImage"}
            reportId ={"fromImage"}
          />
          : ''}
      </div>
    );
  }
}
