import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const initialState = {
  notificationRequests: []
};
export default function useraccountReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATION_INFO:
      return (assign({}, state, {
        notificationRequests: action.notificationRequests
      }));
    default:
      return state;
  }
}
