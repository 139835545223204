import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../actions/';

export default function UseraccountContainer(ComposedComponent) {
  class UseraccountContainer extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (<ComposedComponent {...this.props} />);
    }
  }

  function mapStateToProps(state) {
    return { useraccountReducer: state.useraccountReducer, authReducer: state.authReducer, commonReducer: state.commonReducer };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ActionCreators, dispatch)
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(UseraccountContainer);
}

UseraccountContainer.contextTypes = {
  router: React.PropTypes.object
};
