import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath } from '../../constants/staticData';
import Arrow from "./Arrow";

export default class Review extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentSlide:1
    }

  }
  componentDidMount() { setTimeout(() => {window.dispatchEvent(new Event('resize')) }, 6000); }





  render() {


    const { promotionalOffersData, router } = this.props;

    console.log("router",router);

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      responsive: [
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1400,
          settings: { slidesToShow: 3 }
        },
      ],
      afterChange: (prev, next) => {

        this.setState({ currentSlide: prev+1 });
      },
    };
    const reviews = [
      {
        user:"Ginto George",
        text: "Carcility has helped me quickly to find the most appropriate workshop for my car's major service at the best rates which are almost 70% cheaper then the dealers.",
        url: "review-ginto-george.png"
      },  {
        user:" Christina John",
        text: "Carcility is a great platform for all car services. I was very happy with the services provided. Very prompt responses and great customer service.",
        icon: "C"
      },  {
        user:"Anjali",
        text: "Really like their service! I got my car serviced through them and got multiple quotations pretty quick. Thank you Carcility, keep up the good work.",
        url : "review-anjali.png"
      },  {
        user:"Nadeen Nasr",
        text: "It's perfect. So I got about 4 or 5 quotes and they came in so quick wow, like you can even chat with the garages if you have quick queries.he process to book a service is so easy.",
        icon: "N"
      },  {
        user:"Michael Moffat",
        text: "In short Carcility saved me AED 5,750 with zero hassle!!!\n" +
          "All in all I was able to secure comparable or better tyres (Michelin, current year), fitting and a new battery from the same supplier as the original manufacturer for a fraction of the cost (AED 2,750) total.\n" +
          "\n" +
          "Carcility service were attentive and available throughout the process.",
        icon: "M"
      },{
        user:"Alan Davis",
        text: "A must have app for all those who own a car in UAE. It is great to have a couple of quotes for the required job from trusted garages across the city.",
        icon: "A"
      },
    ];

/*


    let promoCodesHtml =  promotionalOffersData.map((promoCode, index) => {
      return (
        <div className="col-md-4" key={index}  onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()}  >
          <div className="deal-img">
            <img src={  promoCode.images && promoCode.images[0] ?  promoCode.images[0].medium : `${imageBasePath}/images/default-offer-image.png` } alt="" />
          </div>
        </div>
      );
    });

*/


    let reviewHtml =  reviews.map((review, index) => {
      return (
        <div className={this.state.currentSlide == index?"review-active":"" } key={index} >
          <div  className="review-box">
            {review.url ?
              <img src={`${imageBasePath}/images/quick-booking/` + review.url} alt=""/>
              :
              <span className="icon-alphabet">{review.icon}</span>
            }

            <h2>{review.user}</h2>
            <ul>
              <li><img src={`${imageBasePath}/images/quick-booking/review-star-active.png`} alt="" /></li>
              <li><img src={`${imageBasePath}/images/quick-booking/review-star-active.png`} alt="" /></li>
              <li><img src={`${imageBasePath}/images/quick-booking/review-star-active.png`} alt="" /></li>
              <li><img src={`${imageBasePath}/images/quick-booking/review-star-active.png`} alt="" /></li>
              <li><img src={`${imageBasePath}/images/quick-booking/review-star-active.png`} alt="" /></li>

            </ul>
            <div className="review-description">{review.text}</div>
          </div>

        </div>
      );
    });



    return (
        <div className="deals-section review-section " id="deals-section">
          <div className="review-dashboard-heading">
            <h5>Hear from our Customers</h5>
          </div>
          <div className="col-md-12 Deals-layout dashboard-row">
            {reviewHtml ?
              <Slider ref="slick" {...settings} >
                {reviewHtml}
              </Slider>
            :""}
          </div>
        </div>
    );
  }
}
