import React, { Component } from 'react';
import { DropdownButton, MenuItem, Media } from 'react-bootstrap';
import each  from 'lodash/each';
import map  from 'lodash/map';
import sumBy  from 'lodash/sumBy';
import indexOf  from 'lodash/indexOf';
import range  from 'lodash/range';
import filter  from 'lodash/filter';
import moment from 'moment';
import PageLoader from '../common/PageLoader';
import NotificationEmptyState from '../EmptyState/NotificationEmptyState';
import { appBasePath } from '../../constants/staticData';

export default class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationBar: false,
      isNotificationsLoaded: false
    };
  }
  componentWillMount() {
    if ((!this.props.commonReducer.isNotificationsLoaded) && window.location.pathname != '/notification') {
      this.props.actions.getNotificationAlerts(0, 5);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.isNotificationsLoaded && !this.state.isNotificationsLoaded) {
      this.setState({
        'isNotificationsLoaded': true
      });
    }
  }
  render() {
    const { commonReducer } = this.props;
    let unReadNotifications = 0;
    const notifications = filter(commonReducer.notifications, (notification) => { return !notification.isRead })
    let notificationsData = [];
    if (notifications && notifications.length > 5) {
      each(range(0, 5), (index) => {
        notificationsData.push(notifications[index]);
      });
    } else {
      notificationsData = notifications;
    }
    const currentTimeStamp = moment(new Date());
    const notificationsView = map(notificationsData, (message, key) => {
      let quoteAmount = 0;
      if (message.services && message.services.length > 0) {
        quoteAmount = sumBy(message.services, 'amount');
      }
      let messageRead = false;
      if (indexOf(commonReducer.markedNotifications, message.alertId ? message.alertId : message.data.alertId) > -1) {
        messageRead = true;
      } else {
        unReadNotifications += 1;
      }
      const notificationTimeStamp = moment(message.created_at).format();
      let timeStampString = '';
      const timeDuration = moment.duration(currentTimeStamp.diff(notificationTimeStamp));
      if (timeDuration.asSeconds() < 60) {
        const seconds = Math.round(timeDuration.asSeconds());
        timeStampString = `${seconds ? seconds : 0} seconds ago`;
      } else if (timeDuration.asMinutes() < 60) {
        timeStampString = `${Math.round(timeDuration.asMinutes())} minutes ago`;
      } else if (timeDuration.asHours() < 24) {
        timeStampString = `${Math.round(timeDuration.asHours())} hours ago`;
      } else if (timeDuration.asDays() < 31) {
        timeStampString = `${Math.round(timeDuration.asDays())} days ago`;
      } else if (timeDuration.asMonths() < 12) {
        timeStampString = `${Math.round(timeDuration.asMonths())} months ago`;
      }
      else {
        timeStampString = `${timeDuration.asYears()} year ago`
      }
      const nameFirstLetter = message && message.garageName && message.garageName.toUpperCase();
      let colorChange = '';
      if (nameFirstLetter && nameFirstLetter.length && nameFirstLetter.charCodeAt(0) > 64 && nameFirstLetter.charCodeAt(0) <= 69) {
        colorChange = { 'backgroundColor': '#EAABAA' };
      } else if (nameFirstLetter && nameFirstLetter.length && nameFirstLetter.charCodeAt(0) > 69 && nameFirstLetter.charCodeAt(0) <= 74) {
        colorChange = { 'backgroundColor': '#AED5D1' };
      } else if (nameFirstLetter && nameFirstLetter.length && nameFirstLetter.charCodeAt(0) > 74 && nameFirstLetter.charCodeAt(0) <= 79) {
        colorChange = { 'backgroundColor': '#D0E2B2' };
      } else if (nameFirstLetter && nameFirstLetter.length && nameFirstLetter.charCodeAt(0) > 79 && nameFirstLetter.charCodeAt(0) <= 84) {
        colorChange = { 'backgroundColor': '#F7E196' };
      } else if (nameFirstLetter && nameFirstLetter.length && nameFirstLetter.charCodeAt(0) > 84 && nameFirstLetter.charCodeAt(0) <= 90) {
        colorChange = { 'backgroundColor': '#DBDBD9' };
      } else {
        colorChange = { 'backgroundColor': '#C7C7E5' };
      }
      return (
        <MenuItem style={{ 'backgroundColor': messageRead ? '#F9FBFB' : '#FFFFFF', 'cursor': 'pointer' }} eventKey={key} key={key} onClick={(e) => {
          e.preventDefault();
          this.setState({ 'showNotificationBar': false });

          if (!messageRead) {
            message && (message.alertId || (message.data && message.data.alertId)) && this.props.actions.markNotificationAsRead(message.alertId ? message.alertId : message.data.alertId);
          }
          if (message.bookingId) {
            this.props.router.push(`${appBasePath}/request/${message.bookingId}`);
          } else if (message.garageId) {
            this.props.router.push(`${appBasePath}/vendor-profile/${message.garageName.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`);
          }else if (message.data && message.data.bookingId) {
            this.props.router.push(`${appBasePath}/request/${message.data.bookingId}`);
          }
        }}>
          <Media>
          <Media.Left>
            <div className="notification-img">
              <p className="no-image-color" style={colorChange}>{nameFirstLetter && nameFirstLetter.length ? nameFirstLetter[0] : ''}</p>
            </div>
          </Media.Left>
          <Media.Body>
            <Media.Heading>
              {/* <span style={{fontWeight: 'bold', color: 'black'}}>{message.garageName}</span>  has sent {message.type} */}
              {message.msg}
            </Media.Heading>
            {/* {quoteAmount && message.userbookingrequest_id ? (<p><span style={{fontWeight: 'bold', color: 'black'}}>Quote Amount: </span>{quoteAmount} AED, <span style={{fontWeight: 'bold', color: 'black'}}> Booking ID: </span>{message.userbookingrequest_id}</p>) : ''} */}
            {<span className="notify-time">{timeStampString}</span>}
          </Media.Body>
        </Media>
        </MenuItem>
      )
    });
    return (
      <li className={this.state.showNotificationBar ? "notification-menu desktop-view active" : "notification-menu desktop-view"}>
        <div className="text-dropdown notify" >
          <DropdownButton open={this.state.showNotificationBar} bsSize="large" id="dropdown-size-large" noCaret onToggle={() => { this.setState({ 'showNotificationBar': !this.state.showNotificationBar }); }} title={
            <div className="">
              <i className="mdi mdi-bell" aria-hidden="true" />
              {commonReducer.totalUnreadAlerts > 0 && <span className="no-notify">
                <label></label>
              </span>}
            </div>} >
            <MenuItem eventKey="Notifications">
              Notifications
              <a onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/notification'); }} className="view_all">View All</a>
            </MenuItem>
            <div className="desk-notify">
              {this.state.isNotificationsLoaded ?
                commonReducer.unableToFetchNotifications
                  ? <div><h3>Unable to fetch notifications <a style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault; this.props.actions.getNotificationAlerts(0, 5) }}>Try Again</a></h3></div>
                  : (notificationsData && notificationsData.length > 0) ? notificationsView : <NotificationEmptyState />
                : <PageLoader />}
            </div>
          </DropdownButton>
        </div>
      </li>
    );
  }
}
