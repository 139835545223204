import React  from 'react';
import { imageBasePath } from '../../constants/staticData';


export default function WhyPeopleLove() {
   return(
       <div id="whypeoplelove-section">
          <div className="whypeople-text-section">
                <div  className="whypeople-header">
                    <span>Why people love using Carcility?</span>
                </div>
                <div className="whypeople-sub-header">
                    <span>We aim to bring the best service possible and take the burden off 
                          our customers when it comes to auto repair and maintainance.</span>
                </div>
            </div>
        <div className="common-why-people-section">
          <div className="container">
            <div className="row"> 
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
                   <div className="why-loveimage">
                    <div className="why-icon">
                    <div className="easy easy-use"  width="5" height="1"></div>
                        {/*<img src={`${imageBasePath}/images/landing/EasytoUse.svg`} alt="easy to use" className="easy-use"/>*/}    
                    </div>
                    </div>
                    <div className="why-people-maintext">
                        <h1>Easy to Use</h1>
                        <p>Our site and mobile apps make it easy to book anytime,anywhere.</p>
                    </div>
               </div>
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
                    <div className="why-loveimage">
                    <div className="why-icon" >
                    <div className="peace peace-mind"></div>
                        {/*<img src={`${imageBasePath}/images/landing/PeaceofMindGuaranteed.svg`} alt="peace of mind" className="peace-mind" /> */}   
                    </div>
                    </div>
                    <div className="why-people-maintext">
                        <h1>Peace of Mind Guaranteed</h1>
                        <p>Quality service, no unexpected charges,and customer support.</p>
                    </div>
               </div>
               <div className="clearBothWhy"></div>
            </div>
            <div className="row"> 
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
                    <div className="why-loveimage">
                    <div className="why-icon">
                    <div className="fair fair-quote"></div>
                       {/*<img src={`${imageBasePath}/images/landing/GetInstant-FairQuote.svg`} alt="instant fair quote" className="fair-quote" /> */}   
                    </div>
                    </div>
                    <div className="why-people-maintext">
                        <h1>Get Instant and Fair Quote</h1>
                        <p>Recieve and compare instant quotes from different service providers.</p>
                    </div>
               </div>
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
                   <div className="why-loveimage">
                   <div className="why-icon">
                    <div className="maintain maintain-track"></div>
                       {/* <img src={`${imageBasePath}/images/landing/MaintainanceTracker.svg`} alt="maintainance tracker" className="maintain-track" /> */}   
                    </div>
                    </div>
                    <div className="why-people-maintext">
                        <h1>Maintainance Tracker</h1>
                        <p>Service is tracked,and you'll receive reminders for future service.</p>
                    </div>
               </div>
               <div className="clearBothWhy"></div>
            </div>
            <div className="row"> 
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option why-people-individual">
               <div className="why-loveimage"> 
                  <div className="why-icon">
                    <div className="manage manage-car"></div>
                       {/* <img src={`${imageBasePath}/images/landing/ManageyourCarsOnline.svg`} alt="manage your cars online" className="manage-car" /> */}   
                    </div>
                    </div>  
                    <div className="why-people-maintext">
                        <h1>Manage your Cars Online</h1>
                        <p>Store your car and insurance details,24/7 access to service records
                        and no more paper work!</p>
                    </div>
               </div>
               <div className="col-md-6 col-sm-12 col-xs-12 why-people-option">
               <div className="why-loveimage">
                    <div className="why-icon">
                    <div className="secure secure-pay"></div>
                       {/* <img src={`${imageBasePath}/images/landing/SecurePayment.svg`} alt="secure payment" className="secure-pay" />   */} 
                    </div>
                    </div>  
                    <div className="why-people-maintext">
                        <h1>Secure Payment</h1>
                        <p>We hold payment until service is complete and you're satisfied.</p>
                    </div>
               </div>
               <div className="clearBothWhy"></div>
            </div>

            </div>
        </div>
       
       
       </div>
   )
}