export const checkArrayKeyExists = (arr, attr, value) => {

    for (var i = 0; i < arr.length; i++) {
        if (arr[i][attr] === value) {
            return i
        }
    }
    return -1;

}
export const getCarCoverImage = (car) => {

    let coverImageUrl1 = undefined;

    if(!car.images) {
        return undefined
    }

    const nnn = car.images.filter((data, ind) => {
        return data.type == "vehicle"
    })
    const carImageIndex1 = checkArrayKeyExists(nnn, "isCover", true)

    if (carImageIndex1 !== -1) {
        const coverImage1 = nnn[carImageIndex1]
        if (coverImage1.isCover) {
            coverImageUrl1 = coverImage1.original ? { uri: coverImage1.original } : undefined
        }
    }

    return coverImageUrl1

}