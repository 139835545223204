import React, { Component } from 'react';
import Button from '../common/Button';
import { Media } from 'react-bootstrap';
import {round} from 'lodash';
import { imageBasePath } from '../../constants/staticData';

class QuotesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavouriteVisible: true,
    };
  }
  render() {
    const { index, vendorName, rating, distance,vendorImage, reviews, phoneNo, vendorId, isFavourite } = this.props;
    return (
      <div>
        <div className={this.props.activeClass == "active" ? "jobcard box active" : "jobcard box"} onMouseMove={this.props.ClickedQuoteCard} ref={index}>
          <div className="box-content">
            <Media onClick={() => this.props.headingClickCallback()} >
              <Media.Left onClick={() => this.props.headingClickCallback()}>
                <div className="vendor-brand-holder">
                  <img src={vendorImage ? vendorImage : `${imageBasePath}/images/placeholder.png`} alt="Image" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading onClick={() => this.props.headingClickCallback()}>{index} {vendorName}
                  <span className={isFavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"} onClick={(e) => this.props.removeFavourite(e, vendorId)} />
                </Media.Heading>
                <div className="rating rating-left" onClick={() => this.props.headingClickCallback()}>
                  <span className={rating>0 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                  <span className={rating>1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                  <span className={rating>2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                  <span className={rating>3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                  <span className={rating>4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                  <span className="rating-text">{round(rating)} <span className="total-reviews">({reviews} reviews)</span></span></div>
                <span className="distance" onClick={() => this.props.headingClickCallback()} >{distance ? round(distance, 2) + ' Km' : ''} </span>
              </Media.Body>
            </Media>
            <div className="box-footer search-result-card">
              <div className="footer-container">
                <span className="card-ph-number" style={{visibility: 'hidden'}}>{phoneNo && phoneNo}</span>
                <Button btnType="transparent" btnSize="lg" fontSize={13} label="Book Service" btnCallBack={this.props.btnClickFunc} />
                {/*<span className="quotes-cost">120 AED</span>
                                <div className="box-message">
                                    <IconNotification iconType="comment-processing-outline" iconLabel="Messages" notifyClass="notification" viewMessaging={this.props.viewMessaging}/>
                                </div>*/}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuotesCard;
