import React, { Component } from 'react';
import CarouselSlider from '../common/CarouselSlider';
import LoginHeader from '../common/LoginHeader';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import CustomScroll from 'react-custom-scroll';
import AlertDismissable from '../common/AlertDismissable';
import { validateFormSubmit } from '../../helpers';
import { validationMessage, appBasePath } from '../../constants/staticData';
import { trimStart, has } from 'lodash';

export default class SendOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionError: false,
      termsAgreed: false,
      isButtonLoading: false,
      flow: ""
    };
    this.initialFormData = {
      'phone': ''
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      'phone': false,
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
  }

  componentWillMount() {
    this.props.actions.clearComponentKey();
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    let flowData = localStorage.flow ?
    (this.IsValidJSONString(localStorage.flow) ?
    "Get OTP " + JSON.parse(localStorage.flow) : "Get OTP " + localStorage.flow) : '';

    this.setState({ flow: flowData});
    if(this.props.params.id) {
      this.sendOTPAction();
    }
    if (authData) {
      this.formData.phone = authData.phone ? trimStart(authData.phone, '+971') : '';
      if (authData.phone && authData.phoneVerified && !this.props.params.id) {
        this.props.router.push(`${appBasePath}/dashboard-plus`);
      }
    } else {
      if(!this.props.params.id){
        this.props.router.push(`${appBasePath}/`);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    let flowData = localStorage.flow ?
    (this.IsValidJSONString(localStorage.flow) ?
    "Get OTP " + JSON.parse(localStorage.flow) : "Get OTP " + localStorage.flow) : '';

    this.setState({ flow: flowData});
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ 'isButtonLoading' : false});
    }
    if (authData) {
      if (authData.phone && authData.phoneVerified && !this.props.params.id) {
        this.props.router.push(`${appBasePath}/dashboard-plus`);
      }
    } else {
      if(!this.props.params.id){
        this.props.router.push(`${appBasePath}/`);
      }
    }
  }
  componentWillUnmount() {
    this.props.actions.hideErrorMessage();
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }

  IsValidJSONString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        console.log('Its not json');
        return false;
    }
    console.log('Its a json');
    return true;
  }

  sendOTPAction(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      this.setState({ submissionError: false, isButtonLoading: true });
      const authData = localStorage.authData ? JSON.parse(localStorage.authData) : {};
      authData.phone = '+971' + this.formData.phone;
      localStorage.setItem('authData', JSON.stringify(authData));
      this.props.actions.resendOTP('+971' + this.formData.phone);
    }
  }
  render() {
    const { router, authReducer } = this.props;
    return (
      <div className="container-fluid" id="wrapper">
        <LoginHeader headerTitle='Sign Up' />
        <CarouselSlider />
        <div className="col-md-6 col-sm-12 col-xs-12 pad0 grid-12">
          <div className="customScroll">
            <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
              <div className="login-panel otp">
                <div className="login-panel-header">
                  <h3 className="login-title">Sign Up as Customer</h3>
                  {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle="danger" closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                    <p> <i className="mdi mdi-block-helper" /> {authReducer.statusMessage} </p>
                  </AlertDismissable>}
                </div>
                <div className="login-panel-body">
                  <p className="note-text input-title">Enter your mobile number to receive an OTP</p>
                  <div className="otp-mobile-wrapper">
                    <div className="send-otpbtn-align">
                      <TextInput
                        customClass="otp-input"
                        type="phone"
                        name="phone"
                        value={this.formData.phone}
                        showValidationError={this.errors['phone']}
                        emptyError={validationMessage.phone.emptyError}
                        validationError={validationMessage.phone.validationError}
                        label="Mobile Number"
                        onBlur={this.onFieldBlur.bind(this)}
                      />
                    </div>
                    <Button dataLabelledby="Get OTP"  btnCallBack={this.sendOTPAction.bind(this)} btnType="red otpbtnAlign" btnSize="sm" fontSize={16} label="Get OTP" isButtonLoading={this.state.isButtonLoading} isSubmitBtn={true}/>
                    </div>
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
