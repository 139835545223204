import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap';
import map  from 'lodash/map';
import PageLoader from '../common/PageLoader';
import moment from 'moment';
import { imageBasePath } from '../../constants/staticData';
import { defaultImageOnError } from '../../helpers';
import { isNullOrUndefined } from 'util';
export default class ChatBox extends Component {
    constructor(props){
        super(props);
        this.state={
            scrollBottom: null,
            quotesMessage: ''
        };
    }
    componentDidMount() {
      const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
      const customerProfilePhoto = authData ? authData.profilePhoto : '';
        // this.setState({scrollBottom:document.getElementsByClassName('inner-container')[2].scrollHeight});
        if (this.props.jobData && this.props.jobData.hasOwnProperty('userbookingrequest_services') && this.props.jobData.userbookingrequest_services && this.props.jobData.userbookingrequest_services.length) {
          if (!this.props.commonReducer.jobChatRooms[this.props.jobData.userbookingrequest_services[0].userbookingrequestId + '|' + this.props.selectedVendorId] && this.props.selectedVendorId) {
            this.props.actions.createChatRoom({
              garage_id: this.props.selectedVendorId,
              jobrequest_id: this.props.jobData.userbookingrequest_services[0].userbookingrequestId
            }, customerProfilePhoto);
          }
        }
    }

    componentWillReceiveProps(nextProps) {
      const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
      const customerProfilePhoto = authData ? authData.profilePhoto : '';
      if (nextProps.jobData && nextProps.jobData.hasOwnProperty('userbookingrequest_services') && nextProps.jobData.userbookingrequest_services && nextProps.jobData.userbookingrequest_services.length) {
        const chatRoomData = nextProps.commonReducer.jobChatRooms[nextProps.jobData.userbookingrequest_services[0].userbookingrequestId + '|' + nextProps.selectedVendorId];
        if (chatRoomData) {
            const that = this;
            // const chatRoomId = nextProps.commonReducer.currentJobChatRoomId ? nextProps.commonReducer.currentJobChatRoomId : chatRoomData.id;
            window.socket.emit('join-chatroom', chatRoomData.id, function(){
              // that.props.actions.joinChatRoom(chatRoomId);
            that.props.actions.markMessagesAsRead(chatRoomData, that.props.commonReducer.chatMessages);
            });
        } else if (nextProps.selectedVendorId && nextProps.jobData.hasOwnProperty('userbookingrequest_services')) {
          this.props.actions.createChatRoom({
            garage_id: nextProps.selectedVendorId,
            jobrequest_id: nextProps.jobData.userbookingrequest_services[0].userbookingrequestId
          }, customerProfilePhoto);
        }
        const previousChatRoomData = nextProps.jobData.hasOwnProperty('userbookingrequest_services') ? this.props.commonReducer.jobChatRooms[this.props.jobData.userbookingrequest_services[0].userbookingrequestId + '|' + this.props.selectedVendorId]: null;
        if (((chatRoomData && previousChatRoomData) && chatRoomData.id == previousChatRoomData.id) && ((nextProps.commonReducer.chatMessages[chatRoomData.id] && nextProps.commonReducer.chatMessages[chatRoomData.id].messages && nextProps.commonReducer.chatMessages[chatRoomData.id].messages.length) > (this.props.commonReducer.chatMessages[chatRoomData.id] && this.props.commonReducer.chatMessages[chatRoomData.id].messages && this.props.commonReducer.chatMessages[chatRoomData.id].messages.length))) {
          if (document.getElementsByClassName('inner-container') && document.getElementsByClassName('inner-container').length && document.getElementsByClassName('inner-container')[3]) {
            this.setState({ 'scrollBottom': document.getElementsByClassName('inner-container')[3].scrollHeight+10 });
          }
        }
      }
    }
    sendNewMessage(message, customerProfilePhoto) {
      if (message) {
        const that = this;
        //JobChatRoomId is important here to make sure the message goes to the current recipient
        const jobChatRoomId =  this.props.commonReducer.jobChatRooms[this.props.jobData.userbookingrequest_services[0].userbookingrequestId + '|' + this.props.selectedVendorId];
        that.props.actions.sendNewMessage(message,
          that.props.jobData.userbookingrequest_services[0].userbookingrequestId,
          that.props.selectedVendorId,
          jobChatRoomId.id,
          customerProfilePhoto);
        console.log('Triggering job-message event: ', message);
        window.socket.emit('job-message', {
            msg: message,
            room: jobChatRoomId.id,
            user: localStorage.userId
          }, function(data){
          console.log('Socket Response: ', data);
        });
        if (document.getElementsByClassName('inner-container') && document.getElementsByClassName('inner-container').length && document.getElementsByClassName('inner-container')[3]) {
          that.setState({ 'quotesMessage': '', 'scrollBottom': document.getElementsByClassName('inner-container')[3].scrollHeight+10 });
        }
      }
    }
    renderMessages(selectedVendorMessageList, customerProfilePhoto, vendorProfilePhoto) {
        const messageView = map(selectedVendorMessageList, (messageObj, index) => {
          let messageClass = 'c-message';
          const type = (localStorage.userId == messageObj.userId) ? 'sender' : 'receiver';

          messageClass += type == 'receiver' ? ' message-in' : ' message-out';
          if (index > 0 && selectedVendorMessageList[index]['userId'] === selectedVendorMessageList[index - 1]['userId']) {
            messageClass += ' chain-msg';
          }
          if ((index + 1) < selectedVendorMessageList.length) {
            if (selectedVendorMessageList[index]['userId'] === selectedVendorMessageList[index + 1]['userId']) {
              messageClass += ' message-continuation';
            }
          }

          return (
            <div className={messageClass} key={index}>
              <div className="profile-head">
                <span>
                  <img src={
                    type === 'sender' ? customerProfilePhoto ? customerProfilePhoto : `${imageBasePath}/images/pic.png` : vendorProfilePhoto ? vendorProfilePhoto : `${imageBasePath}/images/pic.png`}
                   alt="Profile Avatar"
                   onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}}
                   />
                </span>
              </div>
              <div className="c-chat">
                <p>{messageObj.msg}</p>
              </div>
              {messageObj.createdAt && <div className="delivered-details">
                <label>{moment(messageObj.createdAt).format('h:mm A')}</label>
              </div>}
            </div>
          );
        });
        return messageView;
      }
    render() {
      const jobChatRoomId =  (this.props.jobData && this.props.jobData.hasOwnProperty('userbookingrequest_services')) ? this.props.commonReducer.jobChatRooms[this.props.jobData.userbookingrequest_services[0].userbookingrequestId + '|' + this.props.selectedVendorId]: isNullOrUndefined;
      const currentChat = (jobChatRoomId && jobChatRoomId.id) ? this.props.commonReducer.chatMessages[jobChatRoomId.id] : undefined;


      const messages = (currentChat && currentChat.messages && currentChat.messages.length > 0 )? currentChat.messages : '';
      const customerProfilePhoto = (currentChat && currentChat.customerProfilePhoto ? currentChat.customerProfilePhoto : '');
      const vendorProfilePhoto = (currentChat && currentChat.vendorProfilePhoto ? currentChat.vendorProfilePhoto : '');
      const messagesView = messages ? this.renderMessages(messages, customerProfilePhoto, vendorProfilePhoto) : <h5 style={{color: '#999999', fontFamily: 'CenturyGothic', textAlign: 'center'}}>This is the beginning of your chat. You can use this space to discuss any details with {this.props.selectedVendorName}</h5>;
      return (
          <div>
              <div className="quotes-chat-area">
                <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true} keepAtBottom={true} scrollTo={this.state.scrollBottom}>
                {currentChat && currentChat.isFetched
                  ? messagesView
                  : <PageLoader/>

                }
                  </CustomScroll>
              </div>
              {this.props.jobData.requeststatus && <div className="quotes-message-footer">
                  <form onSubmit={(e) => { e.preventDefault(); this.sendNewMessage(this.state.quotesMessage, customerProfilePhoto); }}>
                      <FormGroup>
                          <InputGroup>
                              <FormControl value={this.state.quotesMessage} type="text" placeholder="Type your message here..." onChange={(e) => { e.preventDefault(); this.setState({ 'quotesMessage': e.target.value }); }} />
                              <InputGroup.Addon onClick={(e) => { e.preventDefault(); if (this.state.quotesMessage) { this.sendNewMessage(this.state.quotesMessage, customerProfilePhoto); }}}>
                                  <i className={this.state.quotesMessage ? "mdi mdi-send active" : "mdi mdi-send"} />
                              </InputGroup.Addon>
                          </InputGroup>
                      </FormGroup>
                  </form>
              </div>}
          </div>
      );
    }
}
