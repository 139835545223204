import React, { Component } from "react";
import { Media } from "react-bootstrap";
import { imageBasePath } from "../../constants/staticData";

class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.closeToast = this.closeToast.bind(this);
  }
  componentDidMount() {
    const that = this;
    if (this.props.type != "error") {
      setTimeout(function() {
        that.props.actions.hideToastMessage();
      }, 3000);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.type != "error") {
      setTimeout(function() {
        nextProps.actions.hideToastMessage();
      }, 3000);
    }
  }
  closeToast() {
    this.props.actions.hideToastMessage();
  }
  render() {
    return (
      <div className={`toast-msg ${this.props.type} show`}>
        {this.props.type == "error" ? (
          <i className="mdi mdi-close" onClick={this.closeToast.bind(this)} />
        ) : (
          ""
        )}
        <Media>
          <Media.Left align="middle">
            <img
              width={25}
              height={18}
              src={
                this.props.type == "success"
                  ? `${imageBasePath}/images/tick.png`
                  : `${imageBasePath}/images/warning.png`
              }
              alt="Image"
            />
          </Media.Left>
          <Media.Body>
            <Media.Heading>{this.props.title}</Media.Heading>
            <p>{this.props.text} </p>
          </Media.Body>
        </Media>
      </div>
    );
  }
}

export default ToastMessage;
