import React, { Component } from 'react';
import SelectDropdown from '../common/SelectDropdown';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import Button from '../common/Button';


class MobileFilter extends Component {

    render() {
        return (
            <div className={this.props.customClass}>
                <div className="header">
                    <i className="mdi mdi-arrow-left" onClick={this.props.hideOption}></i>

                    <div className="f-card">
                        <h5>Request Status</h5>
                        {this.props.activeTab == "OpenItems" ?
                            <div className="row">
                                <div className="col-md-6 pad0">
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="request_all" value="" checked={this.props.requestStatus.all} onChange={() => this.props.checkBoxVal('all')} />
                                        <label htmlFor="request_all" className="custom-checkbox"></label>
                                        <label htmlFor="request_all"> All</label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="active" value="" checked={this.props.requestStatus.active} onChange={() => this.props.checkBoxVal('active')} />
                                        <label htmlFor="active" className="custom-checkbox"></label>
                                        <label htmlFor="active">Active</label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="in_progress" value="" checked={this.props.requestStatus.inProgress} onChange={() => this.props.checkBoxVal('inProgress')} />
                                        <label htmlFor="in_progress" className="custom-checkbox"></label>
                                        <label htmlFor="in_progress">
                                            In Progress
                            </label>
                                    </div>
                                </div>
                                <div className="col-md-6 pad0">
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="waiting" value="" checked={this.props.requestStatus.waiting} onChange={() => this.props.checkBoxVal('waiting')} />
                                        <label htmlFor="waiting" className="custom-checkbox"></label>
                                        <label htmlFor="waiting">
                                            Waiting
                            </label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="accepted" value="" checked={this.props.requestStatus.accepted} onChange={() => this.props.checkBoxVal('accepted')} />
                                        <label htmlFor="accepted" className="custom-checkbox"></label>
                                        <label htmlFor="accepted">
                                            Accepted
                            </label>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-6 pad0">
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="request_all" value="" checked={this.props.requestStatus.all} onChange={() => this.props.checkBoxVal('all')} />
                                        <label htmlFor="request_all" className="custom-checkbox"></label>
                                        <label htmlFor="request_all">
                                            All
                            </label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="cancelled" value="" checked={this.props.requestStatus.cancelled} onChange={() => this.props.checkBoxVal('cancelled')} />
                                        <label htmlFor="cancelled" className="custom-checkbox"></label>
                                        <label htmlFor="cancelled">
                                            Cancelled
                            </label>
                                    </div>
                                </div>
                                <div className="col-md-6 pad0">
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="completed" value="" checked={this.props.requestStatus.completed} onChange={() => this.props.checkBoxVal('completed')} />
                                        <label htmlFor="completed" className="custom-checkbox"></label>
                                        <label htmlFor="completed">
                                            Completed
                            </label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="expired" value="" checked={this.props.requestStatus.expired} onChange={() => this.props.checkBoxVal('expired')} />
                                        <label htmlFor="expired" className="custom-checkbox"></label>
                                        <label htmlFor="expired">
                                            Expired
                            </label>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    {this.props && this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && <div className="f-card">
                        <div className="form-section car-profile">
                            <h5>Car Profile</h5>
                            <SelectDropdown data={this.props.carProfileReducer.carProfiles} filterCarProfile={!this.props.selectedCarProfileId ? true : false} select={() => this.props.select} />
                        </div>
                    </div>}
                    <div className="f-card">
                        <h5>Service Type</h5>
                        <div className="row">
                            <div className="col-md-6 pad0">
                                <div className="checkbox-style checkbox-wrapper">
                                    <input type="checkbox" id="all" value="" checked={this.props.serviceType.all} onChange={this.props.requestTypeCheckbox.bind(this)} />
                                    <label htmlFor="all" className="custom-checkbox"></label>
                                    <label htmlFor="all">
                                        All
                                    </label>
                                </div>
                                <div className="checkbox-style checkbox-wrapper">
                                    <input type="checkbox" id="car_service" value="" checked={this.props.serviceType.service} onChange={this.props.requestTypeCheckbox.bind(this)} />
                                    <label htmlFor="car_service" className="custom-checkbox"></label>
                                    <label htmlFor="car_service">
                                        Car Service
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pad0">
                                <div className="checkbox-style checkbox-wrapper">
                                    <input type="checkbox" id="car_wash" value="" checked={this.props.serviceType.wash} onChange={this.props.requestTypeCheckbox.bind(this)} />
                                    <label htmlFor="car_wash" className="custom-checkbox"></label>
                                    <label htmlFor="car_wash">
                                        Car Wash
                                    </label>
                                </div>
                                <div className="checkbox-style checkbox-wrapper">
                                    <input type="checkbox" id="car_repair" value="" checked={this.props.serviceType.repair} onChange={this.props.requestTypeCheckbox.bind(this)} />
                                    <label htmlFor="car_repair" className="custom-checkbox"></label>
                                    <label htmlFor="car_repair">
                                        Car Repair
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="f-card toggleBtn">
                        <h5>Job Priority</h5>
                        <div className="holder">
                            <span className="pad0">Only show urgent request</span>
                            <ToggleSwitch
                                checked={this.props.showUrgent}
                                size="small"
                                onChange={this.props.showUrgentFunc}
                                ref={(node) => {
                                    this.toggleSwitch = node;
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-footer footer">
                          <a onClick={this.props.clearFilterValues}>Clear</a>
                          <Button btnType="red" btnSize="sm" fontSize={15} label="Apply" btnCallBack={()=>(this.props.filterSelect())} />
                        </div>
                </div>
            </div>
        );
    }
}

export default MobileFilter;
