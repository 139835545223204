import React, { Component } from 'react';
import { DropdownButton, Media, MenuItem } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import moment from 'moment';
import {  appBasePath } from '../../constants/staticData';
import { defaultImageOnError } from '../../helpers'; 

export default class MessagesView extends Component {
  constructor(props) {
    super(props);
  }
  renderChatRooms(chatRooms) {
    return chatRooms.map((chatRoom, key) => {
      let lastMessage = null;
      const allMessages = this.props.commonReducer.chatMessages[`${chatRoom.jobrequestId}|${chatRoom.garageId}`];
      if (allMessages && allMessages.messages && allMessages.messages.length > 0) {
        lastMessage = (allMessages.messages && allMessages.messages.length > 0) ? allMessages.messages[allMessages.messages.length - 1] : undefined
      } else {
        lastMessage = (chatRoom.messages && chatRoom.messages.length > 0) ? chatRoom.messages[chatRoom.messages.length - 1] : undefined
      }
      let timeStampString = null;
      if (lastMessage) {
        const messageTimeStamp = moment(lastMessage.createdAt);
        const currentTimeStamp = moment(new Date());
        const timeDuration = moment.duration(currentTimeStamp.diff(messageTimeStamp));
        const days = Math.ceil(timeDuration.asDays());
        if (days == 1) {
          if (timeDuration.asSeconds() < 60) {
            timeStampString = `${Math.round(timeDuration.asSeconds())} seconds ago`;
          } else if (timeDuration.asMinutes() < 60) {
            timeStampString = `${Math.round(timeDuration.asMinutes())} minutes ago`;
          } else {
            timeStampString = `${Math.round(timeDuration.asHours())} hours ago`;
          }
        } else {
          timeStampString = `${moment(lastMessage.createdAt).format('MMM DD, YYYY, hh:mm A')}`;
        }
      }
      const nameFirstLetter = chatRoom.customer.name.toUpperCase();
      let colorChange ='';
      if(nameFirstLetter.charCodeAt(0)> 64 && nameFirstLetter.charCodeAt(0)<= 69){
        colorChange ={'backgroundColor':'#EAABAA'};
      } else if(nameFirstLetter.charCodeAt(0)> 69 && nameFirstLetter.charCodeAt(0)<= 74){
        colorChange ={'backgroundColor':'#AED5D1'};
      } else if(nameFirstLetter.charCodeAt(0)> 74 && nameFirstLetter.charCodeAt(0)<= 79){
        colorChange ={'backgroundColor':'#D0E2B2'};
      } else if(nameFirstLetter.charCodeAt(0)> 79 && nameFirstLetter.charCodeAt(0)<= 84){
        colorChange ={'backgroundColor':'#F7E196'};
      } else if(nameFirstLetter.charCodeAt(0)> 84 && nameFirstLetter.charCodeAt(0)<= 90){
        colorChange ={'backgroundColor':'#DBDBD9'};
      } else {
        colorChange ={'backgroundColor':'#C7C7E5'};
      }
      const isCustomer = (lastMessage.userId || lastMessage.user_id) == localStorage.userId;
      return (
        <div className="messageCard" key={key} onClick={(e) => { e.preventDefault(); this.props.router.push(`${appBasePath}/request/${chatRoom.userbookingrequest.requestid}?jobid=${chatRoom.userbookingrequest.id}&view=message&garage_id=${chatRoom.garageId}`); }}>
          <Media>
            <Media.Left>
              {(chatRoom.garage && chatRoom.garage.profilePhoto && chatRoom.garage.profilePhoto.trim().length > 0) ?
                <img width={36} height={36} src={chatRoom.garage.profilePhoto} alt={chatRoom.garage.companyprofilename || ""} 
                onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}}/>
                : <span className="no-image-color fullView" style={colorChange}>{nameFirstLetter[0]}</span>
            }
            </Media.Left>
            <Media.Body>
              {/*<Media.Heading>{'Car Profile Name N/A'}</Media.Heading>*/}
              <h4 className="messenger-name">{chatRoom.garage.companyprofilename}</h4>
              {/*<span className="messenger-firm">{chatRoom.garage.companyprofilename}</span>*/}
              {lastMessage && <p style={{ width: '100%' }}><span>{isCustomer ? 'You' : chatRoom.garage.companyprofilename }: </span> {lastMessage.msg}</p>}
              <div className="option_time">
                <div className="three-dots-icon">
                  <DropdownButton bsSize="xsmall" footer="show" id="dropdown-size-extra-small" title={<i className="mdi mdi-dots-vertical" />} onToggle={() => {}} noCaret pullRight>
                    <MenuItem eventKey="Share">Share Offer Details</MenuItem>
                    <MenuItem
                      eventKey="Vendor Profile"
                      onClick={
                        () => {
                          this.props.router.push(`${appBasePath}/vendor-profile/${chatRoom.garage.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`) }}>View Vendor Profile</MenuItem>
                    <MenuItem eventKey="Email Vendor">Email Vendor</MenuItem>
                  </DropdownButton>
                </div>
              </div>
              <div className="messageTime">{timeStampString}</div>
              {chatRoom.unseenMsg ? <div className="newMessage_count">
                {chatRoom.unseenMsg}
              </div> : ''}
            </Media.Body>
          </Media>
        </div>
      );
    });
  }

  render() {
    const { commonReducer } = this.props;
    const chatRooms = commonReducer.chatRooms;
    const chatRoomsView = this.renderChatRooms(chatRooms);
    return (
      <div className="row">
        <div className="col-md-12 pad0">
          <div className="inboxContainer">
            <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
              <div className="inboxMessage messages_list-view">
                {chatRoomsView}
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
