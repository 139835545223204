import React, { Component } from 'react';
import CarouselSlider from '../common/CarouselSlider';
import LoginHeader from '../common/LoginHeader';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import AlertDismissable from '../common/AlertDismissable';
import CustomScroll from 'react-custom-scroll';
import { validateFormSubmit } from '../../helpers';
import { validationMessage, appBasePath } from '../../constants/staticData';
import has  from 'lodash/has';
import TagManager from "react-gtm-module";

export default class VerifyOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionError: false,
      isButtonLoading: false,
      flow: "Verify OTP"
    };
    this.initialFormData = {
      'otp': ''
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors =  {
      'otp': false
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
  }
  componentWillMount() {
    if(this.props.params.id) {
      const otpreq ={phone: JSON.parse(localStorage.getItem('verifyotp')).phone}
      this.props.actions.sendOtp(otpreq);
    }

    this.setState({ flow: "Verify OTP "+localStorage.flow });

    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    if (authData && !this.props.params.id) {
      if (authData.phone) {
        if (authData.phoneVerified) {

          let provider = authData.provider?authData.provider:"normal";
          let route = authData.subtype == "guest" ?"guestuser":authData.subtype;
          let urlData = "?method="+provider+"&route="+route;

         if ( localStorage.flow == "quickbooking"){
           let redirectPath = appBasePath + localStorage.serviceId == 1 ? 'car-wash': localStorage.serviceId == 2 ? 'car-service': 'car-repair';

           window.location = `${appBasePath}/quick-booking/`+redirectPath+"/"+urlData;

         }else{
           window.location = `${appBasePath}/dashboard-plus`+urlData;

         }


        }
      } else {
        this.props.router.push(`${appBasePath}/send-otp`);
      }
    } else {
      if(!this.props.params.id) {
        this.props.router.push(`${appBasePath}/`);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.authReducer.currentComponentKey) {
      this.props.actions.clearComponentKey();
    }
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ 'isButtonLoading' : false});
    }
    this.setState({ flow: "Verify OTP "+localStorage.flow });
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    if (authData && !this.props.params.id) {
      if (authData.phone) {
        if (authData.phoneVerified) {

          let provider = authData.provider?authData.provider:"normal";
          let route = authData.subtype == "guest" ?"guestuser":authData.subtype;
          let urlData = "?method="+provider+"&route="+route;

          if ( localStorage.flow == "quickbooking"){
            let redirectPath = appBasePath + localStorage.serviceId == 1 ? 'car-wash': localStorage.serviceId == 2 ? 'car-service': 'car-repair';
            window.location = `${appBasePath}/quick-booking/`+redirectPath+urlData;
          }else {
            window.location = `${appBasePath}/dashboard-plus`+urlData;
          }
        }
      } else {
        this.props.router.push(`${appBasePath}/send-otp`);
      }
    } else {
      if(!this.props.params.id) {
        this.props.router.push(`${appBasePath}/`);
      }
    }
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }

  onKeyPress(e) {
    this.verifyOTPAction(e);
  }
  verifyOTPAction(e) {
    e.preventDefault();
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      this.setState({ submissionError: false, isButtonLoading: true });
      if(this.props.params.id){
        let contactUpdates ={
          "name" :JSON.parse( localStorage.getItem('verifyotp')).name ,
          "phone":JSON.parse(localStorage.getItem('verifyotp')).phone,
          "otp":this.formData.otp,
          "profile_photo":JSON.parse(localStorage.getItem('verifyotp')).profile_photo}
        this.props.actions.contactDetailsUpdate(contactUpdates);
      } else {
        this.props.actions.showWelcomePage(
          this.formData.otp,
          authData.phone,
          authData.id
        );
      }
    }
  }
  componentWillUnmount() {
    if(!this.props.params.id){
      this.props.actions.hideErrorMessage();
    }
    localStorage.removeItem('verifyotp');
  }
  render() {


    const tagManagerArgs = {
      dataLayer: {
        pageCategory: "Normal Sign Up",
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);



    let pageCategory = "";
    if(this.state.flow == "Guest User Normal Sign up" || this.state.flow == "Guest User Facebook Sign up" ||  this.state.flow == "Guest User Google Sign up"){
      pageCategory =  'Guest User Sign Up';

    }else if(this.state.flow == "Normal Sign up" || this.state.flow == "Facebook Sign up" ||  this.state.flow == "Google Sign up"){
      pageCategory =  'Normal Sign Up';

    }


    const { router, authReducer, actions } = this.props;
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    let sendOtp = this.props.params.id ? 'send-otp/'+this.props.params.id : 'send-otp';
    return (
      <div className="container-fluid" id="wrapper">
        <LoginHeader pageType ="verifyOtp"  actions={actions} headerTitle='Sign Up'  router={router} />
        <CarouselSlider />
        <div className="col-md-6 col-sm-12 col-xs-12 pad0 grid-12">
          <div className="customScroll">
            <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
              <div className="login-panel otp">
                <div className="login-panel-header">
                  <h3 className="login-title">{this.props.params.id ? 'Verify phone Number':'Sign Up as Customer'}</h3>
                  {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle="danger" closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                    <p> <i className="mdi mdi-block-helper" /> {authReducer.statusMessage} </p>
                  </AlertDismissable>}
                  <p className="note-text">
                    A One Time Password has been sent on your registered mobile number.
                    {authData && authData.phone && <strong><span className="edit-mobileno">{this.props.params.id?JSON.parse(localStorage.getItem('verifyotp')).phone :authData.phone}</span><i className="mdi mdi-pencil" onClick={(e) => { e.preventDefault(); router.push(`${appBasePath}/${sendOtp}`); }} /></strong>}
                  </p>
                </div>
                <div className="login-panel-body input-button-addon">
                  <p className="note-text input-title">Enter the OTP code below to continue</p>
                  <TextInput
                    customClass="otp-input"
                    name="otp"
                    type="text"
                    showValidationError={this.errors['otp']}
                    emptyError={validationMessage.otp.emptyError}
                    validationError={validationMessage.otp.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                    onEnterCall={true}
                    isPositiveNumber={true}
                    isOTP={true} />
                  <Button dataLabelledby={this.state.flow}  btnCallBack={this.verifyOTPAction.bind(this)} isSubmitBtn={true} btnType="red" btnSize="lg" fontSize={14} label="Complete Sign Up" isButtonLoading={this.state.isButtonLoading}/>
                  <p className="note-text">
                    {'Didn\'t get OTP ?'} <a onClick={(e) => {e.preventDefault(); this.props.params.id ?  this.props.actions.sendOtp(JSON.parse(localStorage.getItem('verifyotp')).phone)
                    :this.props.actions.resendOTP(authData.phone, true)}} className="blue-text">Resend</a>
                  </p>
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
