import React  from 'react';
import LazyLoad from 'react-lazyload';
import { Element, animateScroll as scroll } from 'react-scroll';
import CustomerTestimonials from "../common/Testimonials";

export default function CustomerFeedback() {
    return(
        <Element name="overview" className="element">
        <LazyLoad>
          <div className="common-wrapper" id="testimonial-section">
              <div className="workshop-container container">
                <div className="workshop-heading wow slideInUp">
                  <h2>Hear from our Customers</h2>
                  <div className="customer-feedback-section">We believe in building trust and our happy customers show gratitude for the kind of
                  car service and maintenance given to their vehicles.</div>
                </div>
                <div className="row ">
                    <CustomerTestimonials/>
                </div>
            </div>
           </div>
          </LazyLoad>  
        </Element>
    )
}
