import React, { Component }  from 'react';
import LazyLoad from 'react-lazyload';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { imageBasePath, appBasePath } from '../../constants/staticData';


export default class Service extends Component {
    constructor(props) {
      super(props);
    }

    render(){
      const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    return(
      <Element name="overview" className="element">
       <div  id="service-section">
        {/* <LazyLoad>*/}
          <div>
          <div className="service-text-section">
            <div className="common-heading">
              <span>What service do you need?</span>
            </div>
            <div className="service-section-sub">
                Finding the best car service is no hassle.
            </div>
            <div className="service-section-subText">
            From a plethora of car servicing providers, choose convenient, efficient, affordable service and maintenance within a click. Experience the best car care with Carcility, one-stop destination for all your car service and car repair needs in Dubai, Abudhabi and Sharjah.
            </div>
            </div>
            <div className="row our-service">
              <div className="col-md-3 col-xs-6 col-sm-6 service-div">
                <div
                  className="service-img-land"
                  onClick={e => {
                    e.preventDefault();
                    if (authData && authData.type == 'customer') {
                      this.props.router.push(appBasePath + '/car-repair');
                    } else {
                      this.props.router.push(appBasePath + '/car-repair-in-dubai');
                    }
                  }}
                >
                  <img src={imageBasePath + '/images/landing/car-repair-service-in-dubai.svg'} alt="car repair service in dubai" />
                  <h2>CAR REPAIR</h2>
                </div>
              </div>
              <div className="col-md-3 col-xs-6 col-sm-6 service-div">
                <div
                  className="service-img-land"
                  onClick={e => {
                    e.preventDefault();
                    if (authData && authData.type == 'customer') {
                      this.props.router.push(appBasePath + '/car-service');
                    } else {
                      this.props.router.push(appBasePath + '/car-service-in-dubai');
                    }
                  }}
                >
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai.svg`} alt="car service in dubai" />
                  <h2>CAR SERVICE</h2>
                </div>
              </div>
              <div className="col-md-3 col-xs-6 col-sm-6 service-div">
                <div
                  className="service-img-land"
                  onClick={e => {
                    e.preventDefault();
                    if (authData && authData.type == 'customer') {
                      this.props.router.push(appBasePath + '/car-wash');
                    } else {
                      this.props.router.push(appBasePath + '/car-wash-in-dubai');
                    }
                  }}
                >
                  <img src={imageBasePath + '/images/landing/car-wash-service-in-dubai.svg'} alt="car wash service in dubai" />
                  <h2>CAR WASH</h2>
                </div>
              </div>

              <div className="col-md-3 col-xs-6 col-sm-6 service-div">
                <div
                  className="service-img-land"
                  onClick={e => {
                    emergencyIsComingSoon ? e.preventDefault() : this.props.router.push(appBasePath + '/emergency');
                  }}
                >
                  <img src={`${imageBasePath}/images/landing/car-mobile service-in-dubai.svg`} alt="car roadside assistance service in dubai" />
                  <h2>MOBILE SERVICE</h2>
                </div>
              </div>
            </div>
          </div>
        {/*</LazyLoad>*/}
        </div>
    </Element>
    );
}
}