import React, { Component } from 'react';
import Button from '../common/Button';
import Footer from '../Landing/Footer';
import { imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import { Helmet } from "react-helmet";

class AuthLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarVisible: false
    };
  }
  render() {
    return (
      <div className="authLanding-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['register'] && metaTagData['register'].title ? metaTagData['register'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['register'] && metaTagData['register'].description ? metaTagData['register'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        <nav className="navbar navbar-default default-header" id="header-section1">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
                onClick={() => {
                  this.setState({ navbarVisible: !this.state.navbarVisible });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a
                className="navbar-brand desk-logo"
                onClick={() => {
                  this.props.router.push(`${appBasePath}/`);
                }}
              >
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>
              {/*<a className="navbar-brand mobile-logo" onClick={() => {this.props.router.push('/');}}>
                                <img src="/images/logo.svg" />
                            </a>*/}
            </div>
            <div
              id="navbar"
              className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}
            >
              <ul className="nav navbar-nav navbar-right">
                <li className="work-active">
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(`${appBasePath}/`);
                    }}
                  >
                    {' '}how it works{' '}
                  </a>
                  <label />
                </li>
                <li>
                  <a href="/business">For Business </a>
                  <label />
                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(`${appBasePath}/sign-in`);
                    }}
                  >
                    Login{' '}
                  </a>
                  <label />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="authBanner">
          <div className="authCard">
            <h4>Customer</h4>
            <p>Find everything you need to track your success on the road.</p>
            <Button
              dataLabelledby="Sign Up as Customer"
              btnCallBack={e => {
                e.preventDefault();
                this.props.router.push(`${appBasePath}/sign-up`);
              }}
              btnType="red"
              btnSize="lg"
              fontSize={14}
              label="Sign Up as Customer"
            />
            <img src={`${imageBasePath}/images/authCard-left.png`} alt="Register your car service" />
          </div>
          <div className="authCard">
            <h4>Business</h4>
            <p>Find everything you need to track your success on the road.</p>
            <Button
              dataLabelledby="Sign Up as Business"
              btnCallBack={e => {
                e.preventDefault();
                window.location.pathname = '/business/sign-up';
              }}
              btnType="red"
              btnSize="lg"
              fontSize={14}
              label="Sign Up as Business"
            />
            <img src={`${imageBasePath}/images/authCard-right.png`} alt="Register your car service center" />
          </div>
        </div>
       <Footer {...this.props} />
      </div>
    );
  }
}

export default AuthLanding;
