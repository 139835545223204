import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import CardView from './CardView';
import { DropdownButton } from 'react-bootstrap';
import Button from '../common/Button';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import SelectDropdown from '../common/SelectDropdown';
import map from 'lodash/map';
import size from 'lodash/size';
import PageLoader from '../common/PageLoader';
import MyRequestEmptyState from '../EmptyState/MyRequestEmptyState';
import MobileFilter from './MobileFilter';
import RequestMobileSort from '../common/RequestMobileSort';
import MobileHeaderSearch from '../common/MobileHeaderSearch'

export default class MyRequestCard extends Component {
  constructor(props, context) {
    super(props, context);
    this.requestStatus = { all: false, active: false, waiting: false, inProgress: false, accepted: false, cancelled: false, completed: false, expired: false };
    this.openRequestStatus = [];
    this.closedRequestStatus = [];
    this.serviceTypeStatus = [];
    this.serviceType = { all: false, wash: false, service: false, repair: false }
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile: false,
      showMobileSort: false,
      showMobileFilter: false,
      isLoading: true,
      carProfileDd: false,
      carProfile: "audi",
      notificationVisible: false,
      messageVisible: false,
      activeTab: "OpenItems",
      sortByVal: "",
      filterData: false,
      filterSort: "dateNO",
      filterdropdown: false,
      showUrgent: false,
      chkbox: false,
      openRowCount: undefined,
      closedRowCount: undefined,
      openCurrentPage: 0,
      closedCurrentPage: 0,
      filterApplied: false,
      previouslyFilterApplied: false,
      selectedCarProfileId: undefined,
      selectedCarProfile: undefined,
      userPaymentStatus: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.clearFilterValues = this.clearFilterValues.bind(this);
  }

  componentWillMount() {
    this.props.actions.clearBookingReducerComponentKey();
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    }
    this.props.actions.getAllBookingRequests('open', 10, this.state.openCurrentPage, false);
    this.props.actions.getAllBookingRequests('closed', 10, this.state.closedCurrentPage, false);
    this.props.actions.getAllBookingRequests('completed', 10, this.state.closedCurrentPage, false);
    document.body.addEventListener('mousedown', this.bodyClick.bind(this));
    this.props.actions.getUserPaymentStatus();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bookingRequestReducer.isLoaded) {
      this.setState({ isLoading: false });
    }
    if (nextProps.bookingRequestReducer.openBookingRequests) {
      this.setState({
        openCurrentPage: nextProps.bookingRequestReducer.nextPageInOpenJobs,
        openRowCount: nextProps.bookingRequestReducer.openPageInfo.rowCount
      });
    }
    if (nextProps.bookingRequestReducer.closedBookingRequests) {
      this.setState({
        closedCurrentPage: nextProps.bookingRequestReducer.nextPageInClosedJobs,
        closedRowCount: nextProps.bookingRequestReducer.closedPageInfo.rowCount
      });
    }
    if (nextProps.bookingRequestReducer.completedBookingRequests) {
      this.setState({
        completedCurrentPage: nextProps.bookingRequestReducer.nextPageInCompletedJobs,
        completedRowCount: nextProps.bookingRequestReducer.completedPageInfo.rowCount
      });
    }
    this.setState({
      userPaymentStatus: this.props.commonReducer && this.props.commonReducer.userPaymentStatusData && this.props.commonReducer.userPaymentStatusData.enabled,

    });
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.bodyClick.bind(this));
  }

  // bodyClick(e) {
  //   if (e.target.className == "filterLabel showFilters " || e.target.closest(".showFilters")) {
  //     this.setState({ filterdropdown: !this.state.filterdropdown })
  //   } else if ((e.target.className == "input-sel-dropdown" || e.target.closest(".modal-cus-Dd") || e.target.closest(".Filterby.filterCard") && !this.state.filterdropdown)) {
  //     this.setState({ filterdropdown: true });
  //   } else if (this.state.filterdropdown){
  //     this.setState({ filterdropdown: false });
  //   }
  // }
  bodyClick(e) {
    if ((e.target.closest('.filter-dropdown') || e.target.closest('.showFilters')) && (!this.state.filterdropdown)) {
      this.setState({ filterdropdown: true, sortBydropdown: false });
    }
    else if (e.target.closest('.Filterby') == null && e.target.closest('.sortFilter') == null) {
      this.setState({ filterdropdown: false, sortBydropdown: false });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  requestStatusCheckbox(chkbox) {
    if (chkbox == "all") {
      if (this.requestStatus.all == false) {
        this.requestStatus = { all: true, active: true, waiting: true, inProgress: true, accepted: true, cancelled: true, completed: true, expired: true };
      } else {
        this.requestStatus = { all: false, active: false, waiting: false, inProgress: false, accepted: false, cancelled: false, completed: false, expired: false };
      }
    } else {
      this.requestStatus["all"] = false;
      this.requestStatus[chkbox] = !this.requestStatus[chkbox];
    }
    this.setState({ chkbox: !this.state.chkbox });
  }

  requestTypeCheckbox(chkbox) {
    if (chkbox == "all") {
      if (this.serviceType.all == false) {
        this.serviceType = { all: true, wash: true, service: true, repair: true }
      } else {
        this.serviceType = { all: false, wash: false, service: false, repair: false }
      }
    } else {
      this.serviceType["all"] = false;
      this.serviceType[chkbox] = !this.serviceType[chkbox];
    }
    this.setState({ chkbox: !this.state.chkbox });
  }


  clearFilterValues() {
    this.requestStatus = { all: false, active: false, waiting: false, inProgress: false, accepted: false, cancelled: false, completed: false, expired: false };
    this.serviceType = { all: false, wash: false, service: false, repair: false }
    this.openRequestStatus = [];
    this.closedRequestStatus = [];
    this.serviceTypeStatus = [];
    this.props.actions.getAllBookingRequests('open', 10, 0, false);
    this.props.actions.getAllBookingRequests('closed', 10, 0, false);
    this.setState({ filterApplied: false, filterdropdown: true, showUrgent: false, chkbox: !this.state.chkbox, selectedCarProfileId: undefined, selectedCarProfile: undefined });
  }

  filterDdFunc() {
    this.setState({ filterdropdown: true });
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id,
      selectedCarProfile: carProfile
    });
  }

  loadMore(type) {
    if (!this.state.filterApplied) {
      if (type == "open") {
        this.props.actions.getAllBookingRequests('open', 10, this.state.openCurrentPage, true, this.state.filterSort);
      } else if (type == "closed") {
        this.props.actions.getAllBookingRequests('closed', 10, this.state.closedCurrentPage, true, this.state.filterSort);
      }else if (type == "completed") {
        this.props.actions.getAllBookingRequests('completed', 10, this.state.completedCurrentPage, true, this.state.filterSort);
      }
    } else {
      if (type == "open") {
        this.props.actions.getAllBookingRequests('open', 10, this.state.openCurrentPage, true, this.state.filterSort, this.openRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent);
      } else if (type == "closed") {
        this.props.actions.getAllBookingRequests('closed', 10, this.state.closedCurrentPage, true, this.state.filterSort, this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent);
      }else if (type == "completed") {
        this.props.actions.getAllBookingRequests('completed', 10, this.state.completedCurrentPage, true, this.state.filterSort, this.completedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent);
      }
    }
  }

  applyFilter() {
    this.openRequestStatus = [];
    this.closedRequestStatus = [];
    this.serviceTypeStatus = [];
    let status = [];
    map(this.requestStatus && this.requestStatus, (value, key) => {
      if (!this.requestStatus['all']) {
        if (this.requestStatus[key] && key == "active") {
          this.openRequestStatus.push(1);
        } else if (this.requestStatus[key] && key == "waiting") {
          this.openRequestStatus.push(2);
        } else if (this.requestStatus[key] && key == "inProgress") {
          this.openRequestStatus.push(3);
        } else if (this.requestStatus[key] && key == "accepted") {
          this.openRequestStatus.push(4);
        } else if (this.requestStatus[key] && key == "cancelled") {
          this.closedRequestStatus.push(5);
        } else if (this.requestStatus[key] && key == "completed") {
          this.closedRequestStatus.push(6);
        } else if (this.requestStatus[key] && key == "expired") {
          this.closedRequestStatus.push(7);
        }
      } else if (this.requestStatus['all']) {
        this.openRequestStatus = [];
        this.closedRequestStatus = [];
        this.openRequestStatus.push(1, 2, 3, 4);
        this.closedRequestStatus.push(5, 6, 7);
      }
      this.setState({ showMobileFilter: false });
    });
    map(this.serviceType && this.serviceType, (value, key) => {
      if (!this.serviceType['all']) {
        if (this.serviceType[key] && key == "wash") {
          this.serviceTypeStatus.push(1);
        } else if (this.serviceType[key] && key == "service") {
          this.serviceTypeStatus.push(2);
        } else if (this.serviceType[key] && key == "repair") {
          this.serviceTypeStatus.push(3);
        }
      } else if (this.serviceType['all']) {
        this.serviceTypeStatus = [];
        this.serviceTypeStatus.push(1, 2, 3);
      }
    });
    let filterApplied = false;
    if ((status && status.length) || (this.serviceTypeStatus && this.serviceTypeStatus.length) || this.state.selectedCarProfileId || this.state.showUrgent || (this.closedRequestStatus && this.closedRequestStatus.length) || (this.openRequestStatus && this.openRequestStatus.length)) {
      filterApplied = true;
    }
    this.setState({
      ...this.state,
      showMobileFilter: false,
      filterdropdown: false,
      filterApplied: filterApplied,
      previouslyFilterApplied: true
    });
    this.props.actions.getAllBookingRequests("open", 10, 0, false, this.state.filterSort, this.openRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent);
    this.props.actions.getAllBookingRequests("closed", 10, 0, false, this.state.filterSort, this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent);
  }

  render() {
    const { commonReducer, actions, router, bookingRequestReducer, authReducer } = this.props;
    const allOpenBookingRequests = size(bookingRequestReducer.openBookingRequests) ? bookingRequestReducer.openBookingRequests : [];
    const allClosedBookingRequests = size(bookingRequestReducer.closedBookingRequests) ? bookingRequestReducer.closedBookingRequests : [];
    const allCompletedBookingRequests = size(bookingRequestReducer.completedBookingRequests) ? bookingRequestReducer.completedBookingRequests : [];
    const allCompletedBookingRequestsCount = size(allCompletedBookingRequests);
    const allOpenBookingRequestsCount = size(allOpenBookingRequests);
    const allClosedBookingRequestsCount = size(allClosedBookingRequests);

    console.log("this.state.userPaymentStatus",this.state.userPaymentStatus)
    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={() => this.setState({ searchMobile: true })} bookingRequestReducer={this.props.bookingRequestReducer} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer} />
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader">
          {/*Sidebar*/}
          <Sidebar router={router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header ">
            <div className="padwrapper">
              <h4>My Request</h4>
              <span className="request-filter mshow">
                <i className={this.state.showMobileFilter ? "mdi mdi-filter-variant active" : "mdi mdi-filter-variant "} onClick={() => this.setState({ showMobileFilter: !this.state.showMobileFilter })}></i>
                <i className={this.state.showMobileSort ? "mdi mdi-swap-vertical active" : "mdi mdi-swap-vertical"} onClick={() => this.setState({ showMobileSort: !this.state.showMobileSort })}></i>
              </span>
            </div>
          </div>
          <div className="inSection">
            {!this.state.isLoading ? (!this.state.filterApplied && (allOpenBookingRequestsCount || allClosedBookingRequestsCount)) || this.state.filterApplied || this.state.previouslyFilterApplied ?
              <div className="padwrapper">
                <div className="sub-header">
                  <div className="tab">
                    <span className={this.state.activeTab == "OpenItems" ? "active" : ""} onClick={() => {
                      this.setState({ activeTab: "OpenItems", filterdropdown: false });
                    }}>{this.props.bookingRequestReducer && this.props.bookingRequestReducer.openPageInfo && this.props.bookingRequestReducer.openPageInfo.rowCount ? this.props.bookingRequestReducer.openPageInfo.rowCount + " Open" : "0 Open"} </span>
                  </div>
                  <div className="tab">
                    <span className={this.state.activeTab == "CompletedItems" ? "active" : ""} onClick={() => {
                      this.setState({ activeTab: "CompletedItems", filterdropdown: false });
                    }}>{this.props.bookingRequestReducer && this.props.bookingRequestReducer.completedPageInfo && this.props.bookingRequestReducer.completedPageInfo.rowCount ? this.props.bookingRequestReducer.completedPageInfo.rowCount + " Completed" : "0 Completed"} </span>
                  </div>
                  <div className="tab">
                    <span className={this.state.activeTab == "CloseItems" ? "active" : ""} onClick={() => {
                      this.setState({ activeTab: "CloseItems", filterdropdown: false });
                    }}>{this.props.bookingRequestReducer && this.props.bookingRequestReducer.closedPageInfo && this.props.bookingRequestReducer.closedPageInfo.rowCount ? this.props.bookingRequestReducer.closedPageInfo.rowCount + " Closed" : '0 Closed'}</span>
                  </div>
                  <div className={"filterSection active sort-request-card mhide"}>
                    <DropdownButton bsSize="small" id="dropdown-size-small" pullRight open={this.state.sortBydropdown} onToggle={(e) => { this.setState({ sortBydropdown: e }) }} noCaret title={
                      <div className="filterLabel showSortBy">
                        <i className="mdi mdi-swap-vertical" />
                        <label>Sort by</label>
                        <i className={this.state.sortBydropdown ? "mdi mdi-chevron-up downIcon downAlign pull-right" : "mdi mdi-chevron-down downIcon downAlign pull-right"} />
                      </div>
                    }>
                      <div className="sortFilter filterCard">
                        <ul className="list-unstyled">
                          <li onClick={() => { actions.getAllBookingRequests(this.state.activeTab == "OpenItems" ? "open" : "closed", 10, 0, false, "dateNO", this.state.activeTab == "OpenItems" ? this.openRequestStatus : this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent), this.setState({ filterSort: "dateNO", sortBydropdown: false }) }} className={this.state.filterSort == "dateNO" ? "active" : ""}>
                            <label>
                              Date Created - New to Old
                          </label>
                            <span>
                              <i className={this.state.filterSort == "dateNO" ? "mdi mdi-check active" : "hide"} />
                            </span>
                          </li>

                          <li onClick={() => { actions.getAllBookingRequests(this.state.activeTab == "OpenItems" ? "open" : "closed", 10, 0, false, "dateON", this.state.activeTab == "OpenItems" ? this.openRequestStatus : this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent), this.setState({ filterSort: "dateON", sortBydropdown: false }); }} className={this.state.filterSort == "dateON" ? "active" : ""}>
                            <label>
                              Date Created - Old to New
                          </label>
                            <span>
                              <i className={this.state.filterSort == "dateON" ? "mdi mdi-check active" : "hide"} />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </DropdownButton>
                  </div>
                  <div className={this.state.filterApplied ? "filterSection active request-card sort-request-card mhide" : "filterSection request-card sort-request-card mhide"}>
                    <DropdownButton bsSize="large" open={this.state.filterdropdown} onSelect={this.filterDdFunc.bind(this)} pullRight noCaret id="dropdown-size-large" title={
                      <div className="filterLabel showFilters ">
                        <i className="mdi mdi-filter-variant" />
                        <label>Filter</label>
                        <i className={this.state.filterdropdown ? "mdi mdi-chevron-up downIcon pull-right" : "mdi mdi-chevron-down downIcon pull-right"} />
                      </div>
                    }>
                      <div className="Filterby filterCard">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 left">
                            <div className="filterby-wrapper">
                              <div className="f-card">
                                <h5>Request Status</h5>
                                {this.state.activeTab == "OpenItems" ?
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 pad0">
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="request_all" value="" checked={this.requestStatus.all} onChange={this.requestStatusCheckbox.bind(this, 'all')} />
                                        <label htmlFor="request_all" className="custom-checkbox"></label>
                                        <label htmlFor="request_all">
                                          All
                                      </label>
                                      </div>
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="active" value="" checked={this.requestStatus.active} onChange={this.requestStatusCheckbox.bind(this, 'active')} />
                                        <label htmlFor="active" className="custom-checkbox"></label>
                                        <label htmlFor="active">
                                          Active
                                      </label>
                                      </div>
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="in_progress" value="" checked={this.requestStatus.inProgress} onChange={this.requestStatusCheckbox.bind(this, 'inProgress')} />
                                        <label htmlFor="in_progress" className="custom-checkbox"></label>
                                        <label htmlFor="in_progress">
                                          In Progress
                                      </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="waiting" value="" checked={this.requestStatus.waiting} onChange={this.requestStatusCheckbox.bind(this, 'waiting')} />
                                        <label htmlFor="waiting" className="custom-checkbox"></label>
                                        <label htmlFor="waiting">
                                          Waiting
                                      </label>
                                      </div>
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="accepted" value="" checked={this.requestStatus.accepted} onChange={this.requestStatusCheckbox.bind(this, 'accepted')} />
                                        <label htmlFor="accepted" className="custom-checkbox"></label>
                                        <label htmlFor="accepted">
                                          Accepted
                                      </label>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 pad0">
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="request_all" value="" checked={this.requestStatus.all} onChange={this.requestStatusCheckbox.bind(this, 'all')} />
                                        <label htmlFor="request_all" className="custom-checkbox"></label>
                                        <label htmlFor="request_all">
                                          All
                                      </label>
                                      </div>
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="cancelled" value="" checked={this.requestStatus.cancelled} onChange={this.requestStatusCheckbox.bind(this, 'cancelled')} />
                                        <label htmlFor="cancelled" className="custom-checkbox"></label>
                                        <label htmlFor="cancelled">
                                          Cancelled
                                      </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="completed" value="" checked={this.requestStatus.completed} onChange={this.requestStatusCheckbox.bind(this, 'completed')} />
                                        <label htmlFor="completed" className="custom-checkbox"></label>
                                        <label htmlFor="completed">
                                          Completed
                                      </label>
                                      </div>
                                      <div className="checkbox-style checkbox-wrapper">
                                        <input type="checkbox" id="expired" value="" checked={this.requestStatus.expired} onChange={this.requestStatusCheckbox.bind(this, 'expired')} />
                                        <label htmlFor="expired" className="custom-checkbox"></label>
                                        <label htmlFor="expired">
                                          Expired
                                      </label>
                                      </div>
                                    </div>
                                  </div>}
                              </div>
                            </div>
                            {this.props && this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && <div className="f-card">
                              <div className="form-section car-profile">
                                <h5>Car Profile</h5>
                                <SelectDropdown data={this.props.carProfileReducer.carProfiles} filterCarProfile={!this.state.selectedCarProfileId ? true : false} select={this.select.bind(this)} />
                              </div>
                            </div>}
                          </div>
                          <div className="col-md-6 col-sm-6 right toggleBtn">
                            <div className="filterby-wrapper">
                              <div className="f-card">
                                <h5>Service Type</h5>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 pad0">
                                    <div className="checkbox-style checkbox-wrapper">
                                      <input type="checkbox" id="all" value="" checked={this.serviceType.all} onChange={this.requestTypeCheckbox.bind(this, 'all')} />
                                      <label htmlFor="all" className="custom-checkbox"></label>
                                      <label htmlFor="all">
                                        All
                                    </label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                      <input type="checkbox" id="car_service" value="" checked={this.serviceType.service} onChange={this.requestTypeCheckbox.bind(this, 'service')} />
                                      <label htmlFor="car_service" className="custom-checkbox"></label>
                                      <label htmlFor="car_service">
                                        Car Service
                                    </label>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="checkbox-style checkbox-wrapper">
                                      <input type="checkbox" id="car_wash" value="" checked={this.serviceType.wash} onChange={this.requestTypeCheckbox.bind(this, 'wash')} />
                                      <label htmlFor="car_wash" className="custom-checkbox"></label>
                                      <label htmlFor="car_wash">
                                        Car Wash
                                    </label>
                                    </div>
                                    <div className="checkbox-style checkbox-wrapper">
                                      <input type="checkbox" id="car_repair" value="" checked={this.serviceType.repair} onChange={this.requestTypeCheckbox.bind(this, 'repair')} />
                                      <label htmlFor="car_repair" className="custom-checkbox"></label>
                                      <label htmlFor="car_repair">
                                        Car Repair
                                    </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="f-card">
                                <h5>Job Priority</h5>
                                <div className="holder">
                                  <span className="pad0">Only show urgent request</span>
                                  <ToggleSwitch
                                    checked={this.state.showUrgent}
                                    size="small"
                                    onChange={() => this.setState({ showUrgent: !this.state.showUrgent })}
                                    ref={(node) => {
                                      this.toggleSwitch = node;
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 footer">
                            <a onClick={(e) => { this.clearFilterValues(e); }}>Clear</a>
                            <Button btnType="red" btnSize="sm" fontSize={15} label="Apply" btnCallBack={() => this.applyFilter()} />
                          </div>
                        </div>
                      </div>
                    </DropdownButton>
                  </div>
                </div>
                <div className="card-view-holder">
                  {this.state.activeTab == "OpenItems" && <CardView userPaymentStatus={this.state.userPaymentStatus} source={'open'} emptyStateTitle={this.state.filterApplied || this.state.previouslyFilterApplied ? "No Results found" : "Oops! Looks like you don’t have any open requests"} checkLoadMore={allOpenBookingRequestsCount < this.state.openRowCount && this.state.activeTab == 'OpenItems'} btnCallBack={() => { this.loadMore("open"); }} authReducer={authReducer} bookingRequestReducer={bookingRequestReducer} actions={this.props.actions} router={this.props.router} data={allOpenBookingRequestsCount ? allOpenBookingRequests : []} />}
                  {this.state.activeTab == "CloseItems" && <CardView userPaymentStatus={this.state.userPaymentStatus}  source={'closed'} emptyStateTitle={this.state.filterApplied || this.state.previouslyFilterApplied ? "No Results found" : "Oops! Looks like you don’t have any closed requests"} checkLoadMore={allClosedBookingRequestsCount < this.state.closedRowCount && this.state.activeTab == 'CloseItems'} btnCallBack={() => { this.loadMore("closed"); }} authReducer={authReducer} bookingRequestReducer={bookingRequestReducer} actions={this.props.actions} router={this.props.router} data={allClosedBookingRequestsCount ? allClosedBookingRequests : []} />}
                  {this.state.activeTab == "CompletedItems" && <CardView userPaymentStatus={this.state.userPaymentStatus}  source={'completed'} emptyStateTitle={this.state.filterApplied || this.state.previouslyFilterApplied ? "No Results found" : "Oops! Looks like you don’t have any closed requests"} checkLoadMore={allCompletedBookingRequestsCount < this.state.completedRowCount && this.state.activeTab == 'CompletedItems'} btnCallBack={() => { this.loadMore("completed"); }} authReducer={authReducer} bookingRequestReducer={bookingRequestReducer} actions={this.props.actions} router={this.props.router} data={allCompletedBookingRequests ? allCompletedBookingRequests : []} />}
                </div>
              </div> :
              <MyRequestEmptyState router={router} btn={(!this.state.filterApplied && (allOpenBookingRequestsCount == 0 && allClosedBookingRequestsCount == 0))} /> : <PageLoader />
            }
          </div>
        </div>

        <MobileFilter customClass={this.state.showMobileFilter ? "mobile-filter-options mobile-sort-options" : "mobile-filter-options hide"}
          hideOption={() => this.setState({ showMobileFilter: false })}
          activeTab={this.state.activeTab}
          showUrgent={this.state.showUrgent}
          requestStatus={this.requestStatus}
          checkboxVal={this.requestStatusCheckbox.bind(this)}
          serviceType={this.serviceType}
          requestTypeCheckbox={this.requestTypeCheckbox.bind(this)}
          carProfileReducer={this.props.carProfileReducer}
          selectedCarProfileId={this.state.selectedCarProfileId}
          select={this.select.bind(this)}
          showUrgentFunc={() => this.setState({ showUrgent: !this.state.showUrgent })}
          clearFilterValues={(e) => { this.clearFilterValues(e) }}
          filterSelect={this.applyFilter.bind(this)} />

        {this.state.showMobileSort && <RequestMobileSort
          type="all-request"
          back={() => { this.setState({ showMobileSort: false }) }}
          activeTab={this.state.activeTab}
          actions={actions}
          filterSort={this.state.filterSort}
          dateCreatedAsc={() => { actions.getAllBookingRequests(this.state.activeTab == "OpenItems" ? "open" : "closed", 10, 0, false, "dateNO", this.state.activeTab == "OpenItems" ? this.openRequestStatus : this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent), this.setState({ filterSort: "dateNO", sortBydropdown: false, showMobileSort: false }) }} className={this.state.filterSort == "dateNO" ? "active" : ""}
          dateCreatedDesc={() => { actions.getAllBookingRequests(this.state.activeTab == "OpenItems" ? "open" : "closed", 10, 0, false, "dateON", this.state.activeTab == "OpenItems" ? this.openRequestStatus : this.closedRequestStatus, this.serviceTypeStatus, this.state.selectedCarProfileId, this.state.showUrgent), this.setState({ filterSort: "dateON", sortBydropdown: false, showMobileSort: false }); }} className={this.state.filterSort == "dateON" ? "active" : ""} />}
        <div className="footerSection" >
          <Footer {...this.props} />
        </div>
        {this.state.searchMobile && <div >
          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
        </div>}
      </div>
    );
  }
}
