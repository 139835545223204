import React, { Component } from 'react';
import InputRange from 'react-input-range';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import Rating from 'react-rating';
import Button from '../common/Button';

class RequestMobileSort extends Component {
    render() {
        return (
            <div className="mobile-sort-options n-request-mobile-sort n-request-mobile-filter">
                <i className="mdi mdi-arrow-left" onClick={() => (this.props.back())}></i>
                <div className="f-card n-distance-range">
                    <h5>Distance</h5>
                    <InputRange
                        formatLabel={distValue => `${distValue}km`}
                        minValue={0}
                        maxValue={100}
                        value={this.props.stateVal.distValue}
                        onChange={distValue => (this.props.distValueFunc('distance', distValue))} />
                    <span className="range-min">{this.props.stateVal.distValue.min + " Km"}</span>
                    <span className="range-max">{this.props.stateVal.distValue.max + " Km"}</span>
                </div>
                <div className="f-card n-distance-range">
                    <h5>Price</h5>
                    <InputRange
                        formatLabel={priceValue => `${priceValue}AED`}
                        minValue={0}
                        maxValue={30000}
                        value={this.props.stateVal.priceValue}
                        onChange={priceValue => (this.props.distValueFunc('price', priceValue))} />
                    <span className="range-min">{this.props.stateVal.priceValue.min + " AED"}</span>
                    <span className="range-max">{this.props.stateVal.priceValue.max + " AED"}</span>
                </div>
                <div className="f-card openAlign n-time-range">
                    <h5>Open Between</h5>
                    <ul className="list-unstyled">
                        <li className={this.props.stateVal.daySelected["sunday"] ? 'active' : ''} onClick={() => (this.props.day("sunday", 0))}>SUN</li>
                        <li className={this.props.stateVal.daySelected["monday"] ? 'active' : ''} onClick={() => (this.props.day("monday", 1))}>MON</li>
                        <li className={this.props.stateVal.daySelected["tuesday"] ? 'active' : ''} onClick={() => (this.props.day("tuesday", 2))}>TUE</li>
                        <li className={this.props.stateVal.daySelected["wednesday"] ? 'active' : ''} onClick={() => (this.props.day("wednesday", 3))}>wed</li>
                        <li className={this.props.stateVal.daySelected["thrusday"] ? 'active' : ''} onClick={() => (this.props.day("thrusday", 4))}>thu</li>
                        <li className={this.props.stateVal.daySelected["friday"] ? 'active' : ''} onClick={() => (this.props.day("friday", 5))}>fri</li>
                        <li className={this.props.stateVal.daySelected["saturday"] ? 'active' : ''} onClick={() => (this.props.day("saturday", 6))}>sat</li>
                    </ul>
                    <div className="row date-time model-select date-time-align">
                        <span className="n-time-from">
                            <TimePicker
                                showSecond={false}
                                className="custom-timepicker"
                                popupClassName="mobile-tp"
                                format={'h:mm a'}
                                value={this.props.stateVal.rangeFromDefault}
                                onChange={(time) => (this.props.rangefrom(time, "menu"))}
                                defaultValue={moment('2016-12-19T00:00:00+05:30')}
                                use12Hours
                            />
                            <i className="mdi mdi-chevron-down time-to" />
                        </span>
                        <span className="time-to-time n-time-to">to</span>

                        <span className="n-time-to">
                            <TimePicker
                                showSecond={false}
                                className="custom-timepicker"
                                format={'h:mm a'}
                                popupClassName="mobile-tp"
                                onChange={(time) => (this.props.rangeTo(time, "menu"))}
                                value={this.props.stateVal.rangeToDefault}
                                defaultValue={moment('2016-12-19T23:59:00+05:30')}
                                use12Hours
                            />
                            <i className="mdi mdi-chevron-down time-to" />
                        </span>
                    </div>
                    {/* </div> */}
                </div>
                <div className="f-card toggleBtn">
                    <h5>Open 24/7</h5>
                    <ToggleSwitch
                        checked={this.props.toggleSwitchVal.Open24_7}
                        size="small"

                        onChange={() => (this.props.switch('Open24_7'))}
                        ref={(node) => {
                            this.toggleSwitch = node;
                        }} />
                </div>
                <div className="f-card star-rating">
                    <h5>Rating</h5>
                    <Rating
                        empty="mdi mdi-star-outline "
                        full="mdi mdi-star active-star"
                        initialRate={this.props.stateVal.ratingValue}
                        onChange={(e) => (this.props.ratingOnChange(e))}
                    />
                </div>
                <div className="f-card toggleBtn">
                    <h5>Only show Favourites</h5>
                    <ToggleSwitch
                        checked={this.props.toggleSwitchVal.showFavourites}
                        size="small"
                        onChange={() => (this.props.switch('showFavourites'))}
                        ref={(node) => {
                            this.toggleSwitch = node;
                        }}
                    />
                </div>
                <div className="f-card payment-type toggleBtn">
                    <h5>Payment by</h5>
                    <div className="holder">
                        <span className="pad0">Pay by Cash</span>
                        <ToggleSwitch
                            checked={this.props.toggleSwitchVal.byCash}
                            size="small"
                            onChange={() => (this.props.switch('byCash'))}
                            ref={(node) => {
                                this.toggleSwitch = node;
                            }}
                        />
                    </div>
                    <div className="holder">
                        <span className="">Pay by Credit Card</span>
                        <ToggleSwitch
                            checked={this.props.toggleSwitchVal.byCreditcard}
                            size="small"
                            onChange={() => (this.props.switch('byCreditcard'))}
                            ref={(node) => {
                                this.toggleSwitch = node;
                            }}
                        />
                    </div>
                </div>
                <div className="filter-footer footer">
                    <a onClick={() => (this.props.clearFilter(this.props.jobId))}>Clear</a>
                    <Button btnType="red" btnSize="sm" fontSize={15} label="Apply" btnCallBack={() => (this.props.filterSelect(this.props.jobId))} />
                </div>
            </div>
        );
    }
}

export default RequestMobileSort;
