import React, { Component } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import Gmaps from '../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import { Modal, FormGroup, FormControl } from 'react-bootstrap';
import map  from 'lodash/map';
import difference  from 'lodash/difference';
import SelectDropdown from '../common/SelectDropdown';
var uniqid = require('uniqid');
import LocationSearchBox from '../common/LocationSearchBox';
import TimePicker from 'rc-time-picker';
import PageLoader from '../common/PageLoader';
import ServiceTypeEmptyState from '../EmptyState/ServiceTypeEmptyState';
import { imageBasePath, appBasePath } from '../../constants/staticData';


class WashSteps extends Component {
  constructor(props) {
    super(props);
    let momentdate = moment().add(1,'hours');
    let momentPicker = new Date(momentdate._d);

    this.state = {
      step1Active: false,
      step2Active: null,
      PrefferedLocation: 'Select Location',
      selectedDate: moment().add(1, 'hours'),
      heating: false,
      step1Panel: true,
      step2Panel: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      visibleCategory: undefined,
      now: moment().add(1, 'hours'),
      dateValue: momentPicker.getDate(),
      monthValue: momentPicker.getMonth(),
      yearValue: momentPicker.getFullYear(),
      specialInstruction: '',
      hoursValue: moment().add(1, 'hours').hours(),
      minutesValue: moment().minutes(),
      selectedCarProfileId: '',
      submissionError: false,
      selectedLocation: '',
      searchValue: '',
      currentTime: '',
      selectedTimeValue: '',
      isButtonLoading: false,
      promoCodesModal: false,
      selectedPromoCode: '',
      isPromoCodeUpdated: false,
      promotionalOffersIsApproved: false,
      popUpPromoCode: '',
      promoPopupError:0
    };
    this.formData = {
      selectedLocation: '',
      selectedCarProfileId: this.state.selectedCarProfileId,
      timeValid: moment().add(1, 'hours')
    };
    this.errors = {
      selectedLocation: false,
      selectedCarProfileId: false,
      timeValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitPromoCodeData = this.submitPromoCodeData.bind(this);
    this.updateRadioButton = this.updateRadioButton.bind(this);

  }

  componentWillReceiveProps(nextProps) {



    if(! this.state.isPromoCodeUpdated){
      let selectedPromotionalOffersData = this.props && this.props.selectedPromotionalOffersData ? this.props.selectedPromotionalOffersData : null;

      if (selectedPromotionalOffersData &&  selectedPromotionalOffersData.promoCode){

        this.setState({ selectedPromoCode: selectedPromotionalOffersData.promoCode,
          promotionalOffersMessage:'Successfully applied',
          promotionalOffersIsApproved:true
        });


      }
    }


    const { selectedCarWashDataArray } = this.props;

    console.log("selectedCarWashDataArray"+selectedCarWashDataArray);

    this.setState({ selectedCarWashDataArray: selectedCarWashDataArray});



    if(nextProps.commonReducer && nextProps.commonReducer.promotionalOffersMessage && this.state.selectedPromoCode && this.state.isPromoCodeUpdated ){

      this.setState({
        promotionalOffersMessage:nextProps.commonReducer.promotionalOffersMessage,
        promotionalOffersIsApproved:nextProps.commonReducer.promotionalOffersIsApproved });
    }

    console.log("nextProps.commonReducer.promotionalOffersMessage"+nextProps.commonReducer.promotionalOffersMessage);






    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false, isButtonLoading: false, });
    } else if (nextProps && nextProps.showError) {
      this.setState({ isButtonLoading: false, });
    }



  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id
    });
    this.props.changeCarProfile(carProfile);
    this.errors['selectedCarProfileId'] = false;
    this.formData['selectedCarProfileId'] = true;
  }

  createProfile(event) {
    if (event.target.value === "createProfile") {
      this.props.router.push(`${appBasePath}/car-profiles/create`);
    }
  }

  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }

  hidePanel(panel) {
    this.props.disableEdit();
    if (panel == 'step1') {
      this.setState({ step1Panel: !this.state.step1Panel, step2Panel: false });
    } else if (panel == 'step2') {
      this.setState({ step1Panel: false, step2Panel: !this.state.step2Panel, step1Active: true, step2Active:true });
    }
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      'selectedCarCategoryForModel': categoryDetails,
      'catDescriptionModalVisible': true
    });
  }

  specialIns(data) {
    if (data.target.value.length) {
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
    else {
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  requestQuotes() {

    const { actions } = this.props;


    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true, });
      return;
    } else {
      if(this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng){
        this.setState({ submissionError: false, isButtonLoading: true, step2Active: true });
        let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
        let momentTimeStamp = moment.utc(timeStamp).format();
        let payload;
        if (this.props.garageProfileDetails) {
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "vendorid": this.props.garageProfileDetails.id,
            "subserviceid": this.state.selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        } else {
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "subserviceid": this.state.selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        }

        if(this.state.promotionalOffersIsApproved){
          payload.promo_code = this.state.selectedPromoCode;
        }


       // console.log("payload"+JSON.stringify(payload));

        actions.createBookingRequest(payload);
      }
    }
  }

  timePicker(value) {
    if (value && value.hours() && value.minutes()) {
      if (moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue) {
        if (value.startOf('minutes') < moment().add(1, 'hours').startOf('minutes')) {
          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        } else {
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        }
      } else {
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
      }
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if (moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()) {
      if (this.state.selectedTimeValue < moment().add(1, 'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });
      } else {
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
  }
  submitPromoCodeData(isFromPopUp){


    let selectedPromoCode = this.state.selectedPromoCode;

    this.setState({
      isPromoCodeUpdated: true,
    });

    if(isFromPopUp){
      selectedPromoCode =  this.state.popUpPromoCode;
      if(selectedPromoCode)
        this.setState({ promoCodesModal: false, selectedPromoCode:selectedPromoCode});

    }

    if(selectedPromoCode){
      var promotionData = {
        place: 0,
        services: this.state.selectedCarWashDataArray,
        promo_code: this.state.selectedPromoCode,
        brand: this.props.selectedCarProfile.carmodel.carmakeId,
      }

      this.props.actions.applyPromotionalOffers(promotionData);

    }


  }



  updateRadioButton(event) {


    var promotionalOffersData =  this.props.promotionalOffersData;

    var name = promotionalOffersData[event.target.value].promoCode;

    this.setState({ popUpPromoCode: name});


  }



  render() {
    console.log('🔥 🔥 step2Panel -- ',this.state.step2Panel);
    console.log('🔥 🔥 🔥 categorisSelected -- ',this.props.categoriesSelectedCount);
    const {router} = this.props;

    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
        boxShadow: '0 0 4px 0 rgba(134,134,134,0.5)',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      },
      promoButtonDiv: {
        padding: '0px'
      },

    };
    // console.log("formData",this.formData)
    // console.log("errorData",this.errors)
    // const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails } = this.props;
    const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails, promotionalOffersData } = this.props;
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];


    //Filter the offers based on the Services that was choosen

    const validPromoCode = promotionalOffersData.filter((promoCode) => {
      const serviceIds = map(promoCode.services, 'serviceId');
      return difference(this.props.selectedCarWashDataArray, serviceIds).length === 0;
    });

    console.log('validPromoCode ',validPromoCode);


    let promoCodesHtml = validPromoCode.map((promoCode, index) => {


      var validPromo = false;

      promoCode.services.map((service) => {

        if(service.parentId == 1){
          validPromo = true;
        }

      });
      if (! validPromo){
        return;
      }



      var radioId = "radio-"+promoCode.id;
      return (<div className="form-check promo-item" key={promoCode.id}>
          <input id={radioId}  className="radio-custom"  onClick={ e => this.updateRadioButton(e) }   value={index} name="radio-group" type="radio"/>
          <label htmlFor={radioId} className="radio-custom-label">{promoCode.promoCode}</label>
          <p>
            {promoCode.description}
          </p>

          <a
            onClick={() => {

             // let route = this.$router.resolve({path: '/offer-terms'});
              // let route = this.$router.resolve('/link/to/page'); // This also works.
              window.open('/offer-terms/'+promoCode.id, '_blank');





              /* router.push({
                 pathname: appBasePath + '/offer-terms',
                 state: { promotionalOffersTerms: promoCode.termsAndConditions }
               });*/


            }}
          >*Terms and Conditions Apply</a>
        </div>

      );

    });


    //console.log("getPromotionalOffersData"+ JSON.stringify(promotionalOffersData));

    return (
      <div className="panel-section car-wash car-category-wrapper p-panel">
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => { this.hidePanel('step1'); }}>
            <span className={this.state.step1Active ? "n-step active" : "n-step"}>1</span> <h4>Select Car Wash Type</h4>
            <i className={this.state.step1Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {servicesFetched ? this.state.step1Panel && <div className="panel-content">
            <div className="row">
              <div className="col-md-9 pad0">
                <div className="search-box">
                  <TextInput value={this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => {
                    this.setState({
                      ...this.state,
                      searchValue: e
                    });
                    this.props.searchView(e);
                  }} />
                  <i className="mdi mdi-magnify" />
                </div>
              </div>
            </div>
            {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState/>
            }
            {this.props.viewBlock.length > 0 && <div className="next-button">
              <Button disabled={categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => { this.hidePanel('step2'); }} />
            </div>}
          </div> :
            <PageLoader />}
        </section>
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => { categoriesSelectedCount > 0 && this.hidePanel('step2'); }}>
            <span className={this.state.step2Active==null? "n-step inactive" :"n-step"}>2</span><h4>Create A Car Wash Request</h4>
            <i className={this.state.step2Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {this.state.step2Panel && categoriesSelectedCount > 0 &&
          <div className="panel-content">
            <div className="row">
              <div className="col-md-12 pad0">
                <div className="form-section n-wash-sec">
                  <h4 className="panel-sub-title">Select Car Profile</h4>
                  <div className={this.errors.selectedCarProfileId && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                    <SelectDropdown data={carProfiles} selectedCarProfile={selectedCarProfile && selectedCarProfile} select={this.select.bind(this)} />
                    {this.errors.selectedCarProfileId && this.state.submissionError && <span className="error-text">Select Car Profile</span>}
                  </div>
                </div>
                <div className="form-section">
                  <h4 className="panel-sub-title">Prefered Time & Date</h4>
                  <div className="row date-time model-select date-time-align">
                    <div className="col-md-6 padLeft0 timeDate_grid">
                      <DatePicker
                        selected={this.state.selectedDate}
                        onChange={this.handleChange}
                        minDate={moment().add(1, 'hours')}
                      />
                    </div>
                    <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 form-group timeDate_grid error": "col-md-6 padRight0 form-group timeDate_grid"}>
                      {/* <TimeInput value={this.state.TimePickerFrom} onChange={(e) => {this.timePicker(e); this.setState({ TimePickerFrom: e })}} /> */}
                      <TimePicker
                        showSecond={false}
                        className="custom-timepicker"
                        format={'h:mm a'}
                        //disabledHours= {this.disabledHours.bind(this)}
                        //disabledMinutes= {this.disabledMinutes.bind(this)}
                        onChange={this.timePicker.bind(this)}
                        defaultValue={this.state.now}
                        use12Hours
                      />
                      {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                        </div>
                        </div>
                        </div>
                        <div className="form-section">
                        <h4 className="panel-sub-title">Prefered location</h4>
                        <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                        <div className="location-dd header-search ws-form-section">
                        {/* <LocationDropdown /> */}
                        <LocationSearchBox placeholder="Select Location" style={style} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined}/>
                        </div>
                      {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                        </div>
                        {this.state.selectedLocation && <div className="map-panel">
                          <div className="gmaps">
                            <Gmaps
                              center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                              zoom={12}
                              setCenter={true}
                              markers={markerLocation}
                              containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                              mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                            />
                          </div>
                        </div>}
                        </div>
                        </div>
                        <div className="col-md-12 pad0">
                        <h4 className="panel-sub-title">Special Instruction</h4>
                        <p className="panel-text">
                        <FormGroup>
                        <FormControl
                        className="textAlign"
                        componentClass="textarea"
                        placeholder="Instructions text goes here"
                        onChange={(e) => { this.specialIns(e); }}
                        />
                        </FormGroup>
                        </p>
                        </div>
                       {/* { this.state.selectedPromoCodeName?*/}

                          <div className="col-md-12 pad0 promo-code-div">
                            <h4 className="panel-sub-title">Promo Code</h4>
                            <p className="panel-text">
                              <div className="row">
                                <FormGroup bsClass="col-md-9 form-group bottom-zero padding-zero ">
                                  <FormControl
                                    readOnly={this.state.promotionalOffersIsApproved}
                                    className="textAlign"
                                    componentClass="textarea"
                                    placeholder="Add Promo Code"
                                    onChange={(e) => { this.setState({ selectedPromoCode: e.target.value }) }}
                                    value = { this.state.selectedPromoCode }
                                  />
                                </FormGroup>

                                {this.state.promotionalOffersIsApproved?
                                  <div className="col-md-3" style={style.promoButtonDiv}  onClick={() => {

                                    this.setState({
                                      promotionalOffersMessage:"",
                                      promotionalOffersIsApproved:false });


                                  }}>
                                    <Button btnType="transparent" customClass="promo-button" btnSize="sm" fontSize={15}
                                            label="Remove"/>
                                  </div> :
                                  <div className="col-md-3" style={style.promoButtonDiv}  onClick={() => {
                                    this.submitPromoCodeData(false);
                                  }}>
                                    <Button btnType="transparent" customClass="promo-button" btnSize="sm" fontSize={15}
                                            label="Apply"/>
                                  </div>
                                }


                              </div>
                              <div className="promo-page-error">
                                { this.state.promoPopupError?"":  this.state.promotionalOffersMessage }
                              </div>

                          {
                            <div className="promo-label"  onClick={() => this.setState({ promoCodesModal: true, popUpPromoCode: ""  }) }>View Valid Promo Codes </div>
                            }
                            </p>

                          </div>
                         {/* : ""
                        }*/}
                        </div>
                        <div className="next-button clearfix">
                        <Button btnType="red" btnSize="lg" fontSize={14} label="Request For Quotes" btnCallBack={this.requestQuotes.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                        </div>
                        </div>}
                    </section>
                    <CustomModal showModal={this.state.catDescriptionModalVisible} title={this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.name} closeIcon={true} hideModal={() => { this.setState({ 'catDescriptionModalVisible': false }) }}>
                      <Modal.Body>
                        <p className="info-text">{this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.description}</p>
                      </Modal.Body>
                    </CustomModal>

                    <CustomModal showModal={this.state.promoCodesModal} footer={validPromoCode.length > 0? true: false} cancelText="Cancel" saveText="Apply"
                                 title="Add Promocode"
                                 submitCallBack ={() => { this.submitPromoCodeData(true);  }}
                                 closeIcon={true}  hideModal={() => { this.setState({ promoCodesModal: false }) }}>
                      <Modal.Body>
                        <div className="emgcy-modal">


                          <div className="holder">



                            {validPromoCode.length > 0?  <form> {promoCodesHtml}  </form> :  <div className="promo-empty"> <img src={'/images/offers_empty.png'} alt='' /> <p> Oops! Looks like you don't have any Promo Offers. </p>  </div> }



                          </div>

                        </div>
                      </Modal.Body>
                    </CustomModal>
                  </div>
                  );
                  }
          }

          export default WashSteps;
