import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import moment from "moment";
import Button from "../common/Button";
import Arrow from "../common/Arrow";

export default class OfferCard extends Component {

  constructor(props, context) {
    super(props, context);


  }
  componentDidMount() { /*setTimeout(() => {window.dispatchEvent(new Event('resize')) }, 6000);*/ }



  onOfferClick(parentId,index,showPromoCode){



    let url =  showPromoCode? "all-offers" : parentId == 1 ? 'car-wash': parentId == 2 ? 'car-service': 'car-repair';

    if(showPromoCode) {

     // window.location.href = this.props.isAuthenticated? appBasePath + "/offers/"+this.props.promotionalOffersData[index].id : appBasePath + "/"+url+"/"+this.props.promotionalOffersData[index].id;


     // alert(showPromoCode);
      this.props.router.push({
          pathname: this.props.isAuthenticated? appBasePath + "/offers/"+this.props.promotionalOffersData[index].id : appBasePath + "/"+url+"/"+this.props.promotionalOffersData[index].id,
          state: {selectedPromotionalOffersData: this.props.promotionalOffersData[index]},
        }
      );
    }else {
      this.props.router.push({
          pathname: appBasePath + url,
          state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
        }
      );
    }

  }


  render() {


    const { promotionalOffersData , router, title , authData, showPromoCode } = this.props;


    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      responsive: [
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 900,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 1067,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 1400,
          settings: { slidesToShow: 4 }
        },
      ],
      // arrows:true
    };



    let promoCodesHtml =  promotionalOffersData.map((offer, index) => {

      // let redirectPath =   showPromoCode && this.props.isAuthenticated ? appBasePath + offer.services[0].parentId == 1 ? 'car-wash': offer.services[0].parentId == 2 ? 'car-service': 'car-repair':  appBasePath+"/all-offers/"+offer.id+"/book-now" ;



      let redirectPathSearch = "";
      if(authData && !authData.hasVehicleProfile){
        redirectPathSearch = "?redirect_to="+redirectPath;
        redirectPath = "/car-profiles/create";
      }


      let days = moment(offer.validTo).diff(moment(new Date()),'days');
      return (
        <div className="col-md-3 col-sm-6 col-xs-6 slider-offer" key={index} >
          <div className={offer && offer.discountPercentage &&  offer.discountPercentage == 100 ? "card free-card " : "card "}>
            <figure  onClick={() => {  this.onOfferClick(offer.services[0].parentId,index,showPromoCode) }}>
              <img className="offer-cover" src={ offer.images && offer.images.length ?  offer.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="BMW car repair" />

              {offer && offer.discountPercentage ?
                offer.discountPercentage == 100 ?
                  <div className=" price free-price col-md-6 col-sm-6 col-xs-6">

                    <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                    <div className="price-content">

                      <span className="amount">Free</span>
                    </div>
                  </div>
                  :
                  <div className=" price col-md-4 col-sm-4 col-xs-4">

                    <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                    <div className="price-content">

                      <span className="amount">{offer && offer.discountPercentage }%</span>
                      <span className="type">OFF</span>
                    </div>
                  </div>
                :
                <div className=" price col-md-4 col-sm-4 col-xs-4">

                  <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                  <div className="price-content discount-amount-content">

                    <span className="discount-amount">{offer && Number(offer.discountAmount).toFixed(2)}</span>
                    <span className="discount-currency">AED </span>
                    <span className="discount-type">OFF</span>
                  </div>
                </div>

              }
            </figure>

            <div className="description col-md-12 col-sm-12 col-xs-12">

              <div  onClick={() => {   this.onOfferClick(offer.services[0].parentId,index,showPromoCode)  }}>

                <span className="garage">{offer.garages.length ? "Service Providers (" + (offer.garages.length) + ")" : ""}</span>
                <span className="title">{offer.offerName}</span>
                <span className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon.png` }  /> {days} Days Left</span>
              </div>
              {offer.totalAmount ?
                <div className="offer-price" onClick={() => {
                  this.onOfferClick(offer.services[0].parentId,index,showPromoCode)
                }}>

                  {offer && offer.discountPercentage ?
                    offer.discountPercentage == 100 ?
                      <span className="amount">Free</span>
                      :
                      <span className="amount">{Number(offer.discountedAmount).toFixed(2)} AED</span>
                    :
                    <span className="amount">{Number(offer.discountedAmount).toFixed(2)} AED</span>
                  }
                  <span className="original">{Number(offer.totalAmount).toFixed(2)} AED</span>
                </div>
                :
                <div className="offer-price" onClick={() => {
                  this.onOfferClick(offer.services[0].parentId,index,showPromoCode)
                }}>
                </div>
              }


              <Button btnType="transparent" fontSize={16} label="Book Now" btnCallBack={() =>

                router.push({
                    pathname: redirectPath,
                    search: redirectPathSearch,
                    state: { selectedPromotionalOffersData: offer }
                  }
                )
              } />



            </div>

          </div>
        </div>
      );
    });



    return (
      <div className="row" >
        <div className="" id="deals-section">
          <div className="offer-heading">
            <h5>{title}</h5>
          </div>
          <div className="col-md-12 offer-main ">
            {promoCodesHtml ?
              <Slider ref="slick" {...settings} >
                {promoCodesHtml}
              </Slider>
            :""}
          </div>
        </div>
      </div>
    );
  }
}
