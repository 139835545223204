import React, { Component } from 'react';
import Button from '../common/Button';
import {appBasePath, imageBasePath} from '../../constants/staticData';
import Header from "../common/Header";
import MobileNotification from "../common/MobileNotification";
import MobileMessage from "../common/MobileMessage";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import MobileHeaderSearch from "../common/MobileHeaderSearch";

export default class PaymentDeclined extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false
    };
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  componentDidMount() {
    console.log("requestId")


    const requestId = localStorage.getItem('requestId') ? localStorage.getItem('requestId') : undefined;
    const orderRef = localStorage.getItem('orderRef') ? localStorage.getItem('orderRef') : undefined;

    if(requestId && orderRef){

      this.props.actions.paymentStatus(orderRef, requestId);
    }else{
      this.props.router.push(appBasePath + '/request/' + this.props.router.params.requestId)
    }




  }
  render() {
    const { commonReducer, actions, router, bookingRequestReducer } = this.props;
    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})}  bookingRequestReducer={this.props.bookingRequestReducer}  commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Payment Status</h4>
            </div>
          </div>
          <div className="inSection">

            <div className="empty-state-holder payment-state-holder">
                <span className="empty-state-content">
                   <img src={`${imageBasePath}/images/payment-failed.png`} alt=""/>
                    <span className="paymentHeading">Payment Declined!</span>
                    <span className="sub-text">Please retry the payment</span>
                    <Button btnCallBack={() => {  router.push(appBasePath + '/request/' + this.props.router.params.requestId) }} btnType="red" fontSize={15} label="Retry" />

                </span>
            </div>



          </div>
        </div>
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
