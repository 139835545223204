import React from "react";
import { Route, IndexRoute } from "react-router";
import axios from "axios";

// Containers
import DashboardContainer from "./containers/DashboardContainer";
import AuthContainer from "./containers/AuthContainer";
import UseraccountContainer from "./containers/UseraccountContainer";
import CarProfileContainer from "./containers/CarProfileContainer";
import BookingRequestContainer from "./containers/BookingRequestContainer";
/*
import TagManager from 'react-gtm-module';
*/



// Auth Components
import SignIn from "./components/Auth/SignIn";
import SignUp from "./components/Auth/SignUp";
import VerifyOTP from "./components/Auth/VerifyOTP";
import SendOTP from "./components/Auth/SendOTP";
import ForgotPassword from "./components/Auth/ForgotPassword";
import EditMobileNo from "./components/Auth/EditMobileNo";
import ResetPassword from "./components/Auth/ResetPassword";
import Confirmed from "./components/Auth/Confirmed";
import ResetEmailConfirmation from "./components/Auth/ResetEmailConfirmation";
import EmailVerified from "./components/Auth/EmailVerified";
import { packageJson, serviceTypes, baseLandingUrl } from "./constants/staticData";
import { store } from "./index";



// Dashboard Components
export const isLoggedIn = (nextState, replace) => {
  if (localStorage.accessToken) {
    axios.defaults.headers.common["x-access-token"] = localStorage.accessToken;
    axios.defaults.headers.common["app-version"] = packageJson.appversion;
  }
  window.scrollTo(0, 0);
  const userId = localStorage.getItem("userId");
  let authData = localStorage.getItem("authData");
  authData = authData ? JSON.parse(authData) : "";
  if (authData && authData.type === "business") {
    // window.location.pathname = '/business';
    replace({
      pathname: "/sign-in"
    });


    // console.log("baseLandingUrl 1")

    // window.location.href = baseLandingUrl+"/sign-in"
  } else if (
    !(userId && authData && authData.phone && authData.phoneVerified)
  ) {


    console.log("baseLandingUrl 2",userId)
    console.log("baseLandingUrl 2",authData.phone)
    console.log("baseLandingUrl 2",authData.phoneVerified)
    console.log("baseLandingUrl 2",authData)

    /*
    console.log("baseLandingUrl 2")

    window.location.href = baseLandingUrl+"/sign-in"*/
    replace({
      pathname: "/sign-in"
    });

    store.dispatch({
      type: "REDIRECT_URI",
      payload: nextState.location.pathname+nextState.location.search
    });
  } else {
    window.axiosInterceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      function(error) {
        console.log("baseLandingUrl 3")
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = baseLandingUrl+"/sign-in"
        }
        return Promise.reject(error);
      }
    );
  }
};
export const scrollToTop = () => {
  if (localStorage.accessToken) {
    axios.defaults.headers.common["x-access-token"] = localStorage.accessToken;
  }
  window.scrollTo(0, 0);
};
//

import Loadable from 'react-loadable';

function Loading({ error }) {
  if (error) {
    return 'Oh nooess!';
  } else {
    return <h3></h3>;
  }
}


{/*const Landing = Loadable({
  loader: () => import('./components/LandingNew/Landing'),
  loading: Loading
});
const Dashboard = Loadable({
  loader: () => import('./components/Dashboard'),
  loading: Loading
});
const Home = Loadable({
  loader: () => import('./components/Home'),
  loading: Loading
});

const MyRequest = Loadable({
  loader: () => import('./components/MyRequest/MyRequest'),
  loading: Loading
});

const CreateCarProfile = Loadable({
  loader: () => import('./components/CarProfile/CreateCarProfile'),
  loading: Loading
});
const BookService = Loadable({
  loader: () => import('./components/BookService/BookService'),
  loading: Loading
});
const CarTimeline = Loadable({
  loader: () => import('./components/CarTimeline/CarTimeline'),
  loading: Loading
});
const CarRepair = Loadable({
  loader: () => import('./components/CarRepair/CarRepair'),
  loading: Loading
});
const CarWash = Loadable({
  loader: () => import('./components/CarWash/CarWash'),
  loading: Loading
});
const EditCarWash = Loadable({
  loader: () => import('./components/CarWash/EditCarWash'),
  loading: Loading
});
const CarService = Loadable({
  loader: () => import('./components/CarService/CarService'),
  loading: Loading
});
const OfferTerms = Loadable({
  loader: () => import('./components/CarWash/OfferTerms'),
  loading: Loading
});
const Messages = Loadable({
  loader: () => import('./components/Messages/Messages'),
  loading: Loading
});
const SearchResult = Loadable({
  loader: () => import('./components/SearchResult/SearchResult'),
  loading: Loading
});
const Notification = Loadable({
  loader: () => import('./components/Notification/Notification'),
  loading: Loading
});
const Favourites = Loadable({
  loader: () => import('./components/Favourites/Favourites'),
  loading: Loading
});
const VendorProfile = Loadable({
  loader: () => import('./components/VendorProfile/VendorProfile'),
  loading: Loading
});
const Terms = Loadable({
  loader: () => import('./components/Terms/Terms.jsx'),
  loading: Loading
});
const Policy = Loadable({
  loader: () => import('./components/Policy/Policy.jsx'),
  loading: Loading
});
const UserAccounts = Loadable({
  loader: () => import('./components/UserAccounts/UserAccounts.jsx'),
  loading: Loading
});
const pageNotFound = Loadable({
  loader: () => import('./components/pageNotFound/pageNotFound'),
  loading: Loading
});
const MyRequestCard = Loadable({
  loader: () => import('./components/RequestCard/MyRequestCard'),
  loading: Loading
});
const PaymentAuthorised = Loadable({
  loader: () => import('./components/Payment/PaymentAuthorised'),
  loading: Loading
});
const PaymentDeclined = Loadable({
  loader: () => import('./components/Payment/PaymentDeclined'),
  loading: Loading
});
const PaymentCancelled = Loadable({
  loader: () => import('./components/Payment/PaymentCancelled'),
  loading: Loading
});
const Emergency = Loadable({
  loader: () => import('./components/Emergency/Emergency'),
  loading: Loading
});
const EmergencyRequest = Loadable({
  loader: () => import('./components/Emergency/MyRequest'),
  loading: Loading
});
const EmergencyResponses = Loadable({
  loader: () => import('./components/EmergencyResponses/EmergencyResponses'),
  loading: Loading
});
const Preview = Loadable({
  loader: () => import('./components/EmergencyResponses/preview'),
  loading: Loading
});
const CarProfileEmptyState = Loadable({
  loader: () => import('./components/EmptyState/CarProfileEmptyState.jsx'),
  loading: Loading
});
const EditCarRepair = Loadable({
  loader: () => import('./components/CarRepair/EditCarRepair'),
  loading: Loading
});
const EditCarService = Loadable({
  loader: () => import('./components/CarService/EditCarService'),
  loading: Loading
});
const AuthLanding = Loadable({
  loader: () => import('./components/common/AuthLanding'),
  loading: Loading
});
const Faq = Loadable({
  loader: () => import('./components/Faq/Faq'),
  loading: Loading
});
const AboutUs = Loadable({
  loader: () => import('./components/AboutUs/AboutUs'),
  loading: Loading
});
const ContactUs = Loadable({
  loader: () => import('./components/ContactUs/ContactUs'),
  loading: Loading
});
const GuestUserFlow = Loadable({
  loader: () => import('./components/GuestUserFlow/GuestUserFlow'),
  loading: Loading
});
const OfferAll = Loadable({
  loader: () => import('./components/Offers/OfferAll'),
  loading: Loading
});
const SingleOffer = Loadable({
  loader: () => import('./components/Offers/SingleOffer'),
  loading: Loading
});
const OfferList = Loadable({
  loader: () => import('./components/Offers/OfferList'),
  loading: Loading
});
const OfferDetail = Loadable({
  loader: () => import('./components/Offers/OfferDetail'),
  loading: Loading
});
const QuickBooking = Loadable({
  loader: () => import('./components/QuickBooking/QuickBooking'),
  loading: Loading
});
const QuickCarWash = Loadable({
  loader: () => import('./components/QuickBooking/CarWash/QuickCarWash'),
  loading: Loading
});
const QuickCarRepair = Loadable({
  loader: () => import('./components/QuickBooking/CarRepair/QuickCarRepair'),
  loading: Loading
});
const QuickCarService = Loadable({
  loader: () => import('./components/QuickBooking/CarService/QuickCarService'),
  loading: Loading
});
const QuickBookingCarService = Loadable({
  loader: () => import('./components/QuickBooking/QuickBookingCarService'),
  loading: Loading
});
const QuickBookingCarWash = Loadable({
  loader: () => import('./components/QuickBooking/QuickBookingCarWash'),
  loading: Loading
});
const QuickBookingCarRepair = Loadable({
  loader: () => import('./components/QuickBooking/QuickBookingCarRepair'),
  loading: Loading
});
const QuickBookingLanding = Loadable({
  loader: () => import('./components/QuickBooking/QuickBookingLanding'),
  loading: Loading
});
// import BlogRedirection from './components/BlogRedirection';
// import OfferIframe from './components/Offers/OfferIframe';
const OfferIframe = Loadable({
  loader: () => import('./components/Offers/OfferIframe'),
  loading: Loading
});
const BlogRedirection = Loadable({
  loader: () => import('./components/BlogRedirection'),
  loading: Loading
});
*/}
import ChasisNumberPopup from "./components/common/ChasisNumberPopup";


import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import MyRequest from "./components/MyRequest/MyRequest";
import CreateCarProfile from "./components/CarProfile/CreateCarProfile";
import BookService from "./components/BookService/BookService";
import CarTimeline from "./components/CarTimeline/CarTimeline";
import CarRepair from "./components/CarRepair/CarRepair";
import CarWash from "./components/CarWash/CarWash";
import EditCarWash from "./components/CarWash/EditCarWash";
import CarService from "./components/CarService/CarService";
import OfferTerms from "./components/CarWash/OfferTerms";
import Messages from "./components/Messages/Messages";
import SearchResult from "./components/SearchResult/SearchResult";
import Notification from "./components/Notification/Notification";
import Favourites from "./components/Favourites/Favourites";
import VendorProfile from "./components/VendorProfile/VendorProfile";
import Terms from "./components/Terms/Terms.jsx";
import Policy from "./components/Policy/Policy.jsx";
import UserAccounts from "./components/UserAccounts/UserAccounts.jsx";
import pageNotFound from "./components/pageNotFound/pageNotFound";
import MyRequestCard from "./components/RequestCard/MyRequestCard";
import PaymentAuthorised from "./components/Payment/PaymentAuthorised";
import PaymentDeclined from "./components/Payment/PaymentDeclined";
import PaymentCancelled from "./components/Payment/PaymentCancelled";
import Emergency from "./components/Emergency/Emergency";
import EmergencyRequest from "./components/Emergency/MyRequest";
import EmergencyResponses from "./components/EmergencyResponses/EmergencyResponses";
import Preview from "./components/EmergencyResponses/preview";
import CarProfileEmptyState from "./components/EmptyState/CarProfileEmptyState.jsx";
import Landing from "./components/LandingNew/Landing";
import OldLanding from "./components/Landing/Landing";
// import AnniversaryLanding from "./components/Landing/AnniversaryLanding";
import EditCarRepair from "./components/CarRepair/EditCarRepair";
import EditCarService from "./components/CarService/EditCarService";
import AuthLanding from "./components/common/AuthLanding";
import Faq from "./components/Faq/Faq";
import AboutUs from "./components/AboutUs/AboutUs";


import BlogRedirection from "./components/BlogRedirection";

import ContactUs from "./components/ContactUs/ContactUs";
import GuestUserFlow from "./components/GuestUserFlow/GuestUserFlow";
import OfferAll from "./components/Offers/OfferAll";
import OfferList from "./components/Offers/OfferList";
import OfferDetail from "./components/Offers/OfferDetail";
import OfferIframe from "./components/Offers/OfferIframe";


import SingleOffer from "./components/Offers/SingleOffer";
import AllOfferBooking from "./components/Offers/AllOfferBooking";

import QuickBooking from "./components/QuickBooking/QuickBooking";
import QuickCarWash from "./components/QuickBooking/CarWash/QuickCarWash";
import QuickCarRepair from "./components/QuickBooking/CarRepair/QuickCarRepair";
import QuickCarService from "./components/QuickBooking/CarService/QuickCarService";

import QuickBookingCarService from "./components/QuickBooking/QuickBookingCarService";
import QuickBookingCarWash from "./components/QuickBooking/QuickBookingCarWash";
import QuickBookingCarRepair from "./components/QuickBooking/QuickBookingCarRepair";
import QuickBookingLanding from "./components/QuickBooking/QuickbookingLanding";



import DashboardPlus from "./components/Plus/DashboardPlus";
import DashboardPlusNext from "./components/Plus/DashboardPlusNext";
import DashboardPlusAdditional from "./components/Plus/DashboardPlusAdditional";
import DashboardPlusRequest from "./components/Plus/DashboardPlusRequest";
import EditRequest from "./components/MyRequest/Editplusrequest"

const inEmergencyEnable = () => {
  let flag = true;
  serviceTypes.forEach((type) => {
    if (type.isComingSoon && (type.className === 'emergency')) {
      flag = false;
    }
  });
  return flag;
};


export default (
  <Route path="/" component={Home}>
    <IndexRoute  onEnter={isLoggedIn} component={DashboardContainer(DashboardPlus)} />
    <Route
      path="dashboard-plus"
      onEnter={isLoggedIn}
      component={DashboardContainer(DashboardPlus)}
    />
    <Route
      path="sign-up"
      onEnter={scrollToTop}
      component={AuthContainer(SignUp)}
    />
    <Route
      path="sign-in"
      onEnter={scrollToTop}
      component={AuthContainer(SignIn)}
    />
    <Route
      path="forgot-password"
      onEnter={scrollToTop}
      component={AuthContainer(ForgotPassword)}
    />
    <Route
      path="edit-mobileno"
      onEnter={scrollToTop}
      component={AuthContainer(EditMobileNo)}
    />
    <Route
      path="send-otp"
      onEnter={scrollToTop}
      component={AuthContainer(SendOTP)}
    />
    <Route
      path="send-otp/:id"
      onEnter={scrollToTop}
      component={AuthContainer(SendOTP)}
    />
    <Route
      path="verify-otp"
      onEnter={scrollToTop}
      component={AuthContainer(VerifyOTP)}
    />
    <Route
      path="verify-otp/:id"
      onEnter={scrollToTop}
      component={AuthContainer(VerifyOTP)}
    />
    <Route
      path="reset-password"
      onEnter={scrollToTop}
      component={AuthContainer(ResetPassword)}
    />
    <Route
      path="confirmed"
      onEnter={scrollToTop}
      component={AuthContainer(Confirmed)}
    />
    <Route
      path="reset-email-confirmation"
      onEnter={scrollToTop}
      component={AuthContainer(ResetEmailConfirmation)}
    />
    <Route
      path="verify"
      onEnter={scrollToTop}
      component={AuthContainer(EmailVerified)}
    />
    <Route
      path="request/:requestId(/:view)"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(MyRequest)}
    />
    <Route
      path="request"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(MyRequestCard)}
    />

    <Route
      path="payment/:requestId/authorised"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(PaymentAuthorised)}
    />

    <Route
      path="edit-plus-request"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(EditRequest)}
    />


<Route
      path="chasis-popup"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(ChasisNumberPopup)}
    />




    <Route
      path="payment/:requestId/declined"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(PaymentDeclined)}
    />
    <Route
      path="payment/:requestId/cancelled"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(PaymentCancelled)}
    />
    <Route
      path="all-offers"
      component={BookingRequestContainer(OfferAll)}
    />
    <Route
      path="all-offers/:id"
      component={BookingRequestContainer(SingleOffer)}
    />{/*
    <Route
      path="all-offers/:id/book-now"
      component={BookingRequestContainer(AllOfferBooking)}
    />*/}
    <Route
      path="all-offers/:id/book-now"
      component={BookingRequestContainer(QuickBookingLanding)}
    />
    <Route path="services/car-oil-service-offer" component={BlogRedirection} />
    <Route path="offers/car_service" component={BlogRedirection} />

    <Route path="vendor-profile/146-axis-auto-garage" component={BlogRedirection} />
    <Route path="vendor-profile/148-auto-mystique-car-care-llc" component={BlogRedirection} />
    <Route path="vendor-profile/149-golden-workshop" component={BlogRedirection} />
    <Route path="vendor-profile/150-800-wash" component={BlogRedirection} />
    <Route path="vendor-profile/151-al-kayed-workshop" component={BlogRedirection} />
    <Route path="vendor-profile/152-rover-auto-centre-llc" component={BlogRedirection} />
    <Route path="vendor-profile/153-boby-auto-garage" component={BlogRedirection} />
    <Route path="vendor-profile/154-snash-car-care-l-l-c" component={BlogRedirection} />
    <Route path="vendor-profile/157-protouch-auto-care-llc" component={BlogRedirection} />
    <Route path="vendor-profile/165-max-garage" component={BlogRedirection} />
    <Route path="vendor-profile/171-mik-garage" component={BlogRedirection} />
    <Route path="vendor-profile/172-mik-garage-aweer" component={BlogRedirection} />
    <Route path="vendor-profile/182-autotrak-auto-service-center" component={BlogRedirection} />
    <Route path="vendor-profile/184-atlantis-auto-care" component={BlogRedirection} />
    <Route path="vendor-profile/186-battery-wheels" component={BlogRedirection} />
    <Route path="vendor-profile/190-vip-german-auto-repairing" component={BlogRedirection} />
    <Route path="vendor-profile/161-gargash-autobody" component={BlogRedirection} />

    <Route
      path="offers"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(OfferList)}
    />
    <Route
      path="offers/:id"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(OfferDetail)}
    />
    <Route
      path="offer/car-services-oil-service-offer"
      component={BookingRequestContainer(OfferIframe)}
    />
    <Route
      path="car-profiles/create"
      onEnter={isLoggedIn}
      component={CarProfileContainer(CreateCarProfile)}
    />
    <Route
      path="car-profiles"
      onEnter={isLoggedIn}
      component={CarProfileContainer(BookService)}
    />
    <Route
      path="car-profiles/:id/view"
      onEnter={isLoggedIn}
      component={CarProfileContainer(CarTimeline)}
    />
    <Route
      path="car-profiles/:id/edit"
      onEnter={isLoggedIn}
      component={CarProfileContainer(CreateCarProfile)}
    />
    {/* <Route path="car-list" component={AuthContainer(BookService)} /> */}
    <Route
      path="timeline"
      onEnter={isLoggedIn}
      component={AuthContainer(CarTimeline)}
    />
    <Route
      path="car-repair"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(CarRepair)}
    />
    <Route
      path="car-wash"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(CarWash)}
    />
    <Route
      path="edit-carwash"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(EditCarWash)}
    />

    <Route
      path="edit-carrepair"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(EditCarRepair)}
    />
    <Route
      path="edit-carservice"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(EditCarService)}
    />
    <Route
      path="car-service"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(CarService)}
    />
    <Route
      path="offer-terms/:id"
      //  onEnter={isLoggedIn}
      component={AuthContainer(OfferTerms)}
    />
    <Route
      path="quick-booking"
      component={AuthContainer(QuickBooking)} />
    <Route
      path="quick-booking/car-wash"
      onEnter={isLoggedIn}
      component={AuthContainer(QuickCarWash)} />

    <Route
      path="quick-booking/car-repair"
      onEnter={isLoggedIn}
      component={AuthContainer(QuickCarRepair)} />

    <Route
      path="quick-booking/car-service"
      onEnter={isLoggedIn}
      component={AuthContainer(QuickCarService)} />

    <Route
      path="car-service-in-dubai"
      component={AuthContainer(QuickBookingCarService)} />

    <Route
      path="car-wash-in-dubai"
      component={AuthContainer(QuickBookingCarWash)} />

    <Route
      path="car-repair-in-dubai"
      component={AuthContainer(QuickBookingCarRepair)} />

    <Route
      path="messages"
      onEnter={isLoggedIn}
      component={AuthContainer(Messages)}
    />
    <Route path="search-result" component={DashboardContainer(SearchResult)} />
    <Route path="top-car-service-centers-dubai" component={DashboardContainer(SearchResult)} />
    <Route
      path="notification"
      onEnter={isLoggedIn}
      component={AuthContainer(Notification)}
    />
    <Route
      path="favourites"
      onEnter={isLoggedIn}
      component={DashboardContainer(Favourites)}
    />
    <Route
      path="User-Accounts"
      onEnter={isLoggedIn}
      component={UseraccountContainer(UserAccounts)}
    />
    <Route path="vendor-profile/188" onEnter={scrollToTop} component={pageNotFound} />


    <Route
      path="vendor-profile/:vendor_name(\\w+)"
      onEnter={scrollToTop}
      component={DashboardContainer(VendorProfile)}
    />


    <Route
      path="vendor-profile/:vendor_id(\\d+)-:vendor_name(\\w+)/book-now"
      onEnter={scrollToTop}
      component={DashboardContainer(QuickBooking)}
    />
    <Route
      path="terms"
      onEnter={scrollToTop}
      component={AuthContainer(Terms)}
    />
    <Route
      path="privacy"
      onEnter={scrollToTop}
      component={AuthContainer(Policy)}
    />
    {
      inEmergencyEnable() &&
      <Route
        path="emergency"
        onEnter={isLoggedIn}
        component={AuthContainer(Emergency)}
      />
    }
    {
      inEmergencyEnable() &&
      <Route
        path="emergency/:status_type"
        onEnter={isLoggedIn}
        component={BookingRequestContainer(EmergencyRequest)}
      />
    }
    {
      inEmergencyEnable() &&
      <Route
        path="emergency-response"
        onEnter={isLoggedIn}
        component={AuthContainer(EmergencyResponses)}
      />
    }
    <Route
      path="preview"
      onEnter={isLoggedIn}
      component={AuthContainer(Preview)}
    />
    <Route
      path="empty-state"
      onEnter={isLoggedIn}
      component={AuthContainer(CarProfileEmptyState)}
    />
    {/*    <Route
      path="anniversary"
      onEnter={scrollToTop}
      component={AuthContainer(AnniversaryLanding)}
    />*/}
    <Route
      path="landing"
      onEnter={scrollToTop}
      component={AuthContainer(OldLanding)}
    />
    <Route
      path="dashboard-plus"
      onEnter={isLoggedIn}
      component={DashboardContainer(DashboardPlus)}
    />
    <Route
      path="plus"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(DashboardPlusNext)}
    />
    <Route
      path="dashboard-plus-additional"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(DashboardPlusAdditional)}
    />

    <Route
      path="dashboard-plus-request/:requestId"
      onEnter={isLoggedIn}
      component={BookingRequestContainer(DashboardPlusRequest)}
    />
    <Route
      path="register"
      onEnter={scrollToTop}
      component={AuthContainer(AuthLanding)}
    />
    <Route path="blog/faq" component={AuthContainer(Faq)} />
    <Route path="about" component={AuthContainer(AboutUs)} />
    <Route path="contact" component={AuthContainer(ContactUs)} />

    <Route
      path="guest-user"
      onEnter={scrollToTop}
      component={AuthContainer(GuestUserFlow)}
    />
    <Route path="*" onEnter={scrollToTop} component={pageNotFound} />
  </Route>
);
