import React, { Component } from 'react';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import Slider from 'react-slick';
import SpriteMaterial from '../common/SpriteMaterial';

import {isWebpSupported} from 'react-image-webp/dist/utils';

export default class Welcome extends Component {
    constructor(props) {
      super(props);
      const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};
      this.state ={
        locateMeDD: false,
        locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : '',
        autoCompleteData: [],
        showYoutubePromo: false,
        currentSlide:1
      }
    }

    autocompleteLocation(e) {
        let that = this;
        const searchTerm = e.target.value;
        if (searchTerm) {
          that.setState({
            locationSearchTerm: searchTerm
          });
          let places = new google.maps.places.AutocompleteService();
          places.getPlacePredictions({ input: searchTerm }, function (res) {
            that.setState({
              autoCompleteData: res
            });
          });
        } else {
          localStorage.removeItem('userSelectedLocation');
          this.props.actions.setCurrentLocation({});
          that.setState({
            locationSearchTerm: searchTerm,
            autoCompleteData: []
          });
        }
      }

      setUserLocation(location, searchTerm) {
        const that = this;
        let geoCodeParams = {};
        if (location && ((location.lat && location.lng) || location.place_id)) {
          if (location && location.lat && location.lng) {
            geoCodeParams['location'] = { lat: Number(location.lat), lng: Number(location.lng) };
          } else if (location.place_id) {
            geoCodeParams['placeId'] = location.place_id;
          }
          let geocoder = new google.maps.Geocoder();
          geocoder.geocode(geoCodeParams, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK && results && results.length) {
              let locationResult = {};
              if (results[0].formatted_address && results[0].formatted_address.indexOf('Unnamed Road') > -1) {
                locationResult = results[1];
              } else {
                locationResult = results[0];
              }
              this.setState({
                userLocation: locationResult,
                locationSearchTerm: searchTerm || location.description || locationResult.formatted_address,
                showLocationDropdown: false
              });
              that.props.actions.setCurrentLocation({
                lat: locationResult.geometry.location.lat(),
                lng: locationResult.geometry.location.lng(),
                formatted_address: locationResult.formatted_address,
                label: searchTerm || location.description || locationResult.formatted_address
              });

            } else {
              this.setState({ showLocationDropdown: false });
            }
          });
        }
      }

    render(){

      const settings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        speed: 2000,
        slidesToShow: 1,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        swipe: false,
        fade: true,
        arrows: false,
        pauseOnHover: false,
        autoplay: true,
        afterChange: (prev, next) => {
          let currentSlide = prev+1;
          this.setState({ currentSlide: currentSlide });
       },
     };


        let locationData = [{ textPlaceholder: 'Current Location' }];
        this.state.autoCompleteData.map((location, key) => {
          return locationData.push({ key: key, description: location.description, place_id: location.place_id });
        });
        this.props.commonReducer &&this.props.commonReducer.hasOwnProperty('userLocations') &&this.props.commonReducer.userLocations.map( (location, key) => {
          return locationData.push({
            key: key,
            lat: location.lat,
            lng: location.lng,
            label: location.label,
            id: location.id,
            formatted_address: location.location
          });
        });
        const {innerWidth} = this.props;

        const locationAutosuggestedList = locationData.map( (val, key) => {
            return (
              <MenuItem
                onClick={item => {
                  if (item.target.text != 'Current Location') {
                    this.setUserLocation(val, item.target.text);
                  } else {
                    if (navigator.geolocation) {
                      const that = this;
                      navigator.geolocation.getCurrentPosition(function (position) {
                        that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                      }, function (error) {
                        switch (error.code) {
                          case error.PERMISSION_DENIED:
                            that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                            break;
                          case error.POSITION_UNAVAILABLE:
                            that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                            break;
                          case error.TIMEOUT:
                            that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                            break;
                          case error.UNKNOWN_ERROR:
                            that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                            break;
                        }
                      }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 });
                    } else {
                      this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
                    }
                  }
                }}
                eventKey={key}
                key={key}
              >
                {val.description ? val.description : val.label ? val.label : val.textPlaceholder}
              </MenuItem>
            );
          });

          let Background = isWebpSupported() ?'../images/landing/welcome-back.webp' : '../images/landing/landing-welcome-back.svg';
          let BackgroundResponse = isWebpSupported() ?'../images/landing/welcome-back-mob.webp' : '../images/landing/landing-welcome-back-mob.svg';
        return(
            <div id="welcome-page" style={{background:(innerWidth > 480 ?`url(${Background})`:'none',`height: 78vh`)}}>
            <ModalVideo channel='youtube' isOpen={this.state.showYoutubePromo} videoId='h736TiMyfrs' maxwidth="600px" height="500px" onClose={() => this.setState({ showYoutubePromo: false })} />
            <div className="row main-header">
             <div className="col-md-6 col-sm-12 col-xs-12 pad0">
             <div className="fb-rating-desk landing-mobile-fal">
             <div className="static-rating"></div>
               {/*<img  src={`${imageBasePath}/images/landing/static-rating.png`} className="fb-rating landing-mobile-fal"/>*/}
                </div>

                <div className="no-one-car-desk"><h2>The <span className="no-one-bold">No.1</span> Car Service in UAE </h2></div>
                <div className="no-one-car-mob"><h2><div>The
                <span className="no-one-bold"> No.1</span> </div><div>Car Service in UAE</div></h2>
                 </div>
                 <div className="service-section-subText welcome-service-section">
                 Choose from a variety of qualified, reliable car service providers for your auto repair and maintenance
                 </div>
         <div className="welcome-landing">
            <div className="search-wrapper">
              <form className="form-inline">
                <DropdownButton
                  bsSize="small"
                  id="dropdown-size-small"
                  noCaret
                  onToggle={e => this.setState({ locateMeDD: e })}
                  onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                  className="location-search"
                  title={
                    <span className="search-left">
                     {/*<i className="mdi mdi-crosshairs-gps" />*/}
                     <SpriteMaterial posx={262} posy={0} width={23} height={23} widthBack={23} classNameVal='crosshairs-gps'/>
                      <input
                        type="text"
                        className="form-control form-search-land"
                        placeholder="Search a Service near you"
                        value={this.state.locationSearchTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autocompleteLocation(e);
                          this.setState({ locationSearchTerm: e.target.value });
                        }}
                      />
                     {/*<i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />*/}
                     {
                      this.state.locateMeDD ?
                      <SpriteMaterial posx={295} posy={0} width={15} height={20} widthBack={15} classNameVal='chevron-up'/>:
                      <SpriteMaterial posx={279} posy={0} width={15} height={20} widthBack={15} classNameVal='chevron-down'/>
                    }
                    </span>
                  }
                >
                  {locationAutosuggestedList}
                </DropdownButton>
                <button
                  data-labelledby="Book Now"
                  onClick={e => {
                    e.preventDefault();
                    // this.searchVendors(this.state.searchText, 'keyword');
                    this.props.router.push(appBasePath + '/guest-user');
                  }}
                  className="btn btn-default green-btn button welcome-book-now"
                >
                  Book Now
                  </button>
                </form>
              </div>
            </div>
            <div className="landing-mob-tru landing-rate ">
            <div className="fb-rating-desk ">
              <div className="static-rating"></div></div>
                {/*<img  src={`${imageBasePath}/images/landing/static-rating.png`} className="fb-rating "/>*/}
            </div>

            <div className="welcome-know-carcility landing-mobile-fal" onClick={e => {
                e.preventDefault();
                this.setState({ showYoutubePromo: true })

              }}>
                <img src="/images/landing/play-video.svg" alt="app store" />
                <span>Know Carcility</span>

              </div>
                <div className="download-app-text landing-mobile-fal">
                    Download the app!
                </div>
                <ul className="download-app-link landing-mobile-fal">
                <li>
                 <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                  <img src={`${imageBasePath}/images/landing/car-service-app-apple-store.svg`} alt="car service app - app store" />
                 </a>
                </li>
                <li>
                  <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                    <img src={`${imageBasePath}/images/landing/car-service-app-googleplay-store.svg`} alt="car service app - play store" />
                  </a>
                </li>
              </ul>
            </div>
            <div id="welcome-page-mobileback" style={{background:(innerWidth < 480 ?`url(${BackgroundResponse}) repeat-x`:'none')}} className="col-md-6 col-sm-12 col-xs-12 pad0 welcome-mobile-img welcome-mobile-image-desk">
               <div className="slider-div" >
               <Slider ref="slick" {...settings} >
               <div>
                  <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${innerWidth < 480 ? (isWebpSupported() ? 'mob.webp' :'mob.jpg') : (isWebpSupported() ? 'desk.webp' :'desk.jpg')}`}/>
                </div>
                <div>
                  <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${innerWidth < 480 ? (isWebpSupported() ? 'mob1.webp' :'mob1.jpg') : (isWebpSupported() ? 'desk1.webp' :'desk1.jpg')}`}/>
                </div>
                <div>
                <img className="welcome-slider-image" src={`${imageBasePath}/images/landing/landingwelcomemobile-${innerWidth < 480 ? (isWebpSupported() ? 'mob2.webp' :'mob2.jpg') : (isWebpSupported() ? 'desk2.webp' :'desk2.jpg')}`}/>
                </div>
                </Slider>
               </div>
            </div>
            </div>

            </div>
              )
    }
}
