import React from 'react';
import {render} from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import routes from './routes';
import configureStore from './store/configureStore';
import Cookies from 'js-cookie'
import axios from 'axios';


import './styles/bootstrap.min.css';
import './styles/badge.css';
import './styles/button.css';
import './styles/dashboard.css';
import './styles/deals.css';
import './styles/offer.css';
import './styles/workshop.css';
import './styles/dropdown.css';
import './styles/extra.css';
import './styles/footer.css';
import './styles/form.css';
import './styles/header.css';
import './styles/jobcard.css';
import './styles/login.css';
import (/* webpackChunkName:"materialicon" */ './styles/materialdesignicons.min.css');
import './styles/mobileNotification.css';
import './styles/sidebar.css';
import './styles/status.css';
import './styles/style.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/'./styles/typography.css');
import './styles/my-request.css';
import './styles/my-request-cards.css';
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/slick-carousel/slick/slick.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/slick-carousel/slick/slick-theme.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/react-datepicker/dist/react-datepicker.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/@trendmicro/react-toggle-switch/dist/react-toggle-switch.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/react-input-range/lib/css/index.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/  '../node_modules/css-star-rating/dist/css/star-rating.css');
import './styles/JobUpdateCard.css';
import './styles/gmaps.css';
import './styles/job-details.css';
import (/* webpackChunkName:"collapse" */ './styles/collapse-panel.css');
import './styles/book-service.css';
import './styles/timeline.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/'./styles/modal.css');
import './styles/messages.css';
import './styles/search-result.css';
import './styles/notification.css';
import './styles/vendor-profile.css';
import './styles/vendordetail.css';
import './styles/terms.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/pageNotFound.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-custom-scroll/dist/customScroll.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-select/dist/react-select.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/scrollbar.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/emergency.css');
import './styles/user-accounts.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/empty-state.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true"*/ './styles/toast.css');
import './styles/landing.css';
import './styles/animation.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/tiny-slider.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-image-gallery/styles/css/image-gallery.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-image-gallery/styles/css/image-gallery-no-icon.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-phone-number-input/rrui.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-phone-number-input/style.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/worklogModal.css');
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/rc-time-picker/assets/index.css');
import './styles/animate.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ 'intro.js/introjs.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/walkthrough.css');
import './styles/customer-responsive.css';
import './styles/authLanding.css';
import './styles/quick-booking.css';
import './styles/quick-booking-landing.css';
import './styles/vendor-mobile.css';
import './styles/plus.css';
import (/* webpackChunkName:"mainlater", webpackPreload: true*/ './styles/contact.css');
import (/* webpackChunkName:"mainlater", webpackPreload: true*/ './styles/faq.css');

import './styles/guest-user-flow.css';
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/request-mobile-sort.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/mobile-search.css');
import (/* webpackChunkName:"mainlater", webpackPrefetch: true*/ './styles/lightBox.css');
import {gtmId} from "./constants/staticData";
import TagManager from 'react-gtm-module';
import (/* webpackChunkName:"mainnode", webpackPrefetch: true*/ '../node_modules/react-modal-video/scss/modal-video.scss');
import './styles/floating-menu.css';

// import FloatingMenu from './components/LandingNew/FloatingMenu';
 import * as serviceWorker from './serviceWorker';

  if(gtmId){

    const tagManagerArgs = {
      gtmId: gtmId,
      dataLayerName: 'PageDataLayer'
    }

    TagManager.initialize(tagManagerArgs);

  }


export const store = configureStore();

const history = syncHistoryWithStore(browserHistory, store);


if(localStorage.getItem('accessToken') == null && Cookies.get('accessToken')){


 
    // axios.get(`https://api-server-stage.carcility.com/api/services`)
    
    //   .then(response => {
    //     
        
    //     localStorage.setItem('response',JSON.stringify(response.data));

    //   })
    

  // localStorage.setItem('response1',JSON.stringify(response.data));


  var customerUrl  = '.carcility.com'
 

  localStorage.setItem('accessToken', Cookies.get('accessToken'));
  localStorage.setItem('authData', JSON.parse(Cookies.get('authData')));
  localStorage.setItem('userId', JSON.parse(Cookies.get('userId')));
  localStorage.setItem('flow', Cookies.get('flow'));
  localStorage.setItem('subtype', Cookies.get('subtype'));
  localStorage.setItem('bookingRequestId', Cookies.get('bookingRequestId'));
  localStorage.setItem('selectedPromoCodeId', Cookies.get('selectedPromoCodeId'));
  localStorage.setItem('selectedPromoCode', Cookies.get('selectedPromoCode'));
  localStorage.setItem('serviceId', Cookies.get('serviceId'));
  localStorage.setItem('hideFirstStep', Cookies.get('hideFirstStep'));


  var serviceId= localStorage.serviceId;

  if(serviceId == 1){
    localStorage.setItem('selectedCarWashDataArray', Cookies.get('selectedCarWashDataArray'));
    localStorage.setItem('washCategoryData', JSON.parse(Cookies.get('washCategoryData')));
    Cookies.remove('selectedCarWashDataArray',{ domain: customerUrl })
    Cookies.remove('washCategoryData',{ domain: customerUrl })

  }
  if(serviceId == 2){
    localStorage.setItem('selectedServiceId', Cookies.get('selectedServiceId'));
    localStorage.setItem('selectedServiceName', Cookies.get('selectedServiceName'));
    localStorage.setItem('selectedService', Cookies.get('selectedService'));
    localStorage.setItem('serviceCategoryData', JSON.parse(Cookies.get('serviceCategoryData')));
    localStorage.setItem('serviceCategoryData1', JSON.parse(Cookies.get('serviceCategoryData1')));
    Cookies.remove('selectedServiceId',{ domain: customerUrl })
    Cookies.remove('selectedServiceName',{ domain: customerUrl })
    Cookies.remove('selectedService',{ domain: customerUrl })
    Cookies.remove('serviceCategoryData',{ domain: customerUrl })
    Cookies.remove('serviceCategoryData1',{ domain: customerUrl })
    var service = JSON.parse(localStorage.getItem('serviceCategoryData'));
    var service2= JSON.parse(localStorage.getItem('serviceCategoryData1'));
    var serviceCategoryList = [];
    serviceCategoryList.push(service[0]);
    serviceCategoryList.push(service[1]);
    serviceCategoryList.push(service[2]);
    serviceCategoryList.push(service[3]);
    serviceCategoryList.push(service[4]);
    serviceCategoryList.push(service[5]);
    serviceCategoryList.push(service[6]);
    serviceCategoryList.push(service[7]);
    serviceCategoryList.push(service[8]);
    serviceCategoryList.push(service[9]);
    serviceCategoryList.push(service[10]);
    serviceCategoryList.push(service[11]);
    serviceCategoryList.push(service[12]);
    serviceCategoryList.push(service[13]);
    serviceCategoryList.push(service[14]);
    serviceCategoryList.push(service[15]);
    serviceCategoryList.push(service[16]);
    serviceCategoryList.push(service[17]);
    serviceCategoryList.push(service[18]);
    serviceCategoryList.push(service[19]);
    serviceCategoryList.push(service[20]);
    serviceCategoryList.push(service2[0]);
    serviceCategoryList.push(service2[1]);
    serviceCategoryList.push(service2[2]);
    serviceCategoryList.push(service2[3]);
    serviceCategoryList.push(service2[4]);
    serviceCategoryList.push(service2[5]);
    serviceCategoryList.push(service2[6]);
    serviceCategoryList.push(service2[7]);
    serviceCategoryList.push(service2[8]);
    serviceCategoryList.push(service2[9]);

    localStorage.setItem('CarserviceCategoryList',JSON.stringify(serviceCategoryList));


    // localStorage.setItem('s1', JSON.stringify(service));
    // localStorage.setItem('s2', JSON.stringify(service2));

    // console.log("Service = ",service[0]);
    // console.log("Service2 = ",service2[0]);
  }
  if(serviceId == 3){
    localStorage.setItem('selectedCarRepairDataArray', Cookies.get('selectedCarRepairDataArray')); 
    localStorage.setItem('repairCategoryData', JSON.parse(Cookies.get('repairCategoryData')));
    localStorage.setItem('repairCategoryData1', JSON.parse(Cookies.get('repairCategoryData1')));
    // localStorage.setItem('repairCategoryData2', JSON.parse(Cookies.get('repairCategoryData2')));
    // localStorage.setItem('repairCategoryData3', JSON.parse(Cookies.get('repairCategoryData3')));
    // localStorage.setItem('repairCategoryData4', JSON.parse(Cookies.get('repairCategoryData4')));
    // localStorage.setItem('repairCategoryData5', JSON.parse(Cookies.get('repairCategoryData5')));
    // localStorage.setItem('repairCategoryData6', JSON.parse(Cookies.get('repairCategoryData6')));
    // localStorage.setItem('repairCategoryData7', JSON.parse(Cookies.get('repairCategoryData7')));
    // localStorage.setItem('repairCategoryData8', JSON.parse(Cookies.get('repairCategoryData8')));
    // localStorage.setItem('repairCategoryData9', JSON.parse(Cookies.get('repairCategoryData9')));
    // localStorage.setItem('repairCategoryData10', JSON.parse(Cookies.get('repairCategoryData10')));
    // localStorage.setItem('repairCategoryData11', JSON.parse(Cookies.get('repairCategoryData11')));
    // localStorage.setItem('repairCategoryData12', JSON.parse(Cookies.get('repairCategoryData12')));
    Cookies.remove('selectedCarRepairDataArray',{ domain: customerUrl })
    Cookies.remove('repairCategoryData',{ domain: customerUrl })
    Cookies.remove('repairCategoryData1',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData2',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData3',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData4',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData5',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData6',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData7',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData8',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData9',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData10',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData11',{ domain: customerUrl })
    // Cookies.remove('repairCategoryData12',{ domain: customerUrl })
  }
 
  // localStorage.setItem('serviceCategoryData1', JSON.parse(Cookies.get('serviceCategoryData1')));
  
  localStorage.setItem('servicesFetched', Cookies.get('servicesFetched'));

}

render(

    <Provider store={store}>
      <Router history={history} routes={routes} />
    </Provider>, document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//serviceWorker.register();
