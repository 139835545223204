import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import Arrow from './Arrow';
import LazyLoad from 'react-lazyload';

export default class Workshops extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 6000);
    console.log(document.getElementsByClassName('slick-active workshop-card'));
  }

  addDefaultSrc(ev) {
    ev.target.src = '../../images/placeholder.png';
  }

  render() {
    const { workshopsData, router, title, isLanding } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <Arrow imgClassName={!isLanding ? "workshop-arrow-next" : "workshop-arrow-next-landing"} type="next" onClick={() => { console.log() }} />,
      prevArrow: <Arrow imgClassName={!isLanding ? "workshop-arrow-prev" : "workshop-arrow-prev-landing"} type="prev" />,
      // variableWidth: true,
      responsive: [
        {
          breakpoint: 640,
          variableWidth: false,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1067,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 1100,
          variableWidth: true,
          settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 1400,
          variableWidth: true,
          settings: { slidesToShow: 4 }
        },
      ],
      // arrows:true
    };

    let promoCodesHtml = workshopsData.map((workshop, index) => {
      let workshopRating = [];
      let noRating = [];
      for (let i = 1; i <= (Math.floor(workshop.googlerating)); i++) {
        workshopRating.push(i);
      }
      for (let i = 1; i <= (5 - Math.floor(workshop.googlerating)); i++) {
        noRating.push(i);
      }

      return (
        // <div className={isLanding ? "workshop-card-landing" : "workshop-card"} key={index} style={{ width: isLanding ? '259px' : '245px' }}>
        <div className={isLanding ? "workshop-card-landing" : "workshop-card"} key={index}>
          <div className="workshop-body">
            <LazyLoad>
              <img className="offer-cover" onError={this.addDefaultSrc} onClick={() => { router.push(`${appBasePath}/vendor-profile/${workshop.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`) }} src={workshop.images && workshop.images.length ? workshop.images[0].small : `${imageBasePath}/images/placeholder.png`}
                   alt="workshops-image" />
            </LazyLoad>
            <div className="description workshop-details">
              <div onClick={() => { router.push(`${appBasePath}/vendor-profile/${workshop.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`) }} >
                <div className="garage">{workshop.companyprofilename}</div>
                <div className="title">{workshop.business_profile.companyname}</div>
                <div className="ratings">
                  {workshopRating.map((ratings, index) => {
                    return (
                      <img key={index} className="star-icon" src={`${imageBasePath}/images/star_filled.png`} alt="star-filled" />
                    );
                  })}
                  {noRating.map((ratings, index) => {
                    return (
                      <img key={index} className="star-icon" src={`${imageBasePath}/images/star_border.png`} alt="star-border" />
                    );
                  })}
                </div>
                <div className="workshop-location">
                  <img className="location-marker" src={`${imageBasePath}/images/location-marker.png`} alt="location-marker" />
                  {/* <i class="fa fa-map-marker" aria-hidden="true"></i> */}
                  <span className="distance">{workshop.distance && workshop.distance.toFixed(2) + ' kms'}</span>
                </div>
              </div>
            </div>
            {!!workshop.googlerating && workshop.googlerating !== 0 &&
              <div className="price" onClick={() => { router.push(`${appBasePath}/vendor-profile/${workshop.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`) }} >
                <div className="workshop-rating">
                  <img className="google-icon" src={`${imageBasePath}/images/google.png`} alt="google-icon" />
                  <span className="wrating">{workshop.googlerating}</span>
                </div>
              </div>
            }
          </div>
        </div >
      );
    });

    return (
      <div className="row" >
        <div className="workshop-row" id="deals-section">
          <div className={isLanding ? "workshop-heading workshop-heading-landing" : "workshop-heading"}>
            {isLanding ?
              <div className="row"><span className="landing-workshop-header" onClick={() => { router.push(`${appBasePath}/top-car-service-centers-dubai`) }}>View All</span></div>
              :
              (
                <div>
                  <h5 className="workshop-dashboard-header">{title}</h5>
                  <span onClick={() => { router.push(`${appBasePath}/top-car-service-centers-dubai`) }}>View All</span>
                </div>
              )
            }
          </div>
          <div className="col-md-12 offer-layout">
          {/* <div className=""> */}
            {promoCodesHtml ?
              <Slider ref="slick" {...settings} >
                {promoCodesHtml}
              </Slider>
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
