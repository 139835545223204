import React, { Component } from 'react';
import Header from "../common/Header";
import MobileNotification from "../common/MobileNotification";
import MobileMessage from "../common/MobileMessage";
import Sidebar from "../common/Sidebar";
import map  from 'lodash/map';
import PageLoader from "../common/PageLoader";
import ToastMessage from "../common/ToastMessage";
import Footer from '../common/Footer';
import MobileHeaderSearch from "../common/MobileHeaderSearch";
import OfferBox from "./OfferBox";
import FloatingMenu from "../LandingNew/FloatingMenu";


export default class OfferList extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false,
      promotionalOffersData: this.props.promotionalOffersData?this.props.promotionalOffersData : []
    };

  }


  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }



  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  componentDidMount() {

    if (this.state.promotionalOffersData.length < 1) {
      this.props.actions.getPromotionalOffers();
    }

  }



  componentWillReceiveProps(nextProps) {

    this.setState({
      promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
    });

  }

  render() {


    const { router, commonReducer, actions,bookingRequestReducer } = this.props;


    return (
      <div>
      <FloatingMenu  />
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        notificationCount={2} commonReducer={commonReducer} notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader ">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>All Offers</h4>
            </div>
          </div>
          {!this.state.isLoading ? <div className="inSection dashboard-wrapper offer-wrapper">
            <div className="row ">
              <div className="padwrapper offer-list">
                <div className="offer-body row">
                  {/*Job Updates*/}
                  {(this.state.promotionalOffersData && this.state.promotionalOffersData.length ? map(this.state.promotionalOffersData, (offer, index) => {
                    return (
                      <OfferBox key={index}
                                      offer = {offer}
                                      router={router}
                      />);
                  }) : <PageLoader />)}
                </div>
              </div>
            </div>
          </div> : <PageLoader />}
        </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>

    );
  }
}

