import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath } from '../../constants/staticData';

export default class pageNotFound extends Component {
    render() {
        return (
            <div>
                <div className="header-section page-404-header">
                    <div className="logo-section">
                        <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="logo-img" />
                    </div>
                </div>
                <div className="pageNotFoundHolder">
                    <img src={`${imageBasePath}/images/404.png`} alt=""/>
                    <span className="errTextHeading">404 Error Page</span>
                    <span className="sub-text">This page doesn’t exist anymore.</span>
                    <Button btnCallBack={() => {window.location.pathname = '/'}} btnType="red" fontSize={14} label="Go to home page" />
                </div>
            </div>
        );
    }
}
