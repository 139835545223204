import React, { Component } from 'react';
import forEach  from 'lodash/forEach';
import Gmaps from '../../MyRequest/Gmaps';
import Services from './Services';
import Button from '../../common/Button';
import { imageBasePath } from '../../../constants/staticData';

class GfEmergency extends Component {
    constructor(){
        super()
        this.state={
            subServices:[],
            setCenter:false,
            mapsCenter: { lat: 12.9952672, lng: 77.5905857 },
            dragPosition: {
                lat: 12.9569939,
                lng: 77.208421,
                pinImage: `${imageBasePath}/images/drag-loc.png`,
                dragEle:true
            },
            currentPosition: {
                lat: 0,
                lng: 0,
                pinImage: `${imageBasePath}/images/map_blue_pointer.png`,
                dragEle:false

            },
        }
    }
  componentWillMount() {
    let modal = false;

    let that = this;
    this.props.actions.getAllServices(function (data) {
      that.setState({ subServices: data[3].sub_services });
    });
  }
    getCurrentLocationFromLatLng(latlng){
        console.log(latlng);
        console.log(latlng);
        const that = this;
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({location: {'lat' : latlng.lat, 'lng': latlng.lng}}, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK && results && results.length) {
               that.updateData(results[0]);
          }
        });
      }
    markerDrag(map){
        let position={
            lat:map.latLng.lat(),
            lng:map.latLng.lng(),
            pinImage: `${imageBasePath}/images/drag-loc.png`,
            dragEle:true
        }
        this.getCurrentLocationFromLatLng(position);
        this.setState({dragPosition:position})
    }
    onSearchBoxMounted(searchBox) {
        this._searchBox = searchBox;
    }
    onPlacesChanged() {

        const places = this._searchBox.getPlaces();
        let newDragPosition= {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
            pinImage: `${imageBasePath}/images/drag-loc.png`,
        }
        let newCenter = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
        }
        this.getCurrentLocationFromLatLng(newCenter);
        this.setState({dragPosition:newDragPosition,setCenter:true,mapsCenter:newCenter})
    }
    fixCenter(){
        let CenterVal =  { lat: this.state.currentPosition.lat, lng: this.state.currentPosition.lng };
        this.setState({mapsCenter:CenterVal,setCenter:true})
    }

    render() {
        let allMarkers =  [];
        allMarkers.push(this.state.currentPosition);
        allMarkers.push(this.state.dragPosition)
        const jobCardLocation = forEach(allMarkers, (loc) => {
                return {
                    lat: loc.latitude, lng: loc.longitude, pinImage: loc.pinImage
                }
        })
        return (
            <div className="padwrapper GfEmergency">
                <div className="maps-container mapSection">
                    <Gmaps
                        mapDrag={() => { this.setState({ setCenter: false }) }}
                        setCenter={this.state.setCenter}
                        center={this.state.mapsCenter}
                        zoom={9}
                        mapElement={<div className="map-align" style={{ height: `100%`, width: `100%` }} />}
                        containerElement={<div className="map-wrapper" style={{ height: 100 + '%', width: "1100px" }} />}
                        markers={jobCardLocation}
                        markerDrag={this.markerDrag.bind(this)}
                        onSearchBoxMounted={this.onSearchBoxMounted.bind(this)}
                        onPlacesChanged={this.onPlacesChanged.bind(this)}
                    />
                    <span onClick={this.fixCenter.bind(this)} className="current-position"><i className="mdi mdi-crosshairs-gps"></i></span>
                    <Services data={this.state.subServices} carProfileReducer={this.props.carProfileReducer}
                            address={this.state.mapAddress} mapPosition={this.state.dragPosition}
                            actions={this.props.actions} />
                    <div className="next-button">
                      <Button dataLabelledby="Select Service Next" btnType="red" btnSize="sm" fontSize={14} label="Next"  />
                    </div>
                </div>
            </div>
        );
    }
}

export default GfEmergency;
