import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import map  from 'lodash/map';
import CustomScroll from 'react-custom-scroll';


class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carProfile: props.customPlaceholder ? props.customPlaceholder : "Select Car Profile",
      carProfileDd: false
    };
  }

  componentDidMount() {
    if (this.props.selectedCarProfile) {
      this.setState({
        carProfile: this.props.selectedCarProfile ? this.props.selectedCarProfile.name : "Select Car Profile",
      });
    } else if (this.props.getAllCancelReasons) {
      this.setState({
        carProfile: "Select a cancel reason"
      });
    }
    this.props.select && this.props.selectedCarProfile && this.props.select(this.props.selectedCarProfile);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.filterCarProfile) {
      this.setState({
        ...this.state,
        carProfile: 'Select Car Profile'
      });
    }

  }

  render() {
    const { getAllCancelReasons } = this.props;

    return (
      <div className="modal-cus-Dd">
        <DropdownButton
        //  onSelect={(e) => {this.setState({ carProfile: this.props.getAllCancelReasons ? e.reason : e.name }), this.props.select(e); }}
          className={this.props.cname ? this.props.cname : "modal-dd-text"}
          onToggle={(e) => this.setState({ carProfileDd: e })}
          open={this.state.carProfileDd}
          bsSize="xsmall"
          title={
            <span>
              <span className="selected-car-name">{this.state.carProfile}</span>
              <i className={this.state.carProfileDd ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
            </span>
          }
          id="dropdown-size-extra-small" noCaret>
          <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true} >
            {map(this.props.data, (list, key) => {




              return (
                  <a
                   onClick={() => {this.setState({carProfile: list.name, carProfileDd: false}, () => {this.props.select(list)})}}
                   key={key} eventKey={list}  >{list.name}<br/></a>
              );
            })}
            {
              getAllCancelReasons && map(getAllCancelReasons, (value, key) => {
                return (
                  <a
                  onClick={() => {
                    this.setState({carProfile: value.reason, carProfileDd: false}, () => this.props.select(value))
                  }}
                  key={key} eventKey={value}>{value.reason}</a>
                );
              })
            }
          </CustomScroll>
        </DropdownButton>
      </div>
    );
  }
}

export default SelectDropdown;
