import React, { Component } from 'react';
import Button from '../common/Button';
import each  from 'lodash/each';
import map  from 'lodash/map';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import Upload from '../common/Upload';
import Gmaps from '../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
var uniqid = require('uniqid');
import LocationSearchBox from '../common/LocationSearchBox';
import TimePicker from 'rc-time-picker';
import PageLoader from '../common/PageLoader';
import LightBox from '../common/LightBox';
import { imageBasePath } from '../../constants/staticData';
import TextInput from '../common/TextInput';
import { queryStringtoArray } from '../../helpers/index';

class EditServiceSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form1Active:false,
      form2Active:false,
      selectedDate:  moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      uploadImageErrText: false,
      imageUploaded: [],
      uploadImage: [],
      policeReport: [],
      rationCard: [],
      drivingLicence: [],
      PreferedLocation: 'Select Location',
      isButtonLoading:false,
      step1Panel: true,
      step2Panel: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      visibleCategory: undefined,
      selectedCarProfileId: '',
      uploadedImages: [],
      uploadedImagesId: [],
      imageCountErrText: false,
      imageTotalCount:0,
      editRequestImageArray:[],
      jobDetails: this.props.bookedRequest && this.props.bookedRequest.details,
      now: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      dateValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).date(),
      monthValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).month(),
      yearValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).year(),
      hoursValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).hours(),
      minutesValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).minutes(),
      checked: false,
      selectedLocation: '',
      referenceId: false,
      currentTime: '',
      selectedTimeValue:'',
      batchID: undefined,
      searchValue:'',
      newImageIdArray:[],
      imageStartIndex:undefined,
      chassisImages: [],
      chassis_number: '',
      uploadFlag: false,
    };
    this.formData = {
      selectedLocation: true,
      timeValid: this.state.hoursValue
    };
    this.errors = {
      selectedLocation: false,
      timeValid: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    //this.props.actions.createBatchID(this.state.batchID);
    this.props.actions.clearImageArray();
    if(this.props && this.props.bookedRequest && this.props.bookedRequest.batchId){
      this.setState({
        ...this.state,
        batchID:this.props.bookedRequest.batchId
      });
    }
  }

  componentDidMount() {
    this.setState({
      'filteredCarServiceCategories': cloneDeep(this.props.carServiceCategories)
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  componentWillReceiveProps(nextProps) {

    // Add code for ChassisImages
    const CSNImages = [];
    if(nextProps && nextProps.carProfiles &&
    nextProps.bookedRequest && nextProps.bookedRequest.vehicleId) {
      const selectedCarForCSN = nextProps.carInfo && nextProps.carInfo;
      console.log('aaaaaa');
      console.log(selectedCarForCSN);



      map(selectedCarForCSN.images, (img) => {
        if (img.type == "chassisNumber") {
          CSNImages.push({
            isUploaded: true,
            isFailed: false,
            id: img.id,
            thumbnail: img.small,
            original: img.original,
            large: img.large,
            isCover: false
          });
        }
      });
    }

    let activerequests = nextProps && nextProps.bookingRequestDetails && nextProps.bookingRequestDetails.requeststatus && nextProps.bookingRequestDetails.requeststatus.displayname == "Active";
    if(!activerequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile && nextProps.bookingRequestReducer){
      nextProps.bookedRequest.images && nextProps.bookedRequest.images.length > 0 && nextProps.bookedRequest.images.map((value)=>{
        if(value.type == "image"){
          if(!this.state.uploadedImagesId.includes(value.id)){
            this.state.uploadedImagesId.push(value.id);
            this.state.uploadImage.push(value);
          }
        }
      });
      this.setState({
        ...this.state,
          jobDetails: nextProps.bookedRequest && nextProps.bookedRequest.details,
          checked: nextProps.bookedRequest.isurgentrequest? true: false,
          imageTotalCount: this.state.uploadImage.length,
          referenceId: nextProps.bookedRequest && nextProps.bookedRequest.id,
          selectedCarProfileId: nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
          chassis_number: nextProps.carInfo && nextProps.carInfo.chassisNumber ? nextProps.carInfo.chassisNumber: '',
          uploadFlag:nextProps.carInfo && (nextProps.carInfo.images.length > 0 )? (this.state.uploadFlag? true : false): true,
          batchID: nextProps.bookedRequest.batchId,
          selectedLocation: {
            ...this.state.selectedLocation,
              formatted_address:nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
              label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
              lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
              lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
          }
      });
    }
    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false });
    } else if(activerequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile && nextProps.bookingRequestReducer){
      nextProps.bookedRequest.images && nextProps.bookedRequest.images.length > 0 && nextProps.bookedRequest.images.map((value)=>{
        if(value.type == "image"){
          if(!this.state.uploadedImagesId.includes(value.id)){
            this.state.uploadedImagesId.push(value.id);
            this.state.uploadImage.push(value);
          }
        }
      });
      this.setState({
        ...this.state,
          jobDetails: nextProps.bookedRequest && nextProps.bookedRequest.details,
          checked: nextProps.bookedRequest.isurgentrequest? true: false,
          imageTotalCount: this.state.uploadImage.length,
          referenceId: nextProps.bookedRequest && nextProps.bookedRequest.id,
          selectedCarProfileId: nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
          chassis_number: nextProps.selectedCarProfile && nextProps.selectedCarProfile.chassisNumber ? nextProps.selectedCarProfile.chassisNumber: '',
          uploadFlag:nextProps.selectedCarProfile && nextProps.selectedCarProfile.images.length > 0 ? false: true,
          batchID: nextProps.bookedRequest.batchId,
          statusActive: true,
          step1Panel: false,
          step2Panel: true,
          selectedLocation: {
            ...this.state.selectedLocation,
              formatted_address:nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
              label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
              lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
              lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
          }
      });
    } else if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.editRequestImageArray && nextProps.bookingRequestReducer.editRequestImageArray.length && nextProps.bookingRequestReducer.editRequestImageArray.length > this.state.editRequestImageArray.length){
      let uploadImage = cloneDeep(this.state.uploadImage);
      nextProps.bookingRequestReducer.editRequestImageArray.map((editRequestImageArray) => {
        let findUploadImageIndex = findIndex(this.state.uploadImage, {fileName: editRequestImageArray.fileName});
        if(findUploadImageIndex > -1){
          uploadImage[findUploadImageIndex]['uploaded'] = true;
          uploadImage[findUploadImageIndex]['id'] = editRequestImageArray.id;
        }
      });
      this.setState({
        ...this.state,
        editRequestImageArray: nextProps.bookingRequestReducer.editRequestImageArray,
        uploadImage: uploadImage,
      });
    } else if(nextProps && nextProps.showError){
      this.setState({isButtonLoading:false});
    }
     console.log('&&&&&&')
     console.log(CSNImages)
    this.setState({
      chassisImages: CSNImages
    });
  }

  imageThumbnails(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
        {this.state.uploadFlag ? <span className="cancel-image" onClick={(e) => {
          e.preventDefault();
          this.props.actions.deleteVehicleImage(index, img.id, imageArray, this.props.bookingRequestId);
        }}>
          <i className="mdi mdi-close" />
        </span> : ''}
          <img
            src={img.thumbnail}
          />
          {!img.isUploaded ? <div><img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" /></div> : ''}
        </div>
      );
    });
  }

  imageThumbnailsForChassis(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          <img
            src={img.thumbnail}
          />
        </div>
      );
    });
  }

  fileNameUpload(e) {
    let files = [], fileBlob = [], fileImgSize = 0, count = 0,errFileType = false;
    this.setState({ uploadImageErrText: false });
    each(e.target.files, (val) => {
      let uniqueId= uniqid();
      let value = val;
      value['fileName'] = uniqueId;
      files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size,fileName: uniqueId,thumbnail: URL.createObjectURL(val),original: URL.createObjectURL(val) });
      fileBlob.push(value);
      fileImgSize = val.size;
      count = count + 1;
      if (val.type == "image/png" || val.type == "image/jpeg") {
      } else {
        errFileType = true;
      }
    });
    // upload = { ...this.state.imageUploaded, files }
    if (fileImgSize >= 25000000 || errFileType == true) {
      this.setState({ uploadImageErrText: true });
    } else if (this.state.imageTotalCount + count > 5){
      this.setState({ imageCountErrText: true });
    } else {
      this.setState({
        uploadImage: this.state.uploadImage.concat(files),
        imageTotalCount : this.state.imageTotalCount + count,
        imageCountErrText: false,
        uploadImageErrText: false,
      });
      e.target.value = null;
      let that = this;
      map(fileBlob, (value) => {

        let img = new Image();
        img.onload = function () {
          value.height = this.height;
          value.width = this.width;

          that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
          if (data) {
            that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
              let newImageIdArray = cloneDeep(that.state.newImageIdArray);
              newImageIdArray.push(imageId);
              that.setState({newImageIdArray: newImageIdArray});
             that.props.actions.bookingUploadImage(imageId,value.fileName,"editrequest",that.state.batchID,true);
            }, () => that.uploadImageError(value));
          }
        }, () => that.uploadImageError(value), that.state.referenceId && that.state.referenceId, "image");
      };
      img.onerror = function () {
        // alert( "not a valid file: " + value.type);
      };
      img.src = URL.createObjectURL(value);
      });
      //this.props.actions.bookingUploadImage(fileBlob, this.state.batchID,"editrequest",files);
    }
  }

  uploadImageError(value) {
    this.props.actions.bookingUploadImage('',value,this.state.batchID, false);
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  uploadImageError2(value) {
    this.props.actions.bookingUploadImage('',value,this.state.batchID, false);
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  cancelUploadImage(val,index,id) {
    let editRequestImageArray = cloneDeep(this.state.editRequestImageArray);
    this.props.actions.deleteUploadedImages(id,"editrequest");
    const array = this.state.uploadImage;
    array.splice(index, 1);
    let removeImage = remove(editRequestImageArray, (n) => {
      return n.id != id;
    });
    this.setState({
      uploadImage: array,
      imageTotalCount: this.state.imageTotalCount-1,
      editRequestImageArray: removeImage,
    });
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id
    });
    this.props.changeCarProfile(carProfile);
    this.errors['selectedCarProfileId'] = false;
    this.formData['selectedCarProfileId'] = true;
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if(moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()){
      if(this.state.selectedTimeValue < moment().add(1,'hours')) {
      this.formData['timeValid'] = '';
      this.errors['timeValid'] = true,
      this.setState({
        submissionError: true,
      });
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
      this.setState({
        submissionError: false,
      });
    }
    }else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
      this.setState({
        submissionError: false,
      });
    }
  }

  jobDetails(data) {
    if (data.target.value.length) {
      this.errors['jobDetails'] = false;
      this.formData['jobDetails'] = true;
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
    else {
      this.errors['jobDetails'] = true;
      this.formData['jobDetails'] = false;
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
  }

  requestQuotes() {
    const { selectedCarServiceDataArray, actions,bookingRequestId } = this.props;
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else if(validForm && bookingRequestId && this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng) {
      this.setState({ submissionError: false, isButtonLoading:true  });
      let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
      let momentTimeStamp = moment.utc(timeStamp).format();
      let payload;
      if (this.props.garageProfileDetails) {
        payload = {
          "batch_id": this.state.batchID,
          "serviceid": 2,
          "chassis_number": this.state.chassis_number.toString(),
          "vendorid": this.props.garageProfileDetails.id,
          "subserviceid": [this.props.selectedServiceId],
          "vehicleid": this.state.selectedCarProfileId,
          "details": this.state.jobDetails,
          "userpreffereddatetime": momentTimeStamp,
          "location": this.state.selectedLocation.formatted_address,
          "latitude": this.state.selectedLocation.lat.toString(),
          "longitude": this.state.selectedLocation.lng.toString(),
          "isurgentrequest": this.state.checked,
          "images": this.state.newImageIdArray,
        };
      } else {
        payload = {
          "batch_id": this.state.batchID,
          "serviceid": 2,
          "chassis_number": this.state.chassis_number.toString(),
          "subserviceid": [this.props.selectedServiceId],
          "vehicleid": this.state.selectedCarProfileId,
          "details": this.state.jobDetails,
          "userpreffereddatetime": momentTimeStamp,
          "location": this.state.selectedLocation.formatted_address,
          "latitude": this.state.selectedLocation.lat.toString(),
          "longitude": this.state.selectedLocation.lng.toString(),
          "isurgentrequest": this.state.checked,
          "images": this.state.newImageIdArray,
        };
      }
      actions.editBookingRequest(bookingRequestId,payload);
    }
  }

  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  cancelEdit(){
    //this.props.router.push(`request/${20171016141948}`);
    window.history.back();
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      'selectedCarCategoryForModel': categoryDetails,
      'catDescriptionModalVisible': true
    });
  }

  hidePanel(panel) {
    if (panel == 'step1') {
      this.setState({ step1Panel: !this.state.step1Panel, step2Panel: false });
    } else if (panel == 'step2') {
      this.setState({ step1Panel: false, step2Panel: !this.state.step2Panel,form1Active:true,form2Active:true });
    }
  }

  timePicker(value){
    if(value && value.hours() && value.minutes()){
      if(moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue){
        if(value.startOf('minutes') < moment().add(1,'hours').startOf('minutes')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
        this.setState({
          submissionError: true,
          selectedTimeValue: value,
          hoursValue: value.hours(),
          minutesValue: value.minutes(),
        });
        } else{
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
        }
      } else{
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
          selectedTimeValue: value,
          hoursValue: value.hours(),
          minutesValue: value.minutes(),
        });
      }
    }
  }

  selectedDropdownText(location) {
    this.setState({ PreferedLocation: location });
  }

  onChasisChange(value) {
    this.setState({
      chassis_number: value!= " " ? value : ""
    });
  }

  
// The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number

  chassisFileUpload(e) {
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.selectedCarProfile.batchId,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.selectedCarProfile.batchId,
                    imageId,
                    true,
                    'CSN'
                  );
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError2(value),
          that.props.selectedCarProfile && that.props.selectedCarProfile.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      // Once the image is uploaded Successfully need to reset the ChassisPopUp value
      // so that its not displayed anymore
      this.setState({
        showChasis: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
        this.setState({
          showChasis: true
        });
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize                           
    });
  }

  render() {
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const CSNUploadView = this.imageThumbnails(
      this.state.chassisImages ? this.state.chassisImages : []
    );

    const CSN_w_o_UploadView = this.imageThumbnailsForChassis(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const {selectedService,selectedServiceId,servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, bookingRequestReducer, commonReducer, actions, garageProfileDetails,bookedRequest } = this.props;
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];

    let uploadImageLength = this.state.uploadImage.length;

    let imageUploadedView =this.state.uploadImage && uploadImageLength ? map(this.state.uploadImage, (img, index) => {
      if(!img.fileName){
        return (
          // <div key={index} className="upload-images"><img src={img.small} /></div>
           <div className='upload-box-wrapper box-shadow' >
             <div className="uploaded-images">
               <span className="cancel-image" onClick={() => { this.cancelUploadImage("uploadImage", index,img.id) }}>
                 <i className="mdi mdi-close" />
               </span>
               <img index={index} src={img.small} onClick={() => {
                    this.setState({showModal: true, imageStartIndex:index});
                  }}/>
             </div>
            </div>
         );
      } else if(!img.uploaded){
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
            <img src={img.path} />
            <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />
            </div>
          </div>
        );
      } else {
        return(
          <div className='upload-box-wrapper box-shadow' key={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("uploadImage", index,img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              <div className="upload-images">
                <img src={img.path} alt="" onClick={() => {
                    this.setState({showModal: true, imageStartIndex:index});
                }}/>
              </div>
            </div>
          </div>
        );
      }
    }) : '';
    return (
      <div className="panel-section car-wash car-category-wrapper p-panel">
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => { this.hidePanel('step1') }}>
          <span className= {this.state.form1Active?"n-step active":"n-step"}>1</span>
            <h4>Select Car Service Type</h4>
            <i className={this.state.step1Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {servicesFetched ? this.state.step1Panel && <div className="panel-content">
            {/* <div className="row">
              <div className="col-md-6 pad0">
                <div className="search-box">
                  <TextInput value={this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => { this.props.searchView(e);this.setState({searchValue:e}); }} />
                  <i className="mdi mdi-magnify" />
                </div>
              </div>
            </div> */}
            <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
            {/* {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState/>
            } */}
            {<div className="next-button">
              <Button disabled={selectedServiceId ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => { this.hidePanel('step2') }} />
            </div>}
          </div>:
          <PageLoader/>}
        </section>
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => {  selectedService && this.hidePanel('step2'); }}>
          <span className= {this.state.form2Active?"n-step ":"n-step active"}>2</span>
            <h4>Create A Car Service Request</h4>
            <i className={this.state.step2Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {this.state.step2Panel &&  selectedService &&
            <div className="panel-content">
              <div className="row">
                <div className="col-md-12 pad0">
                  <div className="form-section">
                    <h4 className="panel-sub-title">Select Car Profile</h4>
                    <div className="model-select form-group">
                      <h1 className="panel-sub-title">{selectedCarProfile && selectedCarProfile.name}</h1>
                    </div>
                  </div>
                  {/*<div className="form-section">
                  <h4 className="panel-sub-title">Chassis Number</h4>
                  <div className="model-select form-group">
                    <h1
                      className="panel-sub-title"
                      style={{
                        fontFamily: "Century Gothic",
                        fontSize: "16px",
                        lineHeight: "1em",
                        color: "#717982",
                      }}
                      >{(selectedCarProfile && selectedCarProfile.chassisNumber)? selectedCarProfile.chassisNumber : (CSNUploadView.length ? CSNUploadView : '-')}
                      </h1>
                  </div>
                    </div>*/}
                <div className="row car-profile">
                <div className="col-md-6 padLeft0">


                  <TextInput
                    label={this.state.chassis_number.trim()!= ""?"":"Chassis Number"}
                    name="chassis_number"
                    type="text"
                    customClass="noMargin"
                    disabled={(selectedCarProfile && selectedCarProfile.chassisNumber.trim()!= "") || CSNUploadView.length>0 || CSN_w_o_UploadView.length>0}
                    value={this.state.chassis_number!== "" ? this.state.chassis_number : " "}
                    onChange={this.onChasisChange.bind(this)}
                  />
                  <label className="mobChassis">
                      The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
                    </label>
                </div>
                 
                 
                 {((this.state.chassis_number).trim() === "") &&
                
                <div className="col-md-6 padRight0">
                  {
                    <div className="or-text-vert-job">
                  <span className="or-text-vert-span or-text-chassis">OR</span>
                    <div>
                      <h4 className="panel-sub-title">Upload images</h4>
                      <div className="img-uploads">
                        {(this.state.uploadFlag) && this.state.chassisImages.length < 1 ?
                          <Upload
                            margin={false}
                            id="chassisFileUpload"
                            fileUpload={(e) => this.chassisFileUpload(e)} /> : <div />}
                        {this.state.uploadFlag ? CSNUploadView : CSN_w_o_UploadView}

                      </div>
                      <div className="mobChassisFileSizeJob">
                        {this.state.uploadFlag &&  'The file has to be below 25MB. Please use JPG or PNG'}
                      </div>
                      <span className={this.state.uploadImageTypeErr ? "image-upload-error " : "image-upload-error  hide"}>
                        <p>Sorry, only JPG or PNG is accepted.
                                                Try again with image file.</p>
                        <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageTypeErr: false })} />
                      </span>
                      <span className={this.state.uploadImageSizeErr ? "image-upload-error " : "image-upload-error  hide"}>
                        <p>Sorry, uploaded image exceeds the file size limit of 25mb.
                                                Try again with another image.</p>
                        <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageSizeErr: false })} />
                      </span>
                      <span className={this.state.imageMaxLimitError ? "image-upload-error " : "image-upload-error  hide"}>
                        <p>Maximum allowed limit is 5.</p>
                        <i className="mdi mdi-close" onClick={() => this.setState({ imageMaxLimitError: false })} />
                      </span>
                    </div>
                  </div>}
                </div>
              }
              </div>

                  <div className="form-section">
                    <h4 className="panel-sub-title">Add Notes</h4>
                    <div className={this.errors.jobDetails && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                      <input type="text" defaultValue={bookedRequest && bookedRequest.details} className="jd-text" placeholder="Additional information you can provide" onChange={(e) => { this.jobDetails(e); }} />
                      {this.errors.jobDetails && this.state.submissionError && <span className="error-text">Enter Job Details</span>}
                    </div>
                  </div>

                  <div className="form-section">
                    <h4 className="panel-sub-title">Prefered Date</h4>
                    <div className="toggleBtn">
                      <span> This is an urgent request ! </span>
                      <ToggleSwitch
                        checked={this.state.checked}
                        size="small"
                        onChange={(event) => {
                          this.setState({ checked: !this.state.checked });
                        }}
                      />
                    </div>
                    {!this.state.checked && <div className="row date-time date-time-align">
                      <div className="col-md-6 padLeft0 timeDate_grid">
                        <DatePicker
                          selected={this.state.selectedDate}
                          onChange={this.handleChange}
                          minDate = {moment().add(1, 'hours')}
                        />
                      </div>
                      <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                        {/* <TimeInput value={this.state.TimePickerFrom} onChange={(e) => {this.timePicker(e); this.setState({ TimePickerFrom: e })}} /> */}
                        <TimePicker
                          showSecond= {false}
                          className= "custom-timepicker"
                          format= {'h:mm a'}
                          onChange= {this.timePicker.bind(this)}
                          defaultValue= {this.state.now}
                          use12Hours
                        />
                        {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                        </div>
                    </div>}
                  </div>

                </div>
                <div className="col-md-12 pad0">
                  <div className="form-section">
                    <h4 className="panel-sub-title">Prefered location</h4>
                    <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                      <div className="location-dd header-search">
                        <LocationSearchBox style={style} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined}/>
                      </div>
                      {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                    </div>
                    {this.state.selectedLocation && <div className="map-panel">
                      <div className="gmaps">
                        <Gmaps
                          center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                          zoom={12}
                          setCenter={true}
                          markers={markerLocation}
                          containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                          mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                        />
                      </div>
                    </div>}
                  </div>
                  <div className="form-section uploads car-service-upload">
                    <div className="row">
                      <h4 className="panel-sub-title">upload a image (Optional)</h4>
                      <div className="model-select upload">
                        {this.state.imageTotalCount < 5 &&  <Upload id="uploadImage" fileUpload={(e) => { this.fileNameUpload(e, 'uploadImage') }} />}
                        {imageUploadedView}
                        {/* {uploadedImages} */}
                        {/* {this.state.editRequestImageArray && this.state.editRequestImageArray.length > 0 &&  editRequestImage} */}
                      </div>
                      <span className={this.state.uploadImageErrText ? "image-upload-error" : "image-upload-error hide"}>
                        <p>Sorry, your image format is wrong or image size exceeds the limit of 20mb. Try again with another image</p>
                        <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageErrText: false })} />
                      </span>
                      <span className={this.state.imageCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                        <p>Only 5 images can upload</p>
                        {<i className="mdi mdi-close" onClick={() => this.setState({ imageCountErrText: false })} />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="next-button clearfix editPage-button-section">
                <Button btnType="transparent" btnSize="sm" fontSize={14} label="Cancel" btnCallBack={this.cancelEdit.bind(this)}/>
                <Button btnType="red" btnSize="lg" fontSize={14} label="Save" btnCallBack={this.requestQuotes.bind(this)} isButtonLoading={this.state.isButtonLoading} />
              </div>
            </div>
          }
        </section>
        {this.state.imageStartIndex > -1 ?
          <LightBox
            showUploadIcon={this.state.uploadImage.length < 5}
            actions={this.props.actions}
            images={this.state.uploadImage}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.fileNameUpload}
            cancelUploadImage = {this.cancelUploadImage}
            fromCreateRequest = {"uploadImage"}
            reportId ={"fromImage"}
          />
          : ''}
      </div>
    );
  }
}

export default EditServiceSteps;
