import React, { Component } from 'react';
import map from 'lodash/map';
import Gmaps from '../MyRequest/Gmaps';
import { imageBasePath } from '../../constants/staticData';
import SpriteMaterial from '../common/SpriteMaterial';


export default class VendorMaps extends Component {
  constructor(props) {
    super(props);
  }
  render(){
    const mapsCenter = { lat: 25.2048493, lng: 55.270782800000006 };
    let jobCardLocation = [];
    if( this.props.workshopsData  && this.props.workshopsData.length >0 ){
        jobCardLocation = map(this.props.workshopsData,(val, key) => {
        let coverImg = '';
        if (val && val.images && val.images.length) {
          coverImg = val.images[0] && val.images[0];
        }
        return {
          lat: Number(val.latitude),
          lng: Number(val.longitude),
          pinImage: true,
          name: val.companyprofilename,
          rating: val.rating,
          distance: val.distance,
          review: val.reviews,
          image: coverImg && coverImg.medium,
          zIndex: val.zIndex ? val.zIndex : 9
        };
      })
    }
    return(
        <div className="row" id="maps-section">
            <div  id ="maps-description-pop" className="hidden-sm-up">
            <div className="maps-details-mob">
              <img src={`${imageBasePath}/images/landing/map-icon.svg`} alt="map icon"/>
              <div className="maps-header2">Find expert auto mechanics near you.</div>
              <div className="maps-header3">Carcility certified garages in UAE</div>

              <div className="maps-details">
                  <div className="tickMaps-selection">
                  <span>
                  <SpriteMaterial posx={23} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/>
                  </span>Certified garages</div>
                  <div className="tickMaps-selection"><span><SpriteMaterial posx={23} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/></span>Skilled mechanics</div>
                  <div className="tickMaps-selection"><span><SpriteMaterial posx={23} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/></span>Workmanship Warranty</div>
              </div>
              </div>
          </div>
          <div className="col-md-6 maps-content col-sm-12 clearfix">
            <div  id ="maps-description">

              <img src={`${imageBasePath}/images/landing/map-icon.svg`} alt="map icon"/>
              <div className="maps-header3">Carcility certified garages in UAE</div>
              <div className="maps-header2">Find expert auto mechanics near you.</div>
              <div  className="maps-details">
                   <div className="tickMaps-selection" ><span><SpriteMaterial posx={773} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/></span>Certified garages</div>
                   <div className="tickMaps-selection"><span> <SpriteMaterial posx={773} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/></span>Skilled and reliable mechanics</div>
                   <div className="tickMaps-selection"><span> <SpriteMaterial posx={773} posy={0} width={26} height={23} widthBack={26} classNameVal='tick-maps'/></span>Workmanship Warranty</div>
              </div>
          </div>
        </div>
        {<div className= "col-md-6 col-sm-12 clearfix pad0" >
                    <div className={"mapSection"}>
                      <div className="quotes-right-body">
                      {/*  <Gmaps
                          mapDrag={false}
                          setCenter={false}
                          center={mapsCenter}
                          infoPopUp={true}
                          markers={{ jobCardLocation }}
                          zoom={5}
                          containerElement={<div style={{ height: 100 + '%' }} />}
                          mapElement={<div style={{ height: 100 + '%' }} />}
                        />*/}
                        <img src="https://maps.googleapis.com/maps/api/staticmap?center=25.2048,55.2708&zoom=13&scale=1&size=720x695&maptype=roadmap&key=AIzaSyCHA-6XNQkWe57C-GMN3cpWuBTBufh-hys&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:D%7C25.2048,55.2708" alt="Google Map of 25.2048,55.2708" />
                      </div>
                    </div>
                  </div> }</div>
    )
}
}
