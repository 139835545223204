import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class FavouritesEmptyState extends Component {
    render() {
        return (
            <div className="empty-state-holder">
                <span className="empty-state-content">
                    <img src={`${imageBasePath}/images/favourite_empty.png`} alt="" />
                    <span className="text">Oops! Looks like you don’t have any Favourites. </span>
                </span>
            </div>
        );
    }
}
