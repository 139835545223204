import React  from 'react';
import { imageBasePath } from '../../constants/staticData';


export default function WhyCarcility() {
    return(
        <div  id="whycarcility-section">
        <div>
            <div className="common-heading">
                <span>Why Carcility?</span>
            </div>
            <div className="row common-why-section">
                <div className="col-md-4 col-sm-4 col-xs-4 text-center why-section wow slideInUp">
                    <div className="advantage-list">
                        <div className="al-image-holder al-image-holder-align">
                        <div className="easyToUseSprite" width="1" height="1"></div>
                        {/*<img src={`${imageBasePath}/images/landing/book-car-service-in-dubai.svg`} alt="book car service in dubai" />*/}
                        </div>
                        <h5 >Easy to use</h5>
                        <p className="land-mob-hide">Book your car service online with a few clicks.</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4 text-center why-section wow slideInUp">
                <div className="advantage-list">
                    <div className="al-image-holder al-image-holder-align">
                    <div className="instantQuotesSprite"></div>
                    {/*<img src={`${imageBasePath}/images/instant-car-service-booking.svg`} alt="instant car service booking" />*/}
                    </div>
                    <h5 > Instant quotes </h5>
                    <p className="land-mob-hide">Receive and compare instant quotes from different service providers.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4  text-center why-section-last wow slideInUp last-image-holder-align">
                    <div className="advantage-list">
                    <div className="al-image-holder">
                        <div className="trustedServicesSprite"></div>
                        {/*<img src={`${imageBasePath}/images/trusted-car-booking-services.svg`} alt="trusted car booking services" />*/}
                        </div>
                        <h5 > Trusted service </h5>
                        <p className="land-mob-hide">
                        {' '}
                        Extensively vetted service providers, for a hassle-free experience.
                        {' '}
                        </p>
                    </div>
                    </div>
            </div>
            </div>
        </div>
    )
}
