import React, { Component } from 'react';
import { ProgressBar, Media, DropdownButton } from 'react-bootstrap';
import map from 'lodash/map';
import find from 'lodash/find';
import round from 'lodash/round';
import InfiniteScroll from 'react-infinite-scroller';
import PageLoader from '../common/PageLoader';
import { imageBasePath } from '../../constants/staticData';

export default class Reviews extends Component {
  constructor(...args) {
    super(...args);
    this.toggleSwitchVal = { Open24_7: false, showFavourites: false, authorizedBusinesses: false, dealsOffers: false, byCash: true, byCreditcard: false }
    this.state = {
      dropDownOpen: false,
      filterSort: "high-low",
      sortBydropdown: false,
      sortKey: ''
    };
    this.getRatingsCount = this.getRatingsCount.bind(this);
  }
  filterOption(selectedOption) {
    let sortKey = this.state.sortKey;
    if (selectedOption == "high-low") {
      sortKey = 'rating=-1';
    } else if (selectedOption == "low-high") {
      sortKey = 'rating=1';
    } else if (selectedOption == "new-old") {
      sortKey = 'date=-1';
    } else if (selectedOption == "old-new") {
      sortKey = 'date=1';
    }
    this.props.commonReducer.garageProfileDetails && this.props.actions.getRatingsAndReviews(this.props.commonReducer.garageProfileDetails.id, 0, 10, sortKey, false);
    this.setState({ sortKey: sortKey });
    this.setState({ filterSort: selectedOption, sortBydropdown: false });
  }

  getRatingsCount(ratingNumber, ratingArr) {
    let rating = find(ratingArr, { rating: ratingNumber });
    if (rating && rating.ratingCount) {
      return rating.ratingCount;
    } else {
      return 0;
    }
  }

  getRatingPercentage(totalCount, ratingCount) {
    return 100 * (ratingCount / totalCount);
  }

  render() {
    const { commonReducer } = this.props;
    const reviews = map(commonReducer.ratingsAndReviews, (review) => {
      let profilePhoto = '';
      if (review.profilePhoto && review.profilePhoto.length) {
        profilePhoto = review.profilePhoto.trim();
      }
      let reviewDate = new Date(review && review.createdAt);
      let monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      reviewDate = monthList[reviewDate.getMonth()] + ' ' + reviewDate.getDate() + ', ' + reviewDate.getFullYear();
      return (
        <li>
          <Media>
            <Media.Left>
              <img width={50} height={50} src={profilePhoto.length ? `${profilePhoto}` : `${imageBasePath}/images/notification1.png`} alt="Image" />
            </Media.Left>
            <Media.Body>
              <Media.Heading>{review.name ? review.name : ''}</Media.Heading>
              <div className="rating rating-left">
                <span className={review && review.rating >= 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={review && review.rating >= 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={review && review.rating >= 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={review && review.rating >= 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={review && review.rating >= 5 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              </div>
              <span className="commented-time">
                {reviewDate ? reviewDate : ''}
              </span>
            </Media.Body>
          </Media>
          <p>
            {review.comments ? review.comments : ''}
          </p>
        </li>
      );
    });
    let ratingsOverview = commonReducer.garageProfileDetails && commonReducer.garageProfileDetails.reviews && commonReducer.garageProfileDetails.reviews.overview ;
    let totalCount = ratingsOverview && ratingsOverview.totalCount ? ratingsOverview.totalCount : 0;
    return (
      <div className="vendor-services" id="n-reviews">
        <div className="vendor-description">
          <h4>{totalCount ? totalCount : 'No'} Reviews Found</h4>
          {totalCount ? <div className="filterSection">
            <DropdownButton bsSize="small" id="dropdown-size-small" onToggle={(isOpen) => { this.setState({ 'sortBydropdown': isOpen }); }} open={this.state.sortBydropdown} noCaret title={
              <div className="filterLabel showSortBy" >
                <label>Sort by</label>
                <i className="mdi mdi-chevron-down" />
              </div>
            }>
              <div className="sortFilter filterCard">
                <ul className="list-unstyled">
                  <li onClick={() => (this.filterOption("high-low"))} className={this.state.filterSort == "high-low" ? "active" : ""}>
                    <label>
                      Rating - Highest to Lowest
                                    </label>
                    <span>
                      {this.state.filterSort == "high-low" && <i className="mdi mdi-check" />}
                    </span>
                  </li>
                  <li onClick={() => (this.filterOption("low-high"))} className={this.state.filterSort == "low-high" ? "active" : ""}>
                    <label>
                      Rating - Lowest to Highest
                                    </label>
                    <span>
                      {this.state.filterSort == "low-high" && <i className="mdi mdi-check" />}
                    </span>
                  </li>
                  <li onClick={() => (this.filterOption("new-old"))} className={this.state.filterSort == "new-old" ? "active" : ""}>
                    <label>
                      Date - Newest to Oldest
                                    </label>
                    <span>
                      {this.state.filterSort == "new-old" && <i className="mdi mdi-check" />}
                    </span>
                  </li>
                  <li onClick={() => (this.filterOption("old-new"))} className={this.state.filterSort == "old-new" ? "active" : ""}>
                    <label>
                      Date - Oldest to Newest
                                    </label>
                    <span>
                      {this.state.filterSort == "old-new" && <i className="mdi mdi-check" />}
                    </span>
                  </li>
                </ul>
              </div>
            </DropdownButton>
          </div> : ''}
          {totalCount ? <div className="vendor-review-section">
            <div className="row">
              <div className="col-md-12 pad0">
                <div className="col-md-3 pad0">
                  <div className="vendor-overall-rating">
                    <label>{ratingsOverview && ratingsOverview.avgRating ? round(ratingsOverview.avgRating,1) : ''}</label>
                    <span>Overall rating <br /> out of 5 </span>
                  </div>
                </div>
                <div className="col-md-9 pad0">
                  <div className="vendor-customer-ratings">
                    <ul>
                      <li>
                        <label>Excellent</label>
                        <div className="rating">
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                        </div>
                        <div className="rating-progress">
                          <ProgressBar now={ratingsOverview ? this.getRatingPercentage(totalCount, this.getRatingsCount(5, ratingsOverview.ratings)) : 0} />
                        </div>
                        <span className="ratings-count">{ratingsOverview ? this.getRatingsCount(5, ratingsOverview.ratings) : 0}</span>
                      </li>
                      <li>
                        <label>Very Good</label>
                        <div className="rating">
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star-outline" />
                        </div>
                        <div className="rating-progress">
                          <ProgressBar now={ratingsOverview ? this.getRatingPercentage(totalCount, this.getRatingsCount(4, ratingsOverview.ratings)) : 0} />
                        </div>
                        <span className="ratings-count">{ratingsOverview ? this.getRatingsCount(4, ratingsOverview.ratings) : 0}</span>
                      </li>
                      <li>
                        <label>Average</label>
                        <div className="rating">
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                        </div>
                        <div className="rating-progress">
                          <ProgressBar now={ratingsOverview ? this.getRatingPercentage(totalCount, this.getRatingsCount(3, ratingsOverview.ratings)) : 0} />
                        </div>
                        <span className="ratings-count">{ratingsOverview ? this.getRatingsCount(3, ratingsOverview.ratings) : 0}</span>
                      </li>
                      <li>
                        <label>Poor</label>
                        <div className="rating">
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                        </div>
                        <div className="rating-progress">
                          <ProgressBar now={ratingsOverview ? this.getRatingPercentage(totalCount, this.getRatingsCount(2, ratingsOverview.ratings)) : 0} />
                        </div>
                        <span className="ratings-count">{ratingsOverview ? this.getRatingsCount(2, ratingsOverview.ratings) : 0}</span>
                      </li>
                      <li>
                        <label>Terrible</label>
                        <div className="rating">
                          <span className="mdi mdi-star" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                          <span className="mdi mdi-star-outline" />
                        </div>
                        <div className="rating-progress">
                          <ProgressBar now={ratingsOverview ? this.getRatingPercentage(totalCount, this.getRatingsCount(1, ratingsOverview.ratings)) : 0} />
                        </div>
                        <span className="ratings-count">{ratingsOverview ? this.getRatingsCount(1, ratingsOverview.ratings) : 0}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> : ''}
          <div className="vendor-comments-section">
            <div className="vendor-comments-container">
              <ul>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => { commonReducer.garageProfileDetails && this.props.actions.getRatingsAndReviews(commonReducer.garageProfileDetails.id, commonReducer.ratingsAndReviews.length, 10, this.state.sortKey ? this.state.sortKey : 'rating=-1', true); }}
                  hasMore={commonReducer.ratingsAndReviews && commonReducer.ratingsAndReviews.length < totalCount}
                  loader={<PageLoader />}>
                  {commonReducer.ratingsAndReviews && commonReducer.ratingsAndReviews.length ? reviews : <div />}
                </InfiniteScroll>
              </ul>
            </div>
            {/* <div className="load-more-comments">
              <Button btnType="transparent" btnSize="sm" fontSize={13} label="Load More" />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
