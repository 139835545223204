import React, { Component } from 'react';
import Footer from '../Landing/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import SummaryCollpasePanel from '../common/SummaryCollpasePanel';
import { MenuItem } from 'react-bootstrap';
import findIndex  from 'lodash/findIndex';
import filter  from 'lodash/filter';
import take  from 'lodash/take';
import map  from 'lodash/map';

import { Link } from 'react-scroll';
import Panel1 from './Panel1';
import Panel2 from './panel2/Panel2';
import Panel3 from './Panel3';
import Panel4 from './Panel4';
import ToastMessage from '../common/ToastMessage';
import { queryStringtoArray, removeParameterFromUrl } from '../../helpers/index';
import Scroll from 'react-scroll';
import { imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'

class GuestUserFlow extends Component {
  constructor(props) {
    super(props);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : {};
    this.searchVendors = this.searchVendors.bind(this);
    this.state = {
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : "",
      searchText: "",
      autoCompleteData: [],
      autoSuggestedList: [],
      alreadySearched: false,
      open: false,
      showTab: "panel1",
      activeRequestType: "",
      activeRequestTypeLabel: "",
      getCarProfileData: {},
      getCarWashData: [],
      getCarRepairData: [],
      getCarServiceData: [],
      getRequestDetails: {},
      navbarVisible: false,
      sidebarOpen: false,
      sidebarProfileDropdown: false,
      panel1Valid: false,
      panel2Valid: false,
      panel3Valid: false,
    };
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        'locationSearchTerm': searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ 'input': searchTerm }, function (res) {
        that.setState({
          'autoCompleteData': res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        'locationSearchTerm': searchTerm,
        'autoCompleteData': []
      });
    }
  }

  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { 'lat': Number(location.lat), 'lng': Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          this.setState({ 'userLocation': results[0], 'locationSearchTerm': searchTerm || location.description || results[0].formatted_address, 'showLocationDropdown': false });
          that.props.actions.setCurrentLocation({
            'lat': results[0].geometry.location.lat(),
            'lng': results[0].geometry.location.lng(),
            'formatted_address': results[0].formatted_address,
            'label': searchTerm || location.description || results[0].formatted_address
          })
        } else {
          this.setState({ 'showLocationDropdown': false });
        }
      });
    }
  }

  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == "keyword") {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, "distance");
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == "autosuggest") {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, "distance");
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList && !this.state.autoSuggestedList.length) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    if (!localStorage.getItem('authData') && !this.state.alreadySearched) {
      if (queryStringtoArray('q')) {
        this.searchVendors(queryStringtoArray('q'), 'keyword');
        this.setState({ alreadySearched: true, serviceSearchTerm: decodeURI(queryStringtoArray('q')), searchText: decodeURI(queryStringtoArray('q')) });
      } else {
        // this.searchVendors('', 'keyword');
        const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
        let searchLocation = userSelectedLocation;
        if (searchLocation == undefined) {
          searchLocation = this.props.commonReducer.userLocation;
        } else {
          searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
        }
        if (searchLocation && searchLocation.latitude && searchLocation.longitude && (queryStringtoArray('lat'))) {
          this.props.actions.searchVendors(false, false, searchLocation, 'distance');
        }
        this.setState({ alreadySearched: true });
      }
    }

  }
  componentWillMount() {
    this.props.actions.getManufacturers();
    this.props.actions.getAllServices();
    if (!(this.props.commonReducer.autoSuggestedList && this.props.commonReducer.autoSuggestedList.length)) {
      this.props.actions.getAutosuggestedList();
    }
    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    const userId = localStorage.getItem("userId");
    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {


       let provider = authData.provider?authData.provider:"normal";
        let urlData = "?method="+provider+"&route=guestuser";

        this.props.router.push(`${appBasePath}/dashboard-plus`+urlData);
      }
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  activeRequest(val) {


    let activeRequestTypeLabel = "";
    switch (val) {
      case 'wash':
        activeRequestTypeLabel = "Car Wash";
        break;
      case 'service':
        activeRequestTypeLabel = "Car Service";
        break;
      case 'repair':
        activeRequestTypeLabel = "Car Repair";
        break;
      default:
        activeRequestTypeLabel = "Service yet to be selected";
    }
    this.setState({
      activeRequestType: val,
      activeRequestTypeLabel: activeRequestTypeLabel,
      getCarWashLabelData: [],
      getCarRepairListData: [],
      getCarServiceName: "",
      categoriesEdit: false

    });
  }

  // toggleNextButton(val, callback) {
  //   if (callback)
  //     this.setState({ showTab: val });
  // }
  getCarProfileData(val) {
    this.setState({
      getCarProfileData: val,
      activeRequestType: this.state.activeRequestType? this.state.activeRequestType :"wash",
      activeRequestTypeLabel: this.state.activeRequestTypeLabel? this.state.activeRequestTypeLabel :"Car Wash",
    });
  }

  getCarWashData(val) {

    let categoryIdArray = [];
    let categoryLabelArray = [];
    val.forEach(function (val) {
      if (val.checked) {
        categoryIdArray.push(val.id);
        categoryLabelArray.push(val.name);
      }
    });

    this.setState({
      getCarWashData: categoryIdArray,
      getCarWashLabelData: categoryLabelArray,
      categoriesEdit: true
    });
  }

  getCarServiceData(val) {

    this.setState({
      getCarServiceData: [val.id],
      getCarServiceName: val.name,
      categoriesEdit: true
    });
  }

  getCarRepairData(val) {

    let categoryIdArray = [];
    map(val, (subCat, key) => {
      filter(subCat.sub_services, (checked) => {
        if (checked.checked == true) {
          categoryIdArray.push(checked.id);
        }
      });
    });

    this.setState({
      getCarRepairData: categoryIdArray,
      getCarRepairListData: val,
      categoriesEdit: true
    });
  }

  getRequestDetails(val) {
    this.setState({
      getRequestDetails: val,
    });
  }

  toggleNextButton(val, callback) {
    let scroll = Scroll.animateScroll;
    if (callback) {
      scroll.scrollTo(100);
      this.setState({
        showTab: val,
      });
    }
  }

  panel1Valid(isValid) {
    this.setState({
      panel1Valid: isValid
    });
  }

  panel2Valid() {
    this.setState({
      panel2Valid: true,
    });
  }

  panel3Valid() {
    this.setState({
      panel3Valid: true
    });
  }



  render() {
    let baseUrl = location.origin;
    const tagManagerArgs = {
      dataLayer: {
        pageCategory: 'Guest User Sign Up',
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


    let searchView = filter(this.state.autoSuggestedList, (val) => {
      if (this.state.searchText != "" && val.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
        return val;
      }
    });
    searchView = take(searchView, 5);
    const searchAutoSuggestedList = map(searchView, (val, key) => {
      return (
        <MenuItem onClick={() => {
          this.setState({ 'searchText': val.name });
          // this.searchVendors(val, 'autosuggest');
        }} eventKey={key} key={key}>{val.name}</MenuItem>
      );
    });

    let locationData = [{ "textPlaceholder": "Current Location" }]
    map(this.state.autoCompleteData, (location, key) => {
      return (
        locationData.push({ "key": key, "description": location.description, 'place_id': location.place_id })
      );
    });
    map(this.props.commonReducer && this.props.commonReducer.userLocations, (location, key) => {
      return (
        locationData.push({ "key": key, 'lat': location.lat, 'lng': location.lng, 'label': location.label, 'id': location.id, 'formatted_address': location.location })
      );
    });
    const locationAutosuggestedList = map(locationData, (val, key) => {
      return (
        <MenuItem onClick={(item, ) => {
          if (item.target.text != "Current Location") { this.setUserLocation(val, item.target.text) }
        }} eventKey={key} key={key}>{val.description ? val.description : val.label ? val.label : val.textPlaceholder}</MenuItem>
      );
    });
    const carWashSelectedList = map(this.state.getCarWashLabelData, (val, key) => {
      return (
        <li key={key} >
          <label>{val}</label>
        </li>
      );
    });


    const genericFooterItem = [
      {
        footerHeading: 'Carcility',
        sublist: [
          {
            title: 'About Us',
            hyperLink: '/about'
          },
          {
            title: 'Blog',
            hyperLink: '/blog'
          },
          {
            title: 'Contact Us',
            hyperLink: '/contact'
          }
        ]
      },
      {
        footerHeading: 'For Customer',
        sublist: [
          {
            title: 'Mobile Apps',
            hyperLink: ''
          },
          {
            title: 'Help',
            hyperLink: '/blog/faq'
          }
        ]
      },
      {
        footerHeading: 'For Business',
        sublist: [
          {
            title: 'Join Us',
            hyperLink: '/business'
          },
          {
            title: 'Benefits',
            hyperLink: '/business'
          },
          {
            title: 'Sign Up',
            hyperLink: '/business/sign-up'
          }
        ]
      }
    ];
    const genericFooterLink = map(genericFooterItem, (item, key) => {
      return (
        <div className="col-md-2 col-sm-3 col-xs-12 pad0" key={key}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTagData['guestUser'] && metaTagData['guestUser'].title ? metaTagData['guestUser'].title : 'Carcility'}</title>
            <meta name="description" content={metaTagData['guestUser'] && metaTagData['guestUser'].description ? metaTagData['guestUser'].description : ''} />
            <link rel="canonical" href={canonicalLink} />
          </Helmet>
          <div className="footer-link">
            <h5>{item.footerHeading}</h5>
            <ul className="list-unstyled">
              {map(item.sublist, (subListVal, index) => {
                return (
                  <li key={index}>
                    {subListVal.title == 'Mobile Apps' &&
                    <Link to="download" smooth={true} offset={-100} duration={600}>
                      Mobile Apps
                    </Link>}
                    {subListVal.title != 'Mobile Apps' &&
                    <a
                      href={baseUrl + subListVal.hyperLink}
                      onClick={() => {
                        if (subListVal.title === 'Benefits') {
                          window.localStorage.setItem(subListVal.title, true);
                        }
                      }}
                    >
                      {subListVal.title}
                    </a>}

                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    });
    const socialIcon = [
      {
        title: 'facebook',
        hyperLink: 'https://www.facebook.com/carcility'
      },
      {
        title: 'twitter',
        hyperLink: 'https://twitter.com/carcility'
      },
      {
        title: 'instagram',
        hyperLink: 'https://www.instagram.com/carcility'
      }
    ];
    const socialLink = map(socialIcon, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
            <i className={'mdi mdi-' + item.title} />
          </a>
        </li>
      );
    });


    return (
      <div className="guf">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['guest-flow'] && metaTagData['guest-flow'].title ? metaTagData['guest-flow'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['guest-flow'] && metaTagData['guest-flow'].description ? metaTagData['guest-flow'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        {!localStorage.getItem('authData') ? <div id="landing" ref="landing">
          <nav className="navbar navbar-default default-header search-result-header" id="header-section1">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={() => { this.setState({ 'navbarVisible': !this.state.navbarVisible }) }}>
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand desk-logo" href={baseUrl} onClick={() => { this.props.router.push(appBasePath + '/') }}>
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                </a>
                {/*<a className="navbar-brand mobile-logo" href="https://www.carcility.com" onClick={()=>{this.props.router.push('/')}}>
                  <img src="/images/logo.svg" />
                </a>*/}
              </div>
              <div id="navbar" className={this.state.navbarVisible ? "navbar-collapse" : "navbar-collapse collapse"}>
                <ul className="nav navbar-nav navbar-right">
                  <li className="work-active">
                    <Link to="how-works" smooth={true} offset={-100} duration={600}>
                      how it works
                    </Link>
                    <label></label>
                  </li>
                  <li>
                    <a href="/business">
                      For Business
                    </a>
                    <label></label>
                  </li>
                  <li>
                    <a onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/sign-in'); }}>Log In </a>
                    <label></label>
                  </li>
                  <li>
                    <a onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/register'); }}> Sign Up</a>
                    <label></label>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-default scroll-header search-result-header" id="header-section2">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={() => { this.setState({ 'navbarVisible': !this.state.navbarVisible }) }}>
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href={baseUrl} onClick={() => { this.props.router.push(appBasePath + '/') }}>
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                </a>
              </div>
              <div id="navbar" className={this.state.navbarVisible ? "navbar-collapse" : "navbar-collapse collapse"}>
                {/*Search Option*/}
                {/*<ul className="nav navbar-nav nav-left">
                  <li>
                    <div className="banner-search">
                      <div className="search-wrapper">
                        <form className="form-inline">
                          <DropdownButton bsSize="small" id="dropdown-size-small" noCaret onToggle={(e) => this.setState({ locateMeDD: e })}
                            onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                            className="location-search" title={
                              <span className="search-left">
                                <i className="mdi mdi-crosshairs-gps"></i>
                                <input type="text" className="form-control" placeholder="Locate Me" value={this.state.locationSearchTerm} onChange={(e) => { e.preventDefault(); this.autocompleteLocation(e); this.setState({ 'locationSearchTerm': e.target.value }) }} />
                                <i className={this.state.locateMeDD ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
                              </span>

                            }>
                            {locationAutosuggestedList}
                          </DropdownButton>
                          <DropdownButton bsSize="small" id="dropdown-size-small"
                            noCaret
                            onToggle={(e) => this.setState({ serviceDD: e })}
                            open={this.state.searchText == "" ? false : this.state.serviceDD}
                            onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                            onSelect={(id, e) => {
                              this.setState({ serviceSearchTerm: e.target.innerText });
                            }}
                            className="location-search search-service-block" title={
                              <span className="search-right" onClick={() => this.setState({ blinkText: false })}>
                                <input type="text" ref={(focusFunction) => this.inputFocus = focusFunction} className="form-control" onChange={(e) => { e.preventDefault; const serviceSearchTerm = e.target.value; this.setState({ 'serviceSearchTerm': serviceSearchTerm }) }} value={this.state.serviceSearchTerm} />
                                <input className={'hide'} type="submit" onClick={(e) => { e.preventDefault(); this.searchVendors(this.state.searchText, 'keyword'); }} />
                                <span onClick={() => { this.inputFocus.focus() }} className={this.state.blinkText ? "blink-text" : "blink-text hide"}>
                                  <ReactRotatingText items={["What service do you need today?"]} />
                                </span>
                                <i className="mdi mdi-magnify" onClick={() => { this.searchVendors(this.state.searchText, 'keyword'); }} />
                              </span>

                            }>
                            {searchAutoSuggestedList}
                          </DropdownButton>
                        </form>
                      </div>
                    </div>
                  </li>
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <Button isSubmitBtn={true} btnCallBack={() => { this.props.router.push('/guest-user'); }} btnType="red" btnSize="sm" fontSize={13} label="Get Started" />
                    </li>
                    <li className="menu-option" onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen, sidebarProfileDropdown: false })}>
                      <i className="mdi mdi-menu"></i>
                    </li>
                </ul> */}
              </div>
            </div>
          </nav>
        </div> : ''}
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className={localStorage.getItem('authData') ? "main-wrapper" : "main-wrapper no-padding no-margin"}>
          {/* <Sidebar router={this.props.router} /> */}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Please tell us about your ride and service you need</h4>
            </div>
          </div>

          <div className="inSection gf-panel gf-container">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-body col-md-8 gf-panel-container">
                <form>
                  <Panel1
                    panel1Valid={this.panel1Valid.bind(this)}
                    selectedTab={this.toggleNextButton.bind(this)}
                    {...this.props}
                    toggleNextButton={this.toggleNextButton.bind(this)}
                    tabOpen={this.state.showTab}
                    getCarProfileData={this.getCarProfileData.bind(this)}
                  />
                  <Panel2 panel1Valid={this.state.panel1Valid} panel2Valid={this.panel2Valid.bind(this)} selectedTab={this.toggleNextButton.bind(this)} {...this.props} toggleNextButton={this.toggleNextButton.bind(this)} tabOpen={this.state.showTab} activeRequest={this.activeRequest.bind(this)} getCarWashData={this.getCarWashData.bind(this)} getCarServiceData={this.getCarServiceData.bind(this)} getCarRepairData={this.getCarRepairData.bind(this)} />
                  <Panel3 panel2Valid={this.state.panel2Valid} panel3Valid={this.panel3Valid.bind(this)} selectedTab={this.toggleNextButton.bind(this)} {...this.props} toggleNextButton={this.toggleNextButton.bind(this)} tabOpen={this.state.showTab} activeRequestType={this.state.activeRequestType} getRequestDetails={this.getRequestDetails.bind(this)} />
                  <Panel4 panel3Valid={this.state.panel3Valid} selectedTab={this.toggleNextButton.bind(this)} {...this.props} toggleNextButton={this.toggleNextButton.bind(this)} tabOpen={this.state.showTab} getCarProfileData={this.state.getCarProfileData} activeRequestType={this.state.activeRequestType} getCarWashData={this.state.getCarWashData} getCarRepairData={this.state.getCarRepairData} getCarServiceData={this.state.getCarServiceData} getRequestDetails={this.state.getRequestDetails} />


                </form>

              </div>
              <div className="myCar-body col-md-4">
                <div className="car-summary">
                  <div className="summary-heading">
                    Summary
                  </div>
                  <div className="car-wrapper">
                    <div className="type-card">
                      <div className="type-img car-img guest-img">
                        <div className="type-img car-img">
                          <span className="car-profile-placeholder">
                            <img src={`${imageBasePath}/images/default-guest-car-profile.jpg`} alt="Carcility" />
                          </span>
                        </div>
                      </div>
                      <div className="type-desc type-desc-guest">
                        <h5>Car Profile</h5>
                        <label className="label-text">{this.state.getCarProfileData.name ? this.state.getCarProfileData.name : "Car Profile yet to be selected"}</label>
                      </div>
                    </div>

                  </div>
                  <div className="car-wrapper">
                    <div className="type-card">
                      <div className="type-img car-img guest-img">
                        {
                          (() => {
                            if (this.state.activeRequestType == "wash")
                              return (<img src={`${imageBasePath}/images/book-service-1.png`} alt="" />);
                            if (this.state.activeRequestType == "service")
                              return (<img src={`${imageBasePath}/images/book-service-2.png`} alt="" />);
                            if (this.state.activeRequestType == "repair")
                              return (<img src={`${imageBasePath}/images/book-service-3.png`} alt="" />);
                            else
                              return (<img src={`${imageBasePath}/images/placeholder.png`} alt="" />);
                          })()
                        }

                      </div>
                      <div className="type-desc type-desc-guest">
                        <h5>Service Type</h5>
                        <label className="label-text">{this.state.activeRequestTypeLabel ? this.state.activeRequestTypeLabel : "Category yet to be selected"}</label>
                      </div>
                    </div>
                  </div>
                  <div className="category-wrapper car-wrapper">
                    <div className="category-heading">
                      <h5>Categories Selected</h5>
                      {this.state.categoriesEdit ?
                        <label>
                          <a onClick={() => this.setState({ showTab: "panel2" })}>Edit</a>
                        </label>
                        :
                        []}
                    </div>
                    <div className="category-list">

                      {
                        (() => {
                          if (this.state.activeRequestType == "wash") {
                            if (this.state.getCarWashLabelData)
                              return (<ul className="guf-category-ul">{carWashSelectedList}</ul>);
                          } else if (this.state.activeRequestType == "service") {
                            if (this.state.getCarServiceName)
                              return (<ul className="guf-category-ul"><li><label>{this.state.getCarServiceName}</label></li></ul>);
                          } else if (this.state.activeRequestType == "repair") {
                            return (
                              map(this.state.getCarRepairListData, (subCat, key) => {
                                let count = 0;
                                let checkedItem = false;
                                filter(subCat.sub_services, (checked) => {
                                  if (checked.checked == true) {
                                    checkedItem = true;
                                    count += 1
                                  }
                                });
                                if (checkedItem == true) {
                                  return (
                                    <SummaryCollpasePanel subCat={subCat} selectedChkBoxCount={count} />
                                  );
                                }
                              })
                            )
                          }
                        })()
                      }




                    </div>
                  </div>
                  {!queryStringtoArray('vendor_id') ? <div className="car-wrapper">

                      <div className="type-card">
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                    <div className="car-wrapper">
                      <div className="type-card">
                        {this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.logo ?
                          <div className="type-img car-img">
                            <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                          </div>
                          :
                          <div className="type-img car-img">
                            <span className="car-profile-placeholder">
                              <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                            </span>
                          </div>
                        }
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className={localStorage.getItem('authData') ? "footerSection" : "footerSection guest-user-footer"}>
          <div className="container">
            <div className="guest-appLink-section">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                    <h1 className="guest-appLink-section">Carcility connects you with all the car
                    services under the sun - Be it car repair, car wash, car detailing or
                    your regular car service. It can be tough to select the right person
                    to trust your car with when you have so many options around you but so
                    little time and energy to explore them. With Carcility, you can easily
                    create a profile for your car and select the service that your car needs.
                    You will immediately be connected to all the vendors with their quotes
                    in an order of your preference, be it location or timing. So now even
                    if it's just an oil change or a dent removal that your car needs,
                    don't wait for the day of your car service. Connect now with trusted vendors.
                    </h1>
                </div>
              </div>
            </div>
        </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default GuestUserFlow;
