import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
export default class SaveLocationModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      label: undefined,
      labelError: false
    };
  }
  onFieldChange(e) {
    const value = e.target.value;
    if (trim(value)) {
      this.setState({'labelError': false, 'label': value});
    } else {
      this.setState({'labelError': true, 'label': value});
    }
  }
  saveLocation(e) {
    const that = this;
    if (trim(this.state.label)) {
      this.setState({'labelError': false});
      let geocoder = new google.maps.Geocoder();
      if (this.props.saveLocationData.userLocation) {
        that.props.saveLocationCallBack({
          'lat': this.props.saveLocationData.userLocation.lat,
          'lng': this.props.saveLocationData.userLocation.lng,
          'label': that.state.label,
          'location': this.props.saveLocationData.formatted_address
        });
      } else {
        that.props.saveLocationCallBack({
          'lat': this.props.saveLocationData.lat,
          'lng': this.props.saveLocationData.lng,
          'label': that.state.label,
          'location': this.props.saveLocationData.formatted_address
        });
      }
    } else {
      this.setState({'labelError': true});
    }
  }
  getCurrentLocationFromLatLng(latlng, hideModal){
    const that = this;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {'lat' : latlng.lat, 'lng': latlng.lng}}, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && results && results.length) {
        that.props.selectLocationCallBack({
          userLocation: {
            'lat': results[0].geometry.location.lat(),
            'lng': results[0].geometry.location.lng()
          },
          'formatted_address': results[0].formatted_address
        });
      }
      that.props.hideModal();
    });
  }
  render(){
    const {title, saveLocationData} = this.props;
    const newLocationData = cloneDeep(saveLocationData);
    return (
      <Modal onHide={this.props.hideModal} show={true}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
              <h5 className="caption">Address</h5>
              <span className="caption-result">{saveLocationData.description || saveLocationData.formatted_address}</span>
          </div>
          <div className={this.state.labelError ? "form-group error" : "form-group"}>
              <h5 className="caption">Location Label</h5>
              <input type="text" value={this.state.label} className="form-input plain-input" onChange={this.onFieldChange.bind(this)} />
              {this.state.labelError && <span className="error-text">Please enter label</span>}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button btnType="transparent" btnSize="sm" fontSize={15} label={this.props.cancelText?this.props.cancelText:"Cancel"}  btnCallBack={this.props.hideModal}/>
          <Button btnType="red" btnSize="sm" fontSize={15} label={this.props.saveText?this.props.saveText:"Save"} btnCallBack={this.saveLocation.bind(this)} />
        </Modal.Footer>
      </Modal>
    );
  }
}
