import * as types from './actionTypes';
import * as API_END_POINTS from '../constants/api.js';
import axios from 'axios';

export function changePasswordAction (changeData) {
 return (dispatch) => {
  //axios.d efaults.headers.common['x-access-token'] = localStorage.getItem('accessToken');
  axios.put(API_END_POINTS.CHANGE_PASSWORD, JSON.stringify(changeData), {
      headers: {
        'Accept': 'application/json,',
        'Content-Type': 'application/json'
      },
     // withCredentials:true
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage: 'Password Change succesfully'
        });
      }
    })
    .catch((err) => {
     dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:  err.response && err.response.data && err.response.data.message ? err.response.data.message :'Unknown error occurred please try again'
        });
    });
   };
  }

  export function getNotificationInfo(methodType,notificationInfo) {
    return (dispatch) => {
       const postMethod = (methodType == 'get') ? axios.get : axios.post;
      axios.defaults.headers.common['x-access-token'] = localStorage.getItem('accessToken');
      postMethod(API_END_POINTS.GET_NOTIFICATIOINFO,notificationInfo,{
        headers: {
          'Accept': 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials:true
      })
      .then((response) => {
        if (response.data && response.data.status === 1) {
          if(methodType == 'post'){
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Notification Preference Change succesfully'
           });
        }
        dispatch({
            type: types.GET_NOTIFICATION_INFO,
            notificationRequests: response.data
          });
      } else {
        dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Unknown error occurred please try again'
           });
      }
      })
      .catch((err) => {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Unknown error occurred please try again'
          });
      });
    };
  }

  export function contactDetailsUpdate(changeData) {
 return (dispatch) => {
  //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('accessToken');
  axios.put(API_END_POINTS.CONTACTDETAILSUPDATE, JSON.stringify(changeData), {
      headers: {
        'Accept': 'application/json,',
        'Content-Type': 'application/json'
      },
      //withCredentials:true
    })
    .then((response) => {
      if (response.status === 200) {
        let localDetails = JSON.parse(localStorage.getItem('authData'));
        if(changeData.otp) {
          localDetails.name =response.data.name;
            if(response.data.profile_photo) {
              localDetails.profilePhoto = response.data.profile_photo || response.data.profilePhoto;
            }
          localDetails.phone =response.data.phone;
          localStorage.setItem('authData',JSON.stringify(localDetails));
          window.history.back();
        } else {
          localDetails.name =response.data.name;
          if(response.data.profile_photo) {
              localDetails.profilePhoto = response.data.profile_photo;
            }
          localStorage.setItem('authData',JSON.stringify(localDetails));
        }
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage: 'Contact Details Updated succesfully'
        });
      }
    })
    .catch((err) => {
     dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:  err.response && err.response.data && err.response.data.message ? err.response.data.message :'Unknown error occurred please try again'
        });
    });
   };
  }


export function sendOtp (phoneNumber) {
 return (dispatch) => {
  //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('accessToken');
  axios.post(API_END_POINTS.SENDOTP, JSON.stringify(phoneNumber), {
      headers: {
        'Accept': 'application/json,',
        'Content-Type': 'application/json'
      },
     // withCredentials:true
    })
    .then((response) => {
      if (response.status === 200) {
        alert('otp is sent to the mobile');
      }
    })
    .catch((err) => {
     dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:  err.response && err.response.data && err.response.data.message ? err.response.data.message :'Unknown error occurred please try again'
        });
    });
   };
  }


export function sign_request(file, done) {
  return (dispatch) => {
    axios.get(API_END_POINTS.IMAGE_UPLOAD_REQUEST+ file.name + "&file_type=" + file.type +"&for=dp", {
      headers: {
        'Accept': 'application/json,',
        'Content-Type': 'application/json'
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let responseData = response;
        done(responseData);
      }
    }).catch((err) => {
     dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:  err.response.data && err.response.data.message ? err.response.data.message :'Unknown error occurred please try again'
        });
    });
  };
}

export function uploadImageDP(file, signed_request,url,done) {
  return (dispatch) => {
    axios.put(signed_request , file, {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let responseData = response;
            done(responseData);
      }
    }).catch((err) => {
     dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:  err.response.data && err.response.data.message ? err.response.data.message :'Unknown error occurred please try again'
        });
    });

  };
}

