import React, { Component } from 'react';
import TextInput from '../../common/TextInput';
import each  from 'lodash/each';
import map  from 'lodash/map';
import has  from 'lodash/has';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import Upload from '../../common/Upload';
import Gmaps from '../../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
var uniqid = require('uniqid');
import LocationSearchQuickBox from '../../common/LocationSearchQuickBox';
import Scroll from 'react-scroll';
import TimePicker from 'rc-time-picker';
import PageLoader from '../../common/PageLoader';
import ServiceTypeEmptyState from '../../EmptyState/ServiceTypeEmptyState';
import LightBox from '../../common/LightBox';
import { imageBasePath, appBasePath } from '../../../constants/staticData';
import ChasisNumberPopup from '../../common/ChasisNumberPopup';

class QuickRepairSteps extends Component {
  constructor(props) {
    super(props);
    let momentdate = moment().add(1,'hours');
    let momentPicker = new Date(momentdate._d);
    this.state = {
      step1Active: false,
      step2Active: null,
      accidentRadioBtn: false,
      selectedDate: moment().add(1, 'hours'),
      generalRadioBtn: true,
      uploadImgSize: 0,
      policeReportImgSize: 0,
      rationCardImgSize: 0,
      drivingLicenceImgSize: 0,
      uploadImage: [],
      policeReport: [],
      carImages: [],
      chassisImages: [],
      newImagesArray: [],
      policeReportImage: false,
      rationCardImage: false,
      drivingLicenceImage: false,
      rationCard: [],
      drivingLicence: [],
      PreferedLocation: 'Select Location',
      startDate: "",
      step1Panel: true,
      step2Panel: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      now: moment().add(1, 'hours'),
      uploadImageErrText: false,
      policeReportErrText: false,
      drivinLicenceErrText: false,
      registrationcardErrText: false,
      rationCardErrText: false,
      drivingLicenceCountErrText: false,
      selectedCarProfileId: '',
      dateValue: momentPicker.getDate(),
      monthValue: momentPicker.getMonth(),
      yearValue: momentPicker.getFullYear(),
      jobDetails: '',
      hoursValue: moment().add(1, 'hours').hours(),
      minutesValue: moment().minutes(),
      checked: false,
      selectedLocation: '',
      showModal: false,
      currentTime: '',
      selectedTimeValue: '',
      batchID: uniqid(),
      imageTotalCount: 0,
      searchValue: '',
      policeReportTotalCount: 0,
      imageStartIndex: 0,
      policeReportStartIndex: 0,
      registrationReportStartIndex: 0,
      drivingReportStartIndex: 0,
      registrationCardCount: 0,
      drivingLicenceCardCount: 0,
      imageCountErrText: false,
      policeReportCountErrText: false,
      createRequestImageArray: [],
      createRequestFileArray: [],
      isButtonLoading: false,
      policeReportId: undefined,
      registrationId: undefined,
      drivingLicenceId: undefined,
      policeReportUrl: undefined,
      registrationUrl: undefined,
      drivingLicenseUrl: undefined,
      newImageIdArray: [],
      chassis_number: '',
      showChasis: false,
      fillChassis: true,
      uploadFlag: false
    };
    this.formData = {
      selectedLocation: '',
      selectedCarProfileId: this.state.selectedCarProfileId,
      timeValid: moment().add(1, 'hours')
    };
    this.errors = {
      selectedLocation: false,
      selectedCarProfileId: false,
      timeValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.cancelUploadImage = this.cancelUploadImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadImageError = this.uploadImageError.bind(this);
    this.uploadFileError = this.uploadFileError.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.imageThumbnails = this.imageThumbnails.bind(this);

  }

  componentWillMount() {
    this.props.actions.createBatchID(this.state.batchID);
    this.props.actions.clearImageArray();
  }

  componentDidMount() {
    const selectedCar = this.props.selectedCarProfile;
    const checkCSNImages = [];
    //At this Point Check if the selected CarProfile has any ChassisImage associated
    if(selectedCar && selectedCar.images) {
      map(selectedCar.images, (img) => {
        if (img.small.indexOf('_chassisNumber_') > -1) {
          checkCSNImages.push({
            isUploaded: true,
            isFailed: false,
            id: img.id,
            thumbnail: img.small,
            original: img.original,
            large: img.large,
            isCover: false
          });
        }
      });
    }

    if (checkCSNImages.length) {
      this.setState({
        uploadFlag: false,
        fillChassis: false
      })
    } else {
      this.setState({
        uploadFlag: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {


    const { selectedCarRepairDataArray } = this.props;

    console.log("selectedCarRepairDataArray"+selectedCarRepairDataArray);

    this.setState({ selectedCarRepairDataArray: selectedCarRepairDataArray});

    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false });
    } else if (nextProps && nextProps.showError && this.state.isButtonLoading) {
      this.props.deactivateLoader();
      this.setState({ isButtonLoading: false });
    } else if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.createRequestImageArray && nextProps.bookingRequestReducer.createRequestImageArray.length && nextProps.bookingRequestReducer.createRequestImageArray.length > this.state.createRequestImageArray.length) {
      let uploadImage = cloneDeep(this.state.uploadImage);
      nextProps.bookingRequestReducer.createRequestImageArray.map((createRequestImageArray) => {
        let findUploadImageIndex = findIndex(this.state.uploadImage, { fileName: createRequestImageArray.fileName });
        if (findUploadImageIndex > -1) {
          uploadImage[findUploadImageIndex]['uploaded'] = true;
          uploadImage[findUploadImageIndex]['id'] = createRequestImageArray.id;
        }
      });
      this.setState({
        ...this.state,
        createRequestImageArray: nextProps.bookingRequestReducer.createRequestImageArray,
        uploadImage: uploadImage,
      });
    } else if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.createRequestFileArray && nextProps.bookingRequestReducer.createRequestFileArray.length && nextProps.bookingRequestReducer.createRequestFileArray.length > this.state.createRequestFileArray.length) {
      let policeReportId, registrationId, drivingLicenceId, policeReportUrl, registrationUrl, drivingLicenseUrl;
      let newImageIdArray = cloneDeep(this.state.newImageIdArray);
      map(nextProps.bookingRequestReducer.createRequestFileArray, (createRequestFile) => {
        newImageIdArray.push(createRequestFile.id);
        if (createRequestFile.type === "policereport") {
          policeReportId = createRequestFile.id;
          policeReportUrl = createRequestFile.url;
        } else if (createRequestFile.type === "registrationcard") {
          registrationId = createRequestFile.id;
          registrationUrl = createRequestFile.url;
        } else if (createRequestFile.type === "drivinglicense") {
          drivingLicenceId = createRequestFile.id;
          drivingLicenseUrl = createRequestFile.url;
        }
      });
      this.setState({
        ...this.state,
        createRequestFileArray: nextProps.bookingRequestReducer.createRequestFileArray,
        policeReportId: policeReportId,
        registrationId: registrationId,
        drivingLicenceId: drivingLicenceId,
        policeReportUrl: policeReportUrl,
        registrationUrl: registrationUrl,
        drivingLicenseUrl: drivingLicenseUrl,
        newImageIdArray: newImageIdArray,
      });
    }

    // Load ChassisNumber Images on Receiving Props,
    // Since the ChassisImage and CarImages are all part of the same image array
    // this is where the chassisImage is plucked and not on the car reducers
    /*
      if next props has the images of chassis Number put them
      as the default value of chassisImages in the state
    */
   if (nextProps && nextProps.selectedCarProfile &&
      nextProps.selectedCarProfile.images) {
      console.log('Start of Selected Car ---> ', nextProps.selectedCarProfile.images);
      let imageArray = [...this.state.chassisImages];
      const CSNimages = [];
      map(nextProps.selectedCarProfile.images, (img) => {
        if (img.type == "chassisNumber") {
          CSNimages.push({
            isUploaded: true,
            isFailed: false,
            id: img.id,
            thumbnail: img.small,
            original: img.original,
            large: img.large,
            isCover: false
          });
        }
      });
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(imageArray, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        chassisImages: CSNimages
      });
      if(CSNimages.length) {
        this.setState({
          fillChassis: false
        });
      } else {
        this.setState({
          fillChassis: true
        });
      }
    }

    // check if new CSN numbers are uploaded here
    if (nextProps && nextProps.carProfileReducer &&
      nextProps.carProfileReducer.newCarProfileData
      && nextProps.carProfileReducer.newCarProfileData.CSNimages
      && nextProps.carProfileReducer.newCarProfileData.CSNimages.length
      && (nextProps.carProfileReducer.newCarProfileData.batch_id === nextProps.selectedCarProfile.batchId))
      {
      const CSNimages = [...nextProps.carProfileReducer.newCarProfileData.CSNimages];
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(CSNimages, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        chassisImages: CSNimages
      });
      if(CSNimages.length) {
        this.setState({
          fillChassis: false
        });
      } else {
        this.setState({
          fillChassis: true
        });
      }
    }
    if(this.props.selectedCarProfile){


      this.setState({
        selectedCarProfileId: this.props.selectedCarProfile.id
      });
      this.errors['selectedCarProfileId'] = false;
      this.formData['selectedCarProfileId'] = true;

    }



  }



  // The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number
  chassisFileUpload(e) {
    console.log('What are the files here target ', e.target.files);
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      console.log('SETTING this to ERROR in Repair');
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      console.log('SETTING this to no ERROR in Repair');
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    console.log('What is the props is here =---> ',this.props);
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.selectedCarProfile.batchId,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  // console.log("What ssssss ", that.props.carProfileReducer);
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.selectedCarProfile.batchId,
                    imageId,
                    true,
                    'CSN'
                  );
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError(value),
          that.props.selectedCarProfile && that.props.selectedCarProfile.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      // Once the image is uploaded Successfully need to reset the ChassisPopUp value
      // so that its not displayed anymore
      console.log('On Setting chassis');
      this.setState({
        fillChassis: false
      });
      img.onerror = function() {
        console.log('On Error received');
        // alert( "not a valid file: " + value.type);
        this.setState({
          fillChassis: true
        });
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });
  }

  hidePanel(panel) {
    this.props.disableEdit();
    if (panel == 'step1') {
      this.setState({ step1Panel: !this.state.step1Panel, step2Panel: false });
    } else if (panel == 'step2') {
      Scroll.animateScroll.scrollToTop();
      this.setState({ step1Panel: false, step2Panel: !this.state.step2Panel, step1Active: true, step2Active: true });
    }
  }

  onFieldBlur(value, name, validateError, event) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
    this.errors['registrationnumber'] = (this.formData['insuranceprovider'].length || this.formData['insurancepolicynumber'].length || this.formData['state'].length) && !this.formData['registrationnumber'].length ? true : false;
    this.setState({ isLoaded: this.state.isLoaded });
  }

  onChasisChange(value) {
    console.log('Value ', value);
    this.setState({
      chassis_number: value ? value : "",
      //fillChassis: false
    });
  }

  hideModalCallback() {
    this.setState({ showChasis: false, fillChassis: false });
  }

  submitChassisCallback(number) {
    console.log('The chasis Number is 💔', number);
    this.setState({
      chassis_number: number ? number : "",
      showChasis: false,
      fillChassis: false
    });
  }

  imageThumbnails(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          {img.isUploaded ? <span className="cancel-image" onClick={(e) => {
            e.preventDefault();
            this.props.actions.deleteVehicleImage(index, img.id, imageArray, this.props.selectedCarProfile.id);
          }}>
            <i className="mdi mdi-close" />
          </span> : ''}
          <img src={img.isFailed ? `${imageBasePath}/images/warning.png` : img.thumbnail} onClick={() => {
            this.setState({ showModal: true, imageStartIndex: index });
          }} />
          {!img.isUploaded ? <div><img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" /></div> : ''}
        </div>
      );
    });
  }

  imageThumbnailsForChassis(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          <img
            src={img.thumbnail}
          />
        </div>
      );
    });
  }

  selectedDropdownText(location) {
    this.setState({ PreferedLocation: location });
  }

  uploadImageError(value) {
    this.props.actions.bookingUploadImage('', value, this.state.batchID, false);
  }

  uploadFileError(type) {
    this.props.actions.bookingUploadFile('', '', this.state.batchID, type, "createrequest", false);
  }

  uploadImage(e, img) {
    let files = [], fileImgSize = 0, count = 0;
    if (img == 'uploadImage') {
      let fileImageBlob = [];
      let errFileType = false;
      each(e.target.files, (val) => {
        let uniqueId = uniqid();
        let value = val;
        value['fileName'] = uniqueId;
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, fileName: uniqueId, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileImageBlob.push(value);
        fileImgSize += val.size;
        count = count + 1;
        if (val.type == "image/png" || val.type == "image/jpeg") {
        } else {
          errFileType = true;
        }
      });
      if (this.state.uploadImgSize + fileImgSize >= 20000000 || errFileType == true) {
        this.setState({ uploadImageErrText: true });
      } else if (count > 5 || this.state.imageTotalCount + count > 5) {
        this.setState({ imageCountErrText: true });
      }
      else {
        this.setState({
          uploadImage: this.state.uploadImage.concat(files),
          uploadImgSize: fileImgSize + this.state.uploadImgSize,
          imageTotalCount: this.state.imageTotalCount + count,
          imageCountErrText: false,
          uploadImageErrText: false,
        });
        e.target.value = null;
        //this.props.actions.bookingUploadImage(fileImageBlob,this.state.batchID,"createrequest",files);
        let that = this;
        map(fileImageBlob, (value) => {

          let img = new Image();
          img.onload = function () {
            value.height = this.height;
            value.width = this.width;

            that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  let newImageIdArray = cloneDeep(that.state.newImageIdArray);
                  newImageIdArray.push(imageId);
                  that.setState({ newImageIdArray: newImageIdArray });
                  that.props.actions.bookingUploadImage(imageId, value.fileName, "createrequest", that.state.batchID, true);
                }, () => that.uploadImageError(value));
              }
            }, () => that.uploadImageError(value), false, "image");

          };
          img.onerror = function () {
            // alert( "not a valid file: " + value.type);
          };
          img.src = URL.createObjectURL(value);
        });
      }
    }

    else if (img == 'policeReport') {
      let errFileType = false;
      let filePoliceBlob = [];
      let count = 0;
      let policeReportImage = false;
      each(e.target.files, (val) => {
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        filePoliceBlob.push(val);
        fileImgSize += val.size;
        count = count + 1;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg" || val.type == "image/jpg") {
          policeReportImage = true;
        }
        else {
          errFileType = true;
        }
      });
      if (this.state.policeReportImgSize + fileImgSize >= 20000000 || errFileType == true) {
        this.setState({ policeReportErrText: true });
      } else if (count > 1 || this.state.policeReportTotalCount + count > 1) {
        this.setState({ policeReportCountErrText: true });
      } else {
        this.setState({
          ...this.state,
          policeReportImage: policeReportImage,
          policeReport: this.state.policeReport.concat(files),
          policeReportImgSize: fileImgSize + this.state.policeReportImgSize,
          policeReportTotalCount: this.state.policeReportTotalCount + count,
          policeReportCountErrText: false,
          policeReportErrText: false,
        });
        e.target.value = null;
        let that = this;
        map(filePoliceBlob, (value) => {

          if (policeReportImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "policereport", "createrequest", true);
                  }, () => that.uploadFileError("policereport"));
                }
              }, () => that.uploadFileError("policereport"), false, 'policereport');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "policereport", "createrequest", true);
                }, () => this.uploadFileError("policereport"));
              }
            }, () => this.uploadFileError("policereport"), false, 'policereport');
          }
        });
        //this.props.actions.bookingUploadFile(this.state.policeReport.concat(filePoliceBlob),this.state.batchID,"policereport","createrequest" );
      }
    }

    else if (img == 'rationCard') {
      let errFileType = false;
      let fileRationBlob = [];
      let count = 0;
      let rationCardImage = false;
      each(e.target.files, (val) => {
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileRationBlob.push(val);
        fileImgSize += val.size;
        count = count + 1;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg" || val.type == "image/jpg") {
          rationCardImage = true;
        } else {
          errFileType = true;
        }
      });
      if (this.state.rationCardImgSize + fileImgSize >= 20000000 || errFileType == true) {
        this.setState({ rationCardErrText: true });
      } else if (count > 1 || this.state.registrationCardCount + count > 1) {
        this.setState({ registrationcardErrText: true });
      } else {
        this.setState({
          rationCard: this.state.rationCard.concat(files),
          rationCardImage: rationCardImage,
          rationCardImgSize: fileImgSize + this.state.rationCardImgSize,
          registrationcardErrText: false,
          rationCardErrText: false,
          registrationCardCount: this.state.registrationCardCount + count
        });
        e.target.value = null;
        let that = this;
        map(fileRationBlob, (value) => {

          if (rationCardImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "registrationcard", "createrequest", true);
                  }, () => that.uploadFileError("registrationcard"));
                }
              }, () => that.uploadFileError("registrationcard"), false, 'registrationcard');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "registrationcard", "createrequest", true);
                }, () => this.uploadFileError("registrationcard"));
              }
            }, () => this.uploadFileError("registrationcard"), false, 'registrationcard');
          }

        });
        //this.props.actions.bookingUploadFile(this.state.rationCard.concat(fileRationBlob),this.state.batchID,"registrationcard","createrequest");
      }
    }

    else if (img == 'drivingLicence') {
      let errFileType = false;
      let fileLisenceBlob = [];
      let count = 0;
      let drivingLicenceImage = false;
      this.setState({ drivingLicenceErrText: false });
      each(e.target.files, (val) => {
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileLisenceBlob.push(val);
        fileImgSize += val.size;
        count = count + 1;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg") {
          drivingLicenceImage = true;
        } else {
          errFileType = true;
        }
      });
      // upload = { ...this.state.imageUploaded, files }
      if (this.state.drivingLicence + fileImgSize >= 20000000 || errFileType == true) {
        this.setState({ drivingLicenceErrText: true });
      } else if (count > 1 || this.state.drivingLicenceCardCount + count > 1) {
        this.setState({ drivingLicenceCountErrText: true });
      } else {
        // this.state.uploadImgSize += fileImgSize;
        this.setState({
          drivingLicence: this.state.drivingLicence.concat(files),
          drivingLicenceImgSize: fileImgSize + this.state.drivingLicenceImgSize,
          drivingLicenceCountErrText: false,
          drivingLicenceImage: drivingLicenceImage,
          drivingLicenceCardCount: this.state.drivingLicenceCardCount + count
        });
        e.target.value = null;
        let that = this;
        map(fileLisenceBlob, (value) => {

          if (drivingLicenceImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "drivinglicense", "createrequest", true);
                  }, () => that.uploadFileError("drivinglicense"));
                }
              }, () => that.uploadFileError("drivinglicense"), false, 'drivinglicense');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "drivinglicense", "createrequest", true);
                }, () => this.uploadFileError("drivinglicense"));
              }
            }, () => this.uploadFileError("drivinglicense"), false, 'drivinglicense');
          }

        });
        //this.props.actions.bookingUploadFile(this.state.drivingLicence.concat(fileLisenceBlob),this.state.batchID,"drivinglicense","createrequest");
      }
    }
  }

  cancelUploadImage(val, index, id) {
    let deleteFileSize = 0;
    let createRequestImageArray = cloneDeep(this.state.createRequestImageArray);
    if (val == 'uploadImage') {
      if (this.state.uploadImgSize >= 20000000) {
        this.setState({ uploadImageErrText: true });
      } else {
        this.setState({ uploadImageErrText: false });
      }
      this.props.actions.deleteUploadedImages(id, "createrequest");
      const uploadImage = this.state.uploadImage;
      deleteFileSize = this.state.uploadImgSize - this.state.uploadImage[index].size;
      uploadImage.splice(index, 1);
      let removeImage = remove(createRequestImageArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        uploadImage: uploadImage,
        imageTotalCount: this.state.imageTotalCount - 1,
        createRequestImageArray: removeImage,
        uploadImgSize: deleteFileSize
      });
    } else if (val == 'policeReport') {
      let createRequestFileArray = cloneDeep(this.state.createRequestFileArray);
      let policeReport = cloneDeep(this.state.policeReport);
      if (this.state.rationCard >= 20000000) {
        this.setState({ policeReport: policeReport });
        this.setState({ policeReportErrText: true });
      } else {
        this.setState({ policeReportErrText: false });
      }
      deleteFileSize = this.state.policeReportSize - this.state.policeReport[index].size;
      policeReport.splice(index, 1);
      let removePoliceReport = remove(createRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        policeReport: policeReport,
        createRequestFileArray: removePoliceReport,
        policeReportTotalCount: this.state.policeReportTotalCount - 1,
        policeReportSize: deleteFileSize,
        policeReportId: undefined,
      });
      this.props.actions.deleteUploadedFile(id, "createrequest");
    } else if (val == 'rationCard') {
      let createRequestFileArray = cloneDeep(this.state.createRequestFileArray);
      let rationCard = cloneDeep(this.state.rationCard);
      if (this.state.rationCard >= 20000000) {
        this.setState({ rationCard: rationCard });
        this.setState({ rationCardErrText: true });
      } else {
        this.setState({ rationCardErrText: false });
      }
      deleteFileSize = this.state.rationCardSize - this.state.rationCard[index].size;
      rationCard.splice(index, 1);
      let removeRationCard = remove(createRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        rationCard: rationCard,
        createRequestFileArray: removeRationCard,
        registrationCardCount: this.state.registrationCardCount - 1,
        rationCardSize: deleteFileSize,
        registrationId: undefined,
      });
      this.props.actions.deleteUploadedFile(id, "createrequest");
    } else if (val == 'drivingLicence') {
      let createRequestFileArray = cloneDeep(this.state.createRequestFileArray);
      let drivingLicence = cloneDeep(this.state.drivingLicence);
      if (this.state.drivingLicence >= 20000000) {
        this.setState({ drivingLicence: drivingLicence });
        this.setState({ drivingLicenceErrText: true });
      } else {
        this.setState({ drivingLicenceErrText: false });
      }
      deleteFileSize = this.state.drivingLicenceSize - this.state.drivingLicence[index].size;
      drivingLicence.splice(index, 1);
      let removeDrivingLicence = remove(createRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        drivingLicence: drivingLicence,
        drivingLicenceSize: deleteFileSize,
        createRequestFileArray: removeDrivingLicence,
        drivingLicenceCardCount: this.state.drivingLicenceCardCount - 1,
        drivingLicenceId: undefined,
      });
      this.props.actions.deleteUploadedFile(id, "createrequest");
    }
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id
    });
    if (this.props.carProfiles[carProfile.id].chassisNumber){
      this.setState({
        chassis_number: this.props.carProfiles[carProfile.id].chassisNumber
      });
    } else {
      this.setState({
        chassis_number: ""
      });
    }

    const selectedCar = this.props.carProfiles[carProfile.id];
    const checkCSNImages = [];
    //At this Point Check if the selected CarProfile has any ChassisImage associated
    if(selectedCar && selectedCar.images) {

      console.log("selectedCar.images",selectedCar.images);
      map(selectedCar.images, (img) => {
        if (img.type == "chassisNumber") {
          checkCSNImages.push({
            isUploaded: true,
            isFailed: false,
            id: img.id,
            thumbnail: img.small,
            original: img.original,
            large: img.large,
            isCover: false
          });
        }
      });
    }

    if (checkCSNImages.length) {
      this.setState({
        uploadFlag: false,
        fillChassis: false
      })
    } else {
      this.setState({
        uploadFlag: true
      });
    }
    // Check for Images Length , if a single entry is present , we disable the Upload option


    this.props.changeCarProfile(carProfile)
    this.errors['selectedCarProfileId'] = false;
    this.formData['selectedCarProfileId'] = true;
  }

  timePicker(value) {
    if (value && value.hours() && value.minutes()) {
      if (moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue) {
        if (value.startOf('minutes') < moment().add(1, 'hours').startOf('minutes')) {
          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              now: value,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        } else {
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              now: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        }
      } else {
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            now: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
      }
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if (moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()) {
      if (this.state.selectedTimeValue < moment().add(1, 'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });
      } else {
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
  }

  jobDetails(data) {
    if (data.target.value.length) {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
    else {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }


  requestQuotesValidate(){
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }

    console.log("00000",this.errors);

    if (!validForm) {
      this.setState({ submissionError: true, });

      return false;
    }
    return true;
  }

  requestQuotes() {
    const { selectedCarRepairDataArray, actions } = this.props;
    console.log('what is the value chasis ', this.state.chassis_number);
    console.log('what is the value this.state.fillChassis ', this.state.fillChassis);


    let formData = {
      ...this.formData
    };
    let validForm = true;
    console.log('Form Data --> ', formData);
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      if(!this.state.chassis_number && this.state.fillChassis )
      {
        this.setState({
          showChasis: true,
        });
        return;
      } else {
        this.setState({
          showChasis: false,
        });
      }
      if(!this.state.chassisImages.length && !this.state.chassis_number){
        this.setState({
          fillChassis: true
        });
      } else {
        this.setState({
          fillChassis: false
        });
      }


      if (this.state.selectedCarProfileId && this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng) {
        this.setState({ submissionError: false, isButtonLoading: true, step2Active: true });
        let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
        let momentTimeStamp = moment.utc(timeStamp).format();
        let payload = {
          "batch_id": this.state.batchID,
          "serviceid": 3,
          "chassis_number": this.state.chassis_number.toString(),
          "subserviceid": selectedCarRepairDataArray,
          "vehicleid": this.state.selectedCarProfileId,
          "details": this.state.jobDetails,
          "userpreffereddatetime": momentTimeStamp,
          "location": this.state.selectedLocation.formatted_address,
          "latitude": this.state.selectedLocation.lat.toString(),
          "longitude": this.state.selectedLocation.lng.toString(),
          "isurgentrequest": this.state.checked,
          "repairreason": this.state.accidentRadioBtn ? "accident" : "general",
          "images": this.state.newImageIdArray,
        };
        if (this.props.garageProfileDetails) {
          payload["vendorid"] = this.props.garageProfileDetails.id;
        }

        if(this.props.selectedPromoCode){
          payload.promo_code = this.props.selectedPromoCode;
        }
        this.props.actions.createQuickBookingRequest(payload);
        const carData = Object.assign({}, this.props.selectedCarProfile, {
          chassis_number: this.state.chassis_number.toString(),
          plate_no: this.props.selectedCarProfile.plateNo.toString(),
          model_id: this.props.selectedCarProfile.modelId.toString()
        });
        console.log('Car data is ', this.props.selectedCarProfile.id);

        let editProfile = false;
        const propChassisNumber = this.props.carProfiles[this.props.selectedCarProfile.id].chassisNumber ?
          this.props.carProfiles[this.props.selectedCarProfile.id].chassisNumber : "";
        const stateChassisNumber = this.state.chassis_number ? this.state.chassis_number : "";
        editProfile = propChassisNumber != stateChassisNumber ? true : false;

        this.setState({isButtonLoading: true})

        this.props.activateLoader();

        actions.setCarProfileAction(
          carData,
          editProfile,
          this.props.selectedCarProfile.id.toString(),
          this.props.router,
          this.props.selectedCarProfile.batchId? this.props.selectedCarProfile.batchId.toString(): "",
          this.props.selectedCarProfile.images,
          null);
      }
    }
  }


  render() {
    const CSNUploadView = this.imageThumbnails(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const CSN_w_o_UploadView = this.imageThumbnailsForChassis(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
        boxShadow: '0 0 4px 0 rgba(134,134,134,0.5)',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails, bookingRequestReducer, } = this.props;
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];
    let uploadImageLength = this.state.uploadImage.length;
    let uploadImage = this.state.uploadImage && uploadImageLength ? map(this.state.uploadImage, (img, index) => {
      if (!img.uploaded) {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              <img src={img.path} />
              <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />
            </div>
          </div>
        );
      } else {
        return (
          <div className='upload-box-wrapper box-shadow' key={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("uploadImage", index, img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              <div className="upload-images">
                <img src={img.path} alt="" onClick={() => {
                  this.setState({ showModal: true, imageStartIndex: index, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
                }} />
              </div>
            </div>
          </div>
        );
      }
    }) : '';

    const policeReportView = map(this.state.policeReport, (policereport, index) => {
      return (
        <div className='upload-box-wrapper box-shadow' index={index}>
          <div className="uploaded-image">
            {this.state.policeReportId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('policeReport', index, this.state.policeReportId) }}>
              <i className="mdi mdi-close" />
            </span>}
            {!this.state.policeReportImage ? <div className="upload-images">
              <img src={`${imageBasePath}/images/pdf.png`} alt="" onClick={() => {
                { this.state.policeReportId && window.open(this.state.policeReportUrl && this.state.policeReportUrl, "_blank"); }
              }} />
              {!this.state.policeReportId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
            </div> :
              <div className="upload-images">
                <img src={policereport.path} alt="" onClick={() => {
                  this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 1, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
                }} />
                {!this.state.policeReportId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div>
            }
          </div>
        </div>
      );
    });

    const rationCardView = map(this.state.rationCard, (img, index) => {
      return (
        <div className='upload-box-wrapper box-shadow' index={index}>
          <div className="uploaded-image">
            {this.state.registrationId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('rationCard', index, this.state.registrationId) }}>
              <i className="mdi mdi-close" />
            </span>}
            {!this.state.rationCardImage ? <div className="upload-images">
              <img src={`${imageBasePath}/images/pdf.png`} alt="" onClick={() => {
                { this.state.registrationId && window.open(this.state.registrationUrl && this.state.registrationUrl, "_blank"); }
              }} />
              {!this.state.registrationId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
            </div> :
              <div className="upload-images">
                <img src={img.path} alt="" onClick={() => {
                  this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 1, drivingReportStartIndex: 0 });
                }} />
                {!this.state.registrationId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div>
            }
          </div>
        </div>
      );
    });

    const drivingLicenceView = map(this.state.drivingLicence, (img, index) => {
      return (
        <div className='upload-box-wrapper box-shadow' index={index}>
          <div className="uploaded-image">
            {this.state.drivingLicenceId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('drivingLicence', index, this.state.drivingLicenceId) }}>
              <i className="mdi mdi-close" />
            </span>}
            {!this.state.drivingLicenceImage ? <div className="upload-images">
              <img src={'/images/pdf.png'} alt="" onClick={() => {
                { this.state.drivingLicenceId && window.open(this.state.drivingLicenseUrl && this.state.drivingLicenseUrl, "_blank"); }
              }} />
              {!this.state.drivingLicenceId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
            </div> :
              <div className="upload-images">
                <img src={img.path} alt="" onClick={() => {
                  this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 1 });
                }} />
                {!this.state.drivingLicenceId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div>
            }
          </div>
        </div>
      );
    });



    return (
      <div className="panel-section car-wash quick-booking-panel car-category-wrapper p-panel">
        <section className={this.props.hideStep1Panel? "collapse-panel hide" : "collapse-panel "}>
          <div className="panel-head">
            <h4>Car Repair</h4>

          </div>
          {servicesFetched ? this.state.step1Panel && <div className="panel-content">
            {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState/>
            }

          </div> :
            <PageLoader />}
        </section>
        <section className={this.props.hideStep1Panel?  this.props.showSummary?  "collapse-panel hide ":  "collapse-panel " : "collapse-panel hide "}>
          {! this.state.hideStep1Panel && categoriesSelectedCount > 0 && <div className="panel-content">
            <div className="row">
              <div className="col-md-12 left pad0">
                <div className="form-section">
                  <h4 className="panel-sub-title">Reason For Repair</h4>
                  <div className="radio-btn">
                    <div className="radio-style">
                      <label>
                        <input type="radio" name="radio" checked={this.state.accidentRadioBtn} onChange={() => this.setState({ accidentRadioBtn: true, generalRadioBtn: false })} />
                        <i className="mf-radio-button" /><span>Accident</span>
                      </label>
                    </div>
                    <div className="radio-style">
                      <label>
                        <input type="radio" name="radio" checked={this.state.generalRadioBtn} onChange={() => this.setState({ accidentRadioBtn: false, generalRadioBtn: true })} />
                        <i className="mf-radio-button" /><span>General</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-section location-section">
                  <h4 className="panel-sub-title">Location</h4>
                  <label>We will get quotes from nearby car workshops</label>
                  <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                    <div className="location-dd header-search ws-form-section">
                      <LocationSearchQuickBox style={style} placeholder="Select your Location" commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined} />
                    </div>
                    {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select your Location</span>}
                  </div>
                  {this.state.selectedLocation && <div className="map-panel">
                    <div className="gmaps">
                      <Gmaps
                        center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                        zoom={12}
                        setCenter={true}
                        markers={markerLocation}
                        containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                        mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                      />
                    </div>
                  </div>}
                </div>
                <div className="form-section">
                  <h4 className="panel-sub-title">Prefered Time & Date</h4>
                  <div className="toggleBtn">
                    <span> This is an urgent request ! </span>
                    <ToggleSwitch
                      checked={this.state.checked}
                      size="small"
                      onChange={(event) => {
                        this.setState({
                          ...this.state,
                          selectedDate: this.state.checked ? this.state.selectedDate : moment(),
                          submissionError: false,
                          selectedTimeValue: this.state.checked ? this.state.selectedDate : moment().add(1, 'hours'),
                          now: this.state.checked ? this.state.now : moment().add(1, 'hours'),
                          checked: !this.state.checked,
                        });
                      }}
                    />
                  </div>
                  {!this.state.checked && <div className="row date-time">
                    <div className="col-md-6 padLeft0 timeDate_grid">
                      <DatePicker
                        selected={this.state.selectedDate}
                        onChange={this.handleChange}
                        minDate={moment().add(1, 'hours')}
                      />
                    </div>
                    <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                      <TimePicker
                        showSecond={false}
                        className="custom-timepicker"
                        format={'h:mm a'}
                        onChange={this.timePicker.bind(this)}
                        value={this.state.now}
                        use12Hours
                      />
                      {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                    </div>
                  </div>}
                </div>



                <div className="form-section">
                  <h4 className="panel-sub-title">Notes (Optional)</h4>
                  <div className="model-select">
                    <input value={this.state.jobDetails} type="text" className="jd-text" placeholder="Enter Text Here" onChange={(e) => { this.jobDetails(e); }} />
                  </div>
                </div>



                <div className="row car-profile">
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      //showValidationError={this.errors['chassis_number']}
                      label="Chassis Number"
                      name="chassis_number"
                      type="text"
                      customClass="noMargin"
                      //validationError="Kms Travelled cannot be empty"
                      value={this.state.chassis_number ? this.state.chassis_number : ""}
                      //onBlur={this.onFieldBlur.bind(this)}
                      onChange={this.onChasisChange.bind(this)}
                    />
                    <label className="mobChassis">
                      The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
                    </label>
                  </div>
                  <div className="col-md-6 padRight0">
                    {<div className="or-text-vert-job">
                      <span className="or-text-vert-span">OR</span>
                      <div>
                        <h4 className="panel-sub-title">Upload images</h4>
                        <div className="img-uploads">
                          {(this.state.uploadFlag) && this.state.carImages.length < 5 ?
                            <Upload
                              margin={false}
                              id="carProfileUpload"
                              fileUpload={(e) => this.chassisFileUpload(e)} /> : <div />}
                          {this.state.uploadFlag ? CSNUploadView : CSN_w_o_UploadView}
                        </div>
                        <div className="mobChassisFileSizeJob">
                          {this.state.uploadFlag &&  'The file has to be below 25MB. Please use JPG or PNG'}
                        </div>
                        <span className={this.state.uploadImageTypeErr ? "image-upload-error " : "image-upload-error  hide"}>
                          <p>Sorry, only JPG or PNG is accepted.
                                                  Try again with image file.</p>
                          <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageTypeErr: false })} />
                        </span>
                        <span className={this.state.uploadImageSizeErr ? "image-upload-error " : "image-upload-error  hide"}>
                          <p>Sorry, uploaded image exceeds the file size limit of 25mb.
                                                  Try again with another image.</p>
                          <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageSizeErr: false })} />
                        </span>
                        <span className={this.state.imageMaxLimitError ? "image-upload-error " : "image-upload-error  hide"}>
                          <p>Maximum allowed limit is 5.</p>
                          <i className="mdi mdi-close" onClick={() => this.setState({ imageMaxLimitError: false })} />
                        </span>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="col-md-12 right pad0">
                <div className="form-section uploads">
                  <div className="row">
                    <h4 className="panel-sub-title">upload a image (Optional)</h4>
                    <div className="model-select upload">
                      {this.state.imageTotalCount < 5 && <Upload id="uploadImage" fileUpload={(e) => { this.uploadImage(e, 'uploadImage') }} />}
                      {uploadImage}
                      {/* {this.state.createRequestImageArray && this.state.createRequestImageArray.length > 0 &&  createRequestImage} */}
                    </div>
                  </div>
                  <span className={this.state.uploadImageErrText ? "image-upload-error" : "image-upload-error hide"}>
                    <p>Sorry, your image format is wrong or image size exceeds the limit of 20mb. Try again with another image</p>
                    <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageErrText: false })} />
                  </span>
                  <span className={this.state.imageCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                    <p>Only 5 images can upload</p>
                    {<i className="mdi mdi-close" onClick={() => this.setState({ imageCountErrText: false })} />}
                  </span>
                </div>
                {this.state.accidentRadioBtn && <div className="clearfix">
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">police report</h4>
                      <div className="model-select upload">
                        {this.state.policeReportTotalCount < 1 && <Upload id="policeReport" fileUpload={(e) => { this.uploadImage(e, 'policeReport') }} />}
                        {policeReportView}
                      </div>
                    </div>
                    <span className={this.state.policeReportErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ policeReportErrText: false })} />
                    </span>
                    <span className={this.state.policeReportCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Police Report can be upload</p>
                      {<i className="mdi mdi-close" onClick={() => this.setState({ policeReportCountErrText: false })} />}
                    </span>
                  </div>
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">Registration card</h4>
                      <div className="model-select upload">
                        {this.state.registrationCardCount < 1 && <Upload id="rationCard" fileUpload={(e) => { this.uploadImage(e, 'rationCard') }} />}
                        {rationCardView}
                      </div>
                    </div>
                    <span className={this.state.rationCardErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ rationCardErrText: false })} />
                    </span>
                    <span className={this.state.registrationcardErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Registration card can be upload</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ registrationcardErrText: false })} />
                    </span>
                  </div>
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">Driving Licence</h4>
                      <div className="model-select upload">
                        {this.state.drivingLicenceCardCount < 1 && <Upload id="drivingLicence" fileUpload={(e) => { this.uploadImage(e, 'drivingLicence') }} />}
                        {drivingLicenceView}
                      </div>
                    </div>
                    <span className={this.state.drivingLicenceErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ drivingLicenceErrText: false })} />
                    </span>
                    <span className={this.state.drivingLicenceCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Driving Licence can be upload</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ drivingLicenceCountErrText: false })} />
                    </span>
                  </div>
                </div>}
              </div>
            </div>

            <ChasisNumberPopup
              modalStatus={this.state.showChasis}
              hideModalCallback={this.hideModalCallback.bind(this)}
              submitCallback={this.submitChassisCallback.bind(this)}
              actions= {this.props.actions}
              bookingRequestReducer={this.props.bookingRequestReducer}
              carProfileReducer={this.props.carProfileReducer}
              selectedCarProfile={this.props.selectedCarProfile}
            />
          </div>}

        </section>

        {this.state.imageStartIndex > -1 ?
          <LightBox
            showUploadIcon={this.state.uploadImage.length < 5}
            actions={this.props.actions}
            images={this.state.uploadImage}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            cancelUploadImage={this.cancelUploadImage}
            fromCreateRequest={"uploadImage"}
            reportId={"fromImage"}
          />
          : ''}
        {this.state.policeReportStartIndex && this.state.policeReportStartIndex > 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.policeReport}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"policeReport"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.policeReportId}
            showUploadIcon={this.state.policeReportTotalCount < 1}
          />
          : ''}
        {this.state.registrationReportStartIndex && this.state.registrationReportStartIndex >= 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.rationCard}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"rationCard"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.registrationId}
            showUploadIcon={this.state.registrationCardCount < 1}
          />
          : ''}
        {this.state.drivingReportStartIndex && this.state.drivingReportStartIndex >= 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.drivingLicence}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"drivingLicence"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.drivingLicenceId}
            showUploadIcon={this.state.drivingLicenceCardCount < 1}
          />
          : ''}
      </div>
    );
  }
}

export default QuickRepairSteps;
