import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import LightBox from '../common/LightBox';
import { imageBasePath } from '../../constants/staticData';

export default class ServiceDetails extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      plateNumber: "B 509234 Dubai",
      brand: "My Nissan GT-R",
      color: "Silver",
      year: "2016",
      showModal: false,
      upcomingService: "10500",
      date: "17 Mar '17",
      previousService: "8500",
      imageStartIndex: 0
    };
    this.setAsCover = this.setAsCover.bind(this);
  }
  closeModal() {
    this.setState({ showModal: false });
  }

  setAsCover(image) {
    if (this.props.routeParams.id && image.id) {
      this.props.actions.setVehicleCoverImage(
        image.id,
        this.props.routeParams.id
      );
    }
  }
  render() {
    // let carProfileImages = cloneDeep(this.props.images);
   let carProfileImages = filter(this.props.images, (img) => {
      return img.type  === "vehicle";
    });

 /*   let carProfileImages = filter(this.props.images, {
      c: "vehicle"
    });*/

    map(carProfileImages, (image, index) => {
      carProfileImages[index].thumbnail = image.small;
      carProfileImages[index].original = image.large;
    });
    return (
      <div className="service-details">
        {(carProfileImages && carProfileImages.length) ? <Carousel interval={3000} >
          {map(carProfileImages, (image, key) => {
            return (
              <Carousel.Item key={key} onClick={() => {
                this.setState({ showModal: true, imageStartIndex: key });
              }}>
                <img width={400} height={280} alt="" src={image.large ? image.large : `${imageBasePath}/images/placeholder.png`} />
              </Carousel.Item>
            );
          })}
        </Carousel> : <div className="placeholderImg"><img src={`${imageBasePath}/images/placeholder.png`} alt="" /></div>}
        <div className="service-car-info">
          <div className="service-carPlate">
            <h6>{this.props.plateNo} {this.props.state}</h6>
            <h4>{this.props.name}</h4>
            <span className="car-make">{this.props.model} - {this.props.year}</span>
            {this.props.mileage && <li>
              <h5>Mileage</h5>
              <span><span className="text-wrap">{this.props.mileage}</span> {!this.props.mileage.includes("kms") && <span>Kms</span>} </span>
            </li>}
            {!this.props.cylinder == 0 && <li>
              <h5>Cylinder</h5>
              <span className="text-wrap">{this.props.cylinder}</span>  
            </li>}
          </div>
          {/*<div className="service-info">
            <div className="service-info-content">
              <ul>
                <li>
                  <h4>Previous Service</h4>
                  <h5>Mileage</h5>
                  <span><span className="text-wrap">{this.props.mileage}</span> Kms</span>
                  <h5>On</h5>
                  <span>Car Wash: 17 Mar ‘17, 1097735 Kms</span>
                </li>
              </ul>
            </div>
          </div>*/}
        </div>


        {carProfileImages && carProfileImages.length ?
          <LightBox images={carProfileImages} imageStartIndex={this.state.imageStartIndex} showSetAsCover={true} showModal={this.state.showModal} isEditable={true} closeModal={this.closeModal.bind(this)} setAsCover={this.setAsCover.bind(this)} actions={this.props.actions} vehicleId={this.props.routeParams.id}/>
        :''}


      </div>
    );
  }
}
