import React, { Component } from 'react';
import truncate  from 'lodash/truncate';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import LightBox from "../common/LightBox";

export default class OtherDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentEditable: false,
            insuranceProvider: "Al - Sabah Insurance",
            insurancePolicyNumber: "AL-SI-2345",
            insuranceState: "Dubai",
            registrationNumber:"98072345",
            readMore:false,
            chassisImages: [],
            imageStartIndex: 0,
            photos: this.props.images,

        };
    }

    contentEditableValue(){
        this.setState({ contentEditable: true });
    }

    changeIcon(val){
        if(val=="edit"){
            this.setState({ contentEditable: true });
        }else{
            this.setState({ contentEditable: false });
        }
    }
    componentWillReceiveProps(nextProps) {



      // Load ChassisNumber Images on Receiving Props,
    // Since the ChassisImage and CarImages are all part of the same image array
    // this is where the chassisImage is plucked and not on the car reducers
    /*
      if next props has the images of chassis Number put them
      as the default value of chassisImages in the state
    */

    /*
      if (nextProps && nextProps.selectedCarProfile &&
        nextProps.selectedCarProfile.images) {
        let imageArray = [...this.state.chassisImages];
        const CSNimages = [];
        map(nextProps.selectedCarProfile.images, (img) => {
          if (img.small.indexOf('_chassisNumber_') > -1) {
            CSNimages.push({
              isUploaded: true,
              isFailed: false,
              id: img.id,
              thumbnail: img.small,
              original: img.original,
              large: img.large,
              isCover: false
            });
          }
        });
        if (
          nextProps.carProfileReducer &&
          nextProps.carProfileReducer.deletedImageId
        ) {
          remove(imageArray, function(f) {
            return f.id == nextProps.carProfileReducer.deletedImageId;
          });
        }
        this.setState({
          chassisImages: CSNimages
        });
      }

      // check if new CSN numbers are uploaded here
      if (nextProps && nextProps.carProfileReducer &&
        nextProps.carProfileReducer.newCarProfileData
        && nextProps.carProfileReducer.newCarProfileData.CSNimages
        && nextProps.carProfileReducer.newCarProfileData.CSNimages.length
        && (nextProps.carProfileReducer.newCarProfileData.batch_id === nextProps.batchId))
        {
        const CSNimages = [...nextProps.carProfileReducer.newCarProfileData.CSNimages];
        if (
          nextProps.carProfileReducer &&
          nextProps.carProfileReducer.deletedImageId
        ) {
          remove(CSNimages, function(f) {
            return f.id == nextProps.carProfileReducer.deletedImageId;
          });
        }
        this.setState({
          chassisImages: CSNimages
        });
      }*/




    }

    imageThumbnails(imageArray) {
      return map(imageArray, (img, index) => {
        return (
          <div className="upload-box-wrapper box-shadow" key={index}>
            <img
              src={img.thumbnail}
            />
          </div>
        );
      });
    }

    render() {
        // let check="asdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsadasdasdsad"
        let truncatedNotes = '';
        let showNotes = this.props.carnotes && this.props.carnotes.length > 130;
        let chassisPhotosView = undefined;


        if (showNotes) {
          truncatedNotes = truncate(this.props.carnotes, { length: 130 });
        } else {
          truncatedNotes =  this.props.carnotes || '-';
        }

        let chassisNumbersImages = this.props.images;

        let chassisNumbers = filter(chassisNumbersImages, {
          type: "chassisNumber"
        });

      console.log("chassisNumbers",chassisNumbers);

      let CSNimages = [];

      map(chassisNumbers, (image, key) => {

          CSNimages.push({
            isUploaded: true,
            isFailed: false,
            id: image.id,
            thumbnail: image.medium ? image.medium : image.small,
            original: image.original,
            large: image.large,
            isCover: false
          });

        })

        chassisPhotosView = map(CSNimages, (photo, key) => {
          return (
            <li
              onClick={e => {
                e.preventDefault();
                this.setState({ showLightBox: true, imageStartIndex: key });
                this.setState({
                  photos: CSNimages,
                  showLightBox: true,
                  imageStartIndex: 0
                });
              }}
              key={key}
            >
              <img src={photo.thumbnail} alt="" />
            </li>
          );
        });

      console.log("111111");


      return (
            <div className="otherDetails-content">
                <ul>
                    <li>
                        <label>Insurance Provider</label>
                        <span>{this.props.insuranceprovider ? this.props.insuranceprovider : '-'}</span>
                    </li>
                    <li>
                        <label>Insurance Policy Number</label>
                        <span>{this.props.insurancepolicynumber ? this.props.insurancepolicynumber : '-'}</span>
                    </li>
                    <li>
                        <label>Vehicle Reg. Number</label>
                        <span>{this.props.registrationnumber ? this.props.registrationnumber : '-'}</span>
                    </li>
                    <li>
                        <label>Emirate</label>
                        <span>{this.props.state ? this.props.state : '-'}</span>
                    </li>
                    <li>
                        <label>Plate Number</label>
                        <span>{this.props.plateNo ? this.props.plateNo : '-'}</span>
                    </li>

                    <li>
                        <label>Chassis Number</label>
                        <span>{this.props.chassisNumber ? this.props.chassisNumber : '-' }</span>
                    </li>
                    <li>
                      <label></label>
                      {chassisPhotosView ? (
                        <ul className="photoList">{chassisPhotosView}</ul>
                      ) : (
                        <p>Not uploaded</p>
                      )}
                    </li>
                    <li>
                        <label>Car Notes</label>
                         <span className="car-notes-content">
                           <p contentEditable={this.state.contentEditable ? "true" : "false"}>
                              {/* {this.props.carnotes ? truncate(this.props.carnotes, { length: 130 }) : '-'} */}
                              {this.state.readMore ? (this.props.carnotes || '-') : truncatedNotes}
                          </p>
                          {!this.state.readMore && showNotes  && <span className="" onClick={()=>this.setState({readMore:true})}>Read More</span>}
                          {this.state.readMore && showNotes && <span className="" onClick={()=>this.setState({readMore:false})}>Read Less</span>}

                          {/* {this.state.contentEditable?
                              <a onClick={()=>{this.changeIcon('removeEdit');}}><i className="mdi mdi-check"/> Done</a>:
                              <a onClick={()=>{this.changeIcon('edit');}}><i className="mdi mdi-pencil"/> Edit</a>
                          } */}
                      </span>
                    </li>
                </ul>
              {this.props.images && this.props.images.length > 0 ? (
                <LightBox
                  showUploadIcon={false}
                  actions={this.props.actions}
                  images={this.state.photos}
                  imageStartIndex={this.state.imageStartIndex}
                  showModal={this.state.showLightBox}
                  isEditable={false}
                  closeModal={() => {
                    this.setState({ showLightBox: false });
                  }}
                />
              ) : (
                ""
              )}
            </div>
        );
    }
}
