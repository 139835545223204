import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../actions/";
import { appBasePath } from "../constants/staticData";
import FloatingMenu from "../components/LandingNew/FloatingMenu";

export default function AuthContainer(ComposedComponent) {
  class AuthContainer extends Component {
    constructor(props) {
      super(props);
      this.checkShowFloatMenu = this.checkShowFloatMenu.bind(this);
    }

    componentWillUpdate(nextProps) {
      if (nextProps.authReducer && nextProps.authReducer.currentComponentKey) {
        nextProps.router.push(
          appBasePath + nextProps.authReducer.currentComponentKey
        );
      }
      if (
        nextProps.bookingRequestReducer &&
        nextProps.bookingRequestReducer.currentComponentKey
      ) {
        nextProps.router.push(
          appBasePath + nextProps.bookingRequestReducer.currentComponentKey
        );
      }
      if (
        nextProps.commonReducer &&
        nextProps.commonReducer.currentComponentKey
      ) {
        nextProps.router.push(
          appBasePath + nextProps.commonReducer.currentComponentKey
        );
      }
      if (nextProps.authReducer.redirectUrl) {
        nextProps.router.push(nextProps.authReducer.redirectUrl);
      }
    }

    checkShowFloatMenu(){
      const { route} =this.props;
      return (route.hasOwnProperty('path') && (route.path !=="messages" && route.path !=="notification"));
    }

    render() {
      if (window.innerWidth <= 830 && window.Tawk_API.hasOwnProperty('getStatus')) {
           window.Tawk_API.hideWidget();
      }
      return (
        <div>
           {(this.checkShowFloatMenu()) && <FloatingMenu  />}
           <ComposedComponent {...this.props} />
        </div>
        );
    }
  }

  function mapStateToProps(state) {
    return {
      authReducer: state.authReducer,
      commonReducer: state.commonReducer,
      carProfileReducer: state.carProfileReducer,
      bookingRequestReducer: state.bookingRequestReducer
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ActionCreators, dispatch)
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
}

AuthContainer.contextTypes = {
  router: React.PropTypes.object
};
