import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class Arrow extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { type, className, imgClassName, style, onClick } = this.props;

    return (
      <div
        className={className}
        style={{ ...style, display: "block", }}
        onClick={onClick}
      >
        <img className={`amount-cover ${imgClassName ? imgClassName : ''}`} src={type == "next" ? `${imageBasePath}/images/next-arrow.png` : `${imageBasePath}/images/prev-arrow.png`} alt="" />
      </div>
    );
  }
}
