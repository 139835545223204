import React, { Component } from 'react';
import lowerCase  from 'lodash/lowerCase';
import Header from '../common/Header';
import Footer from '../common/Footer';
import RequestCard from './RequestCard';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import {  appBasePath } from '../../constants/staticData';
import { DropdownButton, MenuItem, Modal, FormGroup, FormControl } from 'react-bootstrap';
import CustomModal from '../common/CustomModal';
import SelectDropdown from '../common/SelectDropdown';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import PlusSidebar from "./PlusSidebar";

export default class DashboardPlusRequest extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile: false,
      userPaymentStatus: false,
      notificationVisible: false,
      messageVisible: false,
      showModal: false,
      max_chars: 300,
      getAllCancelReasons: [],
      chars_left: 0,
      cancelReason: ''
    };
    this.cancelRequestFormData = {
      cancelReason: '',
    };
    this.errorCancelRequestFormData = {
      cancelReason: false,
    };
  }

  componentWillMount() {
    this.props.actions.clearBookingReducerComponentKey();
    //this.props.actions.hideToastMessage();
    if (!(this.props.bookingRequestReducer.getAllCancelReasons && this.props.bookingRequestReducer.getAllCancelReasons.length)) {
      this.props.actions.getAllCancelReasons();
    }
    if (this.props.router.params.requestId) {
      this.props.actions.getBookingRequest(this.props.router.params.requestId, this.props.router);
    }
    this.props.actions.getUserPaymentStatus();

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.getAllCancelReasons) {
      this.setState({
        ...this.state,
        getAllCancelReasons: nextProps.bookingRequestReducer.getAllCancelReasons
      });
    }
    if ((this.props.params.requestId && nextProps.params.requestId) && (nextProps.params.requestId != this.props.params.requestId)) {
      this.props.actions.getBookingRequest(nextProps.params.requestId, nextProps.router);
    }

    this.setState({
      userPaymentStatus: this.props.commonReducer && this.props.commonReducer.userPaymentStatusData && this.props.commonReducer.userPaymentStatusData.enabled,

    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  handleChange(event) {
    let input = null
    input = event
    const val = event.target.value;
    this.setState({
      chars_left: this.state.max_chars - val.length,
      cancelComments: event.target.value
    });
  }

  select(cancelReason) {
    this.cancelRequestFormData['cancelReason'] = true;
    this.errorCancelRequestFormData['cancelReason'] = false;
    this.setState({
      ...this.state,
      cancelReason: cancelReason.id,
      submissionError: false
    });
  }

  submitCallBack() {
    const { actions } = this.props;
    let cancelRequestFormData = {
      ...this.cancelRequestFormData
    };
    let validForm = true;
    for (const key in cancelRequestFormData) {
      if (!cancelRequestFormData[key]) {
        this.errorCancelRequestFormData[key] = true;
        validForm = false;
      } else {
        this.errorCancelRequestFormData[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      this.setState({ submissionError: false });
      let id = this.props.router.params.requestId;
      let payload = {
        "requestId": id,
        "cancellationReasonId": this.state.cancelReason,
        "cancellationComment": this.state.cancelComments
      };
      actions.cancelUserBookinRequest(payload);
    }
  }

  render() {
    const { commonReducer, actions, router, bookingRequestReducer, authReducer } = this.props;
    const requestId = (this.props.router.params && this.props.router.params.requestId) ? this.props.router.params.requestId : undefined;
    const bookingRequestData = bookingRequestReducer.bookingRequests[requestId];
    let requestType = bookingRequestData && bookingRequestData.requeststatus && lowerCase(bookingRequestData.requeststatus.displayname);
    let isEditable = (requestType == 'waiting' || requestType == 'active') ? true : false;
    let isCancellable = (requestType == 'waiting' || requestType == 'active' || requestType == 'accepted') ? true : false;
    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={() => this.setState({ searchMobile: true })} bookingRequestReducer={this.props.bookingRequestReducer} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer} />
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <PlusSidebar page="requestDetail" router={this.props.router}  />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <span className="plus-additional-back-button"><i className="mdi mdi-arrow-left "></i></span>
              <h4>Car Service Request</h4>
              {(isEditable || isCancellable) && <div className="three-dots-icon">
                <DropdownButton className="cancel-request" onToggle={() => { }} bsSize="xsmall" id="dropdown-size-extra-small" title={<i className="mdi mdi-dots-vertical" />} noCaret pullRight>
                  {isEditable &&
                  <MenuItem
                    eventKey="Edit"
                    onClick={() => {
                      switch (bookingRequestData.serviceId) {
                        case 1:
                          router.push(`${appBasePath}/edit-carwash?requestId=${requestId}`);
                          break;
                        case 3:
                          router.push(`${appBasePath}/edit-carrepair?requestId=${requestId}`);
                          break;
                        case 2:
                          router.push(`${appBasePath}/edit-carservice?requestId=${requestId}`);
                          break;
                        default:
                          break;
                      }
                    }}>
                    Edit Request
                  </MenuItem>}
                  {isCancellable && <MenuItem eventKey="Cancel" onClick={() => this.setState({ showModal: true })}>Cancel Request</MenuItem>}
                </DropdownButton>
              </div>}
              <CustomModal submitCallBack={this.submitCallBack.bind(this)} showModal={this.state.showModal} hideModal={() => { this.cancelRequestFormData['cancelReason'] = ''; this.setState({ showModal: false, cancelReason: '', cancelComments: '', chars_left: 0 }) }} footer={true} title="Cancel request" saveText="Confirm" cancelText="Close">
                <Modal.Body>
                  <p className="info-text">Please let us know why you would like to cancel this request from the options below</p>
                  <div className="info-heading">
                    <span>Reason to cancel</span>
                  </div>
                  <div className="model-select form-group error">
                    {/* <select className="car-selection ">
                      <option value="select">I dont want to do it now</option>
                      <option value="volvo">Emergency use</option>
                      <option value="saab">I will do it later</option>
                      <option value="mercedes">Too expensive</option>
                    </select>
                    <i className="mdi mdi-chevron-down" /> */}
                    <SelectDropdown getAllCancelReasons={this.state.getAllCancelReasons} select={this.select.bind(this)} />
                    {this.errorCancelRequestFormData.cancelReason && this.state.submissionError && <span className="error-text">Select Reason to Cancel</span>}
                  </div>
                  <div className="comments">
                    <FormGroup>
                      <FormControl
                        maxLength="300"
                        className="textAlign"
                        componentClass="textarea"
                        placeholder="Comments"
                        onChange={this.handleChange.bind(this)} />
                    </FormGroup>
                    <span className="text-limit">{this.state.chars_left}/300</span>
                  </div>
                </Modal.Body>
              </CustomModal>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper">
              <div className="myCar-list request-wrapper">
                <div className="myCar-body row">
                  {/*Job Updates*/}
                  <RequestCard {...this.props} userPaymentStatus={this.state.userPaymentStatus}  requestId={requestId} jobType={requestType} router={router} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>} */}
        <div className="footerSection footerSection-plus" >
          <Footer {...this.props} />
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
        </div>}

      </div>
    );
  }
}
