import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import GMapView from './GMapView';

export default class PickLocationModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      defaultCenter: undefined
    };
  }
  componentWillMount() {
    const that = this;
    navigator.geolocation.getCurrentPosition(function(location) {
      that.setState({
        'defaultCenter': {'lat': location.coords.latitude, 'lng': location.coords.longitude}
      });
    });
  }
  changeCurrentPosition(markerPosition) {
    const latlng = markerPosition.latLng;
    const lat = latlng.lat();
    const lng = latlng.lng();
    if (lat && lng) {
      this.setState({'defaultCenter': {'lat': lat, 'lng': lng}});
    }
  }
  getCurrentLocationFromLatLng(latlng, hideModal){
    const that = this;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {'lat' : latlng.lat, 'lng': latlng.lng}}, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && results && results.length) {
        that.props.pickLocationCallBack({
          userLocation: {
            'lat': results[0].geometry.location.lat(),
            'lng': results[0].geometry.location.lng()
          },
          'label': results[0].formatted_address,
          'formatted_address': results[0].formatted_address
        });
      }
      that.props.hideModal();
    });
  }
  render(){
    return (
        <Modal onHide={this.props.hideModal} className="map-modal" show={this.state.defaultCenter ? true : false}>
          <Modal.Header>
            <Modal.Title>{"Mark your location"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.state.defaultCenter &&
                <GMapView
                  showRecenter={true}
                  showMarker={true}
                  markerDraggable={true}
                  onDragEnd={(markerPosition) => {this.changeCurrentPosition(markerPosition)}}
                  defaultCenter={this.state.defaultCenter}
                  containerElement={<div style={{ height: 562 + 'px', width: 'auto' }} className="locationPopup" />}
                  mapElement={<div style={{ height: 562 + 'px', width: 'auto' }} className="locationPopup" />}
                />
              }
          </Modal.Body>
          <Modal.Footer>
            <Button btnType="transparent" btnSize="sm" fontSize={15} label={this.props.cancelText?this.props.cancelText:"Cancel"}  btnCallBack={this.props.hideModal}/>
            <Button btnType="red" btnSize="sm" fontSize={15} label={this.props.saveText?this.props.saveText:"Save"} btnCallBack={(e) => {this.props.pickLocationCallBack && this.getCurrentLocationFromLatLng(this.state.defaultCenter) }} />
          </Modal.Footer>
      </Modal>
    );
  }
}
