import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import LoginHeader from '../common/LoginHeader';
import { appBasePath } from '../../constants/staticData';
export default class OfferTerms extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      notificationVisible: false,
      messageVisible: false,
      promoId: this.props.params?this.props.params.id : "",
      promotionalOffersTerms: ""
    };
  }

  componentWillMount() {


    if(this.props.params.id)
      this.props.actions.getSingleOffer(this.props.params.id);


  }
  componentWillReceiveProps(nextProps) {


    if(this.props.params.id) {

      let offer = nextProps.commonReducer && nextProps.commonReducer.singleOffersData ? nextProps.commonReducer.singleOffersData : "";


      this.setState({
        promotionalOffersTerms: offer.termsAndConditions ? offer.termsAndConditions : ""
      });
    }


  }



  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  render() {
    const { commonReducer, router, actions,bookingRequestReducer } = this.props;
    return (
      <div className="contact-body">
        {localStorage.getItem('authData')
          ? <Header
            hideOption={() => this.setState({ searchMobile: true })}
            bookingRequestReducer = {bookingRequestReducer}
            commonReducer={commonReducer}
            notificationCount={2}
            profileName="Derrick Frank"
            notificationCallBack={this.toggleNotification}
            messageCallBack={this.toggleMessage.bind(this)}
            router={router}
            actions={actions}
            authReducer={this.props.authReducer}
            metaTagKey={'terms'}
          />
          : <LoginHeader
            hideOption={() => this.setState({ searchMobile: true })}
            pageType="signIn"
            headerClickAction={e => {
              e.preventDefault();
              router.push(`${appBasePath}/sign-up`);
            }}
            metaTagKey={'terms'}
          />}
        {/* <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} /> */}
        <div className="banner-top"></div>
        <div className="contact-us">
          <div className="row">
            <div className="col-md-12">

              <div className="n-holder">
                <div className="inSection">

                  <div className="padwrapper">
                    <div className="myCar-list">
                      <div className="myCar-body row">
                        <div className="desc-holder">
                          <div className="page-sec-header">
                            <h4>Terms & Conditions</h4>
                          </div>


                                  <div className="terms-page-content" dangerouslySetInnerHTML={{ __html: this.state.promotionalOffersTerms }} />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
                {this.state.searchMobile &&
            <div>

                        <MobileHeaderSearch
                mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() => this.setState({ searchMobile: false })}
            />
                    </div>}
              <Footer {...this.props} />      
            </div>
        );
    }

}

