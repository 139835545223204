import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Button from '../common/Button';
import OtherDetails from './OtherDetails';
import ServiceDetails from './ServiceDetails';
import Timeline from './Timeline';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import CustomModal from '../common/CustomModal';
import map from 'lodash/map';
import size from 'lodash/size';
import CreateRequestPopup from '../common/CreateRequestPopup';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { appBasePath } from '../../constants/staticData';
import ToastMessage from '../common/ToastMessage';

export default class BookService extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      nextOffset: 0,
      carProfileId: undefined,
      notificationVisible: false,
      messageVisible: false,
      timelineUpdate: "otherDetails",
      myCarDropdownIcon: true,
      showModal: false,
      deleteModal: false,
      bookServiceModalVisible: false,
    };
    this.deleteCarProfile = this.deleteCarProfile.bind(this);
    this.editCarProfile = this.editCarProfile.bind(this);
    this.switchCarProfile = this.switchCarProfile.bind(this);
    this.showBookServiceModal = this.showBookServiceModal.bind(this);
  }
  componentDidMount() {
    const routeParams = this.props.routeParams;
    if (routeParams.id) {
      this.props.actions.getCarProfileDetails(routeParams.id, this.props.router);
    }
    if (!this.props.carProfileReducer.carProfiles) {+1.
      this.props.actions.getCarProfileList(this.props.router);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.carProfileReducer.currentComponentKey) {
      this.props.router.push(appBasePath + nextProps.carProfileReducer.currentComponentKey);
    }
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  timelineDetail(val) {
    this.setState({ timelineUpdate: val });
  }
  myCarDropdown() {
    this.setState({ myCarDropdownIcon: !this.state.myCarDropdownIcon, deleteModal: false });
  }
  carSelection(car) {
    this.setState({ selectedCar: car });
  }
  // modalVisiblity() {
  //     this.setState({ showModal: true });
  // }
  editCarProfile() {
    const routeParams = this.props.routeParams;
    if (routeParams && routeParams.id) {
      this.props.router.push(`${appBasePath}/car-profiles/${routeParams.id}/edit`);
    }
  }
  deleteCarProfile() {
    const routeParams = this.props.routeParams;
    if (routeParams && routeParams.id) {
      this.props.actions.deleteCarProfile(routeParams.id, this.props.router);
    }
  }

  switchCarProfile(carProfileId) {
    if (carProfileId) {
      this.props.actions.getCarProfileDetails(carProfileId, this.props.router);
      this.props.router.push(`${appBasePath}/car-profiles/${carProfileId}/view`);
    } else {
      this.props.router.push(`${appBasePath}/car-profiles/create`);
    }
  }

  showBookServiceModal(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      'bookServiceModalVisible': !this.state.bookServiceModalVisible,
    });
  }

  hideModalCallback() {
    this.setState({ bookServiceModalVisible: false });
  }

  render() {
    const { carProfileReducer, commonReducer, router, actions,bookingRequestReducer } = this.props;
    let carProfiles = this.props.carProfileReducer.carProfiles;
    const routeParams = this.props.routeParams;
    let currentCarProfile = undefined;
    if (carProfiles && !this.props.carProfileReducer.hasOwnProperty('currentCarProfile')) {
      if (routeParams.id) {
        currentCarProfile = carProfiles[routeParams.id];
      }
    } else {
      currentCarProfile = this.props.carProfileReducer.currentCarProfile;
    }
    return (
      <div>
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <Button btnType="transparent" btnSize="sm" customClass="timeline" fontSize={14} label="Book Service" btnCallBack={this.showBookServiceModal} />
              <div className="text-dropdown add-new car-profile-header" >
                {(carProfiles && currentCarProfile) &&
                  ((size(carProfiles) == 1) ?
                  <h4>{currentCarProfile.name}</h4>
                  :
                  (<DropdownButton bsSize="large" id="dropdown-large" noCaret onSelect={this.switchCarProfile} onToggle={() => { this.myCarDropdown() }} title={
                    <span>
                      <h4>{currentCarProfile.name}</h4>
                      {this.state.myCarDropdownIcon && <i className="mdi mdi-chevron-down" />}
                      {!this.state.myCarDropdownIcon && <i className="mdi mdi-chevron-up" />}
                    </span>} >
                    {map(carProfiles, (carProfile, key) => {
                      return (<MenuItem eventKey={carProfile.id} key={key}>{carProfile.name}</MenuItem>);
                    })}
                  </DropdownButton>))}
              </div>

              <div className="three-dots-icon">
                <DropdownButton bsSize="xsmall" id="dropdown-size-extra-small" title={<i className="mdi mdi-dots-vertical" />} noCaret pullRight onToggle={() => {}}>
                  <MenuItem eventKey="Edit" onClick={this.editCarProfile}>Edit</MenuItem>
                  <MenuItem eventKey="Delete" onClick={() => { this.setState({ deleteModal: true }) }}>Delete</MenuItem>
                </DropdownButton>
              </div>
              {currentCarProfile && <CustomModal showModal={this.state.deleteModal} title={"Delete " + currentCarProfile.name} className="deleteCarProfile-modal" hideModal={() => { this.setState({ deleteModal: false }) }} footer={true}
                submitCallBack={this.deleteCarProfile}
                saveText="Delete">
                <Modal.Body>
                  <p className="warning-text">Are you sure you want to delete this profile?</p>
                </Modal.Body>
              </CustomModal>}
              <CustomModal showModal={this.state.bookServiceModalVisible} title="book a service" className="bookService-modal" closeIcon="true" hideModal={() => { this.setState({ 'bookServiceModalVisible': false }) }}>
                <Modal.Body>
                  <ul>
                    <CreateRequestPopup selecedCarProfileId={routeParams && routeParams.id} carProfileReducer={carProfileReducer} router={router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={this.hideModalCallback.bind(this)} />
                  </ul>
                </Modal.Body>
              </CustomModal>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper">
              <div className="row timeline-card">
                <div className="col-md-4 pad0">
                  {currentCarProfile && <ServiceDetails {...currentCarProfile} {...this.props}/>}
                </div>
                <div className="col-md-8 pad0">
                  <div className="row timeline-summary-header">
                    <div className="col-md-6 col-sm-6 col-xs-6 pad0">
                      <div className={this.state.timelineUpdate == "otherDetails" ? "timeline-summary-tab active" : "timeline-summary-tab"} onClick={() => { this.timelineDetail('otherDetails'); }}>
                        <span>OVERVIEW</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6 pad0">
                      <div
                        className={this.state.timelineUpdate == "timeline" ? "timeline-summary-tab active" : "timeline-summary-tab"}
                        onClick={() => {
                          currentCarProfile && currentCarProfile.serviceHistory && currentCarProfile.serviceHistory.length ==0 && this.props.actions.getCarServiceHistory(currentCarProfile.id, 10, 0, false);
                          this.timelineDetail('timeline');
                          // this.setState({ nextOffset: 0 });
                        }}
                      >
                        <span>TIMELINE</span>
                      </div>
                    </div>
                  </div>
                  <div className="row timeline-summary-body">
                    <div className="">
                      {/* <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}> */}
                        {this.state.timelineUpdate == "otherDetails" && <div className="tab-otherDetails">
                          {currentCarProfile && <OtherDetails
                            {...currentCarProfile}
                            carProfileReducer={carProfileReducer}
                            />}
                        </div>}
                        {this.state.timelineUpdate == "timeline" && <div className="tab-timeline ">
                          <Timeline carProfileReducer={carProfileReducer} currentCarProfile={currentCarProfile} nextOffset={this.state.nextOffset} actions={actions} />
                        </div>}
                      {/* </CustomScroll> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
      </div>
    );
  }
}
