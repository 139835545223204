import React, { Component } from "react";
import CarouselSlider from "../common/CarouselSlider";
import LoginHeader from "../common/LoginHeader";
import Button from "../common/Button";
import TextInput from "../common/TextInput";
import AlertDismissable from "../common/AlertDismissable";
import CustomScroll from "react-custom-scroll";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { validateFormSubmit } from "../../helpers";
import {
  validationMessage,
  googleClientId,
  fbAppId,
  imageBasePath,
  appBasePath
} from "../../constants/staticData";
import { has } from "lodash";
import TagManager from "react-gtm-module";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionError: false,
      termsAgreed: true,
      isButtonLoading: false
    };
    this.initialFormData = {
      name: "",
      email: "",
      password: "",
      phone: "",
      terms: true
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      name: false,
      email: false,
      password: false,
      phone: false
      // 'terms': false
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
  }
  componentWillMount() {
    const userId = localStorage.getItem("userId");
    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";

    let provider = authData.provider?authData.provider:"normal";
    let urlData = "?method="+provider+"&route=signup";

    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      this.props.router.push(`${appBasePath}/dashboard-plus`+urlData);
    }
  }
  componentWillReceiveProps(nextProps) {
    const userId = localStorage.getItem("userId");
    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ isButtonLoading: false });
    }

    let provider = authData.provider?authData.provider:"normal";
    let urlData = "?method="+provider+"&route=signup";

    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      this.props.router.push(`${appBasePath}/dashboard-plus`+urlData);
    }
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  onKeyPress(e){
    let key=e.keyCode || e.which;
    if(key==13){
      this.sendOTPAction(e);
    }
  }

  sendOTPAction(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      this.setState({ submissionError: false, isButtonLoading: true });
      let phone_no = this.formData.phone;
      this.props.actions.showVerifyOTPPage({
        name: this.formData.name,
        email: this.formData.email,
        phone: "+971" + phone_no,
        password: this.formData.password,
        type: "customer",
        usertype: "customer",
        flow:"Normal Sign up",
        subtype:"signup",
      });
    }
  }
  componentWillUnmount() {
    this.props.actions.hideErrorMessage();
  }
  render() {
  const tagManagerArgs = {
    dataLayer: {
      pageCategory: "Normal Sign Up",
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs);

  const { router, authReducer } = this.props;
    return (
      <div className="container-fluid" id="wrapper">
        <LoginHeader
          pageType="signUp"
          headerClickAction={e => {
            e.preventDefault();
            router.push(`${appBasePath}/sign-in`);
          }}
          metaTagKey={"sign-up"}
        />
        <CarouselSlider
          sliderImage={`${imageBasePath}/images/customer-sign-up-carcility.png`}
          sliderImageAlt="Customer sign up carcility"
        />
        <div className="col-md-6 col-sm-12 col-xs-12 pad0 grid-12">
          <div className="customScroll">
            <CustomScroll
              heightRelativeToParent="calc(100%)"
              allowOuterScroll={true}
            >
              <div className="login-panel signup">
                <div className="login-panel-header">
                  <h1 className="login-title">Sign Up as Customer</h1>
                  <span data-labelledby="Facebook Sign up" >
                    <FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture"
                      cssClass="btn btn-theme lg facebook blue"
                      textButton="Sign up with Facebook"
                      callback={socialResponse => {
                        if (socialResponse && socialResponse.accessToken) {
                          this.props.actions.socialAuth(
                            socialResponse.accessToken,
                            "facebook",
                            "Facebook Sign up"
                          );
                        }
                      }}
                      isMobile={true}
                      disableMobileRedirect={true}
                      icon={<i className="mdi mdi-facebook" />}
                    />
                  </span>

                  <span data-labelledby="Google Sign up" >
                     <GoogleLogin
                       clientId={googleClientId}
                       className="btn btn-theme lg google"
                       onSuccess={socialResponse => {
                         if (socialResponse && socialResponse.accessToken) {
                           this.props.actions.socialAuth(
                             socialResponse.accessToken,
                             "google",
                             "Google Sign up"
                           );
                         }
                       }}
                     >
                    <i className="mdi mdi-google" />Sign up with Google
                  </GoogleLogin>
                  </span>

                </div>
                <div className="or-text">
                  <span>OR</span>
                </div>
                <div className="login-panel-body">
                  {authReducer &&
                  authReducer.showErrorMessage && (
                    <AlertDismissable
                      bsStyle="danger"
                      closeLabel="Close alert"
                      closeAction={this.props.actions.hideErrorMessage}
                    >
                      <p>
                        {" "}
                        <i className="mdi mdi-block-helper" />{" "}
                        {authReducer.statusMessage}{" "}
                      </p>
                    </AlertDismissable>
                  )}
                  <TextInput
                    type="text"
                    label="Name"
                    name="name"
                    showValidationError={this.errors["name"]}
                    emptyError={validationMessage.name.emptyError}
                    validationError={validationMessage.name.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                  />
                  <TextInput
                    type="email"
                    label="Email"
                    name="email"
                    showValidationError={this.errors["email"]}
                    emptyError={validationMessage.email.emptyError}
                    validationError={validationMessage.email.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                  />
                  <TextInput
                    type="phone"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    name="phone"
                    showValidationError={this.errors["phone"]}
                    emptyError={validationMessage.phone.emptyError}
                    validationError={validationMessage.phone.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                  />
                  <TextInput
                    type="password"
                    label="Password"
                    name="password"
                    showValidationError={this.errors["password"]}
                    emptyError={validationMessage.password.emptyError}
                    validationError={validationMessage.password.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                  />
                  <div className="checkbox-style checkbox-wrapper n-align">
                    <input
                      type="checkbox"
                      id="check-box"
                      onChange={e => {
                        this.setState({ terms: !e.target.checked });
                        this.formData.terms = e.target.checked;
                        this.errors.terms = !e.target.checked;
                      }}
                      checked={this.formData.terms}
                    />
                    <label htmlFor="check-box" className="custom-checkbox" />
                    <label htmlFor="" className="n-signup-checkbox">
                      By using Carcility, you agree to our
                      <a
                        onClick={() => {
                          // this.props.router.push(`${appBasePath}/terms`);
                          window.open(`${appBasePath}/terms`, "_blank");
                        }}
                        className="blue-text"
                      >
                        {" "}
                        Terms & Conditions
                      </a>, and{" "}
                      <a
                        onClick={() => {
                          // this.props.router.push(`${appBasePath}/privacy`);
                          window.open(`${appBasePath}/privacy`, "_blank");
                        }}
                        className="blue-text"
                      >
                        Privacy Policy
                      </a>.
                    </label>
                  </div>
                  <p
                    className={
                      this.errors.terms
                        ? "note-text tc-text error"
                        : "note-text tc-text hide"
                    }
                  >
                    <span className="error-text">
                      {"Please agree to the terms and condition"}
                    </span>
                  </p>
                </div>
                <div className="login-panel-footer">
                  <Button
                    dataLabelledby="Get OTP"
                    btnCallBack={this.sendOTPAction.bind(this)}
                    isSubmitBtn={true}
                    btnType="red"
                    btnSize="sm"
                    label="Get OTP"
                    isButtonLoading={this.state.isButtonLoading}
                  />
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
