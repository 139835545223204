import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class NotificationEmptyState extends Component {

    render() {
        return (
            <div >
                <div className="empty-state-holder">
                    <span className="empty-state-content">
                        <img src={`${imageBasePath}/images/notification_empty.png`} alt="" />
                        <span className="text">Looks like you dont have any notifications. </span>
                    </span>
                </div>
            </div>
        );
    }
}
