import React, { Component } from 'react';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Button from '../common/Button';
import SpriteMaterial from '../common/SpriteMaterial';



export default class Header extends Component {
    constructor(props) {
      super(props);
      const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};
      this.state={
        showOfferNotification :false,
        navbarVisible: false,
        searchMobile: false,
        sidebarOpen: false,
        sidebarProfileDropdown: false,
        autoCompleteData: [],
        locateMeDD: false,
        redirectToSection : localStorage.getItem("redirectToSection"),
        locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : '',
      }
      this.moveToInternalPosition = this.moveToInternalPosition.bind(this);
      this.findPos= this.findPos.bind(this);
      window.addEventListener('scroll', this.scrollPage);
    }

    moveToInternalPosition(id){
      // document.getElementById(`${id}`).scrollIntoView();
      window.scroll(0,this.findPos(document.getElementById(`${id}`)));
      const { innerWidth} = this.props;
      if(innerWidth<480){
        this.setState({navbarVisible: !this.state.navbarVisible});
      }
    }

    findPos(obj) {
      var curtop = 0;
      const { innerWidth} = this.props;
      var additionalPos = innerWidth < 480 ? 150 :innerWidth < 800 ? 120 : 40;
      if (obj.offsetParent) {
          do {
              curtop += obj.offsetTop;
          } while (obj = obj.offsetParent);
      return [(curtop - (additionalPos))];
      }
  }


    componentDidMount() {
      setTimeout(
        function() {
          this.setState({showOfferNotification: true});
        }
          .bind(this),
        5000
      );

      if(this.state.redirectToSection ){
        //this.moveToInternalPosition(this.state.redirectToSection );

        window.scroll(0,this.findPos(document.getElementById(`${this.state.redirectToSection }`)));
        localStorage.removeItem('redirectToSection');

      }


    }

  autocompleteLocation(e) {
      let that = this;
      const searchTerm = e.target.value;
      if (searchTerm) {
        that.setState({
          locationSearchTerm: searchTerm
        });
        let places = new google.maps.places.AutocompleteService();
        places.getPlacePredictions({ input: searchTerm }, function (res) {
          that.setState({
            autoCompleteData: res
          });
        });
      } else {
        localStorage.removeItem('userSelectedLocation');
        this.props.actions.setCurrentLocation({});
        that.setState({
          locationSearchTerm: searchTerm,
          autoCompleteData: []
        });
      }
    }



render(){
    let baseUrl = location.origin;
    let locationData = [{ textPlaceholder: 'Current Location' }];
    this.state.autoCompleteData.map((location, key) => {
      return locationData.push({ key: key, description: location.description, place_id: location.place_id });
    });
    this.props.workshopsData && this.props.workshopsData.map((location, key) => {
      return locationData.push({
        key: key,
        lat: location.lat,
        lng: location.lng,
        label: location.label,
        id: location.id,
        formatted_address: location.location
      });
    });
    const locationAutosuggestedList = locationData.map((val, key) => {
      return (
        <MenuItem
          onClick={item => {
            if (item.target.text != 'Current Location') {
              this.setUserLocation(val, item.target.text);
            } else {
              if (navigator.geolocation) {
                const that = this;
                navigator.geolocation.getCurrentPosition(function (position) {
                  that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                }, function (error) {
                  switch (error.code) {
                    case error.PERMISSION_DENIED:
                      that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                      break;
                    case error.POSITION_UNAVAILABLE:
                      that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                      break;
                    case error.TIMEOUT:
                      that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                      break;
                    case error.UNKNOWN_ERROR:
                      that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                      break;
                  }
                }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 });
              } else {
                this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
              }
            }
          }}
          eventKey={key}
          key={key}
        >
          {val.description ? val.description : val.label ? val.label : val.textPlaceholder}
        </MenuItem>
      );
    });

    return(

        <div>
          <div className={this.state.showOfferNotification ? "notification-alert  show-notification" : "notification-alert alert alert-success"} role="alert">
            <div className="content">

              {/*
            <div className="off-percent">We have just partnered with Mobil 1 to improve customer experience for automotive repair and services.</div>
*/}

              {/*
            <span className="off-percent">45% OFF</span> on Steam car wash *Limited period offer. Use Code: <span className="off-percent">45OFF</span>*
*/}

              <span className="off-percent">Save upto 50% on car service. Limited Offer!</span>


              <span className="book-now" onClick={() => this.props.router.push(`${appBasePath}/offers/car_service`) } >BOOK NOW</span>


            </div>
            <div className="  close" onClick={() => this.setState({ showOfferNotification: false })}>
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
        {!this.props.headerSectionVisible ?<nav className={this.state.showOfferNotification ? "navbar navbar-default default-header default-show-header-top" : "navbar navbar-default default-header default-header-top"} id="header-section1">


          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
                onClick={() => {
                  this.setState({ navbarVisible: !this.state.navbarVisible });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand desk-logo logo-border" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>

              </div>
            {/*Search mobile for mobile */}
            <span className="search-mobile sm-mob" onClick={() => this.props.searchMobileFunc()}>
             {/*<i className="mdi mdi-magnify" aria-hidden="true" />*/}
             {this.props.innerWidth <= 767 && <SpriteMaterial posx={73} posy={0} width={21} height={23} widthBack={21} classNameVal='magnify' aria-hidden="true"/>}
            </span>

            <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
              <ul className="nav navbar-nav navbar-left-sub">
               {/* <li className="search-mobile md-mob" onClick={() => this.setState({ searchMobile: true })}>
                  <i className="mdi mdi-magnify" aria-hidden="true" />
                </li> */}

                <li className="offer-item">
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/all-offers');
                    }}
                  >
                    Offers{' '}
                  </a>
                </li>
                <li className="work-active">

                  <a onClick={() => this.moveToInternalPosition('howworks-section')}>
                    how it works
                  </a>
                  <label />
                </li>
                <li>
                  <a href="/business ">
                    For Business
                  </a>
                  <label />
                </li>
                <li>
                <a href="/blog">
                  BLOG
                </a>
                <label />
              </li>
              </ul>
              <ul className="nav navbar-nav navbar-right navbar-right-land">
              {/*<li className="download-text">
                  <a onClick={() => this.moveToInternalPosition('download-section')}>
                  <img className="landing-download" src={`${imageBasePath}/images/download-app-logo.png`} alt="download the app" />
                   Download the app!
                  </a>
                  </li>*/}
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/sign-in');
                    }}
                  >
                    Log In{' '}
                  </a>
                  <label />
                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/register');
                    }}
                  >
                    {' '}Sign Up
                  </a>
                  <label />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        :<nav className="navbar navbar-default scroll-header" id="header-section2">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
              onClick={() => {
                this.setState({ navbarVisible: !this.state.navbarVisible });
              }}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className=" navbar-brand desk-logo logo-border" href={baseUrl}>
              <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
            </a>
          </div>
          <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
            <ul className="nav navbar-nav nav-left navbar-scroll-landing">
            <li className="offer-item">
               <a
                 onClick={e => {
                 e.preventDefault();
                 this.props.router.push(appBasePath + '/all-offers');
               }}
               >
                 Offers{' '}
               </a>
            </li>
              <li>
                <div className="banner-search">
                  <div className="search-wrapper">
                    <form className="form-inline">

                      <DropdownButton
                        bsSize="small"
                        id="dropdown-size-small"
                        noCaret
                        onToggle={e => this.setState({ locateMeDD: e })}
                        onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                        className="location-search"
                        title={
                          <span className="search-left">
                             {/*<i className="mdi mdi-crosshairs-gps" />*/}
                             <SpriteMaterial posx={1010} posy={0} width={24} height={23} widthBack={24} classNameVal='landing-crosshairs-gps'/>
                            <input
                              type="text"
                              className="form-control form-search-land"
                              placeholder="Search a Service near you"
                              value={this.state.locationSearchTerm}
                              onChange={e => {
                                e.preventDefault();
                                this.autocompleteLocation(e);
                                this.setState({ locationSearchTerm: e.target.value });
                              }}
                            />
                            {/*<i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />*/}
                            {
                              this.state.locateMeDD ?
                              <SpriteMaterial posx={952} posy={0} width={16} height={20} widthBack={16} classNameVal='chevron-up'/>:
                              <SpriteMaterial posx={938} posy={0} width={16} height={20} widthBack={16} classNameVal='chevron-down'/>
                            }
                          </span>
                        }
                      >
                        {locationAutosuggestedList}
                      </DropdownButton>


                    </form>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right navbar-right-land">
              <li>
                <Button
                  dataLabelledby="Get Started"
                  isSubmitBtn={true}
                  btnCallBack={() => {
                    this.props.router.push(appBasePath + '/guest-user');
                  }}
                  btnType="red"
                  btnSize="sm"
                  fontSize={13}
                  label="Get Started"
                />
              </li>
              {/*<li className="download-text">
                  <a onClick={() => this.moveToInternalPosition('download-section')}>
                  <img className="landing-download" src={`${imageBasePath}/images/download-app-logo.png`} alt="download the app" />
                   Download the app!
                  </a>
                </li>*/}
              <li
                className="menu-option"
                onClick={() => {this.props.sideBarFunc()}}
              >
                {/*<i className="mdi mdi-menu" />*/}
                <SpriteMaterial posx={965} posy={0} width={21} height={23} widthBack={21} classNameVal='menu'/>
              </li>
            </ul>
          </div>
        </div>
      </nav>}
        </div>
    )


}
}
