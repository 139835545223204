import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class MobilText extends Component {


  constructor(props, context) {
    super(props, context);



  }



  render() {
      const {router, authData} = this.props;




      return (
           <div className="mobil-banner">
               <div className="container-fluid">


                 <p className="header">Preferred Lubricant Partner</p>

                 <img className="mobil-dashboard-logo" src={`${imageBasePath}/images/mobil-logo-dashboard.png`} alt=""/>
                 <img className="carcility-dashboard-logo" src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt=""/>


               </div>
           </div>
        );
    }
}
