import React, { Component } from 'react';
import { Link,animateScroll as scroll,  } from 'react-scroll';;
import { map } from 'lodash';
import { appBasePath, metaTagData, canonicalLink, imageBasePath,phoneNumber } from '../../constants/staticData';
import { Helmet } from "react-helmet";
import SpriteMaterial from '../common/SpriteMaterial';
import {isWebpSupported} from 'react-image-webp/dist/utils';


export default class Footer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let baseUrl = location.origin;

    const footerItem = [
      {
        footerHeading: 'Car Wash',
        sublist: [
          {
            title: 'Exterior/Interior wash',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Steam Wash',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Car Wrapping',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Paint Treatment',
            hyperLink: '/car-wash-in-dubai'
          },
          {
            title: 'Car Detailing',
            hyperLink: '/car-wash-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'Car Service',
        sublist: [
          {
            title: 'Normal Car Servicing',
            hyperLink: '/car-service-in-dubai'
          },
          {
            title: 'Annual Car Maintenance',
            hyperLink: '/car-service-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'Car Repair',
        sublist: [
          {
            title: 'AC/Heating & Cooling',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Battery Services',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Engine fixes',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Window Screens & Mirrors',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Tyres, Wheels & Tracking',
            hyperLink: '/car-repair-in-dubai'
          },
          {
            title: 'Interiors, Audio & Navigation Us',
            hyperLink: '/car-repair-in-dubai'
          }
        ]
      },
      {
        footerHeading: 'For Business',
        sublist: [
          {
            title: 'Join Us',
            hyperLink: '/business'
          },
          {
            title: 'Benefits',
            hyperLink: '/business'
          },
          {
            title: 'Sign Up',
            hyperLink: '/business/sign-up'
          }
        ]
      }
    ];
    const footerLink = map(footerItem, (item, key) => {
      return (
        <div className="col-md-3 col-sm-3 col-xs-12 pad0" key={key}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTagData['landing'] && metaTagData['landing'].title ? metaTagData['landing'].title : 'Carcility'}</title>
            <meta name="description" content={metaTagData['landing'] && metaTagData['landing'].description ? metaTagData['landing'].description : ''} />
            <link rel="canonical" href={canonicalLink} />
          </Helmet>
          <div className="footer-link new-footer-link">
            <h5>{item.footerHeading}</h5>
            <ul className="list-unstyled">
              {map(item.sublist, (subListVal, index) => {
                return (
                  <li key={index}>
                    {subListVal.title == 'Mobile Apps' &&
                    <Link to="download" smooth={true} offset={-100} duration={600}>
                      Mobile Apps
                    </Link>}
                    {subListVal.title != 'Mobile Apps' &&
                    <a
                      href={baseUrl + subListVal.hyperLink}
                      onClick={() => {
                        if (subListVal.title === 'Benefits') {
                          window.localStorage.setItem(subListVal.title, true);
                        }
                      }}
                    >
                      {subListVal.title}
                    </a>}

                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    });
    const socialIcon = [
      {
        title: 'facebook',
        position: 303,
        width: 20,
        hyperLink: 'https://www.facebook.com/carcility'
      },
      {
        title: 'twitter',
        position: 426,
        width: 26,
        hyperLink: 'https://twitter.com/carcility'
      },
      {
        title: 'instagram',
        position: 190,
        width: 26,
        hyperLink: 'https://www.instagram.com/carcility/'
      },
      {
        title: 'whatsapp',
        position: 164,
        width: 26,
        hyperLink: `//api.whatsapp.com/send?phone=${phoneNumber}`
      },
      {
        title: 'youtube-play',
        position: 141,
        width: 27,
        hyperLink:`https://www.youtube.com/watch?v=h736TiMyfrs`
      },
      {
        title: 'pinterest',
        position: 115,
        width: 26,
        hyperLink:`https://www.pinterest.com/carcilitysocial/`
      }
    ];
    const quickLinks = [
      {
        title: 'About Us',
        hyperLink: '/about'
      },
      {
        title: 'Help',
        hyperLink: '/blog/faq'
      },
      {
        title: 'Blog',
        hyperLink: '/blog'
      },
      {
        title: 'Contact Us',
        hyperLink: '/contact'
      }
    ];
    const socialLink = map(socialIcon, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
           {/*<i className={'mdi mdi-' + item.title} />*/}
           <SpriteMaterial posx={item.position} posy={0} width={20} height={23} widthBack={item.width} classNameVal='social-links'/>
          </a>
        </li>
      );
    });
    const quickLink = map(quickLinks, (item, key) => {
      return (
        <li key={key}>
          <a href={item.hyperLink} target="_blank">
            {item.title}
          </a>
        </li>
      );
    });
    
    let Background = isWebpSupported() ?'../images/landing/footer-background-img.webp' : '../images/landing/landing-footer-background.svg';
    let BackgroundResponse = isWebpSupported() ?'../images/landing/footer-background-img-mob.webp' : '../images/landing/landing-welcome-back-mob.svg';

    return (<div id="main-landing-footer">
      
      <div className="footerSection footerpad dashboard-footer authpage-footer landingPage-footer landing-Footer">
        <div id="landing-footer-back" style={{background:(this.props.innerWidth > 1100 && `url(${Background}) repeat-x #FCFCFF`)}}>
          <div className="footer-container">
            <div className="quick-link-section landing-quick-link">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                  <div className="footer-link new-footer-link">
                    <ul className="social-icon">
                      {quickLink}
                       <li className="mobil-footer landing-mobile-fal"><img src={`${imageBasePath}/images/landing/mobil-logo.svg`} alt="mobil1" /></li>
                    </ul>   
                  </div>
                </div>

              </div>
            </div>
            
            <div >
              <div className="row">
                <div className="col-md-9 col-sm-8 col-xs-7 pad0">
                  {footerLink}
                </div>
              </div>
            </div>
            <img className="mobil-footer1 landing-mobile-fal landing-desk-fal mobil-master" src={`${imageBasePath}/images/landing/mobil-logo.svg`} alt="mobil1" />
           
           
            
            <div className="social-section landing-social-section">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                  <div className="footer-link landing-footer-link new-footer-link">
                    <ul className="social-icon">
                      {socialLink}
                    </ul>
                  </div>
                </div>
               
                
            </div>
           </div>
          </div>
        </div>
        <div className="social-section term-condition-block col-md-12 col-sm-12 col-xs-12 pad0">
                  <div className="terms-right terms">
                   <div className="terms-text">
                    <label className="termsConditionsLink">
                      <a
                        onClick={() => {
                          this.props.router.push(appBasePath + '/terms');
                          // window.open(appBasePath + '/terms', "_blank");
                        }}
                      >
                        Terms and Conditions and{' '}
                      </a>
                      <a
                        onClick={() => {
                          this.props.router.push(appBasePath + '/privacy');
                          // window.open(appBasePath + '/privacy', "_blank");
                        }}
                      >
                        Privacy Policy{' '}
                      </a>
                    </label>
                    <span> | </span>
                    <label>&copy;2020 <a>carcility.com</a>.All Rights Reserved</label>
                    </div>
                    <ul className="terms-img desktop-footer-fal">
                      <li className="visa visa-footer"><img src={`${imageBasePath}/images/landing/visa-landing.svg`} alt="visa card" /></li>  
                      <li className="master master-footer"><img src={`${imageBasePath}/images/landing/master-landing.svg`} alt="master card" /></li>  
                    </ul>
                    <ul className="terms-img terms-landing-mobile-fal">
                      <li className="master master-footer"><img src={`${imageBasePath}/images/landing/master-landing.svg`} alt="master card" /></li>  
                      <li className="visa visa-footer"><img src={`${imageBasePath}/images/landing/visa-landing.svg`} alt="visa card" /></li>  
                      </ul>
                  </div>
                  
                </div>
              </div>
      </div>);
}
}
