import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import TextInput from '../common/TextInput';
import LightBox from '../common/LightBox';

import filter from 'lodash/filter';
import map from 'lodash/map';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import range from 'lodash/range';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import CarDetails from '../common/CarDetails';
import Button from '../common/Button';
import CustomScroll from 'react-custom-scroll';
import PageLoader from '../common/PageLoader';
import { validateFormSubmit } from '../../helpers';
import { imageBasePath } from '../../constants/staticData';
var uniqid = require('uniqid');

class Panel1 extends Component {
  constructor(props) {
    super(props);
    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });
    this.state = {
      open: false,
      make: false,
      carList: false,
      filteredCarList: false,
      activeLogo: null,
      makeDropdown: true,
      modelDropdown: false,
      yearDropdown: false,
      filteredCarModelList: false,
      filteredYears: years,
      allYears: years,
      selectedCarMakeKey: '',
      newImagesArray: [],
      form1Active: false,
      carImages: [],
      imageMaxLimitError: false,
      isLoaded: false,
      isMakeandModelLoaded: false,
      submissionError: false,
      showModal: false,
      imageStartIndex: 0,
      uploadImageTypeErr: false,
      uploadImageSizeErr: false,
      activeModel: null,
      manufacturerTabVisible: true,
      modelTabVisible: true,
      otherDetailsTabVisible: false,
      selectError: false,
      carModel: [],
      isButtonLoading: false,
      formValidation: false,
      chassisImages: [], // Images for Chassis Number only
      hasMultipleSubCategories: false,
      yearListDD: false,
      showSubCategory: false,
      hideForm: false,
      hideForm1: true,
      hideForm2: true,
      hideForm3: true,
      hideCarDetails: true,
      open: false,
      isButtonLoading: false,
      "selectedYear": "Year",
      "subServiceName": "Select Service",
      "allYears": years,
      "allYearsList": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      carDetailsvalueChanged: false
    };
    this.initialFormData = {
      'make': '',
      'model': '',
      'name': '',
      'year': '',
      'plate_no': '',
      'mileage': '',
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      'name': false,
      'plate_no': false,
      'mileage': false,
      'carDetails': false
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.imageThumbnails = this.imageThumbnails.bind(this);
    this.manufactureSelected = this.manufactureSelected.bind(this);
    this.yearSelected = this.yearSelected.bind(this);
    this.modelSelected = this.modelSelected.bind(this);
    this.clearCarDetails = this.clearCarDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;
      this.setState({
        carList: nextProps.carProfileReducer.carMakeAndModels,
        filteredCarList: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        carModel: carModel,
        filteredCarModelList: carModel
      });
    }
    if (nextProps.tabOpen == "panel1") {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }

    if (nextProps.carProfileReducer.newCarProfileData.images) {

      let imageArray = [...this.state.carImages];

      map(nextProps.carProfileReducer.newCarProfileData.images, (img) => {
        let imageIndex = findIndex(imageArray, (localImg) => {
          return (localImg.localUniqueId && img.localUniqueId && localImg.localUniqueId == img.localUniqueId) || (localImg.id == img.id);
        });
        if (imageIndex != -1) {
          imageArray[imageIndex] = img;
        } else if (img.id) {
          imageArray.push(img);
        }
      });
      if (nextProps.carProfileReducer && nextProps.carProfileReducer.deletedImageId) {
        remove(imageArray, function (f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        carImages: imageArray,
      });
    }

    // Load ChassisNumber Images on Receiving Props
    /*
      if next props has the images of chassis Number put them
      as the default value of chassisImages in the state
    */
    if (nextProps.carProfileReducer.newCarProfileData.CSNimages) {
      let imageArray = [...this.state.chassisImages];
      map(nextProps.carProfileReducer.newCarProfileData.CSNimages, img => {
        let imageIndex = findIndex(imageArray, localImg => {
          return (
            (localImg.localUniqueId &&
              img.localUniqueId &&
              localImg.localUniqueId == img.localUniqueId) ||
            localImg.id == img.id
          );
        });
        if (imageIndex != -1) {
          imageArray[imageIndex] = img;
        } else if (img.id) {
          imageArray.push(img);
        }
      });
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(imageArray, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        chassisImages: imageArray
      });
    }
  }

  activeLogo(name) {
    this.setState({ activeLogo: name });
    this.formData['make'] = name;
  }

  activeModel(name, type) {
    this.setState({ activeModel: name });
    //this.formData['model'] = name;
    this.formData[type] = name;
  }


  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
      this.props && this.props.panel1Valid(false);
    }
    //this.errors['registrationnumber'] = (this.formData['insuranceprovider'].length || this.formData['insurancepolicynumber'].length || this.formData['state'].length) && !this.formData['registrationnumber'].length ? true : false;
    //this.setState({ isLoaded: this.state.isLoaded });
  }


  closeModal() {
    this.setState({ showModal: false });
  }

  onSubmit() {
    let formData = {
      ...this.formData
    };
    let errors = this.errors;
    errors && !errors.registrationnumber && delete errors.registrationnumber;
    const validatedForm = validateFormSubmit(formData, errors);
    this.errors = validatedForm.errors;
    if (validatedForm.validForm) {
      this.setState({
        submissionError: false,
        formValidation: true,
      });
      this.props && this.props.getCarProfileData(this.formData);
      this.props && this.props.panel1Valid(true);
      this.props.toggleNextButton('panel2', true);
    } else {
      this.setState({ submissionError: true });
      return;
    }
  }

  imageThumbnails(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          {img.isUploaded ? (
            <span
              className="cancel-image"
              onClick={e => {
                e.preventDefault();
                this.props.actions.deleteVehicleImage(
                  index,
                  img.id,
                  imageArray,
                  this.props.routeParams.id
                );
              }}
            >
              <i className="mdi mdi-close" />
            </span>
          ) : (
            ""
          )}
          <img
            src={
              img.isFailed
                ? `${imageBasePath}/images/warning.png`
                : img.thumbnail
            }
            onClick={() => {
              this.setState({ showModal: true, imageStartIndex: index });
            }}
          />
          {!img.isUploaded ? (
            <div>
              <img
                className="img-loader-on-upload"
                src={`${imageBasePath}/images/loader_white.gif`}
                alt="Upload in progress"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }

  uploadImageError(value) {
    alert(value);
    this.props.actions.uploadCarProfilePictureTest(value, this.props.carProfileReducer.newCarProfileData.batch_id, '', false);
  }

  // The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number
  chassisFileUpload(e) {
    console.log('What are the files here target ', e.target.files);
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    let that = this;
    console.log('What is the props in here at ProfileSteps =---> ',this.props);
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.carProfileReducer.newCarProfileData.batch_id,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  console.log("What ssssss ", that.props.carProfileReducer);
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.carProfileReducer.newCarProfileData.batch_id,
                    imageId,
                    true,
                    'CSN'
                  );
                  /* if (
                    that.props.carProfileReducer.newCarProfileData.images &&
                    that.props.carProfileReducer.newCarProfileData.images
                      .length &&
                    that.props.carProfileReducer.newCarProfileData.images[0] &&
                    that.props.carProfileReducer.newCarProfileData.images[0]
                      .id == data.imageId &&
                    that.props.routeParams &&
                    that.props.routeParams.id
                  ) {
                    that.props.actions.setVehicleCoverImage(
                      data.imageId,
                      that.props.routeParams.id
                    );
                  }*/
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError(value),
          that.props.routeParams && that.props.routeParams.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });
  }

  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,
      hideCarDetails:false
    });
  }

  manufactureSelected(name,id,model){
    this.formData["make"] = name;
    this.errors["make"] = false;
    this.props.actions.getCarModels(id);
    this.setState({
      selectedCarMakeKey: id
    });
  }

  modelSelected(modelId,modelName){
    this.formData['model_id'] = modelId;
    this.formData['model'] = modelName;
    this.errors["model_id"] = false;
    this.errors["model"] = false;
    this.setState({
      carDetailsvalueChanged: true
    });
  }

  yearSelected(year) {
    this.formData["year"] = year;
    this.errors["carDetails"] = false;
    this.setState({
      carDetailsvalueChanged: true
    });
  }

  clearCarDetails(){
    this.formData["make"] = "";
    this.formData['model_id'] = "";
    this.formData['model'] = "";
    this.formData["year"] = "";
    this.errors["carDetails"] = true;
    this.setState({
      carDetailsvalueChanged: true
    });
  }

  render() {
    const CSNUploadView = this.imageThumbnails(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const carModalList = this.state.isMakeandModelLoaded && this.state.filteredCarModelList;
    const carList = this.state.filteredCarList ? this.state.filteredCarList : this.state.carList;
    let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
       
    return (
      <div className="panel1">
        <Panel collapsible expanded={this.state.open} header={
          <div className="panel-section p-panel" onClick={() => (this.props.selectedTab("panel1", true))}>
            <div className="panel-head" >
              <span className={(this.props.tabOpen == 'panel2' || this.props.tabOpen == 'panel3' || this.props.tabOpen == 'panel4') ? "n-step active" : "n-step"}>1</span>
              <h4>Create Car Profile</h4>
              <i className={this.state.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
            </div>
          </div>
        } >
        <CarDetails carList={carList} carModalList={carModalList} clearCarDetails={this.clearCarDetails} manufactureSelected={this.manufactureSelected} modelSelected={this.modelSelected} yearSelected={this.yearSelected}/>
            
          {this.formData["make"] && this.formData["model"] && this.formData["year"] &&
          <div className="row input-grp">
            <div className="row">
              <div className="col-md-4 noLeftPad m-remove-pad">
                <TextInput
                  customClass=""
                  label="Car Profile Name*"
                  name="name"
                  id="car_profile"
                  type="text"
                  showValidationError={this.errors['name']}
                  value={this.formData.name}
                  validationError="Profile Name cannot be empty"
                  onBlur={this.onFieldBlur.bind(this)}
                />
              </div>
              <div className="col-md-4 m-remove-pad">
                <TextInput
                  customClass=""
                  label="Plate Number*"
                  name="plate_no"
                  id="plate_no"
                  type="text"
                  showValidationError={this.errors['plate_no']}
                  validationError="Plate Number cannot be empty"
                  value={this.formData.plate_no}
                  onBlur={this.onFieldBlur.bind(this)}
                />
              </div>
              <div className="col-md-4 m-remove-pad">
                <TextInput
                  customClass=""
                  label="Kms Travelled*"
                  name="mileage"
                  id="mileage"
                  type="number"
                  showValidationError={this.errors['mileage']}
                  validationError="Kms Travelled cannot be empty"
                  value={this.formData.mileage}
                  onBlur={this.onFieldBlur.bind(this)}
                />
              </div>
            </div>
          </div>}
          <div className="row car-profile">
            <div className="col-md-6 padLeft0">
              <TextInput
                label="Chassis Number"
                name="chassis_number"
                id="chassis_number"
                type="text"
                customClass="noMargin"
                value={this.formData.chassis_number}
                onBlur={this.onFieldBlur.bind(this)} />
              <label className="mobChassis">
                The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
              </label>
            </div>
              {/* <div className="col-md-6 padLeft0">
                <div className="or-text-vert-job">
                <span className="or-text-vert-span">OR</span>
                  <div>
                    <h4 className="panel-sub-title">Upload images</h4>
                    <div className="img-uploads">
                        {this.state.chassisImages.length < 5 ? (
                          <Upload
                            margin={false}
                            id="CSNProfileUpload"
                            fileUpload={e => this.chassisFileUpload(e)}
                          />
                        ) : (
                          <div />
                        )}
                        {CSNUploadView}
                      </div>
                    <div className="mobChassisFileSizeJob">
                      The file has to be below 25MB. Please use JPG or PNG
                    </div>
                    <span className={this.state.uploadImageTypeErr ? "image-upload-error " : "image-upload-error  hide"}>
                      <p>Sorry, only JPG or PNG is accepted.
                                              Try again with image file.</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageTypeErr: false })} />
                    </span>
                    <span className={this.state.uploadImageSizeErr ? "image-upload-error " : "image-upload-error  hide"}>
                      <p>Sorry, uploaded image exceeds the file size limit of 25mb.
                                              Try again with another image.</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageSizeErr: false })} />
                    </span>
                    <span className={this.state.imageMaxLimitError ? "image-upload-error " : "image-upload-error  hide"}>
                      <p>Maximum allowed limit is 5.</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ imageMaxLimitError: false })} />
                    </span>
                  </div>
                </div>
              </div>*/}
            </div>
          {this.formData["make"] && this.formData["model"] && this.formData["year"] && <Button dataLabelledby="Create Car Profile Next" className="panel-next" btnType="red" btnSize="sm" btnCallBack={() => { this.onSubmit() }} fontSize={14} label="Next" />}
        </Panel>
        {this.props.carProfileReducer.newCarProfileData.images && this.props.carProfileReducer.newCarProfileData.images.length ?
          <LightBox
            showUploadIcon={this.state.carImages.length < 5}
            showSetAsCover={this.props.isEditProfile}
            actions={this.props.actions}
            images={this.state.carImages}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.fileNameUpload}
            setAsCover={this.setAsCover}
            vehicleId={this.props.routeParams.id}
          />
          : ''}
      </div>
    );
  }
}

export default Panel1;
