import React, {Component}  from 'react';
import SpriteMaterial from '../common/SpriteMaterial';


export default class ExpandCollapse extends Component {
    constructor(props) {
      super(props);
      this.state={
          openItem: false
      }
     
    }

    componentDidMount(){
        const { initialFlag } = this.props;
       if (initialFlag){
           this.setState({openItem: initialFlag})
       }
    }

render(){
    return(
        <div className="faq-individual">
        <div className="faq-title" onClick={()=>{this.setState({openItem:!this.state.openItem})}}> 
        <span className="faq-icon"> 
        {/*<i className= {this.state.openItem ? "mdi mdi-minus":"mdi mdi-plus"} />*/}
        {this.state.openItem ?
            <SpriteMaterial posx={90} posy={0} width={18} height={22} widthBack={18} classNameVal='faq-plus-minus'/>:
            <SpriteMaterial posx={359} posy={0} width={18} height={22} widthBack={18} classNameVal='faq-plus-minus'/>

           }
        </span>
        <div>{this.props.title}</div></div>
       
        {this.state.openItem && <div className="faq-description" >{this.props.description.split('\n').map(c => {
                  return ( <div> {c} </div>) 
                   })} </div>}
        </div>
    )
}
}



