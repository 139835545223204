import * as types from './actionTypes';
import * as API_END_POINTS from '../constants/api.js';
import axios from 'axios';
import queryString from 'query-string';
import {appBasePath, baseLandingUrl} from '../constants/staticData';
import Cookies from 'js-cookie'

export function signInUser(signInData, dispatch, fromSignup) {
  
  axios
    .post(API_END_POINTS.SIGNIN, JSON.stringify(signInData), {
      headers: {
        Accept: 'application/json,',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
    .then(response => {
      
      if (response.status === 200) {
        let responseData = response.data.user;
        responseData['token'] = response.data.token;
        if (responseData.type === 'business') {
          localStorage.setItem('garage_id', responseData.garageId);
          localStorage.setItem('businessAccessToken', responseData.token);
          localStorage.setItem('businessAuthData', JSON.stringify(responseData));
          localStorage.setItem('businessUserId', JSON.stringify(responseData.id));
          localStorage.setItem('flow', signInData.flow? signInData.flow: "");
        }
        else {
          localStorage.setItem('accessToken', responseData.token);
          localStorage.setItem('authData', JSON.stringify(responseData));
          localStorage.setItem('userId', JSON.stringify(responseData.id));
          localStorage.setItem('flow', signInData.flow? signInData.flow : "");
          localStorage.setItem('subtype', signInData.subtype? signInData.subtype : "");
        }
        if (responseData.token) {
          axios.defaults.headers.common['x-access-token'] = responseData.token;
        }
        if (responseData.phone && !responseData.phoneVerified) {
          
          dispatch({
            type: types.SHOW_VERIFY_OTP_PAGE,
            fromSignIn: fromSignup ? false : true,
            authData: responseData
          });
        } else {
          if (responseData.type === 'business') {
            
            window.location.pathname = '/business/overview';
          } else {
            dispatch({
              type: types.SHOW_DASHBOARD,
              authData: responseData
            });
          }
        }
      } else {
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage: 'Unknown error occurred please try again'
        });
      }
    })
    .catch(err => {
      if (err.config) {
        if (err && err.response && err.response.status) {
          if (err.response.status === 401) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Email or Password is incorrect. Please try again.'
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          }
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      }
    });
}

export function guestUserSignUp(payload) {
  return dispatch => {
    axios
      .post(API_END_POINTS.GUEST_USER, JSON.stringify(payload), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(response => {
        let responseData = response.data.user;
        responseData['token'] = response.data.token;
        localStorage.setItem('accessToken', responseData.token);
        localStorage.setItem('authData', JSON.stringify(responseData));
        localStorage.setItem('userId', JSON.stringify(responseData.id));
        localStorage.setItem('walkthroughCompleted', true);
        localStorage.setItem('flow', payload.flow? payload.flow : "");
        localStorage.setItem('subtype', "guestuser");

        if (responseData.token) {
          axios.defaults.headers.common['x-access-token'] = responseData.token;
        }
        if (!responseData.phone) {
          dispatch({
            type: types.SHOW_SEND_OTP_PAGE,
            authData: responseData
          });
        } else {
          dispatch({
            type: types.SHOW_VERIFY_OTP_PAGE,
            fromSignIn: true,
            authData: responseData
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {

            let errorMessage = "Unknown error occurred please try again";

            if(err.response.data.message){
              errorMessage = err.response.data.message;
              let plateError = errorMessage.search("plate_no");
              if(plateError !== -1){
                errorMessage = "Plate Number "+payload.plate_no+" already exists";
              }

            }

            dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              toastMessage: errorMessage,
              toastType: 'error'
            });
          }
        }
      });
  };
}

export function quickBookingUserSignUp(payload, router) {
  return dispatch => {
    axios
      .post(API_END_POINTS.CREATE_QUICKBOOKING_REQUEST, JSON.stringify(payload), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(response => {
        let responseData = response.data.user;
        responseData.subtype = payload.subtype;

        responseData['token'] = response.data.token;
        localStorage.setItem('accessToken', responseData.token);
        localStorage.setItem('authData', JSON.stringify(responseData));
        localStorage.setItem('userId', JSON.stringify(responseData.id));
        localStorage.setItem('walkthroughCompleted', true);
        localStorage.setItem('flow', payload.flow? payload.flow : "");
        localStorage.setItem('hideFirstStep', payload.hideFirstStep? true : false);
        localStorage.setItem('serviceId', payload.serviceid? payload.serviceid : "");
        localStorage.setItem('bookingRequestId',response.data.userbookingrequest.id);


        if (responseData.token) {
          axios.defaults.headers.common['x-access-token'] = responseData.token;
        }
        if (!responseData.phone) {
          dispatch({
            type: types.SHOW_SEND_OTP_PAGE,
            authData: responseData
          });
        } else {
         /* dispatch({
            type: types.SHOW_VERIFY_OTP_PAGE,
            fromSignIn: true,
            authData: responseData
          });*/

          dispatch({
            type: types.SHOW_VERIFY_OTP_PAGE,
            authData: responseData
          });
          router.push(appBasePath + '/verify-otp');
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {

            let errorMessage = "Unknown error occurred please try again";

            if(err.response.data.message){
              errorMessage = err.response.data.message;
              let plateError = errorMessage.search("plate_no");
              if(plateError !== -1){
                errorMessage = "Plate Number "+payload.plate_no+" already exists";
              }

            }

            dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              toastMessage: errorMessage,
              toastType: 'error'
            });
          }
        }
      });
  };
}

export function signInAction(signInData, dispatch, fromSignup) {
  if (fromSignup) {
    signInData.usertype = 'customer';
    signInUser(signInData, dispatch, fromSignup);
  } else {
    return dispatch => {
      signInUser(signInData, dispatch);
    };
  }
}

export function socialAuth(accessToken, provider,flow) {
  return dispatch => {
    const authPostData = {
      provider: provider,
      type: 'customer',
      userType: 'customer',
      accessToken: accessToken
    };
    axios
      .post(API_END_POINTS.SOCIAL_AUTH, JSON.stringify(authPostData), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status === 200) {
          let responseData = response.data.user;
          responseData['token'] = response.data.token;
          if (responseData.type === 'business') {
            localStorage.setItem('garage_id', responseData.garageId);
          }
          localStorage.setItem('accessToken', responseData.token);
          localStorage.setItem('authData', JSON.stringify(responseData));
          localStorage.setItem('userId', JSON.stringify(responseData.id));
          localStorage.setItem('flow', flow? flow : "");
          if (responseData.token) {
            axios.defaults.headers.common['x-access-token'] = responseData.token;
          }
          if (responseData.phone && !responseData.phoneVerified) {
            dispatch({
              type: types.SHOW_VERIFY_OTP_PAGE,
              authData: responseData
            });
          } else {
            if (responseData.type === 'business') {
              window.location.pathname = '/business/overview';
            } else {
              dispatch({
                type: responseData.phone && responseData.phoneVerified
                  ? responseData.isFirstTimeLoggedin ? types.SHOW_WELCOME_PAGE : types.SHOW_DASHBOARD
                  : types.SHOW_SEND_OTP_PAGE,
                authData: responseData
              });
            }
          }
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unable to authenticate using ' + provider + ', please try again'
          });
          dispatch({
            type: types.SAVE_LOG,
            appLog: 'Calling SignIn API ' + API_END_POINTS.SIGNIN + ' Login Success, Response:  ' + response.data
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {
            if (err.response.status == 400 && provider == 'google') {
              const auth2 = window.gapi.auth2.getAuthInstance();
              if (auth2) {
                auth2.disconnect();
              }
            }
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err && err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          }
        }
      });
  };
}

export function showVerifyOTPPage(signUpData) {
  return dispatch => {
    axios
      .post(API_END_POINTS.SIGNUP, queryString.stringify(signUpData), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          signInAction(
            {
              email: signUpData.email,
              password: signUpData.password,
              flow: signUpData.flow,
              subtype: signUpData.subtype
            },
            dispatch,
            true
          );
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Unknown error occurred please try again'
            });
          }
        }
      });
  };
}

export function showWelcomePage(otp, phone, userId) {
  if (userId) {
    return dispatch => {
      axios
        .get(API_END_POINTS.VERIFY_OTP, {
          params: {
            type: 'phone',
            code: otp
          },
          headers: {
            Accept: 'application/json,',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        })
        .then(response => {
          if (response.status === 200) {
            const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : {};
            authData.phoneVerified = true;
            localStorage.setItem('authData', JSON.stringify(authData));
            dispatch({
              type: types.SHOW_WELCOME_PAGE
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Unknown error occurred please try again'
            });
          }
        })
        .catch(err => {
          if (err.config) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          }
        });
    };
  } else {
    return dispatch => {
      dispatch({
        type: types.SHOW_WELCOME_PAGE
      });
    };
  }
}

export function showErrorMessage(error) {
  return dispatch => {
    dispatch({
      type: types.SHOW_ERROR_MESSAGE,
      statusMessage: error
    });
  };
}

export function hideErrorMessage() {
  return dispatch => {
    dispatch({
      type: types.HIDE_ERROR_MESSAGE
    });
  };
}

export function fetchCurrentUserInfo(router) {
  return dispatch => {
    axios
      .get(API_END_POINTS.CURRENT_USER_DETAILS, {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          localStorage.setItem('authData', JSON.stringify(responseData));
          if (responseData.phone) {
            if (!responseData.phoneVerified) {
              dispatch({
                type: types.SHOW_VERIFY_OTP_PAGE,
                authData: responseData
              });
              router.push(appBasePath + '/verify-otp');
            }
          } else {
            dispatch({
              type: types.SHOW_SEND_OTP_PAGE,
              authData: responseData
            });
            router.push(appBasePath + '/send-otp');
          }
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      });
  };
}

export function resendOTP(phoneNumber, userTriggeredAPI) {
  return dispatch => {
    axios
      .post(API_END_POINTS.REQUEST_OTP, JSON.stringify({ phone: phoneNumber }), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          if (userTriggeredAPI) {
            window.alert('OTP has been send to ' + phoneNumber);
          }
          const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
          dispatch({
            type: types.SHOW_VERIFY_OTP_PAGE,
            authData: authData
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: response.data && response.data.message
              ? response.data.message
              : 'Unknown error occurred please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err && err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Unknown error occurred please try again'
          });
        }
      });
  };
}

export function logout(router) {
  return dispatch => {
    axios
      .get(API_END_POINTS.LOGOUT, {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(() => {
        localStorage.clear();

        Cookies.remove('accessToken');
        Cookies.remove('authData');
        Cookies.remove('userId');
        Cookies.remove('flow');
        Cookies.remove('subtype');

        window.location.href = baseLandingUrl+"/sign-in"

      })
      .catch(err => {
        if (err.config) {


          Cookies.remove('accessToken');
          Cookies.remove('authData');
          Cookies.remove('userId');
          Cookies.remove('flow');
          Cookies.remove('subtype');
          localStorage.clear();
          window.location.href = baseLandingUrl+"/sign-in"


        }
      });
  };
}

export function forgotPassword(emailId) {
  return dispatch => {
    axios
      .post(API_END_POINTS.FORGOT_PASSWORD, JSON.stringify({ email: emailId }), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status == 200) {
          dispatch({
            type: types.SHOW_RESET_EMAIL_CONFIRMATION,
            email: emailId
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err.response && err.response.status === 404) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: "User account doesn't exists!"
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          }
        }
      });
  };
}

export function resetPassword(verificationCode, password) {
  return dispatch => {
    axios
      .post(API_END_POINTS.RESET_PASSWORD, JSON.stringify({ code: verificationCode, password: password }), {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status == 200) {
          dispatch({
            type: types.RESET_PASSWORD_CODE_VERIFIED
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Unknown error occurred please try again'
            });
          }
        }
      });
  };
}

export function verifyEmail(code) {
  return dispatch => {
    axios
      .get(API_END_POINTS.VERIFY_EMAIL, {
        params: {
          type: 'email',
          code: code
        },
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: types.EMAIL_VERIFIED
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: response.status === 401 || response.status === 410
              ? 'Wrong verification code'
              : 'Unable to verify email id, please try again'
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err && err.response && err.response.status) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Unknown error occurred please try again'
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: 'Unknown error occurred please try again'
            });
          }
        }
      });
  };
}

export function clearComponentKey() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_COMPONENT_KEY
    });
  };
}

export function contactUs(ContactInfo) {
  return dispatch => {
    axios
      .post(API_END_POINTS.CONTACT_US, ContactInfo, {
        headers: {
          Accept: 'application/json,',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status == 200) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Your message has been successfully sent. We will contact you very soon!'
          });
        }
      })
      .catch(err => {
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage: err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Error in sending message. Please try again.'
        });
      });
  };
}

export function getAccountStatus() {
  return dispatch => {
    axios.get(API_END_POINTS.GET_ACCOUNT_STATUS).then(response => {
      if (response.status === 200) {
        localStorage.setItem('authData', JSON.stringify(response.data));
        dispatch({
          type: types.GET_ACCOUNT_STATUS,
          authData: response.data
        });
      }
    });
  };
}
