import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';

class FaqCollpasePanel extends Component {
  constructor(props) {
    super(props);
        this.state = {
            open: this.props.faq.id == 1? true: false
        }


      this.createMarkup = this.createMarkup.bind(this);

    }
    createMarkup(description) {
      return {__html: description};
    }
    render() {



      const { faq } = this.props;

        return (

          <Panel collapsible expanded={this.state.open} onSelect={() => this.setState({ open: !this.state.open })} header={
            <span>
                   <i className={this.state.open ? "mdi mdi-minus" : "mdi mdi-plus"} />
                    <span>{faq.title}</span>
                </span>
          }>

            <form>
              <div key={faq.id}  dangerouslySetInnerHTML={this.createMarkup( faq.description)}/>

            </form>
          </Panel>
        );
    }
}

export default FaqCollpasePanel;
