import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';


export default class SpriteMaterial extends Component {
    constructor(props){
        super(props);
        
    }
    

    render(){   
        const {widthBack,posx, posy,width,height, classNameVal,onClick,ariahidden} = this.props;
        return(
          <div style={{width:`${width}`}} className={"sprite-image "  + classNameVal} onClick={()=> {onClick}}  >
            <div style={{'background-image': `url(${imageBasePath}/images/landing/SmallIcons-Sprite.svg)`,
              'background-position': `${posx}px ${posy}px`,
              'width': `${widthBack}`,
              'height': `${height}`,'background-size': 'cover'}} aria-hidden={ariahidden}></div>  
          </div>
        );
    }
}