import React, { Component } from 'react';
import map  from 'lodash/map';
import Header from '../common/Header';
import Footer from '../Landing/Footer';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import LoginHeader from '../common/LoginHeader';
import { appBasePath } from '../../constants/staticData';

export default class Policy extends Component {
    constructor(props, context) {
        super(props, context);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.state = {
            notificationVisible: false,
            messageVisible: false
        };
    }
    toggleNotification(isVisible) {
        this.setState({ notificationVisible: isVisible });
    }
    toggleMessage(isVisible) {
        this.setState({ messageVisible: isVisible });
    }

    render() {
        const { commonReducer, router, actions,bookingRequestReducer } = this.props;
        return (
            <div className="contact-body">
                {localStorage.getItem('authData')
                    ? <Header
                          hideOption={() => this.setState({ searchMobile: true })}
                          bookingRequestReducer = {bookingRequestReducer}
                          commonReducer={commonReducer}
                          notificationCount={2}
                          profileName="Derrick Frank"
                          notificationCallBack={this.toggleNotification}
                          messageCallBack={this.toggleMessage.bind(this)}
                          router={router}
                          actions={actions}
                          authReducer={this.props.authReducer}
                      />
                    : <LoginHeader
                          hideOption={() => this.setState({ searchMobile: true })}
                          pageType="signIn"
                          headerClickAction={e => {
                              e.preventDefault();
                              router.push(`${appBasePath}/sign-in`);
                          }}
                      />}
                {/* <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} /> */}
                <div className="banner-top"></div>
                <div className="contact-us">
                    <div className="row">
                        <div className="col-md-12">

                        <div className="n-holder">
                            <div className="inSection">

                        <div className="padwrapper">
                            <div className="myCar-list">
                                <div className="myCar-body row">
                                    <div className="desc-holder">
                                        <div className="page-sec-header">
                                            <h4>Carcility Privacy Policy</h4>
                                        </div>

                                        <p className="terms-content">
                                          Carcility Technologies FZE owns and maintains the website https://www.carcility.com/. The CARCILITY privacy policy (the “Privacy Policy”) is intended to inform you of our policies and practices regarding the collection, use and disclosure of Personal Information and other information you submit to us through CARCILITY. By accessing or otherwise using the CARCILITY websites, e-mails or other services (collectively “CARCILITY”), you agree to the terms and conditions of this Privacy Policy (which is incorporated into and forms a part of the CARCILITY Terms & Conditions and consent to the processing of your information according to the Privacy Policy.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1. Collection of Information
                                        </h5>
                                        <p>We collect the following types of information: <br/></p>
                                        <h5 className="terms-heading spl-text">
                                           1.1 Personal Information that You Provide
                                        </h5>
                                        <p className="terms-content">
                                            In general, we collect Personal Information that you submit to us voluntarily through CARCILITY. We also collect information that you submit in the process of creating or editing your account and user profile on CARCILITY. For example, our registration and login process requires you to provide us with your name, valid e-mail address and password of your choice. Use of some of our services may require you to save a delivery or shipping address if required. When you personalize your profile and use the features of CARCILITY, we will collect any information you voluntarily provide, and we may also request optional information to support your use of CARCILITY, such as your year of birth, gender and other demographic information. We collect information in the form of the content that you submit during your use of CARCILITY, such as photos, reviews, ratings and other information you choose to submit. For instance, we may collect your user name or profile information that you have permitted to be displayed through CARCILITY in order to display you as a friend or in association with your profile and connections.
                                        </p>
                                        <p className="terms-content">
                                            When you sign up for, or win, any contests, or other activities that we make available on CARCILITY, we will collect the information designated along with such activity, which may include contact information such as your name, address, and phone number.
                                            If you choose to sign up to receive information/newsletters about products or services that may be of interest to you, we will collect your e-mail address and other related information. Additionally, we collect any information that you voluntarily enter, including Personal Information, into any postings, comments or forums within the CARCILITY community.
                                        </p>
                                        <p className="terms-content">
                                          All credit/debit cards’ details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties. We will not pass any debit/credit card details to third parties.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1.2 Personal information from other sources
                                        </h5>
                                        <p className="terms-content">
                                            We may receive Personal information about you from other sources with which you have registered or interacted, companies who we have partnered with (collectively, ‘Partners’) or other third parties. We may associate this information with the other personal information we have collected about you.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1.3 E-mail and E-mail Addresses
                                        </h5>
                                        <p className="terms-content">
                                            We may receive Personal Information about you from other sources with which you have registered or interacted, companies who we have partnered with (collectively, “Partners”) or other third parties. We may associate this information with the other Personal Information we have collected about you.
If you send an e-mail to us, or fill out our “Feedback” form through CARCILITY, we will collect your e-mail address and the full content of your e-mail, including attached files, and other information you provide. We may use and display your full name and e-mail address when you send an e-mail notification to a friend through CARCILITY or the social network from which you have connected to CARCILITY (such as in an invitation, or when sharing your content). Additionally, we may use your e-mail address to contact you on behalf of your friends (such as when someone sends you a personal message) or to send notifications from a social network or other website with which you have registered to receive such notifications. We may also use your e-mail address to contact you for things such as notifications of limited-edition shop sales and other related information. You may indicate your preference to stop receiving further promotional communications as further detailed below.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1.3 E-mail and E-mail Addresses
                                        </h5>
                                        <p className="terms-content">
                                        (i) you are at least 18 years old and are a legal adult in the jurisdiction in which you reside; and (ii) you have the right, authority and capacity to enter into this Agreement and to abide by the Terms, and that you will so abide. If you enter into this Agreement on behalf of a company or other organization, you represent and warrant that you have authority to act on behalf of that entity and to bind that entity to this Agreement. The Services are not available to anyone under 18 years of age, or to any individual whose account has been terminated. Your account may not be used for Services to be performed for another person. </p>
                                        <h5 className="terms-heading spl-text">
                                           1.4 Information Collected Via Technology
                                        </h5>
                                        <p className="terms-content">
                                        As you use CARCILITY, certain information may be passively collected and stored on our or our service providers’ server logs, including your Internet protocol address, browser type and operating system. We also use Cookies and navigational data like Uniform Resource Locators (“URLs”) to gather information regarding the date and time of your visit and the solutions and information for which you searched and viewed, or on which of the advertisements displayed on CARCILITY you clicked. An Internet protocol address, or “IP Address,” is a number that is automatically assigned to your computer when you use the Internet. In some cases your IP Address stays the same from browser session to browser session; but if you use a consumer internet access provider, your IP Address probably varies from session to session. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the site. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience with CARCILITY. Persistent Cookies can be removed by following your Internet browser help file directions. In order to use our services offered through CARCILITY, your web browser must accept Cookies. If you choose to disable Cookies, some aspects of CARCILITY may not work properly, and you may not be able to receive our services.  </p>

                                        <h5 className="terms-heading spl-text">
                                           1.5 Mobile & Location Information
                                        </h5>
                                        <p className="terms-content">
                                        If you use mobile-enabled services provided by CARCILITY, such as any CARCILITY mobile application made available by us, we may receive information about your use of our services from your mobile device while you use our applications or services. This may also include information about your precise location if you have enabled location-based services on your mobile device. Certain applications or services may also require addition information collection, such as information to be sent when you are not logged into CARCILITY for alerts services, and
the terms of such collection will be identified when you subscribe to or sign up for such services or applications.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1.6 Social Media Information
                                        </h5>
                                        <p className="terms-content">
                                        Certain information you post or provide through CARCILITY for public posting, such as your name, profile, comments, collections and reviews, may be shared with other users and posted on publicly available portions of CARCILITY, including without limitation, social media applications and other public domain in which you choose to participate. Please keep in mind that if you choose to disclose Personal Information when posting comments or other information or content through CARCILITY, this information may become publicly available and may be collected and used by others, including people outside the CARCILITY community. Please also be aware that if you choose to share or tweet deals or your purchases, they will become publicly available on the Facebook and Twitter websites. It is up to you to make sure you are comfortable with the information you choose to provide us and the information you choose to publish. We will not have any obligations with respect to any information that you post to parts of CARCILITY available to others, and recommend that you use caution when giving out personal information to others through social media networks or otherwise. </p>
                                        <p className="terms-content">
                                        You may choose to log in or access CARCILITY using the Facebook Connect feature or by downloading the CARCILITY mobile app through Facebook or another third-party platform. By doing this, you are authorizing that third-party platform to send us certain information from your account with them, and you authorize us to collect, store and use in accordance with this Privacy Policy any and all information available to us through the third-party platform.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           1.7 Feedback Information
                                        </h5>

                                        <p className="terms-content">If you provide feedback to us, we may use and disclose such feedback for any purpose, provided we do not associate such feedback with your Personal Information. We will collect any information contained in such feedback and will treat the Personal Information in it in accordance with this Privacy Policy. You agree that any such comments and any e-mail we receive becomes our property. We may use feedback for marketing purposes or to add to or modify our services without paying any royalties or other compensation to you.  </p>
                                        <h5 className="terms-heading spl-text">
                                           1.8 Use and Disclosure of Information
                                        </h5>
                                        <p className="terms-content">In general, Personal Information and other information you submit or share with us is used for the following purposes by CARCILITY : to enable your access to our services; to provide, maintain, protect, develop and improve CARCILITY ; to fulfill your requests for products and services; to customize the content and advertisements you receive from us and our trusted partners; and to offer you additional information, opportunities, promotions and functionality from us or on our behalf, or from our partners or our advertisers at your request.
Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes the Personal Information that we collect with third parties, unless you ask or authorize us to do so.
We may provide your Personal Information to third-party service providers or affiliates who work on behalf of or with us to provide some of the services and features of CARCILITY and to help us communicate with you. Examples of such services include sending e-mail, analyzing data, providing marketing assistance, voucher management services, processing payments (including
credit card payments) and providing customer service. We limit information provided to the aforementioned affiliate and third party service providers to the extent necessary for them to provide the services and require such service providers to promise not to use such information except as necessary to provide the relevant services to us. We may share some or all of your Personal Information (other than credit card information) with Merchants whose Deals you have purchased for their commercial purposes and to provide the Deal. If you do not want us to use or disclose Personal Information collected about you in the manner identified in this Privacy Policy, you may not use CARCILITY. Certain Deals may expressly require you to submit information for fulfillment at the point of purchase, and Merchants may separately request information from you as necessary to redeem the Voucher you have purchased, or for their own purposes. A merchant’s collection and use of any information shall be governed by that Merchant’s own privacy policy. Additionally, we do share your content preferences and other information with social networks you have connected with CARCILITY, along with those companies and persons you have asked us to share your information with.
We may share some or all of your Personal Information with CARCILITY affiliated and group companies that are under a common control, so that they may send daily deal e-mails to you, in which case we will require them to honor this Privacy Policy.
We use Google Analytics to better understand how visitors interact with our site. This service provides non-personally identifiable data including but not limited to data on where visitors came from, what actions they took on our site and where visitors went when they left our site. In addition, provides us with analytics services related to downloads of our mobile app.
We may also develop non-personal information concerning CARCILITY users, using de-identification processes to help ensure that such information cannot reasonably be re-associated or connected with you as an individual. Generally, we aggregate this information and use it in statistical analysis to help us analyze patterns in the use of CARCILITY. This Privacy Policy does not limit our use or disclosure of de-identified information (i.e., that is not connected with you as a user) in any way, and we reserve the right to use and disclose such information to our partners, advertisers and other third parties in our discretion.
In the event we go through a business transition such as a merger, acquisition by another company or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of ours or that acquirer’s assets may continue to process your Personal Information as set forth in this Privacy Policy.
We may disclose your Personal Information if we believe in good faith that such disclosure is necessary to (a) comply with relevant laws or to respond to subpoenas or warrants served on us; or (b) to protect and defend our rights or property, you, or third parties. You hereby consent to us sharing your Personal Information under the circumstances described herein.  </p>

                                        <p className="terms-content">
                                          We takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, Carcility.com cannot guarantee the security of any information that is disclosed online.
                                        </p>
                                        <p className="terms-content">
                                          Carcility is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.
                                        </p>

                                        <h5 className="terms-heading spl-text">
                                           2. Your Choices Regarding Your Personal Information
                                        </h5>
                                        <p className="terms-content">We offer you choices regarding the collection, use and sharing of your Personal Information. You have a right at any time to stop us from contacting you for marketing purposes. When you receive promotional communications from us, you may indicate a preference to stop receiving further promotional communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the promotional e-mail you receive or by contacting us directly at privacy@carcility.com.
Despite your indicated e-mail marketing preferences, we may send you administrative e-mails regarding CARCILITY, including, for example, administrative and transactional confirmations, and notices of updates to our Privacy Policy if we choose to provide such notices to you in this manner.
If you use CARCILITY services provided through mobile devices, you may have additional choices, including over whether location services are enabled. Additionally, if you use other social media services in connection with CARCILITY, the providers of these services may provide you with additional choices concerning the types of data shared with CARCILITY. </p>

                                        <h5 className="terms-heading spl-text">
                                           3. Third Party Advertising and Sites
                                        </h5>
                                        <p className="terms-content">
                                        We may enable advertisers and ad servers to promote third-party products and/or services by placing advertisements on CARCILITY. These advertisers and ad servers may use Cookies, “Web Beacons” (which are usually small, transparent graphic images) or similar technologies in order to monitor information related to the serving of their advertisements, and may use information gathered over time and across Web sites in order to make the ads you receive more relevant. Clicking on such advertisements will direct you to the website of a third-party and the advertiser.
We also engage in remarketing to market our sites, products and services or those of our trusted advertising partners across the web: when a user visits our site, a Cookie is dropped on the user, and advertising networks may deliver targeted advertisements to users with this Cookie. We do not share any of your Personal Information with our remarketing ad networks or advertisers.
CARCILITY may contain links to other websites or may make third-party applications available to our users. Please be aware that we are not responsible for the privacy practices or the content of such other websites or applications. We encourage our users to read the privacy statements of each and every website they visit or application they use. This Privacy Policy applies solely to information collected by us through CARCILITY and does not apply to these third-party websites or applications. The ability to access information of third parties from CARCILITY, or links to other websites or locations, is for your convenience and does not signify our endorsement of such third parties, their products, their services, other websites, locations or their content.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           4. Protecting your security
                                        </h5>
                                        <p className="terms-content">
                                        We are committed to protecting the security of your Personal Information. We use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use or disclosure. Even though we have taken significant steps to protect your Personal Information, no company, including us, can fully eliminate security risks associated with Personal Information.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           5. Children’s Personal Information
                                        </h5>
                                        <p className="terms-content">
                                        CARCILITY does not direct its websites to children under the age of eighteen. We market families-related products or services for purchase by adults. We require registered users of the site to be at least eighteen years old. Protecting the privacy of children is very important. If we obtain actual knowledge that a registered user is under eighteen, we will promptly delete
information associated with such account. By using CARCILITY, you are representing that you are at least eighteen years old.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           6. Access to Personal Information
                                        </h5>
                                        <p className="terms-content">
                                        You have the right to request a copy of the Personal Information that we hold about you. If you would like a copy of some or all of your Personal Information, please contact us at admin@carcility.com. We want to make sure that your information is accurate and up-to-date. You may ask us to correct or remove information that you think is inaccurate. You may change any of your profile information by editing it in the profile settings page.
                                        </p>
                                        <h5 className="terms-heading spl-text">
                                           7. Enforcement
                                        </h5>
                                        <p className="terms-content">
                                        Personal or other information about you collected on CARCILITY sites and services may be stored and processed in the United Arab Emirates or any other country in which CARCILITY or its affiliates, subsidiaries and services providers maintain facilities. CARCILITY complies with the Commercial Laws of United Arab Emirates regarding the collection, use and retention of information.

                                        </p>
                                         <h5 className="terms-heading spl-text">
                                           8. Changes To This Privacy Policy
                                        </h5>
                                        <p className="terms-content">
                                        This Privacy Policy is subject to occasional revision at our discretion, and if we make any substantial changes in the way we use your Personal Information, we will post an alert on this page or may otherwise post an alert on the CARCILITY website. If you object to any such changes, you must cease using CARCILITY. Continued use of CARCILITY following notice of any such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                                        </p>
                                        <p className="terms-content">
                                          The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the Customers’ are encouraged to frequently visit these sections to be updated about the changes on the website. Modifications will be effective on the day they are posted.
                                        </p>
                                         <h5 className="terms-heading spl-text">
                                           How to Contact Us
                                        </h5>
                                        <p className="terms-content">
                                        We are always pleased to hear from our customers (even if it is a complaint!). We are always grateful for any time you spend providing us with the knowledge we need to ensure our customers are completely satisfied - we want you to return to the site and to recommend us to your friends and family. If you have any questions or feedback about this statement, or if you would like us to stop processing your information, please do not hesitate to contact a customer service member of the CARCILITY team, who will be delighted to answer any questions you may have. Please contact our customer care team at support@carcility.com
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                        </div>

                    </div>
                </div>
                {this.state.searchMobile &&
                    <div>

                        <MobileHeaderSearch
                            mobile={true}
                            actions={this.props.actions}
                            commonReducer={this.props.commonReducer}
                            mobSearchClick={() => this.setState({ searchMobile: false })}
                        />
                    </div>}
                    <Footer {...this.props} />
        </div>
        );
    }
}
