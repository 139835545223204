import React, { Component } from 'react';
import {  MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap';
import map  from 'lodash/map';
import size  from 'lodash/size';
import PickLocationModal from '../common/PickLocationModal';
import SaveLocationModal from '../common/SaveLocationModal';
import EditLocationModal from '../common/EditLocationModal';
import Autocomplete from '../../../node_modules/react-autocomplete/build/lib/Autocomplete.js';


export default class LocationSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: false,
      searchDropdown: false,
      location: undefined,
      searchTerm: props.defaultSearchTerm ? props.defaultSearchTerm : undefined,
      autoCompleteData: [],
      editLocationModal: undefined,
      pickLocationModal: undefined,
      saveLocationModal: undefined,
      locationData: [],
    };
    this.autocompleteLocation = this.autocompleteLocation.bind(this);
  }
  componentDidMount() {
    if (!(this.props && this.props.commonReducer && this.props.commonReducer.userLocations && size(this.props.commonReducer.userLocations) > 0) && localStorage.getItem("authData")) {
      this.props && this.props.actions && this.props.actions.getUserLocations();
    }
    document.body.addEventListener('click', this.hideLocationPopup.bind(this));
  }
  componetWillMount() {
    if (this.props.fromSearchBar && this.props.commonReducer && this.props.commonReducer.userLocation && this.props.commonReducer.userLocation.label) {
      this.setState({
        'searchTerm': this.props.commonReducer.userLocation.label
      });
    }
  }


  componetWillUnmount() {
    document.body.removeEventListener();
  }

  hideLocationPopup(e) {
    if (e.target.className.indexOf('location_pop_up') == -1) {
      if (this.state.showLocationDropdown) {
        this.setState({
          showLocationDropdown: false
        });
      }
    }
  }

  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { 'lat': Number(location.lat), 'lng': Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          this.setState({ 'userLocation': results[0], 'searchTerm': searchTerm || location.description || results[0].formatted_address, 'showLocationDropdown': false });
          that.props.selectedLocation({
            'lat': results[0].geometry.location.lat(),
            'lng': results[0].geometry.location.lng(),
            'formatted_address': results[0].formatted_address,
            'label': searchTerm || location.description || results[0].formatted_address
          });
        } else {
          this.setState({ 'showLocationDropdown': false });
        }
      });
    }
  }

  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        'searchTerm': searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ 'input': searchTerm }, function (res) {
        that.setState({
          'autoCompleteData': res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        'searchTerm': searchTerm,
        'autoCompleteData': []
      });
    }
  }
  searchedLocationView(location, style, fromGuestFlow) {
    return (
      <MenuItem eventKey={location.id} className="location_pop_up" onClick={(e) => { e.preventDefault(); this.setUserLocation(location) }}>
        <span className="n-serached-location">
          <div>
            <span className="inline-disp location_pop_up trim-text">
              <i className="mdi mdi-map-marker" />
              <span className="auto-highlight">
                {location.description != this.state.searchTerm && <span >
                  <span style={style.textNormal}>{location.description.substring(0, location.description.toLocaleLowerCase().indexOf(this.state.searchTerm))}</span>
                  <span style={style.textBold}>{location.description.substring(location.description.toLocaleLowerCase().indexOf(this.state.searchTerm), location.description.toLocaleLowerCase().indexOf(this.state.searchTerm) + this.state.searchTerm.length)}</span>
                  <span style={style.textNormal}>{location.description.substring(location.description.toLocaleLowerCase().indexOf(this.state.searchTerm) + this.state.searchTerm.length)}</span>
                </span>}</span>
              {location.description == this.state.searchTerm && <span>
                <span style={style.textBold}>{location.description}</span>
              </span>}
            </span>
            {!fromGuestFlow && <span className="saveLocation inline-disp location_pop_up"
              onClick={(e) => {
                e.preventDefault();
                let geocoder = new google.maps.Geocoder();
                if (location.place_id) {
                  const that = this;
                  geocoder.geocode({ placeId: location.place_id }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK && results && results.length) {
                      // that.props.saveLocationCallBack();
                      that.setState({
                        'saveLocationData': {
                          'lat': results[0].geometry.location.lat(),
                          'lng': results[0].geometry.location.lng(),
                          'formatted_address': results[0].formatted_address
                        }, 'saveLocationModal': true
                      });
                    }
                  });
                }
              }}>
              <a>Save</a></span>}
          </div>
        </span>
      </MenuItem>
    )
  }
  savedLocationView(location) {
    return (
      <MenuItem eventKey={location.id} className="location_pop_up" onClick={(e) => { if (!(e.target.className.indexOf('editLocation') > -1)) { this.setState({ 'searchTerm': location.label, showLocationDropdown: false }); this.props.selectedLocation({ 'lat': location.lat, 'lng': location.lng, 'label': location.label, 'id': location.id, 'formatted_address': location.location }); } }}>
        <span>
          <div className="location_pop_up">
            <label className="location-heading">
              <i className="mdi mdi-pin" />
              <span>{location.label}</span>
            </label>
            <span>
              <span className="small-text">{location.formatted_address}</span>
              <span className="editLocation inline-disp location_pop_up"
                onClick={(e) => { e.preventDefault(); this.setState({ 'editLocationModal': true, 'editLocationData': location }) }}>
                <a className="  ">Edit</a></span>
            </span>
          </div>
        </span>
      </MenuItem>
    );
  }

  editLocation(locationData) {
    this.props.actions.editUserLocation(locationData);
  }

  deleteLocation(locationId) {
    this.props.actions.deleteUserLocation(locationId);
  }

  render() {
    const { commonReducer, style, fromGuestFlow } = this.props;
    let locationData = [{ "textPlaceholder": "Current Location" }]
    map(this.state.autoCompleteData, (location, key) => {
      return (
        locationData.push({ "key": key, "description": location.description, 'place_id': location.place_id })
      );
    });
    map(commonReducer && commonReducer.userLocations, (location, key) => {
      return (
        locationData.push({ "key": key, 'lat': location.lat, 'lng': location.lng, 'label': location.label, 'id': location.id, 'formatted_address': location.location })
      )
    })
    const tooltip = (
      <Tooltip id={"tooltip__search__hide"} >
        <span>{this.state.searchTerm}</span>
      </Tooltip>
    );

    return (
      <div className="searchLeft">
        <OverlayTrigger placement="bottom" overlay={tooltip} >
          <div className="tooltip__custom">
            <Autocomplete
              getItemValue={(item) => item.description ? item.description : item.label ? item.label : item.textPlaceholder}
              items={locationData}
              renderItem={(location, isHighlighted) =>
                <div>
                  <div className={isHighlighted ? "search-menu highlight" : "search-menu"} >
                    <span>
                      {location.textPlaceholder &&
                        <label className="my-location location_pop_up"
                          style={{ pointerEvents: 'auto' }}
                          onClick={() => {
                            this.setState({ 'searchDropdown': false });
                            if (navigator.geolocation) {
                              const that = this;
                              navigator.geolocation.getCurrentPosition(function (position) {
                                that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                              }, function (error) {
                                switch (error.code) {
                                  case error.PERMISSION_DENIED:
                                    that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                                    break;
                                  case error.POSITION_UNAVAILABLE:
                                    that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                                    break;
                                  case error.TIMEOUT:
                                    that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                                    break;
                                  case error.UNKNOWN_ERROR:
                                    that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                                    break;
                                }
                              },{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000});
                            } else {
                              this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
                            }
                          }}>
                          <i className="mdi mdi-crosshairs-gps" />
                          <span>{location.textPlaceholder}</span>
                        </label>}
                      {location.description && this.searchedLocationView(location, style, fromGuestFlow)}
                      {location.label && !fromGuestFlow && this.savedLocationView(location, style)}
                    </span>
                  </div>
                </div>
              }
              open={this.state.searchDropdown}
              value={this.state.searchTerm}
              onChange={(e) => { this.autocompleteLocation(e) }}
              onSelect={(val, item) => { if (val != "Current Location") { this.setUserLocation(item, val) } }}
              onMenuVisibilityChange={(e) => this.setState({ searchDropdown: e })}
              menuStyle={style.dropdownLeftCss}
              inputProps={{ placeholder: this.props.placeholder ? this.props.placeholder : "Locate Me" }}
            />
            <i onClick={() => this.setState({ searchDropdown: !this.state.searchDropdown })} className={this.state.searchDropdown ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
          </div>
        </OverlayTrigger>

        {!this.props.placeholder && <i className={this.state.currentLocation && !this.state.searchTerm == "" ? "main mdi mdi-crosshairs-gps active" : "main mdi mdi-crosshairs-gps "} />}
        {this.state.saveLocationModal && <SaveLocationModal
          saveLocationData={this.state.saveLocationData}
          saveLocationCallBack={(saveLocationData) => { this.setState({ 'saveLocationModal': false }); this.props.actions.addUserLocation(saveLocationData) }}
          hideModal={() => { this.setState({ 'saveLocationModal': false }); }}
          title="save location" />}
        {this.state.editLocationModal && <EditLocationModal
          title="Edit Location"
          editLocationData={this.state.editLocationData}
          editLocationCallBack={(editLocationData) => { this.setState({ 'editLocationModal': false }); this.editLocation(editLocationData) }}
          deleteLocationCallBack={(locationId) => { this.setState({ 'editLocationModal': false }); this.deleteLocation(locationId) }}
          hideModal={() => { this.setState({ editLocationModal: false }) }} />}
        {this.state.pickLocationModal && <PickLocationModal
          pickLocationCallBack={(selectedLocationData) => { this.setState({ 'searchTerm': selectedLocationData.formatted_address, 'pickLocationModal': false, 'userLocation': selectedLocationData, 'selectedLocation': selectedLocationData }); this.props.selectedLocation(selectedLocationData) }}
          hideModal={() => { this.setState({ 'pickLocationModal': false }) }}
          title="Mark your location"
          saveText="Select Location" />}
      </div>
    );
  }
}

