import React, { Component } from 'react';
import Button from '../common/Button';
import { Media } from 'react-bootstrap';
import round from 'lodash/round';
import { imageBasePath, appBasePath } from '../../constants/staticData';

class QuotesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavouriteVisible: false,
      showFullVendorName: false
    };
  }
  render() {
    const { index, vendorName, rating, distance, reviews, phoneNo, vendorImage, vendorId, isFavourite } = this.props;
   // let vendorImagez = false;
    return (
      <div className={this.props.activeClass == "active" ? "jobcard box search-result active" : "jobcard box search-result"} onMouseMove={this.props.ClickedQuoteCard} ref={index}>
        <div className="box-content">
          <Media onClick={() => {
            this.props.headingClickCallback();
          }}
           onMouseOver={() => {


             this.setState({
               showFullVendorName: this.state.showFullVendorName? false: true
             })


          }}

           onMouseOut={() => {


             this.setState({
               showFullVendorName: this.state.showFullVendorName? false: true
             })


          }}

          >
            <Media.Left className="search-result-pointer" onClick={() => {
              this.props.headingClickCallback();
            }}>
              <div className="vendor-brand-holder">
                <div className="vendor-brand-image" style={{ backgroundImage: `url("${vendorImage ? vendorImage : `${imageBasePath}/images/placeholder.png`}")` }} />
              </div>
            </Media.Left>
            <Media.Body>

              {this.state.showFullVendorName ?
                <Media.Heading className="search-result-pointer" onClick={() => {
                  this.props.headingClickCallback();
                }}>
                  {index} {vendorName} <span className={isFavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"}
                                             onClick={(e) => this.props.removeFavourite(e, !isFavourite, vendorId)}/>
                </Media.Heading>
                :
                <Media.Heading className="search-result-pointer" onClick={() => {
                  this.props.headingClickCallback();
                }}>
                  {index} {vendorName.length > 24?  vendorName.substring(0,21)+"..." : vendorName } <span className={isFavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"}
                                             onClick={(e) => this.props.removeFavourite(e, !isFavourite, vendorId)}/>
                </Media.Heading>

              }

              <div className="rating rating-left" onClick={() => {
                this.props.headingClickCallback();
              }}>
                <span className={rating > 0 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating > 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />

                <span className="rating-text">{ Math.round( rating * 10 ) / 10} ({reviews} reviews)</span></div>
              <span className="distance" onClick={() => {
                this.props.headingClickCallback();
              }}>{distance && round(distance, 2) ? round(distance, 2) + ' kms' : ''}</span>
            </Media.Body>
          </Media>
          <div className="box-footer search-result-card">
            <div className="footer-container">
              <span className="card-ph-number" style={{ visibility: 'hidden' }}>{phoneNo ? phoneNo : '+971 919 233 470'}</span>
              <Button btnType="transparent" btnSize="sm" fontSize={13} label="Book Service" btnCallBack={() => {
                localStorage.getItem('authData') ? this.props.btnClickFunc() : this.props.router.push(appBasePath + '/guest-user/');
              }} />
              {/*<span className="quotes-cost">120 AED</span>

                            <div className="box-message">
                                <IconNotification iconType="comment-processing-outline" iconLabel="Messages" notifyClass="notification" viewMessaging={this.props.viewMessaging}/>
                            </div>*/}
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default QuotesCard;
