import React, { Component } from 'react';
import map from 'lodash/map';
import imageExists from "image-exists";

export default class Photos extends Component {
    render() {
        let photos = this.props.garageImages && map(this.props.garageImages, (image, index) => {
          let coverImg = '';

          imageExists(image.small, function(exists) {

            if (! exists) {
              if(image.small){
                imageExists(image.medium, function(exists) {

                  if (! exists) {
                    if(image.medium){
                      coverImg = image.large;
                    }

                  }else{
                    coverImg = image.medium;
                  }


                });
              }

            }else{
              coverImg = image.small;
            }
          });

          if (index <= 2) {
            return (<li key={index} className="upload-images" onClick={() => this.props.openModal(index)}>
              <img src={coverImg} alt="" />
            </li>);
          } else if (index == 3) {
            if (this.props.garageImages.length > 4) {
              return (<li key={index} className="upload-images" onClick={() => this.props.openModal(index)}>
                <img src={coverImg} alt="" />
                <label className="more-popup">
                  {'+' + (this.props.garageImages.length - 4) + ' More'}
                </label>
              </li>);
            } else {
              return (<li key={index} className="upload-images" onClick={() => this.props.openModal(index)}>
                <img src={coverImg} alt="" />
              </li>);
            }
          } else {
            return '';
          }
        });
        return (
            <div className="vendor-services">
                <div className="vendor-description">
                    <h4>Photos</h4>
                    <ul className="figure">
                        {photos}
                    </ul>
                </div>
            </div>
        );
    }
}
