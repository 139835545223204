import React, { Component } from 'react';
import Button from '../common/Button';
import moment from 'moment';
import { imageBasePath, appBasePath } from '../../constants/staticData';

class OfferBox extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };
  }
  render() {
    const {router, offer, key}= this.props;


    let days = moment(offer.validTo).diff(moment(new Date()),'days');


    // let url =  offer.services[0].parentId == 1 ? 'car-wash': offer.services[0].parentId == 2 ? 'car-service': 'car-repair';



    return (

        <div className="col-md-3 col-sm-6 col-xs-6 slider-offer" key={key} onClick={() => {  router.push(`${appBasePath}/offers/`+offer.id) }}>
          <div className={offer && offer.discountPercentage &&  offer.discountPercentage == 100 ? "card free-card " : "card "}>
            <figure>
              <img className="offer-cover" src={ offer.images && offer.images.length ?  offer.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="BMW car repair" />

              {offer && offer.discountPercentage ?
                offer.discountPercentage == 100 ?
                  <div className=" price free-price col-md-6 col-sm-6 col-xs-6">

                    <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                    <div className="price-content">

                      <span className="amount">Free</span>
                    </div>
                  </div>
                  :
                  <div className=" price col-md-4 col-sm-4 col-xs-4">

                    <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                    <div className="price-content">

                      <span className="amount">{offer && offer.discountPercentage }%</span>
                      <span className="type">OFF</span>
                    </div>
                  </div>
                :
                <div className=" price col-md-4 col-sm-4 col-xs-4">

                  <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                  <div className="price-content discount-amount-content">

                    <span className="discount-amount">{offer && Number(offer.discountAmount).toFixed(2)}</span>
                    <span className="discount-currency">AED </span>
                    <span className="discount-type">OFF</span>
                  </div>
                </div>

                }
            </figure>

              <div className="description col-md-12 col-sm-12 col-xs-12">

                <div>

                  <span className="garage">{offer.garages && offer.garages.length ? "Service Providers" : "-"} {offer.garages.length >= 1 ?  ( "(" + (offer.garages.length) + ")")  : "" }</span>

                  <span className="title">{offer.offerName}</span>
                  <span className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon.png` }  /> {days} Days Left</span>
                </div>
                {offer.totalAmount ?
                  <div className="offer-price">

                    {offer && offer.discountPercentage ?
                      offer.discountPercentage == 100 ?
                        <span className="amount">Free</span>
                        :
                        <span className="amount">{Number(offer.discountedAmount).toFixed(2)} AED</span>
                      :
                      <span className="amount">{Number(offer.discountedAmount).toFixed(2)} AED</span>
                    }
                    <span className="original">{Number(offer.totalAmount).toFixed(2)} AED</span>
                  </div>
                  :
                  <div className="offer-price">
                  </div>
                }
                <Button btnType="transparent"  fontSize={13} label="Book Now"
                        btnCallBack={() => {


                          router.push({
                              pathname: appBasePath + url,
                              state: { selectedPromotionalOffersData: offer }
                            }
                          );
                        }}
                />


              </div>

          </div>
        </div>
    );
  }
}

export default OfferBox;
