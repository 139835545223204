import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import moment from "moment";
import Arrow from './Arrow';


export default class OffersAllSlider extends Component {

  constructor(props, context) {
    super(props, context);


  }

  componentDidMount() {
    setInterval(() => {
        if (this.mobSlider && this.mobSlider.slickNext) {
          this.mobSlider.slickNext();
        }
      },
      4000);
  }


  onOfferClick(parentId,index){


    let url = "all-offers";

    this.props.router.push({
        pathname: appBasePath + url+"/"+this.props.promotionalOffersData[index].id,
        state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
      }
    );
  }

  onOfferBookClick(parentId,index){


    let url = "all-offers";

    this.props.router.push({
        pathname: appBasePath + url+"/"+this.props.promotionalOffersData[index].id+"/book-now",
        state: { selectedPromotionalOffersData: this.props.promotionalOffersData[index] },
      }
    );
  }


  render() {


    const { promotionalOffersData , router, title, viewAll }  = this.props;


    let promoCodesHtml = promotionalOffersData.map((promoCode, index) => {


      let days = moment(promoCode.validTo).diff(moment(new Date()),'days');
      return (
        <div className="offer-card" key={index}  >
          <div className="offer-body">

            <img className="offer-cover" onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()}
                 src={promoCode.images && promoCode.images.length ? promoCode.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg`}
                 alt="BMW car repair"/>


            {promoCode.discountPercentage ?
              promoCode.discountPercentage == 100 ?
                <div className=" price free-price" onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()} >

                  <img className="price-cover " src={`${imageBasePath}/images/offer-free.png`} alt=""/>

                  <div className="price-content">

                    <span className="amount">Free</span>
                  </div>
                </div>
                :
                <div className=" price" onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()}>

                  <img className="price-cover " src={`${imageBasePath}/images/offer-price.png`} alt=""/>

                  <div className="price-content">

                    <span className="amount">{promoCode.discountPercentage}%</span>
                    <span className="type">OFF</span>
                  </div>
                </div>
              :
              <div className=" price" onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()}>

                <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png`} alt=""/>

                <div className="price-content discount-amount-content">

                  <span className="discount-amount">{Number(promoCode.discountAmount).toFixed(2)}</span>
                  <span className="discount-currency">AED </span>
                  <span className="discount-type">OFF</span>
                </div>
              </div>

            }


            <div className="description col-md-6 col-sm-6 col-xs-6">

              <div  onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()} >

                <span className="garage">{promoCode.garages.length ? "Service Providers (" + (promoCode.garages.length) + ")" : ""}</span>
                <span className="title">{promoCode.offerName}</span>
                <span className="days"><img className="clock-icon"
                                            src={`${imageBasePath}/images/clock-icon-white.png`}/> {days} Days Left</span>
              </div>

              <div className="offer-promo-code">
                Promo Code <span className="promo">{promoCode.promoCode}</span>
              </div>

              {promoCode.totalAmount ?
                <div className="offer-price">

                  {promoCode.discountPercentage ?
                    promoCode.discountPercentage == 100 ?
                      <span className="amount">Free</span>
                      :
                      <span className="amount">{Number(promoCode.discountedAmount).toFixed(2)} AED</span>
                    :
                    <span className="amount">{Number(promoCode.discountedAmount).toFixed(2)} AED</span>
                  }
                  <span className="original">{Number(promoCode.totalAmount).toFixed(2)} AED</span>
                </div>
                :
                <div className="offer-price">
                </div>
              }

              <span className="book"  onClick={(e)=> this.onOfferBookClick(promoCode.services[0].parentId,index) && e.preventDefault()} >Book Now</span>


            </div>
          </div>
        </div>
      );
    });

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      responsive: [
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 900,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 1067,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 1400,
          settings: { slidesToShow: 1 }
        },
      ],
      arrows:true
    };



    return (
      <div className="row" >
        <div className="offer-row" id="all-offers">
          <div className="offer-heading">
            <h5>{title}</h5>
            { viewAll && <span onClick={() => { router.push(`${appBasePath}/offers`) }}>View All</span> }
          </div>
          <div className="col-md-12  offer-layout exclusive-deals">
              <Slider {...settings}  ref={ref => this.mobSlider = ref} >
                {promoCodesHtml}
              </Slider>
          </div>
        </div>
      </div>
    );
  }
}

/*


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", }}
      onClick={onClick}
    >
      <img className="amount-cover " src={`${imageBasePath}/images/next-arrow.png` } alt="" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", }}
      onClick={onClick}
    >
      <img className="amount-cover " src={`${imageBasePath}/images/prev-arrow.png` } alt="" />
    </div>
  );
}
*/
