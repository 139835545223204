import React, { Component } from 'react';
import Button from '../common/Button';
import Upload from '../common/Upload';
import map  from 'lodash/map';
import each  from 'lodash/each';
import cloneDeep  from 'lodash/cloneDeep';
import has  from 'lodash/has';
import range  from 'lodash/range';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import TextInput from '../common/TextInput';
import LightBox from '../common/LightBox';
import { validateFormSubmit } from '../../helpers';
import PageLoader from '../common/PageLoader';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { imageBasePath } from '../../constants/staticData';
import CarDetails from '../common/CarDetails';

var uniqid = require('uniqid');

class ProfileSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarMakeKey: "",
      newImagesArray: [],
      form1Active: false,
      carImages: [], // Images related to Car profile only sans other images
      chassisImages: [], // Images for Chassis Number only
      imageMaxLimitError: false,
      isLoaded: false,
      isMakeandModelLoaded: false,
      submissionError: false,
      showModal: false,
      imageStartIndex: 0,
      uploadImageTypeErr: false,
      uploadImageSizeErr: false,
      activeLogo: null,
      activeModel: null,
      manufacturerTabVisible: true,
      modelTabVisible: true,
      otherDetailsTabVisible: false,
      selectError: false,
      carList: [],
      carModel: [],
      makeDropdown: true,
      yearDropdown: false,
      modelDropdown: false,
      isButtonLoading: false,
      emirateValue: "default",
      emirateDropdownIcon: true,
      deleteModal: false,
      mileageError: "Kms Travelled cannot be empty",
      chassis_number: this.props.profileData ? this.props.profileData.chassisNumber :"",
      emirateProfiles: [
        { name: "Select an Emirate", id: 0 },
        { name: "Abu Dhabi", id: 1 },
        { name: "Ajman", id: 2 },
        { name: "Dubai", id: 3 },
        { name: "Fujairah", id: 4 },
        { name: "Ras al-Khaimah", id: 5 },
        { name: "Sharjah", id: 6 },
        { name: "Umm al-Quwain", id: 7 }
      ]
    };
    this.initialFormData = {
      make: "",
      model: "",
      name: "",
      year: "",
      plate_no: "",
      chassis_number: "",
      mileage: "",
      cylinder:"",
      insuranceprovider: "",
      insurancepolicynumber: "",
      registrationnumber: "",
      state: "",
      carnotes: "",
      model_id: ""
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      name: false,
      plate_no: false,
      mileage: false,
      //'chassis_number': false, //Removing it as mandatory
      registrationnumber: false
    };
    this.imageThumbnails = this.imageThumbnails.bind(this);
    this.setAsCover = this.setAsCover.bind(this);
    this.fileNameUpload = this.fileNameUpload.bind(this);
    this.chassisFileUpload = this.chassisFileUpload.bind(this);
    this.resetFields = Object.assign({}, this.state);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.uploadImageError = this.uploadImageError.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.emirateProfile = this.emirateProfile.bind(this);
    this.manufactureSelected = this.manufactureSelected.bind(this);
    this.yearSelected = this.yearSelected.bind(this);
    this.modelSelected = this.modelSelected.bind(this);
    this.clearCarDetails = this.clearCarDetails.bind(this);
  }
  onSubmit() {
    
    let formData = {
      ...this.formData,
      
    };
    console.log("Data = ", formData)
    let errors = this.errors;
    errors && !errors.registrationnumber && delete errors.registrationnumber;
    const validatedForm = validateFormSubmit(formData, errors);
    this.errors = validatedForm.errors;
    if (validatedForm.validForm) {
      this.setState({
        submissionError: false,
        isButtonLoading: true,
        form2Active: true
      });
      this.props.onSubmit(
        this.formData,
        this.props.isEditProfile,
        this.state.newImagesArray
      );
    } else {
      this.setState({ submissionError: true });
      return;
    }
  }
  onFieldChange(value, key, name) {
    this.formData[name] = value;
    if (name == "model") {
      this.formData["year"] = "";
    }
    if (name == "make") {
      this.formData["year"] = "";
      this.formData["model"] = "";
    }
    this.errors[name] = value ? false : true;
    if (name === "model") {
      if (!value) {
        this.setState({ selectError: true });
      } else {
        if (this.formData["year"] && !this.props.isEditProfile) {
          this.setState({
            selectError: false,
            manufacturerTabVisible: false,
            modelTabVisible: true,
            otherDetailsTabVisible: false
          });
        } else {
          this.setState({ selectError: false });
        }
      }
    }
  }
  onFieldBlur(value, name, validateError, event) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
    this.errors["registrationnumber"] =
      (this.formData["insuranceprovider"].length ||
        this.formData["insurancepolicynumber"].length ||
        this.formData["state"].length) &&
      !this.formData["registrationnumber"].length
        ? true
        : false;

    if(this.formData["mileage"] < 0 ){
      this.errors["mileage"] =  true ;

      this.setState({ mileageError: "Please prove valid Mileage" });


    }
    this.setState({ isLoaded: this.state.isLoaded });
  }

  emirateProfile(emirateID) {
    let emirateName = "";
    this.state.emirateProfiles.forEach(item => {
      if (emirateID == item.id && emirateID != 0) {
        emirateName = item.name;
        this.formData.state = emirateName;
      }
    });
  }

  emirateDropdown() {
    this.setState({
      emirateDropdownIcon: !this.state.emirateDropdownIcon,
      deleteModal: false
    });
  }
  activeLogo(name) {
    this.setState({ activeLogo: name });
    this.formData["make"] = name;
  }
  activeModel(name) {
    this.setState({ activeModel: name });
    this.formData["model"] = name;
  }
  tabOpen(val) {
    if (!this.props.isEditProfile) {
      if (val == "manufacturerTabVisible") {
        this.setState({
          manufacturerTabVisible: true,
          modelTabVisible: false,
          otherDetailsTabVisible: false
        });
      } else if (val == "modelTabVisible") {
        if (this.formData.make) {
          this.setState({
            manufacturerTabVisible: false,
            modelTabVisible: true,
            otherDetailsTabVisible: false
          });
        }
      } else if (val == "otherDetailsTabVisible") {
        if (this.formData.year && this.formData.model) {
          this.setState({
            manufacturerTabVisible: false,
            modelTabVisible: false,
            otherDetailsTabVisible: true
          });
        }
      }
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  uploadImageError(value) {
    this.props.actions.uploadCarProfilePictureTest(
      value,
      this.props.carProfileReducer.newCarProfileData.batch_id,
      "",
      false
    );
  }

  fileNameUpload(e) {
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.carImages.length + files.length > 5) {
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true });
    } else {
      this.setState({ imageMaxLimitError: false });
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.carImages + files.length);
    let carImages = [...this.state.carImages];
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.carProfileReducer.newCarProfileData.batch_id,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.carProfileReducer.newCarProfileData.batch_id,
                    imageId,
                    true
                  );
                  if (
                    that.props.carProfileReducer.newCarProfileData.images &&
                    that.props.carProfileReducer.newCarProfileData.images
                      .length &&
                    that.props.carProfileReducer.newCarProfileData.images[0] &&
                    that.props.carProfileReducer.newCarProfileData.images[0]
                      .id == data.imageId &&
                    that.props.routeParams &&
                    that.props.routeParams.id
                  ) {
                    that.props.actions.setVehicleCoverImage(
                      data.imageId,
                      that.props.routeParams.id
                    );
                  }
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError(value),
          that.props.routeParams && that.props.routeParams.id
        );
      };

      carImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: value.localUniqueId,
        isCover: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      carImages: carImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });
  }

  // The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number
  chassisFileUpload(e) {
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.carProfileReducer.newCarProfileData.batch_id,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.carProfileReducer.newCarProfileData.batch_id,
                    imageId,
                    true,
                    'CSN'
                  );
                  /* if (
                    that.props.carProfileReducer.newCarProfileData.images &&
                    that.props.carProfileReducer.newCarProfileData.images
                      .length &&
                    that.props.carProfileReducer.newCarProfileData.images[0] &&
                    that.props.carProfileReducer.newCarProfileData.images[0]
                      .id == data.imageId &&
                    that.props.routeParams &&
                    that.props.routeParams.id
                  ) {
                    that.props.actions.setVehicleCoverImage(
                      data.imageId,
                      that.props.routeParams.id
                    );
                  }*/
                },
                () => that.uploadImageError(value)
              );
            }
          },
          () => that.uploadImageError(value),
          that.props.routeParams && that.props.routeParams.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ isButtonLoading: false });
    }
    if (
      nextProps.carProfileReducer &&
      nextProps.carProfileReducer.carMakeAndModels
    ) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, {
        id: this.state.selectedCarMakeKey
      });
      const carModel =
        carList &&
        carList.length &&
        carList[carMakeIndex] &&
        carList[carMakeIndex].carmodel;


      this.setState({
        carList: nextProps.carProfileReducer.carMakeAndModels,
        filteredCarList: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        carModel: carModel,
        filteredCarModelList: carModel
      });
    }
    const { carProfileReducer } = nextProps;
    if (carProfileReducer.currentComponentKey === "/car-profiles/create") {
      this.formData = { ...this.initialFormData };
      this.state = { ...this.resetFields };
    }
    // Get images of CarProfile and Load Similar logic goes in for chassisNumber
    if (nextProps.carProfileReducer.newCarProfileData.images) {
      let imageArray = [...this.state.carImages];

      map(nextProps.carProfileReducer.newCarProfileData.images, img => {
        let imageIndex = findIndex(imageArray, localImg => {
          return (
            (localImg.localUniqueId &&
              img.localUniqueId &&
              localImg.localUniqueId == img.localUniqueId) ||
            localImg.id == img.id
          );
        });
        if (imageIndex != -1) {
          imageArray[imageIndex] = img;
        } else if (img.id) {
          imageArray.push(img);
        }
      });
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(imageArray, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        carImages: imageArray
      });
    }

    // Load ChassisNumber Images on Receiving Props
    /*
      if next props has the images of chassis Number put them
      as the default value of chassisImages in the state
    */

    console.log("nextProps.carProfileReducer.currentCarProfile",nextProps.carProfileReducer.currentCarProfile);
    if (nextProps.carProfileReducer.newCarProfileData.CSNimages) {
      let imageArray = [...this.state.chassisImages];
      map(nextProps.carProfileReducer.newCarProfileData.CSNimages, img => {
        let imageIndex = findIndex(imageArray, localImg => {
          return (
            (localImg.localUniqueId &&
              img.localUniqueId &&
              localImg.localUniqueId == img.localUniqueId) ||
            localImg.id == img.id
          );
        });
        if (imageIndex != -1) {
          imageArray[imageIndex] = img;
        } else if (img.id) {
          imageArray.push(img);
        }
      });
      if (
        nextProps.carProfileReducer &&
        nextProps.carProfileReducer.deletedImageId
      ) {
        remove(imageArray, function(f) {
          return f.id == nextProps.carProfileReducer.deletedImageId;
        });
      }
      this.setState({
        chassisImages: imageArray
      });
    }

    if (nextProps.carProfileReducer.isIndCarProfileLoaded) {
      this.setState({ isLoaded: true });
    }

    if (nextProps.isEditProfile && nextProps.profileData) {
      this.setState({
        activeLogo: nextProps.profileData.make,
        activeModel: nextProps.profileData.model
      });

      each(nextProps.profileData, (val, key) => {
        if (key == "plateNo" && val) {
          this.formData["plate_no"] = val;
        } else {
          this.formData[key] = val;
        }
      });
    }
  }
   
  setAsCover(image) {
    if (this.props.routeParams.id && image.id) {
      this.props.actions.setVehicleCoverImage(
        image.id,
        this.props.routeParams.id
      );
    }
  }
  imageThumbnails(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          {img.isUploaded ? (
            <span
              className="cancel-image"
              onClick={e => {
                e.preventDefault();
                this.props.actions.deleteVehicleImage(
                  index,
                  img.id,
                  imageArray,
                  this.props.routeParams.id
                );
              }}
            >
              <i className="mdi mdi-close" />
            </span>
          ) : (
            ""
          )}
          <img
            src={
              img.isFailed
                ? `${imageBasePath}/images/warning.png`
                : img.thumbnail
            }
            onClick={() => {
              this.setState({ showModal: true, imageStartIndex: index });
            }}
          />
          {!img.isUploaded ? (
            <div>
              <img
                className="img-loader-on-upload"
                src={`${imageBasePath}/images/loader_white.gif`}
                alt="Upload in progress"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }

  onChasisChange(value) {
    this.setState({
      chassis_number: value ? value : "",
    });
  }

  manufactureSelected(name,id,model){
    this.formData["make"] = name;
    this.errors["make"] = false;
    this.props.actions.getCarModels(id);
    this.setState({
      selectedCarMakeKey: id
    });
  }

  modelSelected(modelId,modelName){
    this.formData['model_id'] = modelId;
    this.formData['model'] = modelName;
    this.errors["model_id"] = false;
    this.errors["model"] = false;
    this.setState({
      carDetailsvalueChanged: true
    });
  }

  yearSelected(year) {
    this.formData["year"] = year;
    this.errors["carDetails"] = false;
    this.setState({
      carDetailsvalueChanged: true,
      otherDetailsTabVisible: true
    });
  }

  clearCarDetails(){
    this.formData["make"] = "";
    this.formData['model_id'] = "";
    this.formData['model'] = "";
    this.formData["year"] = "";
    this.errors["carDetails"] = true;
    this.setState({
      carDetailsvalueChanged: true,
      otherDetailsTabVisible: false
    });
  }

  render() {
    let imageUploadedView, CSNUploadView;
    imageUploadedView = this.imageThumbnails(
      this.state.carImages ? this.state.carImages : []
    );
    CSNUploadView = this.imageThumbnails(
      this.state.chassisImages ? this.state.chassisImages : []
    );

    const carList = this.state.filteredCarList
      ? this.state.filteredCarList
      : this.state.carList;
    let carMakeIndex = findIndex(carList, {
      id: this.state.selectedCarMakeKey
    });
   
    return !this.props.isEditProfile ||
      (this.state.isLoaded && this.props.isEditProfile) ? (
      <div className="panel-section p-panel">
     
        {!this.props.isEditProfile && (
          <section className="collapse-panel brand-panel">
            <div
              className="panel-head"
              onClick={() => {
                this.tabOpen("modelTabVisible");
              }}
            >
              <span
                className={this.state.form1Active ? "n-step active" : "n-step"}
              >
                1
              </span>
              <h4>Select The Manufacturer</h4>
              <i
                className={
                  this.state.modelTabVisible
                    ? "mdi mdi-chevron-up"
                    : "mdi mdi-chevron-down"
                }
              />
            </div>
            {this.state.modelTabVisible ? (
              this.state.isMakeandModelLoaded ? (
                <div className="panel-content">
                <CarDetails carList={carList} carModalList={this.state.filteredCarModelList} clearCarDetails={this.clearCarDetails} manufactureSelected={this.manufactureSelected} modelSelected={this.modelSelected} yearSelected={this.yearSelected}/>
                </div>
              ) : (
                <PageLoader />
              )
            ) : (
              ""
            )}
          </section>
        )}
        <section className="collapse-panel">
          {!this.props.isEditProfile && (
            <div
              className="panel-head"
              onClick={() => {
                this.tabOpen("otherDetailsTabVisible");
              }}
            >
              <span
                className={this.state.form2Active ? "n-step active" : "n-step"}
              >
                2
              </span>
              <h4>Enter Other Details</h4>
              <i
                className={
                  this.state.otherDetailsTabVisible
                    ? "mdi mdi-chevron-up"
                    : "mdi mdi-chevron-down"
                }
              />
            </div>
          )}
          {(this.props.isEditProfile || this.state.otherDetailsTabVisible) && (
            <div className="panel-content car-profile-edit-panel">
              <div className="wrapper">
                <div className="row car-profile">
                  <h4 className="panel-sub-title">car profile</h4>
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      showValidationError={this.errors["name"]}
                      label="Car Profile Name*"
                      name="name"
                      type="text"
                      value={this.formData.name}
                      validationError="Profile Name cannot be empty"
                      onBlur={this.onFieldBlur.bind(this)}
                    />
                  </div>
                  {!this.props.isEditProfile && (
                    <div className="col-md-6 padRight0">
                      <TextInput
                        showValidationError={this.errors["plate_no"]}
                        label="Plate Number*"
                        name="plate_no"
                        type="text"
                        validationError="Plate Number cannot be empty"
                        value={this.formData.plate_no}
                        onBlur={this.onFieldBlur.bind(this)}
                        disabled={this.props.isEditProfile}
                      />
                    </div>
                  )}
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      showValidationError={this.errors["mileage"]}
                      label="Kms Travelled*"
                      name="mileage"
                      type="number"
                      isPositiveNumber={true}
                      validationError={this.state.mileageError}
                      value={this.formData.mileage}
                      onBlur={this.onFieldBlur.bind(this)}
                    />
                  </div>
                </div>
                <div className="row car-profile">
                <div className="col-md-6 padLeft0">
                    <TextInput
                      // showValidationError={this.errors["mileage"]}
                      label="Cylinder Value"
                      name="cylinder"
                      type="number"
                      isPositiveNumber={true}
                      // validationError={this.state.mileageError}
                      value={this.formData.cylinder}
                      onBlur={this.onFieldBlur.bind(this)}
                    />
                  </div>

                </div>
                <div className="row car-profile">
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      showValidationError={this.errors["chassis_number"]}
                      label="Chassis Number"
                      name="chassis_number"
                      type="text"
                      customClass="noMargin"
                      disabled={this.state.chassisImages.length >0}
                      value={
                        this.props &&
                        this.props.profileData &&
                        this.props.profileData.chassisNumber && (this.props.profileData.chassisNumber === this.state.chassis_number)
                          ? this.props.profileData.chassisNumber
                          : this.state.chassis_number
                      }
                      onBlur={this.onFieldBlur.bind(this)}
                      onChange={this.onChasisChange.bind(this)}
                    />
                    {/*<div className="chassisLabel">
                        The chasis number can be found on the Vehicle Registration Certificate (VRC)
                    </div>*/}
                    <label
                    className="mobChassis"
                    >
                      The Chassis Number can be found on the Vehicle
                      Registration Certificate (VRC)
                    </label>
                  </div>

                  {(this.state.chassis_number === '') && <div className="col-md-6 padRight0">
                    <div className="or-text-vert">
                      <span className="or-text-vert-span">OR</span>
                      <h4 className="panel-sub-title">Upload images</h4>
                      <div className="img-uploads">
                        {this.state.chassisImages.length < 1 ? (
                          <Upload
                            margin={false}
                            id="CSNProfileUpload"
                            fileUpload={e => this.chassisFileUpload(e)}
                          />
                        ) : (
                          <div />
                        )}
                        {CSNUploadView}
                      </div>
                      <div
                      className="mobChassisFileSize"
                      >
                        The file has to be below 25MB. Please use JPG or PNG
                      </div>
                      <span
                        className={
                          this.state.uploadImageTypeErr
                            ? "image-upload-error "
                            : "image-upload-error  hide"
                        }
                      >
                        <p>
                          Sorry, only JPG or PNG is accepted. Try again with
                          image file.
                        </p>
                        <i
                          className="mdi mdi-close"
                          onClick={() =>
                            this.setState({ uploadImageTypeErr: false })
                          }
                        />
                      </span>
                      <span
                        className={
                          this.state.uploadImageSizeErr
                            ? "image-upload-error "
                            : "image-upload-error  hide"
                        }
                      >
                        <p>
                          Sorry, uploaded image exceeds the file size limit of
                          25mb. Try again with another image.
                        </p>
                        <i
                          className="mdi mdi-close"
                          onClick={() =>
                            this.setState({ uploadImageSizeErr: false })
                          }
                        />
                      </span>
                      <span
                        className={
                          this.state.imageMaxLimitError
                            ? "image-upload-error "
                            : "image-upload-error  hide"
                        }
                      >
                        <p>Maximum allowed limit is 5.</p>
                        <i
                          className="mdi mdi-close"
                          onClick={() =>
                            this.setState({ imageMaxLimitError: false })
                          }
                        />
                      </span>
                    </div>
                  </div>}
                </div>
                <div className="row insurance-details">
                  <h4 className="panel-sub-title">
                    Insurance Details (Optional)
                  </h4>
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      label="Insurance Provider"
                      name="insuranceprovider"
                      type="text"
                      value={this.formData.insuranceprovider}
                      onBlur={this.onFieldBlur.bind(this)}
                    />
                  </div>
                  <div className="col-md-6 padRight0">
                    <TextInput
                      label="Insurance Policy Number"
                      name="insurancepolicynumber"
                      type="text"
                      onBlur={this.onFieldBlur.bind(this)}
                      value={this.formData.insurancepolicynumber}
                    />
                  </div>
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      label="Vehicle Reg. Number"
                      showValidationError={this.errors["registrationnumber"]}
                      emptyValidationNotRequired={
                        (this.formData["insuranceprovider"] &&
                          this.formData["insuranceprovider"].length) ||
                        (this.formData["insurancepolicynumber"] &&
                          this.formData["insurancepolicynumber"].length) ||
                        (this.formData["state"] &&
                          this.formData["state"].length)
                          ? false
                          : true
                      }
                      validationError="Please enter Vehicle Registration Number"
                      name="registrationnumber"
                      type="text"
                      onBlur={this.onFieldBlur.bind(this)}
                      value={this.formData.registrationnumber}
                    />
                  </div>
                  <div className="col-md-6 padRight0">
                    <p className="emirate-label">Emirate</p>
                    {/*<TextInput label="State" name="state" type="text" onBlur={this.onFieldBlur.bind(this)} value={this.formData.state} />*/}

                    <div className="text-dropdown add-new car-profile-header">
                      <DropdownButton
                        bsSize="large"
                        id="dropdown-large"
                        noCaret
                        onSelect={this.emirateProfile}
                        onToggle={() => {
                          this.emirateDropdown();
                        }}
                        title={
                          <span>
                            <span className="emirate-name">
                              {this.formData.state
                                ? this.formData.state
                                : "Select an Emirate"}
                            </span>
                            {this.state.emirateDropdownIcon && (
                              <i className="mdi mdi-chevron-down emirate-dd-arw" />
                            )}
                            {!this.state.emirateDropdownIcon && (
                              <i className="mdi mdi-chevron-up emirate-dd-arw" />
                            )}
                          </span>
                        }
                      >
                        {map(this.state.emirateProfiles, emirateProfiles => {
                          return (
                            <MenuItem
                              eventKey={emirateProfiles.id}
                              key={emirateProfiles.id}
                            >
                              {emirateProfiles.name}
                            </MenuItem>
                          );
                        })}
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className="row car-notes">
                  <h4 className="panel-sub-title">Car Notes (Optional)</h4>
                  <div className="col-md-6 padLeft0">
                    <TextInput
                      label="Additional Details About The Car (Optional)"
                      name="carnotes"
                      type="text"
                      onBlur={this.onFieldBlur.bind(this)}
                      value={this.formData.carnotes}
                    />
                  </div>
                </div>
                <div className="col-md-12 padLeft0">
                  <h4 className="panel-sub-title">Upload images</h4>
                  <div className="img-uploads">
                    {this.state.carImages.length < 5 ? (
                      <Upload
                        id="carProfileUpload"
                        fileUpload={e => this.fileNameUpload(e)}
                      />
                    ) : (
                      <div />
                    )}
                    {imageUploadedView}
                    {/* imageThumbnailView */}
                  </div>
                  <span
                    className={
                      this.state.uploadImageTypeErr
                        ? "image-upload-error "
                        : "image-upload-error  hide"
                    }
                  >
                    <p>
                      Sorry, only JPG or PNG is accepted. Try again with image
                      file.
                    </p>
                    <i
                      className="mdi mdi-close"
                      onClick={() =>
                        this.setState({ uploadImageTypeErr: false })
                      }
                    />
                  </span>
                  <span
                    className={
                      this.state.uploadImageSizeErr
                        ? "image-upload-error "
                        : "image-upload-error  hide"
                    }
                  >
                    <p>
                      Sorry, uploaded image exceeds the file size limit of 25mb.
                      Try again with another image.
                    </p>
                    <i
                      className="mdi mdi-close"
                      onClick={() =>
                        this.setState({ uploadImageSizeErr: false })
                      }
                    />
                  </span>
                  <span
                    className={
                      this.state.imageMaxLimitError
                        ? "image-upload-error "
                        : "image-upload-error  hide"
                    }
                  >
                    <p>Maximum allowed limit is 5.</p>
                    <i
                      className="mdi mdi-close"
                      onClick={() =>
                        this.setState({ imageMaxLimitError: false })
                      }
                    />
                  </span>
                </div>
                <div className="next-button btn-align btn-spacing">
                  <Button
                    btnType="transparent"
                    btnSize="sm"
                    fontSize={15}
                    label="Cancel"
                    btnCallBack={() => {
                      window.history.back();
                    }}
                  />
                  <Button
                    btnType="red"
                    btnSize="sm"
                    fontSize={14}
                    label="Save"
                    btnCallBack={() => this.onSubmit()}
                    isButtonLoading={this.state.isButtonLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
        {this.props.carProfileReducer.newCarProfileData.images &&
        this.props.carProfileReducer.newCarProfileData.images.length ? (
          <LightBox
            showUploadIcon={this.state.carImages.length < 5}
            showSetAsCover={this.props.isEditProfile}
            actions={this.props.actions}
            images={this.state.carImages}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.fileNameUpload}
            setAsCover={this.setAsCover.bind(this)}
            vehicleId={this.props.routeParams.id}
          />
        ) : (
          ""
        )}
        {/* <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} /> */}
      </div>
    ) : (
      <PageLoader />
    );
  }
}

export default ProfileSteps;
