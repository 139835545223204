import React, { Component } from 'react';
import LoginHeader from '../common/LoginHeader';
import Button from '../common/Button';
import AlertDismissable from '../common/AlertDismissable';
import CustomScroll from 'react-custom-scroll';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class EmailVerified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Verifying email ...',
            caption: ''
        }
    }
    componentWillMount() {
        console.log("Router = ",this.props);
        const currentRoute = this.props.router.getCurrentLocation();
        if (currentRoute.query && currentRoute.query.type && currentRoute.query.code) {
            this.props.actions.verifyEmail(currentRoute.query.code);
        } else {
            this.props.router.push(`${appBasePath}/`);
        }
    }
    render() {
        const { authReducer } = this.props;
        return (
            <div className="container-fluid" id="wrapper">
                <LoginHeader headerTitle="Sign Up" />
                <div className="col-md-12 col-sm-12 col-xs-12 pad0 grid-12" style={{textAlign:'center'}}>
                    <div className="customScroll">
                        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
                            <div className="login-panel confirmed-reset-panel">
                                <div className="login-panel-header forget-panel-header" style={{marginTop: '80px'}}>
                                    {authReducer && authReducer.emailVerified && <img src={`${imageBasePath}/images/emailverification.png`} alt="Email Verified"/>}
                                    {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle="danger" closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                                        <p> <i className="mdi mdi-block-helper" /> {authReducer.statusMessage} </p>
                                    </AlertDismissable>}
                                    {!(authReducer && authReducer.showErrorMessage) && <h3 style={{color: '#454B51', fontSize: '36px', fontWeight: 'bold'}} className="login-title">{authReducer.emailVerified ? 'Congratulations!' : this.state.title}</h3>}
                                    {!(authReducer && authReducer.showErrorMessage) && <p className="note-text" style={{color: '#454B51', fontSize: '20px', fontWeight: 'bold'}}>
                                        {authReducer && authReducer.emailVerified ? 'You have successfully confirmed your Email-id' : this.state.caption}
                                    </p>}
                                </div>
                                <div className="login-panel-footer confirmed-reset" style={{textAlign: 'center'}}>
                                    <Button data-labelledby="Login" btnType="red" btnSize="sm" fontSize={14} label="Login" btnCallBack={(e) => { e.preventDefault(); this.props.router.push(`${appBasePath}/sign-in`) }} />
                                </div>
                            </div>
                        </CustomScroll>
                    </div>
                </div>
            </div>
        );
    }
}
