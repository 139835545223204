import React, { Component } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import AlertDismissable from '../common/AlertDismissable';
import { map} from 'lodash';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
this.initialFormData = {
      'currentpassword': '',
      'newpassword':'',
      'confirmpassword':'',
      'terms': true
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      'currentpassword': false,
      'newpassword': false,
      'confirmpassword': false,
      // 'terms': false
    };
    this.onSubmit=this.onSubmit.bind(this);
    }

    componentWillUnmount() {
     this.props.actions.hideErrorMessage();
    }

    onFieldChange(value, name,validateError) {
      this.setState({formError :false});
      this.formData[name] = value;
      this.errors[name] = value ? false : true;
    }
 onSubmit(){
     let data =null;
     let a= document.getElementsByClassName('form-group');
    map(a, function(value, key) {
        data+= value.className;
    });
    let checkError = data.indexOf('error') !== -1 ?true :false;
    if(checkError){
        this.props.actions.showErrorMessage('please correct the errors on this form');
      } else if(this.formData.newpassword !== this.formData.confirmpassword){
                        this.props.actions.showErrorMessage('new and confirmed password is miss match');
      } else {
        let passwordData = {
             "currentPassword": this.formData.currentpassword,
            "newPassword":this.formData.confirmpassword
             }
          this.props.actions.changePasswordAction(passwordData);
         
        }
      }

 
    render() {

  const {authReducer} = this.props;
        return (
            <div>
              {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle={authReducer.statusMessage.indexOf('succesfully') !== -1  ? 'success error-back mob-err-suc-dispaly' :'danger error-back mob-err-suc-dispaly'} closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                      <p> <i className="mdi mdi-block-helper " /> {authReducer.statusMessage} </p>
                    </AlertDismissable>}
                <div className="password-form-info password-form-info-mob">
                <TextInput
                        type="password"
                        label="Current Password"
                        name="currentpassword"
                        showValidationError={this.errors['password']}
                        validationError="Password should be greater than six digits"
                        onBlur={this.onFieldChange.bind(this)} />
                        <TextInput
                        type="password"
                        label="New Password"
                        name="newpassword"
                        showValidationError={this.errors['password']}
                        validationError="Password should be greater than six digits"
                        onBlur={this.onFieldChange.bind(this)} />
                        <TextInput
                        type="password"
                        label="Confirm New Password"
                        name="confirmpassword"
                        showValidationError={this.errors['password']}
                        validationError="Password should be greater than six digits"
                        onBlur={this.onFieldChange.bind(this)} />
                </div>
            <div className="navigation-footer-part clearfix container">
               <Button btnType="red" btnSize="sm" fontSize={16} label="Save" btnCallBack={()=>this.onSubmit(this)}  customClass="btn-theme-save"/>
               <Button btnType="transparent" btnSize="sm" fontSize={16} label="Cancel"  btnCallBack={()=>window.history.back()} customClass="btn-theme"/>
            </div>

            </div>
        );
    }
}
