import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import ProfileSteps from './ProfileSteps';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import AlertDismissable from '../common/AlertDismissable';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'

export default class NewCarProfile extends Component {
  constructor(props) {
    super(props);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false,
      isEditProfile: false,
      selectedPromotionalOffersData: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const routeParams = this.props.routeParams;
    this.props.actions.clearCarProfileBatchId();
    this.props.actions.hideErrorMessage();
    if (!this.props.carProfileReducer.carProfiles) {
      this.props.actions.getCarProfileList(this.props.router);
    }
    if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
      // this.props.actions.getCarMakeandModels();
      this.props.actions.getManufacturers();
    }
    // let batchId = this.props.carProfileReducer.currentCarProfile && this.props.carProfileReducer.currentCarProfile.batchId;
    if (routeParams && routeParams.id) {
      let batchId = this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles.length && this.props.carProfileReducer.carProfiles[routeParams.id];
      this.props.actions.createCarProfileBatchId(routeParams && routeParams.id && batchId ? batchId : false);

      this.setState({ 'isEditProfile': true, profileId: routeParams.id });
      this.props.actions.getCarProfileDetails(routeParams.id, this.props.router);
    } else {
      this.props.actions.createCarProfileBatchId(false);
    }


  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.carProfileReducer.currentComponentKey === 'car-list') {
    //   this.props.router.push('/car-profiles');
    // }
    const selectedPromotionalOffersData = localStorage.getItem('selectedPromotionalOffersData') ? JSON.parse(localStorage.getItem('selectedPromotionalOffersData')) : '';

   //

    this.setState({
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : selectedPromotionalOffersData? selectedPromotionalOffersData : null,
    });

      //localStorage.removeItem("selectedPromotionalOffersData");


  }

  componentWillUnmount() {
    this.props.actions.hideErrorMessage();
  }

  onSubmit(carProfileData, isEditProfile, newImageIdsArray) {
    console.log('🤬 🤬 0 ', carProfileData);
    console.log('🤬 🤬 1 ', isEditProfile);
    console.log('🤬 🤬 2 ', this.state.profileId);
    console.log('🤬 🤬 3 ', this.props.router);
    console.log('🤬 🤬 4 ', this.props.carProfileReducer.newCarProfileData.batch_id);
    console.log('🤬 🤬 5 ', newImageIdsArray);
    console.log('🤬 🤬 6 ', this.state.selectedPromotionalOffersData);
    this.props.actions.setCarProfileAction(
      carProfileData,
      isEditProfile,
      this.state.profileId,
      this.props.router,
      this.props.carProfileReducer.newCarProfileData.batch_id,
      newImageIdsArray,
      this.state.selectedPromotionalOffersData);
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  render() {
    const { authReducer, carProfileReducer, commonReducer, actions, router,bookingRequestReducer } = this.props;
    let carProfiles = carProfileReducer.carProfiles;
    const routeParams = this.props.routeParams;
    let currentCarProfile = undefined;
    if (carProfiles) {
      if (routeParams.id) {
        currentCarProfile = routeParams && carProfiles[routeParams.id];
      }
    }
    return (
      <div>
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})} 
                            bookingRequestReducer= {bookingRequestReducer}
        commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>{this.state.isEditProfile ? 'Edit Car Profile' : 'Create A Car Profile'}</h4>
            </div>
          </div>

          <div className="inSection">
            <div className="padwrapper">
              {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle="danger" closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                <p> <i className="mdi mdi-block-helper" /> {authReducer.statusMessage} </p>
              </AlertDismissable>}
              {/*Job Updates*/}
              <ProfileSteps
                {...this.props}
                onSubmit={this.onSubmit}
                isEditProfile={this.state.isEditProfile}
                profileData={currentCarProfile ? currentCarProfile : undefined} />
            </div>
          </div>

        </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection">
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
