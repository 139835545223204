import React, { Component } from 'react';
import { Link } from 'react-scroll';
import { appBasePath } from '../../constants/staticData';

export default class SideBar extends Component{
  constructor(props) {
    super(props);
    this.moveToInternalPosition = this.moveToInternalPosition.bind(this);
  }

  moveToInternalPosition(id){
    document.getElementById(`${id}`).scrollIntoView();
  }
  render(){
    // alert('am here 1');
    console.log(this.props.sidebarOpen);
    return(
        <div>
         {/* sidebar menu */}
         <div className={this.props.sidebarOpen ? 'menu-sidebar active' : 'menu-sidebar inactive'}>
         {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
         <div className="nonSignup-list">
           <ul className="list-unstyled">
             <li
               onClick={e => {
                 e.preventDefault();
                 this.props.router.push(appBasePath + '/sign-in');
               }}
             >
               <span>
                 LOG IN
               </span>
             </li>
             <li
               onClick={e => {
                 e.preventDefault();
                 this.props.router.push(appBasePath + '/sign-up');
               }}
             >
               <span>
                 SIGN UP
               </span>
             </li>
             <li>
               <Link to="how-works" onClick={() => this.moveToInternalPosition('howworks-section')} smooth={true} offset={-100} duration={600}>
                 <span>
                   HOW IT WORKS
                 </span>
               </Link>
             </li>
             <li
               onClick={e => {
                 e.preventDefault();
                 window.location.pathname = '/business';
               }}
             >
               <span>
                 FOR BUSINESS
               </span>
             </li>
             <li
             onClick={e => {
               e.preventDefault();
               window.location.pathname = '/blog';
             }}
           >
             <span>
               BLOG
             </span>
           </li>
           </ul>
         </div>
       </div>
        </div>
    )
}
}