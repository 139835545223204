import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Button from '../common/Button';
import BookServiceBox from './BookServiceBox';
import map  from 'lodash/map';
import size  from 'lodash/size';
import CreateRequestPopup from '../common/CreateRequestPopup';
import PageLoader from '../common/PageLoader';
import CarProfileEmptyState from '../EmptyState/CarProfileEmptyState';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { appBasePath } from '../../constants/staticData';

export default class BookService extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false,
      isLoading: (props.carProfileReducer && props.carProfileReducer.carProfiles && size(props.carProfileReducer.carProfiles) > 0) ? false : true,
      bookServiceModalVisible: false,
      selecedCarProfileId: '',
    };
    this.showBookServiceModal = this.showBookServiceModal.bind(this);
  }

  componentWillMount() {
    this.props.actions.getCarProfileList(this.props.router);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.carProfileReducer.carProfiles) {
      this.setState({ 'isLoading': false });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  hideModalCallback() {
    this.setState({ bookServiceModalVisible: false });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  showBookServiceModal(e, id) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      ...this.state,
      selecedCarProfileId: id,
      'bookServiceModalVisible': !this.state.bookServiceModalVisible
    });
  }

  render() {
    const { router, carProfileReducer, commonReducer, actions,bookingRequestReducer } = this.props;
    return (
      <div>
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})}
                    bookingRequestReducer= {bookingRequestReducer}
 
        
        notificationCount={2} commonReducer={commonReducer} notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>My Cars</h4>
              <Button btnType="transparent" btnSize="sm" fontSize={13} label="Add New Car" btnCallBack={() => { router.push(`${appBasePath}/car-profiles/create`) }} />
            </div>
          </div>
          {!this.state.isLoading ? <div className="inSection">
            <div className="padwrapper">
              <div className="myCar-list">
                <div className="myCar-body row">
                  {/*Job Updates*/}
                  {(carProfileReducer.carProfiles && size(carProfileReducer.carProfiles) > 0) ? map(carProfileReducer.carProfiles, (profile, index) => {
                    return (
                      <BookServiceBox key={index} {...profile}
                        btnCallBack={(e) => { this.showBookServiceModal(e, profile.id) }}
                        router={router}
                      />);
                  }) : <CarProfileEmptyState router={router} />}
                  <CreateRequestPopup selecedCarProfileId={this.state.selecedCarProfileId} carProfileReducer={carProfileReducer} router={router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={this.hideModalCallback.bind(this)} />
                </div>
              </div>
            </div>
          </div> : <PageLoader />}
        </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
