import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { find } from 'lodash'

class BookServiceBox extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
    }
    render() {
        const {router}= this.props;
        // const formatedDate = this.props.updatedAt ? moment(this.props.updatedAt).format("DD MMM YY") : undefined;




      let coverImage = find(this.props.images, (img) => {

        return img.isCover == true && img.type == "vehicle"

      });


    /*  console.log("coverImage",this.props.images)
      console.log("coverImage",coverImage)*/
      if(! coverImage && this.props.images.length ){

         coverImage = find(this.props.images, (img) => { return  img.type == "vehicle" });


      }


        const carPhoto = coverImage ? coverImage.large : `${imageBasePath}/images/placeholder.png`;
        return (
            <div className="col-md-4 col-sm-6 col-xs-12 bookService-card">
                <div className="myCar-card " onClick={(e) => {e.preventDefault(); this.props.router.push(`${appBasePath}/car-profiles/${this.props.id}/view`)}}>
                    <figure>
                        {carPhoto && <img src={carPhoto} alt="" />}
                        <div className="myCar-card-footer">
                            <ul>
                                <li>
                                    <h4>Upcoming Service</h4>
                                    <span>Not Available</span>
                                </li>
                                <li>
                                    <span>
                                        <h4>model</h4>
                                        <span>{this.props.model} {this.props.year}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </figure>
                    <figcaption className="myCar-card-caption">
                        <h4>{this.props.plateNo} {this.props.state}</h4>
                        <h5>{this.props.name}</h5>
                        <Button btnSize="sm" fontSize={14} btnType="red" label="Book Service" btnCallBack={this.props.btnCallBack} />
                    </figcaption>
                </div>
            </div>
        );
    }
}

export default BookServiceBox;
