import React, { Component } from 'react';

class FaqQuestion extends Component {
    render() {
        let { question, back } = this.props
        return (
            <div className="FaqQuestion">
                <a className="link" onClick={() => (back())}>Go Back</a>
                <div className="ques-ans">
                    <span className="ques">{question.question}</span>
                    <p className="ans">If you have an Airbnb coupon code you want to use towards a new reservation, you can add it on the checkout page. Coupon codes can't be applied to existing reservations or experiences.How can I cancel my order?</p>
                    <p className="ans">To use your coupon: <br />
                        <ol>
                            <li>When entering your payment on the checkout page, click Redeem a coupon below your payment method</li>
                            <li>Enter your code</li>
                            <li>Click Apply</li>
                        </ol>
                    </p>
                    <p className="ans">Note: Only Airbnb gift cards can be used to pay for experiences. Other types of coupons (ex: referral credit, promotional coupons) can't be applied toward the cost of an experience. To pay for an experience using a gift card, log on using Airbnb's mobile app to complete your booking.</p>
                    <p className="ans">Having trouble with your code? Double check to make sure you’re entering it correctly, or <span className="link">learn more about coupon code restrictions</span>.</p>
                </div>

            </div>
        );
    }
}

export default FaqQuestion;