import React, { Component } from 'react';
import Button from '../common/Button';
import CreateRequestPopup from '../common/CreateRequestPopup';
import { imageBasePath } from '../../constants/staticData';

export default class MyRequestEmptyState extends Component {
  constructor() {
    super()
    this.state = {
      bookServiceModalVisible: false
    };
  }
  render() {
    return (
      <div className="empty-state-holder">
        <span className="empty-state-content">
          <img src={`${imageBasePath}/images/requests_empty.png`} alt="" />
          <span className="text">{this.props.title || "Oops! Looks like you haven’t created any requests yet. "}</span>
          {this.props.btn ? <Button btnType="red" btnSize="lg" fontSize={16} label={'Book Service'} btnCallBack={() => this.setState({ bookServiceModalVisible: true })}/>:''}
        </span>
        {!this.props.title && <CreateRequestPopup router={this.props.router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={() => this.setState({ bookServiceModalVisible: false })} />}
      </div>
    );
  }
}
