import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import GfCarWash from './GfCarWash';
import GfCarRepair from './GfCarRepair';
import GfCarService from './GfCarService';
import GfEmergency from './GfEmergency';

import { emergencyIsComingSoon } from '../../../constants/staticData';

class Panel2 extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      activeTab: "wash",
      formValidation: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tabOpen == "panel2") {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  }

  formValidation() {
    this.setState({
      formValidation: true,
    });
  }

  render() {
    return (
      <div className="panel2">
        <Panel collapsible expanded={this.state.open} header={
          <div className="panel-section p-panel" onClick={() => { this.props.selectedTab("panel2", this.props.panel1Valid) }}>
            <div className="panel-head" >
              <span className={(this.props.tabOpen == 'panel3' || this.props.tabOpen == 'panel4') ? "n-step active" : "n-step"}>2</span>
              <h4>Select Service</h4>
              <i className={this.state.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
            </div>
          </div>
        }>
          <div className="panel-subhead">
            <div className="row gf-repair-sub-heading">
              <ul>
                <li className={this.state.activeTab == "wash" ? "active" : ""} onClick={() => { this.setState({ activeTab: "wash" }); this.props.activeRequest("wash") }}> <a>Car Wash</a> </li>
                <li className={this.state.activeTab == "service" ? "active" : ""} onClick={() => { this.setState({ activeTab: "service" }); this.props.activeRequest("service") }}> <a>Car Service</a> </li>
                <li className={this.state.activeTab == "repair" ? "active" : ""} onClick={() => { this.setState({ activeTab: "repair" }); this.props.activeRequest("repair") }}> <a>Car Repair</a> </li>

                {emergencyIsComingSoon ?  (
                  <li > <a>Roadside Assistance</a> </li>
                ) : (
                  <li className={this.state.activeTab == "emergency" ? "active" : ""} onClick={() => { this.setState({ activeTab: "emergency" }); this.props.activeRequest("emergency") }}> <a>Roadside Assistance</a> </li>
                )}


              </ul>
            </div>
          </div>
          <div className="panel-content">
            {this.state.activeTab == "wash" && <GfCarWash {...this.props} formValidation={this.formValidation.bind(this)} />}
            {this.state.activeTab == "repair" && <GfCarRepair {...this.props} formValidation={this.formValidation.bind(this)} />}
            {this.state.activeTab == "service" && <GfCarService {...this.props} formValidation={this.formValidation.bind(this)} />}
            {this.state.activeTab == "emergency" && <GfEmergency {...this.props} />}
          </div>
        </Panel>
      </div>
    );
  }
}

export default Panel2;
