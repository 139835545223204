import React, { Component } from 'react';
import Header from "../common/Header";
import MobileNotification from "../common/MobileNotification";
import MobileMessage from "../common/MobileMessage";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import {appBasePath, imageBasePath} from "../../constants/staticData";
import filter  from 'lodash/filter';
import PageLoader from "../common/PageLoader";
import ToastMessage from "../common/ToastMessage";
import Footer from "../common/Footer";
import MobileHeaderSearch from "../common/MobileHeaderSearch";
import OfferCard from "./OfferCard";
import moment from "moment";
import ProvidersView from "../common/ProvidersView";
import FloatingMenu from "../LandingNew/FloatingMenu";




export default class OfferDetail extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
      this.state = {
        searchMobile: false,
        notificationVisible: false,
        messageVisible: false,
        promotionalOffersData: this.props.promotionalOffersData ? this.props.promotionalOffersData : [],
        offer: this.props.offer?  this.props.offer: "",
        offerId : this.props.params.id?this.props.params.id:"",
    }
  }


  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }



  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  componentDidMount() {

    if (this.state.promotionalOffersData.length < 1) {
      this.props.actions.getPromotionalOffers();
    }

  }



  componentWillReceiveProps(nextProps) {

    let offer = nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData && this.state.offerId ? filter(nextProps.commonReducer.promotionalOffersData, (offer) => { return offer.id == nextProps.params.id }) : "";

    this.setState({
      promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
      offer: offer? offer[0] : ""
    });


  }

  render() {



    const { router, commonReducer, actions, authData,bookingRequestReducer } = this.props;



    let days = this.state.offer && moment(this.state.offer.validTo).diff(moment(new Date()),'days');



    let redirectPath = appBasePath + this.state.offer && this.state.offer.services[0].parentId == 1 ? 'car-wash': this.state.offer  && this.state.offer.services[0].parentId == 2 ? 'car-service': 'car-repair';
    let redirectPathSearch = "";
    if(authData && !authData.hasVehicleProfile){
      redirectPathSearch = "?redirect_to="+redirectPath;
      redirectPath = "/car-profiles/create";
    }


    return (
      <div>
      <FloatingMenu  />
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        
        notificationCount={2} commonReducer={commonReducer} notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Offers</h4>
            </div>
          </div>
          <div className="inSection">
              {this.state.offer ? <div className=" offer-detail-wrapper ">
                  <div className="padwrapper">
                    <div className="offer-body offer-detail row">
                      {/*Job Updates*/}
                      <div className="offer-box card  col-md-12 col-sm-12 col-xs-12" >
                        <div className="card-detail col-md-12 col-sm-12 col-xs-12" >
                          <div className={this.state.offer.discountPercentage &&  this.state.offer.discountPercentage == 100 ? "col-md-4 col-sm-6 col-xs-6  free-card cover-card " : "cover-card col-md-4 col-sm-6  col-xs-6  "}>
                            <figure>

                              <img className="offer-cover" src={ this.state.offer.images && this.state.offer.images.length ?  this.state.offer.images[0].medium : `${imageBasePath}/images/offer-placeholder.jpeg` } alt="BMW car repair" />

                              { this.state.offer.discountPercentage ?

                                this.state.offer.discountPercentage == 100 ?
                                  <div className=" price free-price col-md-6 col-sm-6 col-xs-6">

                                    <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                                    <div className="price-content">

                                      <span className="amount">Free</span>
                                    </div>
                                  </div>
                                  :
                                  <div className=" price col-md-4 col-sm-4 col-xs-4">

                                    <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                                    <div className="price-content">

                                      <span className="amount">{this.state.offer.discountPercentage }%</span>
                                      <span className="type">OFF</span>
                                    </div>
                                  </div>
                                :
                                <div className=" price col-md-4 col-sm-4 col-xs-4">

                                  <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                                  <div className="price-content discount-amount-content">

                                    <span className="discount-amount">{Number(this.state.offer.discountAmount).toFixed(2)}</span>
                                    <span className="discount-currency">AED </span>
                                    <span className="discount-type">OFF</span>
                                  </div>
                                </div>

                              }
                            </figure>

                          </div>
                          <div className=" col-md-3 col-sm-6 col-xs-6">

                            <div className="description">



                              <div className="">

                                <div className="header-right">
                                  <ul className="list-unstyled">

                                    <img className="provider-expand" src={`${imageBasePath}/images/provider-expand.png` } alt="" />
                                    <span className="garage">{this.state.offer.garages.length ? "Service Providers" : "-"}


                                    </span>
                                    <span className="providers">

                                      {this.state.offer.garages.length >=1 ?  ( "(" + (this.state.offer.garages.length) + ")")  : "" }
                                      {/* this.state.offer.garages.length > 1 &&

                                      <ProvidersView {...this.props}  providers={this.state.offer.garages}/>
                                      */}
                                    </span>

                                  </ul>
                                </div>


{/*
                                <span className="garage">{this.state.offer.garages.length ? this.state.offer.garages[0].companyprofilename : "-"}</span>
                                <div className="notification-menu desktop-view ">
                                    <ProvidersView providers={this.state.offer.garages} />

                                </div>*/}
                              </div>

                              <span className="title">{this.state.offer.offerName}</span>
                              <span className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon.png` }  /> {days} Days Left</span>
                              {this.state.offer.totalAmount ?
                                <div className="offer-price">
                                  <span className="original">{Number(this.state.offer.totalAmount).toFixed(2)} AED</span>

                                  {this.state.offer.discountPercentage ?
                                    this.state.offer.discountPercentage == 100 ?
                                      <span className="amount">Free</span>
                                      :
                                      <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                                    :
                                    <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                                  }


                                </div>
                                :
                                <div className="offer-price">
                                </div>

                              }
                            </div>


                          </div>
                          <div className="description-card col-md-5 col-sm-12 col-xs-12">

                            <div className="description description-right">
                              <p>{this.state.offer.description}</p>

                            </div>
                            <div className="terms">

                              <p onClick={() => {
                                let url = "/offer-terms/"+this.state.offer.id;
                                window.open(url, '_blank');

                              }}>*Terms and Conditions Apply</p>

                            </div>


                          </div>
                        </div>
                        <div className="offer-bottom col-md-12 col-sm-12 col-xs-12" >

                          <div className="row">
                            <div className="col-md-9 col-sm-6 col-xs-6 offer-bottom-code" >
                              <span className="use-code">Use Code</span>
                              <span className="promo-code">{this.state.offer.promoCode}</span>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-6 ">
                              <Button btnType="transparent" fontSize={16} label="Book Now" btnCallBack={() =>

                                router.push({
                                    pathname: redirectPath,
                                    search: redirectPathSearch,
                                    state: { selectedPromotionalOffersData: this.state.offer }
                                  }
                                )
                              } />
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
              </div> : <PageLoader />}


              <div className=" ">
                <div className=" bottom-all-offers row">
                  <div className="offer-list">
                    <div className="offer-body padwrapper">

                  {!this.state.isOfferLoading ? this.state.promotionalOffersData && this.state.promotionalOffersData.length > 0 &&
                    <OfferCard
                      showPromoCode={true}
                      title="All offers"
                      promotionalOffersData={this.state.promotionalOffersData}
                      router={router}
                      isAuthenticated={true}
                    />
                    : <PageLoader/>
                  }

                    </div>
                  </div>
                </div>


              </div>
          </div>
            {/*
              <div className="similar-offers padwrapper">
                <div className="offer-list">
                  <div className="offer-body">





                  </div>
                </div>
              </div>*/}

          </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>

    );
  }
}

