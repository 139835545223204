import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import cloneDeep  from 'lodash/cloneDeep';
import trim  from 'lodash/trim';
export default class EditLocationModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      label: undefined,
      labelError: false
    };
  }
  componentWillMount() {
    if (this.props.editLocationData && this.props.editLocationData.label) {
      this.setState({
        'label': this.props.editLocationData.label
      });
    }
  }
  onFieldChange(e) {
    const value = e.target.value;
    if (trim(value)) {
      this.setState({'labelError': false, 'label': value});
    } else {
      this.setState({'labelError': true, 'label': value});
    }
  }
  btnCallBack(e) {
    e.preventDefault();
    if (trim(this.state.label)) {
      const newLocationData = cloneDeep(this.props.editLocationData);
      newLocationData['label'] = this.state.label;
      this.props.editLocationCallBack(newLocationData);
    } else {
      this.setState({'labelError': true});
    }
  }
  render(){
    const {title, editLocationData, deleteLocationCallBack} = this.props;
    const newLocationData = cloneDeep(editLocationData);
    return (
        <Modal onHide={this.props.hideModal} show={true}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          {editLocationData && <Modal.Body>
            <div>
                <h5 className="caption">Address</h5>
                <span className="caption-result">{editLocationData.formatted_address}</span>
            </div>
            <div className="location-edit">
                <h5 className="caption">Location Label</h5>
                <span className="delete-text" onClick={(e) => {e.preventDefault(); const confirmDelete = confirm('Are you sure? You want to delete the location'); if (confirmDelete) {deleteLocationCallBack(editLocationData.id);}}}>Delete Location</span>
                    <div className={this.state.labelError ? " pad0 form-group error" : " pad0 form-group"}>
                      <input type="text" className="form-input plain-input" value={this.state.label} onChange={this.onFieldChange.bind(this)} />
                      {this.state.labelError && <span className="error-text">Please enter label</span>}
                    </div>

            </div>
          </Modal.Body>}
          <Modal.Footer>
            <Button btnType="transparent" btnSize="sm" fontSize={15} label={this.props.cancelText?this.props.cancelText:"Cancel"}  btnCallBack={this.props.hideModal}/>
            <Button btnType="red" btnSize="sm" fontSize={15} label={this.props.saveText?this.props.saveText:"Save"} btnCallBack={this.btnCallBack.bind(this)} />
          </Modal.Footer>
      </Modal>
    );
  }
}
