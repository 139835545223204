import React, {Component}  from 'react';
import ExpandCollapse from "../common/ExpandCollapse";

export default class Faq extends Component {
render(){
    const faqsData =[{
        title:"Why should I choose Carcility?",
        description: "In this digital age, nobody wants to queue up in a car repair shop and waste time.\n" +
          "1. We are here to provide you with a seamless booking platform that is easy,safe and cost-effective to help save your time and energy.\n" +
          "2. Our online platform is where you can give your car the care it deserves and make payment at the comfort of your home and office.\n" +
          "3. The service is not only convenient, it is also more affordable.\n" +
          "4. For all the car issues on the go, our mobile app is here for you to enjoy your drive hassle-free.\n" +
          "5. You can make use of the service provider ratings and reviews on our site and app, to make a well-informed decision.\n" +
          "6. Our messaging system creates a new level of transparency between the customers and service providers."
      },{
        title:"How do I book a service with Carcility?",
        description: "You can download Carcility Mobile App from Google Play & Apple App stores or place a request on our website.\n Book your service request in three easy steps:\n" +
          "1. Fill the basic details about your car, your location and service details needed.\n" +
          "2. Request for service.\n" +
          "3. Just hit Accept Quote and you are good to go." 
      },
        {
          title:"What happens after a booking has been confirmed?",
          description: "With the confirmed booking, you will receive the booking confirmation via email,\n" +
            "SMS and push notifications."
        },
        {
          title:"Are pick-up and delivery facilities for my vehicle free of cost?",
          description: "The free pick-up and delivery facility depend on our service providers. The service providers will mention this facility in their quotes."
        },
        {
          title:"What if my car needs extra work other than the service requested initially?",
          description: "If the service providers find that there is any need for extra work to be carried,\n" +
           "out, they will contact and inform you about what works need to be done and of the additional cost estimates."
        },
        {
          title:"How does Carcility assist me in choosing the right car service centers?",
          description: "Using our website and car service app, you can view all the best service providers in the region. Selecting each centre,\n"+
          "you see their location, tally with yours and can go through their description, facilities, accepted payment methods, service centre timings, \n" +
          "the list of services they provide and any ongoing offers. You can finally select the one that suits best for your purpose after deciding on affordability with instant quotes and details about the provider mentioned for each."
        },
        {
        title:"How soon can I expect my car back?",
        description:"Your service provider will inform you of your service completion date. To avoid confusion or for any other queries, you can contact your service provider directly."  
        },
        {
          title:"Do I need pay in advance for service?",
          description:"Not at all. Our priority at Carcility keeps you and your car service first.You need to pay only after the service is completed. We offer flexible payments options like online payment and cash payment."  
        },
        {
          title:"How do I avail an offer?",
          description:"At the Offers tab on Carcility’s homepage, you will see the complete details of the ongoing offers running across centres. Avail the exclusive deals using the promocodes given for each. Your service provider will confirm for the validity and conditions, if any."  
        },
        {
          title:"Do you offer car service for the premium brand models?",
          description:"Carcility is the one-stop service solution for all your cars. With the best service providers in the regions, you can select for your car wash, car repair, car detailing, car maintenance and other service requirements for the premium models for all brands like Toyota, Audi, BMW, Mercedes, etc."  
        }
      ];
  
  
     
  
    return(
        <div id="faq-section" className="row">
          <div className="faq-header">FAQs</div>
          <div className="faq-body">
            {faqsData.map((item,index ) =>
            <ExpandCollapse  title={item.title} description={item.description} initialFlag={index === 0 ? true : false}/>)}
          </div>
        </div>
    )
}
}