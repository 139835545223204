import React, {Component}  from 'react';
import Slider from 'react-slick';
import Arrow from "../common/Arrow";
import {manufactures} from "../../constants/staticData";



export default class ManufactureList extends Component {

    constructor(props) {
      super(props);
      this.state = {
        currentSlide:1
      }

    }

    render() {
    const settings = {
        dots: false,
      infinite: true,
      autoplay:false,
      mobileFirst:true,
        speed: 500,
        slidesToShow: 6,
        nextArrow: <Arrow type="next" />,
        prevArrow: <Arrow type="prev" />,
        responsive: [
          {
            breakpoint: 480,
            settings: { slidesToShow: 2, slidesToScroll: 2, dots: true }
          },
          {
            breakpoint: 640,
            settings: { slidesToShow: 3, slidesToScroll: 3 }
          },
          {
            breakpoint: 768,
            settings: { slidesToShow: 3, slidesToScroll: 3 }
          },
          {
            breakpoint: 1100,
            settings: { slidesToShow: 3, slidesToScroll: 3 }
          },
          {
            breakpoint: 1400,
            settings: { slidesToShow: 6 }
          },
        ],
        afterChange: (prev, next) => {

          let currentSlide = prev+1;

          if(manufactures.length == currentSlide){
            currentSlide = 0
          }

          this.setState({ currentSlide: currentSlide });
        },
      };


    return(
      <div className="" id="manufactureList-section">
        <div className="common-wrapper manufacture-body">
        <div className="common-heading">
                <span>Brands we serve</span>
        </div>
        <div>
           <Slider ref="slick" {...settings} >
              {manufactures.map((item, key) => {
                 return (
                  <div className="col-md-2 col-sm-3 col-xs-6  make-list">
                     <div className="logo-slider-div">
                       <i className={`make-logo ${item.icon}  logo-slider`}></i>
                     </div>
                     <div className="brand-name-land">{item.name}</div>
                   </div>
                 )
               }) }
           </Slider>
          </div>
        </div>
     </div>
    )
  }
}
