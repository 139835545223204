import React, { Component } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import each  from 'lodash/each';
import map  from 'lodash/map';
import cloneDeep  from 'lodash/cloneDeep';
import remove  from 'lodash/remove';
import findIndex  from 'lodash/findIndex';
import Upload from '../common/Upload';
import Gmaps from '../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
var uniqid = require('uniqid');
import LocationSearchBox from '../common/LocationSearchBox';
import Scroll from 'react-scroll';
import TimePicker from 'rc-time-picker';
import PageLoader from '../common/PageLoader';
import ServiceTypeEmptyState from '../EmptyState/ServiceTypeEmptyState';
import LightBox from '../common/LightBox';
import { imageBasePath, } from '../../constants/staticData';
import { queryStringtoArray } from '../../helpers/index';

class EditRepaiSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form1Active: false,
      form2Active: false,
      accidentRadioBtn: false,
      selectedDate: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      generalRadioBtn: false,
      policeReportImgSize: 0,
      rationCardImgSize: 0,
      drivingLicenceImgSize: 0,
      uploadImage: [],
      uploadedImages: [],
      uploadedPoliceReport: [],
      isButtonLoading: false,
      uploadedPoliceReportId: [],
      uploadedRationCard: [],
      uploadedRationCardId: [],
      uploadedDrivingLicence: [],
      uploadedDrivingLicenceId: [],
      policeReport: [],
      rationCard: [],
      drivingLicence: [],
      uploadedImagesId: [],
      PreferedLocation: 'Select Location',
      step1Panel: true,
      step2Panel: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      uploadImageErrText: false,
      policeReportErrText: false,
      rationCardErrText: false,
      drivingLicenceErrText: false,
      selectedCarProfileId: '',
      drivingLicenceImage: false,
      policeReportImage: false,
      rationCardImage: false,
      fromEdit: false,
      referenceId: false,
      jobDetails: this.props.bookedRequest && this.props.bookedRequest.details,
      now: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      dateValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).date(),
      monthValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).month(),
      yearValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).year(),
      hoursValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).hours(),
      minutesValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).minutes(),
      checked: false,
      selectedLocation: {
        formatted_address: this.props && this.props.bookedRequest && this.props.bookedRequest.location,
        label: this.props && this.props.bookedRequest && this.props.bookedRequest.location,
        lat: this.props && this.props.bookedRequest && this.props.bookedRequest.latitude,
        lng: this.props && this.props.bookedRequest && this.props.bookedRequest.longitude,
      },
      currentTime: '',
      selectedTimeValue: '',
      batchID: undefined,
      imageTotalCount: 0,
      policeReportTotalCount: 0,
      rationCardCount: 0,
      drivingLicenceCardCount: 0,
      imageCountErrText: false,
      policeReportCountErrText: false,
      editRequestImageArray: [],
      editRequestFileArray: [],
      drivinLicenceErrText: false,
      rationCardCountErrText: false,
      drivingLicenceCountErrText: false,
      searchValue: '',
      policeReportId: undefined,
      registrationId: undefined,
      drivingLicenceId: undefined,
      policeReportUrl: undefined,
      registrationUrl: undefined,
      drivingLicenseUrl: undefined,
      imageStartIndex: 0,
      policeReportStartIndex: 0,
      registrationReportStartIndex: 0,
      drivingReportStartIndex: 0,
      newImageIdArray: [],
      chassisImages: [],
      chassis_number: '',
      uploadFlag:false,
      check:null,
    };
    this.formData = {
      selectedLocation: true,
      jobDetails: true,
      timeValid: this.state.hoursValue
    };
    this.errors = {
      selectedLocation: false,
      jobDetails: false,
      timeValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.cancelUploadImage = this.cancelUploadImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadImageError = this.uploadImageError.bind(this);
    this.uploadFileError = this.uploadFileError.bind(this);
  }

  componentWillMount() {
    //this.props.actions.createBatchID(this.state.batchID);
    this.props.actions.clearImageArray();
    if (this.props && this.props.bookedRequest && this.props.bookedRequest.batchId) {
      this.setState({
        ...this.state,
        batchID: this.props.bookedRequest.batchId
      });
    }
  }


  componentWillReceiveProps(nextProps) {
    // Add code for ChassisImages
    let CSNImages = [];
    if(nextProps && nextProps.carProfiles &&
    nextProps.bookedRequest && nextProps.bookedRequest.vehicleId) {
      const selectedCarForCSN = nextProps.carInfo && nextProps.carInfo;


      map(selectedCarForCSN.images, (img) => {
        if (img.type == "chassisNumber") {
          CSNImages.push({
            isUploaded: true,
            isFailed: false,
            id: img.id,
            thumbnail: img.small,
            original: img.original,
            large: img.large,
            isCover: false
          });
        }
      });

    }


    let activeRequests = nextProps && nextProps.bookingRequestDetails && nextProps.bookingRequestDetails.requeststatus && nextProps.bookingRequestDetails.requeststatus.displayname == "Active";
    if (!activeRequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile && nextProps.bookingRequestReducer && !this.state.fromEdit) {
      nextProps.bookedRequest.images && nextProps.bookedRequest.images.length > 0 && nextProps.bookedRequest.images.map((value) => {
        value['thumbnail'] = value.small;
        if (value.type == "image") {
          if (!this.state.uploadedImagesId.includes(value.id)) {
            this.state.uploadedImagesId.push(value.id);
            this.state.uploadedImages.push(value);
          }
        } else if (value.type == "policereport") {
          if (!this.state.uploadedPoliceReportId.includes(value.id)) {
            this.state.uploadedPoliceReportId.push(value.id);
            this.state.uploadedPoliceReport.push(value);
          }
        } else if (value.type == "drivinglicense") {
          if (!this.state.uploadedDrivingLicenceId.includes(value.id)) {
            this.state.uploadedDrivingLicence.push(value);
            this.state.uploadedDrivingLicenceId.push(value.id);
          }
        } else if (value.type == "registrationcard") {
          if (!this.state.uploadedRationCardId.includes(value.id)) {
            this.state.uploadedRationCard.push(value);
            this.state.uploadedRationCardId.push(value.id);
          }
        }
      });
      this.setState({
        ...this.state,
        jobDetails: nextProps.bookedRequest && nextProps.bookedRequest.details,
        accidentRadioBtn: nextProps.bookedRequest.repairreason == "general" ? false : true,
        generalRadioBtn: nextProps.bookedRequest.repairreason == "general" ? true : false,
        checked: nextProps.bookedRequest.isurgentrequest ? true : false,
        imageTotalCount: this.state.uploadedImages.length,
        referenceId: nextProps.bookedRequest && nextProps.bookedRequest.id,
        policeReportTotalCount: this.state.uploadedPoliceReport.length,
        rationCardCount: this.state.uploadedRationCard.length,
        batchID: nextProps.bookedRequest.batchId,
        drivingLicenceCardCount: this.state.uploadedDrivingLicence.length,
        selectedCarProfileId: nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
        fromEdit: true,
        chassis_number: nextProps.carInfo && nextProps.carInfo.chassisNumber ? nextProps.carInfo.chassisNumber: '',
        uploadFlag:nextProps.carInfo && (nextProps.carInfo.images.length > 0 )? (this.state.uploadFlag? true : false): true,
        selectedLocation: {
          ...this.state.selectedLocation,
          formatted_address: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
          lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
        },
      });
    }
    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false })
    } else if (activeRequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile && nextProps.bookingRequestReducer && !this.state.fromEdit) {
      nextProps.bookedRequest.images && nextProps.bookedRequest.images.length > 0 && nextProps.bookedRequest.images.map((value) => {
        value['thumbnail'] = value.small;
        if (value.type == "image") {
          if (!this.state.uploadedImagesId.includes(value.id)) {
            this.state.uploadedImagesId.push(value.id);
            this.state.uploadedImages.push(value);
          }
        } else if (value.type == "policereport") {
          if (!this.state.uploadedPoliceReportId.includes(value.id)) {
            this.state.uploadedPoliceReportId.push(value.id);
            this.state.uploadedPoliceReport.push(value);
          }
        } else if (value.type == "drivinglicense") {
          if (!this.state.uploadedDrivingLicenceId.includes(value.id)) {
            this.state.uploadedDrivingLicence.push(value);
            this.state.uploadedDrivingLicenceId.push(value.id);
          }
        } else if (value.type == "registrationcard") {
          if (!this.state.uploadedRationCardId.includes(value.id)) {
            this.state.uploadedRationCard.push(value);
            this.state.uploadedRationCardId.push(value.id);
          }
        }
      });
      this.setState({
        ...this.state,
        statusActive: true,
        step1Panel: false,
        step2Panel: true,
        jobDetails: nextProps.bookedRequest && nextProps.bookedRequest.details,
        accidentRadioBtn: nextProps.bookedRequest.repairreason == "general" ? false : true,
        generalRadioBtn: nextProps.bookedRequest.repairreason == "general" ? true : false,
        checked: nextProps.bookedRequest.isurgentrequest ? true : false,
        imageTotalCount: this.state.uploadedImages.length,
        referenceId: nextProps.bookedRequest && nextProps.bookedRequest.id,
        policeReportTotalCount: this.state.uploadedPoliceReport.length,
        rationCardCount: this.state.uploadedRationCard.length,
        batchID: nextProps.bookedRequest.batchId,
        drivingLicenceCardCount: this.state.uploadedDrivingLicence.length,
        selectedCarProfileId: nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
        fromEdit: true,
        chassis_number: nextProps.carInfo && nextProps.carInfo.chassisNumber ? nextProps.carInfo.chassisNumber: '',
        uploadFlag:nextProps.carInfo && (nextProps.carInfo.images.length > 0 )? (this.state.uploadFlag? true : false): true,
        selectedLocation: {
          ...this.state.selectedLocation,
          formatted_address: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
          lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
        },
      });
    }
    else if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.editRequestImageArray && nextProps.bookingRequestReducer.editRequestImageArray.length && nextProps.bookingRequestReducer.editRequestImageArray.length > this.state.editRequestImageArray.length) {
      let uploadedImages = cloneDeep(this.state.uploadedImages);
      nextProps.bookingRequestReducer.editRequestImageArray.map((editRequestImageArray) => {
        let findUploadImageIndex = findIndex(this.state.uploadedImages, { fileName: editRequestImageArray.fileName });
        if (findUploadImageIndex > -1) {
          uploadedImages[findUploadImageIndex]['uploaded'] = true;
          uploadedImages[findUploadImageIndex]['id'] = editRequestImageArray.id;
        }
      });
      this.setState({
        ...this.state,
        editRequestImageArray: nextProps.bookingRequestReducer.editRequestImageArray,
        uploadedImages: uploadedImages,
      });
    } else if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.editRequestFileArray && nextProps.bookingRequestReducer.editRequestFileArray.length && nextProps.bookingRequestReducer.editRequestFileArray.length > this.state.editRequestFileArray.length) {
      let policeReportId, registrationId, drivingLicenceId, policeReportUrl, registrationUrl, drivingLicenseUrl;
      let newImageIdArray = cloneDeep(this.state.newImageIdArray);
      map(nextProps.bookingRequestReducer.editRequestFileArray, (editRequestFile) => {
        newImageIdArray.push(editRequestFile.id);
        if (editRequestFile.type === "policereport") {
          policeReportId = editRequestFile.id;
          policeReportUrl = editRequestFile.url;
        } else if (editRequestFile.type === "registrationcard") {
          registrationId = editRequestFile.id;
          registrationUrl = editRequestFile.url;
        } else if (editRequestFile.type === "drivinglicense") {
          drivingLicenceId = editRequestFile.id;
          drivingLicenseUrl = editRequestFile.url;
        }
      });
      this.setState({
        ...this.state,
        editRequestFileArray: nextProps.bookingRequestReducer.editRequestFileArray,
        policeReportId: policeReportId,
        registrationId: registrationId,
        drivingLicenceId: drivingLicenceId,
        policeReportUrl: policeReportUrl,
        registrationUrl: registrationUrl,
        drivingLicenseUrl: drivingLicenseUrl,
        newImageIdArray: newImageIdArray
      });
    } else if (nextProps && nextProps.showError) {
      this.setState({ isButtonLoading: false });
    }

    this.setState({
      chassisImages: CSNImages
    });
  }

  imageThumbnails(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
        {this.state.uploadFlag ? <span className="cancel-image" onClick={(e) => {
          e.preventDefault();
          this.props.actions.deleteVehicleImage(index, img.id, imageArray, this.props.bookingRequestId);
        }}>
          <i className="mdi mdi-close" />
        </span> : ''}
          <img
            src={img.thumbnail}
          />
          {!img.isUploaded ? <div><img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" /></div> : ''}
        </div>
      );
    });
  }

  imageThumbnailsForChassis(imageArray) {
    return map(imageArray, (img, index) => {
      return (
        <div className="upload-box-wrapper box-shadow" key={index}>
          <img
            src={img.thumbnail}
          />
        </div>
      );
    });
  }


  hidePanel(panel) {
    if (panel == 'step1') {
      this.setState({ step1Panel: !this.state.step1Panel, step2Panel: false });
    } else if (panel == 'step2') {
      Scroll.animateScroll.scrollToTop();
      this.setState({ step1Panel: false, step2Panel: !this.state.step2Panel, form1Active: true, form2Active: true });
    }
  }

  selectedDropdownText(location) {
    this.setState({ PreferedLocation: location });
  }

  uploadImageError(value) {
    this.props.actions.bookingUploadImage('', value, this.state.batchID, false);
  }

  uploadFileError() {
    this.props.actions.bookingUploadFile('', '', this.state.batchID, "policereport", "editrequest", false);
  }

  uploadImageChassisError(value) {
    this.props.actions.bookingUploadImage('',value,this.state.batchID, false);
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  uploadImageChassisError2(value) {
    this.props.actions.bookingUploadImage('',value,this.state.batchID, false);
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  uploadImage(e, img) {
    let files = [], fileImgSize = 0, count = 0;
    if (img == 'uploadImage') {
      let fileImageBlob = [];
      let errFileType = false;
      each(e.target.files, (val) => {
        let uniqueId = uniqid();
        let value = val;
        value['fileName'] = uniqueId;
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, fileName: uniqueId, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileImageBlob.push(value);
        fileImgSize = val.size;
        count = count + 1;
        if (val.type == "image/png" || val.type == "image/jpeg") {
        } else {
          errFileType = true;
        }
      });
      if (fileImgSize >= 25000000 || errFileType) {
        this.setState({ uploadImageErrText: true });
      } else if (this.state.imageTotalCount + count > 5) {
        this.setState({ imageCountErrText: true });
      } else {
        this.setState({
          ...this.state,
          uploadedImages: this.state.uploadedImages.concat(files),
          imageTotalCount: this.state.imageTotalCount + count,
          imageCountErrText: false,
          uploadImageErrText: false,
        });
        e.target.value = null;
        let that = this;
        map(fileImageBlob, (value) => {

          let img = new Image();
          img.onload = function () {
            value.height = this.height;
            value.width = this.width;

            that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  let newImageIdArray = cloneDeep(that.state.newImageIdArray);
                  newImageIdArray.push(imageId);
                  that.setState({ newImageIdArray: newImageIdArray });
                  that.props.actions.bookingUploadImage(imageId, value.fileName, "editrequest", that.state.batchID, true);
                }, () => that.uploadImageError(value));
              }
            }, () => that.uploadImageError(value), that.state.referenceId && that.state.referenceId, "image");
          };
          img.onerror = function () {
            // alert( "not a valid file: " + value.type);
          };
          img.src = URL.createObjectURL(value);

        });
      }
    }
    else if (img == 'policeReport') {
      let errFileType = false;
      let filePoliceBlob = [];
      let policeReportImage = false;
      each(e.target.files, (val) => {
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        filePoliceBlob.push(val);
        count = count + 1;
        fileImgSize = val.size;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg") {
          policeReportImage = true;
        } else {
          errFileType = true;
        }
      });

      if (fileImgSize >= 25000000 || errFileType) {
        this.setState({ policeReportErrText: true });
      } else if (count > 1 || this.state.policeReportTotalCount + count > 1) {
        this.setState({ policeReportCountErrText: true });
      } else {
        this.setState({
          ...this.state,
          policeReportImage: policeReportImage,
          uploadedPoliceReport: this.state.uploadedPoliceReport.concat(files),
          policeReportTotalCount: this.state.policeReportTotalCount + 1,
          policeReportCountErrText: false,
          policeReportErrText: false,
        });
        let that = this;
        map(filePoliceBlob, (value) => {
          if (policeReportImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "policereport", "editrequest", true);
                  }, () => that.uploadFileError("policereport"));
                }
              }, () => that.uploadFileError("policereport"), that.state.referenceId && that.state.referenceId, 'policereport');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "policereport", "editrequest", true);
                }, () => this.uploadFileError("policereport"));
              }
            }, () => this.uploadFileError("policereport"), this.state.referenceId && this.state.referenceId, 'policereport');
          }
        });
        //this.props.actions.bookingUploadFile(this.state.uploadedPoliceReport.concat(filePoliceBlob),this.state.batchID,"policereport","editrequest" );
      }
    }

    else if (img == 'rationCard') {
      let errFileType = false;
      let fileRationBlob = [];
      let rationCardImage = false;
      each(e.target.files, (val) => {
        count = count + 1;
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileRationBlob.push(val);
        fileImgSize = val.size;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg") {
          rationCardImage = true;
        } else {
          errFileType = true;
        }
      });
      if (fileImgSize >= 25000000 || errFileType == true) {
        this.setState({ rationCardErrText: true });
      } else if (count > 1 || this.state.rationCardCount + count > 1) {
        this.setState({ rationCardCountErrText: true });
      } else {
        this.setState({
          ...this.state,
          rationCardImage: rationCardImage,
          rationCardErrText: false,
          rationCardCountErrText: false,
          rationCardCount: this.state.rationCardCount + 1,
          uploadedRationCard: this.state.uploadedRationCard.concat(files),
        });
        let that = this;
        map(fileRationBlob, (value) => {
          if (rationCardImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "registrationcard", "editrequest", true);
                  }, () => that.uploadFileError("registrationcard"));
                }
              }, () => that.uploadFileError("registrationcard"), that.state.referenceId && that.state.referenceId, 'registrationcard');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "registrationcard", "editrequest", true);
                }, () => this.uploadFileError("registrationcard"));
              }
            }, () => this.uploadFileError("registrationcard"), this.state.referenceId && this.state.referenceId, 'registrationcard');
          }
        });
        //this.props.actions.bookingUploadFile(this.state.uploadedRationCard.concat(fileRationBlob),this.state.batchID,"registrationcard","editrequest");
      }
    }
    else if (img == 'drivingLicence') {
      let errFileType = false;
      let fileLisenceBlob = [];
      let drivingLicenceImage = false;
      each(e.target.files, (val) => {
        files.push({ name: val.name, path: URL.createObjectURL(val), size: val.size, thumbnail: URL.createObjectURL(val), original: URL.createObjectURL(val) });
        fileLisenceBlob.push(val);
        fileImgSize = val.size;
        count = count + 1;
        if (val.type == "application/pdf" || val.type == "application/docx" || val.type == "application/doc" || val.type == "application/msword") {
        } else if (val.type == "image/png" || val.type == "image/jpeg") {
          drivingLicenceImage = true;
        } else {
          errFileType = true;
        }
      });
      if (fileImgSize >= 25000000 || errFileType == true) {
        this.setState({ drivingLicenceErrText: true });
      } else if (count > 1 || this.state.drivingLicenceCardCount + count > 1) {
        this.setState({ drivingLicenceCountErrText: true });
      } else {
        this.setState({
          ...this.state,
          drivingLicenceImage: drivingLicenceImage,
          uploadedDrivingLicence: this.state.uploadedDrivingLicence.concat(files),
          drivingLicenceCountErrText: false,
          drivingLicenceErrText: false,
          drivingLicenceCardCount: this.state.drivingLicenceCardCount + 1,
        });
        //this.props.actions.bookingUploadFile(this.state.drivingLicence.concat(fileLisenceBlob),this.state.batchID,"drivinglicense","editrequest");
        let that = this;
        map(fileLisenceBlob, (value) => {
          if (drivingLicenceImage) {
            let img = new Image();
            img.onload = function () {
              value.height = this.height;
              value.width = this.width;

              that.props.actions.getSignedURL(value, 'bookings', that.state.batchID, (data) => {
                if (data) {
                  that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                    that.props.actions.bookingUploadFile(imageId, data.url, that.state.batchID, "drivinglicense", "editrequest", true);
                  }, () => that.uploadFileError(""));
                }
              }, () => that.uploadFileError("drivinglicense"), that.state.referenceId && that.state.referenceId, 'drivinglicense');

            };
            img.onerror = function () {
              // alert( "not a valid file: " + value.type);
            };
            img.src = URL.createObjectURL(value);
          } else {
            this.props.actions.getSignedURL(value, 'bookings', this.state.batchID, (data) => {
              if (data) {
                that.props.actions.uploadImage(value, data.signed_request, data.url, data.imageId, true, (imageId) => {
                  that.props.actions.bookingUploadFile(imageId, data.url, this.state.batchID, "drivinglicense", "editrequest", true);
                }, () => this.uploadFileError(""));
              }
            }, () => this.uploadFileError("drivinglicense"), this.state.referenceId && this.state.referenceId, 'drivinglicense');
          }
        });
      }
    }
  }

  cancelUploadImage(val, index, id) {
    let editRequestImageArray = cloneDeep(this.state.editRequestImageArray);
    if (val == 'uploadImage') {
      this.props.actions.deleteUploadedImages(id, "editrequest");
      const uploadedImages = this.state.uploadedImages;
      uploadedImages.splice(index, 1);
      let removeImage = remove(editRequestImageArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        uploadedImages: uploadedImages,
        imageTotalCount: this.state.imageTotalCount - 1,
        editRequestImageArray: removeImage,
      });
    } else if (val == 'policeReport') {
      let editRequestFileArray = cloneDeep(this.state.editRequestFileArray);
      let uploadedPoliceReport = cloneDeep(this.state.uploadedPoliceReport);
      uploadedPoliceReport.splice(index, 1);
      let removePoliceReport = remove(editRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        uploadedPoliceReport: uploadedPoliceReport,
        editRequestFileArray: removePoliceReport,
        policeReportTotalCount: this.state.policeReportTotalCount - 1,
        policeReportId: undefined
      });
      this.props.actions.deleteUploadedFile(id, "editrequest");
    } else if (val == 'rationCard') {
      let editRequestFileArray = cloneDeep(this.state.editRequestFileArray);
      let uploadedRationCard = cloneDeep(this.state.uploadedRationCard);
      uploadedRationCard.splice(index, 1);
      let removeRationCard = remove(editRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        uploadedRationCard: uploadedRationCard,
        editRequestFileArray: removeRationCard,
        rationCardCount: this.state.rationCardCount - 1,
        registrationId: undefined
      });
      this.props.actions.deleteUploadedFile(id, "editrequest");
    } else if (val == 'drivingLicence') {
      let editRequestFileArray = cloneDeep(this.state.editRequestFileArray);
      let uploadedDrivingLicence = cloneDeep(this.state.uploadedDrivingLicence);
      uploadedDrivingLicence.splice(index, 1);
      let removeDrivingLicence = remove(editRequestFileArray, (n) => {
        return n.id != id;
      });
      this.setState({
        ...this.state,
        uploadedDrivingLicence: uploadedDrivingLicence,
        editRequestFileArray: removeDrivingLicence,
        drivingLicenceCardCount: this.state.drivingLicenceCardCount - 1,
        drivingLicenceId: false,
      });
      this.props.actions.deleteUploadedFile(id, "editrequest");
    }
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id
    });
    this.props.changeCarProfile(carProfile);
    this.errors['selectedCarProfileId'] = false;
    this.formData['selectedCarProfileId'] = true;
  }

  timePicker(value) {
    if (value && value.hours() && value.minutes()) {
      if (moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue) {
        if (value.startOf('minutes') < moment().add(1, 'hours').startOf('minutes')) {
          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        } else {
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        }
      } else {
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
      }
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if (moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()) {
      if (this.state.selectedTimeValue < moment().add(1, 'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });
      } else {
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
  }

  jobDetails(data) {
    if (data.target.value.length) {
      this.errors['jobDetails'] = false;
      this.formData['jobDetails'] = true;
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
    else {
      this.errors['jobDetails'] = true;
      this.formData['jobDetails'] = false;
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  cancelEdit() {
    //this.props.router.push(`request/${20171016141948}`);
    window.history.back();
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  onChasisChange(value) {
    this.setState({
      chassis_number: value!= " " ? value : ""
    });
  }

  requestQuotes() {
    const { selectedCarRepairDataArray, actions, bookingRequestId } = this.props;
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true, form2Active: true });
      return;
    } else if (validForm && bookingRequestId && this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng) {
      this.setState({ submissionError: false, isButtonLoading: true });
      let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
      let momentTimeStamp = moment.utc(timeStamp).format();
      let payload = {
        "batch_id": this.state.batchID,
        "serviceid": 3,
        "chassis_number": this.state.chassis_number.toString(),
        "subserviceid": selectedCarRepairDataArray,
        "vehicleid": this.state.selectedCarProfileId,
        "details": this.state.jobDetails,
        "userpreffereddatetime": momentTimeStamp,
        "location": this.state.selectedLocation.formatted_address,
        "latitude": this.state.selectedLocation.lat.toString(),
        "longitude": this.state.selectedLocation.lng.toString(),
        "isurgentrequest": this.state.checked,
        "repairreason": this.state.accidentRadioBtn ? "accident" : "general",
        "images": this.state.newImageIdArray,
      };
      if (this.props.garageProfileDetails) {
        payload["vendorid"] = this.props.garageProfileDetails.id;
      }
      actions.editBookingRequest(this.props.bookingRequestId, payload);
    }
  }

  // The images for Chassis Number goes here. Create a separate set of arrays to
  // to upload the chassis number

  chassisFileUpload(e) {
    let files = [],
      fileBlob = [],
      fileImgSize = 0,
      errFileType = false,
      errFileSize = false;
    each(e.target.files, val => {
      fileImgSize = val.size;
      if (val.type == "image/png" || val.type == "image/jpeg") {
        if (fileImgSize >= 25000000) {
          errFileSize = true;
        } else {
          files.push({
            name: val.name,
            path: URL.createObjectURL(val),
            original: URL.createObjectURL(val),
            large: URL.createObjectURL(val),
            size: val.size,
            thumbnail: URL.createObjectURL(val)
          });
          val.localUniqueId = uniqid();
          val.isUploaded = false;
          val.isFailed = false;
          fileBlob.push(val);
        }
      } else {
        errFileType = true;
      }
    });

    if (this.state.chassisImages.length + files.length > 5) {
      files = [];
      fileBlob = [];
      this.setState({ imageMaxLimitError: true }); // Might have to change this to seperate out chassis number and profile images
    } else {
      this.setState({ imageMaxLimitError: false }); // Might have to change this to seperate out chassis number and profile images
    }

    this.setState({
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize
    });

    e.target.value = null;
    // this.props.actions.uploadCarProfilePicture(fileBlob, this.props.carProfileReducer.newCarProfileData.batch_id, this.state.chassisImages + files.length);
    let chassisImages = [...this.state.chassisImages];
    let that = this;
    map(fileBlob, value => {
      let img = new Image();
      img.onload = function() {
        value.height = this.height;
        value.width = this.width;

        that.props.actions.getSignedURL(
          value,
          "vehicle",
          that.props.selectedCarProfile.batchId,
          data => {
            if (data) {
              that.props.actions.uploadImage(
                value,
                data.signed_request,
                data.url,
                data.imageId,
                true,
                imageId => {
                  let newImagesArray = cloneDeep(that.state.newImagesArray);
                  newImagesArray.push(imageId);
                  that.setState({ newImagesArray: newImagesArray });
                  that.props.actions.uploadCarProfilePictureTest(
                    value,
                    that.props.selectedCarProfile.batchId,
                    imageId,
                    true,
                    'CSN'
                  );
                },
                () => that.uploadImageChassisError(value)
              );
            }
          },
          () => that.uploadImageChassisError2(value),
          that.props.selectedCarProfile && that.props.selectedCarProfile.id,
          'chassisNumber'
        );
      };

      chassisImages.push({
        isUploaded: false,
        isFailed: false,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        localUniqueId: `${value.localUniqueId}_chassisNumber`,
        isCover: false
      });
      // Once the image is uploaded Successfully need to reset the ChassisPopUp value
      // so that its not displayed anymore
      this.setState({
        showChasis: false
      });
      img.onerror = function() {
        // alert( "not a valid file: " + value.type);
        this.setState({
          showChasis: true
        });
      };
      img.src = URL.createObjectURL(value);
    });
    this.setState({
      chassisImages,
      uploadImageTypeErr: errFileType,
      uploadImageSizeErr: errFileSize                           
    });
  }


  render() {
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails, bookedRequest } = this.props;
    const CSNUploadView = this.imageThumbnails(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const CSN_w_o_UploadView = this.imageThumbnailsForChassis(
      this.state.chassisImages ? this.state.chassisImages : []
    );
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];
    const uploadedImages = map(this.state.uploadedImages, (img, index) => {
      if (!img.fileName) {
        return (
          <div className='upload-box-wrapper box-shadow' >
            <div className="uploaded-images">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("uploadImage", index, img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              <img index={index} src={img.small} onClick={() => {
                this.setState({ showModal: true, imageStartIndex: index, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
              }} />
            </div>
          </div>
        );
      } else if (!img.uploaded) {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              <img src={img.path} />
              <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />
            </div>
          </div>
        );
      } else {
        return (
          <div className='upload-box-wrapper box-shadow' key={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("uploadImage", index, img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              <div className="upload-images">
                <img src={img.path} alt="" onClick={() => {
                  this.setState({ showModal: true, imageStartIndex: index, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
                }} />
              </div>
            </div>
          </div>
        );
      }
    });

    const uploadedRationCard = map(this.state.uploadedRationCard, (img, index) => {
      if (!img.size) {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("rationCard", index, img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              {/* <img src={img.small} /> */}
              <img src={img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? `${imageBasePath}/images/pdf.png` : img.small} alt="" onClick={() => {
                { img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? window.open(img && img.original, "_blank") : this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 1, drivingReportStartIndex: 0 }); }
              }} />
            </div>
            {/*<h5>{img.name}</h5>*/}
          </div>
        );
      } else {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              {this.state.registrationId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('rationCard', index, this.state.registrationId) }}>
                <i className="mdi mdi-close" />
              </span>}
              {!this.state.rationCardImage ? <div className="upload-images">
                <img src={`${imageBasePath}/images/pdf.png`} alt="" onClick={() => {
                  img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' && window.open(img && img.original, "_blank");
                }} />
                {!this.state.registrationId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div> :
                <div className="upload-images">
                  <img src={img.path} alt="" onClick={() => {
                    this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 1, drivingReportStartIndex: 0 });
                  }} />
                  {!this.state.registrationId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
                </div>}
            </div>
          </div>
        );
      }
    });

    const uploadedPoliceReport = map(this.state.uploadedPoliceReport, (img, index) => {
      if (!img.size) {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("policeReport", index, img.id); }}>
                <i className="mdi mdi-close" />
              </span>
              <img src={img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? `${imageBasePath}/images/pdf.png` : img.small} alt="" onClick={() => {
                {
                img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? window.open(img && img.original, "_blank") :
                  this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 1, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
                }
              }} />
            </div>
          </div>
        );
      }
      else {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              {this.state.policeReportId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('policeReport', index, this.state.policeReportId) }}>
                <i className="mdi mdi-close" />
              </span>}
              {!this.state.policeReportImage ? <div className="upload-images">
                <img src={`${imageBasePath}/images/pdf.png`} alt="" onClick={() => {
                  img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' && window.open(img && img.original, "_blank");
                }} />
                {!this.state.policeReportId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div> :
                <div className="upload-images">
                  <img src={img.path} alt="" onClick={() => {
                    this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 1, registrationReportStartIndex: 0, drivingReportStartIndex: 0 });
                  }} />
                  {!this.state.policeReportId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
                </div>
              }
            </div>
          </div>
        );
      }
    });

    const uploadedDrivingLicence = map(this.state.uploadedDrivingLicence, (img, index) => {
      if (!img.size) {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              <span className="cancel-image" onClick={() => { this.cancelUploadImage("drivingLicence", index, img.id) }}>
                <i className="mdi mdi-close" />
              </span>
              {/* <img src={img.small} /> */}
              <img src={img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? `${imageBasePath}/images/pdf.png` : img.small} alt="" onClick={() => {
                {
                img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' ? window.open(img && img.original, "_blank") :
                  this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 1 });
                }
              }} />
            </div>
            {/*<h5>{img.name}</h5>*/}
          </div>
        );
      } else {
        return (
          <div className='upload-box-wrapper box-shadow' index={index}>
            <div className="uploaded-image">
              {this.state.drivingLicenceId && <span className="cancel-image" onClick={() => { this.cancelUploadImage('drivingLicence', index, this.state.drivingLicenceId) }}>
                <i className="mdi mdi-close" />
              </span>}
              {!this.state.drivingLicenceImage ? <div className="upload-images">
                <img src={`${imageBasePath}/images/pdf.png`} alt="" onClick={() => {
                  img.small && img.small.split('.') && img.small.split('.').pop() == 'pdf' && window.open(img && img.original, "_blank");
                }} />
                {!this.state.drivingLicenceId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
              </div> :
                <div className="upload-images">
                  <img src={img.path} alt="" onClick={() => {
                    this.setState({ showModal: true, imageStartIndex: 0, policeReportStartIndex: 0, registrationReportStartIndex: 0, drivingReportStartIndex: 1 });
                  }} />
                  {!this.state.drivingLicenceId && <img className="img-loader-on-upload" src={`${imageBasePath}/images/loader_white.gif`} alt="Upload in progress" />}
                </div>}
            </div>
          </div>
        );
      }
    });

    return (
      <div className="panel-section car-wash car-category-wrapper p-panel">
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => { !this.state.statusActive && this.hidePanel('step1'); }}>
            <span className={this.state.form1Active ? "n-step active" : "n-step"}>1</span>
            <h4>Select Car Repair Type</h4>
            <i className={this.state.step1Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {servicesFetched ? this.state.step1Panel && <div className="panel-content">
            <div className="row">
              <div className="col-md-6 pad0">
                <div className="search-box">
                  <TextInput value={this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => { this.props.searchView(e); this.setState({ ...this.state, searchValue: e }) }} />
                  <i className="mdi mdi-magnify" />
                </div>
              </div>
            </div>
            {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 padLeft0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState />
            }
            {this.props.viewBlock.length > 0 && <div className="next-button">
              <Button disabled={categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={13} label="Next" btnCallBack={() => { this.hidePanel('step2') }} />
            </div>}
          </div> :
            <PageLoader />}
        </section>
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => { categoriesSelectedCount > 0 && this.hidePanel('step2') }}>
            <span className={this.state.form2Active ? "n-step " : "n-step active"}>2</span>
            <h4>Create A Car Repair Request</h4>
            <i className={this.state.step2Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {this.state.step2Panel && categoriesSelectedCount > 0 && <div className="panel-content">
            <div className="row">
              <div className="col-md-12 left pad0">
                <div className="form-section">
                  <h4 className="panel-sub-title">Select Car Profile</h4>
                  <div className="model-select form-group">
                    <h1 className="panel-sub-title">{selectedCarProfile && selectedCarProfile.name}</h1>
                  </div>
                </div>
                {/*<div className="form-section">
                  <h4 className="panel-sub-title">Chassis Number</h4>
                  <div className="model-select form-group">
                    <h1
                      className="panel-sub-title"
                      style={{
                        fontFamily: "Century Gothic",
                        fontSize: "16px",
                        lineHeight: "1em",
                        color: "#717982",
                      }}
                      >{(selectedCarProfile && selectedCarProfile.chassisNumber)? selectedCarProfile.chassisNumber : (CSNUploadView.length ? CSNUploadView : '-')}
                      </h1>
                  </div>
                    </div>*/}
                    <div className="row car-profile">
                    <div className="col-md-6 padLeft0">
    
    
                      <TextInput
                        label={this.state.chassis_number.trim()!= ""?"":"Chassis Number"}
                        name="chassis_number"
                        type="text"
                        customClass="noMargin"
                        disabled={(selectedCarProfile && selectedCarProfile.chassisNumber.trim()!= "") || CSNUploadView.length>0 || CSN_w_o_UploadView.length>0}
                        value={this.state.chassis_number!== "" ? this.state.chassis_number : " "}
                        onChange={this.onChasisChange.bind(this)}
                      />
                      <label className="mobChassis">
                          The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
                        </label>
                    </div>
                     
                     
                     {((this.state.chassis_number).trim() === "") &&
                    
                    <div className="col-md-6 padRight0">
                      {
                        <div className="or-text-vert-job">
                      <span className="or-text-vert-span or-text-chassis">OR</span>
                        <div>
                          <h4 className="panel-sub-title">Upload images</h4>
                          <div className="img-uploads">
                            {(this.state.uploadFlag) && this.state.chassisImages.length < 1 ?
                              <Upload
                                margin={false}
                                id="chassisFileUpload"
                                fileUpload={(e) => this.chassisFileUpload(e)} /> : <div />}
                            {this.state.uploadFlag ? CSNUploadView : CSN_w_o_UploadView}
    
                          </div>
                          <div className="mobChassisFileSizeJob">
                            {this.state.uploadFlag &&  'The file has to be below 25MB. Please use JPG or PNG'}
                          </div>
                          <span className={this.state.uploadImageTypeErr ? "image-upload-error " : "image-upload-error  hide"}>
                            <p>Sorry, only JPG or PNG is accepted.
                                                    Try again with image file.</p>
                            <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageTypeErr: false })} />
                          </span>
                          <span className={this.state.uploadImageSizeErr ? "image-upload-error " : "image-upload-error  hide"}>
                            <p>Sorry, uploaded image exceeds the file size limit of 25mb.
                                                    Try again with another image.</p>
                            <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageSizeErr: false })} />
                          </span>
                          <span className={this.state.imageMaxLimitError ? "image-upload-error " : "image-upload-error  hide"}>
                            <p>Maximum allowed limit is 5.</p>
                            <i className="mdi mdi-close" onClick={() => this.setState({ imageMaxLimitError: false })} />
                          </span>
                        </div>
                      </div>}
                    </div>
                  }
                  </div>    
                <div className="form-section">
                  <h4 className="panel-sub-title">Reason For Repair</h4>
                  <div className="radio-btn">
                    <div className="radio-style">
                      <label>
                        <input type="radio" name="radio" checked={this.state.accidentRadioBtn} onChange={() => this.setState({ accidentRadioBtn: true, generalRadioBtn: false })} />
                        <i className="mf-radio-button" /><span>Accident</span>
                      </label>
                    </div>
                    <div className="radio-style">
                      <label>
                        <input type="radio" name="radio" checked={this.state.generalRadioBtn} onChange={() => this.setState({ accidentRadioBtn: false, generalRadioBtn: true })} />
                        <i className="mf-radio-button" /><span>General</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h4 className="panel-sub-title">Add Notes</h4>
                  <div className={this.errors.jobDetails && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                    <input type="text" defaultValue={bookedRequest && bookedRequest.details} className="jd-text" placeholder="Additional information you can provide" onChange={(e) => { this.jobDetails(e); }} />
                    {this.errors.jobDetails && this.state.submissionError && <span className="error-text">Enter Job Details</span>}
                  </div>
                </div>
                <div className="form-section">
                  <h4 className="panel-sub-title">Prefered Time & Date</h4>
                  <div className="toggleBtn">
                    <span> This is an urgent request ! </span>
                    <ToggleSwitch
                      checked={this.state.checked}
                      size="small"
                      onChange={(event) => {
                        this.setState({ checked: !this.state.checked });
                      }}
                    />
                  </div>
                  {!this.state.checked && <div className="row date-time">
                    <div className="col-md-6 padLeft0 timeDate_grid">
                      <DatePicker
                        selected={this.state.selectedDate}
                        onChange={this.handleChange}
                        minDate={moment().add(1, 'hours')}
                      />
                    </div>
                    <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                      <TimePicker
                        showSecond={false}
                        className="custom-timepicker"
                        format={'h:mm a'}
                        onChange={this.timePicker.bind(this)}
                        defaultValue={this.state.now}
                        use12Hours
                      />
                      {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                    </div>
                  </div>}
                </div>
                <div className="form-section">
                  <h4 className="panel-sub-title">Prefered location</h4>
                  <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                    <div className="location-dd header-search">
                      <LocationSearchBox style={style} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined} />
                    </div>
                    {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                  </div>
                  {this.state.selectedLocation && <div className="map-panel">
                    <div className="gmaps">
                      <Gmaps
                        center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                        zoom={12}
                        setCenter={true}
                        markers={markerLocation}
                        containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                        mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                      />
                    </div>
                  </div>}
                </div>
              </div>
              <div className="col-md-12 right pad0">
                <div className="form-section uploads">
                  <div className="row">
                    <h4 className="panel-sub-title">upload a image (Optional)</h4>
                    <div className="model-select upload">
                      {this.state.imageTotalCount < 5 && <Upload id="uploadImage" fileUpload={(e) => { this.uploadImage(e, 'uploadImage') }} />}
                      {uploadedImages}
                    </div>
                  </div>
                  <span className={this.state.uploadImageErrText ? "image-upload-error" : "image-upload-error hide"}>
                    <p>Sorry, your image format is wrong or image size exceeds the limit of 20mb. Try again with another image</p>
                    <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageErrText: false })} />
                  </span>
                  <span className={this.state.imageCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                    <p>Only 5 images can upload</p>
                    {<i className="mdi mdi-close" onClick={() => this.setState({ imageCountErrText: false })} />}
                  </span>
                </div>
                {this.state.accidentRadioBtn && <div className="clearfix">
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">police report</h4>
                      <div className="model-select upload">
                        {this.state.policeReportTotalCount < 1 && <Upload id="policeReport" fileUpload={(e) => { this.uploadImage(e, 'policeReport') }} />}
                        {/* {policeReportView} */}
                        {uploadedPoliceReport}
                      </div>
                    </div>
                    <span className={this.state.policeReportErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ policeReportErrText: false })} />
                    </span>
                    <span className={this.state.policeReportCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Police Report can upload</p>
                      {<i className="mdi mdi-close" onClick={() => this.setState({ policeReportCountErrText: false })} />}
                    </span>
                  </div>
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">Registration card</h4>
                      <div className="model-select upload">
                        {this.state.rationCardCount < 1 && <Upload id="rationCard" fileUpload={(e) => { this.uploadImage(e, 'rationCard') }} />}
                        {uploadedRationCard}
                      </div>
                    </div>
                    <span className={this.state.rationCardErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ rationCardErrText: false })} />
                    </span>
                    <span className={this.state.rationCardCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Registration card can upload</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ rationCardCountErrText: false })} />
                    </span>
                  </div>
                  <div className="form-section uploads">
                    <div className="row">
                      <h4 className="panel-sub-title">driving licence</h4>
                      <div className="model-select upload">
                        {this.state.drivingLicenceCardCount < 1 && <Upload id="drivingLicence" fileUpload={(e) => { this.uploadImage(e, 'drivingLicence') }} />}
                        {uploadedDrivingLicence}
                      </div>
                    </div>
                    <span className={this.state.drivingLicenceErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Sorry, your file format is wrong or file size exceeds the limit of 20mb. Try again with another image</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ drivingLicenceErrText: false })} />
                    </span>
                    <span className={this.state.drivingLicenceCountErrText ? "image-upload-error" : "image-upload-error hide"}>
                      <p>Only one Driving Licence can upload</p>
                      <i className="mdi mdi-close" onClick={() => this.setState({ drivingLicenceCountErrText: false })} />
                    </span>
                  </div>
                </div>}
              </div>
            </div>
            <div className="next-button clearfix editPage-button-section">
              <Button btnType="transparent" btnSize="sm" fontSize={14} label="Cancel" btnCallBack={this.cancelEdit.bind(this)} />
              <Button btnType="red" btnSize="sm" fontSize={13} label="Save" btnCallBack={this.requestQuotes.bind(this)} isButtonLoading={this.state.isButtonLoading} />
            </div>
          </div>}
        </section>

        {this.state.imageStartIndex > -1 ?
          <LightBox
            showUploadIcon={this.state.uploadedImages.length < 5}
            actions={this.props.actions}
            images={this.state.uploadedImages}
            imageStartIndex={this.state.imageStartIndex}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            cancelUploadImage={this.cancelUploadImage}
            fromCreateRequest={"uploadImage"}
            reportId={"fromImage"}
          />
          : ''}
        {this.state.policeReportStartIndex && this.state.policeReportStartIndex > 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.uploadedPoliceReport}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"policeReport"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.policeReportId}
            showUploadIcon={this.state.policeReportTotalCount < 1}
          />
          : ''}
        {this.state.registrationReportStartIndex && this.state.registrationReportStartIndex > 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.uploadedRationCard}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"rationCard"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.registrationId}
            showUploadIcon={this.state.rationCardCount < 1}
          />
          : ''}
        {this.state.drivingReportStartIndex && this.state.drivingReportStartIndex > 0 ?
          <LightBox
            actions={this.props.actions}
            images={this.state.uploadedDrivingLicence}
            imageStartIndex={0}
            showModal={this.state.showModal}
            isEditable={true}
            closeModal={this.closeModal.bind(this)}
            fileUpload={this.uploadImage}
            fromCreateRequest={"drivingLicence"}
            cancelUploadImage={this.cancelUploadImage}
            reportId={this.state.drivingLicenceId}
            showUploadIcon={this.state.drivingLicenceCardCount < 1}
          />
          : ''}
      </div>
    );
  }
}

export default EditRepaiSteps;
