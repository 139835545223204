import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class Deals extends Component {

  constructor(props, context) {
    super(props, context);



  }
  componentDidMount() { setTimeout(() => {window.dispatchEvent(new Event('resize')) }, 6000); }





  render() {


    const { promotionalOffersData, router } = this.props;

    console.log("router",router);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      adaptiveHeight: true,
      slidesToScroll: 1,
      arrows:false,
      responsive: [
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 1400,
          settings: { slidesToShow: 3 }
        },
      ]
    };
    const dealUrl = [
      {
        dealImg: `${imageBasePath}/images/regular-service.png`,
        text:"Regular Service",
        url: "car-service"
      }, {
        dealImg: `${imageBasePath}/images/steam-wash.png`,
        text:"Steam Wash",
        url: "car-wash"
      },{
        dealImg: `${imageBasePath}/images/acs.png`,
        text:"AC Sanitization",
        url: "car-repair"
      }, {
        dealImg: `${imageBasePath}/images/brake-service.png`,
        text:"Brake Service",
        url: "car-repair"
      },{
        dealImg: `${imageBasePath}/images/fdp.png`,
        text:"Full Detailing Package",
        url: "car-wash"
      },
    ];

/*


    let promoCodesHtml =  promotionalOffersData.map((promoCode, index) => {
      return (
        <div className="col-md-4" key={index}  onClick={(e)=> this.onOfferClick(promoCode.services[0].parentId,index) && e.preventDefault()}  >
          <div className="deal-img">
            <img src={  promoCode.images && promoCode.images[0] ?  promoCode.images[0].medium : `${imageBasePath}/images/default-offer-image.png` } alt="" />
          </div>
        </div>
      );
    });

*/


    let promoCodesHtml =  dealUrl.map((promoCode, index) => {
      return (
        <div className="deal-box " key={index} onClick={() => {  router.push(`${appBasePath}/`+promoCode.url) }}   >
          <div className="deal-img">
            <img src={promoCode.dealImg} alt="" />
            <span className="deals-name">{promoCode.text}</span>
          </div>
        </div>
      );
    });



    return (
      <div className="row" >
        <div className="deals-section " id="deals-section">
          <div className="dashboard-heading">
            <h5>Recommended Services </h5>
          </div>
          <div className="col-md-12 Deals-layout dashboard-row">
            {promoCodesHtml ?
              <Slider ref="slick" {...settings} >
                {promoCodesHtml}
              </Slider>
            :""}
          </div>
        </div>
      </div>
    );
  }
}
