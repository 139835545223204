import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class OfferEmptyState extends Component {
    render() {
        return (
            <div className="empty-state-holder">
                <span className="empty-state-content">
                    <img src={`${imageBasePath}/images/offers_empty.png`} alt="" />
                    <span className="text">We're sorry. No offers available at this time!<br/> Please check back later for MORE.</span>
                </span>
            </div>
        );
    }
}
