import React, { Component } from 'react';
import Header from '../common/Header';
import PlusSidebar from './PlusSidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Button from '../common/Button';
import CarProfileBox from './CarProfileBox';
import map  from 'lodash/map';
import size  from 'lodash/size';
import CreateRequestPopup from '../common/CreateRequestPopup';
import PageLoader from '../common/PageLoader';
import CarProfileEmptyState from '../EmptyState/CarProfileEmptyState';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import {appBasePath, imageBasePath, validationMessage} from '../../constants/staticData';
import CustomModal from "../common/CustomModal";
import {FormControl, FormGroup, MenuItem, Modal} from "react-bootstrap";
import findIndex from "lodash/findIndex";
import CustomScroll from "react-custom-scroll";
import TextInput from "../common/TextInput";
import range from "lodash/range";
import has from "lodash/has";
import {validateFormSubmit} from "../../helpers";
import Upload from "../common/Upload";
import ToggleSwitch from "@trendmicro/react-toggle-switch";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import LocationSearchBox from "../common/LocationSearchBox";
import Gmaps from "../MyRequest/Gmaps";
import SelectDropdown from '../common/SelectDropdown';

export default class DashboardPlusAdditional extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);

    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });



    const carSelected = this.props.bookingRequestReducer.carSelected

    const cylinderOption = [{name:'2',key: 1}, {name: '4',key: 2}, {name: '6',key: 3},{name: '8',key: 4},{name: '10',key: 5},{name: '12',key: 6}]
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false,
      isLoading: (props.carProfileReducer && props.carProfileReducer.carProfiles && size(props.carProfileReducer.carProfiles) > 0) ? false : true,
      bookServiceModalVisible: false,
      createCarProfileModal: false,
      engineOilModal: false,
      selectedDate: moment().add(1,'hours'),
      selecedCarProfileId: '',
      submissionError: false,
      "selectedYear": "Year",
      "allYears": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      "selectedCylinder":carSelected.hasOwnProperty('cylinder')?cylinderOption.filter((cy) => cy.name ==carSelected.cylinder)[0]:"",
      chassis_number: this.props.bookingRequestReducer.carSelected.hasOwnProperty('chassisNumber')? this.props.bookingRequestReducer.carSelected.chassisNumber:"",
      mileage: this.props.bookingRequestReducer.carSelected.hasOwnProperty('mileage')? this.props.bookingRequestReducer.carSelected.mileage:"",
      now: moment().add(1, 'hours'),
      selectedCustomServiceId: this.props.location  && this.props.location.state && this.props.location.state.selectedCustomServiceId ? this.props.location.state.selectedCustomServiceId : null,
      selectedCustomAdditionalCost: this.props.location  && this.props.location.state && this.props.location.state.selectedCustomAdditionalCost ? this.props.location.state.selectedCustomAdditionalCost : 0,
    };


    this.initialFormData = {
      "selectedLocation": '',
      "cylinder": carSelected.hasOwnProperty('cylinder')?carSelected.cylinder:"",
      "timeValid": moment().add(1, 'hours'),
      // "mileage":carSelected.hasOwnProperty('mileage')? carSelected.mileage:"",
      // "model": '',
      // "model_id": '',
      // "year": '',
      // "plate_no": '',
      // "serviceid": 3,
      // "terms": true,
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      //"name": false,
      "selectedLocation": false,
      "cylinder": false,
      "timeValid": false,
      "chassis_number": false,
      // "mileage": false
      // "model_id": false,
      // "year": false,
      // "plate_no": false,
      // "serviceid": false,
    };
    this.showBookServiceModal = this.showBookServiceModal.bind(this);
    this.showBookNewCarModal = this.showBookNewCarModal.bind(this);
    this.chooseCarDetails = this.chooseCarDetails.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showOilModal = this.showOilModal.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentWillMount() {


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


    this.props.actions.getCarProfileList(this.props.router);
    if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
      this.props.actions.getManufacturers();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.carProfileReducer.carProfiles) {
      this.setState({ 'isLoading': false });
    }

    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;

      this.setState({
        allManufacturer: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        filteredCarModelList: carModel,
      });
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  showOilModal() {
    this.setState({ engineOilModal: true });
  }

  closeModal() {
    this.setState({ engineOilModal: false })
  }

  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,

    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  hideModalCallback() {
    this.setState({ bookServiceModalVisible: false });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  showBookNewCarModal() {

    this.setState({
      ...this.state,
      createCarProfileModal: true,
    });

  }
  selectCarProfile(e,id) {

    this.setState({
      ...this.state,
      selectedCarProfileId: id,
    });

  }


  onSubmit(e) {
    e.preventDefault();

  }


  timePicker(value){
    if(value && value.hours() && value.minutes()){
      if(moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue){
        if(value.startOf('minutes') < moment().add(1,'hours').startOf('minutes')) {
          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              now : value,
              minutesValue: value.minutes(),
            });
        } else{
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              now : value,
              minutesValue: value.minutes(),
            });
        }
      } else{
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            now : value,
            minutesValue: value.minutes(),
          });
      }
    }
  }

  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }

  showBookServiceModal(e, id) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      ...this.state,
      selecedCarProfileId: id,
      'bookServiceModalVisible': !this.state.bookServiceModalVisible
    });
  }

  jobDetails(data) {
    if (data.target.value.length) {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
    else {
      this.setState({
        ...this.state,
        jobDetails: data.target.value,
      });
    }
  }

  onChasisChange(value) {

    this.errors['chassis_number'] = false,
      this.setState({
        chassis_number: value!= " " ? value : "",
        fillChassis: false
      });
  }

  mileageUpdate(value) {
    // this.formData['mileage'] = value;
    this.errors['mileage'] = false
    this.setState({
      mileage: value!= " " ? value : ""
    });

  }

  createPlusRequest(){
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      let packageSelected = this.props.bookingRequestReducer.packageSelected;

      let payload = {
        "user_preferred_starttime": this.state.selectedDate,
        "latitude": String(formData.selectedLocation.lat),
        "longitude": String(formData.selectedLocation.lng),
        "vehicleid": this.props.bookingRequestReducer.carSelected.id,
        "images":packageSelected.image,
        "mileage":this.state.mileage,
        "details": packageSelected.description,
        "cylinder": Number(this.state.selectedCylinder.name),
        "chassis_number": this.state.chassis_number,
        "pickup_info": {
          "preferred_starttime": this.state.selectedDate,
          "preferred_endtime":  new Date(new Date(this.state.selectedDate).setHours(new Date(this.state.selectedDate).getHours() + 2)),
          "address": formData.selectedLocation.formatted_address
        },
        "drop_info": {
          "preferred_starttime": this.state.selectedDate,
          "preferred_endtime":  new Date(new Date(this.state.selectedDate).setHours(new Date(this.state.selectedDate).getHours() + 2)),
          "address": formData.selectedLocation.formatted_address
        },
        "packages": [{
          "package_id": packageSelected.pricings[0].packageId,
          "package_pricing_id": packageSelected.pricings[0].id,
          "package_pricing_customization_id": this.state.selectedCustomServiceId,
          "cost": packageSelected.pricings[0].cost + this.state.selectedCustomAdditionalCost
        }]
      }

      // let payload = {
      //   "user_preferred_starttime": this.state.selectedDate,
      //   "latitude": String(formData.selectedLocation.lat),
      //   "longitude": String(formData.selectedLocation.lng),
      //   "vehicleid":packageSelected.id,
      //   "images":packageSelected.image,
      //   "mileage":formData.mileage,
      //   "cylinder": this.state.selectedCylinder.name,
      //   "chassis_number": formData.chassis_number,
      //   "packages":
      //   [{ // Basically this should have 1 normal package and all other wrapper packages
      //     "package_id": packageSelected.pricings[0].packageId,
      //     "package_pricing_id": packageSelected.pricings[0].id,
      //     "package_pricing_customization_id": packageSelected.pricings[0].customizations.length > 0?packageSelected.pricings[0].customizations[0].id:packageSelected.pricings[0].id,
      //     "cost": packageSelected.pricings[0].cost + (packageSelected.pricings[0].customizations.length > 0?packageSelected.pricings[0].customizations[0].additionalCost:0)
      //   }],
      //   "pickup_info": { // Special Service => PICKUP
      //     "preferred_starttime": "2021-01-20T18:25:43.511Z",
      //     "preferred_endtime": "2021-01-22T22:25:43.511Z",
      //     "address": "300, new Street, London Bridge"
      //   },
      //   "drop_info": { // Special Service => DROP
      //     "preferred_starttime": "2021-01-21T18:25:43.511Z",
      //     "preferred_endtime": "2021-01-23T19:25:43.511Z",
      //     "address": "300, new Street, London Bridge"
      //   }
      // }
      this.props.actions.createPlusRequest(payload,this.props.router);
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  select(selectedData){
    this.errors['cylinder'] = false;
    this.formData['cylinder'] = location;
    this.setState({selectedCylinder:selectedData});
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if(moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()){
      if(this.state.selectedTimeValue < moment().add(1,'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });
      }else{
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    }else{
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
  }
  render() {
    const { router, carProfileReducer, commonReducer, actions,bookingRequestReducer } = this.props;
    const cylinderOption = [{name:'2',key: 1}, {name: '4',key: 2}, {name: '6',key: 3},{name: '8',key: 4},{name: '10',key: 5},{name: '12',key: 6}]

    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
        boxShadow: '0 0 4px 0 rgba(134,134,134,0.5)',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];



    return (
      <div>
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        
        notificationCount={2} commonReducer={commonReducer} notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader">
          {/*Sidebar*/}

          <PlusSidebar createPlusRequest={()=>this.createPlusRequest()} page="additional" router={this.props.router}  />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="plus-desktop">
            <div className="col-">
              <div className="page-sec-header quick-booking-header">
                <div className="padwrapper padwrap-title padwrap-add-title">
                  <span className="back-button" onClick={() =>  this.props.router.push(`${appBasePath}/plus`)}><i className="mdi mdi-arrow-left "></i></span>
                  <h4>Additional Information</h4>

                </div>
              </div>
              <div className="carcility-plus-section myCar-View myCar-View-additional">
                <div className="mycar-block">
                  <div className="inSection collapse-panel">

                    <div className="padwrapper padwrap">
                      <div className=" panel-content plus-additional-section sameui">

                        <div className="row">
                          <div className="col-md-12 pad0">

                            <div className="row">
                              <div className="col-md-12 plus-cylinder-main">

                                <h4 className="panel-sub-title">Select Cylinder & Mileage</h4>
                                <div id="plus-cyliner-select"className="plus-cylinder">
                                  <div className="col-md-6 model-select-plus">
                                    <SelectDropdown data={cylinderOption} customPlaceholder="Choose Cylinder" selectedCarProfile={this.state.selectedCylinder!="" && this.state.selectedCylinder} select={this.select.bind(this)} />
                                    {this.errors.cylinder && this.state.submissionError && <span className="error-text">Select Cylinder</span>}
                                  </div>
                                  <div className="col-md-6 mileage-text mileage-text-pad">
                                    <TextInput
                                      label="Enter Mileage (Optional)"
                                      name="mileage"
                                      type="text"
                                      customClass="noMargin"
                                      value={this.state.mileage}
                                      onChange={(e)=>this.mileageUpdate(e)}
                                    />
                                    {/* {this.errors.mileage && this.state.submissionError && <span className="error-text">Please Enter Mileage</span>} */}
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="row car-profile">
                              <div className="col-md-6 padLeft0">
                                <div className="form-section">
                                  <h4 className="panel-sub-title">Prefered location</h4>
                                  <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                                    <div className={"location-dd header-search ws-form-section"}>
                                      <LocationSearchBox placeholder="Location" style={style} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined}/>
                                    </div>
                                    {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                                  </div>
                                  {this.state.selectedLocation && <div className="map-panel">
                                    <div className="gmaps">
                                      <Gmaps
                                        center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                                        zoom={12}
                                        setCenter={true}
                                        markers={markerLocation}
                                        containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                                        mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                                      />
                                    </div>
                                  </div>}
                                </div>

                              </div>

                            </div>


                            <div className="form-section">
                              <h4 className="panel-sub-title">Pick up Date & Time</h4>

                              {!this.state.checked && <div className="row date-time date-time-align">
                                {!this.state.checked ?
                                  <div className="col-md-6 padLeft0 timeDate_grid">
                                    <DatePicker
                                      selected={this.state.selectedDate}
                                      onChange={this.handleChange}
                                      minDate = {moment().add(1, 'hours')}
                                    />
                                  </div> :
                                  <div className="col-md-6 padLeft0 timeDate_grid">
                                    <DatePicker
                                      selected={this.state.selectedDate}
                                      onChange={this.handleChange}
                                      minDate = {moment().add(1, 'hours')}
                                      disabled = {true}
                                    />
                                  </div>}
                                {!this.state.checked ?
                                  <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                                    <TimePicker
                                      showSecond={false}
                                      className="custom-timepicker"
                                      format={'h:mm a'}
                                      onChange={this.timePicker.bind(this)}
                                      value={this.state.now}
                                      use12Hours
                                    />
                                    {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}

                                  </div> :
                                  <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                                    <TimePicker
                                      showSecond={false}
                                      className="custom-timepicker"
                                      format={'h:mm a'}
                                      onChange={this.timePicker.bind(this)}
                                      value={this.state.now}
                                      use12Hours
                                      disabled = {true}
                                    />
                                    {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                                  </div>
                                }
                              </div>}
                            </div>

                            <div className="row car-profile">
                              <div className="col-md-6 padLeft0">

                                <h4 className="panel-sub-title">Other Information</h4>

                                <TextInput
                                  label="Chassis Number (Optional)"
                                  name="chassis_number"
                                  type="text"
                                  customClass="noMargin"
                                  value={this.state.chassis_number}
                                  onChange={this.onChasisChange.bind(this)}
                                />
                                <label className="mobChassis">
                                  The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="next-button clearfix">
                          <Button btnType="red" btnSize="lg" fontSize={14} label="Confirm" btnCallBack={() => this.createPlusRequest()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myCar-body features features-additional feature additional-plus-pad row">
                  <div className="col-md-12 padding-zero-plus">
                    <p className="title">Why Carcility Plus?</p>
                    <div className="col-md-12 left-features">
                      <div className="col-md-12">
                        <img className="banner-clock" src={`${imageBasePath}/images/plus/TimeIsMoneySaveAll-Web.svg`} alt=""/>
                        <p className="description">Time is Money.Save Both.</p>
                      </div>
                      <div className="col-md-12">
                        <img className="banner-clock" src={`${imageBasePath}/images/plus/FreePickUp&Drop-Web.svg`} alt=""/>
                        <p className="description">Free Pick up and Drop</p>
                      </div>
                      <div className="col-md-12">
                        <img className="banner-clock" src={`${imageBasePath}/images/plus/WarrantyIncluded-Web.svg`} alt=""/>
                        <p className="description">Warranty included</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="myCar-body features row">


          </div>






          <div className="plus-mobile">
            <div className="page-sec-header quick-booking-header">
              <div className="padwrapper ">
                <span className="back-button" onClick={() =>  this.props.router.push(`${appBasePath}/plus`)}><i className="mdi mdi-arrow-left "></i></span>
                <h4>Additional Details</h4>

              </div>
            </div>
            <div className="collapse-panel">
              <div className="padwrapper ">
                <div className=" panel-content panel-content-mob-add plus-additional-section  ">

                  <div className="row">
                    <div className="col-md-12 pad0">
                      <div className="row">
                        <div className="col-md-6 model-select-plus">
                          <SelectDropdown data={cylinderOption} customPlaceholder="Choose Cylinder" selectedCarProfile={this.state.selectedCylinder!="" && this.state.selectedCylinder} select={this.select.bind(this)} />
                        </div>
                      </div>

                      <div className="row car-profile">
                        <div className="col-md-6 padLeft0">
                          <TextInput
                            label="Approx Mileage (Optional)"
                            name="chassis_number"
                            type="text"
                            customClass="noMargin"
                          />
                        </div>

                      </div>

                      <div className="row car-profile">
                        <div className="col-md-6 padLeft0">
                          <div className="form-section">
                            <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                              <div className={"location-dd header-search ws-form-section"}>
                                <LocationSearchBox placeholder="Location" style={style} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined}/>
                              </div>
                              {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                            </div>
                            {this.state.selectedLocation && <div className="map-panel">
                              <div className="gmaps">
                                <Gmaps
                                  center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                                  zoom={12}
                                  setCenter={true}
                                  markers={markerLocation}
                                  containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                                  mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                                />
                              </div>
                            </div>}
                          </div>

                        </div>

                      </div>


                      <div className="form-section">
                        {!this.state.checked && <div className="row date-time date-time-align">
                          {!this.state.checked ?
                            <div className="col-md-6 padLeft0 timeDate_grid">
                              <DatePicker
                                selected={this.state.selectedDate}
                                onChange={this.handleChange}
                                minDate = {moment().add(1, 'hours')}
                              />
                            </div> :
                            <div className="col-md-6 col-sm-6 col-xs-6 padLeft0 timeDate_grid">
                              <DatePicker
                                selected={this.state.selectedDate}
                                onChange={this.handleChange}
                                minDate = {moment().add(1, 'hours')}
                                disabled = {true}
                              />
                            </div>}
                          {!this.state.checked ?
                            <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                              <TimePicker
                                showSecond={false}
                                className="custom-timepicker"
                                format={'h:mm a'}
                                onChange={this.timePicker.bind(this)}
                                value={this.state.now}
                                use12Hours
                              />
                              {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}

                            </div> :
                            <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 timeDate_grid form-group error" : "col-md-6 padRight0 timeDate_grid form-group"}>
                              <TimePicker
                                showSecond={false}
                                className="custom-timepicker"
                                format={'h:mm a'}
                                onChange={this.timePicker.bind(this)}
                                value={this.state.now}
                                use12Hours
                                disabled = {true}
                              />
                              {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}

                            </div>
                          }
                        </div>}
                      </div>

                      <div className="row car-profile">
                        <div className="col-md-6 padLeft0">

                          <TextInput
                            label="Chassis Number (Optional)"
                            name="chassis_number"
                            type="text"
                            customClass="noMargin"
                            value={this.state.chassis_number!== "" ? this.state.chassis_number : " "}
                            onChange={this.onChasisChange.bind(this)}
                          />
                          <label className="mobChassis">
                            The Chassis Number can be found on the Vehicle Registration Certificate (VRC)
                          </label>
                        </div>

                      </div>



                    </div>


                  </div>

                </div>
              </div>
              <div className="col-md-4 why-plus-container">
                  <p className="title text">Why Carcility Plus ?</p>
                  <div className="col-md-12 row left-features why-plus-card">
                    <div className="col-md-4 col-sm-4 col-xs-4 space">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/TimeIsMoneySaveAll-Web.svg`} alt=""/>
                      <p className="description plus-card">Time is Money. Save Both.</p>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 space">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/FreePickUp&Drop-Web.svg`} alt=""/>
                      <p className="description plus-card">Free Pick up and Drop</p>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 space">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/WarrantyIncluded-Web.svg`} alt=""/>
                      <p className="description plus-card">Warranty included</p>
                    </div>
                  </div>

                </div>
            </div>

          </div>


        </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection footerSection-plus" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}



      </div>
    );
  }
}
