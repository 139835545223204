import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, } from 'react-google-maps';

class GMapView extends Component {
  constructor(props){
    super(props);
    this.state = {
      defaultCenter: undefined,
    };
  }
  componentWillMount() {
    if (this.props.defaultCenter) {
      this.setState({
        'defaultCenter': this.props.defaultCenter
      });
    }
  }
  changeCurrentPosition(markerPosition) {
    const latlng = markerPosition.latLng;
    const lat = latlng.lat();
    const lng = latlng.lng();
    if (lat && lng) {
      this.setState({'defaultCenter': {'lat': lat, 'lng': lng}});
    }
    if (this.props.onDragEnd) {
      this.props.onDragEnd(markerPosition);
    }
  }
  render(){
    const {showMarker, markerOnChange, showRecenter, onLocationChange} = this.props;
    const additionalProps = {};
    if (this.state.defaultCenter) {
      additionalProps.defaultCenter = this.state.defaultCenter;
    }
    return (
      <div>
        {showRecenter && <span onClick={() => {this.setState({ center: true }); }} className="current-position"><i className="mdi mdi-crosshairs-gps"></i></span>}
        <GoogleMap
          draggable={true}
          defaultZoom={15}
          {...additionalProps}
          containerElement={<div style={{ height: '562px', width: 'auto' }} className="locationPopup" />}
          mapElement={<div style={{ height: '562px', width: 'auto' }} className="locationPopup" />}>
          {(showMarker && this.state.defaultCenter) && <Marker
            onDragEnd={(newPoisition) => {
              this.changeCurrentPosition(newPoisition)
            }}
            draggable={this.props.markerDraggable || false}
            position={this.state.defaultCenter}
          />}
        </GoogleMap>
      </div>
    )
  }
}
export default withGoogleMap(GMapView);
