import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionCreators} from '../actions/';
import { appBasePath } from '../constants/staticData';

export default function BookingRequestContainer(ComposedComponent) {
  class BookingRequestContainer extends Component {
    constructor(props) {
      super(props);
    }

    componentWillUpdate(nextProps) {
      if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.currentComponentKey) {
        nextProps.router.push(appBasePath + nextProps.bookingRequestReducer.currentComponentKey);
      }
      if(nextProps.commonReducer && nextProps.commonReducer.currentComponentKey){
        nextProps.router.push(appBasePath + nextProps.commonReducer.currentComponentKey);
      }
    }

    render() {
      return (<ComposedComponent {...this.props}/>);
    }
  }

  function mapStateToProps(state) {
    return {carProfileReducer: state.carProfileReducer, bookingRequestReducer: state.bookingRequestReducer, authReducer: state.authReducer, commonReducer: state.commonReducer};
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ActionCreators, dispatch)
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(BookingRequestContainer);
}

BookingRequestContainer.contextTypes = {
  router: React.PropTypes.object
};
