import React, { Component } from 'react';
import Slider from 'react-slick';
import { imageBasePath } from '../../constants/staticData';
class Testimonials extends Component {
  componentDidMount() {
    setInterval(() => {
      if (this.mobSlider && this.mobSlider.slickNext) {
        this.mobSlider.slickNext();
      }
    },
      4000);
  }
  render() {
    let settings = {
      dots: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },

        }, {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
      ]
    }
    return (
      <div id="benefits-section" className="mob-show">
          <div className="common-heading text-center">
            <h5>Why people love using Carcility</h5>
          </div>
          <div className="mb-wrapper">
            <Slider {...settings} ref={ref => this.mobSlider = ref}>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/easy.svg`} alt="Car repair service made easy" />
                <h5>EASY TO USE</h5>
                <p>Our site and mobile apps make it easy to book anytime, anywhere.</p>
              </div>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/peaceful.svg`} alt="peaceful"  />
                <h5>PEACE OF MIND GUARANTEED</h5>
                <p>Quality service, no unexpected charges, and customer support.</p>
              </div>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/instant.svg`}  alt="instant advantage" />
                <h5>INSTANT, FAIR AND TRANSPARENT </h5>
                <p>Receive and compare instant quotes from different shops.</p>
              </div>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/maintain.svg`}  alt="Car Maintenance"  />
                <h5>MAINTAINANCE TRACKER</h5>
                <p>Service is tracked, and you'll receive reminders for future service.</p>
              </div>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/manage.svg`} alt="Manage with car service app" />
                <h5>MANAGE YOUR CARS ONLINE</h5>
                <p>Store your car and insurance details, 24/7 access to service records and no more paper work!</p>
              </div>
              <div className="mb-list text-center wow slideInUp">
                <img src={`${imageBasePath}/images/landing/secure.svg`} alt="Secured Auto service"  />
                <h5>SECURE PAYMENT (Later)</h5>
                <p>We hold payment until service is complete and you're satisfied.</p>
              </div>
            </Slider>
          </div>
      </div>
    );
  }
}

export default Testimonials;
