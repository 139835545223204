import React, { Component } from 'react';
import map  from 'lodash/map';
import '../../styles/sidebar.css';
import { Panel } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      currentPath:""
    }
  }
  componentWillMount() {
    const currentPath = window.location.pathname;
    this.state = {
      currentPath: currentPath
    };
  }
  componentWillReceiveProps() {
    const currentPath = window.location.pathname;
    this.state = {
      currentPath: currentPath
    };
  }
  goToUrl(url) {
    this.props.router.push(appBasePath + url);
  }
  render() {
    const { bgColor } = this.props;
    const sidebarItems = [
      {
        icon: `${imageBasePath}/images/sidebar/dashboard.svg`,
        icon_active: `${imageBasePath}/images/sidebar/dashboard_active.svg`,
        name: 'Dashboard',
        hyperLink: '/dashboard-plus'
      },
      {
        icon: `${imageBasePath}/images/sidebar/request.svg`,
        icon_active: `${imageBasePath}/images/sidebar/request_active.svg`,
        name: 'My Requests',
        hyperLink: '/request'
      },
      {
        icon: `${imageBasePath}/images/sidebar/car.svg`,
        icon_active: `${imageBasePath}/images/sidebar/car_active.svg`,
        name: 'My Cars',
        hyperLink: '/car-profiles'
      },
      {
        icon: `${imageBasePath}/images/sidebar/message.svg`,
        icon_active: `${imageBasePath}/images/sidebar/message_active.svg`,
        name: 'Messages',
        hyperLink: '/messages'
      },
      {
        icon: `${imageBasePath}/images/sidebar/fav.svg`,
        icon_active: `${imageBasePath}/images/sidebar/fav_active.svg`,
        name: 'Favourites',
        hyperLink: '/favourites'
      },
    ];

    const currentPath = this.props.router.location.pathname;
    var that = this.state.currentPath
    const sideBarCardList = map(sidebarItems, (item, key) => {

      return (
        // <li key={key} className={this.state.currentPath == item.hyperLink ? 'active' : ''}
        <li key={key} className={this.state.currentPath && this.state.currentPath.startsWith(item.hyperLink) ? 'active' : ''}
          onClick={this.goToUrl.bind(this, item.hyperLink)}>
          <a>
            {item.icon && <img src={item.icon} className="non_active" />}
            {item.icon && <img src={item.icon_active} className="active_icon" />}
            <span>{item.name}</span>
          </a>
        </li>
      );
    });
    return (
      <div className="sidebar" style={{ backgroundColor: bgColor ? bgColor : '#fff' }}>
        <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
          <ul className="list-unstyled">
            {currentPath == "/landing" && <li className="s-show profile-collapse">
              <div className="profile-dropdown" onClick={this.props.profileClick}>
                <div className="pofile-img">
                  <img src={`${imageBasePath}/images/pic.png`} className="picture" />
                </div>
                <div className="p-name">
                  <label>Methew Johnny</label>
                </div>
                <div className="arrow-icon">
                  <i className={this.props.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
                </div>
              </div>
              <Panel collapsible expanded={this.props.open}>
                <ul className="list-unstyled">
                  <li>
                    <a href=""> Settings </a>
                  </li>
                  {/* <li>
                    <a href=""> Payment </a>
                  </li> */}
                  <li>
                    <a href=""> Logout </a>
                  </li>
                </ul>
              </Panel>
            </li>}
            {sideBarCardList}
          </ul>
        </CustomScroll>
      </div>
    );
  }
}
