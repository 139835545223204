import React, { Component } from 'react';
import {Route} from "react-router";

class BlogRedirection extends Component {
  constructor() {
    super()



    if(window.location.pathname == "/services/car-oil-service-offer"){
      window.location.href = "https://www.carcility.com/blog/offers/car-services-oil-service-offer/"
    }else if(window.location.pathname == "/offers/car_service"){
      window.location.href = "https://www.carcility.com/blog/offers/car-service-in-dubai/"
    }else if(window.location.pathname == "/vendor-profile/146-axis-auto-garage"){
      window.location.href = "https://www.carcility.com/vendor-profile/axis-auto-garage"
    }else if(window.location.pathname == "/vendor-profile/148-auto-mystique-car-care-llc"){
      window.location.href = "https://www.carcility.com/vendor-profile/auto-mystique-car-care-llc"
    }else if(window.location.pathname == "/vendor-profile/149-golden-workshop"){
      window.location.href = "https://www.carcility.com/vendor-profile/golden-workshop"
    }else if(window.location.pathname == "/vendor-profile/150-800-wash"){
      window.location.href = "https://www.carcility.com/vendor-profile/800-wash"
    }else if(window.location.pathname == "/vendor-profile/151-al-kayed-workshop"){
      window.location.href = "https://www.carcility.com/vendor-profile/al-kayed-workshop"
    }else if(window.location.pathname == "/vendor-profile/152-rover-auto-centre-llc"){
      window.location.href = "https://www.carcility.com/vendor-profile/rover-auto-centre-llc"
    }else if(window.location.pathname == "/vendor-profile/153-boby-auto-garage"){
      window.location.href = "https://www.carcility.com/vendor-profile/boby-auto-garage"
    }else if(window.location.pathname == "/vendor-profile/154-snash-car-care-l-l-c"){
      window.location.href = "https://www.carcility.com/vendor-profile/snash-car-care-l-l-c"
    }else if(window.location.pathname == "/vendor-profile/157-protouch-auto-care-llc"){
      window.location.href = "https://www.carcility.com/vendor-profile/protouch-auto-care-llc"
    }else if(window.location.pathname == "/vendor-profile/165-max-garage"){
      window.location.href = "https://www.carcility.com/vendor-profile/max-garage"
    }else if(window.location.pathname == "/vendor-profile/171-mik-garage"){
      window.location.href = "https://www.carcility.com/vendor-profile/mik-garage"
    }else if(window.location.pathname == "/vendor-profile/172-mik-garage-aweer"){
      window.location.href = "https://www.carcility.com/vendor-profile/mik-garage-aweer"
    }else if(window.location.pathname == "/vendor-profile/182-autotrak-auto-service-center"){
      window.location.href = "https://www.carcility.com/vendor-profile/autotrak-auto-service-center"
    }else if(window.location.pathname == "/vendor-profile/184-atlantis-auto-care"){
      window.location.href = "https://www.carcility.com/vendor-profile/atlantis-auto-care"
    }else if(window.location.pathname == "/vendor-profile/186-battery-wheels"){
      window.location.href = "https://www.carcility.com/vendor-profile/battery-wheels"
    }else if(window.location.pathname == "/vendor-profile/190-vip-german-auto-repairing"){
      window.location.href = "https://www.carcility.com/vendor-profile/vip-german-auto-repairing"
    }else if(window.location.pathname == "/vendor-profile/161-gargash-autobody"){
      window.location.href = "https://www.carcility.com/vendor-profile/gargash-autobody"
    }

  }

  render() {

    <div></div>
  }
}
export default BlogRedirection;
