import React, { Component } from 'react';
import Header from '../common/Header';
import LoginHeader from '../common/LoginHeader';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import Footer from '../Landing/Footer';
import { BreadcrumbList, ListItem } from 'react-semantic-breadcrumbs'

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      navActive: 'aboutUs',
      searchMobile: false,
      notificationVisible: '',
      messageVisible: ''
    };
  }
  toggleNotification(isVisible) {
    this.setState({ notificationVisible: isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ messageVisible: isVisible });
  }
  render() {
    const whatWeAreArr = [
      {
        id: 0,
        headerTxt: 'Better experience',
        subText: 'Driven to work and create products and features that will help consumers and business make a better choice of services.'
      },
      {
        id: 1,
        headerTxt: 'Hassle free service',
        subText: 'Provide complete car management solutions to consumers ensuring convenient and easy to use services.'
      },
      {
        id: 2,
        headerTxt: 'One-stop destination',
        subText: 'Be an exclusive platform that aggregates information about service centers, multi-brand chains, and independent garages.'
      }
    ];
    let baseUrl = location.origin;
    //  if (!/test|localhost/.test(baseUrl)) {
    //     baseUrl += '/customer';
    // }
    const { router, actions, commonReducer,bookingRequestReducer} = this.props;
    return (
      <div className="faq-body">
        {localStorage.getItem('authData')
          ? <Header
            hideOption={() => this.setState({ searchMobile: true })}
            bookingRequestReducer= {bookingRequestReducer}
            commonReducer={commonReducer}
            notificationCount={2}
            profileName="Derrick Frank"
            notificationCallBack={this.toggleNotification}
            messageCallBack={this.toggleMessage.bind(this)}
            router={router}
            actions={actions}
            authReducer={this.props.authReducer}
            metaTagKey={'about'}
          />
          : <LoginHeader
            hideOption={() => this.setState({ searchMobile: true })}
            pageType="signIn"
            headerClickAction={e => {
              e.preventDefault();
              router.push(`${appBasePath}/sign-in`);
            }}
            metaTagKey={'about'}
          />}

        <div className="faq-banner banner-top">
          <div className="faq-img">
            <img src={`${imageBasePath}/images/about-us-auto-care-banner.jpg`} alt="Best car care and car service team" />
          </div>
          <div className="faq-desc">
            <h1> About Us </h1>
            <p>
              {' '}
              We believe in making auto care more connected, engaging and trustworthy than ever before.
                        </p>
          </div>
        </div>
        <div className="faq-content">
          <div className="row">
            <div className="left-block col-md-4">
              <ul className="n-nav-selection">
                <li
                  className={this.state.navActive == 'aboutUs' ? 'active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      navActive: 'aboutUs'
                    });
                  }}
                >
                  About Us
                                </li>

              </ul>
            </div>
            <div className="right-block col-md-8">
              <div>
                <div className="top top-about">
                  <div className="breadcrumbList">
                    <BreadcrumbList format="RDFa" separator=" > ">
                      <ListItem url="/">Home</ListItem>
                      <ListItem url="/about">About</ListItem>
                    </BreadcrumbList>

                  </div>


                  <h2 className="heading about-heading">Who we are</h2>
                  <ul className="question-holder">
                    <li>
                      <p className="about-subtext">
                        Love for cars shines through every sidewalk of this country – but at first look, the options to keep those cars in mint condition seem to be scarce. Servicing and repairs take a lot of time and effort from car owners’ part, and trustworthy service providers who can care for these cars do not have adequate avenues to reach potential consumers. This results both in dissatisfied car owners, and service providers who can’t do justice to the finesse they possess.

                                            </p>
                    </li>
                  </ul>
                  <ul className="question-holder">
                    <li>
                      <p className="about-subtext">
                        Carcility aims to change this status quo. Our online and app-based approach brings car owners and qualified service providers in the vicinity closer, and helps them get what they want with minimal fuss.
                                            </p>
                    </li>
                  </ul>
                </div>
                <div className="top top-about">
                  <h2 className="heading about-heading">What we do</h2>
                  <ul className="question-holder">
                    <li>
                      <p className="about-subtext">
                        {' '}
                        We love cars, and our aim is to help everyone give the best possible care to the cars they own. We leverage technology to address the gap in the market and bring car owners closer to reputed service providers, and deliver rich and seamless connections.
                                                {' '}
                      </p>
                    </li>
                  </ul>
                  <div className="row-box">
                    {whatWeAreArr &&
                      whatWeAreArr.map(weAreVal => {
                        return (
                          <div className="row row-box-container" key={weAreVal.id}>
                            <div className="col-md-4 p-0">
                              <p className="we-are-header mb-0">
                                {weAreVal.headerTxt}
                              </p>
                            </div>
                            <div className="col-md-8 p-0">
                              <p className="we-are-descp mb-0">
                                {weAreVal.subText}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                </div>
                <div className="top top-about">
                  <h4 className="heading about-heading">Why we work</h4>
                  <ul className="question-holder">
                    <li>
                      <p className="about-subtext">
                        {' '}
                        The traditional automobile aftermarket sector is still stuck in the pre-digital age – and these inefficiencies have no place in today’s fast-paced world. We at Carcility aim to transform the way the automotive car businesses functions, at both ends. For service providers, we offer access to a wide range of clientele and parts for every car, and pave the way to gain consumer trust and loyalty.

                                            </p>
                    </li>
                  </ul>
                  <ul className="question-holder">
                    <li>
                      <p className="about-subtext">
                        When it comes to consumers, we help them experience automotive repair and services in an easy, friendly and transparent manner. We eliminate bottlenecks in the current process and ensure consumers find trustworthy service providers with the click of a button. We also facilitate payments for car services in a safe, convenient and frictionless way – helping car owners pay for services in a secure manner, and service providers to receive their payments quickly.
                                            </p>
                    </li>
                  </ul>

                </div>

              </div>
            </div>
          </div>

        </div>
        {this.state.searchMobile &&
          <div>

            <MobileHeaderSearch
              mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() =>
                this.setState({
                  searchMobile: false
                })}
            />
          </div>}
        <Footer {...this.props}/>
      </div>
    );
  }
}
export default AboutUs;
