import React, { Component } from 'react';
import Button from '../common/Button';
import AlertDismissable from '../common/AlertDismissable';
import { imageBasePath } from '../../constants/staticData';

export default class NotificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        notificationSms : true,
        notificationEmail : true,
        marketEmail : true,
        motorEmail: true,
        appNotification: false,
        submiteData: ''
        };
        this.onSubmit=this.onSubmit.bind(this);
        this.notificationUpdates=this.notificationUpdates.bind(this);

    }

    componentWillMount() {
     this.props.actions.getNotificationInfo('get');
    }

    componentWillUnmount() {
     this.props.actions.hideErrorMessage();
    }

    componentWillReceiveProps(nextProps) {
			if(nextProps.userReducer){
          this.notificationUpdates(nextProps.userReducer.notificationRequests);
      }
    }
    
    notificationUpdates(userReducer) {
        this.setState({
        notificationSms : userReducer.notifications.smsNotificationEnabled,
        notificationEmail : userReducer.notifications.emailNotificationEnabled,
        marketEmail : userReducer.notifications.marketingEmailsEnabled,
        motorEmail: userReducer.notifications.motoringNewsEmailsEnabled,
        appNotification: userReducer.notifications.pushNotificationEnabled
        });
    }
    onSubmit(){  
       this.state.submiteData ={
            'sms_notification_enabled': this.state.notificationSms,
            'email_notification_enabled': this.state.notificationEmail,
            'marketing_emails_enabled': this.state.marketEmail,
            'motoring_news_emails_enabled': this.state.motorEmail,
            'push_notification_enabled': this.state.appNotification
        }
        this.props.actions.getNotificationInfo('post',this.state.submiteData);
    }
       
 
    render() {
    const {authReducer} = this.props;
        return (
            <div>
            {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle={authReducer.statusMessage.indexOf('succesfully') !== -1  ? 'success error-back mob-err-suc-dispaly' :'danger error-back mob-err-suc-dispaly'} closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                      <p> <i className="mdi mdi-block-helper " /> {authReducer.statusMessage} </p>
                    </AlertDismissable>}
              <div className="notification-form-info">
              <div className="notification-header-type">
              <img src={`${imageBasePath}/images/Email.svg`} className="notification-header-icon"/>Emails</div>
                <div className="notification-slab row">
                  <div className="notification-text col-xs-4">Get Notifications Through Email</div>
                  <div className="col-xs-6 switch-width-mob">
                  <label className="switch">
                    <input type="checkbox" onChange={()=>this.setState({ notificationEmail: this.state.notificationEmail ? false : true })} checked={this.state.notificationEmail}/>
                    <span className="slider"></span>
                  </label>
                  </div>
                </div>
                <div className="notification-slab row">
                  <div className="notification-text col-xs-4">Get Marketing Emails</div>
                  <div className="col-xs-6 switch-width-mob">
                  <label className="switch">
                    <input type="checkbox" onChange={()=>this.setState({ marketEmail: this.state.marketEmail ? false : true })} checked={this.state.marketEmail}/>
                    <span className="slider"></span>
                  </label>
                  </div>
                </div>
                <div className="notification-slab row">
                  <div className="notification-text col-xs-4">Get Motoring news email</div>
                  <div className="col-xs-6 switch-width-mob">
                  <label className="switch">
                    <input type="checkbox"  onChange={()=>this.setState({ motorEmail: this.state.motorEmail ? false : true })}  checked={this.state.motorEmail} />
                    <span className="slider"></span>
                  </label>
                  </div>
                </div>
                <div  className="notification-header-type">
                <img src={`${imageBasePath}/images/SMS.svg` } className="notification-header-icon"/>SMS</div>
                <div className="notification-slab row">
                  <div className="notification-text col-xs-4">Get Notifications Through SMS</div>
                  <div className="col-xs-6 switch-width-mob">
                    <label className="switch">
                      <input type="checkbox" onChange={()=>this.setState({ notificationSms: this.state.notificationSms ? false : true })} checked={this.state.notificationSms}/>
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div  className="notification-header-type">
                <img src={`${imageBasePath}/images/App.svg` } className="notification-header-icon"/>App</div>
                <div className="notification-slab row">
                  <div className="notification-text col-xs-4">Get in-App Notification</div>
                  <div className="col-xs-6 switch-width-mob">
                  <label className="switch">
                    <input type="checkbox" onChange={()=>this.setState({appNotification: !this.state.appNotification })} checked={this.state.appNotification}/>
                    <span className="slider"></span>
                  </label>
                  </div>
                </div>
              </div>
              <div className="navigation-footer-part clearfix container">
                <Button btnType="red" btnSize="sm" fontSize={16} label="Save"  btnCallBack={()=>this.onSubmit(this)} customClass="btn-theme-save"/>
                <Button btnType="transparent" btnSize="sm" fontSize={16} label="Cancel" btnCallBack={()=>window.history.back()} customClass="btn-theme"/>
              </div>
            </div>
        );
    }
}
