import React, { Component } from 'react';
import Sidebar from '../common/Sidebar';
import Footer from '../Landing/Footer';
import Button from '../common/Button';
import { Link, Element} from 'react-scroll';
import { Tabs, Tab } from 'react-bootstrap';
import map from 'lodash/map';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';
import range from 'lodash/range';

import { validateFormSubmit, validateMobile} from '../../helpers/index';
import { validationMessage, imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import CustomModal from "../common/CustomModal";
import Modal from "react-bootstrap/es/Modal";
import CustomerTestimonials from "../common/Testimonials";
import FaqCollpasePanel from "../common/FaqCollpasePanel";
import CustomScroll from "react-custom-scroll";
import TextInput from "../common/TextInput";
import moment from "moment";
import queryString from "query-string";
var uniqid = require('uniqid');
import { lazyload } from 'react-lazyload';
import FloatingMenu from "../LandingNew/FloatingMenu";
import OfferEmptyState from "../EmptyState/OfferEmptyState";
import PageLoader from "../common/PageLoader";

/*
import FloatingMenu from "./FloatingMenu";
*/
@lazyload({
  height: 200,
  once: true,
  offset: 100
})
export default class QuickBookingLanding extends Component {
  constructor(props) {
    super(props);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};

    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });

    const urlData = queryString.parse(this.props.location.search);
    this.state = {
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : "",
      hasMultipleSubCategories: false,
      yearListDD: false,
      showSubCategory: false,
      hideForm: false,
      hideForm1: true,
      hideForm2: true,
      hideForm3: true,
      open: false,
      isButtonLoading: false,
      "selectedYear": "Year",
      "subServiceName": "Select Service",
      "allYears": years,
      "allYearsList": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      "serviceListDD": false,
      "selectedService": "Select Service",
      //"selectedService": this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData.services[0].parentId == 1 ? 'Car Wash and Detailing': this.props.location.state.selectedPromotionalOffersData.services[0].parentId == 2 ? 'Car Servicing': 'Car Repair':"Select Service":"Select Service",
      //"offer": this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData:"":"",
      "offer": "",
      "allService": [],
      allFilteredCarModelList: [],
      activeRequestType: "",
      activeRequestTypeLabel: "",
      selectedCarMakeKey: '',
      isMakeandModelLoaded: false,
      showFormModalshowFormModal: false,
      workshopsData: [],
      isWorkshopsLoading: true,
      appDownloadPhoneNumber: '',
      showPhoneNoError: false,
      isDataLoaded:false,
      //selectedPromotionalOffersData: this.props.params.id?  this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData?this.props.location.state.selectedPromotionalOffersData:[]:[]
      selectedPromotionalOffersData: []
    };


    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showFormModal = this.showFormModal.bind(this);


    this.initialFormData = {
      "name": '',
      "email": '',
      "phone": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "car_details": '',
      "category": '',
      "serviceid":  this.props.params.id? this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ?this.props.location.state.selectedPromotionalOffersData.services[0].parentId == "1" || this.props.location.state.selectedPromotionalOffersData.services[0].parentId == "2" ? this.props.location.state.selectedPromotionalOffersData.services[0].parentId:3 :"":"",
      "terms": true,
    };

    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
    //  "name": false, //Removing it as mandatory
      "email": false,
      "phone": false,
      "make": false,
      "model": false,
      "model_id": false,
      "year": false,
      "plate_no": false,
      "car_details": false,
      "category": false,
      "serviceid":false,
    };


    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.chooseCarDetails = this.chooseCarDetails.bind(this);
    this.chooseSubCategory = this.chooseSubCategory.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    this.props.actions.getWorkshops();
    if (authData && authData.phone && authData.phoneVerified && authData.type && authData.type == 'customer') {
      this.props.router.push(appBasePath + '/dashboard-plus');
    }
    // only for ie
    var ua = window.navigator.userAgent;

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      this.setState({ ie: true });
    }


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    // if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
    this.props.actions.getManufacturers();
    //}



    //   this.props.actions.getAllPromotionalOffers();

    if(this.props.params.id)
      this.props.actions.getSingleOffer(this.props.params.id);

  }
  componentWillReceiveProps(nextProps) {


    this.setState({
      workshopsData: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? nextProps.commonReducer.vendorsListFromSearch : [],
      isWorkshopsLoading: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? false : true,
      isButtonLoading:false
    });



    if (nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.getAllServices) {
      let quickBookingServices = [];
      nextProps.bookingRequestReducer.getAllServices.forEach((service) => {
        if (service.name !== "Emergency Service") {
          quickBookingServices.push(service);
        }
      });
      this.setState({
        allService: quickBookingServices,
      });

    }

    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;

      this.setState({
        allManufacturer: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        filteredCarModelList: carModel,
        allFilteredCarModelList: carModel,
      });
    }


    if(this.props.params.id){

      let promotionalOffersData = nextProps.commonReducer && nextProps.commonReducer.singleOffersData ? nextProps.commonReducer.singleOffersData : "";


      if(nextProps.commonReducer.isOfferLoaded && ! promotionalOffersData){
        window.location.pathname = "/404";

      }



        console.log(" promotionalOffersData && promotionalOffersData.services", promotionalOffersData && promotionalOffersData.services)
      this.setState({
        isDataLoaded: nextProps.commonReducer.isOfferLoaded && promotionalOffersData?true:false,
        selectedPromotionalOffersData: promotionalOffersData?promotionalOffersData:"",
        selectedPromotionalOffersServiceData: promotionalOffersData?promotionalOffersData.services:"",
        hasMultipleSubCategories: promotionalOffersData && promotionalOffersData.services.length > 1 ? true : false,
        //  subServiceName: promotionalOffersData && promotionalOffersData.services && promotionalOffersData.services.length == 1 ? promotionalOffersData.services[0].name : "Select Service",
        //"selectedService": promotionalOffersData ? promotionalOffersData.services[0].parentId == 1 ? 'Car Wash and Detailing': promotionalOffersData.services[0].parentId == 2 ? 'Car Servicing': 'Car Repair':"Select Service",
        "offer": promotionalOffersData?promotionalOffersData :"",
      //  "serviceid":   promotionalOffersData && promotionalOffersData.services.length ?  promotionalOffersData && promotionalOffersData.services


      });


      if(promotionalOffersData && promotionalOffersData.services.length > 0){



        this.formData["category"] =  promotionalOffersData.services[0].serviceId;
        this.errors["category"] = false;

        this.setState({
          subServiceId: promotionalOffersData.services[0].serviceId,
          subServiceName: promotionalOffersData.services[0].name
        });

      }




    }
  }
  componentWillUnmount() {

    this.props.actions.hideErrorMessage();
  }


  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,

    });
  }
  chooseSubCategory() {

    if(this.state.hasMultipleSubCategories){
      this.setState({
        showSubCategory:true,
        hideForm:true,
      });
    }

  }

  onSubmit(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      console.log(formData);
      console.log(validatedForm.validForm);
      console.log(validatedForm);
      console.log(this.errors);

      this.setState({ submissionError: true });
      return;
    } else {

      this.setState({ submissionError: false, isButtonLoading: true });
      let phone_no = this.formData.phone;
      let payload = {
        "batch_id": uniqid(),
        "name": this.formData.name,
        "email": this.formData.email,
        "phone": '+971' + phone_no,
        "make": this.formData.make,
        "model": this.formData.model,
        "model_id": this.formData.model_id,
        "year": this.formData.year,
        "plate_no": this.formData.plate_no,
        "serviceid": this.formData.serviceid,
        "flow": "quickbooking",
        "provider": "normal",
        "subtype": "all offers",
        "hideFirstStep": true
      };
      if (this.props && this.props.activeRequestType == "repair") {
        payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
          payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
      }



      let promoCategories = [];

      this.state.selectedPromotionalOffersData && this.state.selectedPromotionalOffersData.services && this.state.selectedPromotionalOffersData.services.length && this.state.selectedPromotionalOffersData.services.forEach((promoCategory) => {

        promoCategories.push(promoCategory.serviceId);

      });


      if(this.formData.serviceid == 1){
        //Car Wash
        localStorage.setItem('selectedCarWashDataArray',JSON.stringify(promoCategories));

      }else if(this.formData.serviceid == 2){


        //localStorage.setItem('selectedServiceId', promoCategories.length && promoCategories[0].serviceId);
        localStorage.setItem('selectedServiceId', this.formData.category);
        localStorage.setItem('selectedServiceName', this.state.subServiceName);

        localStorage.setItem('selectedService',JSON.stringify(promoCategories));


      }else{
        localStorage.setItem('selectedCarRepairDataArray',JSON.stringify(promoCategories));

      }

      localStorage.setItem('selectedPromoCode',this.state.selectedPromotionalOffersData.promoCode);
      localStorage.setItem('selectedPromoCodeId',this.state.selectedPromotionalOffersData.id);




      this.props.actions.quickBookingUserSignUp(payload, router);
    }

  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  autoCompleteManufacturer(value){

    this.setState({ searchManufacturerTerm: value });

    let searchView = "";
    if(value){
      searchView = filter(this.state.allManufacturer, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allManufacturer;
    }

    this.setState({ 'filteredCarList': searchView });
  }
  autoCompleteServices(value){

    this.setState({ searchServiceTerm: value });

    let searchView = "";
    if(value){
      searchView = filter(this.state.selectedPromotionalOffersData.services, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.selectedPromotionalOffersData.services;
    }

    this.setState({ 'selectedPromotionalOffersServiceData': searchView });
  }
  autoCompleteModel(value){

    this.setState({ searchModelTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.filteredCarModelList, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allFilteredCarModelList;
    }


    this.setState({ 'filteredCarModelList': searchView });
  }

  autoCompleteYear(value){

    this.setState({ searchYearTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.allYears, val => {

        if (value.toString() != '' && val.toString().indexOf(value.toString()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allYearsList;
    }

    this.setState({ allYears: searchView });
  }

  showFormModal() {


    this.setState({
      showFormModal: true
    });

  }
  render() {

    
    const tagManagerArgs = {
      dataLayer: {
        pageCategory: 'Homepage',
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


    const origin = location.origin;
    let baseUrl = location.origin;

    const settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };

    const carList = this.state.filteredCarList ? this.state.filteredCarList : this.state.allManufacturer;
    const servicesList = this.state.selectedPromotionalOffersServiceData?this.state.selectedPromotionalOffersServiceData:[];

    const carModalList = this.state.isMakeandModelLoaded && this.state.filteredCarModelList;

    let days = "";

    if(this.state.offer){
      days = this.state.offer && moment(this.state.offer.validTo).diff(moment(new Date()),'days');

    }


    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';


    let faqsData =[{
      id:1,
      title:"When do I need to change the oil of my car?",
      description: "This depends on the quality and quantity of the oil being used, the make and build\n" +
        "of the car and the overall usage of the vehicle. A general rule of thumb is to change the\n" +
        "oil every 3-5 months or 5000/10000 kilometers, whichever comes first. Please refer to the user\n" +
        "manual for a more accurate number."
    },{
      id:2,
      title:"What makes a car engine last long?",
      description: "Car engines are one of the most sophisticated pieces of machinery in your car, and\n" +
        "needless to say, one of the most expensive to replace as well. Here are some tips to\n" +
        "elongate the lifespan of your car engine:" +
        "Please keep in mind that these are only preventive measures. Any existing damage to\n" +
        "the engine of your car will still need to be dealt with."
    },
      {
        id:3,
        title:"Are these services transferable?",
        description: "The services are not transferable. Once you have booked a service from the app,\n" +
          "you must carry out the service for the vehicle it was originally booked for. This stands\n" +
          "true for all offers and discounts appearing on the app and our website as well."
      },
      {
        id:4,
        title:" Is the body-wash for the car chargeable?",
        description: "No, the body wash for the car comes as a part of the pack."
      }];


    let faqsHtml = faqsData.map((item, ) =>
      <FaqCollpasePanel faq={item}/>
    );


    let manufactures =[
      {icon:"audi"},
      {icon:"bmw"},
      {icon:"chevrolet"},
      {icon:"nissan"},
      {icon:"toyota"},
      {icon:"kia"},
      {icon:"mercedes-benz"},
      {icon:"mitsubishi"},
      {icon:"ford"},
      {icon:"hyundai"},
      {icon:"suzuki"},
      {icon:"honda"},
      {icon:"gmc"},
      {icon:"alfa-romeo"},
      {icon:"bentley"},
      {icon:"cadillac"},
      {icon:"chrysler"},
      {icon:"dodge"},
      {icon:"fiat"},
      {icon:"infiniti"},
      {icon:"jaguar"},
      {icon:"jeep"},
      {icon:"land-rover"},
      {icon:"lexus"},
      {icon:"mazda"},
      {icon:"mini"},
      {icon:"peugeot"},
      {icon:"renault"},
      {icon:"volkswagen"},
      {icon:"volvo"},
      {icon:"aston-martin"},
      {icon:"byd"},
      {icon:"citroen"},
      {icon:"chery"},
      {icon:"ferrari"},
      {icon:"geely"},
      {icon:"great-wall"},
      {icon:"isuzu"},
      {icon:"lincoln"},
      {icon:"lamborghini"},
      {icon:"lada"},
      {icon:"daihatsu"},
      {icon:"lotus"},
      {icon:"maserati"},
      {icon:"mclaren"},
      {icon:"mg"},
      {icon:"opel"},
      {icon:"porsche"},
      {icon:"proton"},
      {icon:"seat"},
      {icon:"skoda"},
      {icon:"ssang-yong"},
      {icon:"subaru"},
      {icon:"tata"},
      {icon:"luxgen"},
      {icon:"jac"},
      {icon:"foton"},
      {icon:"baic"},
      {icon:"gac"},
      {icon:"kinglong"},
      {icon:"dfm"},
      {icon:"brilliance"},
      {icon:"changan"},
      {icon:"rolls-royce"},
      {icon:"haval"},
      {icon:"maxus"},
      {icon:"faw"},
      {icon:"lifan"},
      {icon:"genesis"},
      {icon:"morgan"},
      {icon:"tesla"},
      {icon:"senova"},
      {icon:"karry"},
      {icon:"kenbo"},
      {icon:"keyton"},
      {icon:"bugatti"},
      {icon:"caterham"},
      {icon:"bear"},
      {icon:"zarooq-motors"},
      {icon:"ram"},
      {icon:"borgward"},
      {icon:"acura"},
      {icon:"devel"},
      {icon:"w-motors"},
      {icon:"mahindra"},
      {icon:"abarth"},
      {icon:"zotye"},
      {icon:"dorcen"},
      {icon:"sandstorm"},
      {icon:"soueast"},
      {icon:"jetour"},
    ]
    return (

      <div>
      <FloatingMenu  />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['landing'] && metaTagData['landing'].title ? metaTagData['landing'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['landing'] && metaTagData['landing'].description ? metaTagData['landing'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        {/* Quick Call Modal */}
        <div id="landing" className={`landing-wrapper ${this.state.enableOpacity}`} ref="landing">
          {this.props.bookingRequestReducer.toastMessage &&
            this.props.bookingRequestReducer.toastType &&
            <ToastMessage
              actions={this.props.actions}
              type={this.props.bookingRequestReducer.toastType}
              title={this.props.bookingRequestReducer.toastType + '...'}
              text={this.props.bookingRequestReducer.toastMessage}
            />}



        <nav className={this.state.showOfferNotification ? "navbar navbar-default default-header default-show-header-top  navbar-quick-landing " : "navbar navbar-default default-header default-header-top  navbar-quick-landing "} id="header-section1">


          <div className="container-fluid">
            <div className="navbar-header">


              <a className="navbar-brand desk-logo" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>
              <div className="logo-section mobile-logo" onClick={(e) => { e.preventDefault(); window.location.pathname = '/'; }}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="quick-logo-img" />
                <img src={`${imageBasePath}/images/quick-booking/phone.png`} alt="back" className="quick-back-arrow" />
              </div>
              <ul className="nav navbar-nav navbar-left offer-nav">



                <li  className="offer-item">
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/all-offers');
                    }}


                  >
                   OFFERS
                  </a>
                  <label />
                </li>
                <li>

                  <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                  </span>
                  </Link>
                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      window.location.pathname = '/business';
                    }}
                  >
                    FOR BUSINESS
                  </a>
                  <label />
                </li>
              </ul>
              {/*<a className="navbar-brand mobile-logo" href="https://www.carcility.com">
                <img src="/images/logo.svg" />
              </a>*/}
            </div>

            <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
              <ul className="nav navbar-nav navbar-right">


                <li className="download-app">

                  <Link to="download" smooth={true} offset={-100} duration={600}>
                  <span>
                    <img src={`${imageBasePath}/images/quick-booking/download-app.png`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />

                    Download the app!
                  </span>
                  </Link>

                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/sign-in');
                    }}
                  >
                    Log In{' '}
                  </a>
                  <label />
                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/register');
                    }}
                  >
                    {' '}Sign Up
                  </a>
                  <label />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-default scroll-header hide" id="header-section2">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
                onClick={() => {
                  this.setState({ navbarVisible: !this.state.navbarVisible });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>
            </div>
            <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <Button
                    dataLabelledby="Get Started"
                    isSubmitBtn={true}
                    btnCallBack={() => {
                      this.props.router.push(appBasePath + '/guest-user');
                    }}
                    btnType="red"
                    btnSize="sm"
                    fontSize={13}
                    label="Get Started"
                  />
                </li>
                <li
                  className="menu-option"
                  onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen, sidebarProfileDropdown: false })}
                >
                  <i className="mdi mdi-menu" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* banner section */}

          {this.state.isDataLoaded?
          <div className="quick-booking-section quick-booking-offer-section  quick-booking-landing-section row offer-section">
            <div className="quick-banner-img">

              {this.state.offer ?
                <div>

                  {this.state.offer.offerId == 12 ?


                    <span>
                         {this.state.windowWidth > 812 ?
                           <img className="normal-bg "
                                src={`${imageBasePath}/images/offer-quick-booking-placeholder-12.jpg`}
                                alt="quick booking"/>
                           :
                           <img className={this.state.offer ? "offer-img responsive-bg" : "quick-img responsive-bg"}
                                src={`${imageBasePath}/images/offer-quick-booking-placeholder-12.jpg`}
                                alt="quick booking"/>
                         }
                  </span>

                    :

                    <span>
                         {this.state.windowWidth > 812 ?
                           <img className="normal-bg "
                                src={this.state.offer.images && this.state.offer.images.length && this.state.offer.images[0].large ? this.state.offer.images[0].large : `${imageBasePath}/images/offer-placeholder.jpeg`}
                                alt="BMW car repair"/>
                           :
                           <img className={this.state.offer ? "offer-img responsive-bg" : "quick-img responsive-bg"}
                                src={this.state.offer.images && this.state.offer.images.length && this.state.offer.images[0].small ? this.state.offer.images[0].small : `${imageBasePath}/images/offer-placeholder.jpeg`}
                                alt="BMW car repair"/>
                         }
                  </span>
                  }

                  <div className="col-md-8 col-sm-8 col-xs-12 description-container">

                    {this.state.offer&&



                    <div className=" offer-detail-wrapper offer-banner ">
                      <div className="padwrapper offer-body">
                        {this.state.offer.discountPercentage ?
                          this.state.offer.discountPercentage == 100 ?
                            <div className="  free-price">

                              <img className="price-cover " src={`${imageBasePath}/images/offer-free.png` } alt="" />

                              <div className="price-content">

                                <span className="amount">Free</span>
                              </div>
                            </div>
                            :
                            <div className=" price">

                              <img className="price-cover " src={`${imageBasePath}/images/offer-price.png` } alt="" />

                              <div className="price-content">

                                <span className="amount">{this.state.offer.discountPercentage }%</span>
                                <span className="type">OFF</span>
                              </div>
                            </div>
                          :
                          <div className=" price">

                            <img className="amount-cover " src={`${imageBasePath}/images/offer-amount.png` } alt="" />

                            <div className="price-content discount-amount-content">

                              <span className="discount-amount">{Number(this.state.offer.discountAmount).toFixed(2)}</span>
                              <span className="discount-currency">AED </span>
                              <span className="discount-type">OFF</span>
                            </div>
                          </div>

                        }

                        <div className="title">{this.state.offer.offerName}</div>

                        <div className="days"><img className="clock-icon" src={`${imageBasePath}/images/clock-icon-white.png` }  /> {days} Days Left</div>
                        <div className="offer-promo-code">
                          Promo Code <span className="promo">{this.state.offer.promoCode}</span>
                        </div>
                        <div className="offer-promo-code-description">
                          {this.state.offer.description}
                        </div>


                        {this.state.offer.totalAmount ?
                          <div className="offer-price">

                            {this.state.offer.discountPercentage ?
                              this.state.offer.discountPercentage == 100 ?
                                <span className="amount">Free</span>
                                :
                                <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                              :
                              <span className="amount">{Number(this.state.offer.discountedAmount).toFixed(2)} AED</span>
                            }
                            <span className="original">{Number(this.state.offer.totalAmount).toFixed(2)} AED</span>
                          </div>
                          :
                          <div className="offer-price">
                          </div>
                        }



                      </div>
                    </div>
                    }

                  </div>
                </div>
                :
                <div>


                </div>
              }


              <div id="quick-booking-section">




                <div className="quick-booking-form quick-booking-landing-form col-md-12 col-sm-12 col-xs-12">

                  <div className={this.state.showSubCategory? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">Select Services
                      <span className="close" onClick={(e) => {
                        this.setState({
                          showSubCategory:false,
                          hideForm:false
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="services"
                          id="services"
                          className="form-control quick-search-input"
                          placeholder="Services"
                          value={this.state.searchServiceTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteServices(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(servicesList, (service, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {


                                      this.formData["category"] =  service.serviceId;
                                      this.errors["category"] = false;

                                      this.setState({
                                        showSubCategory:false,
                                        hideForm:false,
                                        subServiceId: service.serviceId,
                                        subServiceName: service.name,
                                      });


                                    }}>
                                    <label>{service.name}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.hideForm && !this.state.hideForm1? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">Select Manufacturer
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="manufacturer"
                          id="manufacturer"
                          className="form-control quick-search-input"
                          placeholder="Manufacturer"
                          value={this.state.searchManufacturerTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteManufacturer(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(carList, (carItem, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      this.formData["make"] = carItem.name;
                                      this.props.actions.getCarModels(carItem.id);
                                      this.setState({
                                        hideForm1: true,
                                        hideForm2: false,
                                        selectedManufacturer: carItem.name,
                                        carModel: carItem.carmodel,
                                        selectedCarMakeKey: carItem.id,
                                        filteredCarModelList: carItem.carmodel
                                      });
                                      this.errors["make"] = false;
                                      this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

                                    }}>
                                    <label>{carItem.name}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.hideForm  && this.state.hideForm1 && !this.state.hideForm2? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="model"
                          id="model"
                          className="form-control quick-search-input"
                          placeholder="Model"
                          value={this.state.searchModelTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteModel(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(carModalList, (carItem, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {

                                      this.formData['model_id'] = carItem.id;
                                      this.formData['model'] = carItem.name;
                                      this.setState({
                                        selectedModel: carItem.name,
                                        'modelListDD': !this.state.modelListDD,
                                        hideForm1: true,
                                        hideForm2: true,
                                        hideForm3: false,
                                      });
                                      this.errors["model_id"] = false;
                                      this.errors["model"] = false;


                                    }}>
                                    <label>{carItem.name}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.hideForm  && this.state.hideForm1 && this.state.hideForm2 && !this.state.hideForm3? "form car-details": "hide car-details"}>
                    <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
                      <span className="close" onClick={(e) => {
                        this.setState({
                          hideForm:false,
                          searchManufacturerTerm:"",
                          searchYearTerm:"",
                          searchModelTerm:""
                        });
                      }}><i className="mdi mdi-close"></i></span>
                    </div>
                    <div className="row">
                      <div className="col-12">

                        <input
                          type="text"
                          name="year"
                          id="year"
                          className="form-control quick-search-input"
                          placeholder="Year"
                          value={this.state.searchYearTerm}
                          onChange={e => {
                            e.preventDefault();
                            this.autoCompleteYear(e.target.value );
                          }}
                        />
                        <div className="car-search">
                          <ul>
                            <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                              {map(this.state.allYears, (list, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      this.setState({ selectedYear: list,hideForm3:true,hideForm:false });
                                      this.formData["year"] = list.toString();
                                      this.setState({ 'yearListDD': !this.state.yearListDD });
                                      this.errors["year"] = false;
                                      this.errors["car_details"] = false;
                                      this.formData["car_details"] = "car_details";
                                    }}>
                                    <label>{list}</label>
                                  </li>
                                );
                              })}
                            </CustomScroll>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form className={this.state.hideForm? "hide form": "form"}>
                    <div className="row">
                      <div className="col-12">

                        <div className="car-details-div">
                          <TextInput
                            label="Car Details.*"
                            name="car_details"
                            id="car_details"
                            type="text"
                            value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                            showValidationError={this.errors['car_details']}
                            emptyError={validationMessage.car_details.emptyError}
                            validationError={validationMessage.car_details.validationError}
                            onFocus={this.chooseCarDetails.bind(this)} />

                          <span className="close" onClick={(e) => {


                            this.setState({
                              selectedManufacturer:"Manufacturer",
                              selectedModel:"Model",
                              selectedYear:"Year"
                            });

                            this.formData["make"] = "";
                            this.formData['model_id'] = "";
                            this.formData['model'] = "";
                            this.formData["year"] = "";
                            this.formData["car_details"] = "";

                            this.errors["car_details"] = true;



                          }}><i className="mdi mdi-close"></i></span>

                        </div>

                      </div>

                      <div className="col-12">
                        <TextInput
                          label="Plate No.*"
                          name="plate_no"
                          id="plate_no"
                          type="text"
                          showValidationError={this.errors['plate_no']}
                          emptyError={validationMessage.platenumber.emptyError}
                          validationError={validationMessage.platenumber.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
{/*                      <div className="col-12">
                        <TextInput
                          label="Name"
                          name="name"
                          id="name"
                          type="text"
                          showValidationError={this.errors['name']}
                          emptyError={validationMessage.name.emptyError}
                          validationError={validationMessage.name.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>*/}
                      <div className="col-12">
                        <TextInput
                          type="email"
                          label="E-mail ID*"
                          name="email"
                          id="email"
                          showValidationError={this.errors['email']}
                          emptyError={validationMessage.email.emptyError}
                          validationError={validationMessage.email.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
                      <div className="col-12">
                        <TextInput
                          type="phone"
                          label="Phone Number*"
                          placeholder="Mobile Number"
                          name="phone"
                          id="phone"
                          showValidationError={this.errors['phone']}
                          emptyError={validationMessage.phone.emptyError}
                          validationError={validationMessage.phone.validationError}
                          onBlur={this.onFieldBlur.bind(this)} />
                      </div>
                      <div className="col-12 text-center quick-button">
                        <Button dataLabelledby="Quick Booking User SignUp"
                                btnType="red"
                                btnSize="sm"
                                fontSize={13}
                                label="Book Now"
                                onSumbit btnCallBack={this.onSubmit.bind(this)}
                                isButtonLoading={this.state.isButtonLoading} />
                      </div>
                      <div className="col-12 quick-terms">
                        <div className="checkbox-style checkbox-wrapper n-align">
                          <input
                            type="checkbox"
                            id="check-box"
                            onChange={e => {
                              this.setState({ terms: !e.target.checked });
                              this.formData.terms = e.target.checked;
                              this.errors.terms = !e.target.checked;
                            }}
                            checked={this.formData.terms}
                          />
                          <label htmlFor="check-box" className="custom-checkbox" />
                          <label htmlFor="" className="n-signup-checkbox">
                            By using Carcility, you agree to our
                            <a
                              onClick={() => {
                                // this.props.router.push(`${appBasePath}/terms`);
                                window.open(`${appBasePath}/terms`, "_blank");
                              }}
                              className="blue-text"
                            >
                              {" "}
                              Terms & Conditions
                            </a>, and{" "}
                            <a
                              onClick={() => {
                                // this.props.router.push(`${appBasePath}/privacy`);
                                window.open(`${appBasePath}/privacy`, "_blank");
                              }}
                              className="blue-text"
                            >
                              Privacy Policy
                            </a>.
                          </label>
                        </div>
                        <p
                          className={
                            this.errors.terms
                              ? "note-text tc-text error"
                              : "note-text tc-text hide"
                          }
                        >
                    <span className="error-text">
                      {"Please agree to the terms and condition"}
                    </span>
                        </p>


                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

            : <PageLoader /> }


          <div className="advantage-section responsive-book-now-section">
              <div className="container">
                <div className="col-12 text-center quick-button">
                  <Button dataLabelledby="Quick Booking User SignUp"
                          btnType="red"
                          btnSize="sm"
                          fontSize={13}
                          label="Book Now"
                          onSumbit btnCallBack={this.showFormModal}
                  />
                </div>
              </div>

          </div>

        {/* advantage section */}
        <div className="advantage-section description-section">

            <div className="container">

              <div  dangerouslySetInnerHTML={ {__html: this.state.offer.seoDescription}}/>
              <span onClick={() => {
                let url = "/offer-terms/"+this.state.offer.id;
                window.open(url, '_blank');

              }}>*Terms and Conditions Apply</span>
            </div>

        </div>

          <div className="advantage-section advantage-section-quick-booking-landing">

            <div className="container">
              <div className="col-md-4 col-sm-12 col-xs-12 text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder al-image-holder-align">
                    <img src={`${imageBasePath}/images/quick-booking/easy-to-use-round.svg`} alt="book car service in dubai" />
                  </div>
                  <h5 className="green-color">Easy to use</h5>
                  <p>Book your car service with a few clicks.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder al-image-holder-align">
                    <img src={`${imageBasePath}/images/quick-booking/instant-quotes-round.svg`} alt="instant car service booking" />
                  </div>
                  <h5 className="orange-color"> Instant quotes </h5>
                  <p>Receive and compare instant quotes from different service providers.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12  text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder al-image-holder-align">
                    <img src={`${imageBasePath}/images/quick-booking/trusted-service-round.svg`} alt="trusted car booking services" />
                  </div>
                  <h5 className="blue-color"> Trusted service </h5>
                  <p>
                    {' '}
                    Extensively vetted service providers, for a hassle-free experience.
                    {' '}
                  </p>
                </div>
              </div>
            </div>
        </div>



          <Element name="how-works " className="element how-works-quick-landing">
            <div id="how-works">
              <div className="common-heading text-center wow slideInUp">
                <h2>How it Works</h2>
              </div>
              <section id="hiw-timeline" className="container quick-booking-landing-how-it-works">
                <div className="row">
                  <section className="ps-timeline-sec">
                    <div className="container">
                      <p className="ps-line"></p>
                      <ol className="ps-timeline">
                        <li className="first">
                          <div className="img-handler-top"><img src={`${imageBasePath}/images/quick-booking/choose-your-service.svg`}  alt="" /></div>
                          <div className="ps-bot"><h2>Choose your service</h2><p>Select your car, tell us what you need
                            for your car.</p></div>
                          <span className="ps-sp-top"></span>
                          </li>
                        <li className="middle">
                          <div className="img-handler-top"><img src={`${imageBasePath}/images/quick-booking/recieve-your-quotes.svg`} alt=""/></div>
                          <div className="ps-bot"><h2>Receive your quotes </h2><p>Sit back and watch the quotes for your
                            request start rolling in. Compare quotes and book time at your selected shop based on
                            availability, price or rating.</p></div>
                          <span className="ps-sp-top"></span></li>
                        <li className="last">
                          <div className="img-handler-top"><img src={`${imageBasePath}/images/quick-booking/get-your-job-done.svg`}alt="" /></div>
                          <div className="ps-bot"><h2>Get your job done</h2><p>Search. Stop calling and visit more
                            shops. No hassles !</p></div>
                          <span className="ps-sp-top"></span></li>
                      </ol>
                    </div>
                  </section>
                </div>
                <div className="line-block hide"><label></label></div>
              </section>
              <section id="hiw-timeline-mob" className="container">

                <div className="timeline-container">
                  <div className="timeline-item one">
                    <div className="timeline-item-content">
                      <img src={`${imageBasePath}/images/quick-booking/choose-your-service.svg`}  alt="" />
                      <div className="timeline-item-border">
                        <h2>Choose your service</h2>
                        <p>Select your car, tell us what you need for your car.</p>
                      </div>

                      <span className="circle" />
                    </div>
                  </div>
                  <div className="timeline-item two">
                    <div className="timeline-item-content">
                      <img src={`${imageBasePath}/images/quick-booking/recieve-your-quotes.svg`} alt=""/>
                      <div className="timeline-item-border">
                        <h2>Receive your quotes </h2>
                        <p>Sit back and watch the quotes for your
                          request start rolling in. Compare quotes and book time at your selected shop based on
                          availability, price or rating.</p>
                      </div>

                      <span className="circle" />
                    </div>
                  </div>
                  <div className="timeline-item three">
                    <div className="timeline-item-content">
                      <img src={`${imageBasePath}/images/quick-booking/get-your-job-done.svg`}alt="" />
                      <div className="timeline-item-border">

                        <h2>Get your job done</h2>
                        <p>Search. Stop calling and visit more
                          shops. No hassles !</p>

                      </div>
                      <span className="circle" />
                    </div>
                  </div>
                </div>
                <div className="line-block hide">
                  <label />
                </div>
              </section>
            </div>
          </Element>


          {/* Download section */}
          <Element name="download" className="element  download-quick-landing">
            <div id="download-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-offset-5 col-md-7 col-sm-12 col-xs-12 margin-align">
                    <div className="download-desc">
                      <div className="wow slideInDown">
                        <h5>We got you covered!</h5>
                        <p>
                          Take us with you to compare and book service with confidence whenever, wherever.!
                        </p>
                      </div>
                      <div className="wow slideInUp">
                        <label>Download the app!</label>
                        <div className="download-form">
                          <form className="form-inline">

                            <div className="input-group">
                              <span className="countryCode">+971</span>
                              <input
                                placeholder="Enter Phone Number"
                                type="number"
                                className="form-control"
                                onChange={e => {
                                  this.setState({ appDownloadPhoneNumber: e.target.value, showPhoneNoError: false });
                                }}
                                value={this.state.appDownloadPhoneNumber}
                                onBlur={e => {
                                  this.setState({ showPhoneNoError: !validateMobile(e.target.value) });
                                }}
                              />
                              {this.state.appDownloadPhoneNumber &&
                              this.state.showPhoneNoError &&
                              !validateMobile(this.state.appDownloadPhoneNumber) &&
                              <span className="error-text">{validationMessage.phone.validationError}</span>}
                              {/* <input type="text" className="form-control" placeholder="Enter Phone Number" /> */}
                              {/*<Phone
                                                    country="IN"
                                                    placeholder="Enter phone number"
                                                    value={ this.state.value }
                                                    onChange={ value => this.setState({ value }) }/>*/}
                              <span className="input-group-btn" data-labelledby="Text App Link111">
                                <button
                                  data-labelledby="Text App Link"
                                  className="btn btn-default"
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault();
                                    if (!this.state.showPhoneNoError && this.state.appDownloadPhoneNumber) {
                                      this.props.actions.getAppDownloadLink('+971' + this.state.appDownloadPhoneNumber);
                                    }
                                  }}
                                >
                                  Text App Link
                                </button>
                              </span>
                            </div>
                          </form>
                        </div>
                        <ul className="list-unstyled">
                          <li>
                            <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                              <img src={`${imageBasePath}/images/quick-booking/download-app-store.png`} alt="car service app - play store" />
                            </a>
                          </li>
                          <li>
                            <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                              <img src={`${imageBasePath}/images/quick-booking/download-google-play.png`} alt="car service app - app store" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mob-img">
                  <img src={`${imageBasePath}/images/landing/car-service-booking-app-dubai.svg`} alt="car service booking app dubai" />
                </div>
                <div className="highlight-text wow slideInUp">
                  <label>Download</label>
                </div>
              </div>
            </div>
          </Element>

          <Element name="overview" className="element">
            <div className="common-wrapper" id="manufactures">

              <div className="container">
                <h2>Brands We Service</h2>
              </div>

              <div className="container container-border">
                <div className="row">
                  <Tabs defaultActiveKey="home" transition={false} id="main-tab">
                    <Tab eventKey="home" title="Top Makes">

                      <CustomScroll heightRelativeToParent="480px" allowOuterScroll={true}>
                        <div className="car_prices">
                          {map(manufactures, (item, key) => {
                            return (
                                <div className="col-md-2 col-sm-6 col-xs-6  make-list">
                                  <i className={"make-logo "+item.icon}></i>
                                </div>

                            )
                          }) }
                        </div>
                      </CustomScroll>

                    </Tab>


                  </Tabs>


                </div>
              </div>


            </div>
          </Element>

          {this.props.params.id && this.props.params.id == 17 ||  this.props.params.id == 254  ||  this.props.params.id == 292 &&
            <Element name="overview" className="element">
              <div className="common-wrapper" id="faq">
                <div className="container">
                  <h2>FAQs</h2>
                  {faqsHtml}
                </div>


              </div>
            </Element>
          }
            {/* testimonials */}
            <Element name="overview" className="element quick-booking-landing">
              <div className="common-wrapper" id="testimonial-section">


                <div className="workshop-container container">
                  <div className="workshop-heading wow slideInUp">
                    <h2>Hear from our Customers</h2>

                  </div>
                  {/* <div className="padwrapper"> */}

                  <div className="row ">




                      <CustomerTestimonials
                      />


                    </div>


                </div>
              </div>
            </Element>




          <CustomModal className="quick-landing-modal" showModal={this.state.showFormModal} footer={false}
                       closeIcon={true}  hideModal={() => { this.setState({ showFormModal: false }) }}>
            <Modal.Body>
              <div className="quick-booking-form quick-booking-landing-form">


                <div className={this.state.showSubCategory? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">Select Services
                    <span className="close" onClick={(e) => {
                      this.setState({
                        showSubCategory:false,
                        hideForm:false
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="services"
                        id="services"
                        className="form-control quick-search-input"
                        placeholder="Services"
                        value={this.state.searchServiceTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteServices(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(servicesList, (service, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {


                                    this.formData["category"] =  service.serviceId;
                                    this.errors["category"] = false;

                                    this.setState({
                                      showSubCategory:false,
                                      hideForm:false,
                                      subServiceId: service.serviceId,
                                      subServiceName: service.name,
                                    });


                                  }}>
                                  <label>{service.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm && !this.state.hideForm1? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">Select Manufacturer
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="manufacturer"
                        id="manufacturer"
                        className="form-control quick-search-input"
                        placeholder="Manufacturer"
                        value={this.state.searchManufacturerTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteManufacturer(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(carList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.formData["make"] = carItem.name;
                                    this.props.actions.getCarModels(carItem.id);
                                    this.setState({
                                      hideForm1: true,
                                      hideForm2: false,
                                      selectedManufacturer: carItem.name,
                                      carModel: carItem.carmodel,
                                      selectedCarMakeKey: carItem.id,
                                      filteredCarModelList: carItem.carmodel
                                    });
                                    this.errors["make"] = false;
                                    this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && !this.state.hideForm2? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="model"
                        id="model"
                        className="form-control quick-search-input"
                        placeholder="Model"
                        value={this.state.searchModelTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteModel(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(carModalList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {

                                    this.formData['model_id'] = carItem.id;
                                    this.formData['model'] = carItem.name;
                                    this.setState({
                                      selectedModel: carItem.name,
                                      'modelListDD': !this.state.modelListDD,
                                      hideForm1: true,
                                      hideForm2: true,
                                      hideForm3: false,
                                    });
                                    this.errors["model_id"] = false;
                                    this.errors["model"] = false;


                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && this.state.hideForm2 && !this.state.hideForm3? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="year"
                        id="year"
                        className="form-control quick-search-input"
                        placeholder="Year"
                        value={this.state.searchYearTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteYear(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(this.state.allYears, (list, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.setState({ selectedYear: list,hideForm3:true,hideForm:false });
                                    this.formData["year"] = list.toString();
                                    this.setState({ 'yearListDD': !this.state.yearListDD });
                                    this.errors["year"] = false;
                                    this.errors["car_details"] = false;
                                    this.formData["car_details"] = "car_details";
                                  }}>
                                  <label>{list}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <form className={this.state.hideForm? "hide form": "form"}>
                  <div className="row">
                    <div className="col-12">

                      <div className="car-details-div">
                        <TextInput
                          label="Car Details.*"
                          name="car_details"
                          id="car_details"
                          type="text"
                          value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                          showValidationError={this.errors['car_details']}
                          emptyError={validationMessage.car_details.emptyError}
                          validationError={validationMessage.car_details.validationError}
                          onFocus={this.chooseCarDetails.bind(this)} />

                        <span className="close" onClick={(e) => {


                          this.setState({
                            selectedManufacturer:"Manufacturer",
                            selectedModel:"Model",
                            selectedYear:"Year"
                          });

                          this.formData["make"] = "";
                          this.formData['model_id'] = "";
                          this.formData['model'] = "";
                          this.formData["year"] = "";
                          this.formData["car_details"] = "";

                          this.errors["car_details"] = true;



                        }}><i className="mdi mdi-close"></i></span>

                      </div>

                    </div>

                    <div className="col-12">
                      <TextInput
                        label="Plate No.*"
                        name="plate_no"
                        id="plate_no"
                        type="text"
                        showValidationError={this.errors['plate_no']}
                        emptyError={validationMessage.platenumber.emptyError}
                        validationError={validationMessage.platenumber.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        label="Name.*"
                        name="name"
                        id="name"
                        type="text"
                        showValidationError={this.errors['name']}
                        emptyError={validationMessage.name.emptyError}
                        validationError={validationMessage.name.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        type="email"
                        label="E-mail ID*"
                        name="email"
                        id="email"
                        showValidationError={this.errors['email']}
                        emptyError={validationMessage.email.emptyError}
                        validationError={validationMessage.email.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12">
                      <TextInput
                        type="phone"
                        label="Phone Number*"
                        placeholder="Mobile Number"
                        name="phone"
                        id="phone"
                        showValidationError={this.errors['phone']}
                        emptyError={validationMessage.phone.emptyError}
                        validationError={validationMessage.phone.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12 text-center quick-button">
                      <Button dataLabelledby="Quick Booking User SignUp"
                              btnType="red"
                              btnSize="sm"
                              fontSize={13}
                              label="Book Now"
                              onSumbit btnCallBack={this.onSubmit.bind(this)}
                              isButtonLoading={this.state.isButtonLoading} />
                    </div>
                    <div className="col-12 quick-terms">
                      <p
                        className={
                          this.errors.terms
                            ? "note-text tc-text error"
                            : "note-text tc-text hide"
                        }
                      >
                    <span className="error-text">
                      {"Please agree to the terms and condition"}
                    </span>
                      </p>


                    </div>

                  </div>
                </form>


              </div>
            </Modal.Body>
          </CustomModal>




        {/* footer */}
        <Footer {...this.props} />

        {/* sidebar menu */}
        <div className={this.state.sidebarOpen ? 'menu-sidebar active' : 'menu-sidebar inactive'}>
          {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
          <div className="nonSignup-list">
            <ul className="list-unstyled">
              <li
                onClick={e => {
                  e.preventDefault();
                  this.props.router.push(appBasePath + '/sign-in');
                }}
              >
                <span>
                  LOG IN
                </span>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  this.props.router.push(appBasePath + '/sign-up');
                }}
              >
                <span>
                  SIGN UP
                </span>
              </li>
              <li>
                <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                  </span>
                </Link>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  window.location.pathname = '/business';
                }}
              >
                <span>
                  FOR BUSINESS
                </span>
              </li>
            </ul>
          </div>
        </div>
        {this.state.searchMobile &&
        <div>

          <MobileHeaderSearch
            mobile={true}
            actions={this.props.actions}
            commonReducer={this.props.commonReducer}
            mobSearchClick={() => this.setState({ searchMobile: false })}
          />
        </div>}
      </div>
      </div>
    );
  }
}
