import React, { Component } from 'react';
import Search from '../common/Search';

class MobileHeaderSearch extends Component {
    constructor(){
        super()
        this.savedLocation = [
            {
              address: "kr market",
              name: "home"
            },
            {
              address: "551, Mg Road",
              name: "work"
            }, {
              address: "mysore road"
            }
          ];
          this.dropdownList = ["Audi", "Renault", "BMW", "Benz", "Maruthi", "Tesla"];
    }
    render() {
        
        return (
            <div className="n-mob-search header-search">
                <a><i className="mdi mdi-arrow-left" onClick={this.props.mobSearchClick}> </i></a>
                <Search mobile="true" savedLocation={this.savedLocation} actions={this.props.actions} commonReducer={this.props.commonReducer} dropdownList={this.dropdownList}/>
                
                
            </div>
        );
    }
}

export default MobileHeaderSearch;