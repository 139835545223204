import React from 'react';
import FloatingMenuItem from './FloatingMenuItem';
import { phoneNumber } from'../../constants/staticData';
import ModalVideo from 'react-modal-video';

export default class FloatingMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      showYoutubePromo:false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateQuicIcon);
  }

  updateQuicIcon() {
    if(window.innerWidth <= 830 && window.Tawk_API.hasOwnProperty('getStatus')) {
      window.Tawk_API.hideWidget();
    } else if(window.innerWidth > 830 && window.Tawk_API.hasOwnProperty('getStatus')){
      window.Tawk_API.showWidget();
    }
  }


  toggleMenu() {
    this.setState({ toggled: !this.state.toggled });
  }

  playPromoVideo() {
    if(!this.state.showYoutubePromo) {
      this.toggleMenu();
    }
    this.setState({ showYoutubePromo: !this.state.showYoutubePromo });
  }

  chatCloseToggleMenu() {
    this.toggleMenu();
  }

  render() {
    let className = "floating-menu";
    const { toggled, showYoutubePromo } = this.state;
    let icon = "add";
    if (this.state.toggled) {
      className += " open";
      icon = "clear";
    }

    return (
      <div>
      <ModalVideo channel='youtube' isOpen={showYoutubePromo} videoId='h736TiMyfrs' onClose={() => this.playPromoVideo()} />
      <div className={toggled ? "floating-menu-overlay floating-menu-container": "floating-menu-container "} >
        <div className={className}>
         {toggled && <div> 
          <FloatingMenuItem label="Chat With Us" icon="create" classShow="" action={()=>this.chatCloseToggleMenu()} key="i1" >
            <a href="javascript:void(Tawk_API.maximize())">
              <img className="quick-chat" src="/images/landing/chat.png" alt="app store" />
            </a>
          </FloatingMenuItem>
          <FloatingMenuItem label="Call" icon="create" classShow="" action="" key="i2" >
            <a href={`tel:${phoneNumber}`} target="_blank">
              <img className="quick-call" src="/images/landing/call.png" alt="app store" />
            </a>
          </FloatingMenuItem>
          <FloatingMenuItem label="WhatsApp" icon="create" classShow="" action="" key="i3" >
            <a href={`//api.whatsapp.com/send?phone=${phoneNumber}`} target="_blank">
              <img className="quick-watsapp" src="/images/landing/whatsapp.svg" alt="app store" />
            </a>
          </FloatingMenuItem>
          <FloatingMenuItem label="Know Carcility" classShow="" icon="create" playPromo={() => this.playPromoVideo()} action="" key="i4" >
              <img className="quick-promo" src="/images/landing/play.png" alt="app store" />
          </FloatingMenuItem>
          </div> }
          <FloatingMenuItem enableOpac={this.props.enableOpac} classOpenClose={toggled ? "open-icon":"close-icon"} classShow="fb-open" label="" action={this.toggleMenu.bind(this)} key="m" >
          {!toggled && <img className="quick-open" src="/images/landing/open.png" alt="open" />}
          {toggled && <img className="quick-close" src="/images/landing/close.png" alt="close" />}
          </FloatingMenuItem>
        </div>
      </div>
      </div>
    );
  }
}