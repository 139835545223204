import * as types from "./actionTypes";
import * as API_END_POINTS from "../constants/api.js";
import axios from "axios";
import { appBasePath } from "../constants/staticData";
// import { map } from 'lodash';

export function getAllBookingRequests(
  requestType,
  pageSize,
  page,
  fromLoadMore,
  filterSort,
  filterStatus,
  filterService,
  vehicleId,
  urgentRequest
) {
  return dispatch => {
    let status = "";
    if (!filterStatus || filterStatus.length == 0) {
      if (requestType == "all") {
        status = "?status=1,2,3,4,5,6,7,8,11";
      } else if (requestType == "open") {
        status = "?status=1,2,3,4,8,11";
      } else if (requestType == "closed") {
        status = "?status=5,6,7&paymentstatus=paid";
      }else if (requestType == "completed") {
        status = "?status=6&paymentstatus=unpaid";
      }
    } else if (filterStatus && filterStatus.length) {
      status = `?status=${filterStatus}`;
    }
    if (pageSize && status) {
      status = status + "&limit=" + pageSize;
    }
    if (page != undefined && status) {
      status = status + "&offset=" + page;
    }
    if (filterSort == "dateON") {
      status = status + "&sortby=date";
    }
    if (filterService && filterService.length > 0) {
      status = status + `&types=${filterService}`;
    }
    if (vehicleId) {
      status = status + `&profile=${vehicleId}`;
    }
    if (urgentRequest) {
      status = status + `&onlyurgent=1`;
    }
    axios.get(API_END_POINTS.GET_ALL_BOOKING_REQUESTS + status + '&imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.status == 200 && response.data) {
          dispatch({
            type: types.GET_ALL_BOOKING_REQUESTS,
            bookingRequests:
              response.data && response.data.data ? response.data.data : [],
            requestType: requestType,
            pageInfo:
              response.data && response.data.pageInfo
                ? response.data.pageInfo
                : {},
            currentPage: page,
            fromLoadMore: fromLoadMore,
            unableToFetch: false
          });
        } else {
          dispatch({
            type: types.GET_ALL_BOOKING_REQUESTS,
            unableToFetch: true
          });
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unable to fetch booking requests, please try again"
          });
        }
      })
      .catch(err => {
        dispatch({
          type: types.GET_ALL_BOOKING_REQUESTS,
          unableToFetch: true
        });
        if (err.response.status === 403) {
          dispatch({
            type: types.LOGOUT
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "System error, please try later"
          });
        }
      });
  };
}

export function getBookingRequest(requestId, router) {
  return dispatch => {
    axios
      .get(
        API_END_POINTS.GET_BOOKING_REQUESTS_DETAILS +
        requestId +
        "?imageflow=1",
        { withCredentials: true }
      )
      .then(response => {
        if (response.status == 200) {
          if ((response.data && response.data.requeststatus && response.data.requeststatus.name && response.data.requeststatus.name == 'Active') ||
            (response.data && response.data.previousrequeststatus && response.data.previousrequeststatus.name && response.data.previousrequeststatus.name == 'Active')) {
            dispatch(getVendorQuotesList(response.data.id));
          }
          dispatch({
            type: types.GET_BOOKING_REQUESTS_DETAILS,
            bookingRequestData: response.data,
            requestId: requestId
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (
            err.response &&
            err.response.status &&
            err.response.status == 404
          ) {
            router.push(`${appBasePath}/404`);
          } else if (
            err.response &&
            err.response.status &&
            err.response.status === 403
          ) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "System error, please try later"
            });
          }
        }
      });
  };
}

export function getVendorQuotesList(requestId, sortBy, distValue, priceValue, daysSelected, rangeFrom, rangeTo, ratingValue, toggleSwitchVal) {
  return (dispatch) => {
    const defaultLocation = {
      formatted_address: "Dubai - United Arab Emirates",
      label: "Dubai - United Arab Emirates",
      lat: 25.2048493,
      lng: 55.270782800000006,
    };
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : defaultLocation;
    let queryParams = '';
    if (sortBy) {
      queryParams = "&sortby=" + sortBy;
    }
    if (userSelectedLocation && userSelectedLocation.lat && userSelectedLocation.lng) {
      queryParams = queryParams + '&lat=' + userSelectedLocation.lat + '&lng=' + userSelectedLocation.lng;
    }
    if (distValue) {
      queryParams =
        queryParams +
        "&distancefrom=" +
        distValue.min +
        "&distanceto=" +
        distValue.max;
    }
    if (priceValue) {
      queryParams =
        queryParams +
        "&pricefrom=" +
        priceValue.min +
        "&priceto=" +
        priceValue.max;
    }
    if (daysSelected && daysSelected.length) {
      queryParams = queryParams + "&day=" + daysSelected;
    }
    if (rangeFrom && rangeTo) {
      queryParams = queryParams + "&from=" + rangeFrom + "&to=" + rangeTo;
    }
    if (ratingValue) {
      queryParams = queryParams + "&rating=" + ratingValue;
    }
    if (toggleSwitchVal && toggleSwitchVal["byCash"]) {
      queryParams = queryParams + "&cashpayment=" + 1;
    } else if (toggleSwitchVal && toggleSwitchVal["byCreditcard"]) {
      queryParams = queryParams + "&ccpayment=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["showFavourites"]) {
      queryParams = queryParams + "&favonly=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["Open24_7"]) {
      queryParams = queryParams + "&wholeweek=" + 1;
    }
    axios.get(API_END_POINTS.GET_VENDOR_QUOTES_LIST + '?requestid=' + requestId + queryParams + '&imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.GET_VENDOR_QUOTES_LIST,
            requestId: response.data && response.data.requestid,
            vendorQuotesList: response.data
          });
          let garagejobquote = response.data.garagejobquotes && response.data.garagejobquotes[0];
          if (garagejobquote) {
            dispatch(getQuotesPricingDetails(response.data.id, garagejobquote.id));
          }
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "System error, please try later"
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 403
          ) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "System error, please try later"
            });
          }
        }
      });
  };
}

export function getQuotesPricingDetails(requestId, jobId) {
  return dispatch => {
    axios
      .get(
        API_END_POINTS.GET_QUOTES_PRICING_DETAILS +
        "?requestid=" +
        requestId +
        "&jobid=" +
        jobId,
        { withCredentials: true }
      )
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: types.GET_QUOTES_PRICING_DETAILS,
            quotesDetails: response.data,
            jobId: jobId,
            requestId: response.data && response.data.requestid
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err.response && err.response.status === 403) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "System error, please try later"
            });
          }
        }
      });
  };
}

export function clearImageArray() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_IMAGES
    });
  };
}

export function getAllServices(callback) {
  return dispatch => {
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem('accessToken');
    axios
      .get(API_END_POINTS.GET_ALL_SERVICES, { withCredentials: true })
      .then(response => {
        if (response.status == 200 || response.status == 304) {
          console.log("Success = ",response.data)
          if (typeof callback == 'function') {
            callback(response.data);
          }
          dispatch({
            type: types.GET_ALL_SERVICES,
            getAllServices: response.data,
            servicesFetched: true
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 403
          ) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "System error, please try later"
            });
          }
        }
      });
  };
}

export const handleChecked = (obj,item) =>dispatch=>{
dispatch({type:types.ADD_CHECKED,payload:{obj,item}})

}

export function getAllCancelReasons() {
  return dispatch => {
    axios
      .get(API_END_POINTS.GET_ALL_CANCEL_REASONS, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: types.GET_ALL_CANCEL_REASONS,
            getAllCancelReasons:
              response.data && response.data.length > 0 && response.data
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 403
          ) {
            dispatch({
              type: types.LOGOUT
            });
          } else if (
            err.response &&
            err.response.status &&
            (err.response.status === 404 ||
              err.response.status === 401 ||
              err.response.status === 410)
          ) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response.data
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: "System error, please try later"
            });
          }
        }
      });
  };
}

export function bookingUploadImage(
  imageId,
  fileName,
  fromAction,
  batch_id,
  success
) {
  console.log(`BOOKING UPLOAD IMAGE CALLED imageId-${imageId}, filename-${fileName},
  fromAction - ${fromAction}, batch_id - ${batch_id}, success- ${success}`);
  return dispatch => {
    if (success) {
      dispatch({
        type: types.SUCCESS_UPLOAD_IMAGES,
        batch_id: batch_id,
        fromAction: fromAction,
        imgData: {
          id: imageId,
          fileName: fileName
        }
      });
    } else {
      dispatch({
        type: types.FAILURE_UPLOAD_IMAGES,
        batch_id: batch_id
      });
    }
  };
  // return (dispatch) => {
  //   imageUploaded.map((value, key) => {
  //     const formData = new FormData();
  //     formData.append('batch_id', batch_id);
  //     formData.append('photos', value);
  //     axios.post(API_END_POINTS.BOOKING_UPLOAD_IMAGES, formData, {
  //       headers: {
  //         'Accept': 'application/json,',
  //         'Content-Type': 'application/json'
  //       },
  //       withCredentials: true
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         dispatch({
  //           type: types.SUCCESS_UPLOAD_IMAGES,
  //           batch_id: batch_id,
  //           fromAction: fromAction,
  //           imgData: {
  //             id: response.data.id,
  //             url: response.data,
  //             fileName: files[key].fileName,
  //           }
  //         });
  //       }
  //     }).catch(() => {
  //       dispatch({
  //         type: types.FAILURE_UPLOAD_IMAGES,
  //         batch_id: batch_id,
  //       });
  //     });
  //   });
  // };
}

export function bookingUploadFile(
  imageId,
  url,
  batch_id,
  type,
  fromAction,
  success
) {
  return dispatch => {
    if (success) {
      dispatch({
        type: types.SUCCESS_UPLOAD_IMAGES,
        batch_id: batch_id,
        fileData: {
          id: imageId,
          url: url,
          type: type
        },
        fromAction: fromAction
      });
    } else {
      dispatch({
        type: types.FAILURE_UPLOAD_IMAGES,
        batch_id: batch_id
      });
    }

    // imageUploaded.map((value) => {
    //   const formData = new FormData();
    //   formData.append('batch_id', batch_id);
    //   formData.append('docs', value);
    //   formData.append('type', type);
    //   axios.post(API_END_POINTS.BOOKING_UPLOAD_FILES, formData, {
    //     headers: {
    //       'Accept': 'application/json,',
    //       'Content-Type': 'application/json'
    //     },
    //     withCredentials: true
    //   }).then((response) => {
    //     if (response.status === 200) {
    //       dispatch({
    //         type: types.SUCCESS_UPLOAD_IMAGES,
    //         batch_id: batch_id,
    //         fileData: {
    //           id: imageId,
    //           type: type
    //         },
    //         fromAction: fromAction
    //       });
    //     }
    //   }).catch(() => {
    //     dispatch({
    //       type: types.FAILURE_UPLOAD_IMAGES,
    //       batch_id: batch_id,
    //     });
    //   });
    // });
  };
}

export function cancelUserBookinRequest(payload) {
  return dispatch => {
    axios
      .post(
        API_END_POINTS.CANCEL_USER_BOOKING_REQUEST,
        JSON.stringify(payload),
        {
          headers: {
            Accept: "application/json,",
            "Content-Type": "application/json"
          },
          withCredentials: true
        }
      )
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: types.CANCEL_USER_BOOKING_REQUEST
          });
        }
      });
  };
}

export function createBatchID(batch_id) {
  return dispatch => {
    dispatch({
      type: types.CREATE_BATCH_ID,
      batch_id: batch_id
    });
  };
}

export function createBookingRequest(payload) {
  
  return dispatch => {
    
    axios
      .post(API_END_POINTS.CREATE_BOOKING_REQUEST, JSON.stringify(payload), {
        headers: {
          Accept: "application/json,",
          "Content-Type": "application/json"
        },
        withCredentials: true
      })
      .then(response => {
        if (
          (response.status === 200 &&
            response.data.msg === "Job Request Already Exists") ||
          response.data.msg ===
          "The userpreffereddatetime must be greater than today"
        ) {
          // dispatch({
          //   type: types.USER_PREFEREDTIME_ERROR,
          //   err_userpreffereddatetime: response.data.msg
          // });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data.msg,
            toastType: "error"
          });
        } else if (response.status === 200) {
          

          dispatch({
            type: types.CREATE_BOOKING_REQUEST,
            batch_id: response.data.batchId,
            requestid: response.data.requestid
            // getAllServices: response.data,
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              "Your request has been created. Wait for a while to receive quotes from vendors",
            toastType: "success"
          });
        }
      })
      .catch(err => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 400
        ) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.data.userpreffereddatetime &&
                err.response.data.userpreffereddatetime.length &&
                err.response.data.userpreffereddatetime[0],
            toastType: "error"
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err.response.data
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "System error, please try later"
          });
        }
      });
  };
}

export function editBookingRequest(requestId, payload) {
  if (requestId) {
    return (dispatch) => {
      
      axios.put(API_END_POINTS.EDIT_USER_BOOKING_REQUEST + '/' + requestId + '?imageflow=1', JSON.stringify(payload), {
        headers: {
          'Accept': 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
        .then((response) => {
          
          if (response.status === 200 && response.data.msg === "Job Request Already Exists") {
            // dispatch({
            //   type: types.USER_PREFEREDTIME_ERROR,
            //   err_userpreffereddatetime: response.data.msg
            // });
            dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              toastMessage: response.data.msg,
              toastType: "error"
            });
          } else if (response.status === 200) {
            
            dispatch({
              type: types.EDIT_BOOKING_REQUEST,
              requestid: response.data.requestid
            });
            dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              toastMessage: "Your Service Request updated successfully",
              toastType: "success"
            });
          }
        })
        .catch(err => {
          if (err && err.config) {
            if (
              err.response &&
              err.response.status &&
              err.response.status === 403
            ) {
              dispatch({
                type: types.LOGOUT
              });
            } else if (
              err.response &&
              err.response.status &&
              err.response.status === 400 &&
              err.response.data &&
              err.response.data.userpreffereddatetime
            ) {
              dispatch({
                type: types.SHOW_TOAST_MESSAGE,
                toastMessage:
                  err.response.data && err.response.data.message
                    ? err.response.data.message
                    : err.response.data.userpreffereddatetime &&
                    err.response.data.userpreffereddatetime.length &&
                    err.response.data.userpreffereddatetime[0],
                toastType: "error"
              });
            } else if (
              err.response &&
              err.response.status &&
              (err.response.status === 404 ||
                err.response.status === 401 ||
                err.response.status === 410)
            ) {
              dispatch({
                type: types.SHOW_ERROR_MESSAGE,
                statusMessage: err.response.data
              });
            } else {
              dispatch({
                type: types.SHOW_ERROR_MESSAGE,
                statusMessage: "Unknown error occurred please try again"
              });
            }
          }
        });
    };
  }
}


export function createQuickBookingRequest(payload) {
  return dispatch => {
    let bookingRequestId = localStorage.getItem('bookingRequestId');
    axios
      .put(API_END_POINTS.CREATE_QUICK_BOOKING_REQUEST+"/"+bookingRequestId, JSON.stringify(payload), {
        headers: {
          Accept: "application/json,",
          "Content-Type": "application/json"
        },
        withCredentials: true
      })
      .then(response => {
        if (
          (response.status === 200 &&
            response.data.msg === "Job Request Already Exists") ||
          response.data.msg ===
          "The userpreffereddatetime must be greater than today"
        ) {
          // dispatch({
          //   type: types.USER_PREFEREDTIME_ERROR,
          //   err_userpreffereddatetime: response.data.msg
          // });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data.msg,
            toastType: "error"
          });
        } else if (response.status === 200) {


          console.log("CREATE_QUICK_BOOKING_REQUEST",response.data)

          localStorage.removeItem("bookingRequestId");

          dispatch({
            type: types.CREATE_BOOKING_REQUEST,
            batch_id: response.data.batch_id,
            requestid: response.data.requestid
            // getAllServices: response.data,
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              "Your request has been created. Wait for a while to receive quotes from vendors",
            toastType: "success"
          });
        }
      })
      .catch(err => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          err.response.status === 400
        ) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.data.userpreffereddatetime &&
                err.response.data.userpreffereddatetime.length &&
                err.response.data.userpreffereddatetime[0],
            toastType: "error"
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err.response.data
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "System error, please try later"
          });
        }
      });
  };
}


export function clearBookingReducerComponentKey() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_COMPONENT_KEY
    });
  };
}

export function acceptQuotes(requestId, jobId, bookingRequestId, router) {
  return dispatch => {
    axios
      .post(
        API_END_POINTS.ACCEPT_QUOTES,
        {
          requestid: requestId,
          jobid: jobId
        },
        { withCredentials: true }
      )
      .then(response => {
        if (response.status == 200) {
          dispatch({
            type: types.ACCEPT_QUOTES,
            requestId: bookingRequestId
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data.msg
              ? response.data.msg
              : "Quote accepted successfully.",
            toastType: "success"
          });
          dispatch(getBookingRequest(bookingRequestId, router));
        } else {
          dispatch({
            type: types.ACCEPT_QUOTES,
            requestId: bookingRequestId
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.msg
                ? response.data.msg
                : "Unable to accept the quotes right now, please try again",
            toastType: "error"
          });
        }
      })
      .catch(err => {
        dispatch({
          type: types.ACCEPT_QUOTES,
          requestId: bookingRequestId
        });
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to accept the quotes right now, please try again",
            toastType: "error"
          });
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to accept the quotes right now, please try again",
            toastType: "error"
          });
        }
      });
  };
}
export function makePayment(requestId, jobId, method) {

  return dispatch => {
    


    var json = {request_id: requestId, job_id: jobId, method: method}

    if(jobId == "plus"){

      json = {request_id: requestId, plus: 1, method:method}
    }
    axios
      .post(
        API_END_POINTS.MAKE_PAYMENT,
        json,
        { withCredentials: true }
      )
      .then(response => {
        

        if(response.status == 200) {
          console.log("Data res = ",response);
          

          console.log("response",response.data.data.url)


          localStorage.setItem('requestId', requestId);
          localStorage.setItem('orderRef', response.data.data.ref);

          dispatch({
            
            type: types.MAKE_PAYMENT,
            url: response.data.data.url?response.data.data.url:"/",
            method:method,
            status: true
          });
        } else {
          dispatch({
            type: types.HIDE_BUTTON_LOADER
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.msg
                ? response.data.msg
                : "Unable to make payment, please try again",
            toastType: "error"
          });
        }
      })
      .catch(err => {

        console.log("error.status",err.status); // undefined

        dispatch({
          type: types.HIDE_BUTTON_LOADER
        });
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to make payment right now, please try again",
            toastType: "error"
          });
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to make payment right now, please try again",
            toastType: "error"
          });

          dispatch({
            type: types.MAKE_PAYMENT,
            status: false
          });
        }
      });
  };
}

export function paymentStatus(orderRef, requestId) {
  return dispatch => {
    axios.get(API_END_POINTS.PAYMENT_STATUS + '?orderRef='+orderRef+'&requestId='+requestId, { withCredentials: true })
      .then(response => {

        if (response.status == 200) {

          localStorage.removeItem("requestId");
          localStorage.removeItem("orderRef");


          dispatch({
            type: types.PAYMENT_STATUS,
            paymentData: response.data.data
          });
        } else {
          dispatch({
            type: types.HIDE_BUTTON_LOADER
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.msg
                ? response.data.msg
                : "Unable to make payment, please try again",
            toastType: "error"
          });
        }
      })
      .catch(err => {


        dispatch({
          type: types.HIDE_BUTTON_LOADER
        });
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to get payment status now, please try again",
            toastType: "error"
          });
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unable to get payment status now, please try again",
            toastType: "error"
          });
        }
      });
  };
}

export function getFavouriteVendors(
  sortBy,
  serviceTypes,
  distValue,
  daysSelected,
  rangeFrom,
  rangeTo,
  toggleSwitchVal,
  ratingValue,
  userLocation
) {
  return dispatch => {
    let queryParams = "";
    if (sortBy) {
      queryParams = "?sortby=" + sortBy;
    }
    if (userLocation && userLocation.lat && userLocation.lng) {
      queryParams =
        queryParams + "&lat=" + userLocation.lat + "&lng=" + userLocation.lng;
    }
    if (serviceTypes && serviceTypes.length)
      queryParams = queryParams + "&serviceids=" + serviceTypes;
    if (distValue && distValue.min && distValue.max) {
      queryParams =
        queryParams + "&fromd=" + distValue.min + "&tod=" + distValue.max;
    }
    if (daysSelected && daysSelected.length) {
      queryParams = queryParams + "&day=" + daysSelected;
    }
    if (rangeFrom && rangeTo) {
      queryParams =
        queryParams + "&rangefrom=" + rangeFrom + "&rangeto=" + rangeTo;
    }
    if (toggleSwitchVal && toggleSwitchVal["byCash"]) {
      queryParams = queryParams + "&cashpayment=" + 1;
    } else if (toggleSwitchVal && toggleSwitchVal["byCreditcard"]) {
      queryParams = queryParams + "&ccpayment=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["Open24_7"]) {
      queryParams = queryParams + "&wholeweek=" + 1;
    }
    if (ratingValue) {
      queryParams = queryParams + "&rating=" + ratingValue;
    }
    axios.get(API_END_POINTS.GET_FAVOURITE_VENDORS + queryParams + '&imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.status == 200 || response.status == 304) {
          dispatch({
            type: types.GET_FAVOURITE_VENDORS,
            favouriteVendorsList: response && response.data,
            unableToFetch: false
          });
        } else {
          dispatch({
            type: types.GET_FAVOURITE_VENDORS,
            unableToFetch: true
          });
        }
      })
      .catch(err => {
        dispatch({
          type: types.GET_FAVOURITE_VENDORS,
          unableToFetch: true
        });
        if (
          err.response &&
          err.response.status &&
          err.response.status === 403
        ) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unknown error occurred please try again"
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unknown error occurred please try again"
          });
        }
      });
  };
}

export function changeFavouriteSelection(vendorId, status, source, requestId) {
  return dispatch => {
    axios
      .post(
        API_END_POINTS.CHANGE_FAVOURITE_SELECTION,
        {
          garageid: vendorId,
          isfavourite: status
        },
        { withCredentials: true }
      )
      .then(response => {
        if (response.status == 200) {
          if (source == "favouriteSection" || source == "activeVendors") {
            dispatch({
              type: types.CHANGE_FAVOURITE_SELECTION,
              vendorId: vendorId,
              status: status,
              source: source,
              requestId: requestId
            });
          } else {
            dispatch({
              type: types.CHANGE_FAVOURITES_VENDOR,
              vendorId: vendorId,
              status: status,
              source: source
            });
          }
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({
            type: types.LOGOUT
          });
        } else if (
          err.response &&
          err.response.status &&
          (err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 410)
        ) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err.response.data
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again"
          });
        }
      });
  };
}

export function sendReview(
  requestId,
  garageId,
  jobId,
  rating,
  comments,
  userBookingRequestid,
  source
) {
  return dispatch => {
    let requestBody = {
      request_id: requestId,
      garage_id: garageId,
      job_id: jobId,
      rating: rating
    };
    if (comments && comments.length) {
      {
        requestBody["comments"] = comments;
      }
    }
    axios
      .post(API_END_POINTS.SUBMIT_RATING_REVIEW_FOR_JOB, requestBody, {
        withCredentials: true
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.SUBMIT_RATING_REVIEW_FOR_JOB,
            source: source,
            requestBody: requestBody,
            userBookingRequestid: userBookingRequestid
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data.msg
              ? response.data.msg
              : "Thank you for your rating.",
            toastType: "success"
          });
        } else {
          dispatch({
            type: types.SUBMIT_RATING_REVIEW_FOR_JOB
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data.msg
              ? response.data.msg
              : "Unable to update your review, please try again",
            toastType: "error"
          });
        }
      })
      .catch(err => {
        if (err.config) {
          dispatch({
            type: types.SUBMIT_RATING_REVIEW_FOR_JOB
          });
          if (err.response && err.response.status === 403) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              // toastMessage: err.response && err.response.data && err.response.data.err ? err.response.data.err : 'Unable to update your review, please try again',
              toastMessage:
                err.response &&
                  err.response.data &&
                  err.response.data.comments &&
                  err.response.data.comments.length &&
                  err.response.data.comments[0]
                  ? err.response.data.comments[0]
                  : "Unable to update your review, please try again",
              toastType: "error"
            });
          }
        }
      });
  };
}
export function hideToastMessage() {
  return dispatch => {
    dispatch({
      type: types.HIDE_TOAST_MESSAGE
    });
  };
}

export function reactiveExpiredBookingRequests(id, requestid, router) {
  return (dispatch) => {
    axios.put(API_END_POINTS.REACTIVE_EXPIRED_BOOKING_REQUESTS + id + '/reactivate', { withCredentials: true })
      .then(() => {
        router.push(`${appBasePath}/request/${requestid}`);
        dispatch(getBookingRequest(requestid, router));
        dispatch({
          type: types.SHOW_TOAST_MESSAGE,
          toastMessage: "Your Expired Request has been updated",
          toastType: "success"
        });
      });
  };
}

export function deleteUploadedFile(id, fromAction) {
  return dispatch => {
    axios
      .delete(API_END_POINTS.DELETE_UPLOAD_FILE + "/" + id, {
        withCredentials: true
      })
      .then(() => {
        dispatch({
          type: types.SUCCESS_DELETE_FILES,
          id: id,
          fromAction: fromAction
        });
      });
  };
}

export function deleteUploadedImages(id, fromAction) {
  return dispatch => {
    axios
      .delete(API_END_POINTS.DELETE_UPLOAD_IMAGE + "/" + id, {
        withCredentials: true
      })
      .then(() => {
        dispatch({
          type: types.SUCCESS_DELETE_IMAGES,
          id: id,
          fromAction: fromAction
        });
      });
  };
}

export function getJobStatus(jobId, requestId, source) {
  return dispatch => {
    axios
      .get(API_END_POINTS.GET_JOB_STATUS + jobId + "/worklog", {
        withCredentials: true
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_JOB_STATUS,
            jobId: jobId,
            requestId: requestId,
            source: source,
            jobStatus: response.data,
            unableToFetch: false
          });
        } else {
          dispatch({
            type: types.GET_JOB_STATUS,
            jobId: jobId,
            requestId: requestId,
            source: source,
            unableToFetch: true
          });
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again"
          });
        }
      })
      .catch(err => {
        if (err.config) {
          dispatch({
            type: types.GET_JOB_STATUS,
            jobId: jobId,
            requestId: requestId,
            source: source,
            unableToFetch: true
          });
          if (
            err.response &&
            err.response.status &&
            err.response.status === 403
          ) {
            dispatch({
              type: types.LOGOUT
            });
          } else if (
            err.response &&
            err.response.status &&
            (err.response.status === 404 ||
              err.response.status === 401 ||
              err.response.status === 410)
          ) {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: err.response.data
            });
          } else {
            dispatch({
              type: types.SHOW_ERROR_MESSAGE,
              statusMessage: "Unknown error occurred please try again"
            });
          }
        }
      });
  };
}

export function hideButtonLoader() {
  return dispatch => {
    dispatch({
      type: types.HIDE_BUTTON_LOADER
    });
  };
}

export function updateBookingRequestNotification(
  notificationData,
  notificationType
) {
  return dispatch => {
    axios
      .get(
        API_END_POINTS.GET_BOOKING_REQUESTS_DETAILS +
        notificationData.bookingId +
        "?imageflow=1",
        { withCredentials: true }
      )
      .then(response => {
        if (response.status == 200) {
          if ((response.data && response.data.requeststatus && response.data.requeststatus.name && response.data.requeststatus.name == 'Active') ||
            (response.data && response.data.previousrequeststatus && response.data.previousrequeststatus.name && response.data.previousrequeststatus.name == 'Active')) {
            dispatch(getVendorQuotesList(response.data.id));
          }
          if (
            response.data &&
            response.data.requeststatus &&
            response.data.requeststatus.name &&
            (response.data.requeststatus.name == "Active" ||
            response.data.requeststatus.name == "Accepted" ||
              response.data.requeststatus.name == "Completed" ||
              response.data.requeststatus.name == "In-progress")
          ) {
            dispatch(
              getJobStatus(
                response.data.id,
                notificationData.bookingId,
                "requestview"
              )
            );
          }
          dispatch({
            type: types.UPDATE_BOOKING_REQUEST_NOTIFICATION,
            bookingRequestData: response.data,
            requestId: notificationData.bookingId,
            notificationType: notificationType,
            notificationData: notificationData,
            unableTofetch: false
          });
        } else {
          dispatch({
            type: types.UPDATE_BOOKING_REQUEST_NOTIFICATION,
            unableTofetch: true
          });
        }
      })
      .catch(err => {
        if (err.config) {
          if (err.response && err.response.status && err.response.status === 401) {
            dispatch({
              type: types.LOGOUT
            });
          } else {
            // dispatch({
            //   type: types.SHOW_ERROR_MESSAGE,
            //   statusMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'System error, please try later'
            // });
          }
          dispatch({
            type: types.UPDATE_BOOKING_REQUEST_NOTIFICATION,
            unableTofetch: true
          });
        }
      });
  };
}
export function approveCarcilityPackages (data, requestid, callBack)  {

  return async (dispatch) => {
      try {
          const approvePackage = await axios.post(API_END_POINTS.APPROVE_PLUS_REQUEST, data)
          if (approvePackage.data.status) {
            dispatch(getBookingRequest(requestid, undefined, false))
              dispatch({
                  type: types.SHOW_TOAST_MESSAGE,
                  toastMessage: approvePackage.data.msg,
                  toastType: "success"
              })
              callBack(1)
          }
      } catch (error) {
          dispatch({
              type: types.SHOW_TOAST_MESSAGE,
              toastMessage: "Failed to approve request",
              toastType: "error"
          })
          callBack(0)
      }
  }
}

export function udatedSpecialService(payload){


  return async (dispatch) => {

    try {
        const updated = await axios.post(API_END_POINTS.UPDATE_SPECIAL_SERVICE, payload)
        if (updated.data.status) {
            dispatch({
                type: types.SHOW_TOAST_MESSAGE,
                toastMessage: updated.data.msg,
                toastType: "success"
            })
        }
    } catch (error) {
        dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: "Not Updated",
            toastType: "error"
        })

    }
}


}
