import React, { Component } from 'react';
import map from 'lodash/map';
import truncate from 'lodash/truncate';
export default class Description extends Component {
  constructor() {
    super()
    this.state = {
      readMore: true,
      textLenght:250,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const windowWidth = window.innerWidth;
    if(windowWidth<767){
      this.setState({textLenght:100});
    }
  }

  showText(val) {
    if (val == "more") {
      this.setState({ readMore: false })
    } else {
      this.setState({ readMore: true })
    }
  }
  render() {
    let aboutText = this.props.garageProfileDetails ? this.props.garageProfileDetails.about : "";
    let aboutTrimText = aboutText && truncate(aboutText, { 'length': this.state.textLenght, 'separator': ' ' })
    let garage_facilities = this.props.garageProfileDetails &&
      map(this.props.garageProfileDetails.garage_facilities, (facility, key) => {
        return <li key={key}>
        <span className="facilityImg"><img src={facility.image} alt="" /> </span>
        <label>{facility.text}</label></li>;
      });
    return (
      <div>
        <div className="vendor-description" id="n-overview">
          <h4>Description</h4>
          {aboutText && <p>{this.state.readMore ? aboutTrimText : aboutText}</p>}
          {this.state.readMore && aboutText && aboutText.length > this.state.textLenght && <label className="read_more" onClick={this.showText.bind(this, 'more')}>Read More</label>}
          {!this.state.readMore && <label className="read_more" onClick={this.showText.bind(this, 'less')}>Read Less</label>}
        </div>
        {this.props.garageProfileDetails && this.props.garageProfileDetails.garage_facilities && this.props.garageProfileDetails.garage_facilities.length ?
          <div className="row vendor-description">
            <h4>Facilities</h4>
            <ul className="vendor-facilities">
              {garage_facilities}
            </ul>
          </div> : <div />}
      </div>
    );
  }
}
