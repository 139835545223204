import React, { Component } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import AlertDismissable from '../common/AlertDismissable';
import map from 'lodash/map';
import has from 'lodash/has';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { defaultImageOnError } from '../../helpers'; 


export default class ContactDetails extends Component {
  constructor(props) {
    super(props);

    this.errors = {
      'name': false,
      'email': false,
      'phone': false,
      // 'terms': false
    };
    this.initialFormData = {
      'name': '',
      'email': '',
      'phone': '',
      'terms': true,
      'mobileChk': ''
    };
    this.formData = {
      ...this.initialFormData
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.sendOTPAction = this.sendOTPAction.bind(this);
    this.imageUpload = this.imageUpload.bind(this);
    this.setImageURL = this.setImageURL.bind(this);
    this.setImageClass = this.setImageClass.bind(this);

  }

  componentWillMount() {
    let authData = JSON.parse(localStorage.getItem('authData'));
    this.initialFormData.mobileChk = (authData.phone.length == 13) ? authData.phone.substring(4, 13) : authData.phone;

    this.setState({
      imagesrc: authData.profilePhoto ? this.props.authData.profilePhoto : `${imageBasePath}/images/pic.png`,
      Aname: authData.name,
      AphoneNo: this.initialFormData.mobileChk,
      submitedData: '',
      uploadImageFile: _.isEmpty(authData.profilePhoto) ? `${imageBasePath}/images/pic.png` : authData.profilePhoto,
      Aemail: authData.email,
      classVar: "imageupl",
      btnDisbl: true,
    });
  }

  componentWillUnmount() {
    this.props.actions.hideErrorMessage();
  }

  onFieldChange(value, name, validateError) {
    this.setState({ formError: false });
    this.formData[name] = value;
    // this.errors[name] = value ? false : true;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
    if (name === 'name' && this.errors[name] === false) {
      this.state.Aname = value;
    }
    /*else if(name === 'email' && this.errors[name] === false) {
      this.state.Aemail = value;
    }*/
    else if (name === 'phone' && this.errors[name] === false) {
      this.state.AphoneNo = value;
    }
  }
  onSubmit() {
    let data = null;
    this.state.submitedData = {
      'name': this.state.Aname,
      // 'email': this.state.Aemail,
      'phone': '+971' + this.state.AphoneNo,
      'otp': '',
      'profile_photo': this.state.uploadImageFile
    }

    let checkValid = document.getElementsByClassName('form-group');
    map(checkValid, function (value, key) {
      data += value.className;
    });
    let checkError = data.indexOf('error') !== -1 ? true : false;
    if (checkError) {
      this.props.actions.showErrorMessage('please correct the errors on this form');

    } else if (this.initialFormData.mobileChk != this.state.AphoneNo) {
      //this.sendOTPAction();
      localStorage.setItem('verifyotp', JSON.stringify(this.state.submitedData));
      this.props.router.push(appBasePath + '/verify-otp/verify');
    }
    else {
      this.props.actions.contactDetailsUpdate(this.state.submitedData);
    }
  }

  sendOTPAction(e) {
    //e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    }
    /*let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else
        this.errors[key] = false;
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else {*/
    this.setState({ submissionError: false });
    let phone_no = this.formData.phone;
    if (!phone_no.startsWith("+")) {
      phone_no = '+' + phone_no;
    }
    //if (this.formData.password.length >= 6) {
    this.props.actions.showVerifyOTPPage({
      'name': this.state.Aname,
      'email': this.props.authData.email,
      'phone': phone_no,
      'password': 'test123',
      'type': 'customer',
      'usertype': 'customer'
    })
    //}
    //}
  }
  imageUpload(e) {
    let file = e.target.files[0];
    let errMesg = 'Sorry, your image exceeds the file size limit of 1mb, Try again with another image.';
    let propsFunc = this.props.actions;
    let resp = '';
    let that = this;
    if (file.size >= 1000000) {
      this.props.actions.showErrorMessage(errMesg);
    } else {
      that.setImageClass("imgloader");
      that.setState({ btnDisbl: false });
      that.setImageURL("/images/loader_white.gif");

      this.props.actions.sign_request(file, function (response) {
        resp = response;
        //that.setState({uploadImageFile: resp.data.url});
        propsFunc.uploadImageDP(file, resp.data.signed_request, resp.data.url, function () {
          that.setImageURL("");
          that.setImageURL(resp.data.url);
          that.setImageClass("imageupl");
          that.setState({ btnDisbl: true })
        })
      });
    }
  }
  setImageClass(classname) {
    this.setState({ classVar: classname });
  }
  setImageURL(url) {
    this.setState({ uploadImageFile: url });
  }

  render() {
    const { authReducer } = this.props;
    return (
      <div>
        {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle={authReducer.statusMessage.indexOf('succesfully') !== -1 ? 'success error-back mob-err-suc-dispaly' : 'danger error-back mob-err-suc-dispaly'} closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
          <p> <i className="mdi mdi-block-helper " /> {authReducer.statusMessage} </p>
        </AlertDismissable>}
        <div className="container">
          <div className="row clearfix">
            <div className="col-xs-3 img-wrapper">
              <div className="image-element image-element-mob">
                {this.state.uploadImageFile &&
                  <img src={this.state.uploadImageFile} className={this.state.classVar}
                  onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}}
                  />
                }
                {/*!this.state.uploadImageFile &&
                   <p className="imageupl noImage" style={this.state.profilePikColor}>{this.state.firstCharacter}</p>
                   */}
                <label htmlFor="file-upload" className="custom-file-upload file-upload-mob">
                  <i className="glyphicon glyphicon-camera"></i>
                </label>
                <input id="file-upload" type="file" accept="image/*" onChange={(e) => this.imageUpload(e)} />
              </div>
              <div>
                <span className={this.state.uploadImageErrText ? "image-upload-error padLeft15" : "image-upload-error padLeft15 hide"}>
                  <p>Sorry, your image exceeds the file size limit of 20mb.
                                            Try again with another image.</p>
                  <i className="mdi mdi-close" onClick={() => this.setState({ uploadImageErrText: false })} />
                </span>
              </div>
            </div>
            <div className="col-xs-9 form-info-wrapper-mob">
              <div className="form-info contact-details-disable form-info-mob">
                <TextInput
                  type="text"
                  label="Name"
                  name="name"
                  value={this.state.Aname}
                  validationError={'Enter your name'}
                  showValidationError={this.errors['name']}
                  validationError="Enter your name"
                  onBlur={this.onFieldChange.bind(this)} />
                <TextInput
                  type="email"
                  label="Email"
                  name="email"
                  value={this.state.Aemail}
                  disabled
                  customClass="disable-text"
                  validationError="Please enter your email id"
                  showValidationError={this.errors['email']}
                  onChange={this.onFieldChange.bind(this)} />
                <TextInput
                  type="phone"
                  label="Mobile Number"
                  name="phone"
                  value={this.state.AphoneNo}
                  showValidationError={this.errors['phone']}
                  validationError="Enter a valid mobile number"
                  onBlur={this.onFieldChange.bind(this)} />
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-footer-part clearfix container">
          <Button btnType="red" btnSize="sm" fontSize={16} label="Save" btnCallBack={() => this.onSubmit(this)} customClass="btn-theme-save" disabled={this.state.btnDisbl ? "" : "disabled"} />
          <Button btnType="transparent" btnSize="sm" fontSize={16} label="Cancel" btnCallBack={() => window.history.back()} customClass="btn-theme" />
        </div>
      </div>
    );
  }
}
