import React, { Component } from 'react';
import Header from '../../common/Header';
import Sidebar from '../../common/Sidebar';
import Footer from '../../common/Footer';
import MobileNotification from '../../common/MobileNotification';
import MobileMessage from '../../common/MobileMessage';
import Button from '../../common/Button';
import QuickRepairSteps from './QuickRepairSteps';
import each  from 'lodash/each';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import difference  from 'lodash/difference';
import SummaryPanel from '../../common/SummaryPanel';
import { queryStringtoArray } from '../../../helpers/index';
import CustomScroll from 'react-custom-scroll';
import CustomModal from '../../common/CustomModal';
import { Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import ToastMessage from '../../common/ToastMessage';
import MobileHeaderSearch from '../../common/MobileHeaderSearch';
import { imageBasePath, appBasePath } from '../../../constants/staticData';

export default class QuickCarRepair extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      hideStep1Panel:localStorage.getItem("hideFirstStep") || false,
      showSummary:false,
      infoModalData: undefined,
      selectedCarProfile: undefined,
      infoModal: true,
      editClicked: false,
      ChasisNumber: '',
      //filteredCarRepairCategories:undefined,
      visibleCategory: undefined,
      notificationVisible: false,
      messageVisible: false,
      carRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      filteredCarRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      categoriesSelectedCount: JSON.parse(localStorage.getItem("selectedCarRepairDataArray") || "[]").length,
      selectedCarRepairDataArray: JSON.parse(localStorage.getItem("selectedCarRepairDataArray") || "[]"),
      promotionalOffersData: [],
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      showError: undefined,
      servicesFetched: props.bookingRequestReducer.servicesFetched ? true: false,
      filterKeyword: false,
      showChasis: true,
      activeCarDetailSubCategory: [],
      offerFromCreateCarProfile: this.props.location  && this.props.location.state && this.props.location.state.offerFromCreateCarProfile ? this.props.location.state.offerFromCreateCarProfile : false,
      promoCodesModal: false,
      selectedPromoCode: localStorage.getItem("selectedPromoCode") || "",
      isPromoCodeUpdated: false,
      promotionalOffersIsApproved: false,
      showPromoCodesModal:this.props.showPromoCodesModal,
      popUpPromoCode: '',
      promoPopupError:0,
      nonSelectedFirstItem: "",
      promoApplied:false,
    };
    this.changeCheckboxState = this.changeCheckboxState.bind(this);
    this.clearCheckboxState = this.clearCheckboxState.bind(this);
    this.submitPromoCodeData = this.submitPromoCodeData.bind(this);
    this.updateRadioButton = this.updateRadioButton.bind(this);

  }

  componentWillMount() {
    if(! localStorage.getItem('bookingRequestId') || ! localStorage.getItem('serviceId') ){
      this.props.router.push(`${appBasePath}/dashboard-plus`)
    }else if( localStorage.getItem('serviceId') == "1"){
      this.props.router.push(`${appBasePath}/quick-booking/car-wash`)
    }else if( localStorage.getItem('serviceId') == "2"){
      this.props.router.push(`${appBasePath}/quick-booking/car-service`)
    }


    if(this.state.selectedPromoCode && this.state.selectedCarProfile && ! this.state.promoApplied){
      var promotionData = {
        place: 0,
        services: this.state.selectedCarRepairDataArray,
        promo_code: this.state.selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.props.actions.applyPromotionalOffers(promotionData);
    }

    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }
    if (queryStringtoArray('vendor_id')) {
      this.props.actions.getGarageProfileDetails(Number(queryStringtoArray('vendor_id')));
    }
    if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
      console.log('💔 💔 💔 0 ', this.props.commonReducer.promotionalOffersData);
      this.props.actions.getPromotionalOffers();
    }
  }

  componentWillReceiveProps(nextProps) {
    let getVendorsCarRepairData = [];
    let selectedCarProfile;
    //After Updation its getting reset to the first carprofile
    if (queryStringtoArray('car_id')) {
      map(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles, (carProfile) => {
        if (queryStringtoArray('car_id') == carProfile.id) {
          selectedCarProfile = carProfile;
        }
      });
      this.setState({
        ...this.state,
        selectedCarProfile: selectedCarProfile,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
      });
    }
    if (!queryStringtoArray('vendor_id') && nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.carRepairCategories && !this.state.carRepairCategories.length && this.state.filteredCarRepairCategories && !this.state.filteredCarRepairCategories.length) {
      this.setState({
        ...this.state,
        carRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        filteredCarRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    //   // <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    // }
     else if (queryStringtoArray('vendor_id') && nextProps && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services) {
        if(nextProps.commonReducer.garageProfileDetails.garage_services.length == 0) {
          this.setState({
            ...this.state,
            carRepairCategories: [],
            filteredCarRepairCategories: [],
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        } else {
            nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.servicesFetched && nextProps.bookingRequestReducer.repairCategoryData.map((allSubService) => {
              let sub_services = [],services = {};
              allSubService.sub_services.map((subservices) => {
                nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
                  if (subservices.id === vendorSubService.serviceId) {
                    sub_services.push(subservices);
                  }
                });
              });
              if(sub_services && sub_services.length > 0){
                services['description'] = allSubService.description;
                services['id'] = allSubService.id;
                services['image'] = allSubService.image;
                services['name'] = allSubService.name;
                services['parentId'] = allSubService.parentId;
                services['sub_services'] = sub_services;
              }
              console.log("nextProps 🔥",nextProps);
              if(size(services)){
                getVendorsCarRepairData.push(services);
              }
            });

          if (getVendorsCarRepairData.length) {
            this.setState({
              ...this.state,
              carRepairCategories: getVendorsCarRepairData,
              filteredCarRepairCategories: getVendorsCarRepairData,
              servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
            });
          } else {
            this.setState({
              ...this.state,
              carRepairCategories: [],
              filteredCarRepairCategories: [],
              servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
            });
          }
        }
    }
    if (queryStringtoArray('serviceId')) {
      this.openCategory(Number(queryStringtoArray('serviceId')));
    }


    if(! this.state.offerFromCreateCarProfile){
      this.setState({
        promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
        selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      });

    }

    let promoCategories = [];
    this.state.selectedPromotionalOffersData && each(this.state.selectedPromotionalOffersData.services, (promoCategory) => {
      promoCategories.push(promoCategory.serviceId);
      //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);

    });
    console.log('promoCategories --->',promoCategories);
    promoCategories && promoCategories.length && this.setState({
      selectedCarRepairDataArray: promoCategories,
      categoriesSelectedCount: promoCategories.length,

    });


    if (nextProps && nextProps.showError) {
      this.setState({ isButtonLoading: false, });

    }

    if(nextProps.commonReducer && nextProps.commonReducer.promotionalOffersMessage && this.state.selectedPromoCode && this.state.isPromoCodeUpdated ){

      this.setState({
        promotionalOffersMessage:nextProps.commonReducer.promotionalOffersMessage,
        promotionalOffersIsApproved:nextProps.commonReducer.promotionalOffersIsApproved });
    }

    if(this.state.selectedPromoCode){
      this.setState({
        promotionalOffersIsApproved:true,
        
        
      })
    }


    if(this.state.selectedPromoCode && this.state.selectedPromoCode != "undefined" && this.state.selectedCarProfile && ! this.state.promoApplied){
      var promotionData = {
        place: 0,
        services: this.state.selectedCarRepairDataArray,
        promo_code: this.state.selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.setState({ promoApplied:true, isPromoCodeUpdated: true,});

      // this.props.actions.applyPromotionalOffers(promotionData);
    }

  }

  showPromoModal() {


    this.setState({
      promoCodesModal: true
    });

  }

  submitPromoCodeData(isFromPopUp){



    let selectedPromoCode = this.state.selectedPromoCode;
    this.setState({
      isPromoCodeUpdated: true,
    });

    if(isFromPopUp){
      selectedPromoCode =  this.state.popUpPromoCode;
      if(selectedPromoCode)
        this.setState({ promoCodesModal: false, selectedPromoCode:selectedPromoCode});

    }


    if(selectedPromoCode){

      var promotionData = {
        place: 0,
        services: this.state.selectedCarRepairDataArray,
        promo_code: selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.props.actions.applyPromotionalOffers(promotionData);
    }

  }



  updateRadioButton(event,promoId) {
    
    var promotionalOffersData =  this.state.promotionalOffersData;
    var promotionalOffersData1 = promotionalOffersData.filter((promoCode)=>{return promoCode.id === promoId});
    
    var name = promotionalOffersData1[event.target.value].promoCode;

    this.setState({ popUpPromoCode: name});


  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails
    });
  }

  hideModalCallback() {
    this.setState({ showChasis: false });
  }

  submitCallback(number) {
    console.log('The chasis Number is 💔', number);
    this.setState({
      ChasisNumber: number,
      showChasis: false
    });
  }
  disableEdit(){
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carRepairCategories);
      let filteredCarRepairCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        'filteredCarRepairCategories': filteredCarRepairCategories,
        filterKeyword: true
      });
    } else {
      this.setState({
        'filteredCarRepairCategories': cloneDeep(this.state.carRepairCategories),
        filterKeyword: false
      });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  openCategory(id) {
    if(this.state.visibleCategory == id){
      this.setState({
        'visibleCategory': undefined
      });
    }else{
      this.setState({
        'visibleCategory': id
      });
    }
  }
  hidePopover() {
    this.refs.overlay.handleHide();
  }

  customPopoverRight(carDetails,name) {

    return (
      <Popover className="customPopover quick-repair-popover" id="popover-positioned-right">
        <h4>{name}</h4>
        <CustomScroll heightRelativeToParent="200px" allowOuterScroll={true}>

          <ul className="popoverCatList">

              {map(carDetails.sub_services, (subCategory, index) => {
                return (<li className="options" key={index}>
                  <span className="checkbox-style checkbox-wrapper">
                    <input type="checkbox" id={subCategory.id} checked={subCategory.checked ? subCategory.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id, subCategory.id) }} value="" />
                    <label htmlFor={subCategory.id} className="custom-checkbox"></label>
                    <label htmlFor={subCategory.id}>
                      {subCategory.checked} {subCategory.name}
                    </label>
                  </span>
                </li>);
              })}

          </ul>
        </CustomScroll>

        <div className="popOver-footer  text-right">

          <div className="filter-footer footer">
            <a
              onClick={() => {
                this.clearCheckboxState(carDetails.id)
              }}
            >
              Clear
            </a>
            <Button
              btnType="red"
              btnSize="sm"
              fontSize={15}
              label="Done"
              btnCallBack={() => {
                document.body.click()
              }}
            />
          </div>
        </div>
      </Popover>
    );
  }
  customPopoverOnHide(carDetails) {

    console.log("carDetails",carDetails)

/*

    let removedCategoriesArray = this.state.selectedCarRepairDataArray
    let carRepairCategories = cloneDeep(this.state.carRepairCategories);



    let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });



    let filtered = [];
    each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

      return(
        checked.checked = false
      )
    });
*/




  }


  moreServices(){


    let thisz = this;


    each(this.state.filteredCarRepairCategories, (carDetails) => {


      let carRepairCategories = cloneDeep(this.state.carRepairCategories);



      let findCategoryIndex = findIndex(carRepairCategories, { id: carDetails.id });

      let checkItems = false;
      each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

        if(checked.checked){
          checkItems = true;
          return false;
        }else{
          if (checkItems){
            return false;
          }
        }
      });

      if(checkItems == 0){

        console.log("111111")
        console.log(" carDetails.id", carDetails.id)

        thisz.setState({
          nonSelectedFirstItem: carDetails.id
        })

        console.log("3333")
        return false;
      }
    });

  }
  renderCarType(carDetails, key,visibleCategory) {
    return (


      <OverlayTrigger onExit={(e) => {

        let carRepairCategories = cloneDeep(this.state.carRepairCategories);



        let findCategoryIndex = findIndex(carRepairCategories, { id: carDetails.id });

        let checked = false
        each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

          if(! checked.checked){

            this.setState({visibleCategory:"",})
          }
        });

      }} onEnter={(e) => {



        this.setState({
          'activeCarDetailCategory': carDetails
        });


      }} key={key} trigger="click" ref="overlay" rootClose={true} placement="right" overlay={this.customPopoverRight(carDetails,carDetails.name)} >
        <div className="sub-collapse-panel wash-collapse quick-booking-repair-collapse quick-booking-collapse col-md-3  col-sm-3  col-xs-6"  key={key}>
          <div className="sub-collapse-panel wash-collapse quick-booking-repair-web quick-booking-collapse col-md-12  col-sm-12  col-xs-12">
            {carDetails.id == this.state.visibleCategory  || carDetails.id == visibleCategory ? <i className="mdi mdi-checkbox-marked-circle quick-car-repair selected-icon"></i> : "" }
            <div className={carDetails.id == this.state.visibleCategory || carDetails.id == visibleCategory ? "sub-collapse-panel-head active" : this.state.nonSelectedFirstItem == carDetails.id ?  "sub-collapse-panel-head sub-collapse-panel-head-hover" : "sub-collapse-panel-head " } onClick={(event) => { event.preventDefault(); this.openCategory(carDetails.id); }}>
              <figure>
                <img src={carDetails.image} alt="" />
              </figure>
              <h4>{carDetails.name}</h4>
            </div>
          </div>

          <div  onClick={(event) => { this.setState({repairSubCategoryModal:true}) }} className="sub-collapse-panel wash-collapse quick-booking-repair-mob quick-booking-collapse col-md-12  col-sm-12  col-xs-12">
            {carDetails.id == this.state.visibleCategory  || carDetails.id == visibleCategory ? <i className="mdi mdi-checkbox-marked-circle quick-car-repair selected-icon"></i> : "" }
            <div className={carDetails.id == this.state.visibleCategory || carDetails.id == visibleCategory ? "sub-collapse-panel-head active" : "sub-collapse-panel-head "} onClick={(event) => { event.preventDefault(); this.openCategory(carDetails.id); }}>
              <figure>
                <img src={carDetails.image} alt="" />
              </figure>
              <h4>{carDetails.name}</h4>
            </div>
          </div>
        </div>


      </OverlayTrigger>



    );
  }

  changeCheckboxState(e, categoryId, subCategoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const carRepairCategories = cloneDeep(this.state.carRepairCategories);
    const filteredCarRepairCategories = cloneDeep(this.state.filteredCarRepairCategories);
    if(this.state.filterKeyword) {
      filteredCarRepairCategories[categoryId]['sub_services'][subCategoryId]['checked'] = isChecked;
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: filteredCarRepairCategories
      });
    } else {
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }


      localStorage.setItem('selectedCarRepairDataArray',JSON.stringify(removedCategoriesArray));

      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: carRepairCategories
      });
    }
  }

  clearCheckboxState(categoryId) {


    let removedCategoriesArray = this.state.selectedCarRepairDataArray
    let carRepairCategories = cloneDeep(this.state.carRepairCategories);



    let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });



    let filtered = [];
      each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

        return(
          checked.checked = false
        )
      });

      each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {


        let index = removedCategoriesArray.indexOf(checked.id);
        if (index > -1) {
          removedCategoriesArray.splice(index, 1);
        }

      });


    this.setState({
      selectedCarRepairDataArray: removedCategoriesArray,
      carRepairCategories: carRepairCategories,
      filteredCarRepairCategories: carRepairCategories
    });

  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }





  activateLoader(){
    this.setState({isButtonLoading:true})
  }

  deactivateLoader(){

    this.setState({isButtonLoading:false})

  }

  triggerChildFunction(){
    this.refs.QuickRepairSteps.requestQuotes();
  }
  removeItem(categoryId,subCategoryId){



    let removedCategoriesArray = this.state.selectedCarRepairDataArray
    let carRepairCategories = cloneDeep(this.state.carRepairCategories);



    let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });



    let filtered = [];
    each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

      return(
        checked.checked = false
      )
    });

    if(subCategoryId){

      each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {

        if(subCategoryId == checked.id){

          let index = removedCategoriesArray.indexOf(checked.id);
          if (index > -1) {
            removedCategoriesArray.splice(index, 1);
          }
        }


      });

    }else{
      each(carRepairCategories[findCategoryIndex].sub_services, (checked) => {


        let index = removedCategoriesArray.indexOf(checked.id);
        if (index > -1) {
          removedCategoriesArray.splice(index, 1);
        }

      });
    }

    localStorage.setItem('selectedCarRepairDataArray',JSON.stringify(removedCategoriesArray));


    this.setState({
      selectedCarRepairDataArray: removedCategoriesArray,
      carRepairCategories: carRepairCategories,
      filteredCarRepairCategories: carRepairCategories
    });




  }

  renderSubCategory(subCategory,key) {

      return (

        <li className="options" key={key}>
                  <span className="checkbox-style checkbox-wrapper">
                    <input type="checkbox" id={subCategory.id} checked={subCategory.checked ? subCategory.checked : false} onChange={(e) => {
                      this.changeCheckboxState(e, this.state.activeCarDetailCategory.id, subCategory.id) }} value="" />
                    <label htmlFor={subCategory.id} className="custom-checkbox"></label>
                    <label htmlFor={subCategory.id}>
                      {subCategory.checked} {subCategory.name}
                    </label>
                  </span>
        </li>


      )
    }


  openPromoModal(){

    this.setState({
      promoCodesModal: true
    });
  }

  triggerChildValidationFunction(){

    console.log("23423423423423");

    let validation = this.refs.QuickRepairSteps.requestQuotesValidate();

    if(validation){
      this.setState({showSummary: true,})
    }
  }

  render() {
    const { commonReducer, actions, router,bookingRequestReducer } = this.props;


    let isCoverImage = "";
    this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        isCoverImage =  (
          <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
          </div>
        );
      }
    });

    if(! isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length){
      this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
        if (value.type == "vehicle") {
          isCoverImage =  (
            <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
            </div>
          );
        }
      });
    }


    let leftBlock = [];
    let catLength = 0;
    let selectedCarRepairDataArray = this.state.selectedCarRepairDataArray;
    let visibleCategory = "";
    each(this.state.filteredCarRepairCategories, (carDetails) => {




      each(carDetails.sub_services, function(subCarDetails, index){

        if(selectedCarRepairDataArray && selectedCarRepairDataArray.includes(subCarDetails.id)){


          carDetails.sub_services[index].checked = true;
          visibleCategory = carDetails.id;
        } else {
          carDetails.sub_services[index].checked = false;
        }
      });




      catLength += 1;
      leftBlock.push(this.renderCarType(carDetails, catLength,visibleCategory));
    });

    let subCategoryBlock = [];


    if(this.state.activeCarDetailCategory){



      let carRepairCategories = cloneDeep(this.state.carRepairCategories);



      let findCategoryIndex = findIndex(carRepairCategories, { id: this.state.activeCarDetailCategory.id });



      each(carRepairCategories[findCategoryIndex].sub_services, (subCategory,key) => {


        subCategoryBlock.push(this.renderSubCategory(subCategory,key));




      });

    }


    //Filter the offers based on the Services that was choosen

    let validPromoCode = []
    if(this.state.selectedCarRepairDataArray.length){
        validPromoCode = this.state.promotionalOffersData.filter((promoCode) => {
        const serviceIds = map(promoCode.services, 'serviceId');
        return difference(this.state.selectedCarRepairDataArray, serviceIds).length === 0;
      });

    }


    let validPromoOffer =  filter(validPromoCode, (promo) => { return promo.services.parentId != 1 || promo.services.parentId != 2 });


    let promoCodesHtml = validPromoOffer.map((promoCode, index) => {




      var radioId = "radio-"+promoCode.id;
      return (<div className="form-check promo-item" key={promoCode.id}>
          <input id={radioId}  className="radio-custom"  onClick={ e => this.updateRadioButton(e,promoCode.id) }   value={0} name="radio-group" type="radio"/>
          <label htmlFor={radioId} className="radio-custom-label">{promoCode.promoCode}</label>
          <p>
            {promoCode.description}
          </p>

          <a
            onClick={() => {

              // let route = this.$router.resolve({path: '/offer-terms'});
              // let route = this.$router.resolve('/link/to/page'); // This also works.
              window.open('/offer-terms/'+promoCode.id, '_blank');





              /* router.push({
                 pathname: appBasePath + '/offer-terms',
                 state: { promotionalOffersTerms: promoCode.termsAndConditions }
               });*/


            }}
          >*Terms and Conditions Apply</a>
        </div>

      );

    });


    if (validPromoCode.length < 1){
      //  promoCodesHtml = " <img src={'/images/offers_empty.png'} alt=''> <p>Oops! Looks like you don't have any Promo Offers.</p>";
    }



    let authData = localStorage.getItem('authData');
    authData = authData ? JSON.parse(authData) : '';


    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} 
        bookingRequestReducer = {bookingRequestReducer}
        notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper quick-main-wrapper">
          <Sidebar router={this.props.router} />



          <div className="page-sec-header quick-booking-header">
            <div className="padwrapper">
              <span className="back-button"><i className="mdi mdi-arrow-left "></i></span>
              <h4>We can help you get the best car service</h4>


              {leftBlock.length > 0 && !this.state.hideStep1Panel ?

                <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                  <li className=""><a onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }  >Home</a></li>
                  <li className="active"><span>Car Repair</span></li>
                </ol>


                :
                <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                  <li className=""><a  onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }   >Home</a></li>
                  <li className=""><a  onClick={() =>
                    this.setState({ hideStep1Panel: false, showSummary:false })
                  }   >Car Repair</a></li>
                  <li className="active"><span>Book Service</span></li>
                </ol>
              }
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className={this.state.showSummary? "myCar-body col-md-8 hide": "myCar-body col-md-8" }>
                  <QuickRepairSteps
                    ref="QuickRepairSteps"
                    actions= {actions}
                    categoriesSelectedCount= {this.state.categoriesSelectedCount}
                    btnCallBack= {this.requestQuotes}
                    chasisNumber={this.state.ChasisNumber}
                    router= {this.props.router}
                    showError = {this.props.bookingRequestReducer.toastType}
                    servicesFetched = {this.state.servicesFetched}
                    selectedCarProfile= {this.state.selectedCarProfile}
                    carProfiles= {this.props.carProfileReducer.carProfiles}
                    commonReducer= {this.props.commonReducer}
                    bookingRequestReducer= {bookingRequestReducer}
                    selectedCarRepairDataArray= {this.state.selectedCarRepairDataArray}
                    promotionalOffersData = {this.state.promotionalOffersData}
                    selectedPromotionalOffersData = {this.state.selectedPromotionalOffersData}
                    hideStep1Panel = {this.state.hideStep1Panel}
                    showSummary = {this.state.showSummary}
                    selectedPromoCode = {this.state.selectedPromoCode}
                    viewBlock= {leftBlock}
                    searchView= {this.filterCarType.bind(this)}
                    disableEdit = {this.disableEdit.bind(this)}
                    activateLoader = {this.activateLoader.bind(this)}
                    deactivateLoader = {this.deactivateLoader.bind(this)}
                    editClicked= {this.state.editClicked}
                    changeCarProfile= {this.changeCarProfile.bind(this)}
                    garageProfileDetails= {queryStringtoArray('vendor_id') && this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    carProfileReducer={this.props.carProfileReducer}
                  />
                </div>
                <div className="myCar-body col-md-4">

                  <div className="car-summary quick-booking-user">
                    <div className="summary-heading">
                      Hi <span>{authData ? authData.name : ''} !</span>
                    </div>
                    <p>
                      It’s great to see that you are booking a service !
                    </p>



                  </div>

                  <div className={this.state.showSummary?"car-summary quick-booking-summary show" : "car-summary quick-booking-summary"}>
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {isCoverImage ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/default-guest-car-profile.jpg`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc summary-car-detail">
                            <h5>{this.state.selectedCarProfile.name}
                              <span className="edit-car">
                              <a  onClick={() =>  this.props.router.push(`${appBasePath}/car-profiles/${this.state.selectedCarProfile.id}/edit`) }  ><i className="mdi mdi-pencil edit-car-icon"></i> Edit</a>
                            </span>
                            </h5>

                            <label>{this.state.selectedCarProfile.model}</label>
                            <label>Make: {this.state.selectedCarProfile.make}, Year: {this.state.selectedCarProfile.year}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    <div className="category-wrapper car-wrapper">
                      <div className="category-heading">
                        {this.state.selectedCarRepairDataArray.length > 0 ?<h5>Services Selected</h5>:<h5>No Services Selected</h5> }
                        {this.state.categoriesSelectedCount > 0 ?
                          <spna>
                            <label className="more-service-web" onClick={() => {
                              this.setState({ hideStep1Panel: false, showSummary:false })

                              window.scrollTo(0, 0)
                              this.moreServices();

                            }}>
                              <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                            </label>
                            <label  className="more-service-mob"   onClick={() => {
                              window.scrollTo(0, 0)

                              this.setState({ hideStep1Panel: false, showSummary:false })
                              this.moreServices();

                            }}>
                              <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                            </label>

                          </spna>

                          :
                          []}
                      </div>
                      <div className="category-list">
                        {map(this.state.carRepairCategories, (subCat, key) => {
                          let count = 0;
                          let keyCopy = { key }
                          let checkedItem = false;
                          filter(subCat.sub_services, (checked) => {
                            if (checked.checked == true) {
                              checkedItem = true;
                              count += 1
                            }
                          });
                          if (checkedItem == true) {
                            return (
                              <SummaryPanel removeItem={this.removeItem.bind(this)} subCat={subCat} selectedChkBoxCount={count} />
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div className="category-wrapper promo-wrapper">
                      <div className="category-heading">

                      {this.state.promotionalOffersIsApproved?

<div className="promo-success">
  <span className="text">Promo Code</span>
  <span className="code">‘{this.state.selectedPromoCode}’</span>
  <span className="button"  onClick={() => {

    this.setState({
      promotionalOffersMessage:"",
      selectedPromoCode:"",
      promotionalOffersIsApproved:false });


  }}>
    remove
  </span>
  {this.state.selectedPromoCode &&
  <div className="promo-page-error">{this.state.promotionalOffersMessage}</div>
  }
</div>

:
<div>
  <h5>Have a Promo Code ?</h5>
  <label onClick={this.openPromoModal.bind(this)}>
    <i className="mdi mdi-plus more-service-icon"></i><a>Apply</a>
  </label>
  


</div>
}




                      </div>
                    </div>


                    <div className="car-wrapper web-navigation-button">
                      <div className="">
                        <div className="type-desc full-width">
                          {leftBlock.length  > 0 && ! this.state.hideStep1Panel? <div className="next-button">
                              <Button disabled={this.state.selectedCarRepairDataArray.length > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {   this.setState({ hideStep1Panel: true }) }} />
                            </div>:

                            <div className="next-button clearfix">
                              <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                              <label className="label-text">Request will be sent to multiple service providers.</label>

                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection quick-footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        <CustomModal showModal={this.state.repairSubCategoryModal} footer={ false}
                     closeIcon={false}  hideModal={() => { this.setState({ repairSubCategoryModal: false }) }}
                     animation={false}
                     className="quick-repair-modal"
        >
          <Modal.Body>

            <div className="jobUpdate">
              
          <Header 
            hideOption={()=>this.setState({searchMobile:true})} 
            notificationCount={2} 
            bookingRequestReducer={this.props.bookingRequestReducer} 
            commonReducer={commonReducer} 
            profileName="Derrick Frank" 
            notificationCallBack={this.toggleNotification} 
            messageCallBack={this.toggleMessage.bind(this)} 
            router={router} 
            actions={actions} 
            authReducer={this.props.authReducer}
          />

               <div className="main-wrapper quick-main-wrapper">
                <div className="page-sec-header quick-booking-header">
                  <div className="padwrapper">
                    <span className="back-button"  onClick={() =>  this.setState({ repairSubCategoryModal: false }) }  ><i className="mdi mdi-arrow-left "></i></span>
                    <h4>Subcategories</h4>


                      <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                        <li className=""><a  onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }   >Home</a></li>
                        <li className=""><a  onClick={() =>
                          this.setState({ hideStep1Panel: false, repairSubCategoryModal:false })
                        }   >Car Repair</a></li>
                        <li className="active"><span>Subcategories</span></li>
                      </ol>

                  </div>
                </div>
                <div className="inSection">
                  <div className="padwrapper padwrapper-align">
                    <div className="myCar-list row">
                      <div className="sub-category-content">
                        <h4>{this.state.activeCarDetailCategory?this.state.activeCarDetailCategory.name: ""}</h4>

                        <CustomScroll heightRelativeToParent="calc(100vh - 200px)" allowOuterScroll={true}>

                          <ul className="popoverCatList">
                            {subCategoryBlock}

                          </ul>
                        </CustomScroll>


                      </div>


                    </div>
                  </div>

                </div>

              </div>

            </div>

          </Modal.Body>
        </CustomModal>
        <CustomModal showModal={this.state.promoCodesModal} footer={validPromoCode.length > 0? true: false} cancelText="Cancel" saveText="Apply"
                     title="Add Promocode"
                     submitCallBack ={() => { this.submitPromoCodeData(true);  }}
                     closeIcon={true}  hideModal={() => { this.setState({ promoCodesModal: false }) }}>
          <Modal.Body>
            <div className="emgcy-modal">


              <div className="holder">



                {validPromoOffer.length > 0?  <form> {promoCodesHtml}  </form> :  <div className="promo-empty"> <img src={'/images/offers_empty.png'} alt='' /> <p> Oops! Looks like you don't have any Promo Offers. </p>  </div> }



              </div>

            </div>
          </Modal.Body>
        </CustomModal>
        <div className={this.state.repairSubCategoryModal? "sidebar sidebar-subcategory show" : "sidebar sidebar-subcategory hide" } style={{ backgroundColor:'#fff' }}>
          <Button btnType="transparent" btnSize="sm" fontSize={15} label={"Cancel"} btnCallBack={(e) => {   this.setState({  repairSubCategoryModal:false }) }}  />
          <Button btnType="red" btnSize="sm" fontSize={15} label={"Done"} btnCallBack={(e) => { this.setState({  repairSubCategoryModal:false })  }}/>

        </div>
        <div className={this.state.repairSubCategoryModal? "sidebar sidebar-next-button hide" : "sidebar sidebar-next-button show" } style={{ backgroundColor:'#fff' }}>
          {leftBlock.length  > 0 && ! this.state.hideStep1Panel? <div className="next-button">
              <Button disabled={this.state.selectedCarRepairDataArray.length > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {   this.setState({ hideStep1Panel: true }) }} />
            </div>:

            this.state.hideStep1Panel && ! this.state.showSummary?
            <div className="next-button clearfix">
              <Button btnType="red" btnSize="lg" fontSize={14} label="Show Summary" btnCallBack={ this.triggerChildValidationFunction.bind(this) }  />
              <label className="label-text">Request will be sent to multiple service providers.</label>

            </div>
              :
              <div className="next-button clearfix">
                <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                <label className="label-text">Request will be sent to multiple service providers.</label>

              </div>

          }

        </div>

        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
        {/*<ChasisNumberPopup
          modalStatus={this.state.showChasis}
          hideModalCallback={this.hideModalCallback.bind(this)}
          submitCallback={this.submitCallback.bind(this)}
          actions= {actions}
          bookingRequestReducer={bookingRequestReducer}
        />*/}
      </div>
    );
  }
}
