import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import HeatmapLayer from '../../../node_modules/react-google-maps/lib/visualization/HeatmapLayer.js';
import SearchBox from '../../../node_modules/react-google-maps/lib/places/SearchBox';
import { map } from 'lodash';
import { Media } from 'react-bootstrap';

class GmapsEmergency extends Component {
    constructor() {
        super();
        this.searchBoxStyle = {
            boxSizing: `border-box`,
            boxShadow: `0 2px 4px 0 rgba(0,0,0,0.3)`,
            MozBoxSizing: `border-box`,
            border: `3px solid transparent`,
            width: `95%`,
            height: `42px`,
            marginTop: `20px`,
            padding: `0 12px`,
            fontSize: `13px`,
            outline: `none`,
            textOverflow: `ellipses`,
            fontFamily: `CenturyGothic`,
            color: `#949DA5`
        };

        this.state = {
            activeInfoWindow: null,
            map: null,
            bounds: null
        };
    }
    mouseLeave() {
        this.setState({ activeInfoWindow: null });
    }

    render() {
        let mapSettings = {
            defaultZoom: this.props.zoom,
            defaultCenter: this.props.center
        };
        if (this.props.setCenter) {
            mapSettings['center'] = this.props.center;
        }

        return (
            <div>
                <GoogleMap
                    {...mapSettings}
                    options={{ fullscreenControl: false }}
                    draggable={this.props.draggable}
                    onDragEnd={this.props.mapDrag}
                >
                    <Marker
                        {...this.props.marker}
                        onMouseOver={() => this.setState({ activeInfoWindow: 1 })}
                        onMouseOut={() => {
                            this.mouseLeave();
                        }}
                        onDragEnd={this.props.markerDrag}
                        draggable={false}
                        onClick={() => window.open('https://www.google.com/maps?q=25.120381,55.375307')}
                        icon={{ url: this.props.pinImage }}
                    />
                    {this.props.currentmarker &&
                        <Marker
                            {...this.props.currentmarker}
                            onMouseOver={() => this.setState({ activeInfoWindow: 1 })}
                            onMouseOut={() => {
                                this.mouseLeave();
                            }}
                            onDragEnd={this.props.markerDrag}
                            draggable={false}
                            icon={{ url: this.props.currentImage }}
                        />}

                    {/* this.props.heatMapData && <HeatmapLayer
                        data={this.props.heatMapData}
                    /> */}

                    {this.props.onPlacesChanged &&
                        <SearchBox
                            ref={this.props.onSearchBoxMounted}
                            controlPosition={google.maps.ControlPosition.TOP_LEFT}
                            onPlacesChanged={this.props.onPlacesChanged}
                            inputClassName="map-location-input"
                            inputPlaceholder="Mark Your Locations"
                            inputStyle={this.searchBoxStyle}
                        />}
                </GoogleMap>
            </div>
        );
    }
}

export default withGoogleMap(GmapsEmergency);
