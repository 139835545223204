import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class CarProfileEmptyState extends Component {
    render() {
        return (
            <div className="empty-state-holder">
                <span className="empty-state-content">
                    <img src={`${imageBasePath}/images/car_empty.png`} alt="" />
                    <span className="text">Oops! Looks like you haven’t created a Car Profile yet. Fortunately its easy to create one.  </span>
                    <Button btnType="red" btnSize="lg" fontSize={15} label="Create Car Profile" btnCallBack={() => this.props.router.push(appBasePath + '/car-profiles/create')} />
                </span>
            </div>
        );
    }
}
