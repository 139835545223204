import React, { Component } from 'react';
import IconNotification from '../common/IconNotification';
import Button from '../common/Button';
import { Media } from 'react-bootstrap';
import {round} from 'lodash';
import { imageBasePath, appBasePath } from '../../constants/staticData';

class QuotesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavouriteVisible: false,
    };
  }
  render() {
    const { index, vendorName, rating, distance, reviews, price, vendorImage, requestId, jobId, vendorId, router,isFavourite,userRequestId } = this.props;
    return (
      <div className={this.props.activeClass == "active" ? "jobcard box active" : "jobcard box"} onClick={()=>{ this.props.ClickedQuoteCard(vendorId);}} ref={index}>
        <div className="box-content">
          <Media onClick={
            () => {
              vendorId && router.push(`${appBasePath}/vendor-profile/${vendorName.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`);
              }
              }>
            <Media.Left>
              <div className="vendor-brand-holder">
                <img src={vendorImage ? vendorImage : `${imageBasePath}/images/placeholder.png`} alt="Image" />
              </div>
            </Media.Left>
            <Media.Body>
              <Media.Heading>{index} {vendorName} <span className={isFavourite ? "mdi mdi-heart" : "mdi mdi-heart-outline"} onClick={(e) => this.props.removeFavourite(e,!isFavourite ,vendorId,userRequestId)} /></Media.Heading>
              <div className="rating rating-left">
                <span className={rating>0 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating>1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating>2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating>3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className={rating>4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                <span className="rating-text">{round(rating)} <span className="total-reviews">({reviews} reviews)</span></span></div>
              {distance && <span className="distance" onClick={() => { vendorId && router.push(`'${appBasePath}/vendor-profile/${vendorName.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`); }}>{distance} Km</span>}
            </Media.Body>
          </Media>
          <div className="box-footer">
            <div className="footer-container">
              <Button btnType="transparent" btnSize="sm" fontSize={13} label="View Quote" btnCallBack={() => this.props.btnClickFunc(requestId, jobId)} />
              <span className="quotes-cost">{price}</span>
              <div className="box-message">
                <IconNotification iconType="comment-processing-outline" iconLabel="Message" notifyClass="notification" viewMessaging={() => {this.props.viewMessaging(jobId)}} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default QuotesCard;
