import React, { Component } from 'react';
import CustomModal from './CustomModal';
import { Modal, Media } from 'react-bootstrap';
import map  from 'lodash/map';
import { imageBasePath, appBasePath } from '../../constants/staticData';

export default class CreateRequestPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { router, selecedCarProfileId, carProfileReducer, selectedVendorProfile } = this.props;
    const bookServiceOption = [
      {
        image: `${imageBasePath}/images/plus/wash-card.svg`,
        title: "Car Wash",
        url: `/car-wash`
      }, {
        image: `${imageBasePath}/images/plus/service-card.svg`,
        title: "Car Service",
        url: "/car-service"
      }, {
        image: `${imageBasePath}/images/plus/repair-card.svg`,
        title: "Car Repair",
        url: "/car-repair"
      }, {
        image: `${imageBasePath}/images/plus/mobile-service-card.svg`,
        title: "Roadside Assistance",
        url: ""
      }
    ];
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    const hasVehicleProfile = authData && authData.hasVehicleProfile ? true : false;
    const bookServiceOptionView = map(bookServiceOption, (service, key) => {
      let onClickUrl = '';
      if (selectedVendorProfile && selectedVendorProfile.id) {
        onClickUrl = hasVehicleProfile ? `${service.url}?vendor_id=${selectedVendorProfile.id}` : `/car-profiles/create?redirect_to=${service.url.substr(1, service.url.length)}&vendor_id=${selectedVendorProfile.id}`
      } else if (selecedCarProfileId && carProfileReducer && carProfileReducer.carProfiles) {
        onClickUrl = `${service.url}?car_id=${carProfileReducer.carProfiles[selecedCarProfileId].id}`;
      } else {
        onClickUrl = hasVehicleProfile ? service.url : `/car-profiles/create?redirect_to=${service.url.substr(1, service.url.length)}`;
      }
      return (
        <li key={key} onClick={() => {
          router.push(appBasePath + onClickUrl);
          //this.props.actions.getAllServices(this.state.selecedCarProfileId);
        }}>
          <Media>
            <Media.Left>
              <img width={69} height={69} src={service.image} alt="Image" />
            </Media.Left>
            <Media.Body>
              <h5>{service.title}</h5>
              <i className="mdi mdi-chevron-right" />
            </Media.Body>
          </Media>
        </li>
      );
    });

    return (
      <CustomModal showModal={this.props.modalStatus} title="book a service" className="bookService-modal" closeIcon={true} hideModal={() => this.props.hideModalCallback()}>
        <Modal.Body>
          <ul>
            {bookServiceOptionView}
          </ul>
        </Modal.Body>
      </CustomModal>
    );
  }
}
