import React, { Component } from 'react';
import Button from '../common/Button';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { find } from 'lodash'

class CarProfileBox extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
    }
    render() {
        const {router, type, key}= this.props;
        // const formatedDate = this.props.updatedAt ? moment(this.props.updatedAt).format("DD MMM YY") : undefined;


      if(type == "empty"){

        return (
          <div className=" bookService-card bookService-card-car-plus bookService-card-empty carcility-plus-car-profile-card" >
            <div className="myCar-card" onClick={ this.props.btnCallBack }>

              <figcaption className="myCar-card-caption empty">
                <span><i className="mdi mdi-plus" aria-hidden="true"></i></span>
                <h5>Car Profile</h5>
              </figcaption>
            </div>
          </div>
        );
      }else{
        let coverImage = find(this.props.images, (img) => {

          return img.isCover == true && img.type == "vehicle"

        });
        if(! coverImage &&  this.props.images && this.props.images.length ){

          coverImage = find(this.props.images, (img) => { return  img.type == "vehicle" });

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        }


        const carPhoto = coverImage ? coverImage.large : `${imageBasePath}/images/placeholder.png`;
        return (
          <div className="bookService-card-car-plus bookService-card carcility-plus-car-profile-card" key={key}>
            <div className={this.props.showSelection? "myCar-card myCar-card-selected" : "myCar-card"} onClick={ this.props.btnCallBack }>
            <figure>
                {carPhoto && <img src={carPhoto} alt="" />}
              </figure>
              <figcaption className="myCar-card-caption">
                <h4>{this.props.year}</h4>
                <h5 className="carModal-name">{this.props.make}  {this.props.model}</h5>
              </figcaption>
            </div>
          </div>
        );
      }



    }
}

export default CarProfileBox;
