import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { appBasePath } from '../../constants/staticData';
import { defaultImageOnError } from '../../helpers'; 

export default class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownVisible: false
        }
        this.noImageColor = this.noImageColor.bind(this);
    }
    componentWillMount() {

      let authData = JSON.parse(localStorage.getItem('authData'));
      this.state = {
      uploadImageFile: _.isEmpty(authData && authData.profilePhoto)?'': authData && authData.profilePhoto,
      }
      this.noImageColor();
    }
    componentWillReceiveProps(nextProps) {

        let authData = JSON.parse(localStorage.getItem('authData'));
        this.state = {
           uploadImageFile: _.isEmpty(authData && authData.profilePhoto)?'':authData && authData.profilePhoto,
        }
        this.noImageColor();
    }
    noImageColor(){
    let authData = JSON.parse(localStorage.getItem('authData'));
    const nameFirstLetter = authData ? authData.name.toUpperCase() : '';
    let colorChange ='';
    if(nameFirstLetter.charCodeAt(0)> 64 && nameFirstLetter.charCodeAt(0)<= 69){
      colorChange ={'backgroundColor':'#EAABAA'};
    } else if(nameFirstLetter.charCodeAt(0)> 69 && nameFirstLetter.charCodeAt(0)<= 74){
      colorChange ={'backgroundColor':'#AED5D1'};
    } else if(nameFirstLetter.charCodeAt(0)> 74 && nameFirstLetter.charCodeAt(0)<= 79){
      colorChange ={'backgroundColor':'#D0E2B2'};
    } else if(nameFirstLetter.charCodeAt(0)> 79 && nameFirstLetter.charCodeAt(0)<= 84){
      colorChange ={'backgroundColor':'#F7E196'};
    } else if(nameFirstLetter.charCodeAt(0)> 84 && nameFirstLetter.charCodeAt(0)<= 90){
      colorChange ={'backgroundColor':'#DBDBD9'};
    } else {
      colorChange ={'backgroundColor':'#C7C7E5'};
    }

         this.setState({
           'profilePikColor' : colorChange,
           'firstCharacter' : nameFirstLetter.charAt(0)
        });
    }
    render() {
    let authData = JSON.parse(localStorage.getItem('authData'));
        let titleDisplay = authData ? "name: "+authData.name + "\n"+"Mail: " +authData.email :'';
        return (
          <div title={titleDisplay}>
            <DropdownButton
                id={this.props.id ? this.props.id : 0}
                onToggle={()=>{this.setState({'dropDownVisible': !this.state.dropDownVisible});}}
                title={
                    <span>
                        {this.state.uploadImageFile
                         ? (<img src={this.state.uploadImageFile} className="imageupl" 
                         onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}}/>)
                         : (<p className="imageupl noImage no-image-color" style={this.state.profilePikColor}>{this.state.firstCharacter}</p>)
                        }
                        {this.state.dropDownVisible && <i className="mdi mdi-chevron-up" />}
                        {!this.state.dropDownVisible && <i className="mdi mdi-chevron-down" />}
                    </span>
                }>
                <MenuItem onClick={() => {this.props.router.push(`${appBasePath}/User-Accounts`);}} eventKey="1">
                    {/*<i className="mdi mdi-account" />*/} Settings <i className="mdi mdi-chevron-right" />
                </MenuItem>
                {/*<MenuItem eventKey="2">
                    <i className="mdi mdi-seal" /> Rewards
                </MenuItem>
                <MenuItem eventKey="3">
                    <i className="mdi mdi-settings" /> Settings
                </MenuItem>*/}
                <MenuItem onClick={() => {this.props.actions.logout(this.props.router)}} eventKey="4" className="">
                   Sign Out
                </MenuItem>
            </DropdownButton>
         </div>
        );
    }
}
