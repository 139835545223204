import React, { Component } from 'react';
import map  from 'lodash/map';
import {imageBasePath} from "../../constants/staticData";

class SummaryPanel extends Component {
    constructor() {
        super()
        this.state = {
            open: false
        }
    }
    render() {
        return (
          <div>

                <span>
                    <img src={this.props.subCat.image}  className="sub-img"/>
                    <label>{this.props.subCat.name}</label>
                   <img src={`${imageBasePath}/images/main_close.png`}  onClick={(e) => {
                     this.props.removeItem(this.props.subCat.id,"");
                   }}  className="close-img"/>
                </span>
            <ul>
              {map(this.props.subCat.sub_services, (subCat,key) => {
                if (subCat.checked) {
                  return (
                    <li key={key}><span onClick={(e) => {
                      this.props.removeItem(this.props.subCat.id,subCat.id);
                    }}>{subCat.name}  <img  src={`${imageBasePath}/images/sub_close.png`}  className="sub-close-img"/></span></li>
                  )
                }

              })}
            </ul>
          </div>
        );
    }
}

export default SummaryPanel;
