import React, { Component } from 'react';
import map  from 'lodash/map';
class RequestMobileSort extends Component {
  constructor() {
    super()
    this.state = { val: '' }
  }

  componentWillMount() {
    let data = []
    if (this.props.type == "request") {
      data = [{ title: "Quote Date - Newest to Oldest", stateText: "dateNO", orderText: "-date" },
      { title: "Quote Date - Oldest to Newest", stateText: "dateON", orderText: "date" },
      { title: "Name - Ascending - Descending", stateText: "nameON", orderText: "name" },
      { title: "Name - Descending - Ascending", stateText: "nameNO", orderText: "-name" },
      { title: "Distance - Near to Far", stateText: "DistNF", orderText: "distance" },
      { title: "Distance - Far to Near", stateText: "DistFN", orderText: "-distance" },
      { title: "Rating - Highest to Lowest", stateText: "ratingHL", orderText: "-rating" },
      { title: "Rating - Lowest to Highest", stateText: "ratingLH", orderText: "rating" },
      { title: "Price - Highest to Lowest", stateText: "priceHL", orderText: "-price" },
      { title: "Price - Lowest to Highest", stateText: "priceLH", orderText: "price" },

      ]
    } else if (this.props.type == "fav" || this.props.type == "search-res") {
      data = [{ title: "Distance - Near to Far", stateText: "DistNF", orderText: "distance" },
      { title: "Distance - Far to Near", stateText: "DistFN", orderText: "-distance" },
      { title: "Rating - Highest to Lowest", stateText: "ratingHL", orderText: "-rating" },
      { title: "Rating - Lowest to Highest", stateText: "ratingLH", orderText: "rating" },]
    } else if (this.props.type == "all-request") {
      data = [{ title: "Quote Date - Newest to Oldest", stateText: "dateNO", orderText: "-date" },
      { title: "Quote Date - Oldest to Newest", stateText: "dateON", orderText: "date" },
      ]
    }
    this.setState({ val: data });
  }

  render() {
    const { actions, activeTab, filterSort, type, jobId } = this.props;
    return (
      <div className="n-request-mobile-sort mobile-sort-options">
        <div className="header">
          <i className="mdi mdi-arrow-left" onClick={this.props.back}></i>
          {this.props.type != "all-request" ? <ul className="list-unstyled">
            {map(this.state.val, (val, key) => {
              return (
                <li key={key} onClick={() => (this.props.filterOption(val.stateText, val.orderText, jobId))} className={this.props.filterSort == "dateNO" ? "active" : ""}>
                  <label>
                    {val.title}
                  </label>
                  <span>
                    <i className={val.stateText == this.props.filterSort ? "mdi mdi-check active" : "hide"} />
                  </span>
                </li>
              )
            })}
          </ul> :
            <ul className="list-unstyled">
              {type == "all-request" && map(this.state.val, (val, key) => {
                return (
                  <li onClick={val.stateText == "dateNO" ? this.props.dateCreatedAsc : this.props.dateCreatedDesc} key={key}>
                    <label>
                      {val.title}
                    </label>
                    <span>
                      <i className={filterSort == val.stateText ? "mdi mdi-check active" : "hide"} />
                    </span>
                  </li>
                )
              })}

            </ul>}
        </div>
      </div>
    );
  }
}

export default RequestMobileSort;
