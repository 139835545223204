import React, { Component } from 'react';
import Header from '../../common/Header';
import Sidebar from '../../common/Sidebar';
import Footer from '../../common/Footer';
import MobileNotification from '../../common/MobileNotification';
import MobileMessage from '../../common/MobileMessage';
import Button from '../../common/Button';
import QuickServiceSteps from './QuickServiceSteps';
import each  from 'lodash/each';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import difference  from 'lodash/difference';
import CustomModal from '../../common/CustomModal';
import { Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import { queryStringtoArray } from '../../../helpers/index';
import CustomScroll from 'react-custom-scroll';
import ToastMessage from '../../common/ToastMessage';
import MobileHeaderSearch from '../../common/MobileHeaderSearch'
import {imageBasePath, appBasePath} from '../../../constants/staticData';
import TextInput from "../../common/TextInput";
import axios from 'axios';

export default class QuickCarService extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.toggleNotification = this.toggleNotification.bind(this);
    var serviceFetch = localStorage.getItem('servicesFetched');
    var CarServiceCategoryData = JSON.parse(localStorage.getItem('CarserviceCategoryList'));
    this.state = {
      mobileSearch:false,
      showOverlay:false,
      editClicked: false,
      infoModal: true,
      ChasisNumber: '',
      checkedItemPresent: false,
      notificationVisible: false,
      messageVisible: false,
      carServiceCategories: CarServiceCategoryData ? CarServiceCategoryData: [],
      filteredCarServiceCategories: CarServiceCategoryData ? CarServiceCategoryData : [],
      checkedCategories: [],
      selectedCarCategoryForModel: undefined,
      selectedCarProfile: undefined,
      promotionalOffersData: props.commonReducer && props.commonReducer.promotionalOffersData? props.commonReducer.promotionalOffersData : [],
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      catDescriptionModalVisible: false,
      categoriesSelectedCount: localStorage.getItem('selectedServiceId')? 1:0,
      selectedCarServiceDataArray: [],
      showError: undefined,
      selectedServiceId:localStorage.getItem('selectedServiceId')?localStorage.getItem('selectedServiceId'):null,
      selectedServiceName:localStorage.getItem('selectedServiceName')?localStorage.getItem('selectedServiceName'):null,
      selectedPromoCodeId:localStorage.getItem('selectedPromoCodeId')?localStorage.getItem('selectedPromoCodeId'):null,
      selectedService:JSON.parse(localStorage.getItem("selectedService") || false),
      servicesFetched: serviceFetch =="true" ? true: false,
      showChasis: true,
      offerFromCreateCarProfile: this.props.location  && this.props.location.state && this.props.location.state.offerFromCreateCarProfile ? this.props.location.state.offerFromCreateCarProfile : false,
      chassisImage: [],
      serviceClicked: false,
      hideStep1Panel:false,
      serviceSubCategoryModal:false,
      promoCodesModal: false,
      selectedPromoCode: localStorage.getItem("selectedPromoCode") || "",
      isPromoCodeUpdated: false,
      promotionalOffersIsApproved: false,
      popUpPromoCode: '',
      promoPopupError:0,
      searchCategory:[],
      promoApplied:false,
      num:0,
    };

  }

 
  componentWillMount() {

    

    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }


    if (queryStringtoArray('vendor_id')) {
      this.props.actions.getGarageProfileDetails(Number(queryStringtoArray('vendor_id')));
    }

    if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
      console.log('💔 💔 💔 0 ', this.props.commonReducer.promotionalOffersData);
      this.props.actions.getPromotionalOffers();
    }


    if(this.state.selectedPromoCodeId){
      this.props.actions.getSingleOffer(this.state.selectedPromoCodeId);
    }

  }

 

  componentDidMount() {


    
    if(! localStorage.getItem('bookingRequestId') ||  ! localStorage.getItem('serviceId') ){
      this.props.router.push(`${appBasePath}/dashboard-plus`)
    }else if( localStorage.getItem('serviceId') == "1"){
      this.props.router.push(`${appBasePath}/quick-booking/car-wash`)
    }else{
      if( localStorage.getItem('serviceId') != "2")
        this.props.router.push(`${appBasePath}/quick-booking/car-repair`)
    }


    this.setState({ filteredCarServiceCategories: cloneDeep(this.state.carServiceCategories) })
  }

  componentWillReceiveProps(nextProps) {
    
    

    let getVendorsCarServiceData = [];
  

    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }
    if(this.state.selectedPromoCode && this.state.selectedPromoCode != "undefined" && this.state.selectedCarProfile && ! this.state.promoApplied){
      var promotionData = {
        place: 0,
        services: [this.state.selectedServiceId],
        promo_code: this.state.selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.setState({promoApplied: true, isPromoCodeUpdated: true,});

      // this.props.actions.applyPromotionalOffers(promotionData);
    }




    if (!queryStringtoArray('vendor_id') && nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData && this.state.carServiceCategories && !this.state.carServiceCategories.length && this.state.filteredCarServiceCategories && !this.state.filteredCarServiceCategories.length && nextProps.bookingRequestReducer.servicesFetched) {
      this.setState({
        ...this.state,
        carServiceCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData,
        filteredCarServiceCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData,
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
    else if (queryStringtoArray('vendor_id') && nextProps && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services) {
      if (nextProps.commonReducer.garageProfileDetails.garage_services.length == 0) {
        this.setState({
          ...this.state,
          carServiceCategories: [],
          filteredCarServiceCategories: [],
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      } else {
        nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData && nextProps.bookingRequestReducer.serviceCategoryData.map((allSubService) => {
          let sub_services = [], services = {};
          allSubService.sub_services.map((subservices) => {
            nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
              if (subservices.id === vendorSubService.serviceId) {
                sub_services.push(subservices);
              }
            });
          });
          if (sub_services && sub_services.length > 0) {
            services['description'] = allSubService.description;
            services['id'] = allSubService.id;
            services['image'] = allSubService.image;
            services['name'] = allSubService.name;
            services['parentId'] = allSubService.parentId;
            services['sub_services'] = sub_services;
          }
          //console.log("services",services);
          if (size(services)) {
            getVendorsCarServiceData.push(services);
          }
        });
        if (getVendorsCarServiceData.length) {
          this.setState({
            ...this.state,
            carServiceCategories: getVendorsCarServiceData,
            filteredCarServiceCategories: getVendorsCarServiceData,
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        }
      }


      if (nextProps && nextProps.showError) {
        this.setState({ isButtonLoading: false, });

      }
    }


    if(! this.state.offerFromCreateCarProfile){

      this.setState({
        promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
        selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      });

    }



    let promotionalOffersData = nextProps.commonReducer && nextProps.commonReducer.singleOffersData ? nextProps.commonReducer.singleOffersData : "";



    if(promotionalOffersData && promotionalOffersData.services){

      let selectedService = promotionalOffersData.services.filter((service) => {
        return service.serviceId == this.state.selectedServiceId;
      });

      console.log("selectedService",selectedService)

      this.setState({
        selectedServiceId: this.state.selectedServiceId,
        selectedService: selectedService[0],
        selectedPromotionalOffersData: promotionalOffersData,

      });



    }



    let promoCategories = [];


    this.state.selectedPromotionalOffersData && each(this.state.selectedPromotionalOffersData.services, (promoCategory) => {
      promoCategories.push(promoCategory.serviceId);

      //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);

    });

    promoCategories && promoCategories.length && this.setState({
      selectedCarServiceDataArray: promoCategories,
      categoriesSelectedCount: promoCategories.length,

      /*
            selectedServiceId: promoCategoriesData[0].serviceId,
            selectedService: promoCategoriesData[0].name,*/

    });


    if(nextProps.commonReducer && nextProps.commonReducer.promotionalOffersMessage && this.state.selectedPromoCode && this.state.isPromoCodeUpdated ){

      this.setState({
        promotionalOffersMessage:nextProps.commonReducer.promotionalOffersMessage,
        promotionalOffersIsApproved:nextProps.commonReducer.promotionalOffersIsApproved });
    }

    if(this.state.selectedPromoCode){
      this.setState({
        promotionalOffersIsApproved:true,
        
        
      })
    }
    


    if(this.state.selectedServiceId){

      this.setState({
        showOverlay:true,
       // serviceSubCategoryModal:true,

      });

    }


    if(nextProps.commonReducer && nextProps.commonReducer.singleOffersData){
      let promotionalOffersData =  nextProps.commonReducer.singleOffersData;

      this.setState({
        selectedPromotionalOffersData: promotionalOffersData?promotionalOffersData:"",
      //  filteredCarServiceCategories: promotionalOffersData?promotionalOffersData.services:"",
      });


    }


   /* this.setState({
      filteredCarServiceCategories:filteredCarServiceCategories
    });
*/

  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carServiceCategories);
      let filteredCarServiceCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarServiceCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarServiceCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        'filteredCarServiceCategories': filteredCarServiceCategories,
        filterKeyword: true
      });
    } else {
      this.setState({
        'filteredCarServiceCategories': cloneDeep(this.state.carServiceCategories),
        filterKeyword: false
      });
    }
  }

  openCategory(id) {
    if (this.state.visibleCategory == id) {
      this.setState({
        'visibleCategory': undefined
      });
    } else {
      this.setState({
        'visibleCategory': id
      });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  hideModalCallback() {
    this.setState({ showChasis: false });
  }

  submitCallback(number, chassisImage) {
    console.log('The chasis Number is ', number);
    this.setState({
      ChasisNumber: number,
      showChasis: false,
      chassisImage
    });
  }


  submitPromoCodeData(isFromPopUp){


    let selectedPromoCode = this.state.selectedPromoCode;

    this.setState({
      isPromoCodeUpdated: true,
    });

    if(isFromPopUp){
      selectedPromoCode =  this.state.popUpPromoCode;
      if(selectedPromoCode)
        this.setState({ promoCodesModal: false, selectedPromoCode:selectedPromoCode});

    }

    if(selectedPromoCode){
      var promotionData = {
        place: 0,
        services: [this.state.selectedServiceId],
        promo_code: selectedPromoCode,
        brand: this.state.selectedCarProfile.carmodel.carmakeId,
      }

      this.props.actions.applyPromotionalOffers(promotionData);

    }
  }



  updateRadioButton(event,promoId) {


    var promotionalOffersData =  this.state.promotionalOffersData;
    var promotionalOffersData1 = promotionalOffersData.filter((promoCode)=>{return promoCode.id === promoId});


    var name =  this.state.selectedPromotionalOffersData.length? promotionalOffersData1[event.target.value].promoCode :  this.state.selectedPromotionalOffersData.promoCode;

    this.setState({ popUpPromoCode: name});


  }
  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      selectedCarCategoryForModel: categoryDetails,
      catDescriptionModalVisible: true
    })
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }

  disableEdit() {
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  select(carProfile) {



    localStorage.setItem('selectedServiceId', carProfile.id);
    localStorage.setItem('selectedService',JSON.stringify(carProfile));


    this.setState({
      ...this.state,
      selectedServiceId: carProfile.id,
      selectedService: carProfile,
      serviceClicked: true,
      categoriesSelectedCount: 1
    });
    document.body.click()
  }


  openPromoModal(){

    this.setState({
      promoCodesModal: true
    });
  }


  customPopoverRight(data) {
    return (
      <Popover className="customPopover quick-service-popover" id="popover-positioned-right">
        <div className="quick-approx-mileage">

          <TextInput
            type="text"
            placeholder="Enter Approx Mileage"
            name="phone"
            onChange={this.onFieldChange.bind(this)}/>
        </div>
        <h4>Approx Mileage </h4>
        <CustomScroll heightRelativeToParent="200px" allowOuterScroll={true}>

          <ul className="popoverCatList">

            {this.state.searchCategory.length? map(this.state.searchCategory, (item, index) => {
              return (<li className={this.state.selectedServiceId == item.id? "options active": "options"} key={index}>
                  <span  onClick={(e) => {

                    this.select(item)
                  }}
                  >
                    <label htmlFor={item.id} className="custom-checkbox"></label>
                    <label htmlFor={item.id}>
                      {item.checked} {item.name}
                    </label>
                  </span>
              </li>);
            })  :

              <li><label>No result found</label></li>
            }

          </ul>
        </CustomScroll>

        <div className="popOver-footer  text-right">

          <div className="filter-footer footer">
            <Button
              btnType="red"
              btnSize="sm"
              fontSize={15}
              label="Done"
              btnCallBack={() => {
                document.body.click()
              }}
            />
          </div>
        </div>
      </Popover>
    );
  }


  renderCarType(data) {


    let customPlaceholder = this.state.selectedService && this.state.selectedService.name?this.state.selectedService.name : "Enter Approx Milage";
    return (


      <OverlayTrigger onExit={(e) => {

        this.setState({serviceClicked:false})

      }}
        onEnter={(e) => {



          this.setState({
            "searchCategory" :data,
            'activeCarDetailCategory': data
          });


        }} defaultOverlayShown={this.state.showOverlay} trigger="click" ref="overlay" rootClose={true} placement="right" overlay={this.customPopoverRight(data)}   key={1} >
        <div className="sub-collapse-panel wash-collapse quick-booking-collapse col-md-3  col-sm-3  col-xs-6">

          <div className="sub-collapse-panel wash-collapse quick-booking-collapse quick-booking-service-web quick-booking-collapse col-md-12  col-sm-12  col-xs-12">
            {this.state.serviceClicked || this.state.selectedService ?  <i className="mdi mdi-checkbox-marked-circle quick-car-repair selected-icon"></i>: "" }
            <div className={this.state.serviceClicked  || this.state.selectedService  ? "sub-collapse-panel-head active" : "sub-collapse-panel-head"  } onClick={(event) => { this.setState({serviceClicked:true}); event.preventDefault(); }}>
              <figure>
                <img src={`${imageBasePath}/images/carservice.png`} alt="" />
              </figure>
              <h4>Car Service</h4>

            </div>
          </div>
          <div  onClick={(event) => { this.setState({serviceSubCategoryModal:true}) }} className="sub-collapse-panel wash-collapse quick-booking-service-mob quick-booking-collapse col-md-12  col-sm-12  col-xs-12">
            {this.state.serviceClicked || this.state.selectedService ?  <i className="mdi mdi-checkbox-marked-circle quick-car-repair selected-icon"></i>: "" }
            <div className={this.state.serviceClicked  || this.state.selectedService  ? "sub-collapse-panel-head active" : "sub-collapse-panel-head"  } onClick={(event) => { this.setState({serviceClicked:true}); event.preventDefault(); }}>
              <figure>
                <img src={`${imageBasePath}/images/carservice.png`} alt="" />
              </figure>
              <h4>Car Service</h4>

            </div>
          </div>
        </div>

      </OverlayTrigger>

    );
  }

  activateLoader(){
    this.setState({isButtonLoading:true})
  }

  deactivateLoader(){

    this.setState({isButtonLoading:false})

  }


  triggerChildFunction(){
    this.refs.QuickServiceSteps.requestQuotes();
  }


  triggerChildModalFunction(){

    this.refs.QuickServiceSteps.showPromoModal();
  }



  renderSubCategory(item,index) {


          return (<li className={this.state.selectedServiceId == item.id? " active": ""}  key={index}>
                      <span  onClick={(e) => {

                        this.select(item)
                      }}
                      >
                        <label htmlFor={item.id} className="custom-checkbox"></label>
                        <label htmlFor={item.id}>
                          {item.checked} {item.name}
                        </label>
                      </span>
          </li>);

  }




  triggerChildValidationFunction(){


    let validation = this.refs.QuickServiceSteps.requestQuotesValidate();

    if(validation){
      this.setState({showSummary: true,})
    }
  }


  onFieldChange(search) {


    let searchCategory = this.state.activeCarDetailCategory.filter(function(category) {
      return category.name.indexOf(search)>-1;
    });

    this.setState({
      searchCategory:searchCategory
    })




  }
  render() {
    
    const { commonReducer, actions, router, bookingRequestReducer } = this.props;
    console.log("YYYY = ",this.props);
    let isCoverImage = "";
    this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        isCoverImage =  (
          <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
          </div>
        );
      }
    });

    if(! isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length){
      this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
        if (value.type == "vehicle") {
          isCoverImage =  (
            <div className="type-img car-img">
                  <span className="car-profile-placeholder">
                    <img src={value.medium} alt="Carcility" />
                  </span>
            </div>
          );
        }
      });
    }


    let subCategoryBlock = [];

    let activeCarDetailCategory = this.state.searchCategory;


    if(activeCarDetailCategory){




      each(activeCarDetailCategory, (subCategory,key) => {


        subCategoryBlock.push(this.renderSubCategory(subCategory,key));




      });

    }



    //Filter the offers based on the Services that was choosen

    let validPromoCode = []


    if(this.state.selectedCarServiceDataArray.length){
      validPromoCode = this.state.promotionalOffersData.filter((promoCode) => {
        const serviceIds = map(promoCode.services, 'serviceId');
        return difference(this.state.selectedCarServiceDataArray, serviceIds).length === 0;
      });

    }




    let validPromoOffer =  filter(validPromoCode, (promo) => { return promo.services.parentId == 2 });


    if(this.state.selectedPromoCodeId && this.state.selectedPromotionalOffersData){
      validPromoOffer  = [this.state.selectedPromotionalOffersData];

    }


    let promoCodesHtml = validPromoOffer.map((promoCode, index) => {


      var radioId = "radio-"+promoCode.id;
      return (<div className="form-check promo-item" key={promoCode.id}>
          <input id={radioId}  className="radio-custom"  onClick={ e => this.updateRadioButton(e,promoCode.id) }   value={0} name="radio-group" type="radio"/>
          <label htmlFor={radioId} className="radio-custom-label">{promoCode.promoCode}</label>
          <p>
            {promoCode.description}
          </p>

          <a
            onClick={() => {

              // let route = this.$router.resolve({path: '/offer-terms'});
              // let route = this.$router.resolve('/link/to/page'); // This also works.
              window.open('/offer-terms/'+promoCode.id, '_blank');





              /* router.push({
                 pathname: appBasePath + '/offer-terms',
                 state: { promotionalOffersTerms: promoCode.termsAndConditions }
               });*/


            }}
          >*Terms and Conditions Apply</a>
        </div>

      );

    });



    let authData = localStorage.getItem('authData');
    authData = authData ? JSON.parse(authData) : '';

    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})}
        bookingRequestReducer = {bookingRequestReducer}
        commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}

          <div className="page-sec-header quick-booking-header">
            <div className="padwrapper">
              <span className="back-button"><i className="mdi mdi-arrow-left "></i></span>
              <h4>We can help you get the best car service</h4>


              { !this.state.hideStep1Panel ?

                <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                  <li className=""><a onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }  >Home</a></li>
                  <li className="active"><span>Car Service</span></li>
                </ol>


                :
                <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                  <li className=""><a  onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }   >Home</a></li>
                  <li className=""><a  onClick={() =>
                    this.setState({ hideStep1Panel: false , showSummary:false })
                  }   >Car Service</a></li>
                  <li className="active"><span>Book Service</span></li>
                </ol>
              }
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className={this.state.showSummary? "myCar-body col-md-8 hide": "myCar-body col-md-8" }>
                  <QuickServiceSteps
                    ref="QuickServiceSteps"
                    actions={actions}
                    categoriesSelectedCount={this.state.categoriesSelectedCount}
                    btnCallBack={this.requestQuotes}
                    chasisNumber={this.state.ChasisNumber}
                    chasisImage={this.state.chassisImage}
                    router={this.props.router}
                    selectedCarProfile={this.state.selectedCarProfile}
                    carProfiles={this.props.carProfileReducer.carProfiles}
                    selectedServiceId={this.state.selectedServiceId}
                    selectedService={this.state.selectedService}
                    promotionalOffersData = {this.state.promotionalOffersData}
                    selectedPromotionalOffersData = {this.state.selectedPromotionalOffersData}
                    selectedCarServiceDataArray = {this.state.selectedCarServiceDataArray}
                    hideStep1Panel = {this.state.hideStep1Panel}
                    viewBlock={this.renderCarType(this.state.filteredCarServiceCategories)}
                    showError={this.props.bookingRequestReducer.toastType}
                    servicesFetched={this.state.servicesFetched}
                    selectedPromoCode={this.state.selectedPromoCode}
                    showSummary = {this.state.showSummary}
                    searchView={this.filterCarType.bind(this)}
                    disableEdit={this.disableEdit.bind(this)}
                    editClicked={this.state.editClicked}
                    changeCarProfile={this.changeCarProfile.bind(this)}
                    activateLoader = {this.activateLoader.bind(this)}
                    deactivateLoader = {this.deactivateLoader.bind(this)}
                    commonReducer={commonReducer}
                    bookingRequestReducer={bookingRequestReducer}
                    garageProfileDetails={queryStringtoArray('vendor_id') && this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    carProfileReducer={this.props.carProfileReducer}
                  />
                </div>
                <div className="myCar-body col-md-4">

                  <div className="car-summary quick-booking-user">
                    <div className="summary-heading">
                      Hi <span>{authData ? authData.name : ''} !</span>
                    </div>
                    <p>
                      It’s great to see that you are booking a service !
                    </p>



                  </div>

                  <div className={this.state.showSummary?"car-summary quick-booking-summary show" : "car-summary quick-booking-summary"}>
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {isCoverImage ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/default-guest-car-profile.jpg`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc summary-car-detail">
                            <h5>{this.state.selectedCarProfile.name}
                              <span className="edit-car">
                              <a  onClick={() =>  this.props.router.push(`${appBasePath}/car-profiles/${this.state.selectedCarProfile.id}/edit`) }  ><i className="mdi mdi-pencil edit-car-icon"></i> Edit</a>
                            </span>
                            </h5>
                            <label>{this.state.selectedCarProfile.model}</label>
                            <label>Make: {this.state.selectedCarProfile.make}, Year: {this.state.selectedCarProfile.year}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    <div className="category-wrapper car-wrapper">
                      {this.state.selectedService ? <div className="category-wrapper car-wrapper">
                        <div className="category-heading">
                          <h5>Services Selected</h5>
                          {this.state.selectedService ?
                            <spna>
                              <label   className="more-service-mob"   onClick={() => {
                                window.scrollTo(0, 0)

                                this.setState({ hideStep1Panel: false, showSummary:false })

                              }} className="more-service-web">
                                <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                              </label>
                              <label  className="more-service-mob"   onClick={() => {
                                window.scrollTo(0, 0)

                                this.setState({ hideStep1Panel: false, showSummary:false })

                              }}>
                                <i className="mdi mdi-plus more-service-icon"></i><a>  More Services</a>
                              </label>

                            </spna>
                            :
                            []}
                        </div>
                        <div className="category-list">
                            <span>
                                <img src={`${imageBasePath}/images/carservice.png`}  className="sub-img" />
                               <label>Car Service</label>
                               <img src={`${imageBasePath}/images/main_close.png`}  onClick={(e) => {

                                 localStorage.removeItem('selectedServiceId')
                                 localStorage.removeItem('selectedService')


                                 this.setState({
                                   selectedServiceId: null,
                                   selectedService: null,

                                 });
                               }}  className="close-img"/>
                            </span>
                          <ul>

                            {this.state.selectedService && this.state.selectedService.name ?
                              <li className="rfegdf"><span onClick={(e) => {

                                localStorage.removeItem('selectedServiceId')
                                localStorage.removeItem('selectedService')
                                this.setState({
                                  selectedServiceId: null,
                                  selectedService: null,

                                });
                              }}> {this.state.selectedService.name} <img src={`${imageBasePath}/images/sub_close.png`}
                                                                        className="sub-close-img"/></span></li>
                              :
                              <li className="rfegdf"><span onClick={(e) => {

                                localStorage.removeItem('selectedServiceId')
                                localStorage.removeItem('selectedService')
                                this.setState({
                                  selectedServiceId: null,
                                  selectedService: null,

                                });
                              }}> {this.state.selectedServiceName} <img src={`${imageBasePath}/images/sub_close.png`}
                                                                    className="sub-close-img"/></span></li>
                            }
                          </ul>

                        </div>
                      </div>:
                        <div className="category-heading">
                          <h5>No Services Selected</h5>
                          {this.state.selectedService ?
                            <label>
                              <a onClick={() => this.setState({ editClicked: true })}>Edit</a>
                            </label>
                            :
                            []}
                        </div>
                      }
                    </div>

                    <div className="category-wrapper promo-wrapper">
                      <div className="category-heading">

                      {this.state.promotionalOffersIsApproved?

                      <div className="promo-success">
                        <span className="text">Promo Code</span>
                        <span className="code">‘{this.state.selectedPromoCode}’</span>
                        <span className="button"  onClick={() => {

                          this.setState({
                            promotionalOffersMessage:"",
                            selectedPromoCode:"",
                            promotionalOffersIsApproved:false });


                        }}>
                          remove
                        </span>
                        {this.state.selectedPromoCode &&
                        <div className="promo-page-error">{this.state.promotionalOffersMessage}</div>
                        }
                      </div>

                      :
                      <div>
                        <h5>Have a Promo Code ?</h5>
                        <label onClick={this.openPromoModal.bind(this)}>
                          <i className="mdi mdi-plus more-service-icon"></i><a>Apply</a>
                        </label>
                        


                      </div>
}




                      </div>
                    </div>


                    <div className="car-wrapper web-navigation-button">
                      <div className="">
                        <div className="type-desc full-width">
                          {! this.state.hideStep1Panel? <div className="next-button">
                              <Button disabled={this.state.categoriesSelectedCount > 0 && this.state.selectedService && this.state.selectedService.name ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {   this.setState({ hideStep1Panel: true }) }} />
                            </div>:

                            <div className="next-button clearfix">
                              <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                              <label className="label-text">Request will be sent to multiple service providers.</label>

                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal showModal={this.state.serviceSubCategoryModal} footer={ false}
                     closeIcon={false}  hideModal={() => { this.setState({ serviceSubCategoryModal: false }) }}
                     animation={false}
                     className="quick-repair-modal"
        >
          <Modal.Body>

            <div className="jobUpdate">
              <Header hideOption={()=>this.setState({searchMobile:true})}
              bookingRequestReducer = {bookingRequestReducer}
              notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
              <div className="main-wrapper quick-main-wrapper">
                <div className="page-sec-header quick-booking-header">
                  <div className="padwrapper">
                    <span className="back-button"  onClick={() =>  this.setState({ serviceSubCategoryModal: false }) }  ><i className="mdi mdi-arrow-left "></i></span>
                    <h4>Subcategories</h4>


                    <ol role="navigation" aria-label="breadcrumbs" className="breadcrumb">
                      <li className=""><a  onClick={() =>  this.props.router.push(`${appBasePath}/dashboard-plus`) }   >Home</a></li>
                      <li className=""><a  onClick={() =>
                        this.setState({ hideStep1Panel: false, serviceSubCategoryModal:false })
                      }   >Car Service</a></li>
                      <li className="active"><span>Subcategories</span></li>
                    </ol>

                  </div>
                </div>
                <div className="inSection">
                  <div className="padwrapper padwrapper-align">
                    <div className="myCar-list row">
                      <div className="sub-category-content">
                        <div className="quick-approx-mileage">

                          <TextInput
                            type="text"
                            placeholder="Enter Approx Mileage"
                            name="phone"
                            onChange={this.onFieldChange.bind(this)}/>
                        </div>
                        <h4 className="car-service-sub-category-header">Approx Milage</h4>

                        <CustomScroll heightRelativeToParent="calc(100vh - 200px)" allowOuterScroll={true}>

                          <ul className="popoverCatList  car-service-popover">
                            {subCategoryBlock.length ? subCategoryBlock :

                              <li><label>No result found</label></li>
                            }

                          </ul>
                        </CustomScroll>


                      </div>


                    </div>
                  </div>

                </div>

              </div>

            </div>

          </Modal.Body>
        </CustomModal>
        <CustomModal showModal={this.state.promoCodesModal} footer={validPromoCode.length > 0? true: false} cancelText="Cancel" saveText="Apply"
                     title="Add Promocode"
                     submitCallBack ={() => { this.submitPromoCodeData(true);  }}
                     closeIcon={true}  hideModal={() => { this.setState({ promoCodesModal: false }) }}>
          <Modal.Body>
            <div className="emgcy-modal">


              <div className="holder">



                {validPromoOffer.length > 0 && promoCodesHtml?  <form> {promoCodesHtml}  </form> :  <div className="promo-empty"> <img src={'/images/offers_empty.png'} alt='' /> <p> Oops! Looks like you don't have any Promo Offers. </p>  </div> }



              </div>

            </div>
          </Modal.Body>
        </CustomModal>
        <div className={this.state.serviceSubCategoryModal? "sidebar sidebar-subcategory show" : "sidebar sidebar-subcategory hide" } style={{ backgroundColor:'#fff' }}>
          <Button btnType="transparent" btnSize="sm" fontSize={15} label={"Cancel"} btnCallBack={(e) => {   this.setState({  serviceSubCategoryModal:false }) }}  />
          <Button btnType="red" btnSize="sm" fontSize={15} label={"Done"} btnCallBack={(e) => { this.setState({  serviceSubCategoryModal:false })  }}/>

        </div>
        <div className={this.state.serviceSubCategoryModal? "sidebar sidebar-next-button hide" : "sidebar sidebar-next-button show" } style={{ backgroundColor:'#fff' }}>
          {! this.state.hideStep1Panel? <div className="next-button">
              <Button  disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => {   this.setState({ hideStep1Panel: true }) }} />
            </div>:

            this.state.hideStep1Panel && ! this.state.showSummary?
              <div className="next-button clearfix">
                <Button btnType="red" btnSize="lg" fontSize={14} label="Show Summary" btnCallBack={ this.triggerChildValidationFunction.bind(this) }  />
                <label className="label-text">Request will be sent to multiple service providers.</label>

              </div>
              :
              <div className="next-button clearfix">
                <Button btnType="red" btnSize="lg" fontSize={14} label="Book Service" btnCallBack={this.triggerChildFunction.bind(this)} isButtonLoading={this.state.isButtonLoading} />
                <label className="label-text">Request will be sent to multiple service providers.</label>

              </div>

          }

        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection  quick-footerSection" >
          <Footer {...this.props}/>
        </div>
        <CustomModal showModal={this.state.catDescriptionModalVisible} title={this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.name} closeIcon={true} hideModal={() => { this.setState({ 'catDescriptionModalVisible': false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.description}</p>
          </Modal.Body>
        </CustomModal>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
        {/*<ChasisNumberPopup
          modalStatus={this.state.showChasis}
          hideModalCallback={this.hideModalCallback.bind(this)}
          submitCallback={this.submitCallback.bind(this)}
          actions= {actions}
          bookingRequestReducer={bookingRequestReducer}
        />*/}
      </div>
    );
  }
}
