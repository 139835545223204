import React, { Component } from 'react';
import map  from 'lodash/map';

class FaqAndCategories extends Component {
    render() {
        let { selectQuestion, topFaq, categories } = this.props

        return (
            <div>
                <div className="top">
                    <h4 className="heading">Top FAQs</h4>
                    <ul className="question-holder">
                        {map(topFaq, (faqVal, key) => {
                            return (
                                <li className="single-question" key={key} onClick={() => (selectQuestion(key))}>
                                    <i className="mdi mdi-chevron-right" />
                                    <a className="question">{faqVal.question}</a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="bottom">
                    <h4 className="heading">Categories</h4>
                    <ul className="question-holder">
                        {map(categories, (catVal, key) => {
                            return (
                                <li className="single-question" key={key}>
                                    <i className="mdi mdi-chevron-right" />
                                    <a className="question">{catVal}</a>
                                </li>
                            )
                        })}
                    </ul>

                </div>


            </div>
        );
    }
}

export default FaqAndCategories;
