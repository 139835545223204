import React, { Component } from "react";
import lowerCase  from 'lodash/lowerCase';
import groupBy  from 'lodash/groupBy';
import size  from 'lodash/size';
import map  from 'lodash/map';
import find  from 'lodash/find';
import StatusBar from "../common/StatusBar";
import CustomModal from "../common/CustomModal";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import Rating from "react-rating";
import PageLoader from "../common/PageLoader";
import { imageBasePath, appBasePath } from "../../constants/staticData";

class CardType extends Component {
  constructor(props) {
    super(props);
    let garagejobquotes =
      props.cardDetails &&
      props.cardDetails.garagejobquotes &&
      props.cardDetails.garagejobquotes.length
        ? props.cardDetails.garagejobquotes[0]
        : "";
    let garagereview = find(garagejobquotes && garagejobquotes.garagereview, {
      jobId: garagejobquotes.id
    });
    this.state = {
      showTimeLine: false,
      saveModal: false,
      max_chars: 255,
      chars_left: 0,
      collapseTimeline: true,
      ratingValue:
        garagereview && garagereview.rating ? garagereview.rating : 1,
      comments: "",
      isButtonLoading: false,
      showModal: false,
      carProfileImages: []
    };
    this.getIcons = this.getIcons.bind(this);
  }

  ratingOnChange(rating) {
    this.setState({ ratingValue: rating });
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.authReducer.hideButtonLoader ||
        nextProps.bookingRequestReducer.hideButtonLoader) &&
      this.state.isButtonLoading
    ) {
      this.setState({
        isButtonLoading: false,
        saveModal: false,
        showModal: false
      });
      nextProps.actions.hideButtonLoader();
    }
    if (
      nextProps.cardDetails &&
      nextProps.cardDetails.garagejobquotes &&
      nextProps.cardDetails.garagejobquotes.length
    ) {
      let garagejobquotes = nextProps.cardDetails.garagejobquotes.length
        ? nextProps.cardDetails.garagejobquotes[0]
        : "";
      if (
        garagejobquotes &&
        garagejobquotes.garagereview &&
        garagejobquotes.garagereview.length
      ) {
        let garagereview = find(
          garagejobquotes && garagejobquotes.garagereview,
          { jobId: garagejobquotes.id }
        );
        this.setState({
          ratingValue:
            garagereview && garagereview.rating ? garagereview.rating : 1
        });
      }
    }

    if( nextProps.cardDetails &&
        nextProps.cardDetails.vehicles &&
        nextProps.cardDetails.vehicles.images
      ) {
        //Filter non chassisImages
        const nonChasisImages = [];
        map(nextProps.cardDetails.vehicles.images, (img) => {
          if (img.small.indexOf('_chassisNumber_') === -1) {
            nonChasisImages.push(img);
          }
        });
        this.setState({
          carProfileImages: nonChasisImages
        });
      }

  }

  getIcons(jobType, val, garagereview) {
    if (val == "Waiting" || val == "Active") {
      return (
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            switch (jobType.serviceId || jobType && jobType.services.id) {
              case 1:
                this.props.router.push(
                  `${appBasePath}/edit-carwash?requestId=${jobType.requestid}`
                );
                break;
              case 3:
                this.props.router.push(
                  `${appBasePath}/edit-carrepair?requestId=${jobType.requestid}`
                );
                break;

                case 5:
                  this.props.router.push(
                    `${appBasePath}/edit-plus-request?requestId=${jobType.requestid}`
                  );
                  break;
              case 2:
                this.props.router.push(
                  `${appBasePath}/edit-carservice?requestId=${
                    jobType.requestid
                  }`
                );
                break;
              default:
                break;
            }
          }}
          className="job-icon  notification"
        >
          <span className="mdi mdi-pencil" />
          <span className="commentLabel">Edit</span>
        </div>
      );
    } else if (val == "Active") {
      return (
        <div>
          {/* <div
            className="job-icon  notification"
            onClick={this.props.quotesClick}
          >
            <span className="mdi mdi-file-outline" />
            <span className="notifyTag" />
            <span className="commentLabel">Quotes</span>
          </div> */}
        </div>
      );
    } else if (val == "Accepted" || val == "In-progress") {
      return (
        <div
          className="job-icon  notification"
          onClick={this.props.messageClick}
        >
          <span className="mdi mdi-comment-processing-outline" />
          <span className="notifyTag" />
          <span className="commentLabel">Message</span>
        </div>
      );
    } else if (val == "Completed") {
      return (
        <div
          className="job-icon  notification"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ saveModal: true });
          }}
        >
          <span
            className={garagereview ? "mdi mdi-star" : "mdi mdi-star-outline"}
          />
          <span className="commentLabel">Review</span>
        </div>
      );
    } else if (val == "Cancelled") {
      return (
        <div className="job-icon  notification">
          <span className="mdi mdi-help" />
          <span className="commentLabel">Help</span>
        </div>
      );
    } else if (val == "Expired") {
      return (
        <div>
          <div className="job-icon  notification">
            <span className="mdi mdi-help" />
            <span className="commentLabel">Help</span>
          </div>
          {jobType.serviceId !== 4 ? (
            <div
              className="job-icon  notification"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                //  if(jobType && jobType.requestid){
                //   this.props.actions.reactiveExpiredBookingRequests(jobType.requestid);
                //  }
                this.setState({
                  showModal: true
                });
              }}
            >
              <span className="mdi mdi-refresh" />
              <span className="commentLabel">Re-book</span>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }else if (val == "Incomplete") {
      return (
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            localStorage.setItem('bookingRequestId',jobType.id);

            switch (jobType.serviceId) {
              case 1:
                this.props.router.push(
                  `${appBasePath}/quick-booking/car-wash`
                );
                break;
              case 3:
                this.props.router.push(
                  `${appBasePath}/quick-booking/car-repair`
                );
                break;
              case 2:
                this.props.router.push(
                  `${appBasePath}/quick-booking/car-service`
                );
                break;
              default:
                break;
            }
          }}
          className="job-icon  notification"
        >
          <span className="mdi mdi-pencil" />
          <span className="commentLabel">Edit</span>
        </div>
      );
    }
  }

  handleChange(event) {
    const val = event.target.value;
    this.setState({
      chars_left: this.state.max_chars - val.length,
      comments: val
    });
  }
  showTimeline(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showTimeLine: !this.state.showTimeLine });
  }

  submitCallBack() {
    if (
      this.props.cardDetails &&
      this.props.cardDetails.id &&
      this.props.cardDetails.requestid
    ) {
      this.props.actions.reactiveExpiredBookingRequests(
        this.props.cardDetails.id,
        this.props.cardDetails.requestid,
        this.props.router
      );
      //this.props.router.push("/request/" + this.props.cardDetails.requestid);
      this.setState({ showModal: false });
    }
  }

  render() {
    let {
      userPaymentStatus,
      paymentState,
      cardDetails,
      jobLeftGridValue,
      jobRightGridValue,
      messageRoute,
      vendorQuotesCount,
      actions,
      source
    } = this.props;


    let expectedClass =
      cardDetails &&
      cardDetails.requeststatus &&
      cardDetails.requeststatus.displayname == "In-progress"
        ? cardDetails.requeststatus.displayname
        : cardDetails.requeststatus &&
          lowerCase(cardDetails.requeststatus.displayname);
    let startDate = new Date(cardDetails.createdAt);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    startDate =
      startDate.getDate() +
      " " +
      months[startDate.getMonth()] +
      " '" +
      startDate
        .getFullYear()
        .toString()
        .substring(2) +
      " " +
      startDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    let garagejobquotes =
      cardDetails &&
      cardDetails.garagejobquotes &&
      cardDetails.garagejobquotes.length
        ? cardDetails.garagejobquotes[0]
        : "";
    let garageJobquotesDetails =
      cardDetails &&
      cardDetails.jobStatus &&
      cardDetails.jobStatus.length &&
      cardDetails.jobStatus[cardDetails.jobStatus.length - 1]
        .garagejobquotesdetail;
    let groupedJobStatus = {};
    if (
      garageJobquotesDetails &&
      garageJobquotesDetails.length &&
      garageJobquotesDetails[0].parentId != 1
    ) {
      groupedJobStatus =
        garageJobquotesDetails &&
        groupBy(garageJobquotesDetails, "parent.name");
    } else {
      map(garageJobquotesDetails, jobStatus => {
        groupedJobStatus[jobStatus.serviceName] = [jobStatus];
      });
    }
    let garagereview = find(garagejobquotes && garagejobquotes.garagereview, {
      jobId: garagejobquotes.id
    });


    let coverImage = "";
    this.state.carProfileImages && this.state.carProfileImages.length && this.state.carProfileImages.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        coverImage =   value.medium;
      }
    });

    if(! coverImage && this.state.carProfileImages && this.state.carProfileImages.length){
      this.state.carProfileImages && this.state.carProfileImages.length && this.state.carProfileImages.map((value, key) => {
        if (value.type == "vehicle") {
          coverImage =   value.medium;
        }
      });
    }



    return (
      <div className={"job-updates " + expectedClass}>
        <div className="row" onClick={messageRoute}>
          <div className="col-md-12 col-sm-12 col-xs-12 pad0">
            <div className={jobLeftGridValue + " col-sm-12 col-xs-12 pad0"}>
              <div className="job-left">
                <div className="job-card">
                  <div className="card-img">
                    <img
                      src={
                        coverImage
                          ? coverImage
                          : `${imageBasePath}/images/placeholder.png`
                      }
                    />
                  </div>
                  <div className="card-info">
                    <div className="job-name">
                      {cardDetails.vehicles ? cardDetails.vehicles.name : ""}
                    </div>
                    <div className="job-title">

                      {cardDetails.services && cardDetails.plus!=true
                        ? (cardDetails.serviceId != 4 ? " " : "") +
                          cardDetails.services.name
                        :

                        (cardDetails.serviceId != 4 ? cardDetails.plus? "Carcility Plus Service" :"" : "")
                          // +cardDetails.services.name
                        }
                    </div>
                    <div className="job-details">
                      <ul>
                        <li>
                          <label>Request ID :</label>
                          <span>{cardDetails.requestid}</span>
                        </li>
                        <li>
                          <label>Created :</label>
                          <span>{startDate?startDate:""}</span>
                        </li>
                        {cardDetails.requeststatus &&
                        (cardDetails.requeststatus.displayname == "Accepted" ||
                          cardDetails.requeststatus.displayname ==
                            "In-progress" ||
                          cardDetails.requeststatus.displayname ==
                            "Completed") ? (
                          <li
                            className="desktop-expand-timeline"
                            onClick={e => {
                              this.showTimeline(e);
                              if (!cardDetails.jobStatus) {
                                cardDetails &&
                                  actions.getJobStatus(
                                    cardDetails.id,
                                    cardDetails.requestid,
                                    source,

                                  );
                              }
                            }}
                          >
                            {cardDetails.plus!=true ?
                            this.state.showTimeLine ? (
                              <button className="btn btn-theme sm label">
                                <i className="mdi mdi-chevron-up" />
                                Job Status
                              </button>
                            ) : (
                              <button className="btn btn-theme sm label">
                                <i className="mdi mdi-chevron-down" />
                                Job Status
                              </button>
                            ):null}
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={jobRightGridValue + " col-sm-12 col-xs-12 pad0"}>
              <div className="job-right">
                <div className="job-right-header">
                  <div className={"status-label " + expectedClass}>
                    {cardDetails.plus == true && cardDetails.requeststatus.displayname == "Active" ? "Confirmed": cardDetails.requeststatus &&
                    cardDetails.requeststatus.displayname == "In-progress"
                      ? "In Progress"
                      : cardDetails.requeststatus &&
                        cardDetails.requeststatus.displayname}
                    {/* {cardDetails.requeststatus &&
                    cardDetails.requeststatus.displayname == "In-progress"
                      ? "In Progress"
                      : cardDetails.requeststatus &&
                        cardDetails.requeststatus.displayname} */}
                        {/* <p>Confirmed</p> */}

                        <span className="mob-view">
                           {cardDetails.requeststatus && userPaymentStatus &&
                           cardDetails.requeststatus.displayname == "Completed"
                             ? cardDetails.plus == true ?
                             cardDetails.totalamount > 0 ? cardDetails.paymentstatus && cardDetails.paymentstatus.displayname == "Paid" ? " Paid" :  cardDetails.finalAmount > 1 ? " Unpaid" :" Paid" :" Unpaid"
                             :
                             garagejobquotes.totalamount > 0? cardDetails.paymentstatus && cardDetails.paymentstatus.displayname == "Paid" ? " Paid" : garagejobquotes.discountAmount && garagejobquotes.finalAmount == 0? " Free" :" Unpaid" :" Free"
                             : ""
                           }
                        </span>
                        <span className="desk-view">
                        {cardDetails.requeststatus && userPaymentStatus &&
                        cardDetails.requeststatus.displayname == "Completed"
                          ? cardDetails.plus == true ?
                          cardDetails.totalamount > 0? cardDetails.paymentstatus && cardDetails.paymentstatus.displayname == "Paid" ? " Paid" :  cardDetails.finalAmount > 1 ? " Unpaid" :" Paid" :" Unpaid"
                          :
                          garagejobquotes.totalamount > 0? cardDetails.paymentstatus && cardDetails.paymentstatus.displayname == "Paid" ? " Paid" :  garagejobquotes.discountAmount && garagejobquotes.finalAmount == 0? " Free" :" Unpaid" :" Free"
                          : ""
                        }
                     </span>

                  </div>
                  <div className="job-icon notification">
                    {cardDetails.requeststatus
                      ? this.getIcons(
                          cardDetails,
                          cardDetails.requeststatus.displayname,
                          garagereview
                        )
                      : ""}
                  </div>
                </div>
                {cardDetails.requeststatus &&
                (cardDetails.requeststatus.displayname == "Waiting" ||
                  cardDetails.requeststatus.displayname == "Active" ||
                  cardDetails.requeststatus.displayname == "Cancelled" ||
                  cardDetails.requeststatus.displayname == "Incomplete" ||
                  cardDetails.requeststatus.displayname == "Expired"
                  // cardDetails.requeststatus.displayname == "Accepted" ||
                  // cardDetails.requeststatus.displayname == "In-progress"
                  ) ? (
                  <div className="jr-body">
                    <div className="jobInfo ">
                      {cardDetails.requeststatus.displayname == "Waiting" && (
                        <p>
                          {" "}
                          Your Car {cardDetails.services.name} request has been
                          placed successfully. Our service providers are currently
                          assessing your request and will get back with
                          their quotes soon.
                        </p>
                      )}
                       {
                       cardDetails.plus == true && cardDetails.requeststatus.name == "Active" &&(
                        <p>
                          {" "}
                          Your booking is confirmed and our team will contact you shortly.
                        </p>
                      )}
                       { cardDetails.plus != true && cardDetails.requeststatus.name == "Active" && (
                        <p>
                          {" "}
                          You have received {vendorQuotesCount} new quotes.
                          Start a chat with the service providers to define the scope of
                          work and negotiate the quotation.
                        </p>
                      )}

                      {cardDetails.requeststatus.displayname == "Cancelled" && (
                        <p>
                          {" "}
                          You have canceled the job request. Kindly create a new
                          request.
                        </p>
                      )}
                      {cardDetails.requeststatus.displayname == "Expired" && (
                        <p>
                          Sorry, your request has expired. The request had to be
                          closed due to failure in its alottment.{" "}
                          {cardDetails.serviceId !== 4
                            ? "Please re-book to request the job again."
                            : ""}
                        </p>
                      )}
                      {cardDetails.requeststatus.displayname == "Incomplete" && (
                        <p>
                          Your car service request has been created. But to provide you with quotes, we need some more information.{" "}
                          {cardDetails.serviceId !== 4
                            ? "Please re-book to request the job again."
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {
                cardDetails.requeststatus &&
                cardDetails.requeststatus.displayname != "Waiting" &&
                cardDetails.requeststatus.displayname != "Active" &&
                cardDetails.requeststatus.displayname != "Cancelled" &&
                cardDetails.requeststatus.displayname != "Expired"
                // cardDetails.requeststatus.displayname != "Accepted" &&
                // cardDetails.requeststatus.displayname != "In-progress"
                ? (
                  <div className="vendor-quote">
                    {cardDetails.plus == true ?
                    <span className= "vendor-description">
                      <b>{cardDetails.vehicles.name}</b>
                      <p>The Service provider has completed the job and your vehicle is ready.</p>
                    </span>
                  :
                    <ul>
                      <li>
                        <label>Service Provider</label>
                        {garagejobquotes &&
                          garagejobquotes.garage &&
                          garagejobquotes.garage.companyprofilename && (
                            <span
                              className="vendor-place"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (garagejobquotes.garage.id) {
                                  this.props.router.push(
                                    `${appBasePath}/vendor-profile/${garagejobquotes.garage.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`
                                  );
                                }
                              }}
                            >
                              {garagejobquotes.garage.companyprofilename}
                            </span>
                          )}
                        <span>
                          {garagejobquotes && garagejobquotes.garage
                            ? garagejobquotes.garage.address
                            : ""}
                        </span>
                        {/*<span className="vendor-mobile">
                          {garagejobquotes && garagejobquotes.garage
                            ? garagejobquotes.garage.phone
                            : ""}
                          </span>*/}
                      </li>
                      <li>
                        <label>Quote</label>
                        <span>
                          {garagejobquotes && garagejobquotes.finalAmount
                            ? Number(garagejobquotes.finalAmount).toFixed(2)  +
                              " " +
                              garagejobquotes.currency
                            :  "0.00 " + garagejobquotes.currency}
                        </span>
                      </li>
                    </ul>}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {garagejobquotes &&
        garagejobquotes.garagejobquotesdetail &&
        this.state.showTimeLine &&
        this.state.collapseTimeline ? (
          <div className="job-footer active">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                <div className="job-process">
                  <h1 className="job-footer-title">Job Progress</h1>
                  {cardDetails.isJobStatusLoaded ? (
                    <div>
                      <StatusBar
                        jobEndDatetime={
                          cardDetails &&
                          cardDetails.jobStatus &&
                          cardDetails.jobStatus.length &&
                          cardDetails.jobStatus[0] &&
                          cardDetails.jobStatus[
                            cardDetails.jobStatus.length - 1
                          ].jobEndDatetime
                        }
                        jobStartDate={
                          cardDetails &&
                          cardDetails.jobStatus &&
                          cardDetails.jobStatus.length &&
                          cardDetails.jobStatus[0] &&
                          cardDetails.jobStatus[
                            cardDetails.jobStatus.length - 1
                          ].jobStartDatetime
                        }
                        requestStatus={
                          cardDetails.requeststatus &&
                          cardDetails.requeststatus.displayname == "In-progress"
                            ? "inprogress"
                            : cardDetails.requeststatus &&
                              cardDetails.requeststatus.displayname
                        }
                        serviceStatuses={
                          cardDetails &&
                          cardDetails.jobStatus &&
                          cardDetails.jobStatus.length &&
                          cardDetails.jobStatus[0] &&
                          cardDetails.jobStatus[
                            cardDetails.jobStatus.length - 1
                          ].garagejobquotesdetail
                        }
                        groupedJobStatus={
                          size(groupedJobStatus) ? groupedJobStatus : []
                        }
                        statusCount={groupedJobStatus && size(groupedJobStatus)}
                        allServices={
                          cardDetails &&
                          cardDetails.jobStatus &&
                          cardDetails.jobStatus.length &&
                          cardDetails.jobStatus[
                            cardDetails.jobStatus.length - 1
                          ].garagejobquotesdetail
                        }
                      />
                      <span className="job-start-point">Job start</span>
                      <span className="job-end-point">Car ready</span>
                    </div>
                  ) : (
                    <PageLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <CustomModal
          header={false}
          saveProgress={this.state.isButtonLoading}
          footer={garagereview ? false : true}
          showModal={this.state.saveModal}
          hideModal={() => {
            this.setState({
              saveModal: false,
              chars_left: 0,
              ratingValue: 1,
              comments: ""
            });
          }}
          className="rating-modal"
          submitCallBack={() => {
            this.setState({ isButtonLoading: true });
            this.props.actions.sendReview(
              cardDetails.id,
              cardDetails.garagejobquotes[0].garageId,
              cardDetails.garagejobquotes[0].id,
              this.state.ratingValue,
              this.state.comments,
              cardDetails.requestid,
              source
            );
          }}
          closeIcon="true"
          saveText="Submit"
        >
          <Modal.Body>
            <div className="image-holder">
              <img
                src={
                  garagejobquotes && garagejobquotes.garage
                    ? garagejobquotes.garage.logo
                    : ""
                }
                alt=""
              />
              <h4>
                {garagejobquotes && garagejobquotes.garage
                  ? garagejobquotes.garage.companyprofilename
                  : ""}
              </h4>
              <span className="sub-text">
                {cardDetails.services ? "Car " + cardDetails.services.name : ""}
              </span>
              <span className="sub-text">
                {garagejobquotes && garagejobquotes.totalamount
                  ? garagejobquotes.totalamount + " " + garagejobquotes.currency
                  : ""}
              </span>
            </div>
            <div className="stars">
              <Rating
                start={0}
                readonly={garagereview ? true : false}
                initialRate={this.state.ratingValue}
                empty="mdi mdi-star-outline "
                full="mdi mdi-star active-star"
                onChange={e => {
                  this.ratingOnChange(e);
                }}
              />
            </div>
            {!garagereview ? (
              <div className="comments rtg-modal">
                <FormGroup>
                  <FormControl
                    maxLength="255"
                    className="textAlign"
                    componentClass="textarea"
                    placeholder="Comments"
                    onChange={this.handleChange.bind(this)}
                  />
                </FormGroup>
                <span className="text-limit">{this.state.chars_left}/255</span>
              </div>
            ) : (
              <div className="written-comments">
                {garagereview.comments
                  ? garagereview.comments
                  : "No Comments found"}
              </div>
            )}
          </Modal.Body>
        </CustomModal>
        <CustomModal
          submitCallBack={this.submitCallBack.bind(this)}
          saveProgress={this.state.isButtonLoading}
          showModal={this.state.showModal}
          hideModal={() => {
            this.setState({ showModal: false });
          }}
          footer={true}
          title="Rebook request"
          saveText="Confirm"
          cancelText="Close"
        >
          <Modal.Body>
            <p className="info-text">
              Are you sure you want to re-book this request
            </p>
          </Modal.Body>
        </CustomModal>
        {/* <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} /> */}
      </div>
    );
  }
}

export default CardType;
