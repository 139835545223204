import { isProduction, isStaging } from "../constants/staticData";
export const BASE_URL = isProduction
  ? "https://api.carcility.com"
  : isStaging ? "https://api-server-stage.carcility.com" : "https://api-server-test.carcility.com";
/* Auth APIs */
export const SIGNUP = BASE_URL + "/auth/signup";
export const SIGNIN = BASE_URL + "/auth/login";
export const SOCIAL_AUTH = BASE_URL + "/auth/socialsignin";
export const FORGOT_PASSWORD = BASE_URL + "/auth/forgot-password";
export const VERIFY_OTP = BASE_URL + "/api/users/verifyotp/";
export const VERIFY_EMAIL = BASE_URL + "/auth/verifyemail";
export const REQUEST_OTP = BASE_URL + "/api/users/otp";
export const RESET_PASSWORD = BASE_URL + "/auth/reset-password";
export const CONTACT_US = BASE_URL + "/auth/contactus";
export const LOGOUT = BASE_URL + "/api/users/logout";
export const GUEST_USER = BASE_URL + "/api/guest/createjobrequest";
export const GET_ACCOUNT_STATUS = BASE_URL + "/api/users/accountstatus";

/*User Account APIs */
export const CHANGE_PASSWORD = BASE_URL + "/api/users/changepassword";
export const GET_NOTIFICATIOINFO = BASE_URL + "/api/users/notification";
export const CONTACTDETAILSUPDATE = BASE_URL + "/api/users/contactdetails";
export const SENDOTP = BASE_URL + "/api/users/sendotp";
export const IMAGE_UPLOAD_REQUEST =
  BASE_URL + "/api/images/s3upload?file_name=";

export const GET_PROMOTIONAL_OFFERS = BASE_URL + "/api/offer/customer/promotional";
export const GET_ALL_OFFERS = BASE_URL + "/api/offer/customer/all";
export const GET_SINGLE_OFFER = BASE_URL + "/api/offer";
export const APPLY_PROMOTIONAL_OFFERS = BASE_URL + "/api/offer/customer/apply";




/* Common APIs */
export const GOOGLE_PLACES_SEARCH =
  "https://maps.googleapis.com/maps/api/place/nearbysearch/output";
export const GOOGLE_GEO_CODE =
  "http://maps.googleapis.com/maps/api/geocode/json?latlng=";
export const GOOGLE_PLACES_AUTOCOMPLETE =
  "https://maps.googleapis.com/maps/api/place/autocomplete/output?parameters";
export const USER_LOCATION = BASE_URL + "/api/users/location/";
export const GET_AUTO_SUGGESTED_LIST = BASE_URL + "/api/services/searchlist";
export const SEARCH_VENDORS = BASE_URL + "/api/services/business/search";
export const GET_GARAGE_PROFILE = BASE_URL + "/api/vendor/profiledetail";
export const GET_REVIEWS_AND_RATINGS = BASE_URL + "/api/vendor/reviews";
export const GET_APP_DOWNLOAD_LINK = BASE_URL + "/api/services/apps";

/* Car Profile APIs */
export const CREATE_CAR_PROFILE = BASE_URL + "/api/vehicles";
export const CURRENT_USER_DETAILS = BASE_URL + "/api/users/me";
export const LIST_CAR_PROFILES = BASE_URL + "/api/vehicles";
export const EDIT_CAR_PROFILES = BASE_URL + "/api/vehicles/";
export const GET_CAR_PROFILE_DETAILS = BASE_URL + "/api/vehicles/";
export const DELETE_CAR_PROFILE = BASE_URL + "/api/vehicles/";
export const CAR_MAKE_AND_MODELS = BASE_URL + "/api/cars";
export const GET_CAR_MANUFACTURERS = BASE_URL + "/api/cars/manufacturers";
export const GET_CAR_MODELS = BASE_URL + "/api/cars/models";
export const DELETE_VEHICLE_IMAGE = BASE_URL + "/api/vehicles/image/";
export const SET_COVER_FOR_CAR_PROFILE = BASE_URL + "/api/vehicles/changeCover";
export const UPLOAD_VEHICLE_IMAGE = BASE_URL + "/api/vehicles/uploadimages/";
export const GET_CAR_SERVICE_HISTORY = BASE_URL + "/api/vehicles/";

/*Book Service APIs*/
export const GET_ALL_SERVICES = BASE_URL + "/api/services";
export const CREATE_BOOKING_REQUEST =
  BASE_URL + "/api/bookingrequest/createjobrequest?imageflow=1";
export const CREATE_QUICK_BOOKING_REQUEST =
  BASE_URL + "/api/quickbooking";
export const GET_ALL_CANCEL_REASONS =
  BASE_URL + "/api/bookingrequest/cancellationreasons";
export const BOOKING_UPLOAD_IMAGES =
  BASE_URL + "/api/bookingrequest/uploadimage";
export const BOOKING_UPLOAD_FILES =
  BASE_URL + "/api/bookingrequest/uploadfiles";
export const CANCEL_USER_BOOKING_REQUEST =
  BASE_URL + "/api/bookingrequest/cancelrequest";
export const EDIT_USER_BOOKING_REQUEST = BASE_URL + "/api/bookingrequest";
export const REACTIVE_EXPIRED_BOOKING_REQUESTS =
  BASE_URL + "/api/bookingrequest/";

/* Booking Requests */
export const GET_ALL_BOOKING_REQUESTS = BASE_URL + "/api/bookingrequest";
export const GET_BOOKING_REQUESTS_DETAILS = BASE_URL + "/api/bookingrequest/";
export const GET_VENDOR_QUOTES_LIST =
  BASE_URL + "/api/bookingrequest/getquotes";
export const GET_QUOTES_PRICING_DETAILS =
  BASE_URL + "/api/bookingrequest/getquotedetail";
export const ACCEPT_QUOTES = BASE_URL + "/api/bookingrequest/acceptquote";
export const DELETE_UPLOAD_FILE = BASE_URL + "/api/bookingrequest/file";
export const DELETE_UPLOAD_IMAGE = BASE_URL + "/api/bookingrequest/image";
export const GET_JOB_STATUS = BASE_URL + "/api/bookingrequest/";

export const MAKE_PAYMENT = BASE_URL + "/api/payment/create/";
export const PAYMENT_STATUS = BASE_URL + "/api/payment/status/";
export const USER_PAYMENT_STATUS = BASE_URL + "/api/payment/enabled/";



export const CREATE_QUICKBOOKING_REQUEST = BASE_URL + "/api/quickbooking/createrequest/";

/* Favourites */
export const GET_FAVOURITE_VENDORS =
  BASE_URL + "/api/users/markasfavouritegarage";
export const CHANGE_FAVOURITE_SELECTION =
  BASE_URL + "/api/users/markasfavouritegarage";

/* Notifications */
export const GET_NOTIFICATION_ALERTS = BASE_URL + "/api/notifications/alerts";
export const MARK_NOTIFICATION_AS_READ =
  BASE_URL + "/api/notifications/marknotificationasread/";

/* Rating */
export const SUBMIT_RATING_REVIEW_FOR_JOB = BASE_URL + "/api/job/review";

/* Chat */
export const CREATE_CHAT_ROOM = BASE_URL + "/api/job/chatroom/create";
export const GET_ALL_CHATROOMS = BASE_URL + "/api/job/chatrooms/getAll";
export const GET_ALL_UNREAD_MESSAGES = BASE_URL + "/api/job/messages/unread";
export const GET_ALL_MESSAGE_OF_CHAT_ROOM =
  BASE_URL + "/api/job/${roomId}/messages";

/* s3 Image upload */
export const S3_IMAGE_UPLOAD_SIGNED_REQUEST = BASE_URL + "/api/images/s3upload";
export const S3_IMAGE_UPLOAD_DONE = BASE_URL + "/api/images/s3upload/done";
export const APPROVE_PLUS_REQUEST = BASE_URL + "/api/package/request/approvePackages"
export const VERIFY_PACKAGE_COST= BASE_URL + "/api/package/"
export const GET_ALL_PACKAGES = BASE_URL + "/api/package/all";
export const CREATE_PLUS_REQUEST = BASE_URL + "/api/package/request"
export const UPDATE_SPECIAL_SERVICE = BASE_URL +"/api/package/request/specialservice"
export const GET_ALL_DATA = BASE_URL +"/api/bookingrequest/"


