import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import TextInput from '../common/TextInput';
import Button from '../common/Button';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { validationMessage, googleClientId, fbAppId } from '../../constants/staticData';
import has from 'lodash/has';
import { validateFormSubmit } from '../../helpers';
var uniqid = require('uniqid');
import ToastMessage from '../common/ToastMessage';


class Panel4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          isButtonLoading: false,
        };
        this.initialFormData = {
          'name': '',
          'email': '',
          'password': '',
          'phone': '',
          'terms': true
        };
        this.formData = {
          ...this.initialFormData
        };
        this.errors = {
          'name': false,
          'email': false,
          'password': false,
          'phone': false,
          // 'terms': false
        };
        this.onFieldBlur = this.onFieldBlur.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.tabOpen=="panel4"){
            this.setState({open:true});
        }else{
            this.setState({open:false});
        }
        if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
          this.setState({ 'isButtonLoading' : false});
        }
    }
    onFieldBlur(value, name, validateError) {
      this.formData[name] = value;
      if (has(this.errors, name)) {
        this.errors[name] = validateError;
      }
    }

    onSubmit(e) {
      e.preventDefault();
      const { router } = this.props;
      let formData = {
        ...this.formData
      };
      const validatedForm = validateFormSubmit(formData, this.errors);
      this.errors = validatedForm.errors;
      if (!validatedForm.validForm) {
        this.setState({ submissionError: true });
        return;
      } else {

        //remove walk through for guest user

        this.setState({ submissionError: false, isButtonLoading: true });
        let phone_no = this.formData.phone;
        let payload = {
          "batch_id": uniqid(),
          "guesttype": 1,
          "name": this.formData.name,
          "email": this.formData.email,
          "password": this.formData.password,
          "phone": '+971' + phone_no,
          "vehiclename": this.props && this.props.getCarProfileData["name"],
          "plate_no": this.props && this.props.getCarProfileData["plate_no"],
          "make": this.props && this.props.getCarProfileData["make"],
          "model": this.props && this.props.getCarProfileData["model"],
          "model_id": this.props && this.props.getCarProfileData["model_id"],
          "year": this.props && this.props.getCarProfileData["year"],
          "mileage": this.props && this.props.getCarProfileData["mileage"],
          "serviceid": this.props && this.props.activeRequestType == "repair" ? 3 : this.props && this.props.activeRequestType == "wash" ? 1 : 2,
          "subserviceid": this.props && this.props.activeRequestType == "repair" ? this.props && this.props.getCarRepairData : this.props && this.props.activeRequestType == "wash" ? this.props && this.props.getCarWashData : this.props.getCarServiceData,
          "details": this.props && this.props.getRequestDetails["jobDetails"],
          "latitude": this.props && this.props.getRequestDetails["latitude"],
          "longitude": this.props && this.props.getRequestDetails["longitude"],
          "location": this.props && this.props.getRequestDetails["location"],
          "userpreffereddatetime": this.props && this.props.getRequestDetails["userpreffereddatetime"],
          "flow": "Guest User Normal Sign up"
        };
        if(this.props && this.props.activeRequestType == "repair"){
          payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
          payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
        }
        this.props.actions && this.props.actions.guestUserSignUp(payload);
      }
    }
    render() {
      const origin = location.origin;
      let baseUrl = location.origin;
      const {authReducer} = this.props;
        return (
            <div className="panel4">
                <Panel collapsible expanded={this.state.open} header={
                    <div className="panel-section p-panel" onClick={()=>(this.props.selectedTab("panel4",this.props.panel3Valid))}>
                        <div className="panel-head" >
                            <span className={"n-step"}>4</span>
                            <h4>Sign Up</h4>
                            <i className={this.state.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
                        </div>
                    </div>
                } >
                    <div className="fb-gl-button row">
                        <div className="col-md-6 fb">
                          <span data-labelledby="Facebook Sign up" >
                            <FacebookLogin
                              appId={fbAppId}
                              fields="name,email,picture"
                              cssClass="btn btn-theme lg facebook blue"
                              textButton="Sign up with Facebook"
                              callback={(socialResponse) =>
                              {
                                if (socialResponse && socialResponse.accessToken)
                                {
                                  this.setState({ submissionError: false, isButtonLoading: true });
                                  let payload = {
                                    "batch_id": uniqid(),
                                    "guesttype": 3,
                                    "name": socialResponse.name,
                                    "email": socialResponse.email,
                                    "accessToken":socialResponse.accessToken,
                                    "provider": "facebook",
                                    "vehiclename": this.props && this.props.getCarProfileData["name"],
                                    "plate_no": this.props && this.props.getCarProfileData["plate_no"],
                                    "make": this.props && this.props.getCarProfileData["make"],
                                    "model": this.props && this.props.getCarProfileData["model"],
                                    "model_id": this.props && this.props.getCarProfileData["model_id"],
                                    "year": this.props && this.props.getCarProfileData["year"],
                                    "mileage": this.props && this.props.getCarProfileData["mileage"],
                                    "serviceid": this.props && this.props.activeRequestType == "repair" ? 3 : this.props && this.props.activeRequestType == "wash" ? 1 : 2,
                                    "subserviceid": this.props && this.props.activeRequestType == "repair" ? this.props && this.props.getCarRepairData : this.props && this.props.activeRequestType == "wash" ? this.props && this.props.getCarWashData : this.props.getCarServiceData,
                                    "details": this.props && this.props.getRequestDetails["jobDetails"],
                                    "latitude": this.props && this.props.getRequestDetails["latitude"],
                                    "longitude": this.props && this.props.getRequestDetails["longitude"],
                                    "location": this.props && this.props.getRequestDetails["location"],
                                    "userpreffereddatetime": this.props && this.props.getRequestDetails["userpreffereddatetime"],
                                    "flow": "Guest User Facebook Sign up"
                                  };
                                  if(this.props && this.props.activeRequestType == "repair"){
                                    payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
                                      payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
                                  }
                                  this.props.actions && this.props.actions.guestUserSignUp(payload);
                                }}}
                              icon={<i className="mdi mdi-facebook" />}/>
                          </span>

                        </div>
                        <div className="col-md-6 google">
                          <span data-labelledby="Google Sign up" >
                            <GoogleLogin
                              clientId={googleClientId}
                              className="btn btn-theme lg google"
                              onSuccess={(socialResponse) => {if (socialResponse && socialResponse.accessToken) {
                                this.setState({ submissionError: false, isButtonLoading: true });
                                let payload = {
                                  "batch_id": uniqid(),
                                  "guesttype": 3,
                                  "name": socialResponse.name,
                                  "email": socialResponse.email,
                                  "accessToken":socialResponse.accessToken,
                                  "provider": "google",
                                  "vehiclename": this.props && this.props.getCarProfileData["name"],
                                  "plate_no": this.props && this.props.getCarProfileData["plate_no"],
                                  "make": this.props && this.props.getCarProfileData["make"],
                                  "model": this.props && this.props.getCarProfileData["model"],
                                  "model_id": this.props && this.props.getCarProfileData["model_id"],
                                  "year": this.props && this.props.getCarProfileData["year"],
                                  "mileage": this.props && this.props.getCarProfileData["mileage"],
                                  "serviceid": this.props && this.props.activeRequestType == "repair" ? 3 : this.props && this.props.activeRequestType == "wash" ? 1 : 2,
                                  "subserviceid": this.props && this.props.activeRequestType == "repair" ? this.props && this.props.getCarRepairData : this.props && this.props.activeRequestType == "wash" ? this.props && this.props.getCarWashData : this.props.getCarServiceData,
                                  "details": this.props && this.props.getRequestDetails["jobDetails"],
                                  "latitude": this.props && this.props.getRequestDetails["latitude"],
                                  "longitude": this.props && this.props.getRequestDetails["longitude"],
                                  "location": this.props && this.props.getRequestDetails["location"],
                                  "userpreffereddatetime": this.props && this.props.getRequestDetails["userpreffereddatetime"],
                                  "flow": "Guest User Google Sign up"
                                };
                                if(this.props && this.props.activeRequestType == "repair"){
                                  payload["repairreason"] = this.props && this.props.getRequestDetails["repairreason"],
                                    payload["isurgentrequest"] = this.props && this.props.getRequestDetails["isurgentrequest"]
                                }
                                this.props.actions && this.props.actions.guestUserSignUp(payload);
                              }}}>
                            <i className="mdi mdi-google"/>Sign up with Google
                          </GoogleLogin>
                          </span>

                        </div>
                    </div>
                    <span className="line"> <span>OR</span> </span>
                    <div className="row">
                        <div className="col-md-6 noLeftPad">
                            <TextInput
                              label="Name"
                              id="user-name"
                              name="name"
                              type="text"
                              showValidationError={this.errors['name']}
                              emptyError={validationMessage.name.emptyError}
                              validationError={validationMessage.name.validationError}
                              onBlur={this.onFieldBlur.bind(this)} />
                            <TextInput
                              type="email"
                              id="email"
                              label="Email"
                              name="email"
                              showValidationError={this.errors['email']}
                              emptyError={validationMessage.email.emptyError}
                              validationError={validationMessage.email.validationError}
                              onBlur={this.onFieldBlur.bind(this)} />

                        </div>
                        <div className="col-md-6 noRightPad">
                          <TextInput
                            type="phone"
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            name="phone"
                            id="phone"
                            showValidationError={this.errors['phone']}
                            emptyError={validationMessage.phone.emptyError}
                            validationError={validationMessage.phone.validationError}
                            onBlur={this.onFieldBlur.bind(this)} />
                          <TextInput
                            type="password"
                            label="Password"
                            id="password"
                            name="password"
                            showValidationError={this.errors['password']}
                            emptyError={validationMessage.password.emptyError}
                            validationError={validationMessage.password.validationError}
                            onBlur={this.onFieldBlur.bind(this)} />
                        </div>
                    </div>
                    <div className="checkbox-style checkbox-wrapper n-align">
                      <input type="checkbox" id="check-box" onChange={(e) => { this.setState({ 'terms': !e.target.checked }); this.formData.terms = e.target.checked; this.errors.terms = !e.target.checked }} checked={this.formData.terms}/>
                        <label htmlFor="check-box" className="custom-checkbox"></label>
                        <label htmlFor="" className="n-signup-checkbox">
                          By signing up, you agree to the
                          <a className="blue-text" onClick={() => location.href = baseUrl + '/terms'}> Terms & Conditions</a>, and <a onClick={() => location.href = baseUrl + '/privacy'} className="blue-text">Privacy Policy</a>.
                          <p
                          className = {
                            this.errors.terms
                              ? "note-text tc-text error"
                              : "note-text tc-text hide"
                          }
                        >
                          <span className="error-text">
                            {"Please agree to the terms and condition"}
                          </span>
                        </p>
                        </label>

                    </div>
                    <div className="next-button">
                      <Button dataLabelledby="Guest User Book Service" btnType="red" btnSize="sm" fontSize={13} label="Book Service" onSumbit btnCallBack={this.onSubmit.bind(this)} isButtonLoading={this.state.isButtonLoading}/>
                    </div>

                    {/* {authReducer && authReducer.showErrorMessage && <AlertDismissable bsStyle={authReducer.statusMessage.indexOf('succesfully') !== -1  ? 'success error-back' :'danger error-back'} closeLabel="Close alert" closeAction={this.props.actions.hideErrorMessage}>
                      <p> <i className="mdi mdi-block-helper " /> {authReducer.statusMessage} </p>
                    </AlertDismissable>} */}
                </Panel>
                {authReducer.showErrorMessage && <ToastMessage actions={this.props.actions} type={'error'} title={"Error" + '...'} text={authReducer.statusMessage}/>}
            </div>
        );
    }
}

export default Panel4;
