import React, { Component } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import Gmaps from '../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../common/CustomModal';
import { Modal, FormGroup, FormControl } from 'react-bootstrap';
var uniqid = require('uniqid');
import LocationSearchBox from '../common/LocationSearchBox';
import TimePicker from 'rc-time-picker';
import PageLoader from '../common/PageLoader';
import ServiceTypeEmptyState from '../EmptyState/ServiceTypeEmptyState';
import { imageBasePath } from '../../constants/staticData';

class EditWashSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form1Active:false,
      form2Active:false,
      PrefferedLocation: 'Select Location',
      selectedDate: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      heating: false,
      step1Panel: true,
      step2Panel: false,
      isButtonLoading: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      visibleCategory: undefined,
      now: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).local(),
      dateValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).date(),
      monthValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).month(),
      yearValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).year(),
      hoursValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).hours(),
      minutesValue: moment(this.props && this.props.bookedRequest && this.props.bookedRequest.userPrefferedDatetime).minutes(),
      specialInstruction: this.props.bookedRequest && this.props.bookedRequest.details,
      selectedCarProfileId: '',
      submissionError: false,
      selectedLocation:{
        formatted_address:this.props && this.props.bookedRequest && this.props.bookedRequest.location,
        label: this.props && this.props.bookedRequest && this.props.bookedRequest.location,
        lat: this.props && this.props.bookedRequest && this.props.bookedRequest.latitude,
        lng: this.props && this.props.bookedRequest && this.props.bookedRequest.longitude,
      },
      searchValue:'',
      currentTime:'',
      selectedTimeValue:'',
    };

    this.formData = {
      selectedLocation:true,
      timeValid: this.state.hoursValue
    };
    this.errors = {
      selectedLocation: false,
      timeValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let activeRequests = nextProps && nextProps.bookingRequestDetails && nextProps.bookingRequestDetails.requeststatus && nextProps.bookingRequestDetails.requeststatus.displayname == "Active";
    if(!activeRequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile){
      this.setState({
        ...this.state,
        specialInstruction: nextProps.bookedRequest && nextProps.bookedRequest.details,
        selectedCarProfileId:nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
        selectedLocation: {
          ...this.state.selectedLocation,
          formatted_address:nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
          lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
        }
      });
    } else if(activeRequests && nextProps && nextProps.bookedRequest && nextProps.selectedCarProfile){
      this.setState({
        ...this.state,
        statusActive: true,
        step1Panel: false,
        step2Panel: true,
        specialInstruction: nextProps.bookedRequest && nextProps.bookedRequest.details,
        selectedCarProfileId:nextProps.selectedCarProfile && nextProps.selectedCarProfile.id,
        selectedLocation: {
          ...this.state.selectedLocation,
          formatted_address:nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          label: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.location,
          lat: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.latitude,
          lng: nextProps && nextProps.bookedRequest && nextProps.bookedRequest.longitude,
        }
      });
    }
    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false });
    } else if (nextProps && nextProps.showError) {
      this.setState({ isButtonLoading: false, });
    }
  }

  // createProfile(event){
  //   if(event.target.value === "createProfile"){
  //     this.props.router.push("/car-profiles/create");
  //   }
  // }

  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }

  hidePanel(panel) {
    if (panel == 'step1') {
      this.setState({ step1Panel: !this.state.step1Panel, step2Panel: false });
    } else if (panel == 'step2') {
      this.setState({ step1Panel: false, step2Panel: !this.state.step2Panel,form1Active:true,form2Active:true });
    }
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      'selectedCarCategoryForModel': categoryDetails,
      'catDescriptionModalVisible': true
    });
  }

  specialIns(data){
    if(data.target.value.length){
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
    else{
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
  }

  setLocation(location){
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  cancelEdit(){
    window.history.back();
    //this.props.router.push(`request/${20171016141948}`);
  }

  requestQuotes(){
    
    const {selectedCarWashDataArray,actions} = this.props;
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }
    if (!validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      if(this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng){
        this.setState({ submissionError: false,isButtonLoading : true, form2Active:true });
        let timeStamp = new Date(this.state.yearValue,this.state.monthValue,this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
        let momentTimeStamp = moment.utc(timeStamp).format();
        let payload;
        if(this.props.garageProfileDetails){
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "vendorid": this.props.garageProfileDetails.id,
            "subserviceid": selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        } else{
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "subserviceid": selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        }
        actions.editBookingRequest(this.props.bookingRequestId,payload);
      }
    }
  }

  timePicker(value){
    if(value && value.hours() && value.minutes()){
      if(moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue){
        if(value.startOf('minutes') < moment().add(1,'hours').startOf('minutes')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
        this.setState({
          submissionError: true,
          selectedTimeValue: value,
          hoursValue: value.hours(),
          minutesValue: value.minutes(),
        });
      } else{
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
        }
      } else{
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
          selectedTimeValue: value,
          hoursValue: value.hours(),
          minutesValue: value.minutes(),
        });
      }
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if(moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()){
      if(this.state.selectedTimeValue < moment().add(1,'hours')) {
      this.formData['timeValid'] = '';
      this.errors['timeValid'] = true,
      this.setState({
        submissionError: true,
      });
    }else{
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
      this.setState({
        submissionError: false,
      });
    }
    }else{
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
      this.setState({
        submissionError: false,
      });
    }
  }

  render() {
    console.log('🔥 🔥',this.state.step2Panel);
    console.log('🔥 🔥 🔥 🔥',categoriesSelectedCount);
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };
    const {servicesFetched,selectedCarProfile,categoriesSelectedCount,commonReducer,actions,bookedRequest,bookingRequestDetails} = this.props;
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];
    return (
      <div className="panel-section car-wash car-category-wrapper p-panel">
        <section className="collapse-panel">
          <div className="panel-head" onClick={() => {!this.state.statusActive && this.hidePanel('step1'); }}>
          <span className= {this.state.form1Active?"n-step active":"n-step"}>1</span>
            <h4> Select Car Wash Type</h4>
            <i className={this.state.step1Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {servicesFetched > 0 ? this.state.step1Panel && <div className="panel-content">
            <div className="row">
              <div className="col-md-9 pad0">
                <div className="search-box">
                  <TextInput value={this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => {
                    this.setState({
                      ...this.state,
                      searchValue:e
                    });
                    this.props.searchView(e);
                  }} />
                  <i className="mdi mdi-magnify" />
                </div>
              </div>
            </div>
            {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState/>
            }
            {this.props.viewBlock.length > 0  && <div className="next-button">
              <Button disabled = {categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm"
              fontSize={14} label="Next" btnCallBack={() => { this.hidePanel('step2'); }} />
            </div>}
          </div>:
          <PageLoader/>}
        </section>
        <section className="collapse-panel">
          <div className="panel-head" onClick ={() => { categoriesSelectedCount > 0 && this.hidePanel('step2'); }}>
          <span className= {this.state.form2Active?"n-step ":"n-step active"}>2</span>
            <h4>Create A Car Wash Request</h4>
            <i className={this.state.step2Panel ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          </div>
          {this.state.step2Panel && categoriesSelectedCount > 0 &&
            <div className="panel-content">
              <div className="row">
                <div className="col-md-12 pad0">
                  <div className="form-section">
                    <h4 className="panel-sub-title">Selected Car Profile</h4>
                    <div className="model-select">
                      <h1 className="panel-sub-title">{selectedCarProfile && selectedCarProfile.name}</h1>
                    </div>
                  </div>
                  <div className="form-section">
                    <h4 className="panel-sub-title">Prefered Time & Date</h4>
                    <div className="row date-time model-select date-time-align">
                      <div className="col-md-6 padLeft0 timeDate_grid">
                        <DatePicker
                          selected = {this.state.selectedDate}
                          onChange = {this.handleChange}
                          minDate = {moment().add(1, 'hours')}
                        />
                      </div>
                      <div className="col-md-6 padRight0 timeDate_grid form-group error">
                        {/* <TimeInput value={this.state.TimePickerFrom} onChange={(e) => {this.timePicker(e); this.setState({ TimePickerFrom: e })}} /> */}
                        <TimePicker
                          showSecond= {false}
                          className= "custom-timepicker"
                          format= {'h:mm a'}
                          onChange= {this.timePicker.bind(this)}
                          defaultValue= {this.state.now}
                          use12Hours
                        />
                        {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <h4 className="panel-sub-title">Prefered location</h4>
                    <div className="model-select form-group error">
                      <div className="location-dd header-search">
                      {/* <LocationDropdown /> */}
                        <LocationSearchBox style={style} commonReducer = {commonReducer} actions = {actions} selectedLocation = {(location) => {this.setLocation(location);}} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined}/>
                      </div>
                      {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select Prefered Location</span>}
                    </div>
                    {this.state.selectedLocation && <div className="map-panel">
                      <div className="gmaps">
                        <Gmaps
                          center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng)}}
                          zoom={12}
                          setCenter={true}
                          markers={ markerLocation }
                          containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                          mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                        />
                      </div>
                    </div>}
                  </div>
                </div>
                <div className="col-md-12 pad0">
                  <h4 className="panel-sub-title">Special Instruction</h4>
                  <p className="panel-text">
                    <FormGroup>
                      <FormControl
                        className="textAlign"
                        componentClass="textarea"
                        defaultValue={bookedRequest && bookedRequest.details}
                        onChange={(e)=>{this.specialIns(e);}}
                      />
                    </FormGroup>
                  </p>
                </div>
              </div>
              <div className="next-button clearfix editPage-button-section">
                <Button btnType="transparent" btnSize="sm" fontSize={14} label="Cancel" btnCallBack={this.cancelEdit.bind(this)}/>
                <Button btnType="red" btnSize="sm" fontSize={14} label="Save" btnCallBack={this.requestQuotes.bind(this)} isButtonLoading={this.state.isButtonLoading}/>
              </div>
            </div>}
        </section>
        <CustomModal showModal={this.state.catDescriptionModalVisible} title={this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.name} closeIcon={true} hideModal={() => { this.setState({ 'catDescriptionModalVisible': false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.description}</p>
          </Modal.Body>
        </CustomModal>
      </div>
    );
  }
}

export default EditWashSteps;
