import React, { Component } from 'react';
import Sidebar from '../common/Sidebar';
// import Footer from '../common/Footer';
import Button from '../common/Button';
import * as Scroll from 'react-scroll';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import ReactRotatingText from 'react-rotating-text';
import WOW from 'wowjs';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import take  from 'lodash/take';
import findIndex  from 'lodash/findIndex';
import has  from 'lodash/has';
import { removeParameterFromUrl, validateMobile } from '../../helpers/index';
import { validationMessage, imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { Helmet } from "react-helmet";
import { emergencyIsComingSoon } from '../../constants/staticData';
import TagManager from "react-gtm-module";
import ModalVideo from 'react-modal-video';
import Workshops from '../common/Workshops';
import Testimonials from "./Testimonials";
import CustomerTestimonials from "../common/Testimonials";
import Review from "../common/Review";
import Footer from "./Footer";
import Header from '../LandingNew/Header';

import FloatingMenu from "./FloatingMenu";

import LazyLoad from 'react-lazyload';
import {isWebpSupported} from 'react-image-webp/dist/utils';


export default class Landing extends Component {
  constructor(props) {
    super(props);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : {};
    this.searchVendors = this.searchVendors.bind(this);
    this.playPromo = this.playPromo.bind(this);

    (this.reverseScroll = false), (this.startAnimation = false), (this.divEle = 0), (this.state = {
      autoSuggestedList: props.commonReducer && props.commonReducer.autoSuggestedList
        ? props.commonReducer.autoSuggestedList
        : [],
      autoCompleteData: [],
      searchMobile: false,
      sidebarProfileDropdown: false,
      windowWidth: 0,
      searchText: '',
      searchTextHead: '',
      blinkText: true,
      blinkTextHead: true,
      navbarVisible: false,
      activeBenifitsId: 0,
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : '',
      serviceSearchTerm: '',
      serviceSearchTermHead: '',
      timelineHeight: 0,
      locateMeDD: false,
      serviceDD: false,
      value: '+91',
      scrollType: true,
      sidebarOpen: false,
      ie: false,
      appDownloadPhoneNumber: '',
      showPhoneNoError: false,
      showOfferNotification: false,
      showYoutubePromo: false,
      workshopsData: [],
      isWorkshopsLoading: true,
    });
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.scrollPage = this.scrollPage.bind(this);
    this.formData = {
      phone: ''
    };
    this.errors = {
      phone: false
    };
  }
  componentDidMount() {
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    this.props.actions.getWorkshops();
    if (authData && authData.phone && authData.phoneVerified && authData.type && authData.type == 'customer') {
      this.props.router.push(appBasePath + '/dashboard-plus');
    }
    // only for ie
    var ua = window.navigator.userAgent;

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      this.setState({ ie: true });
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    new WOW.WOW().init();
    window.addEventListener('scroll', this.scrollPage);
    this.intervalId = setInterval(this.timer.bind(this), 3500);
    this.setState({
      timelineHeight: document.getElementById('how-works').getBoundingClientRect()
    });

    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : undefined;
    if (userSelectedLocation) {
      this.props.actions.setCurrentLocation(userSelectedLocation);
    }
    if (!(this.props.commonReducer.autoSuggestedList && this.props.commonReducer.autoSuggestedList.length)) {
      this.props.actions.getAutosuggestedList();
    }


    setTimeout(
      function() {
        this.setState({showOfferNotification: true});
      }
        .bind(this),
      5000
    );




    setTimeout(
      () => {
        let scroller = Scroll.scroller;
        if (window.localStorage.getItem('MobileApp') && JSON.parse(window.localStorage.getItem('MobileApp'))) {
          scroller.scrollTo('download', {
            // delay: 100,
            smooth: true,
            offset: -100
          });
          window.localStorage.removeItem('MobileApp');
        }
      },
      1200
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList && !this.state.autoSuggestedList.length) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    this.setState({
      workshopsData: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? nextProps.commonReducer.vendorsListFromSearch : [],
      isWorkshopsLoading: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? false : true,
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.scrollPage);
    //window.Tawk_API.showWidget();
    clearInterval(this.intervalId);
    this.props.actions.hideErrorMessage();
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        locationSearchTerm: searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ input: searchTerm }, function (res) {
        that.setState({
          autoCompleteData: res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        locationSearchTerm: searchTerm,
        autoCompleteData: []
      });
    }
  }
  timer() {
    let benifitId = this.state.activeBenifitsId;
    benifitId += 1;
    this.benefitsToggleClass('none', benifitId);
  }
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }
  benefitsToggleClass(val, id) {
    const totalLength = document.querySelectorAll('.benefits-list').length;
    if (id >= totalLength) {
      id = 0;
      this.setState({ activeBenifitsId: 0 });
    } else {
      this.setState({ activeBenifitsId: id });
    }

    clearInterval(this.intervalId);
    this.intervalId = setInterval(this.timer.bind(this), 3500);

    let children = document.querySelectorAll('.benefits-description');
    for (var i = 0; i < children.length; ++i) {
      if (children[i].className.indexOf('hide') == -1) {
        children[i].className += ' hide';
      }
    }
    let selectedChild = children[id];
    selectedChild && selectedChild.classList.remove('hide');
    let selectImage = document.querySelectorAll('.benefits-list');
    for (var i = 0; i < selectImage.length; ++i) {
      if (selectImage[i].className.indexOf('active') > 0) {
        selectImage[i].classList.remove('active');
      }
    }
    if(selectImage[id] && selectImage[id].className)
      selectImage[id].className += ' active';
  }
  activeTimelineDots(index, height) {
    if (window.pageYOffset > document.getElementsByClassName('hiw-block')[0].offsetHeight + height) {
      if (!document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.add('active');
      }
    } else {
      if (document.getElementsByClassName('hiw-desc')[index].classList.contains('active')) {
        document.getElementsByClassName('hiw-desc')[index].classList.remove('active');
      }
    }
  }
  searchNav() {
    let normalHeader = document.getElementsByClassName('default-header')[0];
    let scrollHeader = document.getElementsByClassName('scroll-header')[0];
    let bannersearchHeight = document.getElementsByClassName('banner-search')[1].offsetHeight;
    if (this.state.windowWidth > 1100 && normalHeader) {
      if (window.pageYOffset > document.getElementsByClassName('banner-search')[1].offsetHeight + bannersearchHeight) {
        if (!normalHeader.classList.contains('hide')) {
          normalHeader.classList.add('hide');
          scrollHeader.classList.remove('hide');
        }
      } else {
        if (normalHeader.classList.contains('hide')) {
          scrollHeader.classList.add('hide');
          normalHeader.classList.remove('hide');
        }
      }
    } else if (normalHeader) {
      if (normalHeader.classList.contains('hide')) {
        scrollHeader.classList.add('hide');
        normalHeader.classList.remove('hide');
      }
    }
    if (this.state.sidebarOpen) {
      this.setState({ sidebarOpen: false });
    }
  }
  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { lat: Number(location.lat), lng: Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          let locationResult = {};
          if (results[0].formatted_address && results[0].formatted_address.indexOf('Unnamed Road') > -1) {
            locationResult = results[1];
          } else {
            locationResult = results[0];
          }
          this.setState({
            userLocation: locationResult,
            locationSearchTerm: searchTerm || location.description || locationResult.formatted_address,
            showLocationDropdown: false
          });
          that.props.actions.setCurrentLocation({
            lat: locationResult.geometry.location.lat(),
            lng: locationResult.geometry.location.lng(),
            formatted_address: locationResult.formatted_address,
            label: searchTerm || location.description || locationResult.formatted_address
          });

        } else {
          this.setState({ showLocationDropdown: false });
        }
      });
    }
  }
  scrollPage() {
    // call benefits function on section active
    if (
      this.state.scrollType &&
      window.pageYOffset > document.getElementById('benefits-section').offsetTop - window.innerHeight - 150
    ) {
      this.setState({ activeBenifitsId: -1, scrollType: false });
      this.startAnimation = true;
    }

    // check browser
    if (this.state.ie) {
      if (!this.reverseScroll) {
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 100) {
          if (document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
            document.getElementsByClassName('line-block')[0].classList.remove('hide');
          }
        }

        if (
          window.scrollY >
          document.getElementById('benefits-section').offsetTop -
          document.getElementById('benefits-section').offsetHeight ||
          window.scrollY < document.getElementById('how-works').offsetHeight + 150
        ) {
          document.getElementsByClassName('line-block')[0].classList.add('hide');
        }

        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 1080) {
          this.reverseScroll = true;
        }

        this.activeTimelineDots(0, 521);
        this.activeTimelineDots(1, 837);
        this.activeTimelineDots(2, 1147);
      }

      this.searchNav();
    } else {
      this.searchNav();

      if (!this.reverseScroll) {
        this.activeTimelineDots(0, 521);
        this.activeTimelineDots(1, 837);
        this.activeTimelineDots(2, 1147);

        // console.log(document.getElementById('how-works')>window.pageYOffset)
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 58) {
          if (document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
            document.getElementsByClassName('line-block')[0].classList.remove('hide');
          }
        }
        // if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 823) {
        //   if (!document.getElementsByClassName('line-block')[0].classList.contains('hide')) {
        //     document.getElementsByClassName('line-block')[0].classList += ' hide';
        //   }
        // }
        if (
          window.scrollY >
          document.getElementById('benefits-section').offsetTop -
          document.getElementById('benefits-section').offsetHeight ||
          window.scrollY < document.getElementById('how-works').offsetHeight + 150
        ) {
          document.getElementsByClassName('line-block')[0].classList.add('hide');
        }
        if (window.pageYOffset > document.getElementById('how-works').offsetHeight + 1080) {
          this.reverseScroll = true;
        }
      }
    }
  }
  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation')
      ? JSON.parse(localStorage.getItem('userSelectedLocation'))
      : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng };
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == 'keyword') {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, 'distance');
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == 'autosuggest') {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, 'distance');
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, 'distance');
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }

  playPromo(){
    this.setState({ showYoutubePromo: true });
  }

  handleConvertedImage(url) {
    console.log("urlurlurl",url);
  }

  render() {
    const tagManagerArgs = {
      dataLayer: {
        pageCategory: 'Homepage',
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


    const origin = location.origin;
    let baseUrl = location.origin;

    let searchView = filter(this.state.autoSuggestedList, val => {
      if (this.state.searchText != '' && val.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
        return val;
      }
    });
    searchView = take(searchView, 5);
    const searchAutoSuggestedList = map(searchView, (val, key) => {
      return (
        <MenuItem
          onClick={() => {
            // this.searchVendors(val, 'autosuggest');
            this.setState({ searchText: val.name });
          }}
          eventKey={key}
          key={key}
        >
          {val.name}
        </MenuItem>
      );
    });

    let searchViewHead = filter(this.state.autoSuggestedList, val => {
      if (this.state.searchTextHead != '' && val.name.toLowerCase().indexOf(this.state.searchTextHead.toLowerCase()) != -1) {
        return val;
      }
    });
    searchViewHead = take(searchViewHead, 5);
    const searchAutoSuggestedListHead = map(searchViewHead, (val, key) => {
      return (
        <MenuItem
          onClick={() => {
            // this.searchVendors(val, 'autosuggest');
            this.setState({ searchTextHead: val.name });
          }}
          eventKey={key}
          key={key}
        >
          {val.name}
        </MenuItem>
      );
    });


    let locationData = [{ textPlaceholder: 'Current Location' }];
    map(this.state.autoCompleteData, (location, key) => {
      return locationData.push({ key: key, description: location.description, place_id: location.place_id });
    });
    map(this.props.commonReducer && this.props.commonReducer.userLocations, (location, key) => {
      return locationData.push({
        key: key,
        lat: location.lat,
        lng: location.lng,
        label: location.label,
        id: location.id,
        formatted_address: location.location
      });
    });
    const locationAutosuggestedList = map(locationData, (val, key) => {
      return (
        <MenuItem
          onClick={item => {
            if (item.target.text != 'Current Location') {
              this.setUserLocation(val, item.target.text);
            } else {
              if (navigator.geolocation) {
                const that = this;
                navigator.geolocation.getCurrentPosition(function (position) {
                  that.setUserLocation({ 'lat': position.coords.latitude, 'lng': position.coords.longitude });
                }, function (error) {
                  switch (error.code) {
                    case error.PERMISSION_DENIED:
                      that.props.actions.showToastMessage('Please grant permission to use location service', 'error');
                      break;
                    case error.POSITION_UNAVAILABLE:
                      that.props.actions.showToastMessage('Location information is unavailable.', 'error');
                      break;
                    case error.TIMEOUT:
                      that.props.actions.showToastMessage('The request to get user location timed out.', 'error');
                      break;
                    case error.UNKNOWN_ERROR:
                      that.props.actions.showToastMessage('An unknown error occurred.', 'error');
                      break;
                  }
                }, { enableHighAccuracy: false, maximumAge: Infinity, timeout: 60000 });
              } else {
                this.props.actions.showToastMessage('Geo location feature is not available in your browser', 'error');
              }
            }
          }}
          eventKey={key}
          key={key}
        >
          {val.description ? val.description : val.label ? val.label : val.textPlaceholder}
        </MenuItem>
      );
    });
    const authData = localStorage.authData ? JSON.parse(localStorage.authData) : '';
    return (

      <div>
      <FloatingMenu />

        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData['landing'] && metaTagData['landing'].title ? metaTagData['landing'].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData['landing'] && metaTagData['landing'].description ? metaTagData['landing'].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet>
        {/* Quick Call Modal */}
        <div id="landing" className={`landing-wrapper ${this.state.enableOpacity}`} ref="landing">
          {this.props.bookingRequestReducer.toastMessage &&
            this.props.bookingRequestReducer.toastType &&
            <ToastMessage
              actions={this.props.actions}
              type={this.props.bookingRequestReducer.toastType}
              title={this.props.bookingRequestReducer.toastType + '...'}
              text={this.props.bookingRequestReducer.toastMessage}
            />}
        <div className={this.state.showOfferNotification ? "notification-alert  show-notification" : "notification-alert alert alert-success"} role="alert">
          <div className="content">

{/*
            <div className="off-percent">We have just partnered with Mobil 1 to improve customer experience for automotive repair and services.</div>
*/}

{/*
            <span className="off-percent">45% OFF</span> on Steam car wash *Limited period offer. Use Code: <span className="off-percent">45OFF</span>*
*/}

            <span className="off-percent">Exclusive Car Service, Repair and Wash deals for you.</span>


            <span className="book-now" onClick={() => this.props.router.push(`${appBasePath}/all-offers`) } >BOOK NOW</span>


          </div>
          <div className="  close" onClick={() => this.setState({ showOfferNotification: false })}>
            <span aria-hidden="true">&times;</span>
          </div>
        </div>
        <Header/>
        <nav className={this.state.showOfferNotification ? "navbar navbar-default default-header default-show-header-top" : "navbar navbar-default default-header default-header-top"} id="header-section1">


          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
                onClick={() => {
                  this.setState({ navbarVisible: !this.state.navbarVisible });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand desk-logo" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>
              <a className="navbar-brand desk-logo mobil-logo" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/mobil-logo.png`} alt="Mobil logo" />
              </a>
              {/*<a className="navbar-brand mobile-logo" href="https://www.carcility.com">
                <img src="/images/logo.svg" />
              </a>*/}
            </div>
            {/*Search mobile for mobile */}
            <span className="search-mobile sm-mob" onClick={() => this.setState({ searchMobile: true })}>
              <i className="mdi mdi-magnify" aria-hidden="true" />
            </span>
            <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
              <ul className="nav navbar-nav navbar-right">
                <li className="search-mobile md-mob" onClick={() => this.setState({ searchMobile: true })}>
                  <i className="mdi mdi-magnify" aria-hidden="true" />
                </li>

                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/all-offers');
                    }}
                  >
                    Offers{' '}
                  </a>
                  <label />
                </li>
                <li className="work-active">

                  <Link to="how-works" smooth={true} offset={-100} duration={600}>
                    how it works
                  </Link>
                  <label />
                </li>
                <li>
                  <a href="/business ">
                    For Business
                  </a>
                  <label />
                </li>
                <li>
                <a href="/blog">
                  BLOG
                </a>
                <label />
              </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/sign-in');
                    }}
                  >
                    Log In{' '}
                  </a>
                  <label />
                </li>
                <li>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.props.router.push(appBasePath + '/register');
                    }}
                  >
                    {' '}Sign Up
                  </a>
                  <label />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-default scroll-header hide" id="header-section2">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
                onClick={() => {
                  this.setState({ navbarVisible: !this.state.navbarVisible });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href={baseUrl}>
                <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
              </a>
            </div>
            <div id="navbar" className={this.state.navbarVisible ? 'navbar-collapse' : 'navbar-collapse collapse'}>
              <ul className="nav navbar-nav nav-left">
                <li>
                  <div className="banner-search">
                    <div className="search-wrapper">
                      <form className="form-inline">

                        <DropdownButton
                          bsSize="small"
                          id="dropdown-size-small"
                          noCaret
                          onToggle={e => this.setState({ locateMeDD: e })}
                          onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                          className="location-search"
                          title={
                            <span className="search-left">
                              <i className="mdi mdi-crosshairs-gps" />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Locate Me"
                                value={this.state.locationSearchTerm}
                                onChange={e => {
                                  e.preventDefault();
                                  this.autocompleteLocation(e);
                                  this.setState({ locationSearchTerm: e.target.value });
                                }}
                              />
                              <i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                            </span>
                          }
                        >
                          {locationAutosuggestedList}
                        </DropdownButton>

                        <DropdownButton
                          bsSize="small"
                          id="dropdown-size-small"
                          noCaret
                          onToggle={e => this.setState({ serviceDD: e })}
                          open={this.state.searchTextHead == '' ? false : this.state.serviceDD}
                          onChange={e => {
                            this.setState({ searchTextHead: e.target.value });
                          }}
                          onSelect={(id, e) => {
                            this.setState({ serviceSearchTermHead: e.target.innerText });
                          }}
                          className="location-search search-service-block"
                          title={
                            <span
                              className="search-right"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({ blinkTextHead: false });
                              }}
                            >
                              <input
                                type="text"
                                ref={focusFunctionHead => this.inputFocusTopHead = focusFunctionHead}
                                className="form-control"
                                placeholder=""
                                onFocus={(e) => e.target.placeholder = "Search for services, service providers.."}
                                onBlur={(e) => e.target.placeholder = "Search for services, service providers.."}
                                onChange={e => {
                                  e.preventDefault;
                                  const serviceSearchTermHead = e.target.value;
                                  this.setState({ serviceSearchTermHead: serviceSearchTermHead });
                                }}
                                value={this.state.serviceSearchTermHead}
                              />
                              <input
                                className={'hide'}
                                type="submit"
                                onClick={e => {
                                  e.preventDefault();
                                  this.searchVendors(this.state.searchTextHead, 'keyword');
                                }}
                              />
                              <span
                                onClick={e => {
                                  e.preventDefault;
                                  this.inputFocusTopHead.focus();
                                }}
                                className={this.state.blinkTextHead ? 'blink-text' : 'blink-text hide'}
                              >
                                <ReactRotatingText
                                  items={[
                                    'Find a quality service provider near you.'
                                  ]}
                                />
                              </span>
                              <i
                                className="mdi mdi-magnify"
                                onClick={() => {
                                  this.searchVendors(this.state.searchTextHead, 'keyword');
                                }}
                              />
                            </span>
                          }
                        >
                          {searchAutoSuggestedListHead}
                        </DropdownButton>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <Button
                    dataLabelledby="Get Started"
                    isSubmitBtn={true}
                    btnCallBack={() => {
                      this.props.router.push(appBasePath + '/guest-user');
                    }}
                    btnType="red"
                    btnSize="sm"
                    fontSize={13}
                    label="Get Started"
                  />
                </li>
                <li
                  className="menu-option"
                  onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen, sidebarProfileDropdown: false })}
                >
                  <i className="mdi mdi-menu" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* banner section */}
        <div id="banner-section" className={this.state.showOfferNotification ? "default-show-banner-margin-top" : "default-banner-margin-top"} >
          <div className="banner-img">
            {this.state.windowWidth > 812 ?

              isWebpSupported() ?
                <img src={`${imageBasePath}/images/car-service-dubai-carcility.webp`}  alt="car service dubai - carcility" /> : <img src={`${imageBasePath}/images/car-service-dubai-carcility.jpg`}  alt="car service dubai - carcility"/>
              :
              isWebpSupported() ?
                <img src={`${imageBasePath}/images/landing/car-service-dubai-carcility-responsive.webp`}  alt="car service dubai - carcility" /> : <img src={`${imageBasePath}/images/landing/car-service-dubai-carcility-responsive.png`}  alt="car service dubai - carcility" />
            }
          </div>
          <div className="banner-search">
            <h2>Credible service. Incredible care.</h2>
            <label>Choose from a variety of qualified, reliable car service providers for your auto repair and maintenance</label>
            <div className="search-wrapper">
              <form className="form-inline">
                <DropdownButton
                  bsSize="small"
                  id="dropdown-size-small"
                  noCaret
                  onToggle={e => this.setState({ locateMeDD: e })}
                  onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                  className="location-search"
                  title={
                    <span className="search-left">
                      <i className="mdi mdi-crosshairs-gps" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Locate Me"
                        value={this.state.locationSearchTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autocompleteLocation(e);
                          this.setState({ locationSearchTerm: e.target.value });
                        }}
                      />
                      <i className={this.state.locateMeDD ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                    </span>
                  }
                >
                  {locationAutosuggestedList}
                </DropdownButton>
                <DropdownButton
                  bsSize="small"
                  id="dropdown-size-small"
                  noCaret
                  onToggle={e => this.setState({ serviceDD: e })}
                  open={this.state.searchText == '' ? false : this.state.serviceDD}
                  onChange={e => this.setState({ searchText: e.target.value })}
                  onSelect={(id, e) => {
                    this.setState({ serviceSearchTerm: e.target.innerText });
                  }}
                  className="location-search search-service-block"
                  title={
                    <span className="search-right" onClick={() => this.setState({ blinkText: false })}>
                      <input
                        type="text"
                        ref={focusFunction => this.inputFocus = focusFunction}
                        className="form-control"
                        placeholder=""
                        onFocus={(e) => e.target.placeholder = this.state.windowWidth > 460 ? "E.g. Battery Services, Brake Repairs …": "E.g. Battery Services.."}
                        onBlur={(e) => e.target.placeholder = this.state.windowWidth > 460 ? "E.g. Battery Services, Brake Repairs …" : "E.g. Battery Services.."}
                        onChange={e => {
                          e.preventDefault;
                          const serviceSearchTerm = e.target.value;
                          this.setState({ serviceSearchTerm: serviceSearchTerm });
                        }}
                        value={this.state.serviceSearchTerm}
                      />
                      <span
                        onClick={e => {
                          e.preventDefault;
                          this.inputFocus.focus();
                        }}
                        className={this.state.blinkText ? 'blink-text' : 'blink-text hide'}
                      >
                        {this.state.windowWidth > 768 &&
                        <ReactRotatingText
                          items={[
                            'What service do you need today?',
                            'Battery Services, Brake Services, Engine ...etc'
                          ]}
                        />}
                        {(this.state.windowWidth <= 768 && this.state.windowWidth > 460) &&
                        <ReactRotatingText
                          items={['What service do you need today?', 'Battery Services, Brake Services ...etc']}
                        />}
                        {(this.state.windowWidth <= 460)&&
                        <ReactRotatingText
                          items={['What service do you need?', 'Battery Services...etc']}
                         // color="#BFC4C9"
                        />}
                      </span>
                      {/* <i className="mdi mdi-magnify" /> */}
                    </span>
                  }
                >
                  {searchAutoSuggestedList}
                </DropdownButton>
                <button
                  data-labelledby="Get Started"
                  onClick={e => {
                    e.preventDefault();
                    // this.searchVendors(this.state.searchText, 'keyword');
                    this.props.router.push(appBasePath + '/guest-user');
                  }}
                  className="btn btn-default green-btn button"
                >
                  Get Started
                </button>
              </form>
            </div>
          </div>
          {this.state.windowWidth > 830 &&
          <div className="youtube-promo " onClick={e => {
            e.preventDefault();
            this.setState({ showYoutubePromo: true })

          }}>
            <img src="/images/landing/play-video.svg" alt="app store" />
            <span>Know Carcility</span>

          </div>
        }
        </div>

        <ModalVideo channel='youtube' isOpen={this.state.showYoutubePromo} videoId='h736TiMyfrs' onClose={() => this.setState({ showYoutubePromo: false })} />



        {/* advantage section */}
        <div className="advantage-section">


          <div className="app-download-section">
            <LazyLoad>
              <div className="download-desc download-desc-app">

                <div className="col-md-4 col-sm-12 col-xs-12 col-md-offset-2">
                  <img src="/images/landing/phone_white.png" className="phone-icon " alt="download the app" />
                  <span className="download-desc-app-text">Download the app!</span>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <ul className="list-unstyled">



                    <li><a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                      <img src="/images/landing/app_store_white.png" alt="app store" /></a></li>
                    <li><a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                      <img src="/images/landing/play_store_white.png" alt="play store" /></a></li>
                  </ul>
                </div>

              </div>
            </LazyLoad>
          </div>



          <div className="container">
              <div className="col-md-4 col-sm-12 col-xs-12 text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder al-image-holder-align">
                    <img src={`${imageBasePath}/images/book-car-service-in-dubai.svg`} alt="book car service in dubai" />
                  </div>
                  <h5 className="green-color">Easy to use</h5>
                  <p>Book your car service with a few clicks.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder al-image-holder-align">
                    <img src={`${imageBasePath}/images/instant-car-service-booking.svg`} alt="instant car service booking" />
                  </div>
                  <h5 className="orange-color"> Instant quotes </h5>
                  <p>Receive and compare instant quotes from different service providers.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12  text-center wow slideInUp">
                <div className="advantage-list">
                  <div className="al-image-holder">
                    <img src={`${imageBasePath}/images/trusted-car-booking-services.svg`} alt="trusted car booking services" />
                  </div>
                  <h5 className="blue-color"> Trusted service </h5>
                  <p>
                    {' '}
                    Extensively vetted service providers, for a hassle-free experience.
                    {' '}
                  </p>
                </div>
              </div>
          </div>
        </div>

          {/* service section */}
          <Element name="overview" className="element">
            <div className="common-wrapper" id="service-section">
              <LazyLoad>
                <div className="container">
                  <div className="common-heading wow slideInUp">
                    <h2>What service do you need?</h2>
                    <span>Finding the best car service is no hassle.</span>
                    <p>
                      From a plethora of car service providers, choose convenient, efficient, affordable service and maintenance within a click. Experience the best car care with Carcility, one-stop destination for all your car service and auto repair needs in Dubai, Abudhabi and Sharjah.
                    </p>
                  </div>

                  <div className="row our-service">
                    <div className="col-md-3 col-xs-6 col-sm-6 wow slideInUp wow-left">
                      <div
                        className="service-img"
                        onClick={e => {
                          e.preventDefault();
                          if (authData && authData.type == 'customer') {
                            this.props.router.push(appBasePath + '/car-repair');
                          } else {
                            this.props.router.push(appBasePath + '/car-repair-in-dubai');
                          }
                        }}
                      >
                        <img src={imageBasePath + '/images/landing/car-repair-service-in-dubai.png'} alt="car repair service in dubai" />
                        <h2>CAR REPAIR</h2>
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-6 col-sm-6 wow slideInUp wow-right">
                      <div
                        className="service-img"
                        onClick={e => {
                          e.preventDefault();
                          if (authData && authData.type == 'customer') {
                            this.props.router.push(appBasePath + '/car-service');
                          } else {
                            this.props.router.push(appBasePath + '/car-service-in-dubai');
                          }
                        }}
                      >
                        <img src={`${imageBasePath}/images/landing/car-service-in-dubai.png`} alt="car service in dubai" />
                        <h2>CAR SERVICE</h2>
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-6 col-sm-6 wow slideInUp wow-left">
                      <div
                        className="service-img"
                        onClick={e => {
                          e.preventDefault();
                          if (authData && authData.type == 'customer') {
                            this.props.router.push(appBasePath + '/car-wash');
                          } else {
                            this.props.router.push(appBasePath + '/car-wash-in-dubai');
                          }
                        }}
                      >
                        <img src={imageBasePath + '/images/landing/car-wash-service-in-dubai.png'} alt="car wash service in dubai" />
                        <h2>CAR WASH</h2>
                      </div>
                    </div>

                    <div className="col-md-3 col-xs-6 col-sm-6 wow slideInUp wow-right">
                      <div
                        className="service-img"
                        onClick={e => {
                          emergencyIsComingSoon ? e.preventDefault() : this.props.router.push(appBasePath + '/emergency');
                        }}
                      >
                        <img src={`${imageBasePath}/images/landing/car-roadside-assistance-service-in-dubai.jpg`} alt="car roadside assistance service in dubai" />
                        <h2>ROADSIDE ASSISTANCE</h2>
                      </div>
                    </div>
                  </div>
                  <div className="highlight-text wow slideInUp">
                    <label>Get a Quote</label>
                  </div>
                </div>
              </LazyLoad>
              <div
                className="bottom-line"
                style={{ borderRight: this.state.windowWidth + 'px solid rgb(255, 255, 255)' }}
              />
            </div>

          </Element>
          {/* how it works */}



          <Element name="how-works" className="element">
            <div id="how-works">
              <div className="common-heading text-center wow slideInUp">
                <h2>How it Works</h2>
                <p className="sub-text">
                  An easy step by step way of getting your car repair done. Select the best car service center online, get quotes and book an appointment as per your time.</p>
              </div>
              <section id="hiw-timeline" className="container">
                <LazyLoad>
                  <div className="hiw-block">
                    <div className="row">
                      <div className="hiw-row active">
                        <div className="col-md-6 col-sm-12 hiw-left ">
                          <div className="hiw-img wow slideInLeft">
                            <img src={`${imageBasePath}/images/landing/choose-car-service.png`} alt="choose car service" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 hiw-right">
                          <div className="hiw-desc">
                            <h5>Choose your service</h5>
                            <p>Choose the right model and make of your car and the kind of service it needs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="hiw-row active">
                        <div className="col-md-6 col-sm-12 hiw-left ">
                          <div className="hiw-img wow slideInLeft">
                            <img src={`${imageBasePath}/images/landing/view-car-service-quotes.png`} alt="view car service quotes" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 hiw-right">
                          <div className="hiw-desc">
                            <h5>Receive your quotes</h5>
                            <p>
                              Sit back and watch the quotes for your request start rolling in.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="hiw-row active">
                        <div className="col-md-6 col-sm-12 hiw-left ">
                          <div className="hiw-img wow slideInLeft">
                            <img src={`${imageBasePath}/images/landing/select-quote-and-book-car-service.png`} alt="select quote and book car service" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 hiw-right">
                          <div className="hiw-desc active">
                            <h5>Get your job done</h5>
                            <p>
                              Compare quotes and book a service provider that suits your time, price or rating preference.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </LazyLoad>
                <div className="line-block hide">
                  <label />
                </div>
              </section>
            </div>
          </Element>


          {/* workshops */}
          <Element name="overview" className="element">
            <div className="common-wrapper" id="workshop-section">
              <div
                className="bottom-line"
                style={{ borderRight: this.state.windowWidth + 'px solid rgb(255, 255, 255)' }}
              />
              <LazyLoad>
                <div className="workshop-container container">
                  <div className="workshop-heading wow slideInUp">
                    <h2>Find experts near you</h2>
                    <p className="sub-text">Locate the nearest car service centers and get your car repair done with expert mechanics.</p>
                  </div>
                  {/* <div className="padwrapper"> */}
                    {!this.state.isWorkshopsLoading && this.state.workshopsData && this.state.workshopsData.length > 0 &&
                    <Workshops
                      title="Explore Workshops"
                      workshopsData={this.state.workshopsData}
                      router={this.props.router}
                      isLanding={true}
                    />
                    }

                  {/* </div> */}
                  <div className="highlight-text-workshop highlight-text wow slideInUp">
                    <label>Workshops</label>
                  </div>
                </div>
              </LazyLoad>
            </div>
          </Element>

          {/* benefits section */}
          <div id="benefits-section" className="mob-hide">
            <div className="round-position" />
            <div className="container">
              <div className="common-heading text-center wow slideInUp">
                <h2>Why people love using Carcility </h2>
                <p className="sub-text">We aim to bring the best service possible and take the burden off our customers when it comes to auto repair and maintainance.</p>
              </div>
              <LazyLoad>
                <div className="benefits-c">
                  <div className="benfits-roller">
                    <div
                      className="benefits-list benefits-one active"
                      onClick={() => {
                        this.benefitsToggleClass('benefits-one', 0);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/easy.svg`} alt="Car repair service made easy" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/easy-active.svg`} alt="easy active" className="b-active" />
                      </div>
                      {/*<span>Easy</span>*/}
                    </div>
                    <div
                      className="benefits-list benefits-two"
                      onClick={() => {
                        this.benefitsToggleClass('peaceful', 1);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/peaceful.svg`} alt="peaceful" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/peaceful-active.svg`} alt="Quality car service and repair" className="b-active" />
                      </div>
                      {/*<span>Peaceful</span>*/}
                    </div>
                    <div
                      className="benefits-list benefits-three"
                      onClick={() => {
                        this.benefitsToggleClass('instant', 2);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/instant.svg`} alt="instant advantage" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/instant-active.svg`} className="b-active" alt="Auto service with transparency" />
                      </div>
                      {/*<span>Instant</span>*/}
                    </div>
                    <div
                      className="benefits-list benefits-four"
                      onClick={() => {
                        this.benefitsToggleClass('maintanence', 3);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/maintain.svg`} alt="Car Maintenance" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/maintain-active.svg`} className="b-active" />
                      </div>
                      {/*<span>Maintanence</span>*/}
                    </div>
                    <div
                      className="benefits-list benefits-five"
                      onClick={() => {
                        this.benefitsToggleClass('manage', 4);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/manage.svg`} alt="Manage with car service app" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/manage-active.svg`} alt="manage" className="b-active" />
                      </div>
                      {/*<span>Manage</span>*/}
                    </div>
                    <div
                      className="benefits-list benefits-six"
                      onClick={() => {
                        this.benefitsToggleClass('secure', 5);
                      }}
                    >
                      <div className="benefit-img">
                        <img src={`${imageBasePath}/images/landing/secure.svg`} alt="Secured Auto service" className="b-inactive" />
                        <img src={`${imageBasePath}/images/landing/secure-active.svg`} className="b-active" />
                      </div>
                      {/*<span>Secure</span>*/}
                    </div>
                  </div>
                  <div className="benefits-wrapper text-center">
                    {this.startAnimation &&
                    <div className="benefits">
                      <div className="benefits-description benefits-easy">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/easy.gif`} />
                          </div>
                          <h5>EASY TO USE</h5>
                          <p>Our site and mobile apps make it easy to book anytime, anywhere.</p>
                        </div>
                      </div>
                      <div className="benefits-description hide benefits-peaceful">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/peaceful.gif`} />
                          </div>
                          <h5>PEACE OF MIND <br /> GUARANTEED</h5>
                          <p>Quality service, no unexpected charges, and customer support.</p>
                        </div>
                      </div>
                      <div className="benefits-description hide benefits-instant">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/instant.gif`} />
                          </div>
                          <h5>INSTANT, FAIR AND <br />TRANSPARENT QUOTES</h5>
                          <p>Receive and compare instant quotes from different shops.</p>
                        </div>
                      </div>
                      <div className="benefits-description hide benefits-maintain">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/maintain.gif`} />
                          </div>
                          <h5>MAINTAINANCE TRACKER</h5>
                          <p>Service is tracked, and you'll receive reminders for future service.</p>
                        </div>
                      </div>
                      <div className="benefits-description hide benefits-manage">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/manage.gif`} />
                          </div>
                          <h5>MANAGE YOUR CARS <br />ONLINE</h5>
                          <p>
                            Store your car and insurance details, 24/7 access to service records and no more paper work!
                          </p>
                        </div>
                      </div>
                      <div className="benefits-description hide benefits-secure">
                        <div className="b-desc">
                          <div className="b-desc-img">
                            <img src={`${imageBasePath}/images/landing/secure.gif`} />
                          </div>
                          <h5>SECURE PAYMENT</h5>
                          <p>We hold payment until service is complete and you're satisfied.</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </LazyLoad>
              {/*<div className="bottom-line" style={{ borderRight: this.state.windowWidth + "px solid rgb(255, 255, 255)" }}></div>*/}
              <div className="highlight-text wow slideInUp">
                <label>Benefits</label>
              </div>
            </div>
          </div>

          <LazyLoad>
            <Testimonials/>
          </LazyLoad>

          {/* Download section */}
          <Element name="download" className="element">
            <div id="download-section">
              <LazyLoad>
                <div className="container">
                  <div className="row">
                    <div className="col-md-offset-5 col-md-7 col-sm-12 col-xs-12 margin-align">
                      <div className="download-desc">
                        <div className="wow slideInDown">
                          <h5>Give your car the best care. Anytime, anywhere.</h5>
                          <p>
                            Download the Carcility app, so that you can access, compare and book services from our trusted network of service providers with confidence whenever, wherever.
                          </p>
                        </div>
                        <div className="wow slideInUp">
                          <label>Download the app!</label>
                          <div className="download-form">
                            <form className="form-inline">

                              <div className="input-group">
                                <span className="countryCode">+971</span>
                                <input
                                  placeholder="Enter Phone Number"
                                  type="number"
                                  className="form-control"
                                  onChange={e => {
                                    this.setState({ appDownloadPhoneNumber: e.target.value, showPhoneNoError: false });
                                  }}
                                  value={this.state.appDownloadPhoneNumber}
                                  onBlur={e => {
                                    this.setState({ showPhoneNoError: !validateMobile(e.target.value) });
                                  }}
                                />
                                {this.state.appDownloadPhoneNumber &&
                                this.state.showPhoneNoError &&
                                !validateMobile(this.state.appDownloadPhoneNumber) &&
                                <span className="error-text">{validationMessage.phone.validationError}</span>}
                                {/* <input type="text" className="form-control" placeholder="Enter Phone Number" /> */}
                                {/*<Phone
                                                      country="IN"
                                                      placeholder="Enter phone number"
                                                      value={ this.state.value }
                                                      onChange={ value => this.setState({ value }) }/>*/}
                                <span className="input-group-btn" data-labelledby="Text App Link111">
                                  <button
                                    data-labelledby="Text App Link"
                                    className="btn btn-default"
                                    type="button"
                                    onClick={e => {
                                      e.preventDefault();
                                      if (!this.state.showPhoneNoError && this.state.appDownloadPhoneNumber) {
                                        this.props.actions.getAppDownloadLink('+971' + this.state.appDownloadPhoneNumber);
                                      }
                                    }}
                                  >
                                    Text App Link
                                  </button>
                                </span>
                              </div>
                            </form>
                          </div>
                          <ul className="list-unstyled">
                            <li>
                              <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                                <img src={`${imageBasePath}/images/landing/car-service-app-play-store.png`} alt="car service app - play store" />
                              </a>
                            </li>
                            <li>
                              <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                                <img src={`${imageBasePath}/images/landing/car-service-app-app-store.png`} alt="car service app - app store" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mob-img">
                    <img src={`${imageBasePath}/images/landing/car-service-booking-app-dubai.png`} alt="car service booking app dubai" />
                  </div>
                  <div className="highlight-text wow slideInUp">
                    <label>Download</label>
                  </div>
                </div>
              </LazyLoad>
              <div
                className="bottom-line"
                style={{ borderRight: this.state.windowWidth + 'px solid rgb(255, 255, 255)' }}
              />
            </div>
          </Element>


            {/* testimonials */}
            <Element name="overview" className="element">
              <div className="common-wrapper" id="testimonial-section">
                <div
                  className="bottom-line"
                  style={{ borderRight: this.state.windowWidth + 'px solid rgb(255, 255, 255)' }}
                />
                <div className="workshop-container container">
                  <div className="workshop-heading wow slideInUp">
                    <h2>Hear from our Customers</h2>
                    <p className="sub-text">We believe in building trust and our happy customers show gratitude for the kind of car service and maintenance given to their vehicles.</p>

                  </div>
                  {/* <div className="padwrapper"> */}

                  <div className="row ">



                    <LazyLoad>
                      <CustomerTestimonials
                      />
                    </LazyLoad>


                    </div>


                  {/* </div> */}
                  <div className="highlight-text-workshop highlight-text wow slideInUp">
                    <label>Testimonials</label>
                  </div>


                </div>
              </div>
            </Element>


            <Element name="overview" className="element">
              <div className="common-wrapper empty-section" id="workshop-section">
                <div
                  className="bottom-line"
                  style={{ borderRight: this.state.windowWidth + 'px solid rgb(255, 255, 255)' }}
                />

              </div>
            </Element>



            <div id="professional-section">

              <LazyLoad>
                <div className="container">
                  <div className="awards-recognition">Awards & Recognitions</div>
                  <div className="awards-bunch">
                    <div className="awards-individual">
                      <div className="awards-body">
                        <img src={`${imageBasePath}/images/landing/award-ge.svg`} alt="Gitex" />
                        <div className="awards-titles">Gitex supernova youth startup semi-finalist</div>
                        <div className="awards-year">2018</div>
                      </div>
                    </div>
                    <div className="awards-individual">
                     <div className="awards-body">
                       <img className="smartpreneur" src={`${imageBasePath}/images/landing/award-dubai.svg`} alt="smartpreneur" />
                       <div className="awards-titles">Top 50 in Smartpreneur</div>
                       <div className="awards-year">2019</div>
                     </div>
                    </div>
                    <div className="awards-individual">
                      <div className="awards-body">
                        <img src={`${imageBasePath}/images/landing/award-alt.svg`} alt="Aim" />
                        <div className="awards-titles">Shortlisted for AIM startup pitch challenge</div>
                        <div className="awards-year">2019</div>
                      </div>
                    </div>
                  </div>
                </div>
              </LazyLoad>
              <LazyLoad>
                <div className="Featured-awards">
                  <div className="as-featured-in">As Featured In</div>
                  <div className="feature-blocks">
                    <a href="https://www.khaleejtimes.com/auto-startup-aims-to-ease-consumer-woes" target="_blank">
                      <img className="khaleej" src={`${imageBasePath}/images/landing/w-award-khaleej.png`} alt="khaleej" />
                    </a>
                    <a href="https://cofounder.ae/carcility-find-the-best-car-service-from-car-wash-to-car-repair-at-this-one-stop-in-the-uae/" target="_blank">
                      <img className="cofounder" src={`${imageBasePath}/images/landing/w-award-cofounder.png`} alt="cofounder" />
                    </a>
                    <a href="https://omny.fm/shows/theagenda/theres-an-app-for-that-carcility" target="_blank">
                      <img className="dubai-eye" src={`${imageBasePath}/images/landing/award-dubai-eye.png`} alt="dubai-eye" />
                    </a>
                  </div>
                </div>
              </LazyLoad>

            </div>
        {/* footer */}
        <div className="busineesFooterSection businessFooterpad ">
          <div className="businessfooter-section">
            <div className="container">
              <div className="col-md-12 col-sm-12 col-xs-12 pad0">
                <h1 className="businessfooter-section">Best value car service in Dubai</h1>
                <h5 className="businessfooter-section">Owning a car is easy but car maintenance is a tough task.
                  Especially, when you don’t know which service provider to got
                  to for a specific service. Carcility comes in as a one-stop
                  solution for choosing the right service for your car from a
                  plethora of options with quotes. Finding the right car service
                  center in Dubai for your car is no more a day’s task.
                  From car repair services to car detailing and car wash,
                  we can connect you to trusted vendors in just one click.
                  So, what are you waiting for?
                  <a href={`${baseUrl}/sign-up`} className="blue-text"> Create a FREE account at Carcility. </a>
                  Car service providers can also list their
                  <a href={`${baseUrl}/business/sign-up`} className="blue-text"> car care business on
                    Carcility. </a> Apart from helping customers to find
                  right car maintenance services, Carcility has a
                  <a href={`${baseUrl}/blog`} className="blue-text"> good automotive Blog section. </a> You can browse
                  through the posts and stay updated about car related
                  technologies, car maintenance tips and automotive industry news.
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/*Footer*/}
        <Footer {...this.props}/>
        {/* sidebar menu */}
        <div className={this.state.sidebarOpen ? 'menu-sidebar active' : 'menu-sidebar inactive'}>
          {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
          <div className="nonSignup-list">
            <ul className="list-unstyled">
              <li
                onClick={e => {
                  e.preventDefault();
                  this.props.router.push(appBasePath + '/sign-in');
                }}
              >
                <span>
                  LOG IN
                </span>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  this.props.router.push(appBasePath + '/sign-up');
                }}
              >
                <span>
                  SIGN UP
                </span>
              </li>
              <li>
                <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                  </span>
                </Link>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  window.location.pathname = '/business';
                }}
              >
                <span>
                  FOR BUSINESS
                </span>
              </li>
              <li
              onClick={e => {
                e.preventDefault();
                window.location.pathname = '/blog';
              }}
            >
              <span>
                BLOG
              </span>
            </li>
            </ul>
          </div>
        </div>
        {this.state.searchMobile &&
        <div>

          <MobileHeaderSearch
            mobile={true}
            actions={this.props.actions}
            commonReducer={this.props.commonReducer}
            mobSearchClick={() => this.setState({ searchMobile: false })}
          />
        </div>}
      </div>
      </div>
    );
  }
}
