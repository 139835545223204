import React, { Component } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import range from 'lodash/range';
import TextInput from '../common/TextInput';
import CustomScroll from 'react-custom-scroll';

export default class CarDetails extends Component {
  constructor(props) {
    super(props);
    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });
    this.state = {
        filteredYears: years,
        allYears: years,
        carDetailsType: 'none',
        "selectedManufacturer": "Manufacturer",
        "selectedModel": "Model",
        "selectedYear": "Year",
        "errorCarDetails": false
    }
    this.chooseCarDetails = this.chooseCarDetails.bind(this);
    this.selectedManufactureDrop = this.selectedManufactureDrop.bind(this);
    this.selectedModelDrop = this.selectedModelDrop.bind(this);
    this.selectedYearDrop = this.selectedYearDrop.bind(this);
    this.clearCarDetails = this.clearCarDetails.bind(this);
  }

  clearCarDetails() {
    this.setState({
      selectedManufacturer:"Manufacturer",
      selectedModel:"Model",
      selectedYear:"Year",
      errorCarDetails: true
    });
    this.props.clearCarDetails();
  }

  chooseCarDetails(type) {
    if(type === 'carDetails'){
       this.setState({
         carDetailsType:'manufacture'
       });
      } else if(type === 'carTypes') {
        this.setState({
          carDetailsType:"none",
          // errorCarDetails: true
        });
      } 
  }

  selectedManufactureDrop(name,id,model){
    this.props.manufactureSelected(name,id,model);
    this.setState({
      carDetailsType: 'model',
      selectedManufacturer: name
    });
  }

  selectedModelDrop(modelId,modelName){
    this.props.modelSelected(modelId,modelName);
    this.setState({
      selectedModel: modelName,
      carDetailsType: 'year',
    });
  }

  selectedYearDrop(year){
    this.props.yearSelected(year);
    this.setState({ selectedYear: year,carDetailsType: 'none'});
  }

  render() {
      return (
        <div>
           {this.state.carDetailsType=== "none" && <div className="row select-details">
                      <div className="col-12">
                        <div className="car-details-div">
                          <TextInput
                            label="Car Details.*"
                            name="car_details"
                            id="car_details"
                            type="text"
                            showValidationError={this.state.errorCarDetails}
                            validationError="Enter your car details"
                            value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                            onFocus={()=> this.chooseCarDetails('carDetails')} />
                          <span className="close" onClick={()=>this.clearCarDetails()}><i className="mdi mdi-close"></i></span>
                        </div>
                       </div>
                      </div>}

          <div className="pad0 select-car-details">
            <div className="panel-content padLeft0">
            <div className={this.state.carDetailsType=== "manufacture"? "form car-details": "hide car-details"}>
            <div className="form-title quick-search-title">Select Manufacturer
              <span className="close" onClick={(e) => {this.chooseCarDetails('carTypes')}}><i className="mdi mdi-close"></i></span>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  name="manufacturer"
                  id="manufacturer"
                  className="form-control quick-search-input"
                  placeholder="Manufacturer"
                  value={this.state.searchManufacturerTerm}
                />
                <div className="car-search">
                  <ul>
                    <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                      {map(this.props.carList, (carItem, key) => {
                        return (
                          <li
                            key={key}
                            onClick={() => {this.selectedManufactureDrop(carItem.name,carItem.id,carItem.carmodel)}}>
                            <label>{carItem.name}</label>
                          </li>
                        );
                      })}
                    </CustomScroll>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.carDetailsType=== "model"? "form car-details": "hide car-details"}>
            <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
              <span className="close" onClick={(e) => {this.chooseCarDetails('carTypes')}}><i className="mdi mdi-close"></i></span>
            </div>
            <div className="row">
              <div className="col-12">

                <input
                  type="text"
                  name="model"
                  id="model"
                  className="form-control quick-search-input"
                  placeholder="Model"
                  value=""
                />
                <div className="car-search">
                  <ul>
                    <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                      {map(this.props.carModalList, (carItem, key) => {
                        return (
                          <li
                            key={key}
                            onClick={() => {this.selectedModelDrop(carItem.id,carItem.name)}}>
                            <label>{carItem.name}</label>
                          </li>
                        );
                      })}
                    </CustomScroll>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.carDetailsType=== "year"? "form car-details": "hide car-details"}>
            <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
              <span className="close" onClick={(e) => {this.chooseCarDetails('carTypes')}}><i className="mdi mdi-close"></i></span>
            </div>
            <div className="row">
              <div className="col-12">

                <input
                  type="text"
                  name="year"
                  id="year"
                  className="form-control quick-search-input"
                  placeholder="Year"
                  value=""
                  onChange={e => {
                    e.preventDefault();
                    this.autoCompleteYear(e.target.value );
                  }}
                />
                <div className="car-search">
                  <ul>
                    <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                      {map(this.state.allYears, (list, key) => {
                        return (
                          <li
                            key={key}
                            onClick={() => {this.selectedYearDrop(list.toString())}}>
                            <label>{list}</label>
                          </li>
                        );
                      })}
                    </CustomScroll>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> 
          </div>
        </div>
      );
    }

}
