import React, { Component } from 'react';
import Gmaps from '../MyRequest/Gmaps';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { imageBasePath } from '../../constants/staticData';

export default class Address extends Component {
  constructor(props) {
    super(props);
  }
  reorderWorkingTime(workingHours) {
    return sortBy(workingHours, function (day) {
      return day.day;
    });
  }
  addClosed(workingHours) {
    const days = ['', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let updatedWorkingHours = [];
    map(days, (day, key) => {
      let index = filter(workingHours, { day: key });
      if (index && index.length) {
        updatedWorkingHours = updatedWorkingHours.concat(index);
        // updatedWorkingHours.push(workingHours[index]);
      } else {
        updatedWorkingHours.push({ isClosed: true, day: day });
      }
    });
    return updatedWorkingHours;
  }
  render() {
    const { garageProfileDetails } = this.props;
    let workingHours = this.reorderWorkingTime(garageProfileDetails.garage_timings);
    workingHours = this.addClosed(workingHours);
    let payment = 'Payment options not available';
    if (garageProfileDetails) {
      if (garageProfileDetails.cashpayment && garageProfileDetails.ccpayment) {
        payment = "Cash & Credit Cards Accepted";
      } else if (garageProfileDetails.cashpayment) {
        payment = "Only Cash Accepted";
      } else if (garageProfileDetails.ccpayment) {
        payment = "Only Credit Cards Accepted";
      }
    }
    let monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let createdDate = new Date(garageProfileDetails && garageProfileDetails.createdAt);
    let memberSince = monthList[createdDate.getMonth()] + ' ' + createdDate.getDate() + ', ' + createdDate.getFullYear();
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const workingHoursList = garageProfileDetails && map(workingHours, (day, index) => {
      if (day.isClosed) {
        if (day.day) {
          return (
            <li key={index}>
              <label>{day.day ? day.day : ''}</label>
              <span>{'Closed'}</span>
            </li>
          );
        }
      } else {
        return (
          <li key={index}>
            <label>{(index > 0 && workingHours[index - 1].day != workingHours[index].day) ? days[day.day - 1] : ''}</label>
            <span>{day.range[0].from} - {day.range[0].to}</span>
          </li>
        );
      }
    });
    const markerLocation = [{
      lat: garageProfileDetails && Number(garageProfileDetails.latitude), lng: garageProfileDetails && Number(garageProfileDetails.longitude),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];
    let website = garageProfileDetails.website && garageProfileDetails.website.startsWith('http') ? garageProfileDetails.website : "http://" + garageProfileDetails.website;
    return (
      <div className="vendor-services">
        <div className="vendor-description n-address">
          <h4>Details</h4>
          <div className="vendor-address-map ">
            <div className="gmaps">
              <Gmaps
                markers={markerLocation}
                center={{ lat: Number(garageProfileDetails.latitude), lng: Number(garageProfileDetails.longitude) }}
                zoom={13}
                setCenter={true}
                containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                mapElement={<div style={{ height: 230 + 'px', width: 100 + '%' }} />}
              />
            </div>
          </div>
          <ul className="vendor-address-details">
            <li>
              <i className="mdi mdi-map-marker" />
              <span className="vendor-icon-label">
                {garageProfileDetails && garageProfileDetails.address}
              </span>
            </li>
            {/* <li>
              <i className="mdi mdi-phone" />
              <span className="vendor-icon-label">
                {garageProfileDetails && garageProfileDetails.phone}
              </span>
            </li>
            <li>
              <i className="mdi mdi-email-outline" />
              <span className="vendor-icon-label">
                <a className="n-text-blue" href={garageProfileDetails && "mailto:"+garageProfileDetails.email}>{garageProfileDetails && garageProfileDetails.email}</a>
              </span>
            </li>
            {garageProfileDetails.website ? <li>
              <i className="mdi mdi-link-variant" />
              <span className="vendor-icon-label">
                <a className="n-text-blue" href={website} target="_blank">{garageProfileDetails && garageProfileDetails.website}</a>
              </span>
            </li> */}
            <li>
              <i className="mdi mdi-credit-card" />
              <span className="vendor-icon-label">
                {payment}
              </span>
            </li>
            {workingHours.length && <li>
              <i className="mdi mdi-calendar-clock" />
              <span className="vendor-icon-label">
                <ul>
                  {workingHoursList}
                </ul>
              </span>
            </li>}
            <li>
              <i className="mdi mdi-calendar-check" />
              <span className="vendor-icon-label">
                {'Member Since ' + memberSince}
              </span>
            </li>
            {garageProfileDetails && garageProfileDetails.completedjobs && 1==2 ? <li>
              <i className="mdi mdi-check-all" />
              <span className="vendor-icon-label">
                {garageProfileDetails && garageProfileDetails.completedjobs + ' Jobs Completed'}
              </span>
            </li> : <div />}
          </ul>
        </div>
      </div>
    );
  }
}
