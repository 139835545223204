import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';


class ServiceTabs extends Component {
    render() {
        return (
            <Panel collapsible expanded={this.props.open} header={
                <div onClick={this.props.tabClick}>
                  <h4>{this.props.title}</h4>
                  <i className={this.props.open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
                </div>
                } >
                {this.props.renderView}
            </Panel>
        );
    }
}

export default ServiceTabs;