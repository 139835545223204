import React, { Component } from "react";
import {
  testSpaces,
  validateEmail,
  validatePassword,
  validateMobile
} from "../../helpers/";
export default class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
      showValidationError: false,
      inputValue: ""
    };
  }
  componentDidMount() {
    if (this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ inputValue: nextProps.value });
    }
    // if (nextProps.showValidationError) {
    this.setState({ showValidationError: nextProps.showValidationError });
    // }
  }
  handleInputChange(e) {
    let inputValue = e.target.value;

    if(this.props.type == "email")
      inputValue = inputValue.trim()

    this.setState({ inputValue: inputValue, showValidationError: false });
    if (this.props.onChange) {
      this.props.onChange(inputValue, this.props.name);
    }
  }
  handleOnBlur(e) {
    const inputValue = e.target.value;
    const {
      type,
      onBlur,
      name,
      onKeyPress,
      id,
      limitCharacters,
      isOTP,
      emptyValidationNotRequired
    } = this.props;
    let validateError = false;
    if (!testSpaces(inputValue)) {
      validateError = true;
      this.setState({
        showValidationError: emptyValidationNotRequired ? false : validateError
      });
    } else {
      if (
        (type == "email" && !validateEmail(inputValue)) ||
        (type == "password" && !validatePassword(inputValue)) ||
        ((type == "mobile" || type == "phone") && !validateMobile(inputValue))
      ) {
        validateError = true;
        this.setState({ showValidationError: validateError });
      } else if (isOTP) {
        validateError = !(inputValue.length == 4);
        this.setState({ showValidationError: validateError });
      } else {
        validateError = false;
        this.setState({ showValidationError: validateError });
      }
    }
    if (onBlur) {
      onBlur(inputValue, name, validateError);
    }
    let key=e.keyCode || e.which;
    if(key==13){
      onKeyPress(e);
    }
  }
  handleOnFocus(e) {
    const inputValue = e.target.value;
    const {
      type,
      onFocus,
      name,
    } = this.props;
    let validateError = false;
    if (onFocus) {
      onFocus(inputValue, name, validateError);
    }
  }
  onlyPositiveIntegers(e) {
    const { onEnterCall } = this.props;
    if(onEnterCall) {
      this.handleOnBlur(e);
      return false;
    }
    const re = /[0-9A-F:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  render() {
    const {
      label,
      isOTP,
      validationError,
      isNumber,
      type,
      value,
      disabled,
      customClass,
      emptyError,
      isPositiveNumber,
      onEnterCall
    } = this.props;
    let inputClass =
      type == "phone"
        ? "form-group input-group phoneNumberInput "
        : "form-group ";
    if (this.state.showValidationError && validationError) {
      inputClass += "error ";
    }
    if (isOTP) {
      inputClass += "otp-input ";
    }
    return type === "password" ? (
      <div className={`${inputClass} ${customClass}`}>
        <input
          name={this.props.name}
          id={this.props.id}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          type={
            type == "password"
              ? this.state.passwordVisible ? "text" : "password"
              : type
          }
          className="form-control form-input"
          value={this.state.inputValue}
          disabled={this.props.disabled ? "disabled" : ""}
          onBlur={this.handleOnBlur.bind(this)}
          onChange={e => this.handleInputChange(e, type)}
          onKeyPress={this.handleOnBlur.bind(this)}
          required
        />
        {type === "password" && (
          <span
            className="input-icon"
            onClick={e => {
              e.preventDefault();
              this.setState({ passwordVisible: !this.state.passwordVisible });
            }}
          >
            {this.state.passwordVisible ? (
              <i className="mdi mdi-eye" />
            ) : (
              <i className="mdi mdi-eye-off" />
            )}
          </span>
        )}
        {this.state.showValidationError &&
          validationError &&
          validationError && (
            <span className="error-text">
              {testSpaces(this.state.inputValue)
                ? validationError
                : emptyError ? emptyError : validationError}
            </span>
          )}
        <label>{label}</label>
      </div>
    ) : (
      <div className={`${inputClass} ${customClass}`}>
        {type == "phone" && <span className="countryCode">+971</span>}
        <input
          name={this.props.name}
          id={this.props.id}
          placeholder={
            type != "phone"
              ? this.props.placeholder ? this.props.placeholder : ""
              : ""
          }
          type={type == "email" ? "text" : type == "mobile" ? "number" : type}
          className={
            type == "phone"
              ? "form-control form-input phone-no"
              : "form-control form-input"
          }
          value={this.state.inputValue}
          disabled={this.props.disabled ? "disabled" : ""}
          onBlur={this.handleOnBlur.bind(this)}
          onFocus={this.handleOnFocus.bind(this)}
          onChange={e => this.handleInputChange(e, type)}
          required
          onKeyPress={(e) => isPositiveNumber && this.onlyPositiveIntegers(e)}
        />
        {this.state.showValidationError &&
          validationError &&
          validationError && (
            <span className="error-text">
              {testSpaces(this.state.inputValue)
                ? validationError
                : emptyError ? emptyError : validationError}
            </span>
          )}
        <label>{label}</label>
      </div>
    );
  }
}
