import React, { Component } from 'react';
import InputRange from 'react-input-range';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import Rating from 'react-rating';
import Button from '../common/Button';

class RequestFilter extends Component {
  render() {
    let { checkBox, stateVal, day, rangefrom, rangeTo, toggleSwitchVal } = this.props
    return (
      <div className="mobile-sort-options n-request-mobile-filter">
        <div className="header">
          <i className="mdi mdi-arrow-left" onClick={this.props.hideOption}></i>
          <div className="f-card f-card-service">
            <h5>Service Type</h5>
            <div className="row">
              <div className="col-md-6 pad0">
                <div className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id="all" value="" checked={checkBox.all} onChange={() => (this.props.changeCheckbox('all'))} />
                  <label htmlFor="all" className="custom-checkbox"></label>
                  <label htmlFor="all">
                    All
                                              </label>
                </div>
                <div className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id="car-wash" value="" checked={checkBox.carWash} onChange={() => (this.props.changeCheckbox('carWash'))} />
                  <label htmlFor="car-wash" className="custom-checkbox"></label>
                  <label htmlFor="car-wash">
                    Car Wash
                                              </label>
                </div>
              </div>
              <div className="col-md-6 pad0">
                <div className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id="car-service" value="" checked={checkBox.carService} onChange={() => (this.props.changeCheckbox('carService'))} />
                  <label htmlFor="car-service" className="custom-checkbox"></label>
                  <label htmlFor="car-service">
                    Car Service
                                              </label>
                </div>
                <div className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id="car-repair" value="" checked={checkBox.carRepair} onChange={() => (this.props.changeCheckbox('carRepair'))} />
                  <label htmlFor="car-repair" className="custom-checkbox"></label>
                  <label htmlFor="car-repair">
                    Car Repair
                                              </label>
                </div>
              </div>
            </div>
          </div>
          <div className="f-card n-distance-range">
            <h5>Distance</h5>
            <InputRange
              formatLabel={distValue => `${distValue}km`}
              minValue={0}
              maxValue={100}
              value={stateVal.distValue}
              onChange={(distValue) => (this.props.distValue(distValue))}
            />
            <span className="range-min">{stateVal.distValue.min + " Km"}</span>
            <span className="range-max">{stateVal.distValue.max + " Km"}</span>

          </div>

          <div className="f-card openAlign n-time-range">
            <h5>Open Between</h5>
            <ul className="list-unstyled">
              <li className={stateVal.daySelected["sunday"] ? 'active' : ''} onClick={() => (day("sunday", 1))}>SUN</li>
              <li className={stateVal.daySelected["monday"] ? 'active' : ''} onClick={() => (day("monday", 2))}>MON</li>
              <li className={stateVal.daySelected["tuesday"] ? 'active' : ''} onClick={() => (day("tuesday", 3))}>TUE</li>
              <li className={stateVal.daySelected["wednesday"] ? 'active' : ''} onClick={() => (day("wednesday", 4))}>wed</li>
              <li className={stateVal.daySelected["thrusday"] ? 'active' : ''} onClick={() => (day("thrusday", 5))}>thu</li>
              <li className={stateVal.daySelected["friday"] ? 'active' : ''} onClick={() => (day("friday", 6))}>fri</li>
              <li className={stateVal.daySelected["saturday"] ? 'active' : ''} onClick={() => (day("saturday", 7))}>sat</li>
            </ul>
            <div className="row date-time model-select date-time-align">
              <span className="n-time-from">
                <TimePicker
                  showSecond={false}
                  popupClassName="mobile-tp"
                  className="custom-timepicker"
                  format={'h:mm a'}
                  value={stateVal.rangeFromDefault}
                  onChange={(time) => (this.props.rangefrom(time))}
                  defaultValue={moment('2016-12-19T00:00:00+05:30')}
                  use12Hours
                />
                <i className="mdi mdi-chevron-down time-to" />
              </span>
              <span className="time-to-time n-time-to">to</span>
              <span className="n-time-to">
                <TimePicker
                  showSecond={false}
                  popupClassName="mobile-tp"
                  className="custom-timepicker"
                  format={'h:mm a'}
                  onChange={(time) => (this.props.rangeTo(time))}
                  value={stateVal.rangeToDefault}
                  defaultValue={moment('2016-12-19T23:59:00+05:30')}
                  use12Hours
                />
                <i className="mdi mdi-chevron-down time-to" />
              </span>
            </div>
          </div>
          <div className="f-card toggleBtn">
            <h5>Open 24/7</h5>
            <ToggleSwitch
              checked={toggleSwitchVal.Open24_7}
              size="small"
              onChange={() => (this.props.switch('Open24_7'))}

              ref={(node) => {
                this.toggleSwitch = node;
              }} />
          </div>
          <div className="f-card star-rating">
            <h5>Rating</h5>
            <Rating
              empty="mdi mdi-star-outline "
              full="mdi mdi-star active-star"
              initialRate={stateVal.ratingValue}
              onChange={(e) => { (this.props.ratingOnChange(e)) }}
            />
          </div>

          <div className="f-card payment-type toggleBtn">
            <h5>Payment by</h5>
            <div className="holder">
              <span className="pad0">Pay by cash</span>
              <ToggleSwitch
                checked={toggleSwitchVal.byCash}
                size="small"
                onChange={() => (this.props.switch('byCash'))}
                ref={(node) => {
                  this.toggleSwitch = node;
                }}
              />
            </div>
            <div className="holder">
              <span className="">Pay by credit card</span>
              <ToggleSwitch
                checked={toggleSwitchVal.byCreditcard}
                size="small"
                onChange={() => (this.props.switch('byCreditcard'))}
                ref={(node) => {
                  this.toggleSwitch = node;
                }}
              />
            </div>
          </div>
          <div className="filter-footer footer">
            <a onClick={() => (this.props.clearFilter(this.props.jobId))}>Clear</a>
            <Button btnType="red" btnSize="sm" fontSize={15} label="Apply" btnCallBack={() => (this.props.filterSelect(this.props.jobId))} />
          </div>
        </div>
      </div>
    );
  }
}

export default RequestFilter;
