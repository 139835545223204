import * as types from '../actions/actionTypes';
import { assign, size, cloneDeep, findIndex, map, remove, concat, reverse } from 'lodash';

const initialState = {
  createdNewCarProfile:false,
  'newCarProfileData': {
    'images': []
  },
};

export default function carProfileReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CAR_PROFILE: {
      let authData = localStorage.authData ? JSON.parse(localStorage.authData) : undefined;
      if (authData) {
        authData.hasVehicleProfile = true;
        localStorage['authData'] = JSON.stringify(authData);
      }
      let carProfiles = cloneDeep(state.carProfiles);
      if (action.carData && !Array.isArray(action.profileId) && action.profileId) {
        carProfiles[action.profileId] = action.carData;
      } else if (action.carData && action.carData.id) {
        carProfiles[action.carData.id] = action.carData;
      }


      return assign({}, state, { carData: action.carData, carProfiles: carProfiles, currentComponentKey: '',createdNewCarProfile:true  , newCarProfileData: {} });
    }
    case types.LIST_CAR_PROFILES:
      if (action.carProfiles && !action.unableToFetch) {
        return assign({}, state, { carProfiles: action.carProfiles, currentComponentKey: '' });
      } else {
        return assign({}, state, { carProfiles: [], noCarProfiles: true, currentComponentKey: '' });
      }
    case types.VIEW_CAR_PROFILE: {
      if (action.unableToFetch) {
        return assign({}, state, { currentComponentKey: '', isIndCarProfileLoaded: true, unableToFetchCarProfile: true });
      } else {
        let carProfiles = cloneDeep(state.carProfiles);
        if (action.carProfile && carProfiles.length) {
          carProfiles[action.carProfile.id] = action.carProfile;
        }

        let images = [];
        let CSNimages = [];
        map(action.carProfile.images, (image) => {


          if (image.type == "chassisNumber") {
            CSNimages.push({
              isUploaded: true,
              isFailed: false,
              id: image.id,
              thumbnail: image.small,
              original: image.original,
              large: image.large,
              isCover: false
            });
          } else if (image.type == "vehicle") {

            images.push(
              {
                isUploaded: true,
                isFailed: false,
                id: image.id,
                thumbnail: image.small,
                original: image.original,
                large: image.large,
                isCover: image.isCover
              });
          }
        });
        let newCarProfileData = cloneDeep(state.newCarProfileData);
        newCarProfileData.images = images;
        newCarProfileData.CSNimages = CSNimages;
        let batch_id = carProfiles && carProfiles.length && carProfiles[action.carProfile.id] && carProfiles[action.carProfile.id].batchId;
        batch_id = batch_id ? batch_id : action.carProfile.batchId;
        if (batch_id) {
          newCarProfileData.batch_id = batch_id;
        }
        return assign({}, state, { carProfiles: carProfiles, currentCarProfile: action.carProfile, currentComponentKey: '', isIndCarProfileLoaded: true, unableToFetchCarProfile: false, newCarProfileData: newCarProfileData });
      }
    }
    case types.DELETE_CAR_PROFILE: {
      let carProfiles = cloneDeep(state.carProfiles);
      delete carProfiles[action.carProfileID];
      if (size(carProfiles) == 0) {
        let authData = localStorage.authData ? JSON.parse(localStorage.authData) : undefined;
        if (authData) {
          authData['hasVehicleProfile'] = false;
          localStorage['authData'] = JSON.stringify(authData);
        }
      }
      return assign({}, state, { carProfiles: carProfiles, currentComponentKey: '' });
    }
    case types.CAR_MAKE_AND_MODELS: {
      if (action.unableToFetch) {
        let carMakeAndModels = cloneDeep(state.carMakeAndModels);
        if (action.responseType == 'model') {
          let index = findIndex(carMakeAndModels, { id: action.makeId });
          if (index != -1 && carMakeAndModels && carMakeAndModels.length && carMakeAndModels[index]) {
            carMakeAndModels[index].isCarModelLoaded = true;
          }
        }
        return assign({}, state, { carMakeAndModels: carMakeAndModels, currentComponentKey: '' });
      } else {
        let carMakeAndModels = cloneDeep(state.carMakeAndModels);
        if (action.responseType == 'make') {
          let manufacturers = [];
          map(action.manufacturers, (make) => {
            let index = findIndex(carMakeAndModels, { id: make.id });
            if (make) {
              manufacturers.push({
                "carmodel": index != -1 ? carMakeAndModels[index] && carMakeAndModels[index].carmodel : [],
                "isCarModelLoaded": carMakeAndModels && carMakeAndModels.length && carMakeAndModels[index] && carMakeAndModels[index].carmodel && carMakeAndModels[index].carmodel.length ? true : false,
                "id": make.id,
                "lang": '',
                "name": make.name,
                "photos": make.photos
              });
            }
          });
          carMakeAndModels = manufacturers;
        } else if (action.responseType == 'model') {
          let index = findIndex(carMakeAndModels, { id: action.makeId });
          if (index != -1 && carMakeAndModels && carMakeAndModels.length && carMakeAndModels[index]) {
            carMakeAndModels[index].carmodel = action.models;
            carMakeAndModels[index].isCarModelLoaded = true;
          }
        }
        return assign({}, state, { carMakeAndModels: carMakeAndModels, currentComponentKey: '' });
      }
      // return assign({}, state, { carMakeAndModels: action.carMakeAndModels, currentComponentKey: '' });
    }
    case types.SET_COVER_FOR_CAR_PROFILE: {
      let currentCarProfile = cloneDeep(state.currentCarProfile);
      let index = findIndex(currentCarProfile.images, { id: action.image_id });
      if (index != -1) {
        map(currentCarProfile.images, (image, key) => {
          if (index == key) {
            currentCarProfile.images[key].isCover = true;
          } else {
            currentCarProfile.images[key].isCover = false;
          }
        });
      }
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      index = findIndex(newCarProfileData.images, { id: action.image_id });
      if (index != -1) {
        map(newCarProfileData.images, (image, key) => {
          if (index == key) {
            newCarProfileData.images[key].isCover = true;
          } else {
            newCarProfileData.images[key].isCover = false;
          }
        });
      }
      return assign({}, state, { currentCarProfile: currentCarProfile, newCarProfileData: newCarProfileData });
    }
    case types.CREATE_CAR_PROFILE_BATCH_ID: {
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      newCarProfileData.batch_id = action.batch_id;
      return assign({}, state, { newCarProfileData: newCarProfileData, isIndCarProfileLoaded: false });
    }
    case types.CLEAR_CAR_PROFILE_BATCH_ID: {
      return assign({}, state, { newCarProfileData: {} });
    }
    case types.UPLOAD_VEHICLE_IMAGE_SUCCESS: {
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      newCarProfileData.images = size(newCarProfileData.images) ? newCarProfileData.images : [];
      newCarProfileData.images.push(action.image);
      return assign({}, state, { newCarProfileData: newCarProfileData });
    }
    case types.UPLOAD_CHASSIS_IMAGE_SUCCESS: {
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      newCarProfileData.CSNimages = size(newCarProfileData.CSNimages) ? newCarProfileData.CSNimages : [];
      newCarProfileData.CSNimages.push(action.image);
      newCarProfileData.batch_id = action.batch_id;
      return assign({}, state, { newCarProfileData: newCarProfileData });
    }
    case types.UPLOAD_VEHICLE_IMAGE_FAILED: {
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      newCarProfileData.images = size(newCarProfileData.images) ? newCarProfileData.images : [];
      newCarProfileData.images.push(action.image);
      return assign({}, state, { newCarProfileData: newCarProfileData });
    }
    case types.DELETE_VEHICLE_IMAGE: {
      let carProfiles = cloneDeep(state.carProfiles);
      let vehicleImages = carProfiles[action.vehicleId] && carProfiles[action.vehicleId].images;
      if (vehicleImages && vehicleImages.length && carProfiles && carProfiles.length && carProfiles[action.vehicleId]) {
        remove(vehicleImages, { id: action.imageId });
        carProfiles[action.vehicleId].images = vehicleImages;
      }
      let newCarProfileData = cloneDeep(state.newCarProfileData);
      remove(newCarProfileData.images, (image, key) => {
        return key == action.key;
      });
      return assign({}, state, { carProfiles: carProfiles, newCarProfileData: newCarProfileData, deletedImageId: action.imageId });
    }

    case types.GET_CAR_SERVICE_HISTORY: {
      if (action.unableToFetch) {
        return assign({}, state, {
          serviceHistoryLoaded: true,
          unableToFetchServiceHistory: true
        });
      } else {
        let carProfiles = cloneDeep(state.carProfiles);
        let serviceHistory = carProfiles && size(carProfiles) && carProfiles[action.vehicleId] && carProfiles[action.vehicleId].serviceHistory ? carProfiles[action.vehicleId].serviceHistory : [];
        let newData = [];
        map(action.serviceHistory && action.serviceHistory.data, (data) => {
          let date = new Date(data.jobdate);
          let monthList = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
          // date = monthList[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
          let requestService = data && data.userbookingrequest_services && data.userbookingrequest_services.length && data.userbookingrequest_services[0];
          // let garagejobquote = data && data.garagejobquotes && data.garagejobquotes.length && data.garagejobquotes[0];
          let statusText = '';
          if (data.requeststatusId == 4) {
            statusText = 'UPCOMING ON ';
          } else if (data.requeststatusId == 3) {
            statusText = 'PROGRESS ON ';
          } else if (data.requeststatusId == 6) {
            statusText = 'COMPLETED ON ';
          }
          newData.push({
            timelineStatus: statusText + monthList[date.getMonth()] + ' ' + date.getDate(),
            timelineMonth: monthList[date.getMonth()],
            timelineIcon: requestService && requestService.image?requestService.image : requestService.parentServiceImage,
            serviceType: requestService && requestService.name,
            timelineBadge: data.services && data.services.name,
            requeststatusId: data.requeststatusId,
          });
        });

        serviceHistory = action.fromLoadMore ? concat(serviceHistory, newData) : newData;
        if (carProfiles && size(carProfiles) && carProfiles[action.vehicleId]) {
          carProfiles[action.vehicleId].serviceHistory = serviceHistory;
          carProfiles[action.vehicleId].serviceHistoryLoaded = true;
          carProfiles[action.vehicleId].pageInfo = action.serviceHistory && action.serviceHistory.pageInfo;
        }
        return assign({}, state, {
          carProfiles: carProfiles,
          serviceHistoryLoaded: true,
          unableToFetchServiceHistory: false
        });
      }
    }

    default:
      return state;
  }
}
