import * as dashboardActions from './dashboardActions';
import * as authActions from './authActions';
import * as carProfileActions from './carProfileActions';
import * as bookingRequestActions from './bookingRequestActions';
import * as useraccountActions from './useraccountActions';
import * as commonActions from './commonActions';
import { assign } from 'lodash';

export const ActionCreators = assign({},
  dashboardActions,
  authActions,
  carProfileActions,
  bookingRequestActions,
  useraccountActions,
  commonActions
);
