import React, { Component } from 'react';
import { map } from 'lodash';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import SelectDropdown from '../common/SelectDropdown';
import Slider from 'react-slick';
import moment from 'moment';
var uniqid = require('uniqid');
import { appBasePath } from '../../constants/staticData';

class Services extends Component {
    constructor() {
        super();
        this.state = {
            servicesModal: false,
            selectedService: undefined,
            selectedValue :'',
            submissionError : false,
            selectedSubService: []
        }
        this.selectCar = this.selectCar.bind(this);
        this.submitEmergencyData = this.submitEmergencyData.bind(this);
        this.selectedServiceCall = this.selectedServiceCall.bind(this);
    }
    selectCar(selectCar){
      this.setState({selectedValue : selectCar });
      this.setState({submissionError:false});
    }
    selectedServiceCall(selService){
      let data = [];
      let SelecetdId = selService.id;
      data.push(SelecetdId);
      return data;
    }
    submitEmergencyData(){
      let payload;
      let timeStamp = new Date();
      let momentTimeStamp = moment.utc(timeStamp).format();
      if(this.state.selectedValue == ''){
       this.setState({submissionError:true});
      } else {
        this.setState({submissionError:false});
        this.setState({ servicesModal: false });

        payload = {
          "batch_id": uniqid(),
          "serviceid": 4,
          "subserviceid": this.state.selectedSubService,
          "vehicleid": this.state.selectedValue.id,
          "details": '',
          "userpreffereddatetime": momentTimeStamp,
          "location": this.props.address.formatted_address,
          "latitude": this.props.mapPosition.lat.toString(),
          "longitude": this.props.mapPosition.lng.toString(),
          "isurgentrequest": true,
        };
        this.props.actions.createBookingRequest(payload);
        this.props.router.push(appBasePath + '/emergency/active')
      }

    }
    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3.3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 4.3,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5.3,
                        slidesToScroll: 5,
                    }
                },
            ]
        };
        const ModalDropDownData = this.props.carProfileReducer.carProfiles;
        //[{ name: "My Audi" }, { name: "My Nissan GT-R" }, { name: "My BMW" }]
        return (
            <div className="service-select">
                <h5>Select a Service</h5>

                { this.props.data.length > 0 &&
                <div className="services-holder">
                    <Slider {...settings}>

                        { map(this.props.data, (services, key) => {
                            return (
                                <div className="img-row" key={key} onClick={() =>
                                this.setState({ servicesModal: true, selectedService: services.name, selectedSubService:this.selectedServiceCall(services)  })
                                }>
                                    <div className="service-container">
                                        <div className="img-holder">
                                            <img src={services.image} alt="" className="service-img" />
                                        </div>
                                        <span>{services.name}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                }
                <CustomModal showModal={this.state.servicesModal} footer={true} cancelText="Go Back" saveText="Confirm"
                    title="Confirm Emergency Request" className="bookService-modal emergency-modal"
                    closeIcon={false} submitCallBack ={() => { this.submitEmergencyData() }} hideModal={() => { this.setState({ servicesModal: false }) }}>
                    <Modal.Body>
                        <div className="emgcy-modal">
                            <div className="holder">
                                <h3>Select a Car Profile</h3>
                                <SelectDropdown selectedCarProfile={this.state.selectedValue} data={ModalDropDownData}
                                select={this.selectCar.bind(this)} />
                                {this.state.submissionError && <span className="error-text">Select Car Profile</span>}
                            </div>
                            <div className="holder">
                                <h3>Selected Service</h3>
                                <span className="text">{this.state.selectedService}</span>
                            </div>
                            <div className="holder">
                                <h3>Your Location</h3>
                                <span className="text">{this.props.address.formatted_address}</span>
                            </div>
                        </div>
                    </Modal.Body>
                </CustomModal>
            </div>
        );
    }
}

export default Services;
