import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class MobileMessage extends Component {

    render() {
        const {isVisible, backBtnCallBack} = this.props;
        return (
            <div className={isVisible ? 'mobileMessage': 'mobileMessage hide'}>
                <div className="notificationHead">
                    <div className="back-option"  onClick={(e) => {e.preventDefault(); backBtnCallBack(false);}}>
                        <img src={`${imageBasePath}/images/back-arrow.png`}  alt="back" />
                    </div>
                    <h4>Messages</h4>
                </div>
                <ul className="notificationList">
                    <li>
                        <h5>Lorem Ipsum diet</h5>
                        <p>Lorem ipsum diet notification text goes here</p>
                        <div className="delete-notification"><img src={`${imageBasePath}/images/delete.png`} alt="delete" /></div>
                    </li>
                    <li className="active">
                        <h5>Lorem Ipsum diet</h5>
                        <p>Lorem ipsum diet notification text goes here</p>
                        <div className="delete-notification"><img src={`${imageBasePath}/images/delete.png`} alt="delete" /></div>
                    </li>
                </ul>
            </div>
        );
    }
}
