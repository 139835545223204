/* auth action types */
export const SHOW_WELCOME_PAGE = "SHOW_WELCOME_PAGE";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const SHOW_DASHBOARD = "SHOW_DASHBOARD";
export const SHOW_SEND_OTP_PAGE = "SHOW_SEND_OTP_PAGE";
export const SHOW_VERIFY_OTP_PAGE = "SHOW_VERIFY_OTP_PAGE";
export const VERIFY_OTP = "VERIFY_OTP";
export const LOGOUT = "LOGOUT";
export const SHOW_RESET_EMAIL_CONFIRMATION = "SHOW_RESET_EMAIL_CONFIRMATION";
export const CLEAR_COMPONENT_KEY = "CLEAR_COMPONENT_KEY";
export const RESET_PASSWORD_CODE_VERIFIED = "RESET_PASSWORD_CODE_VERIFIED";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const USER_DETAILS = "USER_DETAILS";
export const GET_ACCOUNT_STATUS = "GET_ACCOUNT_STATUS";
export const REDIRECT_URI = "REDIRECT_URI";

/* common */
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const ADD_USER_LOCATION = "ADD_USER_LOCATION";
export const GET_USER_LOCATIONS = "GET_USER_LOCATIONS";
export const EDIT_USER_LOCATION = "EDIT_USER_LOCATION";
export const DELETE_USER_LOCATION = "DELETE_USER_LOCATION";
export const GET_AUTO_SUGGESTED_LIST = "GET_AUTO_SUGGESTED_LIST";
export const SEARCH_VENDORS = "SEARCH_VENDORS";
export const GET_GARAGE_PROFILE = "GET_GARAGE_PROFILE";
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const HIDE_TOAST_MESSAGE = "HIDE_TOAST_MESSAGE";
export const GET_REVIEWS_AND_RATINGS = "GET_REVIEWS_AND_RATINGS";
export const HIDE_BUTTON_LOADER = "HIDE_BUTTON_LOADER";
export const MARK_MESSAGES_AS_READ = "MARK_MESSAGES_AS_READ";
export const MARK_MESSAGES_AS_READ_FROM_DROPDOWN =
  "MARK_MESSAGES_AS_READ_FROM_DROPDOWN";
export const GET_APP_DOWNLOAD_LINK = "GET_APP_DOWNLOAD_LINK";
export const GET_USER_PAYMENT_STATUS = "GET_USER_PAYMENT_STATUS";
/* car profile action types */
export const SET_CAR_PROFILE = "SET_CAR_PROFILE";
export const LIST_CAR_PROFILES = "LIST_CAR_PROFILES";
export const VIEW_CAR_PROFILE = "VIEW_CAR_PROFILE";
export const DELETE_CAR_PROFILE = "DELETE_CAR_PROFILE";
export const CAR_MAKE_AND_MODELS = "CAR_MAKE_AND_MODELS";
export const USER_PREFEREDTIME_ERROR = "USER_PREFEREDTIME_ERROR";
export const SET_COVER_FOR_CAR_PROFILE = "SET_COVER_FOR_CAR_PROFILE";
export const CREATE_CAR_PROFILE_BATCH_ID = "CREATE_CAR_PROFILE_BATCH_ID";
export const CLEAR_CAR_PROFILE_BATCH_ID = "CLEAR_CAR_PROFILE_BATCH_ID";
export const UPLOAD_VEHICLE_IMAGE_SUCCESS = "UPLOAD_VEHICLE_IMAGE_SUCCESS";
export const UPLOAD_CHASSIS_IMAGE_SUCCESS = "UPLOAD_CHASSIS_IMAGE_SUCCESS";
export const UPLOAD_VEHICLE_IMAGE_FAILED = "UPLOAD_VEHICLE_IMAGE_FAILED";
export const DELETE_VEHICLE_IMAGE = "DELETE_VEHICLE_IMAGE";
export const GET_CAR_SERVICE_HISTORY = "GET_CAR_SERVICE_HISTORY";

/*book service action types */
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const GET_QUOTES_PRICING_DETAILS = "GET_QUOTES_PRICING_DETAILS";
export const GET_ALL_CANCEL_REASONS = "GET_ALL_CANCEL_REASONS";
export const CREATE_BATCH_ID = "CREATE_BATCH_ID";
export const BOOKING_UPLOAD_IMAGES = "BOOKING_UPLOAD_IMAGES";
export const CANCEL_USER_BOOKING_REQUEST = "CANCEL_USER_BOOKING_REQUEST";
export const INCREAMENT_POLLCOUNT = "INCREAMENT_POLLCOUNT";
export const SUCCESS_UPLOAD_IMAGES = "SUCCESS_UPLOAD_IMAGES";
export const FAILURE_UPLOAD_IMAGES = "FAILURE_UPLOAD_IMAGES";
export const BOOKING_UPLOAD_FILES = "BOOKING_UPLOAD_FILES";
export const SUCCESS_DELETE_FILES = "SUCCESS_DELETE_FILES";
export const CLEAR_IMAGES = "CLEAR_IMAGES";

/* Booking Requests */
export const GET_ALL_BOOKING_REQUESTS = "GET_ALL_BOOKING_REQUESTS";
export const GET_BOOKING_REQUESTS_DETAILS = "GET_BOOKING_REQUESTS_DETAILS";
export const GET_VENDOR_QUOTES_LIST = "GET_VENDOR_QUOTES_LIST";
export const ACCEPT_QUOTES = "ACCEPT_QUOTES";
export const MAKE_PAYMENT = "MAKE_PAYMENT";
export const PAYMENT_STATUS = "PAYMENT_STATUS";
export const EDIT_BOOKING_REQUEST = "EDIT_BOOKING_REQUEST";
export const SUCCESS_DELETE_IMAGES = "SUCCESS_DELETE_IMAGES";
export const GET_JOB_STATUS = "GET_JOB_STATUS";
export const UPDATE_BOOKING_REQUEST_NOTIFICATION =
  "UPDATE_BOOKING_REQUEST_NOTIFICATION";
/*userAccont Information*/
export const GET_NOTIFICATION_INFO = "GET_NOTIFICATION_INFO";

/*user Promotional Offers*/
export const GET_PROMOTIONAL_OFFERS = "GET_PROMOTIONAL_OFFERS";
export const GET_ALL_OFFERS = "GET_ALL_OFFERS";
export const GET_SINGLE_OFFER = "GET_SINGLE_OFFER";
export const APPLY_PROMOTIONAL_OFFERS = "APPLY_PROMOTIONAL_OFFERS";


/*Plus Packages*/
export const GET_ALL_PACKAGES = "GET_ALL_PACKAGES";
export const GET_ALL_DATA = "GET_ALL_DATA";


/* Favourites */
export const GET_FAVOURITE_VENDORS = "GET_FAVOURITE_VENDORS";
export const CHANGE_FAVOURITE_SELECTION = "CHANGE_FAVOURITE_SELECTION";
export const CHANGE_FAVOURITES_VENDOR = "CHANGE_FAVOURITES_VENDOR";

/* Notifications */
export const GET_NOTIFICATION_ALERTS = "GET_NOTIFICATION_ALERTS";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const NOTIFICATION_FROM_SOCKET = "NOTIFICATION_FROM_SOCKET";

/* Rating */
export const SUBMIT_RATING_REVIEW_FOR_JOB = "SUBMIT_RATING_REVIEW_FOR_JOB";

/* Chat */
export const CREATE_CHAT_ROOM = "CREATE_CHAT_ROOM";
export const JOIN_CHAT_ROOM = "JOIN_CHAT_ROOM";
export const SENT_NEW_MESSAGE = "SENT_NEW_MESSAGE";
export const RECEIVE_NEW_MESSAGE = "RECEIVE_NEW_MESSAGE";
export const GET_ALL_CHATROOMS = "GET_ALL_CHATROOMS";
export const GET_ALL_MESSAGE_OF_CHAT_ROOM = "GET_ALL_MESSAGE_OF_CHAT_ROOM";
export const GET_ALL_UNREAD_MESSAGES = "GET_ALL_UNREAD_MESSAGES";


export const PLUS_PACKAGE_SELECTED = "PLUS_PACKAGE_SELECTED";
export const PLUS_CAR_SELECTED ="PLUS_CAR_SELECTED";

export const ADD_CHECKED ="ADD_CHECKED";
