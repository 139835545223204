import React, { Component } from 'react';
import Gmaps from '../../MyRequest/Gmaps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../../common/CustomModal';
import { Modal, FormGroup, FormControl } from 'react-bootstrap';
var uniqid = require('uniqid');
import TimePicker from 'rc-time-picker';
import PageLoader from '../../common/PageLoader';
import ServiceTypeEmptyState from '../../EmptyState/ServiceTypeEmptyState';
import { imageBasePath, appBasePath } from '../../../constants/staticData';
import LocationSearchQuickBox from "../../common/LocationSearchQuickBox";


class QuickWashSteps extends Component {
  constructor(props) {
    super(props);
    let momentdate = moment().add(1,'hours');
    let momentPicker = new Date(momentdate._d);

    this.state = {
      step1Active: false,
      step2Active: null,
      PrefferedLocation: 'Select Location',
      selectedDate: moment().add(1, 'hours'),
      heating: false,
      step1Panel: true,
      step2Panel: false,
      catDescriptionModalVisible: false,
      selectedCarCategoryForModel: undefined,
      visibleCategory: undefined,
      now: moment().add(1, 'hours'),
      dateValue: momentPicker.getDate(),
      monthValue: momentPicker.getMonth(),
      yearValue: momentPicker.getFullYear(),
      specialInstruction: '',
      hoursValue: moment().add(1, 'hours').hours(),
      minutesValue: moment().minutes(),
      selectedCarProfileId: '',
      submissionError: false,
      selectedLocation: '',
      searchValue: '',
      currentTime: '',
      selectedTimeValue: '',
      isButtonLoading: false,

    };
    this.formData = {
      selectedLocation: '',
      selectedCarProfileId: this.state.selectedCarProfileId,
      timeValid: moment().add(1, 'hours')
    };
    this.errors = {
      selectedLocation: false,
      selectedCarProfileId: false,
      timeValid: false,
    };
    this.handleChange = this.handleChange.bind(this);



  }

  componentWillReceiveProps(nextProps) {




    const { selectedCarWashDataArray } = this.props;

    console.log("selectedCarWashDataArray"+selectedCarWashDataArray);

    this.setState({ selectedCarWashDataArray: selectedCarWashDataArray});







    if (nextProps.editClicked && nextProps.editClicked != this.state.step1Panel) {
      this.setState({ step1Panel: true, step2Panel: false, isButtonLoading: false, });
    } else if (nextProps && nextProps.showError && this.state.isButtonLoading) {


     // console.log("nextProps.showError --",nextProps.showError)
      this.props.deactivateLoader();

      this.setState({ isButtonLoading: false, });

    }


    if(this.props.selectedCarProfile){


      this.setState({
        selectedCarProfileId: this.props.selectedCarProfile.id
      });
      this.errors['selectedCarProfileId'] = false;
      this.formData['selectedCarProfileId'] = true;

    }


  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfileId: carProfile.id
    });
    this.props.changeCarProfile(carProfile);
    this.errors['selectedCarProfileId'] = false;
    this.formData['selectedCarProfileId'] = true;
  }

  createProfile(event) {
    if (event.target.value === "createProfile") {
      this.props.router.push(`${appBasePath}/car-profiles/create`);
    }
  }

  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }


  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      'selectedCarCategoryForModel': categoryDetails,
      'catDescriptionModalVisible': true
    });
  }

  specialIns(data) {
    if (data.target.value.length) {
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
    else {
      this.setState({
        ...this.state,
        specialInstruction: data.target.value,
      });
    }
  }

  setLocation(location) {
    this.errors['selectedLocation'] = false;
    this.formData['selectedLocation'] = location;
    this.setState({
      ...this.state,
      selectedLocation: location
    });
  }

  timePicker(value) {
    if (value && value.hours() && value.minutes()) {
      if (moment().date() === this.state.dateValue && moment().month() === this.state.monthValue && moment().year() === this.state.yearValue) {
        if (value.startOf('minutes') < moment().add(1, 'hours').startOf('minutes')) {
          this.formData['timeValid'] = '';
          this.errors['timeValid'] = true,
            this.setState({
              submissionError: true,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        } else {
          this.formData['timeValid'] = value;
          this.errors['timeValid'] = false,
            this.setState({
              submissionError: false,
              selectedTimeValue: value,
              hoursValue: value.hours(),
              minutesValue: value.minutes(),
            });
        }
      } else {
        this.formData['timeValid'] = value;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
            selectedTimeValue: value,
            hoursValue: value.hours(),
            minutesValue: value.minutes(),
          });
      }
    }
  }

  handleChange(date) {
    let datePicker = new Date(date._d);
    let dateValue = datePicker.getDate();
    let monthValue = datePicker.getMonth();
    let yearValue = datePicker.getFullYear();
    this.setState({
      ...this.state,
      selectedDate: date,
      dateValue: dateValue,
      monthValue: monthValue,
      yearValue: yearValue,
    });
    if (moment().date() === datePicker.getDate() && moment().month() === datePicker.getMonth() && moment().year() === datePicker.getFullYear()) {
      if (this.state.selectedTimeValue < moment().add(1, 'hours')) {
        this.formData['timeValid'] = '';
        this.errors['timeValid'] = true,
          this.setState({
            submissionError: true,
          });
      } else {
        this.formData['timeValid'] = true;
        this.errors['timeValid'] = false,
          this.setState({
            submissionError: false,
          });
      }
    } else {
      this.formData['timeValid'] = true;
      this.errors['timeValid'] = false,
        this.setState({
          submissionError: false,
        });
    }
  }


  requestQuotesValidate(){
    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }

    console.log("00000",this.errors);

    if (!validForm) {
      this.setState({ submissionError: true, });

      return false;
    }
    return true;
  }
  requestQuotes() {


    const { actions } = this.props;


    let formData = {
      ...this.formData
    };
    let validForm = true;
    for (const key in formData) {
      if (!formData[key]) {
        this.errors[key] = true;
        validForm = false;
      } else {
        this.errors[key] = false;
      }
    }


    if (!validForm) {
      this.setState({ submissionError: true, });
      return false;
    } else {

      if(this.state.selectedLocation && this.state.selectedLocation.lat && this.state.selectedLocation.lng){


        this.setState({ submissionError: false, isButtonLoading: true, step2Active: true });
        let timeStamp = new Date(this.state.yearValue, this.state.monthValue, this.state.dateValue, this.state.hoursValue, this.state.minutesValue);
        let momentTimeStamp = moment.utc(timeStamp).format();
        let payload;
        if (this.props.garageProfileDetails) {
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "vendorid": this.props.garageProfileDetails.id,
            "subserviceid": this.state.selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        } else {
          payload = {
            "batch_id": uniqid(),
            "serviceid": 1,
            "subserviceid": this.state.selectedCarWashDataArray,
            "vehicleid": this.state.selectedCarProfileId,
            "details": this.state.specialInstruction,
            "userpreffereddatetime": momentTimeStamp,
            "location": this.state.selectedLocation.formatted_address,
            "latitude": this.state.selectedLocation.lat.toString(),
            "longitude": this.state.selectedLocation.lng.toString(),
            "isurgentrequest": false,
          };
        }

        if(this.props.selectedPromoCode){
          payload.promo_code = this.props.selectedPromoCode;
        }

        this.setState({isButtonLoading: true})

        this.props.activateLoader();


        this.props.actions.createQuickBookingRequest(payload);
      }
    }
  }



  render() {
    console.log('🔥 🔥 step2Panel -- ',this.state.step2Panel);
    console.log('🔥 🔥 🔥 categorisSelected -- ',this.props.categoriesSelectedCount);
    const {router} = this.props;

    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "11px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        borderTop: 'none',
        maxHeight: '250px',
        overflow: 'auto',
        boxShadow: '0 0 4px 0 rgba(134,134,134,0.5)',
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      },
      promoButtonDiv: {
        padding: '0px'
      },

    };
    // console.log("formData",this.formData)
    // console.log("errorData",this.errors)
    // const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails } = this.props;
    const { servicesFetched, carProfiles, selectedCarProfile, categoriesSelectedCount, commonReducer, actions, garageProfileDetails, promotionalOffersData } = this.props;
    const markerLocation = [{
      lat: this.state.selectedLocation && Number(this.state.selectedLocation.lat), lng: this.state.selectedLocation && Number(this.state.selectedLocation.lng),
      pinImage: `${imageBasePath}/images/drag-loc.png`,
    }];

    return (
      <div className="panel-section car-wash quick-booking-panel car-category-wrapper p-panel">
        <section className={this.props.hideStep1Panel? "collapse-panel hide" : "collapse-panel "}>
          <div className="panel-head">
            <h4>Car Wash</h4>

          </div>
          {servicesFetched ? this.state.step1Panel && <div className="panel-content">
            {this.props.viewBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{this.props.viewBlock}</div>
              </div>
              : <ServiceTypeEmptyState/>
            }

          </div> :
            <PageLoader />}
        </section>
        <section className={this.props.hideStep1Panel && ! this.props.showSummary ?"collapse-panel " : "collapse-panel hide "}>
          {! this.state.hideStep1Panel && categoriesSelectedCount > 0 &&
          <div className="panel-content">
            <div className="row">
              <div className="col-md-12 pad0">

                <div className="form-section location-section">
                  <h4 className="panel-sub-title">Location</h4>
                  <label>We will get quotes from nearby car workshops</label>
                  <div className={this.errors.selectedLocation && this.state.submissionError ? "model-select form-group error" : "model-select form-group"}>
                    <div className="location-dd header-search ws-form-section">
                      <LocationSearchQuickBox style={style} placeholder="Select your Location" commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.setLocation(location); }} defaultSearchTerm={(this.state.selectedLocation && this.state.selectedLocation.label) ? this.state.selectedLocation.label : undefined} />
                    </div>
                    {this.errors.selectedLocation && this.state.submissionError && <span className="error-text">Select your Location</span>}
                  </div>
                  {this.state.selectedLocation && <div className="map-panel">
                    <div className="gmaps">
                      <Gmaps
                        center={{ lat: Number(this.state.selectedLocation.lat), lng: Number(this.state.selectedLocation.lng) }}
                        zoom={12}
                        setCenter={true}
                        markers={markerLocation}
                        containerElement={<div style={{ height: "auto", width: 100 + '%' }} />}
                        mapElement={<div style={{ height: 192 + 'px', width: 100 + '%' }} />}
                      />
                    </div>
                  </div>}
                </div>
                      <div className="form-section">
                        <h4 className="panel-sub-title">Prefered Time & Date</h4>
                        <div className="row date-time model-select date-time-align">
                          <div className="col-md-6 padLeft0 timeDate_grid">
                            <DatePicker
                              selected={this.state.selectedDate}
                              onChange={this.handleChange}
                              minDate={moment().add(1, 'hours')}
                            />
                          </div>
                          <div className={this.errors.timeValid && this.state.submissionError ? "col-md-6 padRight0 form-group timeDate_grid error": "col-md-6 padRight0 form-group timeDate_grid"}>
                            {/* <TimeInput value={this.state.TimePickerFrom} onChange={(e) => {this.timePicker(e); this.setState({ TimePickerFrom: e })}} /> */}
                            <TimePicker
                              showSecond={false}
                              className="custom-timepicker"
                              format={'h:mm a'}
                              //disabledHours= {this.disabledHours.bind(this)}
                              //disabledMinutes= {this.disabledMinutes.bind(this)}
                              onChange={this.timePicker.bind(this)}
                              defaultValue={this.state.now}
                              use12Hours
                            />
                            {this.errors.timeValid && this.state.submissionError && <span className="error-text">Prefered Time should be greater than 1 hour from now if it is today's date</span>}
                          </div>
                        </div>
                      </div>
                        </div>

                        <div className="col-md-12 pad0">
                          <h4 className="panel-sub-title">Special Instruction</h4>
                          <p className="panel-text">
                            <FormGroup>
                              <FormControl
                              className="textAlign"
                              componentClass="textarea"
                              placeholder="Instructions text goes here"
                              onChange={(e) => { this.specialIns(e); }}
                              />
                            </FormGroup>
                          </p>
                        </div>

                        </div>
                        </div>}
                    </section>

                    <CustomModal showModal={this.state.catDescriptionModalVisible} title={this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.name} closeIcon={true} hideModal={() => { this.setState({ 'catDescriptionModalVisible': false }) }}>
                      <Modal.Body>
                        <p className="info-text">{this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.description}</p>
                      </Modal.Body>
                    </CustomModal>


                  </div>
                  );
                  }
          }

          export default QuickWashSteps;
