import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import CoverPhoto from './CoverPhoto';
import Address from './Address';
import Description from './Description';
import Offers from './Offers';
import Photos from './Photos';
import Reviews from './Reviews';
import Services from './Services';
import { Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Element, Link } from 'react-scroll';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import filter from 'lodash/filter';
import take from 'lodash/take';
import findIndex from 'lodash/findIndex';

import PageLoader from '../common/PageLoader';
import Slider from 'react-slick';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import ReactRotatingText from 'react-rotating-text';
import Button from '../common/Button';
import { queryStringtoArray, removeParameterFromUrl } from '../../helpers/index';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { imageBasePath, appBasePath } from '../../constants/staticData';
import { Helmet } from "react-helmet";
import imageExists from "image-exists";

export default class VendorProfile extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.open = this.open.bind(this);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : {};
    this.state = {
      searchMobile:false,
      locationSearchTerm: userSelectedLocation && userSelectedLocation.label ? userSelectedLocation.label : "",
      searchText: "",
      autoCompleteData: [],
      autoSuggestedList: [],
      alreadySearched: false,
      isLoading: false,
      notificationVisible: false,
      messageVisible: false,
      showModal: false,
      imageStartIndex: 0,
      companyImages: [],
      count: 0,
      topFix: false,
      selectedNav: 'overview',
      selectedMobNav: 'overview_m',
      vendorProfileCall: false
    };
    this.searchVendors = this.searchVendors.bind(this);
    this.handleSetActive = this.handleSetActive.bind(this);
    this.getVendorInformation = this.getVendorInformation.bind(this);
  }

  getInitialState() {
    return { showModal: false };
  }
  componentWillMount() {
    const vendorList = this.props.commonReducer.vendorsListFromSearch.length ? this.props.commonReducer.vendorsListFromSearch:[];
  
    if (vendorList.length > 0) {
      this.getVendorInformation();
    } else {
      this.props.actions.searchVendors('keyword','all');
    }
    window.addEventListener('scroll', this.scrollPage.bind(this));
  }

  componentDidMount() {
    this.props.actions.clearCommonReducerComponentKey();

    if (!(this.props.commonReducer.autoSuggestedList && this.props.commonReducer.autoSuggestedList.length)) {
      this.props.actions.getAutosuggestedList();
    }
    this.props.actions.clearCommonReducerComponentKey();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer.isLoaded) {
      this.setState({ isLoading: false });
      this.props.actions.clearCommonReducerComponentKey();
    }

    if(nextProps.commonReducer.vendorsListFromSearch.length> 0  && !this.state.vendorProfileCall){
      this.getVendorInformation();
    }
    if (nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.images && nextProps.commonReducer.garageProfileDetails.images.length) {
      let companyImages = cloneDeep(nextProps.commonReducer.garageProfileDetails.images);
      map(companyImages, (image, index) => {
        companyImages[index].thumbnail = image.small;
        companyImages[index].original = image.large;
      });
      this.setState({ companyImages: companyImages });
    }
    if (nextProps.commonReducer.ratingsAndReviews) {
      this.setState({ count: nextProps.commonReducer.nextPage });
    }

    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList && !this.state.autoSuggestedList.length) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    if (!localStorage.getItem('authData') && !this.state.alreadySearched) {
      if (queryStringtoArray('q')) {
        this.searchVendors(queryStringtoArray('q'), 'keyword');
        this.setState({ alreadySearched: true, serviceSearchTerm: decodeURI(queryStringtoArray('q')), searchText: decodeURI(queryStringtoArray('q')) });
      } else {
        // this.searchVendors('', 'keyword');
        const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
        let searchLocation = userSelectedLocation;
        if (searchLocation == undefined) {
          searchLocation = this.props.commonReducer.userLocation;
        } else {
          searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
        }
        if (searchLocation && searchLocation.latitude && searchLocation.longitude && (queryStringtoArray('lat'))) {
          this.props.actions.searchVendors(false, false, searchLocation, 'distance');
        }
        this.setState({ alreadySearched: true });
      }
    }

  }

  getVendorInformation(){
    const vendorList = this.props.commonReducer.vendorsListFromSearch;
    if(vendorList.length > 0){
    const garagName = this.props.router.params.vendor_name;
    const garageInfo = vendorList.find( vendor => { return vendor.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase() === garagName.toLowerCase()});
    var garageId = garageInfo.id;
    if(garageId){
      this.setState({ isLoading: true, vendorProfileCall: true });
      this.props.actions.getGarageProfileDetails(garageId);
      this.props.actions.getRatingsAndReviews(garageId, 0, 10, 'rating=-1', false);
      if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
        this.props.actions.getAllServices();
      }
    }
   }
  }

  setUserLocation(location, searchTerm) {
    const that = this;
    let geoCodeParams = {};
    if (location && ((location.lat && location.lng) || location.place_id)) {
      if (location && location.lat && location.lng) {
        geoCodeParams['location'] = { 'lat': Number(location.lat), 'lng': Number(location.lng) };
      } else if (location.place_id) {
        geoCodeParams['placeId'] = location.place_id;
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(geoCodeParams, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results && results.length) {
          this.setState({ 'userLocation': results[0], 'locationSearchTerm': searchTerm || location.description || results[0].formatted_address, 'showLocationDropdown': false });
          that.props.actions.setCurrentLocation({
            'lat': results[0].geometry.location.lat(),
            'lng': results[0].geometry.location.lng(),
            'formatted_address': results[0].formatted_address,
            'label': searchTerm || location.description || results[0].formatted_address
          })
        } else {
          this.setState({ 'showLocationDropdown': false });
        }
      });
    }
  }

  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == "keyword") {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, "distance");
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == "autosuggest") {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, "distance");
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener('scroll', this.scrollPage.bind(this));
  }

  _onThumbnailClick(event, index) {
  }
  open(selectedIndex) {
    if (this.state.companyImages.length) {
      let companyImages = cloneDeep(this.props.commonReducer.garageProfileDetails.images);
      map(companyImages, (image, index) => {



        let thumbnail = '';

        imageExists(image.small, function(exists) {

          if (! exists) {
            if(image.small){
              imageExists(image.medium, function(exists) {

                if (! exists) {
                  if(image.medium){
                    thumbnail = image.large;
                  }

                }else{
                  thumbnail = image.medium;
                }


              });
            }

          }else{
            thumbnail = image.small;
          }
        });
        companyImages[index].thumbnail = thumbnail;
        companyImages[index].original = image.large;
      });
      this.setState({
        companyImages: companyImages,
        showModal: true,
        imageStartIndex: selectedIndex ? selectedIndex : 0
      });
    } else {
      this.setState({
        showModal: false
      });
    }
  }
  renderRightNav(onClick, disabled) {
    return (
      <button
        className='image-gallery-right-nav'
        disabled={disabled}
        onClick={() => {
          onClick();
        }} />
    );
  }
  renderLeftNav(onClick, disabled) {
    return (
      <button
        className='image-gallery-left-nav'
        disabled={disabled}
        onClick={() => {
          onClick();
        }} />
    );
  }
  handleImageLoad() { }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  close() {
    this.setState({ showModal: false });
  }

  // scrollPage() {
  //   if (document.getElementsByClassName('vendor-description')[2] && window.pageYOffset > document.getElementsByClassName('vendor-description')[2].offsetHeight - 100) {
  //     this.setState({ topFix: true });
  //   } else {
  //     this.setState({ topFix: false });
  //   }

  // }
  scrollPage() {
    if ((document.getElementsByClassName('vendor-base-header') && document.getElementsByClassName('vendor-base-header').length && window.pageYOffset > document.getElementsByClassName('vendor-base-header')[0].offsetHeight + 370)) {
      this.setState({ topFix: true });
    } else if (this.state.topFix) {
      this.setState({ topFix: false });
    }
  }

  autocompleteLocation(e) {
    let that = this;
    const searchTerm = e.target.value;
    if (searchTerm) {
      that.setState({
        'locationSearchTerm': searchTerm
      });
      let places = new google.maps.places.AutocompleteService();
      places.getPlacePredictions({ 'input': searchTerm }, function (res) {
        that.setState({
          'autoCompleteData': res
        });
      });
    } else {
      localStorage.removeItem('userSelectedLocation');
      this.props.actions.setCurrentLocation({});
      that.setState({
        'locationSearchTerm': searchTerm,
        'autoCompleteData': []
      });
    }
  }

  handleSetActive(to) {
    if (to && to.indexOf('_m') > -1) {
      this.setState({ 'selectedNav': to });
    } else {
      this.setState({ 'selectedNav': to });
    }
  }


  render() {
    const { commonReducer, actions, router,bookingRequestReducer } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      responsive: [{
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3

        }
      }]
    };
    let searchView = filter(this.state.autoSuggestedList, (val) => {
      if (this.state.searchText != "" && val.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
        return val;
      }
    });
    searchView = take(searchView, 5);
    const searchAutoSuggestedList = map(searchView, (val, key) => {
      return (
        <MenuItem onClick={() => {
          // this.searchVendors(val, 'autosuggest');
          this.setState({ 'searchText': val.name });
        }} eventKey={key} key={key}>{val.name}</MenuItem>
      );
    });

    let locationData = [{ "textPlaceholder": "Current Location" }]
    map(this.state.autoCompleteData, (location, key) => {
      return (
        locationData.push({ "key": key, "description": location.description, 'place_id': location.place_id })
      );
    });
    map(this.props.commonReducer && this.props.commonReducer.userLocations, (location, key) => {
      return (
        locationData.push({ "key": key, 'lat': location.lat, 'lng': location.lng, 'label': location.label, 'id': location.id, 'formatted_address': location.location })
      );
    });
    const locationAutosuggestedList = map(locationData, (val, key) => {
      return (
        <MenuItem onClick={(item, ) => {
          if (item.target.text != "Current Location") { this.setUserLocation(val, item.target.text) }
        }} eventKey={key} key={key}>{val.description ? val.description : val.label ? val.label : val.textPlaceholder}</MenuItem>
      );
    });
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{commonReducer.garageProfileDetails.companyprofilename}</title>
          <meta name="description"
          content={commonReducer.garageProfileDetails.about}
          />
        </Helmet>
        {/*Header*/}
        {!localStorage.getItem('authData') ? <div id="landing" ref="landing">
          <nav className="navbar navbar-default default-header search-result-header" id="header-section1">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={() => { this.setState({ 'navbarVisible': !this.state.navbarVisible }) }}>
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand desk-logo" onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/'); }}>
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                </a>
                {/*<a className="navbar-brand mobile-logo" href="https://www.carcility.com" onClick={(e) => { e.preventDefault(); this.props.router.push('/'); }}>
                  <img src="/images/logo.svg" />
                </a>*/}
              </div>
              <div id="navbar" className={this.state.navbarVisible ? "navbar-collapse" : "navbar-collapse collapse"}>
                <ul className="nav navbar-nav navbar-right">
                  <li className="work-active">
                    <Link to="how-works" smooth={true} offset={-100} duration={600}>
                      how it works
                  </Link>
                    <label></label>
                  </li>
                  <li>
                    <a href="/business">
                      For Business
                  </a>
                    <label></label>
                  </li>
                  <li>
                    <a onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/sign-in'); }}>Log In </a>
                    <label></label>
                  </li>
                  <li>
                    <a onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/register'); }}> Sign Up</a>
                    <label></label>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-default scroll-header search-result-header" id="header-section2">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={() => { this.setState({ 'navbarVisible': !this.state.navbarVisible }) }}>
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/'); }}>
                  <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" />
                </a>
              </div>
              <div id="navbar" className={this.state.navbarVisible ? "navbar-collapse" : "navbar-collapse collapse"}>
                <ul className="nav navbar-nav nav-left">
                  <li>
                    <div className="banner-search">
                      <div className="search-wrapper">
                        <form className="form-inline">
                          <DropdownButton bsSize="small" id="dropdown-size-small" noCaret onToggle={(e) => this.setState({ locateMeDD: e })}
                            onSelect={(id, e) => this.setState({ locationSearchTerm: e.target.innerText })}
                            className="location-search" title={
                              <span className="search-left">
                                <i className="mdi mdi-crosshairs-gps"></i>
                                <input type="text" className="form-control" placeholder="Locate Me" value={this.state.locationSearchTerm} onChange={(e) => { e.preventDefault(); this.autocompleteLocation(e); this.setState({ 'locationSearchTerm': e.target.value }) }} />
                                <i className={this.state.locateMeDD ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
                              </span>

                            }>
                            {locationAutosuggestedList}
                          </DropdownButton>
                          <DropdownButton bsSize="small" id="dropdown-size-small"
                            noCaret
                            onToggle={(e) => this.setState({ serviceDD: e })}
                            open={this.state.searchText == "" ? false : this.state.serviceDD}
                            onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                            onSelect={(id, e) => {
                              this.setState({ serviceSearchTerm: e.target.innerText });
                            }}
                            className="location-search search-service-block" title={
                              <span className="search-right" onClick={() => this.setState({ blinkText: false })}>
                                <input type="text" ref={(focusFunction) => this.inputFocus = focusFunction} className="form-control" onChange={(e) => { e.preventDefault; const serviceSearchTerm = e.target.value; this.setState({ 'serviceSearchTerm': serviceSearchTerm }) }} value={this.state.serviceSearchTerm} />
                                <input className={'hide'} type="submit" onClick={(e) => { e.preventDefault(); this.searchVendors(this.state.searchText, 'keyword'); }} />
                                <span onClick={() => { this.inputFocus.focus() }} className={this.state.blinkText ? "blink-text" : "blink-text hide"}>
                                  <ReactRotatingText items={["What service do you need today?"]} />
                                </span>
                                <i className="mdi mdi-magnify" onClick={() => { this.searchVendors(this.state.searchText, 'keyword'); }} />
                              </span>

                            }>
                            {searchAutoSuggestedList}
                          </DropdownButton>
                        </form>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Button isSubmitBtn={true} btnCallBack={() => { this.props.router.push(appBasePath + '/vendor-profile/'+commonReducer.garageProfileDetails.id+'-'+commonReducer.garageProfileDetails.companyprofilename+'/book-now'); }} btnType="red" btnSize="sm" fontSize={13} label="Get Started" />
                  </li>
                  <li className="menu-option" onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen, sidebarProfileDropdown: false })}>
                    <i className="mdi mdi-menu"></i>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div> : ''}
        {localStorage.getItem('authData') ? <Header hideOption={()=>this.setState({searchMobile:true})} bookingRequestReducer = {bookingRequestReducer} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/> : ''}
        {localStorage.getItem('authData') ? <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} /> : ''}
        {localStorage.getItem('authData') ? <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} /> : ''}
        <div className={localStorage.getItem('authData') ? "main-wrapper" : "main-wrapper no-padding no-margin"}>
          {/*Sidebar*/}
          {localStorage.getItem('authData') ? <Sidebar router={this.props.router} /> : ''}
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Business Profile</h4>
            </div>
          </div>
          {!this.state.isLoading ? <div className="inSection">
            <div className="padwrapper">
              <CoverPhoto actions={actions} garageProfileDetails={commonReducer.garageProfileDetails} />
              <div>
                <div className={this.state.topFix ? "vendor-base-header active" : "vendor-base-header"}>
                  <div className="fixed-header-profile">
                    <span className="fixed-header-profile-pic">
                      <img src={commonReducer.garageProfileDetails.logo ? commonReducer.garageProfileDetails.logo : `${imageBasePath}/images/placeholder.png`} alt="" />
                    </span>
                    <h4>{commonReducer.garageProfileDetails.companyprofilename}</h4>
                  </div>
                  <ul className="bh_desk-view">
                    <li className={this.state.selectedNav == "overview" ? "active" : ""}>
                      <Link to="overview" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Overview</Link>
                    </li>
                    <li className={this.state.selectedNav == "services" ? "active" : ""}>
                      <Link to="services" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Services</Link>
                    </li>
                    <li className={this.state.selectedNav == "reviews" ? "active" : ""} >
                      <Link to="reviews" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Reviews</Link>
                    </li>
                  </ul>
                  <ul className="bh_mobile-view">
                    <Slider {...settings}>
                      <li className={this.state.selectedMobNav == "overview_m" ? "active" : ""}>
                        <Link to="overview" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Overview</Link>
                      </li>
                      <li className={this.state.selectedMobNav == "services_m" ? "active" : ""}>
                        <Link to="services_m" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Services</Link>
                      </li>
                      <li className={this.state.selectedMobNav == "reviews_m" ? "active" : ""} >
                        <Link to="reviews_m" spy={true} smooth={true} offset={this.state.topFix ? -150 : 0} onSetActive={this.handleSetActive}>Reviews</Link>
                      </li>
                    </Slider>
                  </ul>
                </div>
                <div className={this.state.topFix ? "vendor-profile-section row active" : "vendor-profile-section row"}>
                  <div className="vp-desk-view">
                    <div className="col-md-8 vp_left">
                      <Element name="overview">
                        <Description garageProfileDetails={commonReducer.garageProfileDetails} />
                      </Element>
                      <Element name="services">
                        <Services
                          bookingRequestReducer={this.props.bookingRequestReducer}
                          router={router}
                          garageProfileDetails={commonReducer.garageProfileDetails}
                          garageServices={commonReducer.garageProfileDetails.garage_services}
                          garageManufacturers={commonReducer.garageProfileDetails.garage_mfrs}
                        />
                      </Element>
                      <Element name="reviews">
                        <Reviews commonReducer={commonReducer} actions={actions} />
                      </Element>
                    </div>
                    <div className="col-md-4 vp_right">
                      <Photos openModal={(index) => { this.open(index) }} garageImages={commonReducer.garageProfileDetails.images} />
                      <Address garageProfileDetails={commonReducer.garageProfileDetails} />
                      <Element name="offers">
                        <Offers />
                      </Element>
                    </div>
                  </div>
                  <div className="vp-mobile-view">
                    <Element name="overview_m">
                      <Description garageProfileDetails={commonReducer.garageProfileDetails} />
                    </Element>
                    <Element name="services_m">
                      <Services
                        bookingRequestReducer={this.props.bookingRequestReducer}
                        router={router}
                        garageProfileDetails={commonReducer.garageProfileDetails}
                        garageServices={commonReducer.garageProfileDetails.garage_services}
                        garageManufacturers={commonReducer.garageProfileDetails.garage_mfrs}
                      />
                    </Element>
                    <Photos openModal={(index) => { this.open(index) }} garageImages={commonReducer.garageProfileDetails.images} />
                    <Address garageProfileDetails={commonReducer.garageProfileDetails} />
                    <Element name="offers_m">
                      <Offers />
                    </Element>
                    <Element name="reviews_m">
                      <Reviews commonReducer={commonReducer} actions={actions} />
                    </Element>
                  </div>
                </div>
              </div>
            </div>
          </div> : <PageLoader />}
        </div>
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        <Modal className="box-modal" bsSize="large" show={this.state.showModal}>
          <Modal.Body>
            <div className="close-modal">
              <i className="mdi mdi-close" onClick={this.close.bind(this)} />
            </div>
            <div className="image-modal remove-upload">
              {/* <div className="modal-option text-right">
                <ul className="list-unstyled">
                  <li>
                    <a href="">
                      Set as Cover
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="mdi mdi-delete" />
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <div className="modal-upload">
                <label htmlFor="garageImageUpload">
                  <i className="mdi mdi-attachment" />
                </label>
                <input id="garageImageUpload" type="file" className="hide" onChange={(e) => this.fileNameUpload(e)} multiple />
              </div> */}
              <ImageGallery
                ref={i => this._imageGallery = i}
                items={this.state.companyImages}
                slideInterval={2000}
                showFullscreenButton={false}
                showPlayButton={false}
                lazyLoad={true}
                renderRightNav={this.renderRightNav.bind(this)}
                renderLeftNav={this.renderLeftNav.bind(this)}
                onImageLoad={this.handleImageLoad}
                startIndex={this.state.imageStartIndex}
                onThumbnailClick={this._onThumbnailClick.bind(this)} />
            </div>
          </Modal.Body>
        </Modal>
        {/* sidebar menu */}
        <div className={this.state.sidebarOpen ? "menu-sidebar active" : "menu-sidebar inactive"}>
          {/* <Sidebar router={this.props.router} open={this.state.sidebarProfileDropdown} profileClick={()=>this.setState({sidebarProfileDropdown:!this.state.sidebarProfileDropdown})}/> */}
          <div className="nonSignup-list">
            <ul className="list-unstyled">
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/sign-in'); }}>
                <span>
                  LOG IN
                    </span>
              </li>
              <li onClick={(e) => { e.preventDefault(); this.props.router.push(appBasePath + '/register'); }}>
                <span>
                  SIGN UP
                    </span>
              </li>
              <li>
                <Link to="how-works" smooth={true} offset={-100} duration={600}>
                  <span>
                    HOW IT WORKS
                      </span>
                </Link>
              </li>
              <li onClick={(e) => { e.preventDefault(); window.location.pathname = '/business'; }}>
                <span>
                  FOR BUSINESS
                    </span>
              </li>
            </ul>
          </div>
        </div>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
