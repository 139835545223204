import React from 'react';

export default class FloatingMenuItem extends React.Component {

  handleClick() {
    this.props.action && this.props.action();
    this.props.playPromo && this.props.playPromo();
    this.props.enableOpac && this.props.enableOpac();
  }

  render() {
    return (
      <div
        onClick={this.handleClick.bind(this)}
        className={`floating-menu-item ${this.props.classShow}`}>
        {this.props.label && <label>{this.props.label}</label>}
        <div className={`${this.props.classOpenClose} floating-menu-icon `}>{this.props.children}</div>
      </div >
    );
  }
}
