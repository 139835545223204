import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import MessagesView from './MessagesView';
import PageLoader from '../common/PageLoader';
import MessageEmptyState from '../EmptyState/MessageEmptyState';
import MobileHeaderSearch from '../common/MobileHeaderSearch'

export default class Messages extends Component {
    constructor(props, context) {
        super(props, context);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.state = {
            searchMobile:false,
            notificationVisible: false,
            messageVisible: false,
            isChatRoomsLoaded: false
        };
    }
    componentWillMount() {
      if(this.props.commonReducer && !this.state.isChatRoomsLoaded) {
        this.props.actions.getAllChatRooms();
      }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.commonReducer.isChatRoomsLoaded && !this.state.isChatRoomsLoaded) {
            this.setState({ 'isChatRoomsLoaded': true });
        }
    }
    toggleNotification(isVisible) {
        this.setState({ 'notificationVisible': isVisible });
    }

    toggleMessage(isVisible) {
        this.setState({ 'messageVisible': isVisible });
    }

    // modalVisiblity() {
    //     this.setState({ showModal: true });
    // }

    render() {
        const { commonReducer, actions, router,bookingRequestReducer } = this.props;
        return (
            <div>
                {/*Header*/}
                <Header hideOption={()=>this.setState({searchMobile:true})} 
                bookingRequestReducer = {bookingRequestReducer}
                commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
                <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
                <div className="main-wrapper">
                    {/*Sidebar*/}
                    <Sidebar router={this.props.router} />
                    {/*message*/}
                    {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
                    <div className="page-sec-header">
                        <div className="padwrapper">
                            <h4>Messages</h4>
                        </div>
                    </div>
                    <div className="inSection">
                        <div className="padwrapper">
                            <div className={this.props.commonReducer.chatRooms.length > 0 ? "messageBody " : "messageBody no-content"}>
                                {this.state.isChatRoomsLoaded
                                    ? (this.props.commonReducer.chatRooms.length > 0) ? <MessagesView {...this.props} /> : <MessageEmptyState />
                                    : <PageLoader />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerSection" >
                    <Footer {...this.props}/>
                </div>
                {this.state.searchMobile && <div >

                    <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
                </div>}
            </div>
        );
    }
}
