import React, { Component } from 'react';
import {imageBasePath, metaTagData, canonicalLink, appBasePath} from '../../constants/staticData';
import { Helmet } from "react-helmet";

export default class QuickHeader extends Component {
  render() {
    const { headerClickAction, pageType, metaTagKey, type } = this.props;
    return (
      <div className= {type && type == "quick-header"? "header-section navbar-fixed-top quick-header ": "header-section navbar-fixed-top auth-header" }>
        {metaTagData ? <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData[metaTagKey] && metaTagData[metaTagKey].title ? metaTagData[metaTagKey].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData[metaTagKey] && metaTagData[metaTagKey].description ? metaTagData[metaTagKey].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet> : ''}
        <div className="logo-section desk-logo" onClick={(e) => { e.preventDefault(); window.location.pathname = '/'; }}>
          <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="logo-img" />
        </div>
        <div className="logo-section mobile-logo" onClick={(e) => { e.preventDefault(); window.location.pathname = '/'; }}>
          <img src={`${imageBasePath}/images/quick-booking/back-arrow.png`} alt="back" className="quick-back-arrow" />
          <img src={`${imageBasePath}/images/landing/car-service-in-dubai-carcility-logo.svg`} alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="quick-logo-img" />
        </div>
       {/* <ul className="nav navbar-nav navbar-left offer-nav">



          <li  className="offer-item">
            <a
              onClick={e => {
                e.preventDefault();
                this.props.router.push(appBasePath + '/all-offers');
              }}


            >
              OFFERS
            </a>
            <label />
          </li>
          <li>
            <a
              onClick={e => {
                e.preventDefault();
                this.props.router.push(appBasePath + '/sign-in');
              }}
            >
              HOW IT WORKS
            </a>
            <label />
          </li>
          <li>
            <a
              onClick={e => {
                e.preventDefault();
                this.props.router.push(appBasePath + '/register');
              }}
            >
              FOR BUSINESS
            </a>
            <label />
          </li>
        </ul>*/}
        <div className="header-right authNav-right">
          {type && type == "quick-header"?

            <ul className="list-unstyled quick-desk-right">

              <li>
                <a
                  onClick={e => {
                    this.props.router.push(`${appBasePath}/sign-in`);
                    e.preventDefault();

                  }}
                >
                  LOGIN{' '}
                </a>
              </li>
              <li>
                <a
                  onClick={e => {
                    this.props.router.push(`${appBasePath}/sign-up`);
                    e.preventDefault();

                  }}
                >
                  SIGN UP{' '}
                </a>
              </li>

            </ul>


          :
              <ul className="list-unstyled quick-desk-right">
                <li>
                  <label>
                    Already have an account?
                    <a onClick={headerClickAction}>Sign In</a>
                  </label>
                </li>

              </ul>
          }

          <div className="quick-mob-right">
            <a className="sub-content ph-no" href="tel:+971543040110">
              <img src={`${imageBasePath}/images/quick-booking/phone.png`} alt="Carcility logo" className="quick-phone" />
            </a>


          </div>
        </div>
      </div>
    );
  }
}
