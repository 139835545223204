import * as types from './actionTypes';
import * as API_END_POINTS from '../constants/api.js';
import axios from 'axios';
import { keyBy, cloneDeep, remove, findIndex } from 'lodash';
import { queryStringtoArray } from '../helpers/index';
let uniqid = require('uniqid');
import { appBasePath } from '../constants/staticData';
import { getBookingRequest } from './bookingRequestActions';
//import React from "react";

export function setCarProfileAction(carData, isEditProfile, profileId, router, batchId, imageIds,selectedPromotionalOffersData) {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_ERROR_MESSAGE
    });
    const formData = new FormData();
    let mandateFields = ['name', 'make', 'model', 'year', 'plate_no', 'mileage', 'state', 'insurancepolicynumber', 'insuranceprovider', 'carnotes', 'registrationnumber', 'chassis_number'];

    Object.keys(carData).map((value) => {
      if (carData[value] && mandateFields.indexOf(value) !== -1) {
        if (value)
          formData.append(value, carData[value]);
      }
    });

    let requestData = {
      "batch_id": batchId,
      "chassis_number": carData.chassis_number,
      "name": carData.name,
      "make": carData.make,
      "model": carData.model,
      "year": carData.year.toString(),
      "model_id": isEditProfile ? carData.modelId : carData.model_id,
      "plate_no": carData.plate_no,
      "mileage": carData.mileage,
      "images": imageIds && imageIds.length ? imageIds : [],
      "state": carData.state,
      "insurancepolicynumber": carData.insurancepolicynumber,
      "insuranceprovider": carData.insuranceprovider,
      "registrationnumber": carData.registrationnumber,
      "carnotes": carData.carnotes,
      "cylinder": Number(carData.cylinder)
    };
    // formData.append('batch_id', batchId);
    // formData.append('images', imageIds);
    const postMethod = (isEditProfile && profileId) ? axios.put : axios.post;
    // API_END_POINTS.CREATE_CAR_PROFILE + '?imageflow=1'
    postMethod(isEditProfile && profileId ? (API_END_POINTS.EDIT_CAR_PROFILES + profileId + '?imageflow=1') : API_END_POINTS.CREATE_CAR_PROFILE + '?imageflow=1', requestData, {
      withCredentials: true
    })
      .then((response) => {
        if (response.status === 200) {
          const redirect_to = (!isEditProfile && queryStringtoArray('redirect_to')) ? queryStringtoArray('redirect_to') : '';
          const vendor_id = (!isEditProfile && queryStringtoArray('vendor_id')) ? queryStringtoArray('vendor_id') : '';
          dispatch({
            type: types.SET_CAR_PROFILE,
            carData: response.data,
            redirect_to: redirect_to,
            profileId: profileId
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: "Car profile created successfully",
            toastType: 'success'
          });
          if((!isEditProfile || !profileId) && imageIds && imageIds.length && response.data){


            let isCoverImage = "";
            response.data.images.map((value) => {
              if (value.type == "vehicle") {
                isCoverImage =  value.id
              }
            });


            if(isCoverImage)
              dispatch(setVehicleCoverImage(isCoverImage, response.data.id));
          }
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: (isEditProfile && profileId) ? 'Car Profile has been updated successfully' : 'Car Profile has been created successfully',
            toastType: 'success'
          });
          if (redirect_to && vendor_id) {
            router.push(`${appBasePath}/${redirect_to}?car_id=${response.data.id}&vendor_id=${vendor_id}`);
          } else if (redirect_to) {

           // router.push(`${appBasePath}/${redirect_to}?car_id=${response.data.id}`);



            console.log("selectedPromotionalOffersData",selectedPromotionalOffersData)

            router.push({
                pathname: appBasePath+"/"+redirect_to,
                search: "?car_id="+response.data.id,
                state: { selectedPromotionalOffersData: selectedPromotionalOffersData, offerFromCreateCarProfile: true}
              }
            )


            router.push(`${appBasePath}/${redirect_to}?car_id=${response.data.id}`);
          } else {
            router.push(`${appBasePath}/car-profiles`);
          }
          
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data ? response.data.msg : 'Unknown error occurred please try again',
            toastType: 'error'
          });
        }
      }).catch((err) => {
        // dispatch({
        //   type: types.SHOW_TOAST_MESSAGE,
        //   toastMessage: err.response.data ? err.response.data : 'Unknown error. Please try later',
        //   toastType: 'error'
        // });
        if (err.response && err.response.status === 500) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : `Car plate ${carData.plate_no} already exist in the system please contact customer support.`
          });
        } else if (err.response && err.response.status && (err.response.status === 400 || err.response.status === 404 || err.response.status === 401 || err.response.status === 410)) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'Unknown error occurred please try again'
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      });
  };
}

export function getCarProfileList(router) {
  return (dispatch) => {
    axios.get(API_END_POINTS.LIST_CAR_PROFILES + '?imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          const carProfiles = keyBy(response.data, 'id');
          dispatch({
            type: types.LIST_CAR_PROFILES,
            carProfiles: carProfiles,
            unableToFetch: false
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unable to fetch the car list'
          });
          dispatch({
            type: types.LIST_CAR_PROFILES,
            unableToFetch: true
          });
        }
      }).catch((err) => {
        dispatch({
          type: types.LIST_CAR_PROFILES,
          unableToFetch: true
        });
        if (err.response && err.response.status && err.response.status === 403) {
          dispatch({
            type: types.LOGOUT
          });
          router.push(`${appBasePath}/`);
        } else if (err.response && err.response.status && (err.response.status === 404 || err.response.status === 401 || err.response.status === 410)) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: err.response.data
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unknown error occurred please try again'
          });
        }
      });
  };
}

export function getCarProfileDetails(carProfileID, router) {
  return (dispatch) => {
    axios.get(API_END_POINTS.GET_CAR_PROFILE_DETAILS + carProfileID + '?imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: types.VIEW_CAR_PROFILE,
            carProfile: response.data
          });
          dispatch(getCarServiceHistory(carProfileID, 10, 0, false));
        } else {
          dispatch({
            type: types.VIEW_CAR_PROFILE,
            unableToFetch: true
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status && err.response.status == 404) {
          router.push(`${appBasePath}/404`);
          // dispatch({
          //   type: types.VIEW_CAR_PROFILE,
          //   unableToFetch: true
          // })
        } else {
          dispatch({
            type: types.VIEW_CAR_PROFILE,
            unableToFetch: true
          });
        }
      });
  };
}

export function deleteCarProfile(carProfileID, router) {
  return (dispatch) => {
    axios.delete(API_END_POINTS.DELETE_CAR_PROFILE + carProfileID + '?imageflow=1', { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: types.DELETE_CAR_PROFILE,
            carProfileID: carProfileID
          });
          router.push(`${appBasePath}/car-profiles`);
        }
      }).catch((err) => {
        dispatch({
          type: types.SHOW_TOAST_MESSAGE,
          toastMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Unknown error occurred please try again',
          toastType: 'error'
        });
      });
  };
}

export function getCarMakeandModels() {
  return (dispatch) => {

    axios.get(API_END_POINTS.CAR_MAKE_AND_MODELS, { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            carMakeAndModels: response.data
          });
        }
      }).catch((err) => {
        console.log(err);
      });

  }
}

export function getManufacturers() {
  return (dispatch) => {
    axios.get(API_END_POINTS.GET_CAR_MANUFACTURERS, { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            manufacturers: response.data,
            responseType: 'make',
            unableToFetch: false,
          });
        } else {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            unableToFetch: true,
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data && response.data.msg ? response.data.msg : 'Unknown error occurred please try again',
            toastType: 'error'
          });
        }
      }).catch((err) => {
        if (err.config) {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            unableToFetch: true,
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Unknown error occurred please try again',
            toastType: 'error'
          });
        }
      });

  };
}

export function getCarModels(makeId) {
  return (dispatch) => {

    axios.get(API_END_POINTS.GET_CAR_MODELS + '?carmake=' + makeId, { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            models: response.data,
            responseType: 'model',
            makeId: makeId,
            unableToFetch: false,
          });
        } else {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            unableToFetch: true,
            responseType: 'model',
            makeId: makeId
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: response.data ? response.data : 'Unable to fetch car Models. Try Again',
            toastType: 'error'
          });
        }
      }).catch((err) => {
        if (err.config) {
          dispatch({
            type: types.CAR_MAKE_AND_MODELS,
            unableToFetch: true,
            responseType: 'model',
            makeId: makeId
          });
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Unable to fetch car Models. Try Again',
            toastType: 'error'
          });
        }
      });

  };
}

export function deleteVehicleImage(key, imageId, images, vehicle_id) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_VEHICLE_IMAGE,
      key: key,
      imageId: imageId,
      vehicleId: vehicle_id
    });
    if (imageId) {
      axios.delete(API_END_POINTS.DELETE_VEHICLE_IMAGE + imageId, { withCredentials: true })
        .then(() => {
          let updatedImageArray = cloneDeep(images);
          if (images.length) {
            remove(updatedImageArray, function (n) {
              return n.id == imageId;
            });
            if (updatedImageArray.length) {
              let coverImageIndex = findIndex(updatedImageArray, { isCover: true });
              if (coverImageIndex == -1 && vehicle_id) {
                dispatch(setVehicleCoverImage(updatedImageArray[0].id, vehicle_id));
              }
            }
            dispatch(getCarProfileDetails(vehicle_id));
            dispatch(getBookingRequest(vehicle_id));
          }
        });
    }
  };
}
export function setVehicleCoverImage(image_id, vehicle_id) {
  return (dispatch) => {
    axios.post(API_END_POINTS.SET_COVER_FOR_CAR_PROFILE + '?imageflow=1', {
      "vehicle_id": parseInt(vehicle_id),
      "image_id": image_id
    }, { withCredentials: true })
      .then((response) => {
        if (response.status == 200) {
          dispatch(getCarProfileDetails(vehicle_id));
          dispatch({
            type: types.SET_COVER_FOR_CAR_PROFILE,
            image_id: image_id
          });
        } else {
          // dispatch({
          //   type: types.SHOW_ERROR_MESSAGE,
          //   statusMessage: 'Unable to fetch the car list'
          // });
        }
      })
      .catch((err) => {
        if (err) {
          // dispatch({
          //   type: types.SHOW_ERROR_MESSAGE,
          //   statusMessage: 'Unable to fetch the car list'
          // })
        }
      });
  };
}

export function uploadCarProfilePicture(imageUploaded, batch_id, totalImageLength, type) {
  return (dispatch) => {
    imageUploaded.map((value) => {
      const formData = new FormData();
      formData.append('batch_id', batch_id);
      formData.append('photos', value);
      formData.append('type', type);
      axios.post(API_END_POINTS.UPLOAD_VEHICLE_IMAGE, formData, {
        headers: {
          'Accept': 'application/json,',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }).then((response) => {
        if (response.status == 200 && response.data) {
          let uploadedImage = {
            isUploaded: true,
            isFailed: false,
            id: response.data.id,
            localUniqueId: value.localUniqueId,
            thumbnail: response.data.small,
            original: response.data.large,
            large: response.data.large,
            type
          };
          dispatch({
            type: types.UPLOAD_VEHICLE_IMAGE_SUCCESS,
            batch_id: batch_id,
            image: uploadedImage,
            totalImageLength: totalImageLength
          });
        }
      }).catch((e) => {
        let uploadedImage = {
          isUploaded: false,
          isFailed: true,
          thumbnail: URL.createObjectURL(value),
          localUniqueId: value.localUniqueId,
          totalImageLength: totalImageLength
        };
        dispatch({
          type: types.UPLOAD_VEHICLE_IMAGE_FAILED,
          batch_id: batch_id,
          image: uploadedImage
        });
      });
    });

  };
}

export function uploadCarProfilePictureTest(value, batch_id, imageId, success, imageType) {
  return (dispatch) => {
    const localUniqueId = imageType === 'CSN' ? `${value.localUniqueId}_chassisNumber` : value.localUniqueId;
    if (success) {
      let uploadedImage = {
        isUploaded: true,
        isFailed: false,
        id: imageId,
        localUniqueId,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        imageType
      };
      // Dispact the Type based on chassisNumber is set of not
      const type = imageType === 'CSN' ? types.UPLOAD_CHASSIS_IMAGE_SUCCESS : types.UPLOAD_VEHICLE_IMAGE_SUCCESS;

      dispatch({
        type,
        batch_id: batch_id,
        image: uploadedImage
      });
    } else {
      let uploadedImage = {
        isUploaded: false,
        isFailed: true,
        thumbnail: URL.createObjectURL(value),
        localUniqueId
      };
      dispatch({
        type: types.UPLOAD_VEHICLE_IMAGE_FAILED,
        batch_id: batch_id,
        image: uploadedImage
      });
    }
  };
}

export function uploadCarProfileViaJobs(value, batch_id, imageId, success, imageType) {
  return (dispatch) => {
    const localUniqueId = imageType === 'CSN' ? `${value.localUniqueId}_chassisNumber` : value.localUniqueId;
    if (success) {
      let uploadedImage = {
        isUploaded: true,
        isFailed: false,
        id: imageId,
        localUniqueId,
        thumbnail: URL.createObjectURL(value),
        original: URL.createObjectURL(value),
        large: URL.createObjectURL(value),
        imageType
      };

      dispatch({
        type: types.UPLOAD_VEHICLE_IMAGE_SUCCESS,
        batch_id: batch_id,
        image: uploadedImage
      });
    } else {
      let uploadedImage = {
        isUploaded: false,
        isFailed: true,
        thumbnail: URL.createObjectURL(value),
        localUniqueId
      };
      dispatch({
        type: types.UPLOAD_VEHICLE_IMAGE_FAILED,
        batch_id: batch_id,
        image: uploadedImage
      });
    }
  };
}


export function createCarProfileBatchId(batchID) {
  return (dispatch) => {
    const batchId = batchID ? batchID : uniqid();
    dispatch({
      type: types.CREATE_CAR_PROFILE_BATCH_ID,
      batch_id: batchId
    });
  };
}

export function clearCarProfileBatchId() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_CAR_PROFILE_BATCH_ID
    });
  };
}

export function getCarServiceHistory(vehicleId, limit, offset, fromLoadMore) {
  return (dispatch) => {
    axios.get(API_END_POINTS.GET_CAR_SERVICE_HISTORY + vehicleId + '/servicehistory?offset=' + offset + '&limit=' + limit, { withCredentials: true })
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch({
            type: types.GET_CAR_SERVICE_HISTORY,
            serviceHistory: response.data,
            unableToFetch: false,
            fromLoadMore: fromLoadMore,
            vehicleId: vehicleId
          });
        } else {
          dispatch({
            type: types.GET_CAR_SERVICE_HISTORY,
            unableToFetch: true,
          });
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: 'Unable to fetch the service History'
          });
        }
      }).catch((err) => {
        dispatch({
          type: types.GET_CAR_SERVICE_HISTORY,
          unableToFetch: true,
        });
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Unknown error occurred please try again'
        });
      });
  };
}
