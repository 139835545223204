import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import filter  from 'lodash/filter';
import map  from 'lodash/map';
import sumBy  from 'lodash/sumBy';
import indexOf  from 'lodash/indexOf';
import InfiniteScroll from 'react-infinite-scroller';
import PageLoader from '../common/PageLoader';
import NotificationEmptyState from '../EmptyState/NotificationEmptyState';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { appBasePath } from '../../constants/staticData';

export default class CarWash extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile: false,
      notificationVisible: false,
      messageVisible: false,
      isNotificationsLoaded: this.props.commonReducer.notificationsPageInfo ? true: false
    };
    this.renderNotificationRow = this.renderNotificationRow.bind(this);
  }
  componentWillMount() {
    if (!this.props.commonReducer.notificationsPageInfo) {
      this.props.actions.getNotificationAlerts(0, 10);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.isNotificationsLoaded && !this.state.isNotificationsLoaded) {
      this.setState({
        'isNotificationsLoaded': true
      });
    }
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }


  loadMoreNotificationCallback() {
    // const nextPage = this.props.commonReducer.notificationsPageInfo.page + 1;
    this.props.actions.getNotificationAlerts(this.props.commonReducer.notifications.length, 10);
  }
  renderNotificationRow(notification, key) {
    let quoteAmount = 0;
    if (notification.services && notification.services.length > 0) {
      quoteAmount = sumBy(notification.services, 'amount');
    }
    let messageRead = false;
    if (indexOf(this.props.commonReducer.markedNotifications, notification.alertId) > -1) {
      messageRead = true;
    }
    return (
      <div className="notification-card" key={key} onClick={(e) => {
        e.preventDefault();
        if (!notification.isRead && notification.alertId) {
          this.props.actions.markNotificationAsRead(notification.alertId);
        }
        if (notification.bookingId) {
          this.props.router.push(`${appBasePath}/request/${notification.bookingId}`);
        } else if (notification.garageId) {
          this.props.router.push(`${appBasePath}/vendor-profile/${notification.garageName.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`);
        }
      }}>
        <span>
          <i className={"mdi mdi-car"} />
        </span>
        <p>
          {notification.msg}
          {/* {`${notification.garageName} has sent ${notification.type} (Quote Amount: ${quoteAmount} AED,  Booking ID: ${notification.bookingId})`} */}
        </p>
        <label>{`${notification.createdDate} ${notification.createdTime}`}</label>
      </div>
    );
  }

  render() {
    const { commonReducer, actions, router } = this.props;
    const todayNotifications = filter(commonReducer.notifications, (notification) => { return notification.category == 'today' });
    const yesterdayNotifications = filter(commonReducer.notifications, (notification) => { return notification.category == 'yesterday' });
    const oldNotifications = filter(commonReducer.notifications, (notification) => { return notification.category == 'old' });
    const todayNotificationsView = map(todayNotifications, (notification, key) => {
      if (!notification.isRead) {
        return this.renderNotificationRow(notification, key);
      }
    });
    const yesterdayNotificationsView = map(yesterdayNotifications, (notification, key) => {
      if (!notification.isRead) {
        return this.renderNotificationRow(notification, key);
      }
    });
    const oldNotificationsView = map(oldNotifications, (notification, key) => {
      if (!notification.isRead) {
        return this.renderNotificationRow(notification, key);
      }
    });
    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={() => this.setState({ searchMobile: true })} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Notification</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper page-padwrapper">
              <div className="row notification-wrapper">
                <div className="notiy-section">
                  {this.state.isNotificationsLoaded ?
                    commonReducer.unableToFetchNotifications
                      ? <div><h3>Unable to fetch notifications <a style={{ cursor: 'pointer' }} onClick={() => { this.props.actions.getNotificationAlerts(5) }}>Try Again</a></h3></div>
                      : (commonReducer.notifications && commonReducer.notifications.length > 0) ?
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={this.loadMoreNotificationCallback.bind(this)}
                          hasMore={(commonReducer.notificationsPageInfo && commonReducer.notificationsPageInfo.rowCount > commonReducer.notifications.length)}
                          loader={<div className="center"><PageLoader /></div>}>
                          <div>
                            {(todayNotifications && todayNotifications.length) ?
                              <div className="notiy-section"><h5>Today</h5>{todayNotificationsView} </div> : ''}
                            {(yesterdayNotifications && yesterdayNotifications.length) ?
                              <div className="notiy-section"><h5>Yesterday</h5>{yesterdayNotificationsView} </div> : ''}
                            {(oldNotifications && oldNotifications.length) ?
                              <div className="notiy-section"><h5>Older</h5>{oldNotificationsView} </div> : ''}
                          </div>
                        </InfiniteScroll>
                        : (<NotificationEmptyState />)
                    : <PageLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
        </div>}
      </div>
    );
  }
}
