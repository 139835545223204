import React, { Component } from 'react';
import { DropdownButton, MenuItem, Media } from 'react-bootstrap';
import map  from 'lodash/map';
import moment from 'moment';
import PageLoader from '../common/PageLoader';
import MessageEmptyState from '../EmptyState/MessageEmptyState';
import { appBasePath } from '../../constants/staticData';
import { defaultImageOnError } from '../../helpers'; 

export default class MessagesDropDownView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageSelected: false,
      isMessagesLoaded: false
    };
  }
  componentWillMount() {
    if (!this.props.commonReducer.isMessagesLoaded) {
      this.props.actions.getAllUnreadMessages();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.commonReducer && nextProps.commonReducer.isMessagesLoaded && !this.state.isMessagesLoaded) {
      this.setState({
        'isMessagessLoaded': true
      });
    }
  }
  // messageSeen() {
  //   window.socket.emit('message-seen', );
  //   this.props.actions.messageSeen()
  // }
  render() {
    const { commonReducer, router } = this.props;
    const messagesView = map(commonReducer.unreadMessages, (message, key) => {
      const logoUrl = (message.user && message.user.profilePhoto && message.user.profilePhoto.trim().length > 0) ? message.user.profilePhoto : '';
      const messageTimeStamp = moment(message.createdAt);
      const currentTimeStamp = moment(new Date());
      const timeDuration = moment.duration(currentTimeStamp.diff(messageTimeStamp));
      const days = Math.ceil(timeDuration.asDays());
      let timeStampString = '';
      if (days == 1) {
        if (timeDuration.asSeconds() < 60 ) {
          timeStampString = `${Math.round(timeDuration.asSeconds())} seconds ago`
        } else if (timeDuration.asMinutes() < 60) {
          timeStampString = `${Math.round(timeDuration.asMinutes())} minutes ago`
        } else {
          timeStampString = `${Math.round(timeDuration.asHours())} hours ago`
        }
      } else {
        timeStampString = `${moment(message.createdAt).format('MMM DD, YYYY, hh:mm A')}`;
      }
      const nameFirstLetter = message.user && message.user.companyprofilename ? message.user.companyprofilename.toUpperCase() : '';
      let colorChange ='';
      if(nameFirstLetter.charCodeAt(0)> 64 && nameFirstLetter.charCodeAt(0)<= 69){
        colorChange ={'backgroundColor':'#EAABAA'};
      } else if(nameFirstLetter.charCodeAt(0)> 69 && nameFirstLetter.charCodeAt(0)<= 74){
        colorChange ={'backgroundColor':'#AED5D1'};
      } else if(nameFirstLetter.charCodeAt(0)> 74 && nameFirstLetter.charCodeAt(0)<= 79){
        colorChange ={'backgroundColor':'#D0E2B2'};
      } else if(nameFirstLetter.charCodeAt(0)> 79 && nameFirstLetter.charCodeAt(0)<= 84){
        colorChange ={'backgroundColor':'#F7E196'};
      } else if(nameFirstLetter.charCodeAt(0)> 84 && nameFirstLetter.charCodeAt(0)<= 90){
        colorChange ={'backgroundColor':'#DBDBD9'};
      } else {
        colorChange ={'backgroundColor':'#C7C7E5'};
      }
      return (
        <MenuItem eventKey={message.key} key={key} onClick={(e) => { e.preventDefault(); if (!(message && message.lastMessage && message.lastMessage.seen)) {this.props.actions.markMessagesAsReadFromDropDown(message.lastMessage.jobChatRoomId || message.lastMessage.jobChatroomId);} router.push(`${appBasePath}/request/${message.userbookingrequest.requestid}?jobid=${message.userbookingrequest.id}&view=message&garage_id=${message.user.id}`); }}>
          <Media>
            <Media.Left>
              <div className="notification-img">
                {logoUrl ?
                  <img src={logoUrl} alt="Image"
                  onError={(e)=>{e.target.onerror = null; e.target.src= defaultImageOnError()}} />
                  : <p className="no-image-color" style={colorChange}>{nameFirstLetter[0]}</p>
                }
              </div>
            </Media.Left>
            <Media.Body>
              <Media.Heading> <span>{message.user && message.user.companyprofilename ? message.user.companyprofilename : ''}</span> has sent you a message</Media.Heading>
              <p>{message.lastMessage.msg} </p>
              <span className="notify-time">{timeStampString}</span>
            </Media.Body>
          </Media>
        </MenuItem>
      );
    });
    return (
      <li className={this.state.messageSelected ? "notification-menu desktop-view active" : "notification-menu desktop-view"}>
        <div className="text-dropdown notify msg-dd" >
          <DropdownButton bsSize="large" id="dropdown-size-large" noCaret onToggle={() => { this.setState({ messageSelected: !this.state.messageSelected }) }} title={
            <div className="d-inline-block">
              <i className="mdi mdi-message-processing" aria-hidden="true" />
              {(commonReducer.unreadMessages && commonReducer.unreadMessages.length > 0) && <span className="no-notify">
                <label/>
              </span>}
            </div>}>
            <MenuItem eventKey="Messages">
              Messages
              <a onClick={(e) => { e.preventDefault(); router.push(`${appBasePath}/messages`) }} className="view_all">View All</a>
            </MenuItem>
            {this.state.isMessagessLoaded
              ? (commonReducer.unableToFetchUnreadMessages)
                ? (<div><h3>Unable to fetch notifications <a style={{cursor: 'pointer'}} onClick={(e) => {e.preventDefault; this.props.actions.getAllUnreadMessages()}}>Try Again</a></h3></div>)
                : (commonReducer.unreadMessages && commonReducer.unreadMessages.length > 0 )? (messagesView) : <MessageEmptyState />
              : <PageLoader/>
            }
          </DropdownButton>
        </div>
      </li>
    )
  }
}
