import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

class LightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSetAsCover: false
    };
  }
  componentWillReceiveProps(nextProps) {
    let currentIndex = this._imageGallery;
    if (currentIndex != null) {
      currentIndex = this._imageGallery.getCurrentIndex();
    } else {
      currentIndex = nextProps.imageStartIndex;
    }
    if (nextProps.images.length > currentIndex) {
      let showSetAsCover = false;
      if (nextProps.images[currentIndex].isCover) {
        showSetAsCover = false;
      } else {
        showSetAsCover = true;
      }
      this.setState({
        showSetAsCover: showSetAsCover
      });
    }
  }
  renderRightNav(onClick, disabled) {
    return (
      <button
        className='image-gallery-right-nav'
        disabled={disabled}
        onClick={() => {
          onClick();
        }} />
    );
  }
  renderLeftNav(onClick, disabled) {
    return (
      <button
        className='image-gallery-left-nav'
        disabled={disabled}
        onClick={() => {
          onClick();
        }} />
    );
  }

  _onSlide(index) {
    if (this.props.images[index].isCover) {
      this.setState({ showSetAsCover: false });
    } else {
      this.setState({ showSetAsCover: true });
    }
  }

  _onThumbnailClick(event, index) {
  }
  handleImageLoad() {}
  render() {
    const {actions, images, imageStartIndex, showModal, isEditable, closeModal, fileUpload, setAsCover,showSetAsCover, showUploadIcon, vehicleId,fromCreateRequest,reportId,cancelUploadImage } = this.props;
    return (
      <Modal className="box-modal" bsSize="large" show={showModal}>
        <Modal.Body>
          <div className="close-modal">
            <i className="mdi mdi-close" onClick={() => closeModal()} />
          </div>
          <div className={isEditable && showUploadIcon ? "image-modal" : "image-modal remove-upload"}>
            {isEditable && <div className="modal-option text-right">
              <ul className="list-unstyled">
                {this.state.showSetAsCover && showSetAsCover && <li onClick={(e) => {
                  e.preventDefault();
                  setAsCover(images[this._imageGallery.getCurrentIndex()]);
                }}>
                  <a href="">
                    Set as Cover
                    </a>
                </li>}
                  <li onClick={(e) => {
                    e.preventDefault();
                    if(reportId){
                      if(reportId == "fromImage"){
                        actions.deleteUploadedImages(images[this._imageGallery.getCurrentIndex()].id,"createrequest");
                        cancelUploadImage("uploadImage",this._imageGallery.getCurrentIndex(),images[this._imageGallery.getCurrentIndex()].id);
                      } else {
                        actions.deleteUploadedFile(reportId,"createrequest");
                        cancelUploadImage(fromCreateRequest,this._imageGallery.getCurrentIndex(),reportId);
                      }
                    } else {
                      actions.deleteVehicleImage(this._imageGallery.getCurrentIndex(), images[this._imageGallery.getCurrentIndex()].id, images, vehicleId);
                    }
                  }}>
                  <a href="">
                    <i className="mdi mdi-delete" />
                  </a>
                </li>
              </ul>
            </div>}
            {isEditable && showUploadIcon && <div className="modal-upload">
              <label htmlFor="garageImageUpload">
                <i className="mdi mdi-attachment" />
              </label>
              <input id="garageImageUpload" type="file" className="hide" onChange={(e) => { {fromCreateRequest ? fileUpload(e,fromCreateRequest) : fileUpload(e);} }} multiple />
            </div>}
            <ImageGallery
              ref={i => this._imageGallery = i}
              items={images}
              slideInterval={2000}
              showFullscreenButton={false}
              showPlayButton={false}
              lazyLoad={true}
              startIndex={imageStartIndex}
              renderRightNav={this.renderRightNav.bind(this)}
              renderLeftNav={this.renderLeftNav.bind(this)}
              onImageLoad={this.handleImageLoad}
              onThumbnailClick={this._onThumbnailClick.bind(this)}
              onSlide={this._onSlide.bind(this)} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LightBox;
