
import React  from 'react';
import { imageBasePath } from '../../constants/staticData';
import SpriteMaterial from '../common/SpriteMaterial';
import {isWebpSupported} from 'react-image-webp/dist/utils';

export default function DownloadAppPopUp(props) {
  let mobileList = isWebpSupported() ?`${imageBasePath}/images/landing/download-app-logo.webp` : `${imageBasePath}/images/landing/download-app-logo.svg`;
    return(
        <div>
        { props.showModalPopUp && <div className="model-welcome">
           <div className="row">
              <div className="col-xs-3">
                 <img className="landing-download-modal" src={mobileList} alt="download the app" />
              </div>
              <div className="col-xs-9">
                <div className="modal-close-download" onClick={()=> {props.closeModal()}}>
                {/*<i className="mdi mdi-close" />*/}
                <SpriteMaterial posx={279} posy={0} width={15} height={18} widthBack={15} />
                </div>
                  <div className="download-app-text-modal ">
                    Download the app!
                  </div>
                    <ul className="download-app-link-modal ">
                    <li>
                        <a data-labelledby="App Store" href="https://itunes.apple.com/us/app/carcility/id1352273113">
                        <img className="download-modal-img" src={`${imageBasePath}/images/landing/pop-up-apple.png`} alt="car service app - app store" />
                        </a>
                    </li>
                    <li>
                        <a data-labelledby="Google Play" href="https://play.google.com/store/apps/details?id=com.carcility">
                            <img className="download-modal-img" src={`${imageBasePath}/images/landing/pop-up-google-play.png`} alt="car service app - play store" />
                        </a>
                    </li>
                    </ul>
                </div>
            </div>
          </div> }
        </div>
    )
}

 