import * as types from "./actionTypes";
import axios from "axios";
import * as API_END_POINTS from "../constants/api.js";
import { map, each, cloneDeep, orderBy } from "lodash";
import {
  updateQueryStringParameter,
  removeParameterFromUrl,
} from "../helpers/index";
import moment from "moment";
import {
  defaultLat,
  defaultLng,
  defaultRadius,
  appBasePath,
} from "../constants/staticData";

export function setCurrentLocation(locationData) {
  return (dispatch) => {
    localStorage.setItem("userSelectedLocation", JSON.stringify(locationData));
    dispatch({
      type: types.SET_USER_LOCATION,
      userLocation: {
        latitude: locationData.lat,
        longitude: locationData.lng,
        label: locationData.label,
        formatted_address: locationData.formatted_address,
      },
    });
  };
}

export function addUserLocation(locationData) {
  return (dispatch) => {
    axios
      .post(API_END_POINTS.USER_LOCATION, {
        latitude: String(locationData.lat),
        longitude: String(locationData.lng),
        location: locationData.location,
        label: locationData.label,
      })
      .then((response) => {
        response.data.lat = response.data.latitude;
        response.data.lng = response.data.longitude;
        delete response.data.latitude;
        delete response.data.longitude;
        dispatch({
          type: types.ADD_USER_LOCATION,
          userLocation: response.data,
        });
      });
  };
}

export function getUserLocations() {
  return (dispatch) => {
    axios.get(API_END_POINTS.USER_LOCATION).then((response) => {
      if (response.data) {
        const userLocations = map(response.data, (location) => {
          location["lat"] = location.latitude;
          location["lng"] = location.longitude;
          delete location["latitude"];
          delete location["longitude"];
          return location;
        });
        dispatch({
          type: types.GET_USER_LOCATIONS,
          userLocations: userLocations,
        });
      }
    });
  };
}

export function editUserLocation(locationData) {
  return (dispatch) => {
    axios
      .put(API_END_POINTS.USER_LOCATION + locationData.id, {
        location: locationData.formatted_address,
        latitude: locationData.lat,
        longitude: locationData.lng,
        label: locationData.label,
      })
      .then((response) => {
        if (response.data) {
          const responseData = response.data;
          responseData.lat = responseData.latitude;
          responseData.lng = responseData.longitude;
          delete responseData.latitude,
            delete responseData.longitude,
            (responseData.id = locationData.id);
          responseData.userId = locationData.userId;
          dispatch({
            type: types.EDIT_USER_LOCATION,
            userLocation: responseData,
          });
        }
      });
  };
}

export function deleteUserLocation(locationId) {
  return (dispatch) => {
    axios.delete(API_END_POINTS.USER_LOCATION + locationId).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: types.DELETE_USER_LOCATION,
          locationId: locationId,
        });
      }
    });
  };
}

export function getAutosuggestedList() {
  return (dispatch) => {
    axios.get(API_END_POINTS.GET_AUTO_SUGGESTED_LIST).then((response) => {
      if (response.data) {
        dispatch({
          type: types.GET_AUTO_SUGGESTED_LIST,
          autoSuggestedList: response.data,
        });
      }
    });
  };
}

export function searchVendors(
  type,
  searchData,
  userLocation,
  sortBy,
  serviceTypes,
  distValue,
  toggleSwitchVal,
  ratingValue,
  daysSelected,
  rangeFrom,
  rangeTo
) {
  return (dispatch) => {
    let queryParams = "";
    if (type == "autosuggest") {
      queryParams = "?id=" + searchData.id + "&searchid=" + searchData.searchid;
    } else if (type == "keyword") {
      queryParams = "?keyword=" + searchData;
    }
    if (
      userLocation &&
      userLocation.latitude &&
      userLocation.longitude &&
      type &&
      searchData
    ) {
      queryParams =
        queryParams +
        "&lat=" +
        userLocation.latitude +
        "&lng=" +
        userLocation.longitude;
      queryParams = queryParams + "&tod=" + defaultRadius;
    } else if (
      userLocation &&
      userLocation.latitude &&
      userLocation.longitude
    ) {
      queryParams =
        queryParams +
        "&lat=" +
        userLocation.latitude +
        "&lng=" +
        userLocation.longitude;
      queryParams = queryParams + "&tod=" + defaultRadius;
    } else {
      queryParams = queryParams + "&lat=" + defaultLat + "&lng=" + defaultLng;
    }

    if (sortBy) {
      queryParams =
        sortBy == "distance" || sortBy == "-distance"
          ? userLocation && userLocation.latitude && userLocation.longitude
            ? queryParams + "&sortby=" + sortBy
            : queryParams
          : queryParams + "&sortby=" + sortBy;
    }
    if (serviceTypes && serviceTypes.length) {
      queryParams = queryParams + "&serviceids=" + serviceTypes;
    }
    if (userLocation && distValue && distValue.min && distValue.max) {
      queryParams =
        queryParams + "&fromd=" + distValue.min + "&tod=" + distValue.max;
    }
    if (toggleSwitchVal && toggleSwitchVal["byCash"]) {
      queryParams = queryParams + "&cashpayment=" + 1;
    } else if (toggleSwitchVal && toggleSwitchVal["byCreditcard"]) {
      queryParams = queryParams + "&ccpayment=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["showFavourites"]) {
      queryParams = queryParams + "&favorite=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["Open24_7"]) {
      queryParams = queryParams + "&wholeweek=" + 1;
    }
    if (toggleSwitchVal && toggleSwitchVal["PickupDrop"]) {
      queryParams = queryParams + "&facilites=" + 1;
    }
    if (ratingValue) {
      queryParams = queryParams + "&rating=" + ratingValue;
    }
    if (daysSelected && daysSelected.length) {
      queryParams = queryParams + "&day=" + daysSelected;
    }
    if (rangeFrom && rangeTo) {
      queryParams =
        queryParams + "&rangefrom=" + rangeFrom + "&rangeto=" + rangeTo;
    }

    axios
      .get(API_END_POINTS.SEARCH_VENDORS + queryParams + "&imageflow=1")
      .then((response) => {
        if (response.data) {
          let newUrl = "";
          if (
            !window.location.href.includes("top-car") &&
            !window.location.href.includes("vendor-profile")
          ) {
            if (type == "autosuggest" && searchData) {
              newUrl = "/search-result?q=" + searchData.name;
            } else if (searchData) {
              newUrl = "/search-result?q=" + searchData;
            } else {
              newUrl = "/search-result" + queryParams;
            }
          }
          if (window.location.pathname == "/search-result" && searchData) {
            let href = window.location.href;
            let updatedUrl = updateQueryStringParameter(
              href,
              "q",
              type == "autosuggest" ? searchData.name : searchData
            );
            // window.location.href = updatedUrl;
            window.history.replaceState(null, null, updatedUrl);
          } else if (queryParams) {
            let href = window.location.href;
            let updatedUrl = removeParameterFromUrl(href, "q");
            // window.location.href = updatedUrl;
            window.history.replaceState(null, null, updatedUrl);
          }
          dispatch({
            type: types.SEARCH_VENDORS,
            vendorsListFromSearch: response.data,
            changeCurrentComponentKey:
              window.location.pathname == "/search-result" ? false : true,
            newUrl: newUrl,
            searchType: type,
            searchData: searchData,
            userLocation:
              userLocation && userLocation.latitude && userLocation.longitude
                ? userLocation
                : undefined,
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function clearCommonReducerComponentKey() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_COMPONENT_KEY,
    });
  };
}

export function getGarageProfileDetails(garageId) {
  return (dispatch) => {
    axios
      .get(
        API_END_POINTS.GET_GARAGE_PROFILE +
          "?garageId=" +
          garageId +
          "&imageflow=1",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch({
            type: types.GET_GARAGE_PROFILE,
            garageProfileDetails: response.data,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

// Though this is named as promotional offers , remember that it is fetching all the offers
// including the prmotional and All
export function getPromotionalOffers() {
  return (dispatch) => {
    //axios.get(API_END_POINTS.GET_PROMOTIONAL_OFFERS, {
    axios
      .get(API_END_POINTS.GET_ALL_OFFERS, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          var promotionalOffersData = orderBy(
            [...response.data.data.general, ...response.data.data.promotional],
            "createdAt",
            "desc"
          );
          console.log("response.data.data first : ", promotionalOffersData);
          promotionalOffersData = promotionalOffersData.filter(
            ({
              availedCount,
              userLimit,
              totalAvailedCount,
              maxLimit,
              appOnly,
            }) => {
              console.log(
                `Availed Count${availedCount} UserLimit${userLimit} TotalAvailedCOunt${totalAvailedCount} MaxLimit${maxLimit}`
              );
              return (
                (userLimit ? availedCount < userLimit : true) &&
                (maxLimit ? totalAvailedCount < maxLimit : true) &&
                !appOnly
              );
            }
          );
          console.log("response.data.data : PROMO ", promotionalOffersData);
          dispatch({
            type: types.GET_PROMOTIONAL_OFFERS,
            promotionalOffersData,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function getSingleOffer(offerId) {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_SINGLE_OFFER + "/" + offerId, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch({
            type: types.GET_SINGLE_OFFER,
            singleOffersData: response.data.offer,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function getUserPaymentStatus() {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.USER_PAYMENT_STATUS, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch({
            type: types.GET_USER_PAYMENT_STATUS,
            userPaymentStatusData: response.data,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function getAllPromotionalOffers() {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_ALL_OFFERS, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          console.log(
            "response.data.data ALL ---> : ",
            response.data.data.general
          );
          dispatch({
            type: types.GET_ALL_OFFERS,
            promotionalOffersData: response.data.data.general,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function createPlusRequest(payload, router) {
  return (dispatch) => {
    axios
      .post(API_END_POINTS.CREATE_PLUS_REQUEST, payload)
      .then((response) => {
        console.log("SAGAR = ", response.data);
        // dispatch({
        //   type: types.APPLY_PROMOTIONAL_OFFERS,
        //   promotionalOffersMessage: response.data.data.msg,
        //   promotionalOffersIsApproved: true
        // });
        router.push(`${appBasePath}/request/${response.data.bookingId}`);
        dispatch({
          type: types.SHOW_TOAST_MESSAGE,
          toastMessage: "Request created successfully",
          toastType: "success",
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: types.APPLY_PROMOTIONAL_OFFERS,
            promotionalOffersIsApproved: false,
            promotionalOffersMessage: err.response.data.err,
          });
        }
      });
  };
}

export function verifyCost(price, packageId) {
  return (dispatch) => {
    axios
      .get(
        API_END_POINTS.VERIFY_PACKAGE_COST +
          `${packageId}?carmodel=${price.modelId}&year=${price.year}&cylinder=${price.cylinder}&iswrapper=0`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          console.log("packages response data ---> : ", response.data);
          const pack = response.data.package;
          if (pack.pricings.length > 0) {
            console.log(response.data.package);
            dispatch({
              type: types.PLUS_PACKAGE_SELECTED,
              data: response.data.package,
            });
            dispatch({
              type: types.PLUS_CAR_SELECTED,
              data: price,
            });

            /*       dispatch({
                type: types.PLUS_PACKAGE_CUSTOMISATION_SELECTED,
                data: customised
              });*/
          } else {
            dispatch({
              type: types.PLUS_PACKAGE_SELECTED,
              data: {},
            });
            dispatch({
              type: types.PLUS_CAR_SELECTED,
              data: {},
            });
            setTimeout(function () {
              dispatch({
                type: types.SHOW_TOAST_MESSAGE,
                toastMessage: "No package available for this model",
                toastType: "error",
              });
            }, 3000);
            setTimeout(function () {
              dispatch({
                type: types.HIDE_TOAST_MESSAGE,
              });
            }, 6000);
          }
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: "Unknown error occurred please try again",
            toastType: "error",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage: "Unknown error occurred please try again",
            toastType: "error",
          });
        }
      });
  };
}

export function GetAllPackages() {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_ALL_PACKAGES, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          console.log("packages response data ---> : ", response.data);
          dispatch({
            type: types.GET_ALL_PACKAGES,
            packagesData: response.data.packages,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function GetAllData(requestId) {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_ALL_DATA + requestId)
      .then((response) => {
        if (response.status === 200) {
          console.log("response data ---> : ", response.data);
          dispatch({
            type: types.GET_ALL_DATA,
            requestData: response.data,
          });
        } else {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function getWorkshops() {
  return (dispatch) => {
    const defaultLocation = {
      formatted_address: "Dubai - United Arab Emirates",
      label: "Dubai - United Arab Emirates",
      lat: 25.2048493,
      lng: 55.270782800000006,
    };
    const userSelectedLocation = localStorage.getItem("userSelectedLocation")
      ? JSON.parse(localStorage.getItem("userSelectedLocation"))
      : defaultLocation;
    const searchLocation = {
      latitude: userSelectedLocation.lat,
      longitude: userSelectedLocation.lng,
    };
    const queryParams =
      "?lat=" +
      searchLocation.latitude +
      "&lng=" +
      searchLocation.longitude +
      "&sortby=distance";
    axios
      .get(API_END_POINTS.SEARCH_VENDORS + queryParams + "&imageflow=1")
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.SEARCH_VENDORS,
            vendorsListFromSearch: response.data,
            changeCurrentComponentKey: false,
            newUrl: "",
            searchType: false,
            searchData: false,
            // userLocation: userLocation && userLocation.latitude && userLocation.longitude ? userLocation : undefined
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage: "Unknown error occurred please try again",
          });
        }
      });
  };
}

export function applyPromotionalOffers(promotionData) {
  return (dispatch) => {
    axios
      .post(API_END_POINTS.APPLY_PROMOTIONAL_OFFERS, {
        place: String(promotionData.place),
        services: promotionData.services,
        promo_code: promotionData.promo_code,
        brand: promotionData.brand,
      })
      .then((response) => {
        dispatch({
          type: types.APPLY_PROMOTIONAL_OFFERS,
          promotionalOffersMessage: response.data.data.msg,
          promotionalOffersIsApproved: true,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: types.APPLY_PROMOTIONAL_OFFERS,
            promotionalOffersIsApproved: false,
            promotionalOffersMessage: err.response.data.err,
          });
        }
      });
  };
}

export function getNotificationAlerts(offset, limit) {
  const notificationsEndPoint = `${
    API_END_POINTS.GET_NOTIFICATION_ALERTS
  }/?offset=${offset || 0}&limit=${limit || 10}`;
  return (dispatch) => {
    axios
      .get(notificationsEndPoint)
      .then((response) => {
        if (response.status == 200 && response.data && response.data.data) {
          const currentTimeStamp = moment(new Date());
          const nextDayTimeStamp = moment(new Date()).subtract(1, "days");
          const notifications = map(response.data.data, (notification) => {
            const createdTimeStamp = moment(notification.created_at);
            notification.createdDate = createdTimeStamp.format("DD-MMM-YYYY");
            notification.createdTime = createdTimeStamp.format("HH:MM:A");
            notification.category =
              createdTimeStamp.date() == currentTimeStamp.date()
                ? "today"
                : moment(createdTimeStamp).isSame(nextDayTimeStamp, "date")
                ? "yesterday"
                : "old";
            return notification;
          });
          dispatch({
            type: types.GET_NOTIFICATION_ALERTS,
            totalUnreadAlerts: response.data.totalUnreadAlerts,
            notifications: notifications || [],
            notificationsPageInfo: response.data.pageInfo || {},
            offset: offset,
          });
        } else {
          dispatch({
            type: types.GET_NOTIFICATION_ALERTS,
            unableToFetch: true,
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.GET_NOTIFICATION_ALERTS,
            unableToFetch: true,
          });
        }
      });
  };
}

export function markNotificationAsRead(alertID) {
  return (dispatch) => {
    axios
      .put(API_END_POINTS.MARK_NOTIFICATION_AS_READ + alertID)
      .then((response) => {
        if (response.status == 200 && response.data) {
          dispatch({
            type: types.MARK_NOTIFICATION_AS_READ,
            alertID: alertID,
          });
        }
      });
  };
}

export function newSocketNotification(notification, notificationType) {
  notification.created_at = new Date();

  console.log("notification", notification);
  return (dispatch) => {
    dispatch({
      type: types.NOTIFICATION_FROM_SOCKET,
      notification: notification,
      notificationType: notificationType,
    });
  };
}

export function getRatingsAndReviews(
  garageId,
  start,
  count,
  sortKey,
  fromLoadMore
) {
  return (dispatch) => {
    axios
      .get(
        API_END_POINTS.GET_REVIEWS_AND_RATINGS +
          "?garageId=" +
          garageId +
          "&garage-id=" +
          garageId +
          "&start=" +
          start +
          "&count=" +
          count +
          "&" +
          sortKey,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch({
            type: types.GET_REVIEWS_AND_RATINGS,
            ratingsAndReviews: response.data.reviewsDetails,
            ratingsOverview: response.data.overview,
            fromLoadMore: fromLoadMore,
          });
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.message
                ? response.data.message
                : "Unknown error occurred please try again",
            toastType: "error",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "Unknown error occurred please try again",
            toastType: "error",
          });
        }
      });
  };
}
export function showToastMessage(toastMessage, toastType) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_TOAST_MESSAGE,
      toastMessage: toastMessage,
      toastType: toastType,
    });
  };
}
export function createChatRoom(payLoad, customerProfilePhoto) {
  return (dispatch) => {
    axios.post(API_END_POINTS.CREATE_CHAT_ROOM, payLoad).then((response) => {
      if (response.data && response.status == 200) {
        const GET_ALL_MESSAGE_OF_CHAT_ROOM =
          API_END_POINTS.GET_ALL_MESSAGE_OF_CHAT_ROOM.replace(
            "${roomId}",
            response.data.jobChatRoomId
          );
        const jobChatRoomId = response.data.jobChatRoomId;
        axios.get(GET_ALL_MESSAGE_OF_CHAT_ROOM).then((response) => {
          if (response.status == 200) {
            window.socket.emit("join-chatroom", jobChatRoomId);
            dispatch({
              type: types.CREATE_CHAT_ROOM,
              jobChatRoomId: jobChatRoomId,
              jobrequestId: payLoad.jobrequest_id,
              garageId: payLoad.garage_id,
              messages:
                response.data.messages && response.data.messages.length
                  ? response.data.messages.reverse()
                  : response.data.messages,
              customerProfilePhoto: customerProfilePhoto,
            });
            dispatch({
              type: types.JOIN_CHAT_ROOM,
              currentJobChatRoomId: jobChatRoomId,
            });
          }
        });
      }
    });
  };
}

export function joinChatRoom(jobChatRoomId) {
  return (dispatch) => {
    dispatch({
      type: types.JOIN_CHAT_ROOM,
      currentJobChatRoomId: jobChatRoomId,
    });
  };
}

export function sendNewMessage(
  message,
  jobrequestId,
  garageId,
  jobChatRoomId,
  customerProfilePhoto
) {
  return (dispatch) => {
    dispatch({
      type: types.SENT_NEW_MESSAGE,
      message: message,
      jobrequestId: jobrequestId,
      garageId: garageId,
      jobChatRoomId: jobChatRoomId,
      customerProfilePhoto: customerProfilePhoto,
    });
  };
}
export function receiveNewMessage(messageData, jobChatRoomId) {
  return (dispatch) => {
    dispatch({
      type: types.RECEIVE_NEW_MESSAGE,
      message: messageData.msg,
      user: messageData.user,
      userbookingrequest: messageData.userbookingrequest,
      jobChatRoomId: jobChatRoomId,
    });
  };
}
export function getAllChatRooms() {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_ALL_CHATROOMS)
      .then((response) => {
        if (response.data && response.status == 200) {
          dispatch({
            type: types.GET_ALL_CHATROOMS,
            chatRooms: response.data.chatrooms,
          });
        } else {
          dispatch({
            type: types.GET_ALL_CHATROOMS,
            unableToFetch: true,
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.GET_ALL_CHATROOMS,
            unableToFetch: true,
          });
        }
      });
  };
}

export function getAllUnreadMessages() {
  return (dispatch) => {
    axios
      .get(API_END_POINTS.GET_ALL_UNREAD_MESSAGES)
      .then((response) => {
        if (
          response.status == 200 &&
          response.data &&
          response.data.messageAlerts
        ) {
          dispatch({
            type: types.GET_ALL_UNREAD_MESSAGES,
            unreadMessages: response.data.messageAlerts.messages,
            unableToFetch: false,
          });
        } else {
          dispatch({
            type: types.GET_ALL_UNREAD_MESSAGES,
            unableToFetch: true,
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            type: types.GET_ALL_UNREAD_MESSAGES,
            unableToFetch: true,
          });
        }
      });
  };
}

export function getSignedURL(
  file,
  imageFor,
  batchID,
  callback,
  errorCallback,
  referenceID,
  fileType
) {
  console.log("getSignedURL 🤬 🤬 🤬 🤬 ", fileType);
  return (dispatch) => {
    let url =
      API_END_POINTS.S3_IMAGE_UPLOAD_SIGNED_REQUEST +
      "?file_name=" +
      file.name +
      "&file_type=" +
      file.type +
      "&for=" +
      imageFor;
    if (batchID) {
      url = url + "&batchid=" + batchID;
    }
    // for vehicle profile and request editing
    if (referenceID) {
      url = url + "&referenceid=" + referenceID;
    }
    if (fileType) {
      url = url + "&type=" + fileType;
    }
    if (file && file.height && file.width) {
      url = url + "&height=" + file.height + "&width=" + file.width;
    }
    axios
      .get(url, {
        headers: {
          Accept: "application/json,",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status == 200 && response.data) {
          callback(response.data);
        } else {
          errorCallback();
          dispatch({
            type: types.SHOW_ERROR_MESSAGE,
            statusMessage:
              response.data && response.data.message
                ? response.data.message
                : "Unknown error occurred please try again",
          });
        }
      })
      .catch((err) => {
        errorCallback();
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Unknown error occurred please try again",
        });
      });
  };
}

export function uploadImage(
  file,
  signed_request,
  url,
  imageId,
  notifyServer,
  callback,
  errorCallback
) {
  console.log(" UPLOAD IMAGE ---> <----- ", signed_request);
  return (dispatch) => {
    axios
      .put(signed_request, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "private",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          if (notifyServer) {
            dispatch(
              notifyImageUPloadSuccess(imageId, callback, errorCallback)
            );
          }
        } else {
          console.log("EROR out ", response);
          errorCallback();
        }
      })
      .catch((err) => {
        console.log("EROR out 2 ", err);
        errorCallback();
        dispatch({
          type: types.SHOW_ERROR_MESSAGE,
          statusMessage:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Unknown error occurred please try again",
        });
      });
  };
}

// only for Vehicle and Booking Request.
export function notifyImageUPloadSuccess(imageId, callback, errorCallback) {
  return () => {
    axios
      .post(
        API_END_POINTS.S3_IMAGE_UPLOAD_DONE,
        { imageId: imageId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          callback(imageId, true);
        } else {
          errorCallback();
        }
      })
      .catch(() => {
        errorCallback();
      });
  };
}

export function markMessagesAsRead(chatRoomData, chatMessages) {
  return (dispatch) => {
    let currentChat = cloneDeep(chatMessages[chatRoomData.id] || "");
    if (currentChat && currentChat.messages) {
      let unSeenMessageIds = [];
      each(currentChat.messages, (message) => {
        if (!message.seen) {
          message.seen = true;
          if (message.id) {
            unSeenMessageIds.push(message.id);
          }
        }
      });
      if (unSeenMessageIds.length > 0) {
        window.socket.emit("message-seen", unSeenMessageIds);
        dispatch({
          type: types.MARK_MESSAGES_AS_READ,
          chatRoomData: chatRoomData,
          currentChat: currentChat,
          unSeenMessageIds: unSeenMessageIds,
        });
      }
    }
  };
}
export function markMessagesAsReadFromDropDown(jobChatRoomId) {
  window.socket.emit("message-seen", jobChatRoomId);
  return (dispatch) => {
    dispatch({
      type: types.MARK_MESSAGES_AS_READ_FROM_DROPDOWN,
      jobChatRoomId: jobChatRoomId,
    });
  };
}

export function getAppDownloadLink(phoneNumber) {
  return (dispatch) => {
    axios
      .post(API_END_POINTS.GET_APP_DOWNLOAD_LINK, { phone: phoneNumber })
      .then((response) => {
        if (response.status == 200 && response.data) {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.msg
                ? response.data.msg
                : "Download link has been sent to your mobile",
            toastType: "success",
          });
        } else {
          dispatch({
            type: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              response.data && response.data.msg
                ? response.data.msg
                : "Unable to sent app download link to your mobile",
            toastType: "error",
          });
        }
      })
      .catch((err) => {
        if (err.config) {
          dispatch({
            ype: types.SHOW_TOAST_MESSAGE,
            toastMessage:
              err.response && err.response.data && err.response.data.msg
                ? err.response && err.response.data && err.response.data.msg
                : "Unable to sent app download link to your mobile",
            toastType: "error",
          });
        }
      });
  };
}
