import React, { Component } from 'react';
import Badge from '../common/Badge';
import map  from 'lodash/map';
import PageLoader from '../common/PageLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { imageBasePath } from '../../constants/staticData';

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { carProfileReducer, currentCarProfile, actions } = this.props;
    const timelineView = map(currentCarProfile && currentCarProfile.serviceHistory, (val, key) => {
      return (
        <li key={key}>
          <span className="timeline-month">{val.timelineMonth}</span>
          <span className="timeline-icon">
            <img className={!val.timelineIcon ? "placeholder" : ""} src={val.timelineIcon ? val.timelineIcon : imageBasePath + '/images/placeholder.png'} alt="" />
            <span className="line-joint" />
          </span>
          <div className="timeline-content">
            <h4 className={val.requeststatusId == 3 ? 'current' : val.requeststatusId == 4 ? 'upcoming' : 'completed'}>{val.timelineStatus}</h4>
            <span className="timeline-service-type">{val.serviceType}</span>
            <span className="timeline-service-cost">{val.serviceCost} {val.currency}</span>
          </div>
          <Badge badgeType="basic" fontSize={12}>{val.timelineBadge}</Badge>
        </li>
      );
    });
    return (
      <div className="timeline-log">
        {carProfileReducer.serviceHistoryLoaded ? currentCarProfile && currentCarProfile.serviceHistory && currentCarProfile.serviceHistory.length ? <ul>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              currentCarProfile && actions.getCarServiceHistory(currentCarProfile.id, 10, currentCarProfile.serviceHistory.length, true);
            }}
            hasMore={currentCarProfile.pageInfo && currentCarProfile.pageInfo.rowCount > currentCarProfile.serviceHistory.length}
            loader={<PageLoader />}
          >
            {timelineView}
          </InfiniteScroll>
        </ul> : currentCarProfile.serviceHistoryLoaded &&
          <div >
            <div className="empty-state-holder">
              <span className="empty-state-content">
              <img src={`${imageBasePath}/images/timelog.png`} alt="" />
              <span className="text">Looks like you don’t have any requests for this Car Profile.</span>
              </span>
            </div>
          </div>
          : <PageLoader />}
      </div>
    );
  }
}
