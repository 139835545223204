import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow, } from 'react-google-maps';
import SearchBox from 'react-google-maps/lib/places/SearchBox';
import map  from 'lodash/map';
import round  from 'lodash/round';
import { Media } from 'react-bootstrap';
import { imageBasePath } from '../../constants/staticData';

class Gmaps extends Component {
  constructor(props) {
    super(props)
    this.searchBoxStyle = {
      boxSizing: `border-box`,
      boxShadow: `0 2px 4px 0 rgba(0,0,0,0.3)`,
      MozBoxSizing: `border-box`,
      border: `3px solid transparent`,
      width: `100%`,
      height: `42px`,
      marginTop: `20px`,
      padding: `0 12px`,
      fontSize: `13px`,
      outline: `none`,
      textOverflow: `ellipses`,
      fontFamily: `CenturyGothic`,
      color: `#949DA5`

    };

    this.state = {
      activeInfoWindow: null,
      map: null,
      bounds: null,
      init: false,
      defaultZoom: 12
    };
  }
  mouseLeave() {
    this.setState({ activeInfoWindow: null })
  }
  setCenterAndZoom() {
    if (!this.state.init) {
      if (this.props.markers.jobCardLocation) {
        let bounds = new window.google.maps.LatLngBounds();
        if (this.props.markers && this.props.markers.jobCardLocation && this.props.markers.jobCardLocation.length > 1) {
          map(this.props.markers.jobCardLocation, (val) => {
            bounds.extend(new window.google.maps.LatLng(val.lat, val.lng));
          });
          this.refs.map.fitBounds(bounds);
          this.setCenterAndZoom = () => { };
          let currentZoom = this.refs.map.getZoom();
          if (currentZoom > 10) {
            setTimeout(() => {
           //   this.setState({ defaultZoom: 10 });
            }, 100);
          }
        }
      }
    }
    this.setState({
      init: true
    });
  }
  render() {
    let mapRes = null;
    if (this.props.markers == undefined) {
      mapRes = undefined;
    } else if (this.props.markers.jobCardLocation != undefined) {
      mapRes = this.props.markers.jobCardLocation;
    } else {
      mapRes = this.props.markers;
    }
    const markers = mapRes ? map(mapRes, (val, i) => {
      if (val.lat && val.lng) {
        const marker = {
          position: {
            lat: val.lat,
            lng: val.lng
          }
        };
        if (this.props.markerClick) {
          marker['onClick'] = this.props.markerClick;
        }
        return <Marker key={i} {...marker}
          onMouseOver={() => this.setState({ activeInfoWindow: i })}
          onMouseOut={() => { this.mouseLeave(); }}
          onDragEnd={this.props.markerDrag}
          draggable={val.dragEle}
          icon={{ url: val.pinImage }}
          zIndex={val.zIndex} />;
      }
    }) : "";
    let mapSettings = {
      defaultCenter: this.props.center,

    };
    if (this.props.setCenter) {
      mapSettings["center"] = this.props.center;
    }
    return (
      <div>
        <GoogleMap
          {...mapSettings}
          draggable={true}
          //maxZoom={5}
          defaultZoom={this.state.defaultZoom}
          onDragEnd={this.props.mapDrag}
          ref="map"
          onTilesLoaded={() => this.setCenterAndZoom()}>
          {markers}
          {this.props.markers ? map(mapRes, (val, i) => {
            if (i == this.state.activeInfoWindow && val.name) {
              return (
                this.props.infoPopUp ?
                  <InfoWindow options={{ pixelOffset: new window.google.maps.Size(0, -40), maxWidth: 327 }} defaultPosition={{ lat: val.lat, lng: val.lng }} key={i}>
                    <Media>
                      <Media.Left>
                        <img width={100} height={75} src={val.image ? val.image : `${imageBasePath}/images/placeholder.png`} alt="Image" />
                      </Media.Left>
                      <Media.Body>
                        <Media.Heading>{val.name}</Media.Heading>
                        <div className="rating rating-left">
                          <span className={val.rating > 0 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                          <span className={val.rating > 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                          <span className={val.rating > 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                          <span className={val.rating > 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                          <span className={val.rating > 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                          <span className="rating-text">{round(val.rating)} ({val.review} reviews)</span>
                        </div>
                        <span className="distance">{val.distance ? round(val.distance, 2) + ' kms' : ''} </span>
                      </Media.Body>
                    </Media>
                  </InfoWindow> : ""
              );
            }
          }) : ""}

          {this.props.onPlacesChanged && <SearchBox
            ref={this.props.onSearchBoxMounted}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={this.props.onPlacesChanged}
            inputPlaceholder="Search Your Location"
            inputStyle={this.searchBoxStyle}
          />}
        </GoogleMap>
      </div>
    );
  }
}

export default  withGoogleMap(Gmaps);
