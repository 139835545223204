import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';


export default class Awards extends Component{

  render(){
    return(
        <div id="professional-section" >
          <div className="container awards-section">
            <div className="awards-recognition">Awards & Recognitions</div>
            <div className="awards-bunch">
              <div className="awards-individual">
                <div className="awards-body">
                  <img src={`${imageBasePath}/images/landing/award-ge.svg`} alt="Gitex" />
                  <div className="awards-titles">Gitex supernova youth startup semi-finalist</div>
                  <div className="awards-year">2018</div>
                </div>
              </div>
              <div className="awards-individual">
               <div className="awards-body">
                 <img className="smartpreneur" src={`${imageBasePath}/images/landing/award-dubai.svg`} alt="smartpreneur" />
                 <div className="awards-titles">Top 50 in Smartpreneur</div>
                 <div className="awards-year">2019</div>
               </div>
              </div>
              <div className="awards-individual">
                <div className="awards-body">
                  <img src={`${imageBasePath}/images/landing/award-alt.svg`} alt="Aim" />
                  <div className="awards-titles">Shortlisted for AIM startup pitch challenge</div>
                  <div className="awards-year">2019</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Featured-awards">
            <div className="as-featured-in">As Featured In</div>
            <div className="feature-blocks">
              <a href="https://www.khaleejtimes.com/auto-startup-aims-to-ease-consumer-woes" target="_blank">
                <img className="khaleej" src={`${imageBasePath}/images/landing/w-award-khaleej.svg`} alt="khaleej" />
              </a>
              <a href="https://cofounder.ae/carcility-find-the-best-car-service-from-car-wash-to-car-repair-at-this-one-stop-in-the-uae/" target="_blank">
                <img className="cofounder" src={`${imageBasePath}/images/landing/w-award-cofounder.svg`} alt="cofounder" />
              </a>
              <a href="https://omny.fm/shows/theagenda/theres-an-app-for-that-carcility" target="_blank">
                <img className="dubai-eye" src={`${imageBasePath}/images/landing/award-dubai-eye.svg`} alt="dubai-eye" />
              </a>
            </div>
          </div>
      </div>
    )
 }
}