import React, { Component } from 'react';
import IconNotification from '../common/IconNotification';
import Button from '../common/Button';
import { Media } from 'react-bootstrap';
import { imageBasePath, appBasePath } from '../../constants/staticData';

class QuotesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavouriteVisible: true,
        };
    }
    render() {
        const { index, vendor, rating, distance, reviews, quoteKey } = this.props;
        return (
            <div>
                 <div className={this.props.activeClass == "active" ? "jobcard box active" : "jobcard box"} onClick={this.props.ClickedQuoteCard} ref={index}>
                    <div className="box-content">
                        <Media>
                            <Media.Left>
                                <div className="vendor-brand-holder">
                                    <img src={`${imageBasePath}/images/placeholder.png`} alt="Image" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                <Media.Heading>{index} {vendor.name}
                                    <span className={!this.props.favourite?"mdi mdi-heart-outline":"mdi mdi-heart"} onClick={(e) => this.props.removeFavourite(e, quoteKey)} />
                                </Media.Heading>
                                <div className="rating rating-left">
                                    <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                    <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                    <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                    <span className="mdi mdi-star-outline" />
                                    <span className="mdi mdi-star-outline" />
                                    <span className="rating-text">{vendor.rating} <span className="total-reviews">({vendor.reviews} reviews)</span></span></div>
                                <span className="distance">{vendor.distance} Km</span>
                            </Media.Body>
                        </Media>
                        <div className="box-footer search-result-card">
                            <div className="footer-container">
                                <span className="card-ph-number" style={{visibility: 'hidden'}}>+971 919 233 470</span>
                                <Button btnType="transparent" btnSize="lg" fontSize={13} label={this.props.btnText?this.props.btnText:"Book Service"} btnCallBack={this.props.btnClickFunc} />
                                <span className="quotes-cost">120 AED</span>
                                {/* <div className="box-message">
                                    <IconNotification iconType="comment-processing-outline" iconLabel="Messages" notifyClass="notification" viewMessaging={this.props.viewMessaging}/>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuotesCard;
