import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import map  from 'lodash/map';
import {imageBasePath} from '../../constants/staticData';

export default class TimingsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownVisible: false,
      days:["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    }
  }


  checkTimeInbetween(todayTimeings) {
    let startTime = todayTimeings.range[0].from;
    let endTime = todayTimeings.range[0].to;
    let currentDate = new Date();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime.split(":")[1].split(" ")[1] === "PM"?(Number(startTime.split(":")[0]) +12): startTime.split(":")[0]);
    startDate.setMinutes(startTime.split(":")[1].split(" ")[0]);
    startDate.setSeconds("00");

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime.split(":")[1].split(" ")[1] === "PM"?(Number(endTime.split(":")[0]) +12): endTime.split(":")[0]);
    endDate.setMinutes(endTime.split(":")[1].split(" ")[0]);
    endDate.setSeconds("00");
    return startDate < currentDate && endDate > currentDate
  }
  checkOpenClose()  {
    let garageStatus = "Open Now";
    let dayPresent = false;
    const { timings } = this.props;
    const timingSorted = _.sortBy( timings, 'day' );
    const todayDay = new Date().getDay();
    // const todayDay = 3;
    let todayTimeings = "";
    timingSorted.map((time) => {
      if((time.day - 1) === todayDay) {
        dayPresent = true;
        todayTimeings = time;
      }
    })
    if(dayPresent){
      garageStatus = this.checkTimeInbetween(todayTimeings) ? "Open Now": "Closed Now";
    } else {
      garageStatus = "Closed Now";
    }
    return garageStatus;
  };

  render() {
    const {timings,  distance } = this.props;
    const timingSorted = _.sortBy( timings, 'day' );
    const {days} = this.state;
    let garageStatus = "Open Now";
    let count = 0;
    const timeList = map(timingSorted, (timing, key) => {
       if(timing.range[0].from === "12:00 AM" && timing.range[0].to ==="11:59 PM"){
         count = count+1;
       }
      return (
      <MenuItem eventKey={key}>
        <div className="time-day"> {days[timing.day-1]}</div><div>{timing.range[0].from} - {timing.range[0].to}</div>
      </MenuItem>)
    })
   garageStatus = (count===7) ? "24/7" : this.checkOpenClose();
   const statusCode = garageStatus === "Open Now"? "open_now": (garageStatus === "24/7")? "full_week":"close_now";
    return (
     <div className="profile-header timing-popup">
      <DropdownButton
                id={this.props.id ? this.props.id : 0}
                onToggle={()=>{this.setState({'dropDownVisible': !this.state.dropDownVisible});}}
                title={
                  <div >
                    <span className={`${statusCode} garage-status`}>
                     {garageStatus}
                    </span>
                    <span className={`${statusCode} garage-status`}>
                        {this.state.dropDownVisible && <i className="mdi mdi-chevron-up" />}
                        {!this.state.dropDownVisible && <i className="mdi mdi-chevron-down" />}
                    </span>
                    <span className="garage-distance">{distance} kms</span>
                    </div>
                }>
                <div className="flex-align">
                  <div className="timeIcon-img">
                    <img src={`${imageBasePath}/images/landing/timerInd.png`} alt="" />
                  </div>
                  <div className="timeContent">
                    {(timingSorted && timingSorted.length >0) && timeList}
                  </div>
                </div>
            </DropdownButton>
            </div>
    );
  }
}
