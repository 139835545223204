import React, { Component } from 'react';
import Button from './Button';
import ToastMessage from '../common/ToastMessage';
import { imageBasePath } from '../../constants/staticData';

class EmptyState extends Component {
    constructor(){
        super()
        this.state={
            showToast:false
        }
    }
    render() {
        return (
            <div>
                <div className="empty-state-holder">
                    <img src={`${imageBasePath}/images/404.png`} alt="" />
                    <span className="text">Oops! Looks like you don’t have any messages yet. </span>
                    <Button customClass="loader_w" btnType="red" btnSize="lg" fontSize={15} label="Apply" btnCallBack={()=>this.setState({showToast:true})} />
                    <Button btnType="transparent" customClass="loader_r" btnSize="sm" fontSize={13} label="Load More" />
                </div>
                {this.state.showToast && <ToastMessage type="success" title="success.." text="Your image exceeds the file size limit of 20mb. Try again with another image."/>}
            </div>
        );
    }
}

export default EmptyState;
