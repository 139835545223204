import * as types from "../actions/actionTypes";
import {
  assign,
  cloneDeep,
  concat,
  keyBy,
  findIndex,
  find,
  indexOf,
  each
} from "lodash";
import moment from "moment";

const initialState = {
  vendorsListFromSearch: [],
  garageProfileDetails: {},
  notifications: [],
  markedNotifications: [],
  ratingsAndReviews: [],
  jobChatRooms: {},
  chatMessages: {},
  unreadMessages: [],
  chatRooms: [],
  isOfferLoaded: false,
};

export default function commonReducer(state = initialState, action) {
  console.log('What is common actiontype ',action.type);
  switch (action.type) {
/*    case types.SHOW_VERIFY_OTP_PAGE:
      return assign({}, state, {
        currentComponentKey: "/verify-otp",
        authData: action.authData,
        isLoaded: true,
        showErrorMessage: false,
        fromSignIn: action.fromSignIn
      });*/

    case types.GET_ALL_DATA: {
        console.log('What is the action -->', action);
        return assign({}, state, {
          requestData: action.requestData,
        });
      }
    case types.SET_USER_LOCATION: {
      return assign({}, state, { userLocation: action.userLocation });
    }
    case types.ADD_USER_LOCATION: {
      let userLocations = state.userLocations
        ? cloneDeep(state.userLocations)
        : {};
      userLocations[action.userLocation.id] = action.userLocation;
      return assign({}, state, { userLocations: userLocations });
    }
    case types.GET_USER_LOCATIONS: {
      const userLocations = keyBy(action.userLocations, "id");
      return assign({}, state, { userLocations: userLocations });
    }
    case types.EDIT_USER_LOCATION: {
      let userLocations = cloneDeep(state.userLocations);
      userLocations[action.userLocation.id] = action.userLocation;
      return assign({}, state, { userLocations: userLocations });
    }
    case types.DELETE_USER_LOCATION: {
      let userLocations = cloneDeep(state.userLocations);
      delete userLocations[action.locationId];
      return assign({}, state, { userLocations: userLocations });
    }
    case types.GET_AUTO_SUGGESTED_LIST: {
      return assign({}, state, { autoSuggestedList: action.autoSuggestedList });
    }
    case types.SEARCH_VENDORS: {
      return assign({}, state, {
        searchType: action.searchType,
        searchData: action.searchData,
        vendorsListFromSearch: action.vendorsListFromSearch,
        currentComponentKey: action.changeCurrentComponentKey
          ? action.newUrl
          : "",
        isLoaded: true
      });
    }
    case types.CLEAR_COMPONENT_KEY: {
      return assign({}, state, { currentComponentKey: "", isLoaded: false });
    }
    case types.GET_GARAGE_PROFILE: {
      return assign({}, state, {
        garageProfileDetails: action.garageProfileDetails,
        currentComponentKey: "",
        isLoaded: true
      });
    }

    case types.GET_PROMOTIONAL_OFFERS: {
      return assign({}, state, {
        promotionalOffersData: action.promotionalOffersData,
      });
    }

    case types.GET_ALL_OFFERS: {
      console.log('What is the action -->', action);
      return assign({}, state, {
        promotionalOffersData: action.promotionalOffersData,
      });
    }
    case types.GET_ALL_PACKAGES: {
      console.log('What is the action -->', action);
      return assign({}, state, {
        packagesData: action.packagesData,
      });
    }

    

    case types.GET_SINGLE_OFFER: {
      console.log('What is the action -->', action);
      return assign({}, state, {
        singleOffersData: action.singleOffersData,
        isOfferLoaded: true
      });
    }
    case types.GET_USER_PAYMENT_STATUS: {
      console.log('What is the action -->', action);
      return assign({}, state, {
        userPaymentStatusData: action.userPaymentStatusData,
      });
    }

    case types.APPLY_PROMOTIONAL_OFFERS: {
      return assign({}, state, {
        promotionalOffersMessage: action.promotionalOffersMessage,
        promotionalOffersIsApproved: action.promotionalOffersIsApproved,
      });
    }
    case types.CHANGE_FAVOURITES_VENDOR: {
      if (action.source == "searchResult") {
        let vendorsListFromSearch = cloneDeep(state.vendorsListFromSearch);
        let index = findIndex(vendorsListFromSearch, { id: action.vendorId });
        vendorsListFromSearch[index].isfavourite = action.status;
        return assign({}, state, {
          currentComponentKey: "",
          vendorsListFromSearch: vendorsListFromSearch
        });
      } else if (action.source == "vendorProfile") {
        let garageProfileDetails = cloneDeep(state.garageProfileDetails);
        garageProfileDetails.isfavourite = action.status;
        return assign({}, state, {
          currentComponentKey: "",
          garageProfileDetails: garageProfileDetails
        });
      }
      return assign({}, state, { currentComponentKey: "" });
    }

    case types.GET_NOTIFICATION_ALERTS: {
      if (action.unableToFetch) {
        return assign({}, state, {
          isNotificationsLoaded: true,
          unableToFetchNotifications: true
        });
      } else {
        // const notifications = filter(action.notifications, (notification) => {return !notification.isRead})
        let notifications = cloneDeep(state.notifications);
        if (action.offset) {
          if (action.notifications && action.notifications.length) {
            notifications = concat(notifications, action.notifications);
          }
        } else {
          notifications = cloneDeep(action.notifications);
        }
        return assign({}, state, {
          isNotificationsLoaded: true,
          unableToFetchNotifications: false,
          notifications: notifications,
          notificationsPageInfo: action.notificationsPageInfo,
          totalUnreadAlerts: action.totalUnreadAlerts
        });
      }
    }

    case types.MARK_NOTIFICATION_AS_READ: {
      const markedNotifications = cloneDeep(state.markedNotifications);
      const notifications = cloneDeep(state.notifications);
      let totalUnreadAlerts = cloneDeep(state.totalUnreadAlerts);
      console.log("notifications = ", notifications);
      const readNotificationIndex = findIndex(notifications, {
        alertId: action.alertID
      });
      if (notifications[readNotificationIndex] && !notifications[readNotificationIndex].isRead) {
        notifications[readNotificationIndex].isRead = true;
        if (totalUnreadAlerts) {
          totalUnreadAlerts = totalUnreadAlerts - 1;
        }
      }
      markedNotifications.push(action.alertID);
      return assign({}, state, {
        markedNotifications: markedNotifications,
        totalUnreadAlerts: totalUnreadAlerts,
        notifications: notifications
      });
    }

    case types.NOTIFICATION_FROM_SOCKET: {
      const notifications = cloneDeep(state.notifications);
      const notification = action.notification;
      if (action.notificationType && notification) {
        notification["type"] = action.notificationType;
        notifications.unshift(notification);
        return assign({}, state, {
          notifications: notifications,
          isNotificationsLoaded: true,
          unableToFetchNotifications: false
        });
      } else {
        return state;
      }
    }
    case types.GET_REVIEWS_AND_RATINGS: {
      let a = cloneDeep(action.ratingsAndReviews);
      let ratingsAndReviews = [];
      let b = cloneDeep(state.ratingsAndReviews);
      ratingsAndReviews = action.fromLoadMore ? concat(b, a) : a;
      return assign({}, state, {
        ratingsAndReviews: ratingsAndReviews,
        ratingsOverview: action.ratingsOverview
      });
    }
    case types.CREATE_CHAT_ROOM: {
      const authData = localStorage.authData
        ? JSON.parse(localStorage.authData)
        : "";
      const customerId = authData ? authData.id : "";
      let vendorProfilePhoto = "";
      let jobChatRooms = cloneDeep(state.jobChatRooms);
      if (customerId) {
        each(action.messages, message => {
          if (message && message.userId != customerId) {
            vendorProfilePhoto = message.profilePhoto;
            return false;
          }
        });
      }
      jobChatRooms[action.jobrequestId + "|" + action.garageId] = {
        id: action.jobChatRoomId,
        jobrequestId: action.jobrequestId,
        garageId: action.garageId
      };
      let chatMessages = cloneDeep(state.chatMessages);
      chatMessages[action.jobChatRoomId] = {
        messages: action.messages,
        isFetched: true,
        jobChatRoomId: action.jobChatRoomId,
        customerProfilePhoto: action.customerProfilePhoto,
        vendorProfilePhoto: vendorProfilePhoto
      };
      return assign({}, state, {
        jobChatRooms: jobChatRooms,
        chatMessages: chatMessages
      });
    }
    case types.JOIN_CHAT_ROOM: {
      return assign({}, state, {
        currentJobChatRoomId: action.currentJobChatRoomId
      });
    }
    case types.SENT_NEW_MESSAGE: {
      let chatMessages = cloneDeep(state.chatMessages);
      const chatRoom = chatMessages[action.jobChatRoomId];
      if (chatRoom && chatRoom.messages) {
        chatMessages[action.jobChatRoomId].messages.push({
          msg: action.message,
          jobChatRoomId: action.jobChatRoomId,
          createdAt: moment.utc(new Date()).format(),
          userId: Number(localStorage.userId)
        });
      } else {
        chatMessages[action.jobChatRoomId] = {
          isFetched: true,
          messages: [
            {
              msg: action.message,
              jobChatRoomId: action.jobChatRoomId,
              createdAt: moment.utc(new Date()).format(),
              userId: Number(localStorage.userId)
            }
          ],
          jobChatRoomId: action.jobChatRoomId,
          profilePhoto: action.customerProfilePhoto
        };
      }
      return assign({}, state, { chatMessages: chatMessages });
    }
    case types.RECEIVE_NEW_MESSAGE: {
      const localstorageAuthData = JSON.parse(localStorage.authData);
      action.message.jobChatRoomId = action.message.job_chatroom_id;
      action.message.userId = action.message.user_id;
      action.message.createdAt = action.message.created_at;
      let chatMessages = cloneDeep(state.chatMessages);
      let chatRooms = cloneDeep(state.chatRooms);
      let unreadMessages = cloneDeep(state.unreadMessages);
      const chatRoom = chatMessages[action.jobChatRoomId]
        ? cloneDeep(chatMessages[action.jobChatRoomId])
        : "";
      const vendorProfilePhoto = action.user ? action.user.profilePhoto : "";
      if (chatRoom && chatRoom.vendorProfilePhoto) {
        action.message.profilePhoto = chatRoom.vendorProfilePhoto;
      } else if (chatRoom) {
        chatRoom.vendorProfilePhoto = vendorProfilePhoto;
        action.message.profilePhoto = vendorProfilePhoto;
      }
      let customer = undefined;
      if (localstorageAuthData) {
        customer = {
          email: localstorageAuthData.email,
          id: localstorageAuthData.id,
          name: localstorageAuthData.name,
          phone: localstorageAuthData.phone,
          profilePhoto: chatRoom ? chatRoom.customerProfilePhoto : ""
        };
      }
      if (chatRoom && chatRoom.messages) {
        const messageExist = find(chatRoom.messages, function(message) {
          return message.id === action.message.id;
        });
        if (!messageExist) {
          chatMessages[action.jobChatRoomId].messages.push(action.message);
        }
      } else {
        chatMessages[action.jobChatRoomId] = action.message;
      }
      //For updating data on message dropdown
      let currentMessageIndex = undefined;
      const currentMessage = find(unreadMessages, function(
        unreadMessage,
        index
      ) {
        const jobChatRoomId =
          unreadMessage && unreadMessage.lastMessage
            ? unreadMessage.lastMessage.jobChatroomId ||
              unreadMessage.lastMessage.jobChatRoomId
            : "";
        if (jobChatRoomId == action.jobChatRoomId) {
          currentMessageIndex = index;
          return unreadMessage;
        }
      });
      if (currentMessage && currentMessageIndex > -1) {
        currentMessage.lastMessage = action.message;
        unreadMessages.splice(currentMessageIndex, 1);
        unreadMessages.unshift(currentMessage);
      } else {
        unreadMessages.unshift({
          count: 1,
          lastMessage: action.message,
          user: action.user,
          userbookingrequest: action.userbookingrequest
        });
      }
      //For updating data on inbox view
      let existingChatRoomIndex = undefined;
      let existingChatRoom = find(chatRooms, function(chatRoom, index) {
        if (chatRoom && chatRoom.id == action.jobChatRoomId) {
          existingChatRoomIndex = index;
          return chatRoom;
        }
      });
      if (existingChatRoom && existingChatRoomIndex > -1) {
        if (existingChatRoom.messages && existingChatRoom.messages.length) {
          existingChatRoom.unseenMsg = existingChatRoom.unseenMsg
            ? existingChatRoom.unseenMsg + 1
            : 1;
          existingChatRoom.messages.push(action.message);
        }
        chatRooms.splice(existingChatRoomIndex, 1);
        chatRooms.unshift(existingChatRoom);
      } else {
        chatRooms.unshift({
          customer: customer,
          customerId:
            localstorageAuthData && localstorageAuthData.id
              ? localstorageAuthData.id
              : "",
          garage: action.user,
          garageId: action.user.id,
          id: action.message.jobChatRoomId,
          jobrequestId: action.userbookingrequest.id,
          messages: [action.message],
          unseenMsg: 1,
          userbookingrequest: action.userbookingrequest
        });
      }
      return assign({}, state, {
        chatMessages: chatMessages,
        chatRooms: chatRooms,
        unreadMessages: unreadMessages
      });
    }
    case types.GET_ALL_CHATROOMS: {
      if (action.unableToFetch) {
        return assign({}, state, { isChatRoomsLoaded: true });
      } else {
        return assign({}, state, {
          isChatRoomsLoaded: true,
          chatRooms: action.chatRooms
        });
      }
    }
    case types.GET_ALL_MESSAGE_OF_CHAT_ROOM: {
      if (action.unableToFetch) {
        let chatMessages = cloneDeep(state.chatMessages);
        chatMessages[action.jobChatRoomId] = {
          isFetched: true,
          messages: [],
          jobChatRoomId: action.jobChatRoomId
        };
        return assign({}, state, { chatMessages: chatMessages });
      } else {
        let chatMessages = cloneDeep(state.chatMessages);
        chatMessages[action.jobChatRoomId] = {
          isFetched: true,
          messages: action.messages,
          jobChatRoomId: action.jobChatRoomId
        };
        return assign({}, state, { chatMessages: chatMessages });
      }
    }
    case types.GET_ALL_UNREAD_MESSAGES: {
      if (action.unableToFetch) {
        return assign({}, state, {
          isMessagesLoaded: true,
          unableToFetchUnreadMessages: true
        });
      } else {
        return assign({}, state, {
          isMessagesLoaded: true,
          unreadMessages: action.unreadMessages,
          unableToFetchUnreadMessages: false
        });
      }
    }
    case types.MARK_MESSAGES_AS_READ: {
      let chatMessages = cloneDeep(state.chatMessages);
      let chatRooms = cloneDeep(state.chatRooms);
      let unreadMessageIds = cloneDeep(state.unreadMessageIds);
      let unreadMessages = cloneDeep(state.unreadMessages);
      each(action.unSeenMessageIds, message => {
        const unreadMessageIndex = indexOf(state.unreadMessageIds, message);
        if (unreadMessageIndex > -1) {
          unreadMessageIds.splice(unreadMessageIndex, 1);
        }
      });
      const chatRoomIndex = findIndex(chatRooms, function(chatRoom) {
        return chatRoom.id == action.chatRoomData.id;
      });
      if (chatRoomIndex > -1) {
        chatRooms[chatRoomIndex].unseenMsg = 0;
      }
      let readMessageIndex = undefined;
      each(unreadMessages, (message, idx) => {
        if (
          message.lastMessage &&
          message.lastMessage.id &&
          action.unSeenMessageIds.indexOf(message.lastMessage.id) > -1
        ) {
          readMessageIndex = idx;
        }
      });
      chatMessages[action.chatRoomData.id] = action.currentChat;
      if (readMessageIndex > -1) {
        unreadMessages.splice(readMessageIndex, 1);
      }
      return assign({}, state, {
        chatMessages: chatMessages,
        chatRooms: chatRooms,
        unreadMessageIds: unreadMessageIds,
        unreadMessages: unreadMessages
      });
    }
    case types.MARK_MESSAGES_AS_READ_FROM_DROPDOWN: {
      let unreadMessages = cloneDeep(state.unreadMessages);
      let readMessageIndex = undefined;
      each(unreadMessages, (message, idx) => {
        if (
          message.lastMessage &&
          action.jobChatRoomId == message.lastMessage.jobChatRoomId
        ) {
          readMessageIndex = idx;
        }
      });
      if (readMessageIndex > -1) {
        unreadMessages.splice(readMessageIndex, 1);
      }
      return assign({}, state, { unreadMessages: unreadMessages });
    }
    default:
      return state;
  }
}
