import React, { Component } from 'react';
import Button from '../common/Button';
import map  from 'lodash/map';
import truncate  from 'lodash/truncate';
import find  from 'lodash/find';
import LightBox from "../common/LightBox";
import { imageBasePath, appBasePath } from '../../constants/staticData';


export default class AcceptedQuotes extends Component {
  constructor() {
    super();
    this.state = {
      imagesLeft: false,
      isVendorDescTruncated: true,
      imageStartIndex: 0,
      showModal: false,
      images: []
    }

    this.AcceptedQuotes =
      {
        ratingText: 3.2,
        TotalRating: 3,
      };
  }

  showText(val) {
    if (val == "more") {
      this.setState({ readMore: false })
    } else {
      this.setState({ readMore: true })
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { garageDetails, router } = this.props;
    const vendorDesc = garageDetails && garageDetails.about;
    let showMoreButtonVisible = vendorDesc && vendorDesc.length > 130 ? true : false;
    const truncatedvendorDesc = this.state.isVendorDescTruncated ? truncate(vendorDesc, { length: 130, 'separator': ' ' }) : vendorDesc;
    let coverImage = garageDetails && garageDetails.images && find(garageDetails.images, (image) => {
      return image.isCover;
    });
    return (
      <div>
        <div className="vendorDetails">
          <div className="vd-heading">
            <h5>Vendor Details</h5>
          </div>
          <div className="vd-img">
            <img src={coverImage && coverImage.small ? coverImage.small : '/images/placeholder.png'} alt="" />
          </div>
          <div className="vd-address">
            <h5>{garageDetails && garageDetails.companyprofilename ? garageDetails.companyprofilename : ''}</h5>
            <span className="km-details">{garageDetails && garageDetails.distance ? garageDetails.distance : ''}</span>
            <div className="rating">
              <span className={garageDetails && garageDetails.rating >= 1 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              <span className={garageDetails && garageDetails.rating >= 2 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              <span className={garageDetails && garageDetails.rating >= 3 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              <span className={garageDetails && garageDetails.rating >= 4 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              <span className={garageDetails && garageDetails.rating >= 5 ? "mdi mdi-star" : "mdi mdi-star-outline"} />
              <span className="rating-text">{garageDetails && garageDetails.rating ? garageDetails.rating : ''} ({garageDetails && garageDetails.reviews ? garageDetails.reviews : 0} Reviews)</span>
            </div>
            <label>{garageDetails && garageDetails.address ? garageDetails.address : ''}</label>
           {/* <label>{garageDetails && garageDetails.email ? garageDetails.email : ''}</label>
               <label>{garageDetails && garageDetails.phone ? garageDetails.phone : ''}</label>*/}
            <Button btnType="btn-theme transparent alert-margin" btnSize="sm" customClass="timeline" fontSize={14} label="Get Directions" btnCallBack={() => { window.open('https://www.google.com/maps/dir/?api=1&destination=' + garageDetails.latitude + ',' + garageDetails.longitude, "_blank"); }} />
            <Button
              btnType="btn-theme transparent alert-margin"
              btnSize="sm"
              customClass="timeline"
              fontSize={14}
              label="View Profile"
              btnCallBack={() => {
                  //router.push(appBasePath + '/vendor-profile/' + `${garageDetails.id}-${garageDetails.companyprofilename}`)
                  router.push(`${appBasePath}/vendor-profile/${garageDetails.companyprofilename.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}`);
                }
              } />
            <Button btnType="btn-theme transparent" btnSize="sm" customClass={this.props.clientWidth < 991 ? "timeline" : "hide"} fontSize={14} label="View Quotes" btnCallBack={this.props.vendorDetailsVisible} />

          </div>
          <div className="figure no-top-padding">
            <h4>Photos</h4>
            <div className="photos-row">
              {garageDetails && garageDetails.images && garageDetails.images.length ? map(garageDetails.images, (item, key) => {
                if (key < 8) {
                  return (
                    <div
                      className="upload-images"
                      key={key}
                      onClick={() => {
                        let garageImages = garageDetails.images;
                        map(garageImages, (image, key) => {
                          garageImages[key].thumbnail = image.small;
                          garageImages[key].original = image.large;
                        });
                        this.setState({
                          images: garageImages,
                          showModal: true,
                          imageStartIndex: key
                        });
                      }}>
                      <img src={item.small} alt="" />
                      {/* <span className="more-photos">+ 10 more</span> */}
                    </div>
                  );
                }
              }) : 'Photos not found'}
            </div>
            {garageDetails && garageDetails.images && garageDetails.images.length >= 8 ? <div className="upload-images wrap-photo" >
              <img src={`${imageBasePath}/images/test.jpg`} alt="" />
              <span className="more-photos">{"+ " + (garageDetails.images.length - 7) + " more"}</span>
            </div> : ''}
            <p>{truncatedvendorDesc}</p>
            {this.state.isVendorDescTruncated && showMoreButtonVisible && <label className="read_more" onClick={(e) => { e.preventDefault(); this.setState({ 'isVendorDescTruncated': false }) }}>Read More</label>}
            {!this.state.isVendorDescTruncated && <label className="read_more" onClick={(e) => { e.preventDefault(); this.setState({ 'isVendorDescTruncated': true }) }}>Read Less</label>}
          </div>
        </div>
        <LightBox
          images={this.state.images}
          imageStartIndex={this.state.imageStartIndex}
          showModal={this.state.showModal}
          isEditable={false}
          closeModal={this.closeModal.bind(this)}
        />
      </div>
    );
  }
}
