import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../common/Button';

class CustomModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        // this.propTypes = {
        //     showModal: React.PropTypes.bool.isRequired,
        // }
    }
    componentWillReceiveProps(nextProps) {
      const { showModal } = nextProps;
        this.setState({
            showModal: showModal
        });
    }

    // close() {
    //     this.setState({ showModal: false });
    //     // if (this.props.closeModalCallBack){
    //     //   this.props.closeModalCallBack();
    //     // }
    // }


    render() {
      const { children, footer, className, closeIcon,header, saveProgress, animation } = this.props;
      return (
        <div>
          <Modal animation={!animation?animation:true} className={className} show={this.state.showModal} onHide={this.props.hideModal} >
            {header==false?"":<Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
                {closeIcon && <label className="close-modal" onClick={this.props.hideModal}>
                  <i className="mdi mdi-close"/>
                </label>}
            </Modal.Header>}
            {children}
            {footer && <Modal.Footer>
              <Button btnType="transparent" btnSize="sm" fontSize={15} label={this.props.cancelText?this.props.cancelText:"Cancel"}  btnCallBack={this.props.hideModal}/>
              <Button btnType="red" btnSize="sm" fontSize={15} label={this.props.saveText?this.props.saveText:"Save"} btnCallBack={(e) => {this.props.submitCallBack && this.props.submitCallBack(e) }}  isButtonLoading={saveProgress}/>
            </Modal.Footer>}
          </Modal>
        </div>
      );
    }
}

export default CustomModal;
