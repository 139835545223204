import React, { Component } from 'react';
import { imageBasePath } from '../../constants/staticData';

export default class  Button extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { btnType, btnSize, disabled, fontSize, label, btnCallBack, iconName, customClass, backgroundColor, isSubmitBtn, isButtonLoading, spanClass ,id } = this.props;
        const btnProperties = {};
        let btnClass = isButtonLoading ? (btnType && btnType.indexOf('red') > -1) ? 'btn btn-theme loader_w ' : 'btn btn-theme loader_r ' : 'btn btn-theme ';
        let customStyle = {};
        if (fontSize) {
            customStyle.fontSize = Number(fontSize);
        }
        if (backgroundColor) {
            customStyle.backgroundColor = backgroundColor;
        }
        btnProperties.style = customStyle;
        btnProperties.className = btnClass + (btnSize ? (btnSize + " ") : '') + (customClass ? (customClass + " ") : '') + (btnType ? btnType : '');
        if (btnCallBack) {
          btnProperties.onClick = btnCallBack;
        }
        if (disabled) {
          btnProperties.disabled = disabled;
        }
        if (isSubmitBtn) {
          btnProperties.type = "submit";
        }
        if (id) {
          btnProperties.id = id;
        }
        return (
          <button {...btnProperties} data-labelledby={this.props.dataLabelledby ? this.props.dataLabelledby : ''}>
              {<img src={`${imageBasePath}/images/loader_white.gif`}  alt="Upload in progress"  className="loader_white"/>}
              {<img src={`${imageBasePath}/images/loader_red.gif`}  alt="Upload in progress"  className="loader_red"/>}
              {label}
          </button >
        );
    }
}
