import React, { Component } from 'react';
import map  from 'lodash/map';
import round  from 'lodash/round';
import each  from 'lodash/each';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import TextInput from '../../common/TextInput';
import PageLoader from '../../common/PageLoader';
import ServiceTypeEmptyState from '../../EmptyState/ServiceTypeEmptyState';
import Button from '../../common/Button';
import { imageBasePath } from '../../../constants/staticData';

class GfCarWash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getCarWashData: this.props.bookingRequestReducer && this.props.bookingRequestReducer.washCategoryData ? this.props.bookingRequestReducer.washCategoryData : [],
      searchGetCarWashData: this.props.bookingRequestReducer && this.props.bookingRequestReducer.washCategoryData ? this.props.bookingRequestReducer.washCategoryData : [],
      selectedCarWashDataArray: [],
      showError: undefined,
      searchValue: '',
      servicesFetched: true,
      categoriesSelectedCount:0
    };
    this.filterCarType = this.filterCarType.bind(this);
    this.changeCheckboxState = this.changeCheckboxState.bind(this);
  }

  toggleCheckedFiled(loopId) {
    let data = { ...this.state.carWashData };
    map(data, (type, id) => {
      if (loopId == id) {
        type["checked"] = !type.checked;
      }
    });
    this.setState({ carWashData: data });
  }
  componentWillMount(){
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
      this.setState({
        servicesFetched: false,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.getCarWashData && !this.state.getCarWashData.length && this.state.searchGetCarWashData && !this.state.searchGetCarWashData.length) {
      this.setState({
        ...this.state,
        getCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        searchGetCarWashData: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData ? nextProps.bookingRequestReducer.washCategoryData : [],
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
  }

  changeCheckboxState(e, categoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const filteredCarWashCategories = cloneDeep(this.state.getCarWashData);
    let findCategoryIndex = findIndex(filteredCarWashCategories, { id: categoryId });
    filteredCarWashCategories[findCategoryIndex]['checked'] = isChecked;
    if (isChecked) {
      if(!this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarWashDataArray.push(filteredCarWashCategories[findCategoryIndex].id);
      }
    } else {
      if(this.state.selectedCarWashDataArray.includes(filteredCarWashCategories[findCategoryIndex].id)){
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarWashDataArray, (n) => {
          return n != filteredCarWashCategories[findCategoryIndex].id;
        });
      }
    }
    if (!removedCategoriesArray) {
      removedCategoriesArray = this.state.selectedCarWashDataArray;
    }
    this.setState({
      ...this.state,
      selectedCarWashDataArray: removedCategoriesArray,
      categoriesSelectedCount: count,
      searchGetCarWashData: filteredCarWashCategories,
      getCarWashData: filteredCarWashCategories,
    });
  }

  renderCarType(carDetails, key) {
    if (carDetails) {
      return (
        <div className="sub-collapse-panel wash-collapse" key={key}>
          <span className="checkbox-style checkbox-wrapper">
            <label htmlFor={carDetails.id}>
              <figure onClick={(e) => { this.showModal(e, carDetails); }}>
                <img src={carDetails.image ? carDetails.image : `${imageBasePath}/images/placeholder.png`} alt="" />
              </figure>
              <div className={carDetails.id == this.state.visibleCategory ? "sub-collapse-panel-head active" : "sub-collapse-panel-head "} >
                <h4>{carDetails.name}</h4>
                <div className="select-type">
                  <input type="checkbox" id={carDetails.id} checked={carDetails.checked ? carDetails.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id); }} value="" />
                  <label htmlFor={carDetails.id} className="custom-checkbox"></label>
                </div>
              </div>
            </label>
          </span>
        </div>
      );
    }
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.getCarWashData);
      let filteredCarWashCategories = [];
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          filteredCarWashCategories.push(carDetails);
        }
      });
      this.setState({
        ...this.state,
        searchGetCarWashData: filteredCarWashCategories,
      });
    } else {
      this.setState({
        ...this.state,
        searchGetCarWashData: cloneDeep(this.state.getCarWashData)
      });
    }
  }

  render() {
    let leftBlock = [];
    let catLength = 0;
    {
      this.state.searchGetCarWashData && this.state.searchGetCarWashData.length && each(this.state.searchGetCarWashData, (carDetails) => {
        catLength += 1;
        leftBlock.push(this.renderCarType(carDetails, catLength));
      });
    }
    return (
      <div className="sub-collapse-panel wash-collapse" >
        <span className="">
          {this.state.servicesFetched ? <div className="panel-content">
            <div className="row">
              <div className="col-md-4 pad0">
                <div className="gf-cr-search-box">
                  <TextInput value={this.state.searchValue} label="Search" name="text" type="text" onChange={(e) => {
                    this.setState({
                      ...this.state,
                      searchValue: e
                    });
                    this.filterCarType(e);
                  }} />
                  <i className="mdi mdi-magnify" />
                </div>
              </div>
            </div>
            {leftBlock.length > 0 ?
              <div className="row">
                <div className="col-md-12 pad0">{leftBlock}</div>
              </div>
              : <ServiceTypeEmptyState />
            }
            {leftBlock.length > 0 && <div className="next-button">
              <Button dataLabelledby="Select Service Next" disabled={this.state.categoriesSelectedCount > 0 ? false : true} btnType="red" btnSize="sm" fontSize={14} label="Next" btnCallBack={() => { this.props && this.props.formValidation();this.props.getCarWashData(this.state.getCarWashData); this.props.toggleNextButton('panel3',true); this.props ; this.props.panel2Valid();}} />
            </div>}
          </div> :
          <PageLoader/>}
        </span>
      </div>
    );
  }
}

export default GfCarWash;
